import {REICHWEITE_MINIMAL} from "./misc";
import moment from "moment";
import {BASE_URL, BASE_URL_NONMP} from "./apiConfig";

const fetchAufgaben = BASE_URL + "aufgaben/"
const fetchAbgabeplanBetween = (planzeileId, start, ende) => !planzeileId || !start || !ende ? null : BASE_URL + `abgabeplan?planzeile=${planzeileId}&start=${moment(start).format("YYYY-MM-DD")}&ende=${moment(ende).format("YYYY-MM-DD")}`;
const fetchSimulationsBeginn = (planId) => !planId ? null : BASE_URL + "medikationsplan/" + planId + "/abgabeplan/naechster";


const fetchMedikationsplaene = BASE_URL + "medikationsplan";
const fetchAnsprueche = (patientId) => !patientId ? null : BASE_URL + "anspruch?patient=" + patientId;
const fetchBestaende = (patientId) => !patientId ? null : BASE_URL + "bestand/unit/" + patientId;
const fetchDokumente = (patientId) => !patientId ? null : BASE_URL + "dokumente/akteur/" + patientId;
const fetchBlisterJobs = ({patientId, limit}) => !patientId ? null : BASE_URL_NONMP + `job/patient/${patientId}?limit=${limit}`;

const fetchZeitraeume = (planId) => !planId ? null : BASE_URL + `zeitraum?medikationsplanId=${planId}`;

const fetchBlisterJobDetails = (jobId, patientId) => (!patientId || !jobId) ? null : BASE_URL_NONMP + 'job/' + jobId + '/patient/' + patientId + '/entries';

const fetchInfomarkerByPlanzeile = (planzeileId) => !planzeileId ? null : BASE_URL + 'infomarker?planzeileId=' + planzeileId;
const fetchInfomarkerByAbschnitt = (planzeileId) => !planzeileId ? null : BASE_URL + 'infomarker?planzeileId=' + planzeileId;

const fetchMediplanSuchergebnisByQuery = (query) => !query ? null : BASE_URL + 'suche/medikationsplan?query=' + query;
const fetchMediplanSuchergebnisByHeim = (heimId) => !heimId ? null : BASE_URL + 'suche/medikationsplan?active=true&heimId=' + heimId;
const fetchMediplanSuchergebnisByStation = (stationId) => !stationId ? null : BASE_URL + 'suche/medikationsplan?active=true&stationId=' + stationId;

const fetchPlanzeilenFreigabeFehlt = () => BASE_URL + 'planzeile?freigabefehlt=true';
const fetchPlanzeilenUnklar = () => BASE_URL + 'planzeile?unklar=true';
const fetchPlanzeilenReichweiteAlarm = (maxReichweite = REICHWEITE_MINIMAL, heute=null) => BASE_URL + `planzeile?checkAnforderung=true&reichweite=${maxReichweite}${heute ? "&heute="+heute : ""}`;
const fetchPlanzeilenAnforderungNoetig = (datumAnforderung) => BASE_URL + `planzeile?checkAnforderung=true&datumAnforderung=${moment(datumAnforderung).format("YYYYMMDD")}`;
const fetchPlanzeilenVorab = () => BASE_URL + 'planzeile?checkAnforderung=true&vorab=true';

const fetchAktuelleNutzerDaten = () => BASE_URL_NONMP + 'permission/current';

const fetchNachrichtenArchiv = () => BASE_URL_NONMP + 'nm/nachricht';
const fetchNachrichtById = (id) => !id ? null : BASE_URL_NONMP + 'nm/nachricht/' + id;

const fetchGesammelteAnforderungen = () => BASE_URL + 'anforderung?gesammelt=true';
const fetchOffeneAnforderungen = () => BASE_URL + 'anforderung?offen=true';
const fetchAnforderungByNachrichtId = (nachrichtId) => BASE_URL + 'anforderung?nachricht=' + nachrichtId;
const fetchAnforderungenByPatientId = (patientId, nurOffen) => BASE_URL + `anforderung?patient=${patientId}${nurOffen ? "offen=true" : ""}`;
const fetchAnforderungenProblemfaelle = (highPriority) => BASE_URL + `anforderung?problemfaelle=true&highPriority=${highPriority ? 'true' : 'false'}`;

const fetchOffeneAnforderungenByPlanzeile = (planzeileId) => !planzeileId ? null : BASE_URL + 'anforderung?offen=true&planzeile=' + planzeileId;

const fetchArzneimittelByIdAndBedarf = (id, bedarf) => (!id || !bedarf) ? null : BASE_URL + 'arzneimittel/' + id + '/bedarf/' + bedarf;
const fetchArzneimittelGleicheM2ById = (id) => (!id) ? null : BASE_URL + 'arzneimittel/' + id + '/gleiche-m2';

const fetchBlisterJobFehlbestaende = (excludeLieferengpass, highPriority) => BASE_URL_NONMP + `job/shortages?highPriority=${highPriority ? 'true' : 'false'}&excludelieferengpass=${excludeLieferengpass ? 'true' : 'false'}`;
const fetchBlisterJobLieferengpass = (highPriority) => BASE_URL_NONMP + `job/shortages/lieferengpass?highPriority=${highPriority ? 'true' : 'false'}`;
const fetchExternalPackageById = (packageId) => BASE_URL_NONMP + `externalpackage/${packageId}`;
const fetchExternalPackageListByPatient = (patientId, fetchDeep, start, end) => BASE_URL_NONMP + `externalpackage/owner/${patientId}?fetchDeep=${fetchDeep ? 'true' : 'false'}${start ? `&startString=${moment(start).format('YYYYMMDD')}`:''}${end ? `&endString=${moment(end).format('YYYYMMDD')}`:''}`;

export {
    fetchAufgaben,
    fetchAbgabeplanBetween,
    fetchSimulationsBeginn,
    fetchMedikationsplaene,

    fetchAnsprueche,
    fetchBestaende,
    fetchDokumente,
    fetchBlisterJobs,
    fetchBlisterJobDetails,
    fetchInfomarkerByPlanzeile,
    fetchInfomarkerByAbschnitt,
    fetchMediplanSuchergebnisByQuery,
    fetchMediplanSuchergebnisByHeim,
    fetchMediplanSuchergebnisByStation,
    fetchPlanzeilenFreigabeFehlt,
    fetchPlanzeilenUnklar,
    fetchPlanzeilenReichweiteAlarm,
    fetchPlanzeilenVorab,
    fetchAktuelleNutzerDaten,

    fetchNachrichtenArchiv,
    fetchNachrichtById,
    fetchOffeneAnforderungen,
    fetchGesammelteAnforderungen,
    fetchAnforderungByNachrichtId,
    fetchAnforderungenByPatientId,
    fetchAnforderungenProblemfaelle,

    fetchArzneimittelByIdAndBedarf,
    fetchArzneimittelGleicheM2ById,
    fetchOffeneAnforderungenByPlanzeile,
    fetchPlanzeilenAnforderungNoetig,

    fetchZeitraeume,

    fetchBlisterJobFehlbestaende,
    fetchBlisterJobLieferengpass,

    fetchExternalPackageById,
    fetchExternalPackageListByPatient,
}
