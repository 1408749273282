export const equalsDeep = (a, b, properties = []) => {
    if (!properties || properties.length === 0) return JSON.stringify(a) === JSON.stringify(b);
    if (!a && !b) {
        return true;
    } else if (!a || !b) {
        return false;
    }

    const isArray = Array.isArray(properties);
    if (isArray && properties.length === 1 || !isArray) {
        const prop = isArray ? properties[0] : properties;
        return a[prop] === b[prop];
    } else {
        const prop = properties.shift();
        return equalsDeep(a[prop], b[prop], properties);
    }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Simple array check.
 * @param item
 * @returns {boolean}
 */
export function isArray(item) {
    return (item && typeof item === 'object' && Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else if (isArray(source[key]) && isArray(target[key])) {
                target[key].push(...source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}