import React from 'react'

import { Button } from 'antd';


export const MediplanCacheNavigator = ({ chachedIdList, ...props }) => {

    return <div>
        {/* {chachedIdList && chachedIdList.map(
            e => <Button type="dashed">Klick me {e}</Button>
        )} */}
    </div >
}
