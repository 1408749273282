import React, {useContext, useEffect, useState} from "react";
import {Button, DatePicker, Select, Table} from "antd";
import moment from "moment";
import {useAuth} from "../../utilities/useAuth";
import useApiZeitraum from "../../utilities/useApiZeitraum";
import {callApiAsync, CancelablePromise} from "../../utilities/apiUtil";
import {medikationsplanApi} from "../../config/apiConfig";
import {getSorter} from "../../utilities/sortUtil";
import {getZeitraumTypMapForContext} from "./MedikationsplanZeitraeume";

const ArztZeitraeume = ({ unit, typMap = getZeitraumTypMapForContext("adressbuchArzt") }) => {
    const auth = useAuth();
    const zeitraumApi = useApiZeitraum();

    const [zeitraumMap, setZeitraumMap] = useState(null);
    const [zeitraumList, setZeitraumList] = useState([]);
    const [zeitraeumeLoaded, setZeitraeumeLoaded] = useState(false);

    const [zeitraumEdit, setZeitraumEdit] = useState({});
    const [zeitraumNeu, setZeitraumNeu] = useState(null);
    const [zeitraumEditIndex, setZeitraumEditIndex] = useState(-1);

    useEffect(() => {
        if (unit) {
            setZeitraumNeu({
                unitId: unit.id,
                typ: "U",
                start: moment().startOf('day').valueOf()
            });

            zeitraumApi.getZeitraumMapByUnit(unit.id).then(map => {
                setZeitraumMap(map);
            });
        }
    }, [unit])

    useEffect(() => {
        if (zeitraumMap) {
            const listNeu = [];
            for (let typ in typMap) {
                listNeu.push(...(zeitraumMap[typ] || []));
            }

            setZeitraeumeLoaded(true);
            setZeitraumList(listNeu);
        }
    }, [zeitraumMap])

    const updateZeitraum = (zeitraumNeu, zeitraumAlt) => {
        if (!zeitraumNeu && !zeitraumAlt) return null;

        return new CancelablePromise(resolve => {
            setZeitraumMap(prev => {
                const neu = {...prev};

                let typ;
                if (zeitraumNeu) {
                    // Zeitraum wurde aktualisiert
                    typ = zeitraumNeu.id.typ;
                    if (zeitraumAlt?.id?.typ !== typ) {
                        // ZeitraumTyp hat sich geändert oder Zeitraum ist neu
                        if (zeitraumAlt) neu[zeitraumAlt.id.typ] = neu[zeitraumAlt.id.typ].filter(z => z.id.start !== zeitraumAlt.id.start);
                        neu[typ] = [...(neu[typ] || []), zeitraumNeu];
                    } else {
                        neu[typ] = [...(neu[typ] || []).filter(z => z.id.start !== zeitraumAlt.id.start), zeitraumNeu];
                    }
                } else {
                    // Zeitraum wurde gelöscht
                    typ = zeitraumAlt.id.typ;
                    neu[typ] = (neu[typ] || []).filter(z => z.id.start !== zeitraumAlt.id.start);
                }

                neu[typ].sort(getSorter("zeitraum"));

                resolve(neu);
                return neu;
            });
        });
    }

    const deleteZeitraum = async (zeitraum) => {
        if (zeitraum == null) return;

        setZeitraeumeLoaded(false);
        const response1 = await callApiAsync({url: medikationsplanApi.zeitraumApi(), method: "DELETE", data: zeitraum, auth});
        updateZeitraum(null, zeitraum);
        zeitraumApi.updateZeitraumMap(null, zeitraum);
    }

    const postZeitraum = async (zeitraumAlt, editObject) => {
        if (editObject && Object.keys(editObject).length) {
            let zeitraumNeu = {...zeitraumAlt};

            outer: for (let key in editObject) {
                switch (key) {
                    case "unitId":
                    case "typ":
                        await deleteZeitraum(zeitraumAlt);
                        zeitraumNeu.id = {...zeitraumNeu.id, [key]: editObject[key]};
                        break;
                    case "start":
                        await deleteZeitraum(zeitraumAlt);

                        if (!editObject[key]) {
                            zeitraumNeu = null;
                            break outer;
                        } else {
                            zeitraumNeu.id = {...zeitraumNeu.id, start: editObject[key]};
                            if (zeitraumAlt && moment(editObject[key]).isAfter(zeitraumAlt.ende)) zeitraumNeu.ende = null;
                        }
                        break;
                    case "ende":
                        if (editObject[key] && zeitraumAlt && moment(editObject[key]).isBefore(zeitraumAlt.id.start)) return;
                        zeitraumNeu.ende = editObject[key];
                        break;
                }
            }

            if (zeitraumNeu) {
                setZeitraeumeLoaded(false);
                const response2 = await callApiAsync({
                    url: medikationsplanApi.zeitraumApi(),
                    method: "POST",
                    data: zeitraumNeu,
                    auth
                });

                updateZeitraum(response2.data.OBJECT, zeitraumAlt);
                zeitraumApi.updateZeitraumMap(response2.data.OBJECT, zeitraumAlt);
            }
        }

        setZeitraumEditIndex(-1);
        setZeitraumEdit({});
    }

    const columns = [
        {
            title: 'Start',
            key: 'start',
            width: 150,
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <DatePicker
                        defaultValue={moment(zeitraum.id.start)}
                        onChange={value => setZeitraumEdit(prev => ({...prev, start: value.startOf('day').valueOf()}))}
                        format={"DD.MM.YYYY"}
                        allowClear={false}
                    />
                } else {
                    return moment(zeitraum.id.start).format("DD.MM.YYYY")
                }
            },
        },
        {
            title: 'Ende',
            key: 'ende',
            width: 150,
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <DatePicker
                        defaultValue={zeitraum.ende ? moment(zeitraum.ende) : null}
                        onChange={value => setZeitraumEdit(prev => ({...prev, ende: value ? value.endOf('day').valueOf() : null}))}
                        format={"DD.MM.YYYY"}
                    />
                } else {
                    return zeitraum.ende ? moment(zeitraum.ende).format("DD.MM.YYYY") : "-"
                }
            },
        },
        {
            title: 'Typ',
            key: 'typ',
            width: 200,
            render: (zeitraum, _, index) => typMap[zeitraum.id.typ].label
        },
        {
            title: 'Aktion',
            key: 'aktion',
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <Button size={"small"} onClick={() => postZeitraum(zeitraum, zeitraumEdit)}>speichern</Button>
                } else {
                    return <>
                        <Button size={"small"} onClick={() => { setZeitraumEdit({}); setZeitraumEditIndex(index) }}>bearbeiten</Button>
                        <Button size={"small"} onClick={() => deleteZeitraum(zeitraum)}>löschen</Button>
                    </>
                }
            }
        }
    ];

    return <>
        <h3>Zeiträume</h3>
        {!!zeitraumNeu && <div>
            <span style={{marginRight: 10}}>Neuer Zeitraum:</span>
            <span>Start: <DatePicker
                defaultValue={moment(zeitraumNeu.start)}
                onChange={value => setZeitraumNeu(prev => ({...prev, start: value.startOf('day').valueOf()}))}
                format={"DD.MM.YYYY"}
                allowClear={false}
            /></span>
            <span>Ende: <DatePicker
                defaultValue={zeitraumNeu.ende ? moment(zeitraumNeu.ende) : null}
                onChange={value => setZeitraumNeu(prev => ({...prev, ende: value ? value.endOf('day').valueOf() : null}))}
                format={"DD.MM.YYYY"}
            /></span>
            <span>Typ: <Select value={zeitraumNeu.typ} dropdownMatchSelectWidth={false} onChange={value => setZeitraumNeu(prev => ({...prev, typ: value}))}>
                    {Object.keys(typMap).map(typ => {
                        return <Select.Option value={typ} key={typ}>{typMap[typ].label}</Select.Option>;
                    })}
                </Select></span>
            <Button onClick={() => postZeitraum(null, zeitraumNeu)}>speichern</Button>
        </div>}
        <Table
            rowKey={zeitraum => `${zeitraum.id.typ}_${zeitraum.id.start}`}
            style={{width: 800}}

            columns={columns}
            dataSource={zeitraumList}
            pagination={false}
            loading={!zeitraeumeLoaded}
        />
    </>
}

export default ArztZeitraeume;