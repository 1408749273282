import {createContext, useEffect, useState} from "react";

const initVal = {
    selectedInfoMarker: {},
    setSelectedInfoMarker: (infoMarker, index=null, type=null) => {},

    pageOrder: [1],
    setPageOrder: val => {},

    removeInfoMarker: null,
    setRemoveInfoMarker: val => {},

    imContextMenu: {},
    setImContextMenu: val => {}}
;

const PopupDokumenteContext = createContext(initVal);
export default PopupDokumenteContext

export const useInitialState = () => {

    const [selectedInfoMarker, setSelectedInfoMarker] = useState(null);
    const [pageOrder, setPageOrder] = useState([1]);
    const [removeInfoMarker, setRemoveInfoMarker] = useState(null);
    const [imContextMenu, setImContextMenu] = useState(null);

    useEffect(() => {
        window.addEventListener('storage', () => {
            const jsonHighlightInfoMarker = localStorage.getItem('highlightInfoMarker');
            if (jsonHighlightInfoMarker) {
                setSelectedInfoMarker(JSON.parse(jsonHighlightInfoMarker));
            }
        });
    }, []);

    return {
        selectedInfoMarker,
        setSelectedInfoMarker,

        pageOrder,
        setPageOrder,

        removeInfoMarker,
        setRemoveInfoMarker,

        imContextMenu,
        setImContextMenu
    }
};