import React, {useEffect} from 'react';
import {Layout} from 'antd';

import { siderStyle, mainStyle } from '../../style/css';

import  MedikationsplanSuche  from '../organisms/MedikationsplanSuche';

import {MedikationsplanDokumente} from "../organisms/MedikationsplanDokumente";
import {useApi} from "../../utilities/useApi";
import {medikationsplanApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import MedikationsplanTable from "../molecules/MedikationsplanTable";
import {Loading} from "../organisms/StatusDisplay";
import {useHistory} from "react-router-dom";
import queryString from "query-string";

/**
 * @category Pages
 * @method Medikationsplan
 * @param {Object} match match.params.planId ist über URL gewählte Medikationsplan ID
 * @see {@link MedikationsplanSidebarSuche}
 * @see {@link MedikationsplanUebersicht}
 * @see {@link MedikationsplanDetail}
 * @return JSX Component 
 */
export const Medikationsplan = ({ match }) => {
    const api = useApi();
    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
        if ((match.params.planId === "uebersicht" || !match.params.planId) && !api.patientenMitMedikationsplanLoaded) {
            api.loadPatientenMitMedikationsplan();
        }
    }, [match.params.planId])

    useEffect(() => {
        if (match.params.planId) {
            callApiAsync({auth, url: medikationsplanApi.getById(match.params.planId)}).then(response => {
                const medikationsplan = response.data.OBJECT;

                const query = queryString.parse(history.location.search);

                if (match.params.ansicht) query.ansicht = match.params.ansicht;
                else delete query.ansicht;

                if (match.params.planzeileId) query.planzeile = match.params.planzeileId;
                else delete query.planzeile;

                history.replace(queryString.stringifyUrl({url: `/adressbuch/${medikationsplan.patient.id}/medikationsplan`, query}));
            });
        }
    }, [match.params.ansicht, match.params.planzeileId])

    return (
        <Layout>

            <Layout style={{...siderStyle}} className="no-print">
                <MedikationsplanSuche />
                <div style={{flexGrow: 1}}>
                    <MedikationsplanDokumente />
                </div>
            </Layout>

            <Layout style={mainStyle}>

                {/* Übersicht wurde explizit per Link aufgerufen */}
                {(match.params.planId === "uebersicht" || !match.params.planId) ?
                    <MedikationsplanTable />

                    :

                    <Loading />
                }

            </Layout>

        </Layout>
    )
}
