import React, {useEffect, useState} from "react";

/**
 * Abda-Steuerzeichen werden laut Dokumentation ersetzt
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
export const AbdaText = ({text}) => {
    return <AbdaSteuerzeichenN text={text} />
};

/**
 * Steuerzeichen für Zeilenumbruch
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenN = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~n"));
    }, [text])

    return <>
        {children.map((c, i) => <div key={i}><AbdaSteuerzeichenF text={c} /></div>)}
    </>
};

/**
 * Steuerzeichen für Fettschrift
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenF = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~f"));
    }, [text])

    return <>
        {children.map((c, i) => {
            const style = i % 2 === 1 ? {fontWeight: "bold"} : {};
            return <span key={i} style={style}><AbdaSteuerzeichenC text={c} /></span>
        })}
    </>
};

/**
 * Steuerzeichen für Reduzierte Textgröße um 33%
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenC = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~c"));
    }, [text])

    return <>
        {children.map((c, i) => {
            const style = i % 2 === 1 ? {fontSize: "66%"} : {};
            return <span key={i} style={style}><AbdaSteuerzeichenK text={c} /></span>
        })}
    </>
};

/**
 * Steuerzeichen für Kursivschrift
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenK = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~k"));
    }, [text])

    return <>
        {children.map((c, i) => {
            const style = i % 2 === 1 ? {fontStyle: "italic"} : {};
            return <span key={i} style={style}><AbdaSteuerzeichenU text={c} /></span>
        })}
    </>
};

/**
 * Steuerzeichen für unterstrichenen Text
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenU = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~u"));
    }, [text])

    return <>
        {children.map((c, i) => {
            const style = i % 2 === 1 ? {textDecoration: "underline"} : {};
            return <span key={i} style={style}><AbdaSteuerzeichenH text={c} /></span>
        })}
    </>
};

/**
 * Steuerzeichen für hochgestellten Text
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenH = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.replace(/~h(.)/g, "_H_$1_H_").split("_H_"));
    }, [text])

    return <>
        {children.map((c, i) => {
            return <span key={i}>{i % 2 === 1 ?<sup>{c}</sup> : <AbdaSteuerzeichenT text={c} />}</span>
        })}
    </>
};

/**
 * Steuerzeichen für tiefgestellten Text
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenT = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.replace(/~t(.)/g, "_T_$1_T_").split("_T_"));
    }, [text])

    return <>
        {children.map((c, i) => {
            return <span key={i}>{i % 2 === 1 ?<sub>{c}</sub> : <AbdaSteuerzeichenB text={c} />}</span>
        })}
    </>
};

/**
 * Steuerzeichen für Leerzeichen
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenB = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split(/~b|~g/));
    }, [text])

    return <>
        {children.map((c, i) => <span key={i}><span><AbdaSteuerzeichenI text={c} /></span>{i <= children.length-2 && <span>&nbsp;</span>}</span>)}
    </>
};

/**
 * Steuerzeichen für Tabulator
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenI = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~i"));
    }, [text])

    return <>
        {children.map((c, i) => <span key={i}><span><AbdaSteuerzeichenW text={c} /></span>{i <= children.length-2 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}</span>)}
    </>
};

/**
 * Steuerzeichen für Trennmöglichkeit innerhalb eines Wortes
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const AbdaSteuerzeichenW = ({text}) => {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (text) setChildren(text.split("~w"));
    }, [text])

    return <>
        {children.map((c, i) => <span key={i}>{c}</span>)}
    </>
};