import React, { useState, useEffect } from 'react';

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export default function useDebounce(value, delay, options = {instantEmpty: false}) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        let handler;

        if (!value && options.instantEmpty) {
            setDebouncedValue(value);
        } else {
            handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
        }

        return () => {
            if (handler) clearTimeout(handler);
        };
    },[value]);

    return debouncedValue;
}