import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

export const Error = (props) => {
    return (
        <div className="blob">
            <h1>Es ist ein Fehler aufgetreten.</h1>
            <pre>{JSON.stringify(props,null,2)}</pre>
        </div>
    );
}

export const Loading = ({text="Loading...", showText=false, size=undefined, ...props}) => {
    return <div style={{display: "flex", alignItems: "center", gap: 5}}>
        <Spinner animation="border" role="status" size={size} />
        {!!text && <span className={showText ? "" : "sr-only"}>{text}</span>}
    </div>;
}


export const Default = props => {
    return (
        <div>
            <h1>Default page</h1>
            <pre>{JSON.stringify(props, null, 2)}</pre>
        </div>
    )
}
