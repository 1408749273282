import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {List, Button, DatePicker, Row, Col, Tooltip} from "antd";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {useFetch} from "../../utilities/useFetch";
import {dokumenteApi, notizApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import DokumenteContext from "../../contexts/DokumenteContext";
import {getSorter} from "../../utilities/sortUtil";
import moment from "moment";
import {Popover} from "../atoms/Popover";
import {callApiAsync} from "../../utilities/apiUtil";
import {WarningOutlined} from "@ant-design/icons"
import {useApi} from "../../utilities/useApi";
import useDateiVorschau from "../../utilities/useDateiVorschau";
import NotizContext from "../../contexts/NotizContext";
import usePagination from "../../utilities/usePagination";

export const MedikationsplanDokumente = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const dokumenteContext = useContext(DokumenteContext);
    const notizContext = useContext(NotizContext);
    const api = useApi();
    const {openDokument} = useDateiVorschau();

    const [dokumentePatientIdList, setDokumentePatientIdList] = useState([]);
    const [dokumenteInfoMarker, setDokumenteInfoMarker] = useState([]);
    const [dokumenteInfoMarkerFiltered, setDokumenteInfoMarkerFiltered] = useState([]);
    const [dokumenteIdHighlighted, setDokumenteIdHighlighted] = useState({});
    const [notizIdHighlighted, setNotizIdHighlighted] = useState({});

    const [verknuepfungenPatientAktuell, setVerknuepfungenPatientAktuell] = useState([]);
    const [verknuepfungenPatientKommend, setVerknuepfungenPatientKommend] = useState([]);
    const [verknuepfungenPatientVergangen, setVerknuepfungenPatientVergangen] = useState([]);
    const [contextMenuVerknuepfung, setContextMenuVerknuepfung] = useState({});
    const [contextMenuNotiz, setContextMenuNotiz] = useState({});
    const [showContextMenuVerknuepfung, setShowContextMenuVerknuepfung] = useState(null);
    const [showContextMenuNotiz, setShowContextMenuNotiz] = useState(null);
    const [notizenSorted, setNotizenSorted] = useState([]);

    const pagination1 = usePagination();
    const pagination2 = usePagination();
    const pagination3 = usePagination();
    const pagination4 = usePagination();

    const {
        data: verknuepfungenPatient,
        loading: loadingVerknuepfungenPatient,
        update: updateVerknuepfungenPatient,
        reload: reloadVerknuepfungenPatient,
    } = useFetch(dokumenteApi.getVerknuepfungenByPatient(medikationsplanContext.medikationsplan?.patient?.id || null), useCallback(() => getSorter("dokumentVerknuepfung", "gueltigVon", true), []));

    useEffect(() => {
        if (medikationsplanContext.medikationsplan) {
            const patientId = medikationsplanContext.medikationsplan.patient.id;
            const notizenMap = notizContext.notizMap[patientId];

            if (!notizenMap) {
                api.loadNotizenForUnit(patientId);
            } else {
                const keysSorted = Object.keys(notizenMap).sort();

                const notizen = [];
                for (let key of keysSorted) {
                    const notiz = notizenMap[key];
                    let dataEntry;

                    switch (notiz.type) {
                        case "UNIT":
                            dataEntry = (notiz.unitSet || []).find(u  => u.id.unitId === medikationsplanContext.medikationsplan.patient.id);
                            break;
                        case "INTERAKTION":
                            dataEntry = (notiz.interaktionSet || []).find(i => i.id.unitId === medikationsplanContext.medikationsplan.patient.id && (!i.bearbeitet || i.gueltigVon || i.gueltigBis));
                            break;
                    }

                    if (dataEntry) {
                        if (!dataEntry.gueltigVon || moment(dataEntry.gueltigVon).startOf('day').isSameOrBefore(medikationsplanContext.anzeigeDatum)) {
                            if (!dataEntry.gueltigBis || moment(dataEntry.gueltigBis).startOf('day').isSameOrAfter(medikationsplanContext.anzeigeDatum)) {
                                notizen.push(notiz);
                            }
                        }
                    }
                }

                setNotizenSorted(notizen);
            }
        }
    }, [medikationsplanContext.medikationsplan, notizContext.notizMap, medikationsplanContext.anzeigeDatum])

    useEffect(() => {
        if (verknuepfungenPatient) {
            const verknuepfungPatientAktuellNeu = [];
            const verknuepfungPatientVergangenNeu = [];
            const verknuepfungPatientKommendNeu = [];

            const anzeigeDatumMoment = moment(medikationsplanContext.anzeigeDatum);

            const dokumentIdList = [];
            let reloadList = false;
            verknuepfungenPatient.forEach(verknuepfung => {
                if (!verknuepfung.dokument) {
                    reloadList = true;
                    return;
                }

                const dokumentId = verknuepfung.dokumentId || verknuepfung.dokument.id;
                if (!dokumentIdList.includes(dokumentId)) {
                    dokumentIdList.push(dokumentId);
                }

                if (verknuepfung.gueltigVon && moment(verknuepfung.gueltigVon).isAfter(anzeigeDatumMoment)) {
                    verknuepfungPatientKommendNeu.push(verknuepfung);
                } else if (verknuepfung.gueltigBis) {
                    const gueltigBisMoment = moment(verknuepfung.gueltigBis);
                    if (gueltigBisMoment.isSameOrAfter(anzeigeDatumMoment)) verknuepfungPatientAktuellNeu.push(verknuepfung);
                    else verknuepfungPatientVergangenNeu.push(verknuepfung);
                } else {
                    verknuepfungPatientAktuellNeu.push(verknuepfung);
                }
            });

            if (reloadList) {
                reloadVerknuepfungenPatient();
            } else {
                verknuepfungPatientAktuellNeu.sort(getSorter("id", 'default', true));
                verknuepfungPatientKommendNeu.sort(getSorter("id", 'default', true));
                verknuepfungPatientVergangenNeu.sort(getSorter("id", 'default', true));

                setVerknuepfungenPatientAktuell(verknuepfungPatientAktuellNeu);
                setVerknuepfungenPatientKommend(verknuepfungPatientKommendNeu);
                setVerknuepfungenPatientVergangen(verknuepfungPatientVergangenNeu);

                medikationsplanContext.setVerknuepfungen({
                    aktuell: verknuepfungPatientAktuellNeu,
                    kommend: verknuepfungPatientKommendNeu,
                    vergangen: verknuepfungPatientVergangenNeu
                });
                setDokumentePatientIdList(dokumentIdList);
            }
        }
    }, [verknuepfungenPatient, medikationsplanContext.anzeigeDatum])

    useEffect(() => {
        if (medikationsplanContext.reloadVerknuepfungen) {
            medikationsplanContext.setReloadVerknuepfungen(false);

            reloadVerknuepfungenPatient();
        }
    }, [medikationsplanContext.reloadVerknuepfungen])

    useEffect(() => {
        if (!medikationsplanContext.medikationsplan) return;

        const neu = dokumenteContext.dokumentUpdated.neu;
        const alt = dokumenteContext.dokumentUpdated.alt;

        // Dokument wurde erstellt oder bearbeitet
        if (neu) {
            const verknuepfungPatient = neu.verknuepfungen.find(v => v.typ === "PATIENT" && v.unit.id === medikationsplanContext.medikationsplan.patient.id);
            const patientNeu = verknuepfungPatient ? verknuepfungPatient.unit : null;

            if (patientNeu) {
                // update Liste der Dokumente für UnitLangname (oder füge Dokument hinzu)
                const verknuepfungenNeu = verknuepfungenPatient ? [...verknuepfungenPatient.filter(v => v.id !== verknuepfungPatient.id), verknuepfungPatient] : [verknuepfungPatient];
                updateVerknuepfungenPatient(verknuepfungenNeu);
            } else if (alt) {
                const verknuepfungPatientAlt = alt.verknuepfungen.find(v => v.typ === "PATIENT" && v.unit.id === medikationsplanContext.medikationsplan.patient.id);

                // Dokument wurde einem anderen UnitLangname zugeordnet
                if (verknuepfungPatientAlt) {
                    const verknuepfungenNeu = verknuepfungenPatient ? [...verknuepfungenPatient.filter(v => v.id !== verknuepfungPatientAlt.id)] : [];
                    updateVerknuepfungenPatient(verknuepfungenNeu);
                }
            }
        }

        // Dokument wurde gelöschtaktuelle
        else {
            if (alt) {
                const verknuepfungPatientAlt = alt.verknuepfungen.find(v => v.typ === "PATIENT" && v.unit.id === medikationsplanContext.medikationsplan.patient.id);

                if (verknuepfungPatientAlt) {
                    const verknuepfungenNeu = verknuepfungenPatient ? [...verknuepfungenPatient.filter(v => v.id !== verknuepfungPatientAlt.id)] : [];
                    updateVerknuepfungenPatient(verknuepfungenNeu);
                }
            }
        }
    }, [dokumenteContext.dokumentUpdated])

    useEffect(() => {
        if (medikationsplanContext.infoMarkerMap) {
            const dokumenteInfoMarkerNeu = [];
            const dokumenteInfoMarkerIdList = [];

            Object.values(medikationsplanContext.infoMarkerMap).forEach(infoMarkerList => {
                infoMarkerList.forEach(infoMarker => {
                    if (!dokumenteInfoMarkerIdList.includes(infoMarker.dokument.id)) {
                        dokumenteInfoMarkerIdList.push(infoMarker.dokument.id);
                        dokumenteInfoMarkerNeu.push(infoMarker.dokument);
                    }
                })
            })

            setDokumenteInfoMarker(dokumenteInfoMarkerNeu);
        }
    }, [medikationsplanContext.infoMarkerMap])

    useEffect(() => {
        if (dokumenteInfoMarker) {
            const dokumenteInfoMarkerFilteredNeu = dokumenteInfoMarker.filter(dokument => {
                return !dokumentePatientIdList.includes(dokument.id);
            });

            setDokumenteInfoMarkerFiltered(dokumenteInfoMarkerFilteredNeu);
        }
    }, [dokumenteInfoMarker, dokumentePatientIdList])

    // Ermittle die Dokumente, die hervorgehoben werden sollen (InfoMarker: ausgewählte Planzeile + aktueller Dosierabschnitt dieser)
    useEffect(() => {
        if (medikationsplanContext.planzeileSelected && medikationsplanContext.infoMarkerMap[medikationsplanContext.planzeileSelected]) {
            const infoMarkerList = medikationsplanContext.infoMarkerMap[medikationsplanContext.planzeileSelected];
            const dokumenteIdHighlightedNeu = {};
            if (infoMarkerList.length) {
                for (let infoMarker of infoMarkerList) {
                    if (
                        infoMarker.planzeile &&
                        infoMarker.planzeile.id === medikationsplanContext.planzeileSelected &&
                        medikationsplanContext.dosierabschnittAktuell[infoMarker.planzeile.id] &&
                        infoMarker.dosierabschnittSet?.find(d => d.id === medikationsplanContext.dosierabschnittAktuell[infoMarker.planzeile.id].id) &&
                        !Object.keys(dokumenteIdHighlightedNeu).includes(infoMarker.dokument.id)
                    ) {
                        dokumenteIdHighlightedNeu[infoMarker.dokument.id] = infoMarker.id;
                    }
                }
            }

            setDokumenteIdHighlighted(dokumenteIdHighlightedNeu);
        } else {
            setDokumenteIdHighlighted({});
        }
    }, [medikationsplanContext.planzeileSelected, medikationsplanContext.infoMarkerMap, medikationsplanContext.dosierabschnittAktuell])

    useEffect(() => {
        if (medikationsplanContext.planzeileSelected && medikationsplanContext.medikationsplan && notizContext.notizMap[medikationsplanContext.medikationsplan.patient.id]) {
            const notizen = notizContext.notizMap[medikationsplanContext.medikationsplan.patient.id];
            const notizIdHighlightedNeu = {};
            for (let notizId of Object.keys(notizen)) {
                const notiz = notizen[notizId];
                const planzeilen = notizen[notizId].unitSet.find(u => u.id.unitId === medikationsplanContext.medikationsplan.patient.id)?.planzeilen || [];
                if (planzeilen.find(np => np.id.planzeileId === medikationsplanContext.planzeileSelected)) {
                    notizIdHighlightedNeu[notiz.id] = notiz;
                }
            }

            setNotizIdHighlighted(notizIdHighlightedNeu);
        }
    }, [medikationsplanContext.planzeileSelected, notizContext.notizMap])

    const onVisibleChangeContextMenuVerknuepfung = async (visible, verknuepfung, forcePut=false) => {
        if (!visible) {
            setContextMenuVerknuepfung({});
            setShowContextMenuVerknuepfung(null);

            if (!!verknuepfung) {
                if (forcePut || Object.keys(contextMenuVerknuepfung).length > 0) {
                    const data = {...verknuepfung, ...contextMenuVerknuepfung};
                    const response = await callApiAsync({
                        auth,
                        url: dokumenteApi.putVerknuepfung(data.id),
                        method: "put",
                        data
                    });
                    const verknuepfungenPatientNeu = [...verknuepfungenPatient.filter(v => v.id !== data.id), response.data.OBJECT];

                    updateVerknuepfungenPatient(verknuepfungenPatientNeu);
                    medikationsplanContext.setVerknuepfungChanged(response.data.OBJECT);

                }
            }
        }
    }

    const onVisibleChangeContextMenuNotiz = async (visible, notiz, forcePut=false) => {
        if (!visible) {
            if (forcePut || Object.keys(contextMenuNotiz).length > 1) {
                const data = {
                    ...notiz,
                    ...contextMenuNotiz
                }

                const response = await callApiAsync({auth, url: notizApi.post(), method: "post", data});
                const neu = {...data, ...response.data.OBJECT};

                const unitId = neu.unit;
                notizContext.setNotizMap(prev => ({...prev, [unitId]: {...prev[unitId], [notiz.id]: neu}}));
            }

            setContextMenuNotiz({});
            setShowContextMenuNotiz(null);
        }
    }

    return !medikationsplanContext.medikationsplan ? <></> : <div>
        {loadingVerknuepfungenPatient ? <h6>Dokumente werden geladen</h6> : <>

            {/****************
            aktuelle Dokumente
            ****************/}

            <h6>Aktuelle Dokumente:</h6>
            {verknuepfungenPatientAktuell && !!verknuepfungenPatientAktuell.length ? <List
                dataSource={verknuepfungenPatientAktuell}
                rowKey={(verknuepfung) => verknuepfung.id}
                renderItem={(verknuepfung) => {
                    let dokument = verknuepfung.dokument;
                    const isHighlighted = Object.keys(dokumenteIdHighlighted).includes(dokument.id+"");

                    return <Popover trigger={"click"} pointerEvents={"initial"} placement={"right"} content={() => <ContextMenuDokumentVerknuepfung verknuepfung={{...verknuepfung, ...contextMenuVerknuepfung}} onChange={setContextMenuVerknuepfung} />} visible={showContextMenuVerknuepfung === verknuepfung.id} onVisibleChange={(visible) => {onVisibleChangeContextMenuVerknuepfung(visible, verknuepfung)}}>
                        <List.Item onClick={() => openDokument(dokument.id, auth, isHighlighted ? `?im=${dokumenteIdHighlighted[dokument.id]}` : "")} style={{cursor: "pointer", backgroundColor: isHighlighted ? "rgb(173,216,230)" : "initial"}} onContextMenu={e => {
                            e.preventDefault();
                            setShowContextMenuVerknuepfung(verknuepfung.id);
                        }}>
                            <span>{`${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}${verknuepfung.gueltigVon ? ` ${verknuepfung.gueltigBis ? "(" : "(ab "}${moment(verknuepfung.gueltigVon).format("DD.MM.YY")}` : ""}${verknuepfung.gueltigBis ? ` ${verknuepfung.gueltigVon ? "- " : "(bis "}${moment(verknuepfung.gueltigBis).format("DD.MM.YY")}` : ""})`}</span>
                            {!verknuepfung.bearbeitet && <Tooltip title={"Dokument ist nicht fertig bearbeitet"}><WarningOutlined style={{color: "darkred"}} /></Tooltip>}
                        </List.Item>
                    </Popover>
                }}

                pagination={pagination1}
            /> : "[Keine vorhanden]"}

            {/*****************
             kommende Dokumente
             ****************/}

            {verknuepfungenPatientKommend && !!verknuepfungenPatientKommend.length && <div style={{marginTop: 20}}>
                <h6>Kommende Dokumente:</h6>
                <List
                    dataSource={verknuepfungenPatientKommend}
                    rowKey={(dokument) => dokument.id}
                    renderItem={(verknuepfung) => {
                        const dokument = verknuepfung.dokument;
                        const isHighlighted = Object.keys(dokumenteIdHighlighted).includes(dokument.id+"");

                        return <Popover trigger={"click"} pointerEvents={"initial"} placement={"right"} content={<ContextMenuDokumentVerknuepfung auth={auth} verknuepfung={{...verknuepfung, ...contextMenuVerknuepfung}} onChange={setContextMenuVerknuepfung} />} visible={showContextMenuVerknuepfung === verknuepfung.id} onVisibleChange={(visible) => {onVisibleChangeContextMenuVerknuepfung(visible, verknuepfung)}}>
                            <List.Item onClick={() => openDokument(dokument.id, auth, isHighlighted ? `?im=${dokumenteIdHighlighted[dokument.id]}` : "")} style={{cursor: "pointer", backgroundColor: isHighlighted ? "rgb(173,216,230)" : "initial"}} onContextMenu={e => {
                                e.preventDefault();
                                setShowContextMenuVerknuepfung(verknuepfung.id);
                            }}>
                                <span className="overflowEllipsis">{`${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}${verknuepfung.gueltigVon ? ` ${verknuepfung.gueltigBis ? "(" : "(ab "}${moment(verknuepfung.gueltigVon).format("DD.MM.YY")}` : ""}${verknuepfung.gueltigBis ? ` ${verknuepfung.gueltigVon ? "- " : "(bis "}${moment(verknuepfung.gueltigBis).format("DD.MM.YY")}` : ""})`}</span>
                                {!verknuepfung.bearbeitet && <Tooltip title={"Dokument ist nicht fertig bearbeitet"}><WarningOutlined style={{color: "darkred"}} /></Tooltip>}
                            </List.Item>
                        </Popover>
                    }}

                    pagination={pagination2}
                />
            </div>}
        </>}

        {/*********************
         unverknüpfte Dokumente
         ********************/}

        {!dokumenteInfoMarkerFiltered.length ? <></> : <div>
            <h6>Dokumente mit Marker (ohne explizite Verknüpfung):</h6>
            <List
                dataSource={dokumenteInfoMarkerFiltered}
                rowKey={(dokument) => dokument.id}
                renderItem={(dokument) => {
                    const isHighlighted = Object.keys(dokumenteIdHighlighted).includes(dokument.id+"");
                    return <List.Item onClick={() => openDokument(dokument.id, auth, isHighlighted ? `?im=${dokumenteIdHighlighted[dokument.id]}` : "")} style={{cursor: "pointer", backgroundColor: isHighlighted ? "rgb(173,216,230)" : "initial"}}>
                        <span className="overflowEllipsis">{`${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}`}</span>
                    </List.Item>
                }}

                pagination={pagination3}
            />
        </div>}

        {/************
            Notizen
         ***********/}

        {!!notizenSorted.length && <div style={{marginTop: 20}}>
            <h6>Notizen:</h6>
            <List
                dataSource={notizenSorted}
                rowKey={(notiz) => notiz.id}
                renderItem={(notiz) => {
                    const heute = moment().startOf('day');
                    const isHighlighted = !!notizIdHighlighted[notiz.id];

                    let isFaellig = false;
                    switch (notiz.type) {
                        case "UNIT":
                            for (let notizUnit of notiz.unitSet) {
                                if (notizUnit.faelligkeit && moment(notizUnit.faelligkeit).isSameOrBefore(heute)) {
                                    isFaellig = true;
                                    break;
                                }
                            }
                            break;
                        case "INTERAKTION":
                            for (let notizInteraktion of notiz.interaktionSet) {
                                if (notizInteraktion.faelligkeit && moment(notizInteraktion.faelligkeit).isSameOrBefore(heute)) {
                                    isFaellig = true;
                                    break;
                                }
                            }
                            break;
                    }

                    // const isBearbeitet = !!(notiz.unitSet || []).filter(u => u.id.unitId === medikationsplanContext.medikationsplan.patient.id)[0]?.bearbeitet;
                    let isBearbeitet = false;
                    switch (notiz.type) {
                        case "UNIT":
                            for (let notizUnit of notiz.unitSet) {
                                if (notizUnit.bearbeitet) {
                                    isBearbeitet = true;
                                    break;
                                }
                            }
                            break;
                        case "INTERAKTION":
                            for (let notizInteraktion of notiz.interaktionSet) {
                                if (notizInteraktion.bearbeitet) {
                                    isBearbeitet = true;
                                    break;
                                }
                            }
                            break;
                    }

                    const triggerClose = notiz => {
                        setShowContextMenuNotiz(null);
                        onVisibleChangeContextMenuNotiz(false, notiz, true);
                    }

                    return <Popover trigger={"click"} pointerEvents={"initial"} placement={"right"}
                             content={<ContextMenuNotiz auth={auth} notiz={notiz} onChange={setContextMenuNotiz} triggerClose={triggerClose} />} visible={showContextMenuNotiz === notiz.id}
                             onVisibleChange={(visible) => {
                                 onVisibleChangeContextMenuNotiz(visible, notiz);
                             }}>
                        <List.Item onClick={e => {
                            notizContext.setNotiz(notiz);
                            notizContext.setPlanzeileAusgewaehlt(null);
                            notizContext.setVisible(true);

                            (notiz.type === "UNIT" && (notiz.unitSet || []).filter(u => u.id.unitId === medikationsplanContext.medikationsplan.patient.id)[0]?.planzeilen || []).forEach(np => notizContext.addPlanzeileAusgewaehlt(""+np.id.planzeile));
                        }} style={{cursor: "pointer", backgroundColor: isHighlighted ? "rgb(173,216,230)" : "initial"}} onContextMenu={e => {
                            e.preventDefault();
                            setShowContextMenuNotiz(notiz.id);
                            setContextMenuNotiz({id: notiz.id});
                        }}>
                            <Tooltip title={isFaellig && "Diese Notiz ist fällig"}><span style={isFaellig ? {backgroundColor: "#ffadd2"} : {}} className="overflowEllipsis">{notiz.titel ? notiz.titel : "Notiz #"+notiz.id}</span></Tooltip>
                            {!isBearbeitet && <Tooltip title={"Notiz ist nicht bearbeitet"}><WarningOutlined style={{color: "darkred"}} /></Tooltip>}
                        </List.Item>
                    </Popover>
                }}

                pagination={pagination4}
            />
        </div>}
    </div>
}

const ContextMenuDokumentVerknuepfung = ({verknuepfung, onChange=()=>{}, triggerClose=(verknuepfung)=>{}}) => {
    const [values, setValues] = useState({});

    const valuesRef = useRef({});

    useEffect(() => {
        setValues(prev => {
            const val = {...verknuepfung};
            for (let key of ['gueltigVon', 'gueltigBis']) {
                val[key] = verknuepfung[key] ? moment(verknuepfung[key]).startOf('day') : null;
            }

            valuesRef.current = val;
            return val;
        });
    }, [verknuepfung])

    const triggerChangeDate = (key, value) => {
        const verknuepfungNew = {...verknuepfung};

        if (valuesRef.current[key] !== value) {
            verknuepfungNew[key] = value?.valueOf();
            setValues(prev => ({...prev, [key]: value}));

            onChange(verknuepfungNew);
        }
    }

    return <div>
        <h6>Gültigkeit</h6>
        <Row>
            <Col span={6}>von:</Col>
            <Col span={18}><DatePicker
                // defaultValue={verknuepfung.gueltigVon ? moment(verknuepfung.gueltigVon) : null}
                value={values.gueltigVon}
                format={"DD.MM.YYYY"}
                // onChange={date => onChange(prev => ({...prev, gueltigVon: date ? date.startOf('day').valueOf() : null}))}
                onChange={date => triggerChangeDate("gueltigVon", date?.startOf('day'))}
            /></Col>
        </Row>
        <Row>
            <Col span={6}>bis:</Col>
            <Col span={18}><DatePicker
                // defaultValue={verknuepfung.gueltigBis ? moment(verknuepfung.gueltigBis) : null}
                value={values.gueltigBis}
                format={"DD.MM.YYYY"}
                // onChange={date => onChange(prev => ({...prev, gueltigBis: date ? date.startOf('day').valueOf() : null}))}
                onChange={date => triggerChangeDate("gueltigBis", date?.startOf('day'))}
            /></Col>
        </Row>
        <Row>
            <Button onClick={() => onChange(prev => ({...prev, bearbeitet: !verknuepfung.bearbeitet}))}>{verknuepfung.bearbeitet ? "als unbearbeitet markieren" : "als bearbeitet markieren"}</Button>
        </Row>
    </div>;
}

const ContextMenuNotiz = ({auth, notiz, onChange=()=>{}, triggerClose=(notiz)=>{}}) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const [notizEdit, setNotizEdit] = useState({});
    const [dataEntry, setDataEntry] = useState(null);
    const [dataEntryIndex, setDataEntryIndex] = useState(-1);

    const [input, setInput] = useState({});
    const [inputInitialized, setInputInitialized] = useState(false);

    useEffect(() => {
        if (notiz) {
            const notizEditNeu = {...notiz};
            let index = -1, dataEntryNeu = null;
            switch (notizEditNeu?.type) {
                case "UNIT":
                    index = notizEditNeu.unitSet.findIndex(u => u.id.unitId === medikationsplanContext.medikationsplan.patient.id);
                    dataEntryNeu = index >= 0 ? notizEditNeu.unitSet[index] : null;
                    break;
                case "INTERAKTION":
                    index = notizEditNeu.interaktionSet.findIndex(i => i.id.unitId === medikationsplanContext.medikationsplan.patient.id);
                    dataEntryNeu = index >= 0 ? notizEditNeu.interaktionSet[index] : null;
                    break;
            }

            const inputNeu = {};
            if (dataEntryNeu) {
                if (dataEntryNeu.gueltigVon) inputNeu.gueltigVon = moment(dataEntryNeu.gueltigVon);
                if (dataEntryNeu.gueltigBis) inputNeu.gueltigBis = moment(dataEntryNeu.gueltigBis);
                inputNeu.bearbeitet = dataEntryNeu.bearbeitet;
            }

            setInputInitialized(false);
            setInput(inputNeu);
            setDataEntryIndex(index);
            setNotizEdit(notizEditNeu);
            setDataEntry(dataEntryNeu);
        } else {
            setInputInitialized(false);
            setNotizEdit({});
            setDataEntry(null);
            setDataEntryIndex(-1);
            setInput({});
        }
    }, [notiz])

    useEffect(() => {
        if (Object.keys(input).length) {
            if (inputInitialized) {
                const entryNeu = {
                    ...(dataEntry || {}),
                    gueltigVon: 'gueltigVon' in input ? (input.gueltigVon ? input.gueltigVon.startOf('day').valueOf() : null) : dataEntry.gueltigVon,
                    gueltigBis: 'gueltigBis' in input ? (input.gueltigBis ? input.gueltigBis.startOf('day').valueOf() : null) : dataEntry.gueltigBis,
                    bearbeitet: 'bearbeitet' in input ? input.bearbeitet : dataEntry.bearbeitet
                };

                onChangeEntry(entryNeu, dataEntry && input.bearbeitet === !dataEntry.bearbeitet);
            } else {
                setInputInitialized(true);
            }
        }
    }, [input])

    const onChangeEntry = (entryNeu, doClose) => {
        const data = {...notizEdit};
        switch (data?.type) {
            case "UNIT":
                if (dataEntryIndex >= 0) {
                    data.unitSet[dataEntryIndex] = entryNeu;
                } else {
                    data.unitSet.push(entryNeu);
                }
                break;
            case "INTERAKTION":
                if (dataEntryIndex >= 0) {
                    data.interaktionSet[dataEntryIndex] = entryNeu;
                } else {
                    data.interaktionSet.push(entryNeu);
                }
                break;
        }

        setDataEntry(entryNeu);
        setNotizEdit(data);

        onChange(data);

        if (doClose) {
            triggerClose(data);
        }
    }

    return <div>
        <h6>Gültigkeit</h6>
        <Row>
            <Col span={6}>von:</Col>
            <Col span={18}><DatePicker
                value={input.gueltigVon}
                format={"DD.MM.YYYY"}
                onChange={date => setInput(prev => ({...prev, gueltigVon: date}))}
            /></Col>
        </Row>
        <Row>
            <Col span={6}>bis:</Col>
            <Col span={18}><DatePicker
                value={input.gueltigBis}
                format={"DD.MM.YYYY"}
                onChange={date => setInput(prev => ({...prev, gueltigBis: date}))}
            /></Col>
        </Row>
        <Row><Button onClick={() => setInput(prev => ({...prev, bearbeitet: !prev.bearbeitet}))}>als {input.bearbeitet ? "unbearbeitet" : "bearbeitet"} markieren</Button></Row>
    </div>;
}