import { format, addDays } from 'date-fns'
import { getNextZeit, dateStringToTimestamp } from '../utilities/myDates'
import moment from "moment";

export const ROLLEN = [
    {
        id: 1,
        name: "Apotheke"
    },
    {
        id: 2,
        name: "Arztpraxis"
    },
    {
        id: 3,
        name: "Pflegeeinrichtung"
    },
    {
        id: 4,
        name: "Patient"
    },
    {
        id: 5,
        name: "Bevollmächtigter"
    },
    {
        id: 6,
        name: "Sonstige"
    },
    {
        id: 7,
        name: "Station"
    },
    {
        id: 8,
        name: "Pflegeheim"
    }
];

export const GRUPPEN = [
    {
        id: 1,
        name: "Heimleitung"
    },
    {
        id: 2,
        name: "Pflegedienstleitung"
    },
    {
        id: 3,
        name: "Pflegefachkraft"
    },
    {
        id: 4,
        name: "Pflegehilfskraft"
    },
    {
        id: 5,
        name: "Apotheker"
    },
    {
        id: 6,
        name: "PTA"
    },
    {
        id: 7,
        name: "PKA"
    },
    {
        id: 8,
        name: "Arzt"
    },
    {
        id: 9,
        name: "Arzthelfer"
    }
];




// Adressbuch / Adressen
export const DEFAULT_ADRESS_TYPE = "Hauptanschrift";

export const defaultAdresse = ({ akteur, type }) => {
    return {
        id: {
            unitID: akteur.id,
            type
        },
        title: akteur.title,
        firstName: akteur.name,
        name: akteur.surname,
    }
};




// Adressbuch / Adressen
export const DEFAULT_KONTAKT_TYPE = "Haupt-Tel";
export const KONTAKT_TYPES = ["Haupt-Email", "Haupt-Tel", "Haupt-Fax"];
export const KONTAKT_TYPES_INSTITUTION = ["Haupt-KIM"];

export const defaultKontakt = ({ akteur, name }) => {
    return {
        id: {
            unitID: akteur.id,
            name
        },
        value: ""
    };
};




// Medikationsplan / Planzeile
export const defaultMedikationsplan = (patient) => {
    return {
        patient,
        active: true,
        periodenDauer: "7",
        verblisterungTag: 1,
        blisterStartTag: 1,
    };
};




export const defaultPlanzeile = (props) => {
    return {
        dosierschema: [{
            start: dateStringToTimestamp(new Date(props.start)),
            wiederholungsTag: 1,
            abgabemuster: [{
                tag: 1,
                zeiten: []
            }]
        }],
    };
};




// Medikationsplan / Planzeile / Dosierschema / Abschnitt
export const defaultAbschnitt = (abschnitt = null) => {
    const lastAbschnitt = (abschnitt && abschnitt.length > 0) ? abschnitt[abschnitt.length - 1] : null;
    const start = (lastAbschnitt && lastAbschnitt.ende) ? dateStringToTimestamp(addDays(new Date(lastAbschnitt.ende), 1)) : new Date();

    return {
        start,
        status: lastAbschnitt ? "Kopie" : "",

        wiederholungsTag: lastAbschnitt ? lastAbschnitt.wiederholungsTag : 1,
        abgabemuster: lastAbschnitt ? lastAbschnitt.abgabemuster.map(abgabeTag => {
            return {
                tag: abgabeTag.tag,
                zeiten: abgabeTag.zeiten.map(einnahmeZeit => {
                    return {
                        zeit: einnahmeZeit.zeit,
                        menge: einnahmeZeit.menge
                    }
                })
            }
        }) : [{
            tag: 1,
            zeiten: []
        }]
    };
};




// Medikationsplan / Planzeile / Dosierschema / Abschnitt / EinnahmeTag
export const defaultTag = (abgabemuster = null) => {
    let nextTag = 1;
    if (Array.isArray(abgabemuster) && abgabemuster.length)
        nextTag = parseInt(abgabemuster[abgabemuster.length - 1].tag) + 1;

    return { tag: nextTag, zeiten: [] }
};




// Medikationsplan / Planzeile / Dosierschema / Abschnitt / EinnahmeZeit
export const defaultZeit = (zeiten = null) => {
    let nextZeit = "05:00";
    if (Array.isArray(zeiten) && zeiten.length)
        nextZeit = zeiten[zeiten.length - 1].zeit;

    return { zeit: getNextZeit(nextZeit), menge: "0" }
};




// Akteur
export const defaultAkteur = (type = "patient") => {
    switch (type) {
        case 'personOhneRolle':
            return {
                "type": "Person",
                "active": true,
                "transitionToGlobalStorage": false
            }
        case 'einrichtungOhneRolle':
            return {
                "type": "Institution",
                "active": true,
                "transitionToGlobalStorage": false
            }
        case 'arzt':
            return {
                "type": "Person",
                "roles": [
                    {
                        "id": 2
                    }
                ],
                "groups": [
                    {
                        "id": 8
                    }
                ],
                "active": true,
                "transitionToGlobalStorage": false
            };

        case 'praxis':
            return {
                "type": "Institution",
                "roles": [
                    {
                        "id": 2
                    }
                ],
                "groups": [
                    {
                        "id": 8
                    }
                ],
                "active": true,
                "transitionToGlobalStorage": false
            };

        case 'heim':
            return {
                "type": "Institution",
                "roles": [
                    {
                        "id": 8
                    }
                ],
                "active": true,
                "transitionToGlobalStorage": false
            };

        case 'patient':
            return {
                "type": "Person",
                "roles": [
                    {
                        "id": 4
                    }
                ],
                "active": true,
                "transitionToGlobalStorage": false
            }

        default:
            return {
                "type": "Person",
                "active": true,
                "transitionToGlobalStorage": false
            }
    }
};

export const getUnitLangname = (unit = null, mode = null) => {
    if (!unit) return "";

    if (!unit.type) {
        return "";
    }

    let retVal = "";
    if (unit.type.toLowerCase() === 'person') {
        if (mode && mode === "arzt") {
            return (unit.title ? unit.title + ' ' : '') + unit.surname;
        }

        if (unit.surname) retVal = unit.surname;
        if (unit.name) {
            if (retVal.length) retVal += ", ";
            retVal += unit.name;
        }
        if (unit.title) retVal = unit.title + " " + retVal;
        return retVal;
    }

    if (unit.type.toLowerCase() === 'institution')
        return unit.name || "";

    if (unit.type.toLowerCase() === 'metaunit')
        return '<' + (unit.name || "") + '>';

    if (unit.type.toLowerCase() === 'blistermachine')
        return '[' + (unit.name || "") + ']';

    return "unbekannter akteurtyp: " + unit.type
};

export const getUnitNameDateOfBirthConcat = (unit = null) => {
    if (!unit) return "";

    return `${unit.surname} ${unit.name} ${unit.dateOfBirth ? format(new Date(unit.dateOfBirth), 'yyyy-MM-dd') : ""}`;
};

export const getAkteurLangname = (akteur) => {
    let langname = "";

    if (akteur.akteurNachname !== "")
        langname += `${akteur.akteurNachname}, `

    langname += akteur.akteurVorname

    if (akteur.dateOfBirth)
        langname += ` (*${moment(akteur.dateOfBirth).format("DD.MM.YY")})`;

    return langname;
}