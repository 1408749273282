import React, {useContext, useRef, useState} from 'react'
import {Table, Menu} from 'antd';
import {daterangeToString} from '../../utilities/dosierschemaUtil';
import { getUnitLangname } from '../../config/entities';
import PopupDokumenteContext from "../../contexts/PopupDokumenteContext";
import {ANSICHT_STANDARD} from "./MedikationsplanZeilen";
import {useOnClickOutside} from "../../utilities/useOnClickOutside";
import usePagination from "../../utilities/usePagination";
import ApiContext from "../../contexts/ApiContext";
import {useApi} from "../../utilities/useApi";

export const Infomarker = ({ infomarker, onClick, onRemove=()=>{} }) => {
    const popupDokumenteContext = useContext(PopupDokumenteContext);
    const api = useApi();

    const [imContextMenu, setImContextMenu] = useState(null);
    const tableRef = useRef();

    useOnClickOutside(() => {
        setImContextMenu(null);
    }, [tableRef])

    const columns = [
        {
            title: 'Patient',
            key: 'patient',
            width: 200,
            render: row => {
                if (!row.planzeile) return "";

                let patient = row.planzeile.medikationsplan?.patient;
                if (!patient) {
                    const json = JSON.parse(row.planzeile.json);
                    const index = api.akteureIdToIndexMap[json.DELETED.PATIENT];
                    if (index === undefined) {
                        api.loadAkteur(json.DELETED.PATIENT);
                    } else {
                        patient = api.akteure[index];
                    }
                }
                const patientId = patient?.id;

                return <a href={`/adressbuch/${patientId}`} target="parent-default">{getUnitLangname(patient)}</a>
            },
        },
        {
            title: 'Planzeile',
            key: 'planzeile',
            width: 200,
            // render: row => (!row.planzeile || !row.planzeile.arzneimittel) ? "" : <a href={`/medikationsplan/${row.planzeile.medikationsplan.id}`} target={"parent-default"}>{row.planzeile.arzneimittel.name}</a>,
            render: im => {
                if (!im.planzeile || !im.planzeile.arzneimittel) return "";

                let medikationsplanId = im.planzeile.medikationsplan?.id;
                if (!medikationsplanId) {
                    const json = JSON.parse(im.planzeile.json);
                    medikationsplanId = +json.DELETED.MEDIKATIONSPLAN;
                }

                return <a href={`/medikationsplan/${medikationsplanId}/${encodeURIComponent(im.ansicht ? im.ansicht : ANSICHT_STANDARD)}/${im.planzeile.id}`} target={"parent-default"}>{im.planzeile.arzneimittel.name}{im.planzeile.medikationsplan ? '' : ' (gelöscht)'}</a>
            },
        },
        {
            title: 'Dosierabschnitt',
            key: 'dosierabschnitt',
            width: 200,
            render: row => {
                if (row.dosierabschnittSet) {
                    return row.dosierabschnittSet.map(d => <p key={d.start}>{daterangeToString(d.start, d.ende)[0]}</p>);
                } else {
                    return "";
                }
            },
        },
        {
            title: 'Kommentar',
            key: 'kommentar',
            width: 200,
            render: row => row.kommentar
        },
        {
            title: "Aktion",
            key: "aktion",
            render: row => <>
                <a onClick={() => onRemove(row)}>löschen</a>
            </>
        },
    ];

    return <div style={{width: 800, position: "relative"}}>
        <h4>Mit diesem Dokument verknüpft:</h4>
        <Table
            pagination={usePagination()}
            rowKey="id"
            columns={columns}
            dataSource={infomarker}

            ref={tableRef}

            onRow={(infoMarker, rowIndex) => {
                return {
                    onClick: (event) => {
                        setImContextMenu(null);
                        popupDokumenteContext.setSelectedInfoMarker(infoMarker);
                        onClick(infoMarker);
                    },

                    onContextMenu: event => {
                        const target = event.target;
                        const boundingClientRect = target.getBoundingClientRect();
                        const clientTop = event.clientY;
                        const clientLeft = event.clientX - boundingClientRect.left;

                        setImContextMenu({infoMarker, clientLeft, clientTop});
                    }
                };
            }}
        />

        {imContextMenu &&
        <div className={"contextMenu"} style={{zIndex: 200, position: "absolute", top: imContextMenu.clientTop, left: imContextMenu.clientLeft, border: "1px solid black"}}>
            <Menu mode="inline">
                <Menu.Item key="1" onClick={e => popupDokumenteContext.setRemoveInfoMarker(imContextMenu.infoMarker)}>Verknüpfung löschen</Menu.Item>
            </Menu>
        </div>
        }

    </div>
};

