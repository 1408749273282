import React, {useEffect, useState} from "react";
import {useDataAccess} from "../../utilities/useDataAccess";
import {useFetch} from "../../utilities/useFetch";
import {akteureApi} from "../../config/apiConfig";
import {List, Select, Switch} from "antd";
import {getUnitLangname} from "../../config/entities";
import {Link} from "react-router-dom";

export const AdressbuchSonstigesArzt = (props) => {
    const akteur = useDataAccess({
        ...props,
    });

    const {data: patientenHausarzt} = useFetch(akteureApi.getByHausarzt(akteur.current));
    const {data: patientenVerordner} = useFetch(akteureApi.getByVerordner(akteur.current));

    const [anforderungReichweite, setAnforderungReichweite] = useState(null);

    useEffect(() => {
        if (akteur.current) {
            const configJsonString = akteur.current.configJsonString;
            const configJson = configJsonString ? JSON.parse(configJsonString) : {};
            setAnforderungReichweite(configJson.MEDIKATIONSPLAN_ANFORDERUNG_BEDARF_TYP || "WOCHEN_12");
        }
    }, [akteur.current])

    const updateAnforderungReichweite = (val) => {
        const configJsonString = akteur.current.configJsonString;
        const configJson = configJsonString ? JSON.parse(configJsonString) : {}
        configJson.MEDIKATIONSPLAN_ANFORDERUNG_BEDARF_TYP = val;

        console.log("neuer Json: ", configJson);
        akteur.handleChange("configJsonString", JSON.stringify(configJson));
    }

    return <div>
        <p>
            <h6>Anforderungen zusammenfassen</h6>
            <Switch checked={akteur.current?.combinePrescriptions} onChange={(value) => akteur.handleChange("combinePrescriptions", value)} />
        </p><p>
            <h6>Reichweite für Menge bei Anforderungen:</h6>
            <Select
                value={anforderungReichweite}
                onChange={updateAnforderungReichweite}
                style={{width: 200}}
            >
                <Select.Option value={"WOCHEN_1"}>1 Woche</Select.Option>
                <Select.Option value={"WOCHEN_2"}>2 Wochen</Select.Option>
                <Select.Option value={"WOCHEN_3"}>3 Wochen</Select.Option>
                <Select.Option value={"WOCHEN_4"}>4 Wochen</Select.Option>
                <Select.Option value={"WOCHEN_12"}>12 Wochen</Select.Option>
                <Select.Option value={"QUARTAL"}>Bis Quartalsende</Select.Option>
                <Select.Option value={"QUARTAL_PLUS"}>Bis Quartalsende+</Select.Option>
            </Select>
        </p>
        
        {patientenHausarzt && <>
            <h6>Hausarzt für:</h6>
            <List
                dataSource={patientenHausarzt}
                rowKey={"id"}
                renderItem={patient => {
                    return <List.Item><Link to={'/adressbuch/' + patient.id} >{getUnitLangname(patient)}</Link></List.Item>;
                }}
            />
        </>}

        {patientenVerordner && <>
            <h6>Verordner für:</h6>
            <List
                dataSource={patientenVerordner}
                rowKey={"id"}
                renderItem={patient => {
                    return <List.Item><Link to={'/adressbuch/' + patient.id} >{getUnitLangname(patient)}</Link></List.Item>;
                }}
            />
        </>}
    </div>
}