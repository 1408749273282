import {createContext, useEffect, useRef, useState} from "react";
import {getSorter} from "../utilities/sortUtil";
import {useApiAnforderungen} from "../utilities/useApiAnforderungen";

const initVal = {
    setLocalStorage: ()=>{},
    dokumenteListeNeu: [],
    setDokumenteListeNeu: val => {},

    dokumenteListeContext: "",
    setDokumenteListeContext: val => {},

    dokumenteListe: [],
    setDokumenteListe: () => {},
    addDokumente: dokumente => {},

    infoMarkerNeu: {},
    setInfoMarkerNeu: infoMarkerNeu => [],
    addInfoMarker: infoMarkerAdd => {},

    clearInfoMarker: () => {},
    removeInfoMarkerIndex: () => {},

    zuletztGespeicherterInfoMarker: {},
    setZuletztGespeicherterInfoMarker: infoMarker => {},
    zuletztGeloeschterInfoMarker: {},
    setZuletztGeloeschterInfoMarker: infoMarker => {},

    putDokument: (dokument, change) => {},
    removeDokumentId: dokumentId => {},
    dokumentUpdated: null,

    setDokumentOpened: (dokumentId, windowName) => {},
    setDokumentClosed: dokumentId => {},
    setWindowClosed: windowName => {},
    getWindowNameForDokumentId: dokumentId => {},
    getGeoeffneteDokumente: () => [],

    highlightInfoMarker: {},
    setHighlightInfoMarker: infoMarker => {},

    isPDF: false,
    setIsPDF: isPDFNew => false,
};

const DokumenteContext = createContext(initVal);
export default DokumenteContext;

export const useInitialState = () => {
    const [dokumenteListe, setDokumenteListe] = useState([]);
    const [dokumenteListeContext, setDokumenteListeContext] = useState("neu");
    const [dokumenteAllMap, setDokumenteAllMap] = useState({});
    const [dokumenteListeMap, setDokumenteListeMapState] = useState({});
    const [dokumenteListeNeu, setDokumenteListeNeuState] = useState([]);

    const [infoMarkerNeu, setInfoMarkerNeuState] = useState([]);
    const infoMarkerNeuRef = useRef([]);

    const [zuletztGespeicherterInfoMarker, setZuletztGespeicherterInfoMarkerState] = useState(null);
    const [zuletztGeloeschterInfoMarker, setZuletztGeloeschterInfoMarkerState] = useState(null);
    const [dokumenteViewerMap, setDokumenteViewerMapState] = useState({});
    const [highlightInfoMarker, setHighlightInfomarkerState] = useState(null);
    const [dokumenteContextTransfer, setDokumenteContextTranferState] = useState({});
    const [dokumentUpdated, setDokumentUpdatedState] = useState({});

    const [isPDF, setIsPDF] = useState(false);

    useEffect(() => {
        const handler = () => {
            getLocalStorage();
        };

        handler();
        window.addEventListener('storage', () => {
            getLocalStorage();
        });
        return () => {
            clearLocalStorage();
            window.removeEventListener('storage', () => {
                getLocalStorage();
            });
        }
    }, []);

    useEffect(() => {
        if (dokumenteContextTransfer && JSON.stringify(dokumenteContextTransfer.dokumentUpdated) !== JSON.stringify(dokumentUpdated)) {
            const dokumenteUpdatedNeu = dokumenteContextTransfer.dokumentUpdated || {};
            setDokumentUpdatedState(dokumenteUpdatedNeu);

            if (Object.keys(dokumenteUpdatedNeu).length && dokumenteUpdatedNeu.updateInList && dokumenteUpdatedNeu.change) {
                const dokument = dokumenteUpdatedNeu.neu;
                let dokumentAlt = (dokumenteListeMap[dokumenteListeContext] || []).filter(dok => dok.id !== dokument.id);

                if (!!dokumentAlt) for (let key of dokumenteUpdatedNeu.change) {
                    dokumentAlt[key] = dokument[key];
                }

                const mapNeu = {...dokumenteListeMap, [dokumenteListeContext]: dokumenteListeMap[dokumenteListeContext] || []};
                setDokumenteListeMap(mapNeu);
            }
        }
    }, [dokumenteContextTransfer])

    useEffect(() => {
        setDokumenteListe(dokumenteListeMap[dokumenteListeContext] || []);
    }, [dokumenteListeContext, dokumenteListeMap])

    useEffect(() => {
        setDokumenteListeNeuState(dokumenteListeMap.neu || []);
    }, [dokumenteListe])

    const setLocalStorage = () => {
        localStorage.setItem('dokumenteMap', JSON.stringify(dokumenteListeMap));
    }

    const clearLocalStorage = () => {
        // localStorage.removeItem('dokumenteMap');
        localStorage.removeItem('zuletztGespeicherterInfoMarker');
        localStorage.removeItem('zuletztGeloeschterInfoMarker');
        localStorage.removeItem("window-infomarker");
        localStorage.removeItem("window-infomarker-clear");
        localStorage.removeItem("dokumenteContextTransfer");
    }

    const getLocalStorage = () => {
        // alle Daten aus localStorage auslesen
        const jsonDokumenteViewerMap = localStorage.getItem('dokumenteViewerMap');
        const jsonInfoMarkerList = localStorage.getItem('infoMarkerList');
        // const jsonDokumenteMap = localStorage.getItem('dokumenteMap');
        const jsonZuletztGespeicherterInfoMarker = localStorage.getItem('zuletztGespeicherterInfoMarker');
        const jsonZuletztGeloeschterInfoMarker = localStorage.getItem('zuletztGeloeschterInfoMarker');
        const jsonWindowToInfoMarkerObj = localStorage.getItem("window-infomarker");
        const jsonWindowToInfoMarkerClearObj = localStorage.getItem("window-infomarker-clear");
        const dokumenteContextTransferString = localStorage.getItem("dokumenteContextTransfer");

        // localStorage leeren
        clearLocalStorage();

        const dokumenteViewerMap = jsonDokumenteViewerMap ? JSON.parse(jsonDokumenteViewerMap) : {};
        setDokumenteViewerMapState(dokumenteViewerMap);

        const infoMarkerListObj = jsonInfoMarkerList ? JSON.parse(jsonInfoMarkerList).filter(infoMarker => {
            return Object.values(dokumenteViewerMap).includes(`dokumenteViewer-dokument`);
        }) : [];

        setInfoMarkerNeu(infoMarkerListObj);

        if (dokumenteContextTransferString) {
            const dokumenteContextTransfer = JSON.parse(dokumenteContextTransferString);
            setDokumenteContextTranferState(dokumenteContextTransfer);
        }

        if (jsonZuletztGespeicherterInfoMarker) {
            setZuletztGespeicherterInfoMarkerState(JSON.parse(jsonZuletztGespeicherterInfoMarker));
        }

        if (jsonZuletztGeloeschterInfoMarker) {
            setZuletztGeloeschterInfoMarkerState(JSON.parse(jsonZuletztGeloeschterInfoMarker));
        }

        if (jsonWindowToInfoMarkerClearObj) {
            setHighlightInfomarkerState(null);
        } else if (window.name && jsonWindowToInfoMarkerObj) {
            const windowToInfoMarkerObj = JSON.parse(jsonWindowToInfoMarkerObj);
            if (windowToInfoMarkerObj.windowName === window.name) {
                setHighlightInfomarkerState(windowToInfoMarkerObj.infoMarker);
            }
        }
    };

    const setDokumenteContextTransfer = dataNew => {
        const jsonDataNew = JSON.stringify(dataNew);
        if (jsonDataNew === JSON.stringify(dokumenteContextTransfer)) {
            return;
        }

        localStorage.setItem('dokumenteContextTransfer', jsonDataNew);
        setDokumenteContextTranferState(dataNew);
    }

    const setDokumenteListeMap = map => {
        if (map && dokumenteListeMap && JSON.stringify(map) === JSON.stringify(dokumenteListeMap)) return;

        if (map[dokumenteListeContext] && map[dokumenteListeContext].length) {
            map[dokumenteListeContext] = map[dokumenteListeContext].sort(getSorter("dokument"));
        }

        setDokumenteListeMapState(map);

        const dokumenteAllMapNeu = {};
        for (let key of Object.keys(map)) {
            const dokumente = map[key] || [];
            for (let index=0; index<dokumente.length; index++) {
                const dokument = dokumente[index];
                dokumenteAllMapNeu[dokumente.id] = {dokument, key, index};
            }
        }
        setDokumenteAllMap(dokumenteAllMapNeu);
    };

    const setDokumenteListeNeu = dokumente => {
        if (JSON.stringify(dokumente) === JSON.stringify(dokumenteListeMap.neu)) {
            return;
        }

        setDokumenteListeMap({...dokumenteListeMap, neu: dokumente});
    }

    const setInfoMarkerNeu = liste => {
        const jsonListe = JSON.stringify(liste);
        if (jsonListe !== JSON.stringify(infoMarkerNeuRef.current)) {
            infoMarkerNeuRef.current = liste;
            setInfoMarkerNeuState(liste);
            if (liste && liste.length) {
                localStorage.setItem('infoMarkerList', jsonListe);
            } else {
                localStorage.removeItem('infoMarkerList');
            }
        }
    };

    const addDokumente = dokumenteNeu => {
        if (!Array.isArray(dokumenteNeu)) dokumenteNeu = [dokumenteNeu];
        const dokumenteListeNeu = [...dokumenteNeu, ...(dokumenteListeMap[dokumenteListeContext] || [])].sort(getSorter("dokument"));
        setDokumenteListeMap({...dokumenteListeMap, [dokumenteListeContext]: dokumenteListeNeu});
    };

    const setDokumentUpdated = (updatedObj) => {
        const dokumenteContextTransferNeu = {...dokumenteContextTransfer};

        if (updatedObj) {
            if (updatedObj.alt && updatedObj.alt.verknuepfungen) {
                updatedObj.alt.verknuepfungen.forEach(v => v.dokumentId = updatedObj.alt.id);
            }
            if (updatedObj.neu && updatedObj.neu.verknuepfungen) {
                updatedObj.neu.verknuepfungen.forEach(v => v.dokumentId = updatedObj.neu.id);
            }

            dokumenteContextTransferNeu.dokumentUpdated = updatedObj;
        }
        else delete dokumenteContextTransferNeu.dokumentUpdated;

        setDokumenteContextTransfer(dokumenteContextTransferNeu);
    }

    const putDokument = (dokument, change) => {
        // Aktualisierte die Dokumente-Liste, wenn im Hauptfenster
        if ("parent-default" === window.name) {
            let dokumentAlt = null;

            const dokumenteNeu = [...(dokumenteListeMap[dokumenteListeContext] || []).filter(dok => {
                if (dok.id === dokument.id) {
                    dokumentAlt = dok;
                    return false;
                }

                return true;
            })];

            if (dokumenteListeContext !== "neu" || !dokument.verknuepfungen.filter(v => v.typ === "PATIENT").length) {
                dokumenteNeu.push(dokument);
            }

            dokumenteNeu.sort(getSorter("dokument"));

            const mapNeu = {...dokumenteListeMap, [dokumenteListeContext]: dokumenteNeu};

            setDokumenteListeMap(mapNeu);

            const dokumentUpdatedNeu = {alt: dokumentAlt, neu: dokument, change};
            setDokumentUpdated(dokumentUpdatedNeu);
        }

        // Sende neues Dokument an Hauptfenster, wenn nicht in Hauptfenster
        else {
            const dokumentUpdatedNeu = {neu: dokument, updateInList: true, change};
            setDokumentUpdated(dokumentUpdatedNeu);
        }
    }

    const removeDokumentId = dokumentId => {
        let removedIndex = -1;
        const dokumenteNeu = [...(dokumenteListeMap[dokumenteListeContext] || []).filter((dok, i) => {
            if (dok.id === dokumentId) {
                removedIndex = i;
                return false;
            }

            return true;
        })].sort(getSorter("dokument"));

        if (removedIndex > -1) {
            setDokumenteListeMap({...dokumenteListeMap, [dokumenteListeContext]: dokumenteNeu});
            setDokumentUpdated({alt: dokumenteListe[removedIndex]});
        }
    }

    const addInfoMarker = infoMarkerAdd => {
        const neu = [...infoMarkerNeu, infoMarkerAdd];
        console.log("adding new infoMarker: ", neu);
        setInfoMarkerNeu(neu);
    };

    const clearInfoMarker = () => {
        infoMarkerNeuRef.current = [];
        setInfoMarkerNeuState([]);
        localStorage.removeItem('infoMarkerList');
    };

    const removeInfoMarkerIndex = index => {
        const newArray = [...infoMarkerNeu];
        const splice = newArray.splice(index, 1);
        setInfoMarkerNeu(newArray);

        return splice[0];
    };

    const setZuletztGespeicherterInfoMarker = infoMarker => {
        localStorage.setItem('zuletztGespeicherterInfoMarker', JSON.stringify(infoMarker));
        setZuletztGespeicherterInfoMarkerState(infoMarker);
    };

    const setZuletztGeloeschterInfoMarker = infoMarker => {
        localStorage.setItem('zuletztGeloeschterInfoMarker', JSON.stringify(infoMarker));
        setZuletztGeloeschterInfoMarkerState(infoMarker);
    };

    const setDokumenteViewerMap = stateNew => {
        setDokumenteViewerMapState(stateNew);

        if (stateNew && Object.keys(stateNew).length) localStorage.setItem("dokumenteViewerMap", JSON.stringify(stateNew));
        else localStorage.removeItem("dokumenteViewerMap");
    }

    const setDokumentOpened = (dokumentId, windowName) => {
        const dokumenteViewerMapNew = {...dokumenteViewerMap};
        dokumenteViewerMapNew[dokumentId] = windowName;

        setDokumenteViewerMap(dokumenteViewerMapNew);
    }

    const setDokumentClosed = (dokumentId) => {
        const dokumenteViewerMapNew = {...dokumenteViewerMap};
        delete dokumenteViewerMapNew[dokumentId];

        setDokumenteViewerMap(dokumenteViewerMapNew);
    }

    const setWindowClosed = (windowName) => {
        const dokumenteViewerMapNew = {};
        Object.keys(dokumenteViewerMap).forEach(key => {
            if (dokumenteViewerMap[key] && dokumenteViewerMap[key] !== windowName) dokumenteViewerMapNew[key] = dokumenteViewerMap[key]
        });

        setDokumenteViewerMap(dokumenteViewerMapNew);
    }

    const getWindowNameForDokumentId = (dokumentId) => {
        return dokumenteViewerMap[dokumentId];
    }

    const setHighlightInfoMarker = infoMarker => {
        if (infoMarker) {
            const windowName = dokumenteViewerMap[infoMarker.dokument.id];
            localStorage.setItem("window-infomarker", JSON.stringify({windowName, infoMarker}));
        } else {
            localStorage.setItem("window-infomarker-clear", "true");
        }
    }

    const getGeoeffneteDokumente = () => {
        return Object.keys(dokumenteViewerMap);
    }

    return {
        setLocalStorage,
        dokumenteListeNeu,
        setDokumenteListeNeu,

        dokumenteListeContext,
        setDokumenteListeContext,

        dokumenteListe,
        setDokumenteListe,
        addDokumente,

        infoMarkerNeu,
        setInfoMarkerNeu,
        addInfoMarker,
        clearInfoMarker,

        removeInfoMarkerIndex,
        zuletztGespeicherterInfoMarker,
        setZuletztGespeicherterInfoMarker,
        zuletztGeloeschterInfoMarker,
        setZuletztGeloeschterInfoMarker,

        putDokument,
        removeDokumentId,
        dokumentUpdated,

        setDokumentOpened,
        setDokumentClosed,
        setWindowClosed,
        getWindowNameForDokumentId,
        getGeoeffneteDokumente,

        highlightInfoMarker,
        setHighlightInfoMarker,

        isPDF,
        setIsPDF,
    };
};