import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Input, Button, Alert } from 'antd';
import { useAuth } from '../../utilities/useAuth'
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {LoginOutlined} from "@ant-design/icons";

export const LoginGrowl = ({ onSuccess = null }) => {
    const [username, setusername] = useState("")
    const [password, setpassword] = useState("")

    const auth = useAuth()

    const handleSubmit = e => {
        e.preventDefault()
        auth.handleLogin(username, password, false)
        if (onSuccess)
            onSuccess()

    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Input
                    name="username"
                    autoComplete="username"
                    value={username} onChange={e => setusername(e.target.value)}
                    placeholder="Benutzername"
                    suffix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <Input.Password
                    autoComplete="current-password"
                    name="current-password"
                    value={password} onChange={e => setpassword(e.target.value)}
                    placeholder="Passwort"
                />
                <Col sm="2"><Button type="primary" icon={<LoginOutlined />} htmlType="submit" loading={auth.loading} >anmelden</Button></Col>
            </Form>
        </div>
    )
}


