import React, {useEffect, useState} from "react";
import {useAuth} from "../../utilities/useAuth";
import {useDataAccess} from "../../utilities/useDataAccess";
import {Link} from "react-router-dom";
import {List} from "antd";
import {AkteurPicker} from "../atoms/AkteurPicker";
import {Fallback} from "../../config/fallbackPages";
import {getUnitLangname} from "../../config/entities";
import {useFetch} from "../../utilities/useFetch";
import {akteureApi, dokumenteApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import useDateiVorschau from "../../utilities/useDateiVorschau";

export const AdressbuchSonstigesPatient = (props) => {
    const akteur = useDataAccess({
        ...props,
    });

    const {data: verordner} = useFetch(akteureApi.getVerordnerByPatient(akteur.current ? akteur.current.id : null));

    useEffect(() => {
        if (akteur.current) {

        }
    }, [akteur.current])

    if (!akteur.isReady)
        return Fallback(akteur);

    return <div>
        <h6>Verknüpfungen</h6>

        <div>
            {akteur.current.careFacility ?
                <Link to={'/adressbuch/' + akteur.current.careFacility.id} >Pflegeheim:</Link> : "Pflegeheim:"
            }

            {!!akteur.current.active ? <AkteurPicker
                display={"inline-block"}
                insitution={true}
                value={akteur.current.careFacility}
                onChange={e => akteur.handleChange('careFacility', e)}
                inputStyle={{width: 600}}
            /> : <span style={{marginLeft: 5}}>{getUnitLangname(akteur.current.careFacility) || "Kein Eintrag"}</span>}
        </div>


        <div>
            {akteur.current.station ?
                <Link to={'/adressbuch/' + akteur.current.station.id} >Station:</Link> : "Station:"
            }

            {!!akteur.current.active ? <AkteurPicker
                display={"inline-block"}
                insitution={true}
                value={akteur.current.station}
                onChange={e => akteur.handleChange('station', e)}
                inputStyle={{width: 600}}
            /> : <span style={{marginLeft: 5}}>{getUnitLangname(akteur.current.station) || "Kein Eintrag"}</span>}
        </div>


        <div>
            {akteur.current.hausarzt ?
                <Link to={'/adressbuch/' + akteur.current.hausarzt.id} >Hausarzt:</Link> : "Hausarzt:"
            }

            {!!akteur.current.active ? <AkteurPicker
                display={"inline-block"}
                value={akteur.current.hausarzt}
                onChange={e => akteur.handleChange('hausarzt', e)}
                inputStyle={{width: 600}}

                erstelleNeu={{ select: [
                    {type: "arzt", label: "Person", tooltip: "Erstellt einen neuen Eintrag mit angegebenem Namen im Format [Nachname, Vorname]"},
                    {type: "praxis", label: "Praxis", tooltip: "Erstellt einen neuen Eintrag mit angegebener Bezeichnung"}
                ]}}
            /> : <span style={{marginLeft: 5}}>{getUnitLangname(akteur.current.hausarzt) || "Kein Eintrag"}</span>}
        </div>

        {!!verordner?.length && <>
            <h6 style={{marginTop: 10}}>Verordnende Ärzte:</h6>
            <List
                dataSource={verordner}
                rowKey={"id"}
                renderItem={verordner => <Link to={'/adressbuch/' + verordner.id}>{getUnitLangname(verordner)}</Link>}
            />
        </>}
    </div >
}