import React, {useContext, useEffect, useRef, useState} from 'react'

import {Badge, Button, Table, Row, Col, notification, Collapse} from 'antd';
import {Link} from 'react-router-dom';
import {getUnitLangname} from '../../config/entities';

import moment, {now} from 'moment';
import 'moment/locale/de'
import {useAuth} from "../../utilities/useAuth";
import {anforderungApi, planzeilenApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {
    erstelleAnforderung,
    getAnforderungDataAsync,
    RezeptanforderungModal
} from "../molecules/RezeptanforderungModal";
import {useHistory} from "react-router-dom";
import {useApi} from "../../utilities/useApi";
import {ModusRezeptanforderung, Rezeptanforderungen} from "./Rezeptanforderungen";
import NotizTable from "../molecules/NotizTable";
import {getSorter} from "../../utilities/sortUtil";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {postErstelleAnforderung} from "../../config/postApiConfiguration";
import usePagination from "../../utilities/usePagination";
import {getCurrentZeitraumColor, getCurrentZeitraumTyp, getZeitraumColor} from "../../utilities/useApiZeitraum";
import {naechsterWochentag} from "../../utilities/myDates";
import {DOCUMENT_TYPES} from "../molecules/DokumentListeTabelle";
import NotizContext from "../../contexts/NotizContext";
import UnitLangname from "../atoms/UnitLangname";
import UnitCareFacilityLangname from "../atoms/UnitCareFacilityLangname";
import UnitStationLangname from "../atoms/UnitStationLangname";

moment.locale('de');


export const Dashboard = () => {
    const auth = useAuth();
    const api = useApi();
    const history = useHistory();

    const notizContext = useContext(NotizContext);

    const planzeilenAnforderungApiAufgerufen = useRef([]);
    const [planzeileAnforderungsdatumMap, setPlanzeileAnforderungsdatumMap] = useState({});

    const planzeilenBedarfAnforderungApiAufgerufen = useRef([]);
    const [planzeileBedarfAnforderungMap, setPlanzeileBedarfAnforderungMap] = useState({});

    const [rezeptanforderungModalData, setRezeptanforderungModalData] = useState(null);

    const [erstelleAnforderungen, setErstelleAnforderungen] = useState(false);
    const [heute, setHeute] = useState(moment().startOf('day'));
    const [naesterSonntag, setNaesterSonntag] = useState(naechsterWochentag(7));

    const [createAllAnforderungenCreated, setCreateAllAnforderungenCreated] = useState(0);
    const [createAllAnforderungenSkipped, setCreateAllAnforderungenSkipped] = useState(0);

    const refreshInterval = useRef(null);

    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();

    const today = useRef(moment().startOf('day'));

    const loadData = () => {
        if (auth.token) {
            api.loadAnforderungenOffen(false);

            api.loadPlanzeilenAnforderungenNoetig(naesterSonntag, false);
            // api.loadPlanzeilenAnforderungenMoeglich(false);
            api.loadPlanzeilenVorab(false);
            api.loadPlanzeilenFreigabeFehlt(false);
            api.loadPlanzeilenUnklar(false);

            api.loadVerknuepfungenUnbearbeitet(false);
            api.loadNotizenUnbearbeitet(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        refreshInterval.current = setInterval(loadData, 30000);
        return () => clearInterval(refreshInterval.current);
    }, [auth.token])

    useEffect(() => {
        if (api.anforderungenNoetig) {
            for (let planzeile of api.anforderungenNoetig) {
                getBedarfAnforderung(planzeile);
                getAnforderungsdatumViaApi(planzeile);
            }
        }
    }, [api.anforderungenNoetig])

    useEffect(() => {
        if (api.anforderungenMoeglich) {
            for (let planzeile of api.anforderungenMoeglich) {
                getBedarfAnforderung(planzeile);
                getAnforderungsdatumViaApi(planzeile);
            }
        }
    }, [api.anforderungenMoeglich])

    useEffect(() => {
        if (api.planzeilenVorab) {
            for (let planzeile of api.planzeilenVorab) {
                if (!planzeile.medikationsplan) {
                    planzeile.medikationsplan = {patient: {}};
                    console.log(`Vorab zu Planzeile ${planzeile.id} hat keinen Medikationsplan verknüpft - json: `, JSON.parse(planzeile.json));
                }

                getBedarfAnforderung(planzeile);
            }
        }
    }, [api.planzeilenVorab])

    useEffect(() => {
        if (api.notizenUnbearbeitet) {

        }
    }, [api.notizenUnbearbeitet])

    const getAnforderungsdatumViaApi = (planzeile) => {
        if (!planzeileAnforderungsdatumMap[planzeile.id] && !planzeilenAnforderungApiAufgerufen.current.includes(planzeile.id)) {
            planzeilenAnforderungApiAufgerufen.current.push(planzeile.id);

            const url = anforderungApi.getDatumFuerAnforderung(planzeile.id);
            callApiAsync({auth, url})
                .then(response => setPlanzeileAnforderungsdatumMap(prev => {
                    const neu = {...prev};
                    neu[planzeile.id] = moment(response.data.OBJECT);

                    return neu;
                }));
        }
    }

    const getBedarfAnforderung= (planzeile) => {
        if (!planzeileBedarfAnforderungMap[planzeile.id] && !planzeilenBedarfAnforderungApiAufgerufen.current.includes(planzeile.id)) {
            planzeilenBedarfAnforderungApiAufgerufen.current.push(planzeile.id);

            const url = planzeilenApi.getBedarfAnforderungen(planzeile.id);
            callApiAsync({auth, url})
                .then(response => setPlanzeileBedarfAnforderungMap(prev => {
                    const neu = {...prev};
                    neu[planzeile.id] = response.data.OBJECT;

                    return neu;
                }));
        }
    }

    const openRezeptanforderungModal = async (planzeile, callback=()=>{}, isVorab=false) => {
        const anforderungData = await getAnforderungDataAsync(planzeile, auth, null, planzeileBedarfAnforderungMap[planzeile.id], isVorab);

        if (anforderungData && anforderungData.length) {
            const rezeptanforderungModalDataNeu = {
                planzeile: planzeile,
                visible: true,
                onCancel: () => setRezeptanforderungModalData(null),
                onOk: callback,
                hausarzt: planzeile.medikationsplan.patient.hausarzt,
                verordner: planzeile.verordner,
                arzneimittel: planzeile.arzneimittel,
                bedarf: planzeile.bedarfBestand,
                anforderungData,
            };

            setRezeptanforderungModalData(rezeptanforderungModalDataNeu);
        } else if (anforderungData) {
            notification.error({message: "Die Daten für die Anforderung konnten nicht ausgewertet werden."})
        }
    }

    const createAllAnforderungen = async planzeilen => {
        if (planzeilen.length) {
            setErstelleAnforderungen(true);
            const promises = [];

            for (let planzeile of planzeilen) {
                promises.push(new Promise(async resolve => {
                    const anforderungData = await getAnforderungDataAsync(
                        planzeile,
                        auth,
                        null,
                        planzeileBedarfAnforderungMap[planzeile.id],
                        false
                    );

                    const anforderung = await erstelleAnforderung({anforderungData, api, auth, notizContext});
                    if (anforderung) {
                        await callApiAsync({auth, url: postErstelleAnforderung(), method: "post", data: anforderung});
                        setCreateAllAnforderungenCreated(prev => prev + 1);
                    } else {
                        setCreateAllAnforderungenSkipped(prev => prev + 1);
                    }

                    resolve();
                }));
            }

            promises.push(api.loadPlanzeilenAnforderungenNoetig(naesterSonntag));
            promises.push(api.loadAnforderungenGesammelt());

            await Promise.all(promises);
            setErstelleAnforderungen(false);
        }
    }

    const gotoAdressbuch = (unitId, tab) => {
        history.push(`/adressbuch/${unitId}${tab ? "/"+tab : ""}`);
    }

    const handleVerknuepfungUnit = async (unit) => {
        gotoAdressbuch(unit.id, "dokumente");
    }

    const columnsReichweite = (dataSource, removeEntry=()=>{}) => [
        {
            title: 'Patient',
            key: 'patient',
            render: (planzeile) => {
                let patient = planzeile.medikationsplan?.patient;
                let patientId = patient?.id || JSON.parse(planzeile.json)?.DELETED?.PATIENT;

                return <Link to={'/medikationsplan/' + planzeile.medikationsplan.id} style={{color: "black"}}><pre>
                    <UnitLangname unit={patient} unitId={patientId} />
                    <span style={{fontSize: 10}}><UnitCareFacilityLangname unitId={patientId} /></span>
                    <span style={{fontSize: 10}}><UnitStationLangname unitId={patientId} /></span>
                </pre></Link>
            },

            onCell: (planzeile) => {
                const result = { style: {} };

                const zeitraumMap = api.unitIdToZeitraumMapMap[planzeile.medikationsplan.patient.id];
                if (!zeitraumMap) api.getZeitraumMapByMedikationsplan(planzeile.medikationsplan.id);
                else {
                    result.style.backgroundColor = getCurrentZeitraumColor(zeitraumMap, 0.5);
                }

                return result;
            },

            sorter: getSorter("planzeile", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, planzeile) => {
                    const patient = planzeile?.medikationsplan?.patient;
                    const patientId = patient?.id || JSON.parse(planzeile.json)?.DELETED?.PATIENT;

                    const isInAPI = patientId in api.akteureIdToIndexMap;

                    return getUnitLangname(patient)?.toLowerCase().includes(value?.toLowerCase()) ||
                        (isInAPI && (
                            getUnitLangname(api.akteure[api.akteureIdToIndexMap[patientId]]?.careFacility)?.toLowerCase().includes(value?.toLowerCase()) ||
                            getUnitLangname(api.akteure[api.akteureIdToIndexMap[patientId]]?.station)?.toLowerCase().includes(value?.toLowerCase())
                        ))
                }}),
            defaultSortOrder: "ascend"
        },
        {
            title: 'Arzneimittel',
            key: 'arzneimittel',
            render: (planzeile) => getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext),

            sorter: getSorter("planzeile", "arzneimittel"),
            ...getColumnSearchProps({dataIndex: "arzneimittel", onFilter: (value, planzeile) => getArzneimittelLangname(planzeile.arzneimittel).toLowerCase().includes(value.toLowerCase())})
        },
        {
            title: 'Reichweite',
            key: 'reichweite',
            render:
                (row) => {
                    const date = moment(row.reichweiteAnspruch);
                    const diff = Math.max(date.diff(today, "weeks"), 0);

                    return <div>
                        {date.format("DD.MM.YYYY")}<br/>
                        {!!diff && <i>({diff} Woche{diff > 1 && 'n'})</i>}
                    </div>
                },

            sorter: getSorter("planzeile", "reichweite"),
            ...getColumnDateProps("reichweite"),
        },
        {
            title: 'Rezept benötigt bis',
            key: 'datumRezeptBenoetigtBis',
            render:
                (row) => {
                    const date = moment(row.datumRezeptBenoetigtBis);
                    const diff = Math.max(date.diff(today, "weeks"), 0);

                    return <div>
                        {date.format("DD.MM.YYYY")}<br/>
                        {!!diff && <i>({diff} Woche{diff > 1 && 'n'})</i>}
                    </div>
                },

            sorter: getSorter("planzeile", "datumRezeptBenoetigtBis"),
            ...getColumnDateProps("datumRezeptBenoetigtBis"),
        },
        {
            title: 'Anforderung ab',
            key: 'datumAnforderung',
            render:
                (row) => <div>
                    {moment(row.datumAnforderung).format("DD.MM.YYYY")}
                </div>,

            sorter: getSorter("planzeile", "datumAnforderung"),
            ...getColumnDateProps("datumAnforderung"),
        },
        // {
        //     title: `Bedarf`,
        //     key: 'bedarf',
        //     render: (planzeile) => {
        //         let planzeileBedarfAnforderungTyp = "WOCHEN_12";
        //         if (planzeile.verordner) {
        //             const configJsonString = planzeile.verordner.configJsonString;
        //             if (configJsonString) {
        //                 const configJson = JSON.parse(configJsonString);
        //                 if (configJson) {
        //                     planzeileBedarfAnforderungTyp = configJson.MEDIKATIONSPLAN_ANFORDERUNG_BEDARF_TYP;
        //                 }
        //             }
        //         }
        //
        //         return "K/A";
        //     }
        // },
        {
            title: 'Empfohlener Arzt',
            key: 'verordner',
            render: (planzeile) => {
                if (planzeile.verordner)
                    return <span>
                        <Link to={'/adressbuch/' + planzeile.verordner.id}><b style={{color: "black"}}>{getUnitLangname(planzeile.verordner, 'arzt')}</b></Link><br/>
                        (Verordner)
                    </span>

                else if (planzeile.medikationsplan.patient.hausarzt)
                    return <span>
                        <Link to={'/adressbuch/' + planzeile.medikationsplan.patient.hausarzt.id}><b style={{color: "black"}}>{getUnitLangname(planzeile.medikationsplan.patient.hausarzt, 'arzt')}</b></Link><br/>
                        (Hausarzt)
                    </span>

                else return <span><b>kein Arzt hinterlegt!</b></span>
            },

            onCell: (planzeile) => {
                const result = { style: {} };

                const unitId = planzeile?.verordner?.id || planzeile?.medikationsplan?.patient?.hausarzt?.id;
                if (unitId) {
                    const zeitraumMap = api.unitIdToZeitraumMapMap[unitId];
                    if (!zeitraumMap) api.getZeitraumMapByUnit(unitId);
                    else {
                        let currentZeitraumColor;

                        const currentZeitraumTyp = getCurrentZeitraumTyp(zeitraumMap, null, 14);
                        switch (currentZeitraumTyp) {
                            case "U_B":
                                if (planzeile.datumAnforderungVorverlegt) {
                                    currentZeitraumColor = "rgba(0, 125, 255, 0.5)";
                                    break;
                                }
                            case "U":
                                currentZeitraumColor = getZeitraumColor(currentZeitraumTyp, 0.5);
                        }
                        if (currentZeitraumColor) result.style.backgroundColor = currentZeitraumColor;
                    }
                }

                return result;
            },

            sorter: getSorter("planzeile", "arzt"),
            ...getColumnSearchProps({dataIndex: "arzt", onFilter: (value, planzeile) => getUnitLangname(planzeile.verordner || planzeile.medikationsplan.patient.hausarzt).toLowerCase().includes(value.toLowerCase())}),
        },{
            title: "Aktionen",
            key: "aktionen",
            render: (planzeile) => <div>
                <Button onClick={() => openRezeptanforderungModal(planzeile, () => {
                    notification.info({message: "Die Anforderung wurde erstellt."});
                    setRezeptanforderungModalData(null);
                    removeEntry(planzeile);
                })}>Anforderung erstellen</Button>
            </div>
        }

    ]

    const columnsVorab = (dataSource, removeEntry=()=>{}) => [
        {
            title: 'Patient',
            key: 'patient',
            render: (planzeile) => <Link
                to={'/medikationsplan/' + planzeile.medikationsplan.id}>{getUnitLangname(planzeile.medikationsplan.patient)}</Link>,

            onCell: (planzeile) => {
                const result = { style: {} };

                const zeitraumMap = api.unitIdToZeitraumMapMap[planzeile.medikationsplan.patient.id];
                if (!zeitraumMap) api.getZeitraumMapByMedikationsplan(planzeile.medikationsplan.id);
                else result.style.backgroundColor = getCurrentZeitraumColor(zeitraumMap, 0.5);

                return result;
            },

            sorter: getSorter("planzeile", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, planzeile) => getUnitLangname(planzeile.medikationsplan.patient).toLowerCase().includes(value.toLowerCase())}),
            defaultSortOrder: "ascend"
        },
        {
            title: 'Arzneimittel',
            key: 'arzneimittel',
            render: (planzeile) => getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext),

            sorter: getSorter("planzeile", "arzneimittel"),
            ...getColumnSearchProps({dataIndex: "arzneimittel", onFilter: (value, planzeile) => getArzneimittelLangname(planzeile.arzneimittel).toLowerCase().includes(value.toLowerCase())})
        },
        {
            title: `Bedarf`,
            key: 'bedarf',
            render: (planzeile) => {
                const planzeileBedarfAnforderungList = planzeileBedarfAnforderungMap[planzeile.id];
                if (planzeileBedarfAnforderungList) {
                    const planzeileBedarfAnforderung = planzeileBedarfAnforderungList.filter(e => e.id.typ === "VORAB")[0];
                    return `${planzeileBedarfAnforderung.mengeBedarf} ${planzeile.arzneimittel.einheit}`
                } else {
                    return "K/A";
                }
            }
        },
        {
            title: 'Empfohlener Arzt',
            key: 'verordner',
            render: (planzeile) => {
                if (planzeile.verordner) {
                    return <span>
                        <b>{getUnitLangname(planzeile.verordner, 'arzt')}</b><br/>
                        (Verordner)
                    </span>
                }

                else if (planzeile.medikationsplan?.patient?.hausarzt) {
                    return <span>
                            <b>{getUnitLangname(planzeile.medikationsplan.patient.hausarzt, 'arzt')}</b><br/>
                            (Hausarzt)
                        </span>
                }

                return <span><b>kein Arzt hinterlegt!</b></span>
            },

            onCell: (planzeile) => {
                const result = { style: {} };

                const unitId = planzeile?.verordner?.id || planzeile?.medikationsplan?.patient?.hausarzt?.id;
                if (unitId) {
                    const zeitraumMap = api.unitIdToZeitraumMapMap[unitId];
                    if (!zeitraumMap) api.getZeitraumMapByUnit(unitId);
                    else {
                        const currentZeitraumColor = getCurrentZeitraumColor(zeitraumMap, 0.5, null, ["U", "U_B"], 14);
                        if (currentZeitraumColor) result.style.backgroundColor = currentZeitraumColor;
                    }
                }

                return result;
            },

            sorter: getSorter("planzeile", "arzt"),
            ...getColumnSearchProps({dataIndex: "arzt", onFilter: (value, planzeile) => getUnitLangname(planzeile.verordner || planzeile.medikationsplan?.patient?.hausarzt).toLowerCase().includes(value.toLowerCase())}),
        },{
            title: "Aktionen",
            key: "aktionen",
            render: (planzeile) => <div>
                <Button onClick={() => openRezeptanforderungModal(planzeile, () => {
                    notification.info({message: "Die Anforderung wurde erstellt."});
                    setRezeptanforderungModalData(null);
                    removeEntry(planzeile);
                }, true)}>Anforderung erstellen</Button>
            </div>
        }
    ]

    const columnsFreigabe = [
        {
            title: 'Patient',
            key: 'patient',
            render: (obj) => <Link
                to={'/medikationsplan/' + obj.medikationsplan.id}>{getUnitLangname(obj.patient)}</Link>,

            sorter: getSorter("planzeile", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, obj) => getUnitLangname(obj.patient).toLowerCase().includes(value.toLowerCase())}),
            defaultSortOrder: "ascend"
        },

    ];

    const columnsUnklar = [
        {
            title: 'Patient',
            key: 'patient',
            render: (planzeile) => getUnitLangname(planzeile.medikationsplan.patient),

            sorter: getSorter("planzeile", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, planzeile) => getUnitLangname(planzeile.medikationsplan.patient).toLowerCase().includes(value.toLowerCase())}),
            defaultSortOrder: "ascend"
        },
        {
            title: 'Planzeile',
            key: 'planzeile',
            render: (planzeile) => {
                return <Link to={`/adressbuch/${planzeile.medikationsplan.patient.id}/medikationsplan?planzeile=${planzeile.id}`}>{getArzneimittelLangname(planzeile.arzneimittel)}</Link>;
            },

            sorter: getSorter("planzeile", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, planzeile) => getUnitLangname(planzeile.medikationsplan.patient).toLowerCase().includes(value.toLowerCase())}),
            defaultSortOrder: "ascend"
        }
    ];

    const columnsDokumente = [
        {
            title: 'Akteur',
            key: 'akteur',
            render: (entry) => <a onClick={() => handleVerknuepfungUnit(entry.unit)}>{getUnitLangname(entry.unit)}</a>,

            sorter: getSorter("unit"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, unit) => getUnitLangname(unit).toLowerCase().includes(value.toLowerCase())}),
            defaultSortOrder: "ascend",
        },
        {
            title: 'Dokumenttypen',
            key: 'types',
            render: (entry) => Array.from(entry.types).sort().join(', '),

            filters: DOCUMENT_TYPES.map(type => ({ text: type, value: type })),
            onFilter: (filter, entry) => {
                return entry.types.has(filter);
            },
        }

    ];

    const localeTable = {
        filterConfirm: 'Ok',
        filterReset: 'Zurücksetzen',
        emptyText: "keine Daten gefunden"
    }

    return <Row>
            {rezeptanforderungModalData &&
                <RezeptanforderungModal
                    {...rezeptanforderungModalData}
                />
            }

            <Col span={16} style={{paddingRight: "10px"}}>
                <Collapse>
                    <Collapse.Panel key={"mahnungNoetig"} showArrow={false} header={<Badge count={api.mahnungNoetig.length}><h3>Rezeptanforderungen: Mahnung erforderlich</h3></Badge>}>
                        <div style={{overflowX: "scroll"}}>
                            <Rezeptanforderungen
                                title={"Mahnungen nötig"}
                                anforderungen={api.mahnungNoetig}
                                loading={!api.anforderungenOffenLoaded}
                                modus={ModusRezeptanforderung.offen}
                            />
                        </div>
                    </Collapse.Panel>

                    <Collapse.Panel key={"vorab"} showArrow={false} header={<Badge count={api.planzeilenVorab.length}><h3>Rezeptanforderungen Vorab</h3></Badge>}>
                        <div style={{overflowX: "scroll"}}>
                            <Table
                                columns={columnsVorab(api.planzeilenVorab, api.removePlanzeileVorab)}
                                dataSource={api.planzeilenVorab}
                                rowKey={"id"}
                                loading={!api.planzeilenVorabLoaded}
                                pagination={usePagination({position: "none"})}
                                locale={localeTable}
                            />
                        </div>
                    </Collapse.Panel>

                    <Collapse.Panel key={"anforderungNoetig"} showArrow={false} header={<Badge count={api.planzeilenAnforderungenNoetig.length}><h3>Rezeptanforderungen erforderlich</h3></Badge>}>
                        <div style={{overflowX: "scroll"}}>
                            <Table
                                columns={columnsReichweite(api.planzeilenAnforderungenNoetig, api.removePlanzeileAnforderungenNoetig)}
                                dataSource={api.planzeilenAnforderungenNoetig}
                                rowKey={"id"}
                                loading={!api.planzeilenAnforderungenNoetigLoaded}
                                footer={(curr) => <div>
                                    <Button loading={erstelleAnforderungen} onClick={() => createAllAnforderungen(api.planzeilenAnforderungenNoetig)}>Anforderung für alle Einträge erstellen</Button>
                                    {!!erstelleAnforderungen && <span style={{marginLeft: 20}}>bearbeitet: {createAllAnforderungenCreated + createAllAnforderungenSkipped}/{api.planzeilenAnforderungenNoetig.length} ({createAllAnforderungenCreated} erstellt, {createAllAnforderungenSkipped} übersprungen)</span>}
                                </div>}
                                pagination={usePagination({position: "none"})}
                                locale={localeTable}
                            />
                        </div>
                    </Collapse.Panel>

                    <Collapse.Panel key={"notizenUnbearbeitet"} showArrow={false} header={<Badge count={api.notizenUnbearbeitet.length}><h3>Unbearbeitete Notizen</h3></Badge>}>
                        <NotizTable
                            dataSource={api.notizenUnbearbeitet}
                            unitMap={api.notizenUnbearbeitetUnitMap}
                            loading={!api.notizenUnbearbeitetLoaded}

                            locale={localeTable}
                            showColumns={["unit", "titel", "faelligkeit", "gueltigVon", "gueltigBis", "dateErstellt"]}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col span={8} style={{paddingLeft: "10px"}}>
                <Collapse>
                    <Collapse.Panel key={"dokumenteUnbearbeitet"} showArrow={false} header={<Badge count={api.verknuepfungenUnbearbeitetPatienten?.length}><h3>Unbearbeitete Dokumente</h3></Badge>}>
                        <Table
                            columns={columnsDokumente}
                            loading={!api.verknuepfungenUnbearbeitetLoaded}
                            dataSource={api.verknuepfungenUnbearbeitetPatienten}
                            locale={localeTable}
                            rowKey={"id"}
                            pagination={usePagination({position: "none"})}
                        />
                    </Collapse.Panel>

                    <Collapse.Panel key={"freigabeFehlt"} showArrow={false} header={<Badge count={api.patientenFreigabeFehlt.length}><h3>Freigabe fehlt</h3></Badge>}>
                        <Table
                            columns={columnsFreigabe}
                            loading={!api.planzeilenFreigabeFehltLoaded}
                            dataSource={api.patientenFreigabeFehlt}
                            locale={localeTable}
                            rowKey={"id"}
                            pagination={usePagination({position: "none"})}
                        />
                    </Collapse.Panel>

                    <Collapse.Panel key={"unklar"} showArrow={false} header={<Badge count={api.planzeilenUnklar.length}><h3>Unklare Planzeilen</h3></Badge>}>
                        <Table
                            columns={columnsUnklar}
                            loading={!api.planzeilenUnklarLoaded}
                            dataSource={api.planzeilenUnklar}
                            locale={localeTable}
                            rowKey={"id"}
                            pagination={usePagination({position: "none"})}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>
}