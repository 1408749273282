import React, {useEffect} from "react";

export const useOnClickOutside = (effect, deps) => {
    const handleClickOutside = (e) => {
        if (deps) for (let ref of deps) {
            if (ref.current) {
                if (Array.isArray(ref.current)) {
                    for (let sub of ref.current) {
                        if (sub.current.contains(e.target)) {
                            return;
                        }
                    }
                } else if (ref.current.contains(e.target)) {
                    return;
                }
            }
        }

        effect(e);
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        }
    }, deps)
}