import React, {useContext, useEffect, useRef, useState} from 'react'
import moment from 'moment';

import { useFetch } from './../../utilities/useFetch';
import { fetchBlisterJobs } from './../../config/fetchApiConfiguration';

import {Row, Col, Button, notification, Modal} from 'antd';
import { useAuth } from '../../utilities/useAuth';
import {anspruchApi, lagerApi, medikationsplanApi} from '../../config/apiConfig'
import { BlisterJobDetails } from '../molecules/BlisterJobDetails';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {callApiAsync} from "../../utilities/apiUtil";
import {useHistory} from "react-router-dom";

const initialLimit = 4;
export const BlisterJobListe = ({ patientId, medikationsplan = null, onImportJob = null, onJobReload, ...props }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const history = useHistory();

    const [limit, setLimit] = useState(initialLimit);
    const blisterJobs = useFetch(fetchBlisterJobs({patientId, limit}))
    const [zeigeAlleJobs, setZeigeAlleJobs] = useState(false)
    const [modalText, setModalText] = useState(null);
    const [modalHausarztJobId, setModalHausarztJobId] = useState(null);

    const importTriggered = useRef(false);
    const blisterJobsRef = useRef(null);

    useEffect(() => {
        if (!blisterJobs || blisterJobs.loading || blisterJobs.error) return
        if (onJobReload) onJobReload(blisterJobs.data)
        blisterJobsRef.current = blisterJobs.data;
    }, [blisterJobs])

    useEffect(() => {
        if (zeigeAlleJobs) {
            if (limit === initialLimit) {
                setLimit(0);
            }
        }
    }, [zeigeAlleJobs]);


    // Todo importPanzeilen auslagern
    const auth = useAuth();
    const importPanzeilen = async (jobId = null, skipCheckHausarzt = false) => {
        if (!jobId || !medikationsplan || !medikationsplan.patient || importTriggered.current) return

        // Prüfe, ob Hausarzt eingetragen ist
        if (!skipCheckHausarzt && !medikationsplan.patient.hausarzt) {
            setModalHausarztJobId(jobId);
            return;
        }

        importTriggered.current = true;
        const response = await callApiAsync({
                auth,
                url: medikationsplanApi.addByJob(medikationsplan.id, jobId, medikationsplan.patient.id),
                method: "post"
            });

        if (onImportJob !== null) {
            const planzeilen = response.data.OBJECT;

            notification.info({message: `Job #${jobId} wurde erfolgreich importiert.`});
            const patientId = medikationsplanContext.medikationsplan.patient.id;

            // sync Bestände
            setModalText("Die Bestände werden neu geladen.");
            const response2 = await callApiAsync({
                auth,
                url: lagerApi.postSyncBestand(),
                method: 'POST',
                data: {patientId: patientId}
            });

            // sync Ansprüche
            setModalText("Die Ansprüche werden neu geladen.");
            const response3 = await callApiAsync({
                auth,
                url: anspruchApi.reinitAnsprueche(),
                method: "post",
                data: [patientId]
            });

            setModalText(null);
            onImportJob(planzeilen);
        }
    }

    return <div>
        <Modal
            visible={!!modalText}
            footer=""
            closable={false}
        >
            <h3>Bitte warten Sie einen Augenblick</h3>
            <p>{modalText}</p>
        </Modal>

        <Modal
            visible={modalHausarztJobId}
            footer={null}
            width={800}
            closable={false}
        >
            <h3>Für diesen Patient ist kein Hausarzt eingetragen.</h3>
            Soll jetzt ein Hausarzt eingetragen werden?

            <Row style={{marginTop: 20}}>
                <Col span={12}><Button onClick={() => {
                    setModalHausarztJobId(null);
                    importPanzeilen(modalHausarztJobId, true);
                }}>NEIN, Job importieren</Button></Col>

                <Col span={12} style={{textAlign: "right"}}><Button onClick={() => {
                    setModalHausarztJobId(null);
                    history.push(`/adressbuch/${medikationsplan.patient.id}/sonstiges`);
                }}>JA, Import abbrechen und Hausarzt eintragen</Button></Col>
            </Row>
        </Modal>

        <h4>Bisherige Jobs:</h4>
        {blisterJobs.loading && <LoadingOutlined />}

        {!blisterJobs.loading && blisterJobsRef.current && blisterJobsRef.current.map(
            (job, index) => (!zeigeAlleJobs && index > 3) ? null : <BlisterJobDetails
                key={job.id}
                patientId={patientId}
                job={job}
                collapsible={true}
                onClickImportButton={() => importPanzeilen(job.id)}
            />
        )}

        {!blisterJobs.loading && !zeigeAlleJobs && <Button
            onClick={() => setZeigeAlleJobs(true)}
        >... alle anzeigen</Button>}

        {!blisterJobs.loading && zeigeAlleJobs && <Button
            onClick={() => setZeigeAlleJobs(false)}
        >... nur neuste anzeigen</Button>}
    </div >
}

