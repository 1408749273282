import React from "react";

export const siderStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible auto',
    backgroundColor: '#86ADC8',
    width: '250px',
    padding: '10px',
    height: "calc(100vh - 100px)",
    position: 'fixed',
    left: 0,
}

export const mainStyle = {
    marginLeft: '200px'
}
