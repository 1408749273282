import moment from "moment";

export const getQuartalsende = (refDate) => {
    refDate = refDate ? moment(refDate) : moment();
    switch (refDate.format("Q")) {
        case "1":
            return moment("03-31", "MM-DD");
        case "2":
            return moment("06-30", "MM-DD");
        case "3":
            return moment("09-30", "MM-DD");
        default:
            return moment("12-31", "MM-DD");
    }
}

export const WEEKDAYS_ALL = [
    {
        tag: 1,
        bezeichnung: "Montag"
    },
    {
        tag: 2,
        bezeichnung: "Dienstag"
    },
    {
        tag: 3,
        bezeichnung: "Mittwoch"
    },
    {
        tag: 4,
        bezeichnung: "Donnerstag"
    },
    {
        tag: 5,
        bezeichnung: "Freitag"
    },
    {
        tag: 6,
        bezeichnung: "Samstag"
    },
    {
        tag: 7,
        bezeichnung: "Sonntag"
    }
]

export const getWochentag = (tag) => {
    if (tag < 1) {
        while (tag < 1) tag += 7;
    } else {
        while (tag > 7) tag -= 7;
    }

    return WEEKDAYS_ALL[tag - 1].bezeichnung;
}