export function berechneKoordinate(oldX, oldY, markerSize, headerSize, rotation, zoomLevel, pageWidth, pageHeight) {

    const denormalizedX = oldX / 100;
    const denormalizedY = oldY / 100;

    let posX = 0, posY = 0;
    switch (rotation) {

        case 0:
            posX = denormalizedX * zoomLevel;
            posY = denormalizedY * zoomLevel + headerSize;
            break;

        case 90:
            posX = (pageHeight - denormalizedY) * zoomLevel;
            posY = denormalizedX * zoomLevel + headerSize;
            break;

        case 180:
            posX = (pageWidth - denormalizedX) * zoomLevel;
            posY = (pageHeight - denormalizedY) * zoomLevel + headerSize;
            break;

        case 270:
            posX = denormalizedY * zoomLevel;
            posY = (pageWidth - denormalizedX) * zoomLevel + headerSize;
            break;
    }

    posX -= (markerSize / 2);
    posY -= (markerSize / 2);

    return {posX, posY}
}


export function berechneKoordinateReverse(oldX, oldY, markerSize, headerSize, rotation, zoomLevel, pageWidth, pageHeight) {
    let posX = 0, posY = 0;
    switch (rotation) {
        case 0:
            posX = oldX / zoomLevel
            posY = (oldY - headerSize) / zoomLevel
            break;

        case 90:
            posX = (oldY - headerSize) / zoomLevel
            posY = pageHeight - oldX / zoomLevel
            break;

        case 180:
            posX = pageWidth - oldX / zoomLevel
            posY = pageHeight - (oldY - headerSize) / zoomLevel
            break;

        case 270:
            posX = pageWidth - (oldY - headerSize) / zoomLevel
            posY = oldX / zoomLevel
            break;
    }

    posX *= 100;
    posY *= 100;

    return {posX, posY}
}