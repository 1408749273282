import React, {useContext} from "react";
import moment from "moment";
import {Table} from "antd";
import {getUnitLangname} from "../../config/entities";
import NotizContext from "../../contexts/NotizContext";
import {getSorter} from "../../utilities/sortUtil";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import usePagination from "../../utilities/usePagination";

const NotizTable = ({dataSource, unitMap={}, loading=false, locale=null, showColumns=null, sortColumns=null, filterColumns=null, defaultSortColumn, defaultSortOrder="ascend", onSave=(notiz)=>{}}) => {
    const notizContext = useContext(NotizContext);

    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();

    const onFilter = (value, notiz, dataIndex) => {
        let datum, start, ende;

        switch (dataIndex) {
            case "unit":
                return !![...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].find(e => unitMap[e.id.unitId] && getUnitLangname(unitMap[e.id.unitId][0])
                    .toLowerCase()
                    .includes(value.toLowerCase()))
            case "titel":
                return (notiz.titel || "")
                    .toLowerCase()
                    .includes(value.toLowerCase());
            case "faelligkeit":
                return !![...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].find(e => {
                    datum = e.faelligkeit ? moment(e.faelligkeit) : null;
                    start = value.start ? moment(value.start).startOf('day') : null;
                    ende = value.ende ? moment(value.ende).endOf('day') : null;

                    return (!start || (datum && datum.isSameOrAfter(start))) && (!ende || (datum && datum.isSameOrBefore(ende)))
                });
            case "gueltigVon":
                return !![...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].find(e => {
                    datum = e.gueltigVon ? moment(e.gueltigVon) : null;
                    start = value.start ? moment(value.start).startOf('day') : null;
                    ende = value.ende ? moment(value.ende).endOf('day') : null;

                    return (!start || (datum && datum.isSameOrAfter(start))) && (!ende || (datum && datum.isSameOrBefore(ende)))
                });
            case "gueltigBis":
                return !![...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].find(e => {
                    datum = e.gueltigBis ? moment(e.gueltigBis) : null;
                    start = value.start ? moment(value.start).startOf('day') : null;
                    ende = value.ende ? moment(value.ende).endOf('day') : null;

                    return (!start || (datum && datum.isSameOrAfter(start))) && (!ende || (datum && datum.isSameOrBefore(ende)))
                });
        }
    };

    if (!defaultSortColumn) {
        if (sortColumns) {
            defaultSortColumn = sortColumns[0];
        } else {
            defaultSortColumn = "unit";
        }
    }

    const columnsNotizen = [
        {
            title: 'Akteur',
            key: 'unit',
            render: (notiz) => {
                let entrySet;
                switch (notiz.type) {
                    case "UNIT":
                        if (!notiz.unitSet?.length) return "K/A";
                        entrySet = notiz.unitSet;
                        break;
                    case "INTERAKTION":
                        entrySet = notiz.interaktionSet;
                        break;
                }

                return entrySet.sort((a,b) => getSorter("unit")(unitMap[a.id.unitId], unitMap[b.id.unitId])).map((u, i) => {
                    let nameString = null;
                    if (unitMap[u.id.unitId]) {
                        nameString = getUnitLangname(unitMap[u.id.unitId][0]);
                    }

                    if (!nameString) {
                        nameString = "K/A";
                    }

                    return <div key={i}>{nameString}</div>;
                })
            },

            defaultSortOrder: defaultSortColumn === "unit" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("unit") ? (a,b) => {
                const unitA = [...(a.unitSet || []).map(e => unitMap[e.id.unitId]), ...(a.interaktionSet || []).map(e => unitMap[e.id.unitId])].sort(getSorter("unit"))[0]?.[0];
                const unitB = [...(b.unitSet || []).map(e => unitMap[e.id.unitId]), ...(b.interaktionSet || []).map(e => unitMap[e.id.unitId])].sort(getSorter("unit"))[0]?.[0];

                return getSorter("unit")(unitA, unitB);
            } : null,
            ...(!filterColumns || filterColumns.includes("unit") ? getColumnSearchProps({dataIndex: 'unit', onFilter}) : {}),
        },
        {
            title: 'Titel',
            dataIndex: "titel",

            defaultSortOrder: defaultSortColumn === "titel" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("titel") ? getSorter("notiz", "titel") : null,
            ...(!filterColumns || filterColumns.includes("titel") ? getColumnSearchProps({dataIndex: 'titel', onFilter}) : {}),
        },
        {
            title: 'Fälligkeit',
            key: 'faelligkeit',
            render: (notiz) => [...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].map((u, i) => <div key={i}>{u.faelligkeit ? moment(u.faelligkeit).format("DD.MM.YYYY") : ""}</div>),

            onCell: (notiz) => {
                let isFaellig = false;
                for (let entry of notiz.unitSet || notiz.interaktionSet || []) if (entry.faelligkeit && moment().isSameOrAfter(entry.faelligkeit)) {
                    isFaellig = true;
                    break;
                }

                return isFaellig ? {style: {backgroundColor: "#ffadd2"}} : {};
            },

            defaultSortOrder: defaultSortColumn === "faelligkeit" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("faelligkeit") ? getSorter("notiz", "faelligkeit") : null,
            ...(!filterColumns || filterColumns.includes("faelligkeit") ? getColumnDateProps('faelligkeit', onFilter) : {}),
        },
        {
            title: 'Erstellt',
            key: 'dateErstellt',
            render: (notiz) => {
                if (!notiz.dateErstellt) return "";
                return moment(notiz.dateErstellt).format("DD.MM.YYYY");
            },

            defaultSortOrder: defaultSortColumn === "dateErstellt" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("dateErstellt") ? getSorter("notiz", "dateErstellt") : null,
            ...(!filterColumns || filterColumns.includes("dateErstellt") ? getColumnDateProps('dateErstellt', onFilter) : {}),
        },
        {
            title: 'Gültig von',
            key: 'gueltigVon',
            render: (notiz) => [...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].map((u, i) => <div key={i}>{u.gueltigVon ? moment(u.gueltigVon).format("DD.MM.YYYY") : ""}</div>),

            defaultSortOrder: defaultSortColumn === "gueltigVon" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("gueltigVon") ? getSorter("notiz", "gueltigVon") : null,
            ...(!filterColumns || filterColumns.includes("gueltigVon") ? getColumnDateProps('gueltigVon', onFilter) : {}),
        },
        {
            title: 'Gültig Bis',
            key: 'gueltigBis',
            render: (notiz) => [...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].map((u, i) => <div key={i}>{u.gueltigBis ? moment(u.gueltigBis).format("DD.MM.YYYY") : ""}</div>),

            defaultSortOrder: defaultSortColumn === "gueltigBis" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("gueltigBis") ? getSorter("notiz", "gueltigBis") : null,
            ...(!filterColumns || filterColumns.includes("gueltigBis") ? getColumnDateProps('gueltigBis', onFilter) : {}),
        },
        {
            title: 'Bearbeitet',
            key: 'bearbeitet',
            render: (notiz) => {
                return [...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].map((u, i) => <div key={i}>{u.bearbeitet ? "Ja" : "Nein"}</div>)
            },

            defaultSortOrder: defaultSortColumn === "bearbeitet" ? defaultSortOrder : null,
            sorter: !sortColumns || sortColumns.includes("bearbeitet") ? getSorter("notiz", "bearbeitet") : null,
            ...(!filterColumns || filterColumns.includes("bearbeitet") ? {
                filters: [{text: "Ja", value: true}, {text: "Nein", value: false}],
                onFilter: (bool, notiz) => {
                    return !![...(notiz.unitSet || []), ...(notiz.interaktionSet || [])].find(e => e.bearbeitet === bool);
                }
            } : {}),
        }

    ].filter(c => !showColumns || (c.dataIndex && showColumns.includes(c.dataIndex)) || (c.key && showColumns.includes(c.key)));

    return (
        <Table
            columns={columnsNotizen}
            loading={loading}
            dataSource={dataSource}
            locale={locale}
            rowKey={"id"}
            pagination={usePagination({position: "none"})}



            onRow={notiz => ({
                style: {
                    cursor: "pointer",
                    // backgroundColor: notiz.faelligkeit && moment().isSameOrAfter(notiz.faelligkeit) ? "#ffadd2" : "initial"
                },

                onClick: e => {
                    notizContext.setNotiz(notiz);
                    notizContext.setAkteur(unitMap[notiz.unitSet?.[0]?.id?.unitId]?.[0]);
                    notizContext.setVisible(true);
                }
            })}
        />
    )
}

export default NotizTable;