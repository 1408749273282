import React, {useContext, useEffect, useRef, useState} from "react";
import {Tag, Collapse, Button, DatePicker, Row, Col, notification} from "antd";
import InteraktionenContext from "../../contexts/InteraktionenContext";
import {Popover} from "../atoms/Popover";
import {Tooltip} from "../atoms/Tooltip";
import Moveable from "react-moveable";
import WindowContext from "../../contexts/WindowContext";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {interaktionApi, notizApi} from "../../config/apiConfig";
import {mergeDeep} from "../../utilities/collectionUtil";
import {hash} from "../../utilities/encodingUtil";
import moment from "moment";
import {AbdaText} from "../atoms/AbdaText";
import {getUnitLangname} from "../../config/entities";
import {WarningOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {HotKeys} from "react-hotkeys";
import locale_de from "antd/es/date-picker/locale/de_DE";
import Checkbox from "antd/es/checkbox/Checkbox";
import NotizContext from "../../contexts/NotizContext";
export const InteraktionenModal = ({cardId}) => {
    const CARD_ID = cardId;

    const auth = useAuth();

    const interaktionenContext = useContext(InteraktionenContext);
    const windowContext = useContext(WindowContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const notizContext = useContext(NotizContext);

    const isFocused = useRef(false);
    const parentRef = useRef();
    const moveableRef = useRef();
    const [movableTarget, setMovableTarget] = useState(null);
    const [movableFrame, setMovableFrame] = useState({translate: [0, 0]});
    const [dragTarget, setDragTarget] = useState(null);

    const [interaktionenMap, setInteraktionenMap] = useState({});
    const [interaktionenAnzeige, setInteraktionenAnzeige] = useState({});
    const [bewertungenMap, setBewertungenMap] = useState({});
    const [showBewertet, setShowBewertet] = useState(false);

    const __TEXTVERWEIS_REIHENFOLGE = [
        110, // Maßnahmen
        9,   // Literatur
        40,  // Pharmakologischer Effekt
        50,  // Pharmakologischer Effekt (kurzfassung)
        100, // Wichtige Hinweise
        140, // Mechanismus
        300, // Weitere Empfehlungen
        330, // Mögliche Symptome
        340, // Datengrundlage
        360, // Nebenwirkungen
        370, // Applikationswege (links)
        380, // Applikationswege (rechts)

        // 230, // Messwerte für Monitoring
        // 240, // Beginn des Monitorings
        // 250, // Dauer des Monitorings
        // 260, // Monitoring nach Absetzen
        // 270, // Weitere Risikofaktoren
        // 280, // Zeitversetzte Einnahme/Anwendung
        // 290, // Dosis- bzw. Intervallanpassung
        // 310, // Hinweise bei Kurzzeittherapie
        // 350, // Risikofaktoren
        // 390, // Individualselektoren
    ];

    const __CHECK_TEXTVERWEIS_HASH = [100, 110, 300]

    useEffect(() => {
        const isSame = windowContext.activeId === CARD_ID;
        isFocused.current = isSame;
    }, [windowContext.activeId])

    useEffect(() => {
        if (movableTarget) {
            const rect = moveableRef.current.getRect();
            const win = window,
                doc = document,
                docElem = doc.documentElement,
                body = doc.getElementsByTagName('body')[0],
                viewPortWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                viewportHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

            const x = (viewPortWidth - rect.width) / 2;
            const y = (viewportHeight - rect.height) / 4;

            const targetHeight = 355;
            const deltaHeight = targetHeight - rect.height;

            moveableRef.current.request("draggable", {x, y}, true);
            moveableRef.current.request("resizable", {deltaHeight}, true);
        }

        checkMoveableHeight();
    }, [movableTarget])

    useEffect(() => {
        if (Object.keys(interaktionenMap).length && interaktionenContext.showModal) {
            resetMoveable();

            const notizenMap = {};
            const map = notizContext.notizMap[medikationsplanContext.medikationsplan?.patient?.id] || {};
            for (let notizId in map) {
                const notiz = map[notizId];
                for (let interaktion of notiz.interaktionSet || []) {
                    notizenMap[interaktion.id.interaktionId] = notiz;
                }
            }
            interaktionenContext.setNotizenMap(notizenMap);
        } else {
            setMovableTarget(null);
            setDragTarget(null);

            interaktionenContext.setNotizenMap({});
        }
    }, [interaktionenMap, interaktionenContext.showModal])

    useEffect(() => {
        if (interaktionenContext.interaktionen && interaktionenContext.interaktionenTextMap && interaktionenContext.ueberschriftenInt && interaktionenContext.ueberschriftenItx && !interaktionenContext.hashesLoading) {
            setBewertungenMap({});
            setShowBewertet(false);
            interaktionenContext.setHashesLoading(true);

            (async () => {
                const interaktionenMapNeu = {bewertet: {}, unbewertet: {}};

                // Interaktionen in Baum eintragen
                const promiseList = [];
                for (let interaktion of interaktionenContext.interaktionen) {
                    promiseList.push(new Promise(async resolve => {
                        const textverweis = interaktion.textverweis;
                        const textMap = interaktionenContext.interaktionenTextMap[textverweis] || [];

                        if (textMap === []) {
                            console.error(
                                "Textmap ist leer. interaktionen - interaktionenTextMap - ueberschriftenInt - ueberschriftenItx",
                                interaktionenContext.interaktionen,
                                interaktionenContext.interaktionenTextMap,
                                interaktionenContext.ueberschriftenInt,
                                interaktionenContext.ueberschriftenItx);
                        }

                        // Prüfe Hashes der Texte mit Bewertung
                        const bewertung = (await callApiAsync({
                            auth,
                            url: interaktionApi.getBewertungByPatientAndInteraktion(medikationsplanContext.medikationsplan.patient.id, interaktion.keyInt),
                            successOnError: true,
                            onError: e => {
                                return e.data?.EXCEPTION_CLASS === "de.kushiworks.java.verblisterung.ejb.db.util.CustomExceptions$NoResultException";
                            }
                        })).data.OBJECT;

                        if (bewertung) {
                            const hashes = (await callApiAsync({
                                auth,
                                url: interaktionApi.getHashesByInteraktion(interaktion.keyInt)
                            })).data.OBJECT

                            bewertung.relevanceInvalid = bewertung.relevance !== interaktion.relevanz;
                            bewertung.hashesInvalid = [];
                            bewertung.hashes = {};
                            for (let interaktionHash of hashes || {}) {
                                bewertung.hashes[interaktionHash.id.textType] = interaktionHash;

                                if (__CHECK_TEXTVERWEIS_HASH.includes(interaktionHash.id.textType) && interaktionHash.hashCode !== hash(textMap[interaktionHash.id.textType])) {
                                    bewertung.hashesInvalid.push(interaktionHash.id.textType);
                                }
                            }

                            setBewertungenMap(prev => ({...prev, [interaktion.keyInt]: bewertung}));
                        }

                        // Relevanz
                        const bewertetKey = bewertung?.hashesInvalid?.length === 0 && !bewertung.relevanceInvalid ? "bewertet" : "unbewertet";
                        const relevanz = interaktion.relevanz;
                        if (!interaktionenMapNeu[bewertetKey][relevanz]) interaktionenMapNeu[bewertetKey][relevanz] = {};
                        const relevanzMap = interaktionenMapNeu[bewertetKey][relevanz];

                        // Pharmakologischer Effekt (Kurzfassung) => Textfeld 50
                        const effekt = textMap[50] || "kein pharmakologischer Effekt";
                        if (!relevanzMap[effekt]) relevanzMap[effekt] = [];
                        const interaktionen = relevanzMap[effekt];

                        interaktionen.push(interaktion);
                        resolve();
                    }));
                }

                await Promise.all(promiseList);
                setInteraktionenMap(interaktionenMapNeu);
                interaktionenContext.setHashesLoading(false);
            })();
        }
    }, [interaktionenContext.interaktionen, interaktionenContext.interaktionenTextMap, interaktionenContext.ueberschriftenInt, interaktionenContext.ueberschriftenItx])

    useEffect(() => {
        const interaktionenAnzeigeNeu = JSON.parse(JSON.stringify(interaktionenMap.unbewertet || {}));
        if (showBewertet) mergeDeep(interaktionenAnzeigeNeu, interaktionenMap?.bewertet || {});

        setInteraktionenAnzeige(interaktionenAnzeigeNeu);
    }, [showBewertet, interaktionenMap])

    const checkMoveableHeight = (props, props2) => {
        if (!moveableRef.current) return;

        setTimeout(() => {
            const cardBody = document.querySelector("#card-interaktionen .card-body");
            if (cardBody.querySelector(".ant-motion-collapse-legacy")) {
                checkMoveableHeight();
                return;
            }

            const scrollHeight = cardBody.scrollHeight;
            moveableRef.current.request("resizable", {deltaHeight: -scrollHeight}, true);

            const rect = moveableRef.current.getRect();
            const targetHeight = rect.height;

            if (scrollHeight > targetHeight) {
                moveableRef.current.request("resizable", {deltaHeight: scrollHeight - targetHeight}, true);
            }
        }, 10);
    }

    const resetMoveable = () => {
        const cardSelector = document.querySelector(`#${CARD_ID}`);
        setMovableTarget(cardSelector);
        setDragTarget(cardSelector.querySelector(".draggable-target"));
    }

    const markiereBearbeitet = async (interaktion, forceNeuberechnen=false) => {
        const data = {
            id: {
                interactionId: interaktion.keyInt,
                unitId: medikationsplanContext.medikationsplan.patient.id,
            },
            relevance: interaktion.relevanz,
            dateProcessed: new Date().valueOf()
        }

        const bewertung = (await callApiAsync({auth, url: interaktionApi.putBewertung(forceNeuberechnen), method: "put", data})).data.OBJECT;
        bewertung.hashesInvalid = [];
        setBewertungenMap(prev => ({...prev, [interaktion.keyInt]: bewertung}));
    }

    const markiereUnbearbeitet = async (interactionId) => {
        await callApiAsync({auth, url: interaktionApi.deleteBewertung(medikationsplanContext.medikationsplan.patient.id, interactionId), method: "delete"});
        setBewertungenMap(prev => {
            const neu = {...prev};
            delete neu[interactionId];
            return neu;
        });
    }

    if (!Object.keys(interaktionenMap).length) {
        return <></>
    }

    return <div className={"card-container"} style={{position: "absolute", top: 0, left: 0, width: "100%", height: 0, display: interaktionenContext.showModal ? "block" : "none", zIndex: windowContext.activeId === CARD_ID ? 1 : 0}}>
        <div
            ref={parentRef}
            onClick={() => windowContext.setActiveId(CARD_ID)}
            id={CARD_ID}
            style={{display: "block", backgroundColor: "#fff", position: "absolute", width: "70%", border: "1.5px solid #4af"}}
        >
            <div>
                <div className={"card-body"}>
                    <span className={"draggable-target"} style={{textAlign: "center", cursor: "move"}}>
                        <span aria-label={"close"} style={{position: "absolute", top: 0, right: 0, lineHeight: 0, padding: 10, cursor: "pointer"}} onClick={e => interaktionenContext.setShowModal(false)}><Tooltip title={"Fenster schließen"}><CloseOutlined /></Tooltip></span>

                        <h3>Interaktionen</h3>
                        {!!Object.keys(interaktionenMap?.bewertet || {}).length && <Button onClick={() => setShowBewertet(!showBewertet)}>erledigte Interaktionen {showBewertet ? "ausblenden" : "anzeigen"}</Button>}
                    </span>
                    <Collapse accordion onChange={checkMoveableHeight}>

                        {/** sortiere nach Relevanz */}
                        {Object.keys(interaktionenAnzeige).sort((a, b) => b.localeCompare(a)).map((relevanz, i1) => {
                            return <Collapse.Panel header={interaktionenContext.ueberschriftenInt["09"][relevanz].entschluesselung} key={i1}>
                                <Collapse accordion onChange={checkMoveableHeight}>

                                    {/** sortiere nach pharmazeutischem Effekt (kurz) */}
                                    {Object.keys(interaktionenAnzeige[relevanz]).sort((a, b) => a.localeCompare(b)).map((effektText, i2) => {
                                        return <Collapse.Panel header={effektText} key={i2}>
                                            <Collapse accordion onChange={checkMoveableHeight}>

                                                {/** sortiere nach Arzneimittel-Kombination */}
                                                {interaktionenAnzeige[relevanz][effektText].sort((a, b) => a.fziStructList[0].produktname.localeCompare(b.fziStructList[0].produktname)).map((interaktion, i3) => {
                                                    const txtHaeufigkeit = interaktionenContext.ueberschriftenInt["10"][interaktion.haeufigkeit];
                                                    const txtQuellenbewertung = interaktionenContext.ueberschriftenInt["11"][interaktion.quellenbewertung];
                                                    const txtRichtung = interaktionenContext.ueberschriftenInt["12"][interaktion.richtung];
                                                    const txtPlausibilitaet = interaktionenContext.ueberschriftenInt["08"][interaktion.plausibilitaet];
                                                    const txtInteraktionstyp = interaktionenContext.ueberschriftenInt["07"][interaktion.interaktionstyp];

                                                    const fziStructList = interaktion.fziStructList;
                                                    const produktnameList = fziStructList.reduce((produktnameList, fziStruct) => {
                                                        return [...produktnameList, fziStruct.produktname];
                                                    }, []);

                                                    const textverweis = interaktionenContext.interaktionenTextMap[interaktion.textverweis] || {};

                                                    const defaultActiveKey = [0,4,6];
                                                    if (interaktion.keyInt in interaktionenContext.notizenMap) defaultActiveKey.push(interaktion.keyInt + "_notiz")

                                                    {/** Hashes von Texten mit als bearbeitet markierten Interaktinen vergleichen **/}
                                                    // const keys = Object.keys(textverweis).sort();
                                                    // const hashList = keys.interaktionenAnzeige(key => hash(textverweis[key]));

                                                    return <Collapse.Panel header={`${produktnameList.join(" & ")}`} key={i3} extra={<>
                                                    {
                                                        bewertungenMap[interaktion.keyInt]?.relevanceInvalid &&
                                                        <Tooltip title={`Schweregrad geändert von "${interaktionenContext.ueberschriftenInt["09"][bewertungenMap[interaktion.keyInt].relevance].entschluesselung}"`}><WarningOutlined className={"color-red icon-center"} style={{marginRight: 10, fontSize: "1.5em"}} /></Tooltip>
                                                    }
                                                    {
                                                        interaktion.keyInt in bewertungenMap &&
                                                        <span style={{marginRight: 10}}>
                                                            zuletzt am {moment(bewertungenMap[interaktion.keyInt].dateProcessed).format("DD.MM.YYYY") + " "}
                                                            {bewertungenMap[interaktion.keyInt].unitProcessed ? `von ${getUnitLangname(bewertungenMap[interaktion.keyInt].unitProcessed)} ` : ""}
                                                            als erledigt markiert</span>
                                                    }
                                                    {bewertungenMap[interaktion.keyInt]?.hashesInvalid?.length !== 0 || bewertungenMap[interaktion.keyInt]?.relevanceInvalid ? <Button onClick={() => markiereBearbeitet(interaktion, interaktion.keyInt in bewertungenMap)} style={{marginRight: 5}}>als erledigt markieren</Button> : <Button onClick={() => markiereUnbearbeitet(interaktion.keyInt)} style={{marginRight: 5}}>Markierung löschen</Button>}
                                                        <Popover trigger={'hover'} overlayStyle={{zIndex: 9999}} content={<table><tbody>
                                                            <tr>
                                                                <td>Häufigkeit</td>
                                                                <td><Tooltip title={txtHaeufigkeit?.erlaeuterung}>{txtHaeufigkeit?.entschluesselung || "keine Daten"}</Tooltip></td>
                                                            </tr><tr>
                                                                <td>Quellenbewertung</td>
                                                                <td><Tooltip title={txtQuellenbewertung?.erlaeuterung}>{txtQuellenbewertung?.entschluesselung || "keine Daten"}</Tooltip></td>
                                                            </tr><tr>
                                                                <td>Richung</td>
                                                                <td><Tooltip title={txtRichtung?.erlaeuterung}>{txtRichtung?.entschluesselung || "keine Daten"}</Tooltip></td>
                                                            </tr><tr>
                                                                <td>Plausibilität</td>
                                                                <td><Tooltip title={txtPlausibilitaet?.erlaeuterung}>{txtPlausibilitaet?.entschluesselung || "keine Daten"}</Tooltip></td>
                                                            </tr><tr>
                                                                <td>Interaktionstyp</td>
                                                                <td><Tooltip title={txtInteraktionstyp?.erlaeuterung}>{txtInteraktionstyp?.entschluesselung || "keine Daten"}</Tooltip></td>
                                                            </tr><tr>
                                                                <td>Gruppenname links</td>
                                                                <td>{interaktion?.gruppennameLinks || "keine Daten"}</td>
                                                            </tr><tr>
                                                                <td>Gruppenname rechts</td>
                                                                <td>{interaktion?.gruppennameRechts || "keine Daten"}</td>
                                                            </tr>
                                                            </tbody></table>}
                                                        ><Tag>{`#${interaktion.keyInt}`}</Tag></Popover></>}>
                                                                <Collapse defaultActiveKey={!!Object.keys(textverweis).length ? defaultActiveKey : [interaktion.keyInt + "_notiz"]}>
                                                                    <Collapse.Panel key={interaktion.keyInt + "_notiz"} header={<b>Notiz</b>}>
                                                                        <NotizInteraktion interaktion={interaktion} titel={`Interaktion: ${produktnameList.join(" & ")}`} />
                                                                    </Collapse.Panel>
                                                                    {!!Object.keys(textverweis).length ? __TEXTVERWEIS_REIHENFOLGE.map((ueberschriftId, i2) => {
                                                                        const ueberschrift = interaktionenContext.ueberschriftenItx["02"][ueberschriftId];

                                                                        return <Collapse.Panel key={i2} header={<b style={bewertungenMap[interaktion.keyInt]?.hashesInvalid?.includes(ueberschriftId) ? {color: "darkorange"} : {}}>{ueberschrift.entschluesselung}</b>}>
                                                                            <AbdaText text={textverweis[ueberschriftId]} />
                                                                        </Collapse.Panel>
                                                                    }) : <Collapse.Panel key={interaktion.keyInt + "_dummy"} header={"Keine Volltextinformationen zu dieser Interaktion gefunden."} collapsible={'disabled'} showArrow={false} />}
                                                                </Collapse>
                                                    </Collapse.Panel>
                                                })}
                                            </Collapse>
                                        </Collapse.Panel>
                                    })}
                                </Collapse>
                            </Collapse.Panel>
                        })}
                    </Collapse>
                </div>
            </div>
        </div>

        <Moveable
            ref={moveableRef}
            className={`moveable-${CARD_ID} moveable no-border`}
            target={movableTarget}
            origin={false}
            translateZ={windowContext.activeId === CARD_ID ? 1 : -1}



            draggable={true}
            dragTarget={dragTarget}
            throttleDrag={0}
            onDragStart={({ set }) => {
                set(movableFrame.translate);
            }}
            onDrag={({ target, beforeTranslate }) => {
                movableFrame.translate = beforeTranslate;
                target.style.transform
                    = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
            }}
        />
    </div>
};

const NotizInteraktion = ({interaktion, titel="Interaktion"}) => {
    const auth = useAuth();

    const notizContext = useContext(NotizContext);
    const interaktionenContext = useContext(InteraktionenContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [inputVal, setInputVal] = useState({});
    const [notiz, setNotiz] = useState(null);

    const saveTimeout = useRef(null);

    useEffect(() => {
        return onSave;
    }, [])

    useEffect(() => {
        setNotiz(interaktionenContext.notizenMap[interaktion.keyInt]);
    }, [interaktion])

    useEffect(() => {
        const notizInteraktion = notiz?.interaktionSet?.find(i => i.id.interaktionId === interaktion.keyInt);

        setInputVal({
            titel: notiz?.titel || titel,
            text: notiz?.text || null,

            faelligkeit: notizInteraktion?.faelligkeit ? moment(notizInteraktion?.faelligkeit) : null,
            gueltigVon: notizInteraktion?.gueltigVon ? moment(notizInteraktion?.gueltigVon) : null,
            gueltigBis: notizInteraktion?.gueltigBis ? moment(notizInteraktion?.gueltigBis) : null,
            bearbeitet: !!notizInteraktion?.bearbeitet,
        });
    }, [notiz])

    useEffect(() => {
        if (saveTimeout.current) {
            clearTimeout(saveTimeout.current);
        }

        if (inputVal.__changed__) {
            saveTimeout.current = setTimeout(onSave, 5000);
        }
    }, [inputVal])

    const updateNotiz = (notiz) => {
        setNotiz(notiz);

        const unitId = medikationsplanContext.medikationsplan?.patient?.id;
        if (unitId >= 0) {
            notizContext.setNotizMap(prev => ({...prev, [unitId]: {...prev[unitId], [notiz.id]: notiz}}));
        }
    }

    const removeNotiz = (notiz) => {
        setNotiz(null);

        const unitId = medikationsplanContext.medikationsplan.patient.id;
        notizContext.setNotizMap(prev => {
            const neuUnit = {...prev[unitId]};
            delete neuUnit[notiz.id];
            return {...prev, [unitId]: neuUnit};
        });
    }

    const onDelete = async (notiz) => {
        const response = await callApiAsync({auth, url: notizApi.delete(notiz.id), method: "delete"});
        removeNotiz(notiz);

        notification.info({message: "Die Notiz wurde erfolgreich gelöscht"});
    }

    const onSave = async () => {
        if (saveTimeout.current) {
            clearTimeout(saveTimeout.current);
        }

        if (inputVal.__changed__) {
            const unitId = medikationsplanContext.medikationsplan?.patient?.id;
            if (unitId) {
                const notizInteraktion = {
                    id: (notiz?.interaktionSet || []).find(i => i.id.interaktionId === interaktion.keyInt)?.id,

                    faelligkeit: inputVal.faelligkeit ? inputVal.faelligkeit.valueOf() : null,
                    gueltigVon: inputVal.gueltigVon ? inputVal.gueltigVon.valueOf() : null,
                    gueltigBis: inputVal.gueltigBis ? inputVal.gueltigBis.valueOf() : null,
                    bearbeitet: !!inputVal.bearbeitet,
                };
                if (!notizInteraktion.id) notizInteraktion.id = {unitId, notizId: notiz?.id || null, interaktionId: interaktion.keyInt};

                const data = {
                    ...notiz,
                    interaktionSet: [...(notiz?.interaktionSet || []).filter(i => i.id.interaktionId !== interaktion.keyInt), notizInteraktion],

                    titel: inputVal.titel,
                    text: inputVal.text,
                    type: "INTERAKTION",
                };

                const response = await callApiAsync({url: notizApi.post(), method: "post", data, auth});

                const dataNeu = {...data, ...response.data.OBJECT};
                updateNotiz(dataNeu);

                notification.info({message: "Die Notiz wurde gespeichert"});
            }
        }
    }

    const onChange = (key, value) => {
        setInputVal(prev => ({...prev, __changed__: true, [key]: value}))
    }

    return <>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <span style={{lineHeight: 0, color: "darkgrey"}}>
                {!!notiz?.dateErstellt && `Erstellt am ${moment(notiz.dateErstellt).format("DD.MM.YYYY")} von ${notiz.accountErstellt}${!!notiz.accountZuletztBearbeitet && ` - zuletzt bearbeitet am ${moment(notiz.dateZuletztBearbeitet).format("DD.MM.YYYY")} von ${notiz.accountZuletztBearbeitet}`}`}
            </span>

            <span style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                {!!notiz && <Button type={"primary"} danger={true} onClick={() => onDelete(notiz)}>löschen</Button>}

                <Tooltip title={"Fälligkeit"}><DatePicker
                    placeholder={"Fälligkeit"}
                    value={inputVal.faelligkeit}
                    onChange={neu => onChange("faelligkeit", neu ? neu.startOf('day') : null)}
                    format={"DD.MM.YYYY"}
                /></Tooltip>

                <Tooltip title={"Gültigkeit"}>
                    <DatePicker.RangePicker
                        value={[inputVal.gueltigVon, inputVal.gueltigBis]}
                        onCalendarChange={range => {
                            if (range) {
                                onChange("gueltigVon", range[0]);
                                onChange("gueltigBis", range[1]);
                            } else {
                                onChange("gueltigVon", null);
                                onChange("gueltigBis", null);
                            }
                        }}

                        format={"DD.MM.YYYY"}

                        locale={locale_de}
                        allowEmpty
                    />
                </Tooltip>

                <Tooltip title={"Bearbeitet"} style={{marginLeft: "auto"}}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.bearbeitet} onChange={e => {
                                onChange("bearbeitet", e.target.checked);
                            }} /> bearbeitet</span>
                </Tooltip>
            </span>
        </div>

        <div style={{flex: 1}}>
            <Col span={24}>
                <HotKeys keyMap={{SAVE: "ctrl+enter", SELECT_ALL: "ctrl+a"}} handlers={{SAVE: e => onSave()}} style={{height: "100%"}}>
                    <TextArea placeholder={"Inhalt der Notiz"} onChange={e => onChange("text", e.target.value)} value={inputVal.text} style={{minHeight: 150, height: "100%", resize: "none"}} />
                </HotKeys>
            </Col>
        </div>
    </>
}