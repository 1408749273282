import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "antd";
import {ConditionalPopover} from "../atoms/ConditionalPopover";
import {getTeilbarkeitString} from "../../utilities/medicineM2Util";
import {useApi} from "../../utilities/useApi";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import moment from "moment";

const InternalPackage = ({id}) => {
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [internalPackage, setInternalPackage] = useState(null);
    const [mengeVorab, setMengeVorab] = useState(0);
    const [color, setColor] = useState('black');
    const [expiryText, setExpiryText] = useState('');

    useEffect(() => {
        const packung = medikationsplanContext.internalPackageMap[id];

        if (packung) {
            setInternalPackage(packung);

            if (packung.upfrontSet?.length) {
                let mengeVorabNeu = 0;
                for (let upfront of packung.upfrontSet) {
                    mengeVorabNeu += upfront.amountRemaining;
                }

                setMengeVorab(mengeVorabNeu);
            }

            const date = moment(packung.updatedExpiryDate || packung.batch.expiryDate);
            const dateRef = moment().endOf('day').add(1, 'month');
            if (dateRef.isAfter(date)) {
                setColor('red');
                setExpiryText(date.format('DD.MM.YYYY'));
            } else {
                dateRef.add(2, 'month');
                if (dateRef.isAfter(date)) {
                    setColor('orange');
                    setExpiryText(date.format('DD.MM.YYYY'));
                }
            }
        } else {
            api.loadInternalPackage(id);
        }
    }, [medikationsplanContext.internalPackageMap])

    const teilbarkeitString = !internalPackage ? "wird geladen" : internalPackage.batch.id.medicinePZN.medicineM2.m2 in api.medicineM2Map ? getTeilbarkeitString(api.medicineM2Map[internalPackage.batch.id.medicinePZN.medicineM2.m2]) : (api.getMedicineM2(internalPackage.batch.id.medicinePZN.medicineM2.m2) && "wird geladen");

    return internalPackage && !internalPackage.active ? <></> : <Row>
        <ConditionalPopover trigger={"hover"} placement={"left"} content={!!internalPackage && <div className={"grid-auto"}>
            <span>ID:</span>
            <span>{internalPackage.id}</span>

            <span>PZN:</span>
            <span>{internalPackage.batch.id.medicinePZN.pzn}</span>

            <span>M2:</span>
            <span>{internalPackage.batch.id.medicinePZN.medicineM2.m2}</span>

            <span>DRF:</span>
            <span>{internalPackage.batch.id.medicinePZN.medicineM2.drf} ({internalPackage.batch.id.medicinePZN.medicineM2.drfShort})</span>

            <span>Teilbarkeit:</span>
            <span>{teilbarkeitString}</span>

            {!!expiryText && <>
                <span>Packungsverfall:</span>
                <span>{expiryText}</span>
            </>}
        </div>}><Col span={15}>{
            internalPackage ?
                <span style={{color}}>{internalPackage.batch.id.medicinePZN.medicineM2.name} ({internalPackage.batch.id.medicinePZN.medicineM2.drfShort}) {internalPackage.batch.id.medicinePZN.medicineM2.supplierShort} {mengeVorab > 0 && `(Vorab: ${mengeVorab})`} (x{internalPackage.factorBundle ? mengeToString(1/internalPackage.factorBundle, {putSpace: false}) : 0})</span> :
                <span>Packung #{id}</span>
        }</Col></ConditionalPopover>
        <Col span={7}>{teilbarkeitString}</Col>
        <Col span={2}>{!!internalPackage && <span style={{whiteSpace: "nowrap", marginLeft: 5}}>{internalPackage.amountRemaining}/{internalPackage.amountOriginal}</span>}</Col>
    </Row>
}

export default InternalPackage;