import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Dropdown, Menu, Switch, Table, Tabs} from "antd";
import {createAkteur} from "./AdressbuchDetail";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {getSorter} from "../../utilities/sortUtil";
import Highlighter from "react-highlight-words";
import {getUnitLangname, GRUPPEN, ROLLEN} from "../../config/entities";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import usePagination from "../../utilities/usePagination";
import {useAuth} from "../../utilities/useAuth";
import {useApi} from "../../utilities/useApi";
import AdressbuchContext from "../../contexts/AdressbuchContext";

const {TabPane} = Tabs;

const AdressbuchTable = ({}) => {
    const auth = useAuth();
    const api = useApi();
    const history = useHistory();

    const adressbuchContext = useContext(AdressbuchContext);

    const [units, setUnits] = useState(null);
    const [aktiv, setAktiv] = useState(true);

    const filterValue = useRef({});
    const filterTimeout = useRef(null);

    const {getColumnSearchProps} = useTableSearchFilter();

    const pagination = usePagination({pageSize: 50});

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        const {name} = query;
        setFilter({name});
    }, [history.location])

    useEffect(() => {
        const processUnits = (units => setUnits(units.filter(unit => unit.type === "Person" || unit.type === "Institution")));

        if (aktiv) {
            if (!api.akteureLoaded) {
                api.loadAkteure().then(([sorted]) => processUnits(sorted));
            } else {
                processUnits(api.akteure);
            }
        } else {
            const unitsNew = [];
            const promiseList = [];
            if (!api.akteureLoaded) {
                promiseList.push(api.loadAkteure());
            } else {
                unitsNew.push(...api.akteure);
            }

            if (!api.akteureLoadedInaktiv) {
                promiseList.push(api.loadAkteure(false));
            } else {
                unitsNew.push(...api.akteureInaktiv);
            }

            if (promiseList.length) {
                setUnits([]);
                Promise.all(promiseList).then((results) => {
                    for (const array of results) {
                        unitsNew.push(...array[0]);
                    }

                    processUnits(unitsNew);
                });
            } else {
                processUnits(unitsNew);
            }
        }
    }, [aktiv])

    const setFilter = (filterNew) => {
        return;

        if (filterTimeout.current) {
            clearTimeout(filterTimeout.current);
        }

        for (let key in filterNew) {
            if (filterNew[key]) {
                filterValue.current[key] = Array.isArray(filterNew[key]) ? filterNew[key] : [filterNew[key]];
            } else {
                delete filterValue.current[key];
            }
        }

        filterTimeout.current = setTimeout(() => {
            filterTimeout.current = null;

            const url = queryString.stringifyUrl({url: history.location.pathname, query: filterValue.current});

            if (url !== history.location.pathname + history.location.search) {
                history.replace(url);
            }
        }, 100);
    }

    const onFilter = (value, record, dataIndex) => {
        switch (dataIndex) {
            case "Name":
                const akteurLangname = getUnitLangname(record);
                const regex = value.split(/[, ]/).filter(v => v !== "").join(".*?");
                return new RegExp(regex, "i").test(akteurLangname);
        }
    };

    const columns = auth.developer ? [
        {
            key: "id",
            title: "ID",
            render: (unit, index) => unit.id,
            sorter: getSorter("id")
        }
    ] : [];
    columns.push(
        {
            key: "name",
            title: "Name",
            render: (unit, index) => {
                if (filterValue.current?.name?.length) {
                    return <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={filterValue.current.name[0].split(/[, ]/)?.filter(v => v !== "")}
                        autoEscape
                        textToHighlight={getUnitLangname(unit)}
                    />
                } else {
                    return getUnitLangname(unit);
                }
            },
            sorter: getSorter("unit", "name"),
            ...getColumnSearchProps({dataIndex: 'Name', onFilter, splitKeys: false, onChange: searchTextArray => {
                    setFilter({name: searchTextArray ? searchTextArray : null});
                }, initialValue: filterValue.current.name}),
            defaultFilteredValue: filterValue.current?.name?.length ? filterValue.current.name : null
        },
        {
            key: "type",
            title: "Typ",
            render: (unit, index) => {
                switch(unit.type) {
                    case "Person":
                        return "Person";
                    case "Institution":
                        return "Einrichtung";
                }
            },
            filters: [
                {
                    text: "Person",
                    value: "Person",
                },
                {
                    text: "Einrichtung",
                    value: "Institution",
                }
            ],
            onFilter: (typ, unit) => {
                return unit.type === typ;
            }
        },
        {
            key: "rollen",
            title: "Rollen",
            render: (unit, index) => {
                return unit.roles?.map(r => r.name || ROLLEN[r.id - 1].name || `Rolle #${r.id}`).join(", ");
            },
            filters: ROLLEN.map(r => ({ text: r.name, value: r.id })),
            onFilter: (id, unit) => {
                return unit.roles?.filter(r => r.id === id).length;
            },
        },
        {
            key: "gruppen",
            title: "Gruppen",
            render: (unit, index) => {
                return unit.groups?.map(g => g.name || GRUPPEN[g.id - 1].name || `Gruppe #${g.id}`).join(", ");
            },
            filters: GRUPPEN.map(g => ({ text: g.name, value: g.id })),
            onFilter: (id, unit) => {
                return unit.groups?.filter(r => r.id === id).length;
            },
        }
    );

    return <Tabs tabBarExtraContent={
        <div>
            <Dropdown overlay={
                <Menu onClick={e => {
                    adressbuchContext.setNeuType(createAkteur(e.key));
                    history.replace("/adressbuch/neu");
                }}>
                    <Menu.Item key="patient"><UserOutlined/> Person</Menu.Item>
                    <Menu.Item key="heim"><HomeOutlined/> Institution</Menu.Item>
                </Menu>

            }>
                <Button><UserAddOutlined />Neu: <DownOutlined /></Button>
            </Dropdown>

        </div>

    }>
        <TabPane tab={<></>} key="3">
            {units ?
                <>
                    <h6>Übersicht aller Einträge</h6>
                    <Switch checked={aktiv} onChange={setAktiv} style={{marginRight: 5}} />nur aktive Einträge anzeigen
                    <Table
                        sticky={{offestHeader: 100}}
                        rowKey={"id"}
                        dataSource={units}
                        columns={columns}

                        pagination={pagination}

                        onRow={(unit, index) => ({
                            style: {cursor: "pointer"},
                            onClick: () => history.push('/adressbuch/' + unit.id)
                        })}

                        footer={(rows) => <>
                            <Button onClick={() => printAdressListe(rows)}>aktuelle Ansicht drucken ({rows.length})</Button>
                            <Button onClick={() => printAdressListe(units)}>alle drucken ({units.length})</Button>
                        </>}
                    />
                </> :
                <h6>Keine Einträge vorhanden</h6>
            }
        </TabPane>
    </Tabs>
}

export default AdressbuchTable;

export const printAdressListe = (rows, title) => {
    const rowList = rows.map(unit => {
        return `<tr>
                        <td><pre>${unit.id}</pre></td>
                        <td><pre>${getUnitLangname(unit)}</pre></td>
                        <td><pre>${"Institution" === unit.type ? "Einrichtung" : unit.type}</pre></td>
                        <td><pre>${unit.roles?.map(r => r.name || ROLLEN[r.id - 1].name || `Rolle #${r.id}`).join(", ")}</pre></td>
                        <td><pre>${unit.groups?.map(g => g.name || GRUPPEN[g.id - 1].name || `Gruppe #${g.id}`).join(", ")}</pre></td>
                    </tr>`
    });

    const win = window.frames["printf"];
    win.document.write(`<body onload="window.print()"><h3>${title || "Adressbuch-Übersicht"}</h3><table><tr>
            <td>ID</td>
            <td>Name</td>
            <td>Typ</td>
            <td>Rollen</td>
            <td>Gruppen</td>
        </tr>${rowList.join("")}</table></body>`);
    win.document.close();
}