import React, {useContext, useEffect} from 'react'


import 'moment/locale/de'

import { Button, Row, Col, Tabs } from 'antd';

import { useDataNode } from './../../utilities/useDataNode';
import { InputBoolean } from './../atoms/InputBoolean';
import { InputTextfeld } from '../atoms/InputTextfeld';
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";

const { TabPane } = Tabs;


export const EditEinnahmehinweise = ({ value, onChange, onClose, triggerSave, setUpdated }) => {

    const {
        current: planzeile,
        status,
        handleChange,
        updated
    } = useDataNode({
        value,
        // sorter: dosierschemaSortByStart,
        // filter: (e) => (e.ende === null || moment(e.ende).diff() >= 0)
    })

    useEffect(() => {
        if (updated) setUpdated();
    }, [updated])

    useEffect(() => {
        if (triggerSave) handeSave();
    }, [triggerSave])

    const handeSave = () => {
        onChange(planzeile)
    }

    if (!status.ready) return <></>

    return <div style={{
        borderStyle: "solid",
        borderWidth: "3px",
        borderRadius: "10px",
        borderColor: "#97D3B8",
        padding: "5px"
    }}>

        <Tabs
            defaultActiveKey="edit"
            tabBarExtraContent={
                <div>
                    {/*<Button onClick={handeSave} disabled={!updated}><SaveOutlined /></Button>*/}
                    <Button onClick={onClose}><CloseOutlined /> Bearbeitung abbrechen</Button>
                </div>}
        >

            <TabPane tab="Einnahmehinweise" key="edit">
                <Row>
                    <Col span={8}>
                        <InputBoolean
                            value={planzeile.einnahmeNuechtern}
                            wahr="Nüchtern einnehmen"
                            falsch="Unanbhängig vom Essen"
                            label="einnahmeNuechtern"
                            onChange={handleChange}
                        />
                        <br />
                        <InputBoolean
                            value={planzeile.einnahmeVorDemSchlafen}
                            wahr="Vor dem Schlafen einnehmen"
                            falsch="Unanbhängig von der Schlafenszeit"
                            label="einnahmeVorDemSchlafen"
                            onChange={handleChange}
                        />
                    </Col>
                    <Col span={16}>
                        <InputTextfeld
                            value={planzeile.einnahmeFreitext}
                            label="einnahmeFreitext"
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </TabPane>

            <TabPane tab="Automatisch gernerierter Hinweistextes" key="vorschau">
                <Einnahmehinweise planzeile={planzeile} />
            </TabPane>
        </Tabs>

    </div >
}



export const Einnahmehinweise = ({ planzeile }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);

    return <div>
        {medikationsplanContext.dosierabschnittAktuell?.[planzeile.id]?.einnahmeFreitext}
    </div>
}
