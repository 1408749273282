import React, { useEffect, useState } from 'react'
import { callApiAsync } from '../../utilities/myApi_deprecated';
import { useDataAccess } from '../../utilities/useDataAccess'
import { Fallback } from '../../config/fallbackPages'
import { getUnitLangname } from '../../config/entities'

import { akteureApi } from '../../config/apiConfig'
import { useAuth } from '../../utilities/useAuth';
import { Link } from 'react-router-dom';
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import {Checkbox, Input} from "antd";

export const AdressbuchInstitution = ({ ...props }) => {
    const akteur = useDataAccess({
        ...props,
    })

    const auth = useAuth()
    const [bewohner, setBewohner] = useState([])

    useEffect(() => {
        if (!akteur.isReady || !akteur.current?.id) return

        console.log("Akteur geladen, lade jetzt die Bewohner für " + akteur.current.id)
        loadBewohner(akteur.current.id);
    }, [akteur.current])

    const loadBewohner = async (id) => {
        const response = await callApiAsync(auth, akteureApi.getByPflegeheim(id))
        if (response.data.RESULT === 'SUCCESS') {
            setBewohner(response.data.OBJECT)
        }
        else {
            console.error(JSON.stringify(response))
        }
    }


    if (!akteur.isReady)
        return Fallback(akteur)


    return <div className="adressbuch-stammdaten">
        <h4>Insitution</h4>
        <div className={"flex"}>
            Name: <Input
                disabled={!akteur.current.active}
                value={akteur.current.name}
                onChange={e => akteur.handleChange('name', e.target.value)}
            />
        </div>
        <div className={"flex"}>
            Daten vollständig: <Checkbox
                disabled={!akteur.current.active}
                checked={akteur.current.dataComplete}
                onChange={e => akteur.handleChange('dataComplete', e.target.checked)}
            />
        </div>

        <h4>Bewohnerliste:</h4>
        <ul>
            {bewohner.map(b =>
                <li key={b.id}>
                    {b.station &&
                        <span>Station: {b.station.name} </span>
                    }
                    <Link to={'/adressbuch/' + b.id} >
                        {getUnitLangname(b)}                        <SearchOutlined />
                    </Link>
                </li>
            )}
        </ul>
    </div >
}
