import React from 'react'
import { useDataAccess } from '../../utilities/useDataAccess'
import { Fallback } from '../../config/fallbackPages'

import { Select } from 'antd';
import { ROLLEN, GRUPPEN } from '../../config/entities';
const { Option } = Select;


export const AdressbuchUnit = ({ ...props }) => {

    const akteur = useDataAccess({
        ...props,
    })

    if (!akteur.isReady)
        return Fallback(akteur)

    return <div>
        <hr />
        <div style={{display: "grid", gridTemplateColumns: "max-content auto", gap: 15}}>
            <span>Rollen:</span>
            <Select
                disabled={!akteur.current.active}
                mode="multiple"
                placeholder="Zugewiesene Rollen"
                value={akteur.current.roles?.map(r => r.id)}
                onChange={(e) => {
                    akteur.handleChange('roles',
                        e.map(o => { return { id: o } })
                    )
                }}
            >
                {ROLLEN.map(rolle =>
                    <Option key={rolle.id} value={rolle.id}>{rolle.name}</Option>
                )}
            </Select>

            <span>Gruppen:</span>
            <Select
                disabled={!akteur.current.active}
                mode="multiple"
                placeholder="Zugewiesene Gruppen"
                value={akteur.current.groups?.map(g => g.id)}
                onChange={(e) => {
                    akteur.handleChange('groups',
                        e.map(o => { return { id: o } })
                    )
                }}
            >
                {GRUPPEN.map(gruppe =>
                    <Option key={gruppe.id} value={gruppe.id}>{gruppe.name}</Option>
                )}
            </Select>
        </div>
    </div >
}
