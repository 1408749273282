import React, {useContext, useEffect, useRef} from "react";
import {useAuth} from "./useAuth";
import {callApiAsync, CancelablePromise} from "./apiUtil";
import {lagerApi} from "../config/apiConfig";
import MedikationsplanContext from "../contexts/MedikationsplanContext";
import {fetchBestaende} from "../config/fetchApiConfiguration";
import ApiContext from "../contexts/ApiContext";
import moment from "moment";


export const useApiBestaende = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const apiContext = useContext(ApiContext);

    useEffect(() => {
        if (medikationsplanContext.reloadBestaende) {
            medikationsplanContext.setReloadBestaende(false);
            // todo bestände neu laden.
        }
    }, [medikationsplanContext.reloadBestaende])

    const loadInternalPackage = async (packungId, refreshToken=true) => {
        if (apiContext.refs.loadingInternalPackage?.[packungId]) {
            return await apiContext.refs.loadingInternalPackage[packungId];
        }

        let internalPackage;

        // Daten aus Cache laden
        if (apiContext.bestaendeData.internalPackageLoaded?.[packungId] && moment(apiContext.bestaendeData.internalPackageLoaded[packungId]).add(5, 'minutes').isAfter(moment())) {
            internalPackage = apiContext.bestaendeData.internalPackageMap[packungId];
        } else {
            apiContext.refs.loadingInternalPackage = {...apiContext.refs.loadingInternalPackage, [packungId] : callApiAsync({
                    url: lagerApi.getPackungById(packungId, true),
                    auth,
                    refreshToken,
                    onError: res => res.data.EXCEPTION_CLASS === "de.kushiworks.java.verblisterung.ejb.db.util.CustomExceptions$NoResultException" // Keine Fehlermeldung, wenn Packung nicht in DB gefunden wurde
                })};

            const response = await apiContext.refs.loadingInternalPackage[packungId];
            internalPackage = response.data.OBJECT;

            apiContext.setBestaendeData(prev => ({
                ...prev,
                internalPackageMap: {...prev.internalPackageMap, [internalPackage.id]: internalPackage},
                internalPakageLoaded: {...prev.internalPackageLoaded, [internalPackage.id]: Date.now()}
            }));
        }

        if (internalPackage.owner?.id === medikationsplanContext.medikationsplan?.patient?.id) {
            medikationsplanContext.setInternalPackageMap(prev => ({...prev, [packungId]: internalPackage}));
        }

        delete apiContext.refs.loadingInternalPackage[packungId];
        return internalPackage;
    }

    const loadBestaende = (patientId) => {
        if (!patientId) return null;

        medikationsplanContext.setBestaendeLoaded(false);
        if (apiContext.refs.loadBestaende) return apiContext.refs.loadBestaende;

        apiContext.refs.loadBestaende = new CancelablePromise(async (resolve) => {
            let bestandList;

            // Daten aus Cache laden
            if (apiContext.bestaendeData.bestandListLoaded?.[patientId] && moment(apiContext.bestaendeData.bestandListLoaded[patientId]).add(5, 'minutes').isAfter(moment())) {
                bestandList = apiContext.bestaendeData.bestandListMap[patientId];
            } else {
                const result = await callApiAsync({auth, url: fetchBestaende(patientId)});
                bestandList = result.data.OBJECT;

                apiContext.setBestaendeData(prev => ({
                    ...prev,
                    bestandListMap: {...prev.bestandListMap, [patientId]: bestandList},
                    bestandListLoaded: {...prev.bestandListLoaded, [patientId]: Date.now()}
                }));
            }

            if (medikationsplanContext.medikationsplan?.patient?.id === patientId) {
                medikationsplanContext.setBestaendeLoaded(true);
                medikationsplanContext.setBestandList(bestandList);
            }

            resolve(bestandList);
            delete apiContext.refs.loadBestaende;
        });
        return apiContext.refs.loadBestaende;
    }

    return {
        loadInternalPackage,
        loadBestaende,
    }
}