import React, {useContext, useEffect, useRef, useState} from "react";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {useApi} from "../../utilities/useApi";
import {ModusRezeptanforderung, Rezeptanforderungen} from "./Rezeptanforderungen";

const MedikationsplanAnforderungenTable = () => {
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [anforderungenList, setAnforderungenList] = useState(null);
    const patientIdsLoading = useRef({});

    useEffect(() => {
        const patientId = medikationsplanContext.medikationsplan?.patient?.id;
        if (patientId) {
            if (patientId in api.anforderungenAll) {
                if (patientIdsLoading.current[patientId]) {
                    delete patientIdsLoading.current[patientId];
                }

                setAnforderungenList(api.anforderungenAll[patientId]);
            } else {
                patientIdsLoading.current[patientId] = true;
                api.loadAnforderungenAll(patientId);
            }
        }
    }, [medikationsplanContext.medikationsplan, api.anforderungenAll?.[medikationsplanContext.medikationsplan?.patient?.id]])

    return <Rezeptanforderungen
        loading={!anforderungenList}
        anforderungen={anforderungenList}
        modus={ModusRezeptanforderung.patient}
    />
}

export default MedikationsplanAnforderungenTable;