import React from 'react'

import { Select } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';

const { Option } = Select;

export const InputWochentag = ({
    value,
    label,
    onChange
}) => {

    const options = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

    const handleChange = (wochentagInt) => {
        onChange(wochentagInt, label);
    }
    

    return <Select defaultValue={value} style={{ width: 150 }} onChange={handleChange}>
        {options.map((wochentagString, index) => {
            return <Option key={index} style={{ width: "150px" }} value={index+1}>{wochentagString}</Option>
        })}
    </Select>
}

