import {useApiAnforderungen} from "./useApiAnforderungen";
import {useApiPlanzeilen} from "./useApiPlanzeilen";
import {useApiDokumente} from "./useApiDokumente";
import {useApiAkteure} from "./useApiAkteure";
import {useApiNotizen} from "./useApiNotizen";
import {useApiBestaende} from "./useApiBestaende";
import useApiAnsprueche from "./useApiAnsprueche";
import useApiZeitraum from "./useApiZeitraum";
import useApiArzneimittel from "./useApiArzneimittel";
import useApiBuendel from "./useApiBuendel";
import useApiMedikationsplan from "./useApiMedikationsplan";
import {useApiProblemfaelle} from "./useApiProblemfaelle";

export const useApi = () => {
    const apiAnforderungen = useApiAnforderungen();
    const apiPlanzeilen = useApiPlanzeilen();
    const apiDokumente = useApiDokumente();
    const apiAkteure = useApiAkteure();
    const apiNotizen = useApiNotizen();
    const apiBestaende = useApiBestaende();
    const apiAnsprueche = useApiAnsprueche();
    const apiZeitraum = useApiZeitraum();
    const apiArzneimittel = useApiArzneimittel();
    const apiBuendel = useApiBuendel();
    const apiMedikationsplan = useApiMedikationsplan();
    const apiProblemfaelle = useApiProblemfaelle();

    return {
        ...apiAnforderungen,
        ...apiPlanzeilen,
        ...apiDokumente,
        ...apiAkteure,
        ...apiNotizen,
        ...apiBestaende,
        ...apiAnsprueche,
        ...apiZeitraum,
        ...apiArzneimittel,
        ...apiBuendel,
        ...apiMedikationsplan,
        ...apiProblemfaelle,
    }
}