import {useCallback, useContext, useEffect, useState} from "react";
import {
    fetchAnforderungenByPatientId, fetchAnforderungenProblemfaelle,
    fetchGesammelteAnforderungen,
    fetchOffeneAnforderungen
} from "../config/fetchApiConfiguration";
import moment from "moment";
import ApiContext from "../contexts/ApiContext";
import {callApiAsync} from "./apiUtil";
import {useAuth} from "./useAuth";
import {GlobalContext} from "../config/globalContext";
import {anforderungApi} from "../config/apiConfig";
import medikationsplanContext from "../contexts/MedikationsplanContext";

export const useApiAnforderungen = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);
    const globalContext = useContext(GlobalContext);

    const [anforderungenFetched, setAnforderungenFetched] = useState(null);
    const [anforderungenOffen, setAnforderungenOffen] = useState(apiContext.anforderungenData.anforderungenOffen);
    const [anforderungenGesammelt, setAnforderungenGesammelt] = useState(apiContext.anforderungenData.anforderungenGesammelt);
    const [mahnungenNoetig, setMahnungenNoetig] = useState(apiContext.anforderungenData.mahnungenNoetig);

    useEffect(() => {
        const indexMap = {};
        if (anforderungenOffen?.length) {
            for (let i = 0; i < anforderungenOffen.length; i++) {
                indexMap[anforderungenOffen[i].id] = i;
            }
        }

        apiContext.setAnforderungenData(prev => ({...prev, anforderungenOffen, indexMap: {...prev.indexMap, anforderungenOffen: indexMap}}));
    }, [anforderungenOffen])

    useEffect(() => {
        const indexMap = {};
        if (anforderungenGesammelt?.length) {
            for (let i = 0; i < anforderungenGesammelt.length; i++) {
                indexMap[anforderungenGesammelt[i].id] = i;
            }
        }

        apiContext.setAnforderungenData(prev => ({...prev, anforderungenGesammelt, indexMap: {...prev.indexMap, anforderungenGesammelt: indexMap}}));
    }, [anforderungenGesammelt])

    useEffect(() => {
        const indexMap = {};
        if (mahnungenNoetig?.length) {
            for (let i = 0; i < mahnungenNoetig.length; i++) {
                indexMap[mahnungenNoetig[i].id] = i;
            }
        }

        apiContext.setAnforderungenData(prev => ({...prev, mahnungenNoetig, indexMap: {...prev.indexMap, mahnungenNoetig: indexMap}}));
    }, [mahnungenNoetig])

    useEffect(() => {
        if (anforderungenFetched) {
            const mahnungenNoetigNeu = [];
            const anforderungenOffenNeu = [];
            const heute = globalContext.heute;

            const indexMap = {}
            for (let i = 0; i < anforderungenFetched.length; i++) {
                const anforderung = anforderungenFetched[i];
                indexMap[anforderung.id] = i;

                let datumAnforderung = anforderung.datumMahnung2 || anforderung.datumMahnung1 || anforderung.datumAngefordert;
                datumAnforderung = datumAnforderung ? moment(datumAnforderung).startOf("day") : null;

                anforderungenOffenNeu.push(anforderung);

                if (
                    !datumAnforderung ||                                 // es wurde noch nichts angefordert (oder)
                    heute.diff(datumAnforderung, "day") > 5    // die Anforderung/letzte Mahnung ist älter als 6 Tage
                ) {
                    const planzeile = anforderung.planzeile;
                    if (
                        datumAnforderung &&                                                                                             // es wurde bereits angefordert
                        !anforderung.datumMahnung3 &&                                                                                   // es wurde noch nicht bereits 3mal gemahnt
                        (!anforderung.datumFaxempfang || heute.diff(moment(anforderung.datumFaxempfang).startOf('day')) > 5)   // es ist kein Fax angekommen oder der Faxempfang ist älter als 6 Tage
                    ) {
                        mahnungenNoetigNeu.push(anforderung);
                    }
                }
            }

            setAnforderungenOffen(anforderungenOffenNeu);
            setMahnungenNoetig(mahnungenNoetigNeu);
        }
    }, [anforderungenFetched])

    const loadAnforderungenOffen = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchOffeneAnforderungen(), refreshToken});
        setAnforderungenFetched(response.data.OBJECT);

        return response.data.OBJECT;
    }

    const loadAnforderungenGesammelt = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchGesammelteAnforderungen(), refreshToken});
        setAnforderungenGesammelt(response.data.OBJECT);
    }

    const addAnforderungGesammelt = (anforderung) => {
        setAnforderungenGesammelt(prev => [...prev, anforderung]);
    }

    const anforderungAustauschenOderAnhaengen = (anforderung, list) => {
        let anforderungSeen = false;
        const neu = [...(list || []).map(a => {
            if (a.id === anforderung.id) {
                anforderungSeen = true;
                return anforderung;
            }

            return a;
        }), ...(anforderungSeen ? [] : [anforderung])];

        return neu;
    }

    const updateAnforderung = async (anforderung) => {
        let data = null;
        const indexMap = apiContext.anforderungenData.indexMap || {};

        // gesammelt
        if (anforderung.id in (indexMap.anforderungenGesammelt || {})) {
            data = anforderung;
            setAnforderungenGesammelt(anforderungAustauschenOderAnhaengen(anforderung, apiContext.anforderungenData.anforderungenGesammelt));
        }

        // offen
        if (anforderung.id in (indexMap.anforderungenOffen || {})) {
            data = anforderung;
            setAnforderungenOffen(anforderungAustauschenOderAnhaengen(anforderung, apiContext.anforderungenData.anforderungenOffen));
        }

        // mahnungenNoetig
        if (anforderung.id in (indexMap.mahnungenNoetig || {})) {
            data = anforderung;
            setMahnungenNoetig(anforderungAustauschenOderAnhaengen(anforderung, apiContext.anforderungenData.mahnungenNoetig));
        }

        // Alle Anforderungen zu Patient
        const patientId = anforderung.planzeile?.medikationsplan?.patient?.id;
        if (patientId && apiContext.anforderungenData.anforderungenAll?.[patientId]) {
            data = anforderung;
            apiContext.setAnforderungenData(prev => ({...prev, anforderungenAll: {...prev.anforderungenAll, [patientId]: anforderungAustauschenOderAnhaengen(anforderung, prev.anforderungenAll[patientId])}}));
        }

        if (apiContext.anforderungenData.problemfaelleKeyMap?.[anforderung.id]) {
            data = anforderung;
            apiContext.setAnforderungenData(prev => {
                const next = {...prev};

                const map = next.problemfaelleKeyMap[anforderung.id];
                for (const key of map) {
                    next.problemfaelle[key] = next.problemfaelle[key].map(a => a.id === anforderung.id ? anforderung : a);
                }

                return next;
            });
        }

        if (data) {
            const response = await callApiAsync({
                url: anforderungApi.put(data.id),
                data,
                method: "put",
                auth
            });
            return response.data.OBJECT;
        }

        return null;
    }

    const loadAnforderungenAll = async (patientId, refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchAnforderungenByPatientId(patientId), refreshToken});
        apiContext.setAnforderungenData(prev => ({...prev, anforderungenAll: {...prev.anforderungenAll, [patientId]: response.data.OBJECT}}));

        return response.data.OBJECT;
    }

    const loadAnforderungenProblemfaelle = async (refreshToken=true, highPriority=false) => {
        const response = await callApiAsync({auth, url: fetchAnforderungenProblemfaelle(highPriority), refreshToken});
        const problemfaelle = response.data.OBJECT;

        const problemfaelleKeyMap = {};
        for (const key in problemfaelle) {
            const anforderungen = problemfaelle[key];
            for (const anforderung of anforderungen) {
                if (!problemfaelleKeyMap[anforderung.id]) problemfaelleKeyMap[anforderung.id] = [];
                problemfaelleKeyMap[anforderung.id].push(key);
            }
        }

        apiContext.setAnforderungenData(prev => ({...prev, problemfaelle, problemfaelleKeyMap}));
        return problemfaelle;
    }

    return {
        anforderungenOffen: apiContext.anforderungenData.anforderungenOffen || [],
        anforderungenOffenLoaded: !!anforderungenOffen,
        loadAnforderungenOffen,

        anforderungenGesammelt: apiContext.anforderungenData.anforderungenGesammelt || [],
        anforderungenGesammeltLoaded: !!anforderungenGesammelt,
        addAnforderungGesammelt,
        loadAnforderungenGesammelt,

        mahnungNoetig: apiContext.anforderungenData.mahnungenNoetig || [],

        anforderungenAll: apiContext.anforderungenData.anforderungenAll || {},
        loadAnforderungenAll,

        updateAnforderung,

        loadAnforderungenProblemfaelle,
        anforderungenProblemfaelleLoaded: !!apiContext.anforderungenData.problemfaelle,
        anforderungenUeberfaelligFuerVerblisterung: apiContext.anforderungenData.problemfaelle?.ueberfaelligFuerVerblisterung || [],
        anforderungenPatientenMitUnterversorgung: apiContext.anforderungenData.problemfaelle?.patientenMitUnterversorgung || [],
        anforderungenGefaxteRezepteUeberfaellig: apiContext.anforderungenData.problemfaelle?.gefaxteRezepteUeberfaellig || [],
        anforderungenAutomatischesMahnwesenAusgeschoepft: apiContext.anforderungenData.problemfaelle?.automatischesMahnwesenAusgeschoepft || [],
        anforderungenTrotzAusreichendemBestand: apiContext.anforderungenData.problemfaelle?.anforderungenTrotzAusreichendemBestand || [],
        anforderungenGeloeschtePlanzeile: apiContext.anforderungenData.problemfaelle?.planzeileGeloescht || [],
        anforderungenPatientInKrankenhaus: apiContext.anforderungenData.problemfaelle?.patientInKrankenhaus || [],
        anforderungenPatientVerstorben: apiContext.anforderungenData.problemfaelle?.patientVerstorben || [],
        anforderungenPatientGeloescht: apiContext.anforderungenData.problemfaelle?.patientGeloescht || [],
    }
}