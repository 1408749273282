import React, {useContext, useEffect, useRef, useState} from "react";
import {DatePicker, Table} from "antd";
import {useFetch} from "../../utilities/useFetch";
import {anforderungApi, arzneimittelApi, dokumenteApi, medikationsplanApi, planzeilenApi} from "../../config/apiConfig";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import * as moment from "moment";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import usePagination from "../../utilities/usePagination";
import {useOnClickOutside} from "../../utilities/useOnClickOutside";
import {GlobalContext} from "../../config/globalContext";
import NotizContext from "../../contexts/NotizContext";
import {useApi} from "../../utilities/useApi";
import DokumenteContext from "../../contexts/DokumenteContext";

const MedikationsplanKopfLogs = () => {
    const auth = useAuth();
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const globalContext = useContext(GlobalContext);
    const notizContext = useContext(NotizContext);
    const dokumenteContext = useContext(DokumenteContext);

    const heute = globalContext.heute;
    const morgen = moment(globalContext.heute).add(1, 'day');

    const [planzeilenMap, setPlanzeilenMap] = useState({});
    const [anforderungenMap, setAnforderungenMap] = useState({});
    const [notizMap, setNotizMap] = useState({});

    const planzeileIdsRequested = useRef([]);
    const anforderungIdsRequested = useRef([]);
    const arzneimittelMap = useRef({});
    const dokumentMap = useRef({});

    const [refStartPopup, setRefStartPopup] = useState({current:null});
    const [refEndePopup, setRefEndePopup] = useState({current:null});
    const [editDatumReferenz, setEditDatumReferenz] = useState(null);
    const [datumReferenzStart, setDatumReferenzStart] = useState(moment(heute).subtract(1, 'month'));
    const [datumReferenzEnde, setDatumReferenzEnde] = useState(moment(heute).endOf('day'));

    const refStart = useRef();
    const refEnde = useRef();
    const refCalendar = useRef();

    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();

    const {
        data: logEntries,
        reload,
        loading
    } = useFetch(medikationsplanApi.getLogs(medikationsplanContext.medikationsplan?.id, datumReferenzStart, datumReferenzEnde));

    useOnClickOutside(() => {
        if (editDatumReferenz) setEditDatumReferenz(null);
    }, [refStart, refStartPopup, refEnde, refEndePopup, refCalendar])

    useEffect(() => {
        setNotizMap(notizContext.notizMap[medikationsplanContext.medikationsplan.patient.id] || {});
    }, [notizContext.notizMap[medikationsplanContext.medikationsplan.patient.id]])



    const getArzneimittel = (arzneimittelId) => {
        let string = arzneimittelMap.current[arzneimittelId];
        if (!string) {
            arzneimittelMap.current = {...arzneimittelMap.current, [arzneimittelId]: 'wird geladen'};
            callApiAsync({url: arzneimittelApi.getById(arzneimittelId), auth})
                .then(response => arzneimittelMap.current = {...arzneimittelMap.current, [arzneimittelId]: getArzneimittelLangname(response.data.OBJECT)});

            string = 'wird geladen';
        }

        return string;
    }

    const getDokument = (dokumentId) => {
        let dokument = dokumentMap.current[dokumentId];
        if (!dokument) {
            dokumentMap.current = {...dokumentMap.current, [dokumentId]: {dokument: {titel: 'wird geladen'}}}
            callApiAsync({url: dokumenteApi.getById(dokumentId), auth})
                .then(response => dokumentMap.current = {...dokumentMap.current, [dokumentId]: response.data.OBJECT});
        }

        return dokumentMap.current[dokumentId];
    }

    const getText = logEntry => {
        const json = JSON.parse(logEntry.dataJSON);
        const planzeileId = json.planzeileId;
        let planzeile = (medikationsplanContext.planzeilenMap ? medikationsplanContext.planzeilenMap[planzeileId] : null) || planzeilenMap[planzeileId];

        if (!planzeile && planzeileId && !planzeileIdsRequested.current.includes(planzeileId)) {
            planzeileIdsRequested.current.push(planzeileId);

            callApiAsync({url: planzeilenApi.getById(planzeileId, true), auth})
                .then(response => setPlanzeilenMap(prev => ({...prev, [planzeileId]: response.data.OBJECT})))
        }

        const langname = planzeile && getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext);
        switch(logEntry.cause) {
            case "MEDIPLAN_PLANZEILE":
                switch (json.type) {
                    case "ERSTELLT":
                        return `Planzeile #${planzeileId}${langname ? ` (${langname})` : ""} wurde erstellt`;
                    case "BEARBEITET":
                        return `Planzeile #${planzeileId}${langname ? ` (${langname})` : ""} wurde bearbeitet`;
                    case "GELOESCHT":
                        return `Planzeile #${planzeileId}${langname ? ` (${langname})` : ""} wurde gelöscht`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_ABGABEPLAN":
                switch (json.type) {
                    case "CALCULATE":
                        return `Berechnung der Reichweite für ${langname ? `"${langname}"` : `Planzeile #${planzeileId}`} ab ${moment(json.start).format("DD.MM.YYYY")}${json.ende ? " bis " + moment(json.ende).format("DD.MM.YYYY") : ""}`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_ANFORDERUNG":
                const anforderungId = json.anforderungId;

                const anforderung = anforderungenMap[anforderungId];
                if (!anforderung && !anforderungIdsRequested.current.includes(anforderungId)) {
                    anforderungIdsRequested.current.push(anforderungId);

                    callApiAsync({url: anforderungApi.getById(anforderungId), auth})
                        .then(response => setAnforderungenMap(prev => ({...prev, [anforderungId]: response.data.OBJECT})));
                }

                planzeile = anforderung && anforderung.planzeile;
                if (planzeile && !planzeileIdsRequested.current.includes(planzeile.id)) {
                    planzeileIdsRequested.current.push(planzeile.id);
                    setPlanzeilenMap(prev => ({...prev, [planzeile.id]: planzeile}));
                }

                switch (json.type) {
                    case "ERSTELLT":
                        return `Anforderung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} erstellt`;
                    case "ABGEBROCHEN":
                        return `Anforderung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} storniert`;
                    case "GESCHLOSSEN":
                        return `Anforderung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} erfüllt`;
                    case "TEIL_GESCHLOSSEN":
                        return `Faxempfang von Anforderung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} eingetragen`;
                    case "GEMAHNT":
                        return `Anforderung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} gemahnt`;
                    case "MITTEILUNG":
                        return `Mitteilung${planzeile ? " für " + getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext) : ""} erstellt`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_PLANZEILE_DOSIERABSCHNITT":
                switch (json.type) {
                    case "INSERT":
                        return `Dosierung zu Planzeile #${planzeileId}${langname ? ` (${langname})` : ""}: ${json.text} wurde erstellt`;
                    case "UPDATE":
                        return `Dosierung zu Planzeile #${planzeileId}${langname ? ` (${langname})` : ""}: ${json.text} wurde bearbeitet`;
                    case "REMOVE":
                        return `Dosierung zu Planzeile #${planzeileId}${langname ? ` (${langname})` : ""}: ${json.text} wurde gelöscht`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_NOTIZ":
                switch (json.type) {
                    case "INSERT":
                        return `Notiz #${json.notizId}${notizMap[json.notizId] ? ` (${notizMap[json.notizId].titel})` : ''} wurde erstellt`;
                    case "UPDATE":
                        return `Notiz #${json.notizId}${notizMap[json.notizId] ? ` (${notizMap[json.notizId].titel})` : ''} wurde bearbeitet`;
                    case "REMOVE":
                        return `Notiz #${json.notizId}${notizMap[json.notizId] ? ` (${notizMap[json.notizId].titel})` : ''} wurde gelöscht`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_PLANZEILE_ARZNEIMITTEL":
                if (json.arzneimittelIdAlt) return `In Planzeile #${planzeileId} wurde das Arzneimittel von '${getArzneimittel(json.arzneimittelIdAlt)}' zu '${langname}' geändert`;
                return `In Planzeile #${planzeileId} wurde '${langname}' als Arzneimittel eingetragen`;
            case "MEDIPLAN_DOKUMENT":
                const dokument = getDokument(json.dokumentId);
                switch (json.type) {
                    case "INSERT":
                        return `Dokument #${json.dokumentId} (${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}) wurde erstellt`;
                    case "UPDATE":
                        return `Dokument #${json.dokumentId} (${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}) wurde bearbeitet`;
                    case "REMOVE":
                        return `Dokument #${json.dokumentId} (${dokument.typ || dokument.dokument.titel || dokument.dokument.dateiname}${dokument.typ && dokument.dokument.titel ? ` [${dokument.dokument.titel}]` : ""}) wurde gelöscht`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            case "MEDIPLAN_ZEITRAUM":
                switch (json.typ) {
                    case "ERSTELLT":
                        return `Zeitraum ${json.text} wurde erstellt`;
                    case "BEARBEITET":
                        return `Zeitraum ${json.text} wurde bearbeitet`;
                    case "GELOESCHT":
                        return `Zeitraum ${json.text} wurde gelöscht`;
                    default:
                        return `${logEntry.cause}: ${logEntry.dataJSON}`;
                }
            default:
                return `${logEntry.cause}: ${logEntry.dataJSON}`;
        }
    }



    const columns = [
        {
            title: "Datum",
            key: "timestamp",
            width: 150,
            render: (logEntry) => {
                const creationDate = logEntry.id.creationDate;
                return moment({...creationDate.date, month: creationDate.date.month-1, ...creationDate.time}).format("DD.MM.YYYY HH:mm:ss");
            },
        },
        {
            title: "Account",
            key: "account",
            width: 150,
            render: (logEntry) => {
                return logEntry.id.user ? logEntry.id.user.loginName : "K/A";
            },
            ...getColumnSearchProps({dataIndex: "Account", onFilter: (value, logEntry) => logEntry.id.user.loginName.toLowerCase().includes(value.toLowerCase())})
        },
        {
            title: "Typ",
            key: 'typ',
            width: 150,
            render: logEntry => {
                switch (logEntry.cause) {
                    case "MEDIPLAN_PLANZEILE":
                        return 'Planzeile'
                    case "MEDIPLAN_ABGABEPLAN":
                        return 'Abgabeplan'
                    case "MEDIPLAN_ANFORDERUNG":
                        return 'Anforderung'
                    case "MEDIPLAN_PLANZEILE_DOSIERABSCHNITT":
                        return 'Dosierabschnitt'
                    case "MEDIPLAN_NOTIZ":
                        return 'Notiz'
                    case "MEDIPLAN_PLANZEILE_ARZNEIMITTEL":
                        return 'Arzneimittel'
                    case "MEDIPLAN_DOKUMENT":
                        return 'Dokument'
                    case "MEDIPLAN_ZEITRAUM":
                        return 'Zeitraum'
                    default:
                        return 'Unbekannt'
                }
            },
            filters: [
                {text: 'Planzeile', value: 'MEDIPLAN_PLANZEILE'},
                {text: 'Abgabeplan', value: 'MEDIPLAN_ABGABEPLAN'},
                {text: 'Anforderung', value: 'MEDIPLAN_ANFORDERUNG'},
                {text: 'Dosierabschnitt', value: 'MEDIPLAN_PLANZEILE_DOSIERABSCHNITT'},
                {text: 'Notiz', value: 'MEDIPLAN_NOTIZ'},
                {text: 'Arzneimittel', value: 'MEDIPLAN_PLANZEILE_ARZNEIMITTEL'},
                {text: 'Dokument', value: 'MEDIPLAN_DOKUMENT'},
                {text: 'Zeitraum', value: 'MEDIPLAN_ZEITRAUM'},
            ],
            onFilter: (value, logEntry) => logEntry.cause === value
        },
        {
            title: "Änderung",
            key: "content",
            render: getText,
            ...getColumnSearchProps({dataIndex: "text", onFilter: (value, logEntry) => getText(logEntry).toLowerCase().includes(value.toLowerCase())})
        }
    ]

    return <>
        <h1>
            Historie im Zeitraum <span className={"datepicker-without-input"}>
                <span onClick={e => refCalendar.current = e.target}>
                    <DatePicker
                        format={"DD.MM.YYYY"}
                        onChange={datum => {
                            setDatumReferenzStart(datum.startOf('day'));
                        }}
                        disabledDate={date => date.isAfter(heute)}
                        value={datumReferenzStart}
                        open={editDatumReferenz === "start"}
                        getPopupContainer={(popupContainer) => {
                            setRefStartPopup({current: popupContainer});
                            return popupContainer.parentElement;
                        }}
                        style={{paddingTop: 20}}
                    />
                </span>
                <span onClick={() => setEditDatumReferenz("start")} style={{cursor: "pointer"}} ref={refStart}>{datumReferenzStart.format("DD.MM.YYYY")}</span>
            </span> - <span className={"datepicker-without-input"}>
                <span onClick={e => refCalendar.current = e.target}>
                    <DatePicker
                        format={"DD.MM.YYYY"}
                        onChange={datum => setDatumReferenzEnde(datum.endOf('day'))}
                        disabledDate={date => datumReferenzStart.isAfter(date) || !date.isBefore(morgen)}
                        value={datumReferenzEnde}
                        open={editDatumReferenz === "ende"}
                        getPopupContainer={(popupContainer) => {
                            setRefEndePopup({current: popupContainer});
                            return popupContainer.parentElement;
                        }}
                        style={{paddingTop: 20}}
                    />
                </span>
                <span onClick={() => setEditDatumReferenz("ende")} style={{cursor: "pointer"}} ref={refEnde}>{datumReferenzEnde.format("DD.MM.YYYY")}</span>
            </span>
        </h1>

        <Table
            loading={loading}
            dataSource={logEntries}
            columns={columns}
            size={"small"}
            pagination={usePagination({pageSize: 25})}
            rowKey={l => JSON.stringify(l.id)}
        />
    </>
}

export default MedikationsplanKopfLogs;