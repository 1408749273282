import React, {useContext, useEffect, useRef, useState} from 'react'

import {Table, Tag, Button, Divider, Modal, Menu, Select, DatePicker, Switch, Input} from 'antd';
import { getUnitLangname } from '../../config/entities';

import moment from 'moment';


import { usePost } from '../../utilities/usePost';
import {
    postAnforderungenVersenden,
    postAnforderungenAbbrechen,
    postAnforderungenSchliessen,
    postAnforderungenMahnen,
    postAnforderungenVersendeStornoMeldung
} from '../../config/postApiConfiguration';
import {nl2br} from "../../utilities/formatUtil";
import {Link} from "react-router-dom";
import {Tooltip} from "../atoms/Tooltip";
import {anforderungApi, nachrichtApi, planzeilenApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import {DokumentVerknuepfungTypen, getSorter} from "../../utilities/sortUtil";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {ArzneimittelPicker, getArzneimittelLangname} from "../atoms/Arzneimittel";
import usePagination from "../../utilities/usePagination";
import useApiZeitraum, {
    getCurrentZeitraumColor,
    getCurrentZeitraumTyp,
    getZeitraumColor
} from "../../utilities/useApiZeitraum";
import {CheckOutlined, EditOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {callApiAsync} from "../../utilities/apiUtil";
import {useApi} from "../../utilities/useApi";
import UnitLangname from "../atoms/UnitLangname";
import {naechsterWochentag} from "../../utilities/myDates";
import {Popover} from "../atoms/Popover";
import FaxempfangEintragenModal from "./FaxempfangEintragenModal";
import * as ReactDOMServer from "react-dom/server";
import {GlobalContext} from "../../config/globalContext";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {AkteurPicker} from "../atoms/AkteurPicker";
import MitteilungContext from "../../contexts/MitteilungContext";
import UnitStationLangname from "../atoms/UnitStationLangname";
import UnitCareFacilityLangname from "../atoms/UnitCareFacilityLangname";

export const Rezeptanforderungen = ({ anforderungen, loading, modus, title="Anforderungen", onChange=null }) => {
    const api = useApi();
    const auth = useAuth();
    const zeitraumApi = useApiZeitraum();
    const globalContext = useContext(GlobalContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const mitteilungContext = useContext(MitteilungContext);

    const heute = globalContext.heute;
    const naechsterSonntagRef = useRef(naechsterWochentag(7, heute));
    const naechsterSonntag = naechsterSonntagRef.current;

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsMap, setSelectedRowsMap] = useState({});
    const [rowSelection, setRowSelection] = useState(null);
    const [dokumenteErstellt, setDokumenteErstellt] = useState([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [anforderungenFiltered, setAnforderungenFiltered] = useState([]);
    const [editKommentar, setEditKommentar] = useState(null);
    const [inputKommentar, setInputKommentar] = useState(null);
    const [faxempfangEintragen, setFaxempfangEintragen] = useState(false);

    const [planzeileIdToDosierabschnittReversedMap, setPlanzeileIdToDosierabschnittReversedMap] = useState({});
    const [planzeileIdToDatumAnforderungNachAktuellerWocheMap, setPlanzeileIdToDatumAnforderungNachAktuellerWocheMap] = useState({});

    const [editAnforderung, setEditAnforderung] = useState(null);
    const [editAnforderungChange, setEditAnforderungChange] = useState({});

    const versendeAnforderung = usePost(postAnforderungenVersenden(), selectedRows.map(row => row.id));
    const versendeStornoMeldung = usePost(postAnforderungenVersendeStornoMeldung(), (selectedRowsMap.stornoMeldung || []).map(row => row.id));
    const storniereAnforderungMitMeldung = usePost(postAnforderungenAbbrechen(true), (selectedRowsMap.offen || []).map(row => row.id));
    const storniereAnforderungOhneMeldung = usePost(postAnforderungenAbbrechen(false), (selectedRowsMap.offen || []).map(row => row.id));
    const schliesseAnforderungTeil = usePost(postAnforderungenSchliessen(true), (selectedRowsMap.offen || []).map(row => row.id));
    const schliesseAnforderung = usePost(postAnforderungenSchliessen(), (selectedRowsMap.offen || []).map(row => row.id));
    const mahneAnforderung = usePost(postAnforderungenMahnen(), (selectedRowsMap.mahnbar || []).map(row => row.id));

    const colorMap = useRef({
        arzneimittelByAnforderung: [],
        verordnerById: [],
    });

    const anforderungToSubstituteListMap = useRef({});

    const pagination = usePagination();

    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();

    useEffect(() => {
        setRowSelection({
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
            },
            getCheckboxProps: record => ({

            }),
        });
    }, [])

    useEffect(() => {
        setAnforderungenFiltered(anforderungen?.sort(getSorter("anforderung", "patient")) || []);

        const planzeileIdToDatumAnforderungNachAktuellerWocheMap = {};
        if (anforderungen?.length) for (let anforderung of anforderungen) {
            if (!planzeileIdToDosierabschnittReversedMap[anforderung.planzeile.id]) {
                const list = [...(anforderung.planzeile.dosierschema || [])].sort(getSorter("dosierabschnitt")).reverse();
                setPlanzeileIdToDosierabschnittReversedMap(prev => ({...prev, [anforderung.planzeile.id]: list}));
            }

            if (!planzeileIdToDatumAnforderungNachAktuellerWocheMap[anforderung.planzeile.id]) {
                const datumAnforderungNachAktuellerWoche = !anforderung.planzeile.datumAnforderung || moment(anforderung.planzeile.datumAnforderung).isAfter(naechsterSonntag);
                planzeileIdToDatumAnforderungNachAktuellerWocheMap[anforderung.planzeile.id] = datumAnforderungNachAktuellerWoche;
            }
        }

        setPlanzeileIdToDatumAnforderungNachAktuellerWocheMap(planzeileIdToDatumAnforderungNachAktuellerWocheMap);
    }, [anforderungen])

    useEffect(() => {
        const mapNew = {};
        if (selectedRows?.length) {
            for (let anforderung of selectedRows) {
                if (!anforderung.datumAbgebrochen && !anforderung.datumErfuellt) {
                    if (!mapNew.offen) mapNew.offen = [];
                    mapNew.offen.push(anforderung);

                    if (anforderung.datumAngefordert && !anforderung.datumMahnung3) {
                        if (!mapNew.mahnbar) mapNew.mahnbar = [];
                        mapNew.mahnbar.push(anforderung);
                    }
                }

                if (anforderung.datumAbgebrochen && !anforderung.nachrichtStorno) {
                    if (!mapNew.stornoMeldung) mapNew.stornoMeldung = [];
                    mapNew.stornoMeldung.push(anforderung);
                }
            }
        }

        setSelectedRowsMap(mapNew);
    }, [selectedRows])

    const handleVersand = async () => {
        setButtonsDisabled(true);
        const response = await versendeAnforderung.handleSend();

        const dokumenteErstelltNeu = [];
        for (let nachricht of response) {
            dokumenteErstelltNeu.push(...nachricht.anhangListe);
        }
        setDokumenteErstellt(dokumenteErstelltNeu);
        setButtonsDisabled(false);
    }

    const handleStorno = async (erstelleMeldung=false) => {
        setButtonsDisabled(true);
        const response = await (erstelleMeldung ? storniereAnforderungMitMeldung : storniereAnforderungOhneMeldung).handleSend();
        window.location.reload();
    }

    const handleStornoMeldung = async () => {
        setButtonsDisabled(true);
        const response = await versendeStornoMeldung.handleSend();
        window.location.reload();
    }

    const handleTeilerfuellt = async () => {
        setButtonsDisabled(true);
        const response = await schliesseAnforderungTeil.handleSend();
        window.location.reload();
    }

    const handleErfuellt = async () => {
        setButtonsDisabled(true);
        const response = await schliesseAnforderung.handleSend();
        window.location.reload();
    }

    const handleMahnung = async () => {
        setButtonsDisabled(true);
        const response = await mahneAnforderung.handleSend();
        window.location.reload();
    }

    const versendeAlle = async () => {
        setButtonsDisabled(true);
        const response = await versendeAnforderung.handleSend(anforderungen.map(row => row.id));

        const dokumenteErstelltNeu = [];
        for (let nachricht of response) {
            dokumenteErstelltNeu.push(...nachricht.anhangListe);
        }
        setDokumenteErstellt(dokumenteErstelltNeu);
        setButtonsDisabled(false);
    }

    const handleMitteilung = () => {
        const anforderungen = selectedRows;
        mitteilungContext.showModal(null, anforderungen);
    }

    const footer = () => {
        if (modus === ModusRezeptanforderung.gesammelt) {
            return <div>
                {!!selectedRows.length && <>
                    <Button onClick={handleVersand} disabled={buttonsDisabled} loading={buttonsDisabled}>Ausgewählte Anforderungen versenden</Button>
                    <Divider type="vertical"/>
                    <Button onClick={() => handleStorno(false)} disabled={buttonsDisabled} loading={buttonsDisabled}>Ausgewählte Anforderungen löschen</Button>
                    <Divider type="vertical"/>
                </>}
                <Button onClick={versendeAlle} disabled={buttonsDisabled} loading={buttonsDisabled}>Alle Anforderungen versenden</Button>
                <Button onClick={() => printAnforderungenList(anforderungenFiltered, modus, title, api)}>drucken</Button>
            </div>
        } else {
            return <div>
                {/*{!!selectedRows.length && <>*/}
                    <Button onClick={handleMahnung} disabled={buttonsDisabled || !selectedRowsMap.mahnbar?.length} loading={buttonsDisabled}>Mahnung erstellen für ausgewählte Anforderungen ({selectedRowsMap.mahnbar?.length})</Button>
                    <Button onClick={handleTeilerfuellt} disabled={buttonsDisabled || !selectedRowsMap.offen?.length} loading={buttonsDisabled}>Faxempfang für ausgewählte Anforderungen bestätigen ({selectedRowsMap.offen?.length})</Button>
                    <Popover content={<div><h6>Ausgewählte Anforderungen wirklich stornieren?</h6><Button onClick={() => handleStorno(true)}>JA, stornieren</Button></div>}><Button disabled={buttonsDisabled || !selectedRowsMap.offen?.length} loading={buttonsDisabled}>Ausgewählte Anforderungen stornieren mit Storno-Meldung ({selectedRowsMap.offen?.length})</Button></Popover>
                    <Popover content={<div><h6>Ausgewählte Anforderungen wirklich stornieren?</h6><Button onClick={() => handleStorno(false)}>JA, stornieren</Button></div>}><Button disabled={buttonsDisabled || !selectedRowsMap.offen?.length} loading={buttonsDisabled}>Ausgewählte Anforderungen stornieren ohne Storno-Meldung ({selectedRowsMap.offen?.length})</Button></Popover>
                    <Button onClick={handleErfuellt} disabled={buttonsDisabled || !selectedRowsMap.offen?.length} loading={buttonsDisabled}>Ausgewählte Anforderungen als erfüllt markieren ({selectedRowsMap.offen?.length})</Button>
                    <Button onClick={handleMitteilung} disabled={buttonsDisabled || !selectedRows.length} loading={buttonsDisabled}>Mitteilung versenden für ausgewählte Anforderungen ({selectedRows.length})</Button>
                    {modus === ModusRezeptanforderung.patient && <>
                        <Button onClick={handleStornoMeldung} disabled={buttonsDisabled || !selectedRowsMap.stornoMeldung?.length} loading={buttonsDisabled}>Storno-Meldung erstellen für ausgewählte Anforderungen ({selectedRowsMap.stornoMeldung?.length})</Button>
                        <Button onClick={() => setFaxempfangEintragen(true)}>Faxempfang eintragen</Button>
                    </>}
                {/*</>}*/}

                {/*{!selectedRows.length && <>*/}
                {/*    <Button onClick={storniereAlle} disabled={buttonsDisabled} loading={buttonsDisabled}>Alle Anforderungen stornieren</Button>*/}
                {/*    <Divider type="vertical"/>*/}
                {/*    <Button onClick={teilerfuelleAlle} disabled={buttonsDisabled} loading={buttonsDisabled}>Faxempfang für alle Anforderungen bestätigen</Button>*/}
                {/*    <Divider type="vertical"/>*/}
                {/*    <Button onClick={erfuelleAlle} disabled={buttonsDisabled} loading={buttonsDisabled}>Alle Anforderungen als erfüllt markieren</Button>*/}
                {/*    <Divider type="vertical"/>*/}
                {/*    <Button onClick={mahneAlle} disabled={buttonsDisabled} loading={buttonsDisabled}>Mahnung erstellen für alle Anforderungen</Button>*/}
                {/*</>}*/}

                <Button onClick={() => printAnforderungenList(anforderungenFiltered, modus, title, api)}>drucken</Button>
            </div>
        }

        return null
    }

    const onFilterDate = (value, anforderung, dataIndex)=>{
        const {start, ende} = value;
        if (!Array.isArray(dataIndex)) dataIndex = [dataIndex];

        let date;
        for (let key in dataIndex) {
            if (["datumRezeptBenoetigtBis", "reichweiteAnspruch"].includes(key)) {
                if (anforderung.planzeile?.[key]) date = moment(anforderung.planzeile[key]);
            } else if (anforderung[key]) {
                if (anforderung[key]) date = moment(anforderung[key]);
            }

            if (!start || date?.isAfter(start) && (!ende || date?.isBefore(ende))) {
                setAnforderungenFiltered(prev => [...prev.filter(a => a.id !== anforderung.id), anforderung]);
                return true;
            }
        }

        return false;
    };

    const startEditKommentar = (anforderung) => {
        setEditKommentar(anforderung.id);
        setInputKommentar(anforderung.kommentar);
    }

    const saveKommentar = (anforderung) => {
        const anforderungNeu = {...anforderung, kommentar: inputKommentar};

        if (!!onChange) {
            onChange(anforderungNeu);
        } else {
            api.updateAnforderung(anforderungNeu);
        }

        setEditKommentar(null);
        setInputKommentar(null);
    }

    const columns = [
        {
            title: "ID",
            key: "id",
            render: row => row.id,

            devOnly: true,
        }
    ];

    // Patient anzeigen, wenn nicht Modus:Patient
    if (ModusRezeptanforderung.patient !== modus) {
        columns.splice(1, 0, {
            title: 'Patient',
            key: 'patient',
            render: (row) => {
                let patient = row.planzeile.medikationsplan?.patient;
                let patientId = patient?.id || JSON.parse(row.planzeile.json)?.DELETED?.PATIENT;

                return <pre>
                    <UnitLangname unit={patient} unitId={patientId} />
                    <span style={{fontSize: 10}}><UnitCareFacilityLangname unitId={patientId} /></span>
                    <span style={{fontSize: 10}}><UnitStationLangname unitId={patientId} /></span>
                </pre>;
            },

            onCell: (anforderung, index) => {
                const result = {
                    style: {}
                };

                if (!anforderung.planzeile?.id) {
                    anforderung.planzeile = {
                        json: "{}"
                    };

                    result.style.backgroundColor = `rgba(210,0,0,0.5)`; // rot
                    return result;
                }

                const unitId = anforderung.planzeile.medikationsplan?.patient?.id || JSON.parse(anforderung.planzeile.json)?.DELETED?.PATIENT;
                if (!unitId) return result;

                const zeitraumMap = zeitraumApi.unitIdToZeitraumMapMap[unitId];
                if (!zeitraumMap) {
                    const medikationsplanId = anforderung.planzeile.medikationsplan?.id || JSON.parse(anforderung.planzeile.json)?.DELETED?.MEDIKATIONSPLAN;
                    if (!medikationsplanId) return result;

                    zeitraumApi.getZeitraumMapByMedikationsplan(medikationsplanId);
                } else {
                    result.style.backgroundColor = getCurrentZeitraumColor(zeitraumMap, 0.5);
                }

                return result;
            },

            sorter: getSorter("anforderung", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, record) => {
                const patient = record.planzeile?.medikationsplan?.patient;
                const patientId = patient?.id || JSON.parse(record.planzeile.json)?.DELETED?.PATIENT;

                const isInAPI = patientId in api.akteureIdToIndexMap;

                return getUnitLangname(patient)?.toLowerCase().includes(value?.toLowerCase()) ||
                (isInAPI && (
                    getUnitLangname(api.akteure[api.akteureIdToIndexMap[patientId]]?.careFacility)?.toLowerCase().includes(value?.toLowerCase()) ||
                    getUnitLangname(api.akteure[api.akteureIdToIndexMap[patientId]]?.station)?.toLowerCase().includes(value?.toLowerCase())
                ))
            }}),
            defaultSortOrder: "ascend"
        });
    }

    columns.push(...[
        {
            title: 'Arzneimittel',
            key: 'arzneimittel',
            render: (row) => {
                let deleted = false;
                let medikationsplanId = row.planzeile.medikationsplan?.id;
                if (!medikationsplanId) {
                    const json = JSON.parse(row.planzeile.json);
                    if (json.DELETED) {
                        deleted = true;
                        medikationsplanId = json.DELETED.MEDIKATIONSPLAN;
                    }
                }

                if (!deleted && medikationsplanId) {
                    return <Tooltip title={"Planzeile anzeigen"}><Link
                        to={`/medikationsplan/${medikationsplanId}/Apotheke/${row.planzeile.id}`}>
                        <pre>{row.arzneimittel.langname}{deleted && " (gelöscht)"}</pre>
                    </Link></Tooltip>;
                } else {
                    return <pre>{row.arzneimittel.langname}{deleted && " (gelöscht)"}</pre>;
                }
            },

            onCell: (anforderung, index) => {
                const result = {
                    style: {}
                };

                if (anforderung.datumErfuellt || anforderung.datumAbgebrochen) {
                    return result;
                }

                let color = colorMap.current.arzneimittelByAnforderung[anforderung.id];
                if (!color) {
                    const planzeileIstGeloescht = !anforderung.planzeile.medikationsplan?.id && "DELETED" in JSON.parse(anforderung.planzeile.json);
                    let planzeileIstAbgesetzt = !!anforderung.planzeile.datumAbsetzung;

                    const datumAnforderungNachAktuellerWoche = planzeileIdToDatumAnforderungNachAktuellerWocheMap[anforderung.planzeile.id];

                    if (planzeileIstGeloescht || planzeileIstAbgesetzt) {
                        api.loadBuendel(anforderung.planzeile.buendelId).then(buendel => {
                            const filter = buendel?.planzeilen?.filter(p => !p.datumAbsetzung && p.buendelFaktor === anforderung.planzeile.buendelFaktor);
                            anforderungToSubstituteListMap.current[anforderung.id] = filter;

                            if (filter.length) {
                                color = "rgba(0,147,210,0.5)";
                            } else {
                                color = "rgba(210, 0, 0, .5)";
                            }
                            colorMap.current.arzneimittelByAnforderung[anforderung.id] = color;
                        });
                    } else if (datumAnforderungNachAktuellerWoche) {
                        color = "rgba(210, 210, 0, .5)";
                        colorMap.current.arzneimittelByAnforderung[anforderung.id] = color;
                    }
                }
                result.style.backgroundColor = color;

                return result;
            },

            sorter: getSorter("anforderung", "arzneimittel"),
            ...getColumnSearchProps({dataIndex: "arzneimittel", onFilter: (value, record) => {
                    if (getArzneimittelLangname(record.arzneimittel).toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                }})
        },
        {
            title: 'Verordner',
            key: 'verordner',
            render: (row) => <Tooltip title={"Verordner anzeigen"}><Link to={row.verordner ? "/adressbuch/"+row.verordner.id : "#"} style={{color: "black"}}><pre>{getUnitLangname(row.verordner, 'arzt')}</pre></Link></Tooltip>,

            onCell: (anforderung) => {
                const result = { style: {} };

                const unitId = anforderung.verordner?.id;
                if (unitId) {
                    const zeitraumMap = api.unitIdToZeitraumMapMap[unitId];
                    if (!zeitraumMap) api.getZeitraumMapByUnit(unitId);
                    else {
                        let currentZeitraumColor;

                        const currentZeitraumTyp = getCurrentZeitraumTyp(zeitraumMap, null, 14);
                        switch (currentZeitraumTyp) {
                            case "U_B":
                                if (anforderung.planzeile.datumAnforderungVorverlegt) {
                                    currentZeitraumColor = "rgba(0, 125, 255, 0.5)";
                                    break;
                                }
                            case "U":
                                currentZeitraumColor = getZeitraumColor(currentZeitraumTyp, 0.5);
                        }
                        if (currentZeitraumColor) result.style.backgroundColor = currentZeitraumColor;
                    }
                }

                return result;
            },

            sorter: getSorter("anforderung", "verordner"),
            ...getColumnSearchProps({dataIndex: "verordner", onFilter: (value, record) => {
                    if (getUnitLangname(record.verordner).toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                }})
        },
        {
            title: "Angefordert",
            key: "angefordert",
            render: (row) => <pre>{row.datumAngefordert ? moment(row.datumAngefordert).format("DD.MM.YYYY") : ""}</pre>,

            sorter: getSorter("anforderung", "datumAngefordert"),
            ...getColumnDateProps("datumAngefordert", onFilterDate),
        },
        {
            title: "Gemahnt",
            key: "gemahnt",
            render: (row) => {
                let gemahntStringArray = [];
                if (row.datumMahnung1) gemahntStringArray.push("1: "+moment(row.datumMahnung1).format("DD.MM.YYYY"));
                if (row.datumMahnung2) gemahntStringArray.push("2: "+moment(row.datumMahnung2).format("DD.MM.YYYY"));
                if (row.datumMahnung3) gemahntStringArray.push("3: "+moment(row.datumMahnung3).format("DD.MM.YYYY"));

                return <pre>{gemahntStringArray.join("\n")}</pre>
            },

            sorter: getSorter("anforderung", "gemahnt"),
            ...getColumnDateProps("gemahnt", (value, record) => {
                const datum = record.datumMahnung3 || record.datumMahnung2 || record.datumMahnung1;
                if (!datum) return false;

                const start = value.start ? moment(value.start).startOf('day') : null;
                const ende = value.ende ? moment(value.ende).endOf('day') : null;

                if ((!start || (start.isSameOrBefore(datum))) && (!ende || (ende.isSameOrAfter(datum)))) {
                    return true;
                }
            }),
        }
    ]);

    // Erfüllt-Status anzeigen, wenn in Patient-Modus
    if (ModusRezeptanforderung.patient === modus) {
        columns.push({
            title: 'Erledigt',
            key: 'erledigt',
            render: (row) => <pre>
                {!!row.datumErfuellt && moment(row.datumErfuellt).format("DD.MM.YYYY") + " (erfüllt)"}
                {!!row.datumAbgebrochen && moment(row.datumAbgebrochen).format("DD.MM.YYYY") + " (storniert)"}
                {!!row.datumAbgebrochen && !row.nachrichtStorno && "\nkeine Storno-Meldung!"}
            </pre>,

            sorter: getSorter("anforderung", "erledigt"),
            ...getColumnDateProps(["datumErfuellt", "datumAbgebrochen"], onFilterDate),
            defaultSortOrder: "descend"
        });
    }

    columns.push(...[
        {
            title: 'Bedarf',
            key: 'bedarf',
            render: (row) => {
                return <pre>{row.mengeBenoetigt > 0 ? row.mengeBenoetigt : row.anzahlArzneimittel !== 1 ? row.anzahlArzneimittel+"x"+row.arzneimittel?.menge || 0 : row.arzneimittel?.menge || 0}</pre>
            }
        },
        {
            title: 'Benötigt',
            key: 'benoetigt',
            render: (row) => {
                let planzeile = row.planzeile;

                const substituteList = anforderungToSubstituteListMap.current[row.id];
                if (substituteList?.length === 1) {
                    planzeile = substituteList[0];
                }

                return <Popover placement={"top"} trigger={"hover"} pointerEvents={"none"} content={planzeileIdToDatumAnforderungNachAktuellerWocheMap[planzeile.id] && `Um ein rechtzeitiges Eintreffen des Rezepts zu gewährleisten, wird eine Anforderung ab ${moment(planzeile.datumAnforderung).format("DD.MM.YYYY")} empfohlen.`}>
                    <pre>{planzeile.datumRezeptBenoetigtBis ? moment(planzeile.datumRezeptBenoetigtBis).format("DD.MM.YYYY") : ""}</pre>
                </Popover>
            },

            onCell: (anforderung, index) => {
                const result = {
                    style: {}
                };

                if (anforderung.datumErfuellt || anforderung.datumAbgebrochen) {
                    return result;
                }

                if (anforderungToSubstituteListMap.current[anforderung.id]?.length === 1) {
                    result.style.backgroundColor = "rgba(0,147,210,0.5)";
                }

                return result;
            },

            sorter: getSorter("anforderung", "datumRezeptBenoetigtBis"),
            ...getColumnDateProps("datumRezeptBenoetigtBis", onFilterDate),
        },
        {
            title: 'Reichweite',
            key: 'reichweite',
            render: (row) => {
                let planzeile = row.planzeile;

                const substituteList = anforderungToSubstituteListMap.current[row.id];
                if (substituteList?.length === 1) {
                    planzeile = substituteList[0];
                }

                return <pre>{planzeile.reichweiteAnspruch ? moment(planzeile.reichweiteAnspruch).format("DD.MM.YYYY") : ""}</pre>
            },

            onCell: (anforderung, index) => {
                const result = {
                    style: {}
                };

                if (anforderung.datumErfuellt || anforderung.datumAbgebrochen) {
                    return result;
                }

                if (anforderungToSubstituteListMap.current[anforderung.id]?.length === 1) {
                    result.style.backgroundColor = "rgba(0,147,210,0.5)";
                }

                return result;
            },

            sorter: getSorter("anforderung", "reichweiteAnspruch"),
            ...getColumnDateProps("reichweiteAnspruch", onFilterDate),
        },
        {
            title: 'Faxempfang',
            key: 'faxempfang',
            render: (row) => <pre>{row.datumFaxempfang ? moment(row.datumFaxempfang).format("DD.MM.YYYY") : ""}</pre>,

            sorter: getSorter("anforderung", "datumFaxempfang"),
            ...getColumnDateProps("datumFaxempfang", onFilterDate),
        },
        {
            title: 'Kommentar',
            key: 'kommentar',
            render: (row) => {
                if (editKommentar === row.id) {
                    return <div><TextArea value={inputKommentar} autoSize={true} onChange={e => setInputKommentar(e.target.value)} /><Button onClick={() => saveKommentar(row)}>Kommentar speichern</Button></div>;
                } else {
                    return <div style={row.kommentar ? {
                        minWidth: 200
                    } : {}}>{nl2br(row.kommentar)}<Tooltip title={"Kommentar bearbeiten"}><EditOutlined className={"icon edit icon-center link"} onClick={() => startEditKommentar(row)} /></Tooltip></div>;
                }
            },

            ...getColumnSearchProps({dataIndex: "kommentar", onFilter: (value, record, dataIndex) => {
                    if (record[dataIndex].toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                }})
        },
        {
            title: 'Tags',
            key: 'tags',
            render: (row) => {
                return <div className={"anforderung-tags"}>
                    {row.vorabgabe &&
                        <Tag color='volcano' key={"VORABGABE"}>VORABGABE</Tag>
                    }

                    {row.lieferengpass &&
                        <Tag color='volcano' key={"LIEFERENGPASS"}>LIEFERENGPASS</Tag>
                    }

                    {[ModusRezeptanforderung.gesammelt, ModusRezeptanforderung.patient].includes(modus) && row.datumMahnung1 && (
                        row.datumMahnung3 ? <Tag color='volcano' key={"MAHNUNG"}>MAHNSTUFE 3</Tag> :
                            row.datumMahnung2 ? <Tag color='volcano' key={"MAHNUNG"}>MAHNSTUFE 2</Tag> :
                                <Tag color='volcano' key={"MAHNUNG"}>MAHNUNG</Tag>)
                    }

                    {[ModusRezeptanforderung.gesammelt, ModusRezeptanforderung.patient].includes(modus) && row.datumFaxempfang && !row.datumAngefordert &&
                        <Tag color='volcano' key={"VORABERINNERUNG"}>VORABERINNERUNG</Tag>
                    }

                    {row.datumAbgebrochen &&
                        <Tag color='volcano' key={"STORNIERT"}>STORNIERT</Tag>
                    }

                    {ModusRezeptanforderung.gesammelt === modus && row.datumStornoMeldung && !row.nachrichtStornoMeldung &&
                        <Tag color='volcano' key={"STORNOMELDUNG"}>STORNOMELDUNG</Tag>
                    }

                    {ModusRezeptanforderung.patient === modus && row.datumAbgebrochen && !row.nachrichtStornoMeldung &&
                        <Tag color='volcano' key={"STORNOMELDUNG_FEHLT"}>STORNOMELDUNG FEHLT</Tag>
                    }
                </div>
            },

            filters: modus === ModusRezeptanforderung.gesammelt ? [
                {text: "VORABGABE", value: "VORABGABE"},
                {text: "VORABERINNERUNG", value: "VORABERINNERUNG"},
                {text: "MAHNUNG", value: "MAHNUNG"},
                {text: "STORNOMELDUNG", value: "STORNOMELDUNG"},
            ] : modus === ModusRezeptanforderung.patient ? [
                {text: "VORABGABE", value: "VORABGABE"},
                {text: "MAHNUNG", value: "MAHNUNG"},
                {text: "STORNIERT", value: "STORNIERT"},
                {text: "STORNOMELDUNG FEHLT", value: "STORNOMELDUNG_FEHLT"},
            ] : [
                {text: "VORABGABE", value: "VORABGABE"},
                {text: "MAHNUNG", value: "MAHNUNG"},
            ],
            onFilter: (filter, record) => {
                let isVisible = false;
                switch (filter) {
                    case "VORABGABE":
                        isVisible = record.vorabgabe;
                        break;
                    case "VORABERINNERUNG":
                        isVisible = !!record.datumFaxempfang && !record.datumAngefordert;
                        break;
                    case "MAHNUNG":
                        isVisible = !!record.datumMahnung1 || !!record.datumMahnung2 || !!record.datumMahnung3;
                        break;
                    case "STORNIERT":
                        isVisible = !!record.datumAbgebrochen;
                        break;
                    case "STORNOMELDUNG_FEHLT":
                        isVisible = !!record.datumAbgebrochen && !record.nachrichtStornoMeldung;
                        break;
                    case "STORNOMELDUNG":
                        isVisible = record.datumStornoMeldung && !record.nachrichtStornoMeldung;
                        break;
                }

                return isVisible;
            },
        }
    ]);

    const handleContextMenu = (top, left, anforderung) => {
        globalContext.setContextMenu(prev => ({
            top,
            left,
            title: !!anforderung && `Anforderung: ${getUnitLangname(anforderung.planzeile.medikationsplan.patient)} - ${getArzneimittelLangname(anforderung.arzneimittel)}`,
            content: <Menu>
                <Menu.Item key={'edit'} onClick={() => setEditAnforderung(anforderung)}>Anforderung Bearbeiten</Menu.Item>
            </Menu>
        }));
    }

    const onCloseEditAnforderung = () => {
        if (editAnforderung && editAnforderungChange && Object.keys(editAnforderungChange).length) {
            const anforderungNeu = {...editAnforderung, ...editAnforderungChange};
            if (!!onChange) {
                onChange(anforderungNeu);
            } else {
                api.updateAnforderung(anforderungNeu);
            }

            if (editAnforderungChange.planzeile || editAnforderungChange.arzneimittel) {
                delete colorMap.current.arzneimittelByAnforderung[editAnforderung.id];
                delete anforderungToSubstituteListMap.current[editAnforderung.id];
                setPlanzeileIdToDatumAnforderungNachAktuellerWocheMap(prev => ({...prev, [editAnforderung.planzeile.id]: editAnforderung.planzeile.datumAnforderung && moment(editAnforderung.planzeile.datumAnforderung).isAfter(naechsterSonntag)}));
            }
        }

        setEditAnforderung(null);
        setEditAnforderungChange({});
    }




    return <div>
        <Modal
            visible={dokumenteErstellt.length}
            footer={null}
            onCancel={() => window.location.reload()}
        >
            {/*<h6>Folgende Dokumente wurden erstellt</h6>*/}
            {/*<List dataSource={dokumenteErstellt} renderItem={item => {*/}
            {/*    return <List.Item>*/}
            {/*        <Button onClick={() => window.open(nachrichtApi.getDokumentById(item.id) + "?token=" + auth.token)}>{item.titel}</Button>*/}
            {/*    </List.Item>;*/}
            {/*}}/>*/}

            <Button onClick={() => {
                const idList = [];
                dokumenteErstellt.forEach(dokument => idList.push(dokument.id));
                window.open(nachrichtApi.getDokumenteByIdMerged(idList) + "&token=" + auth.token);
            }}>Alle Dokumente drucken</Button>
        </Modal>

        <Modal
            visible={!!editAnforderung}
            footer={null}
            onCancel={onCloseEditAnforderung}

            destroyOnClose={true}
            style={{display: 'flex', justifyContent: 'center'}}
        >
            {!!editAnforderung && <EditAnforderung anforderung={editAnforderung} onChange={setEditAnforderungChange} />}
        </Modal>

        <FaxempfangEintragenModal visible={faxempfangEintragen} onVisibleChange={visible => setFaxempfangEintragen(visible)} />

        <Table
            columns={(modus === ModusRezeptanforderung.gesammelt ?
                columns.filter(col => col.key !== "angefordert" && col.key !== "gemahnt") :
                columns).filter(col => !col.devOnly || auth.developer)
            }
            dataSource={anforderungen}
            rowKey={anforderung => anforderung.id}
            loading={loading}
            rowSelection={rowSelection}
            footer={footer}
            pagination={pagination}

            rowClassName={"anforderung-row"}
            scroll={{ x: "max-width" }}

            onRow={(a) => ({
                onContextMenu: (event) => {
                    handleContextMenu(event.clientY, event.clientX, a);
                }
            })}

            onChange={(pagination, filters, sorter, extra) => {
                setAnforderungenFiltered(extra.currentDataSource);
            }}
        />

    </div>
}

const EditAnforderung = ({anforderung, onChange:onChangeProp=()=>{}}) => {
    const [changes, setChanges] = useState({});

    const [arzneimittelPickerVisible, setArzneimittelPickerVisible] = useState(false);

    function onChange(key, value) {
        const promise = new Promise(resolve => {
            setChanges(prev => {
                const neu = {...prev, [key]: value};

                resolve(neu);
                return neu;
            });
        });

        promise.then(onChangeProp);
        return promise;
    }

    return <div>
        <Modal visible={arzneimittelPickerVisible} footer={null} onCancel={() => setArzneimittelPickerVisible(false)} width={'90%'}>
            <ArzneimittelPicker
                value={changes.arzneimittel || anforderung.arzneimittel}
                onChange={async (neu) => {
                    onChange('arzneimittel', neu);
                    setArzneimittelPickerVisible(false);
                }}

                resetTriggerAfterChange={true}
            />
        </Modal>

        <h3>Anforderung bearbeiten</h3>
        <div style={{display: 'grid', gridTemplateColumns: 'max-content max-content', gap: 10}}>
            <PlanzeilePicker value={changes.planzeile || anforderung.planzeile} onChange={planzeile => {
                onChange('planzeile', planzeile);
                onChange('arzneimittel', planzeile.arzneimittel);
            }} divWrapped={false} />

            <span>Arzneimittel</span>
            <span>{getArzneimittelLangname(changes.arzneimittel || anforderung.arzneimittel)} <Button onClick={() => setArzneimittelPickerVisible(true)}>ändern</Button></span>

            <span>Packungsmenge</span>
            <Input value={changes.anzahlArzneimittel || anforderung.anzahlArzneimittel} onChange={(e) => onChange('anzahlArzneimittel', e.target.value)} />

            <span>Verordner</span>
            <AkteurPicker
                value={changes.verordner || anforderung.verordner}
                onChange={(neu, alt) => onChange('verordner', neu)}
            />

            <span>Faxempfang</span>
            <DatePicker format={'DD.MM.YYYY'} defaultValue={moment(changes.datumFaxempfang || anforderung.datumFaxempfang)} onChange={datumFaxempfang => onChange('datumFaxempfang', datumFaxempfang)} />

            <span>Vorabanforderung</span>
            <span><Switch defaultChecked={changes.vorabgabe || anforderung.vorabgabe} onChange={(vorabgabe) => onChange('vorabgabe', vorabgabe)} /></span>

            <span>Kommentar</span>
            <TextArea value={changes.kommentar || anforderung.kommentar} onChange={(e) => onChange('kommentar', e.target.value)} />
        </div>
    </div>
}

const PlanzeilePicker = ({value, onChange, divWrapped=true}) => {
    const api = useApi();
    const auth = useAuth();

    const [patient, setPatient] = useState(null);
    const [options, setOptions] = useState([]);
    const [planzeileMap, setPlanzeileMap] = useState({});

    useEffect(() => {
        (async () => {
            if (value) {
                if (value.medikationsplan) {
                    setPatient(value.medikationsplan.patient);
                } else {
                    setPatient(await api.loadAkteur(JSON.parse(value.json)?.DELETED?.PATIENT));
                }
            }
        })();
    }, [value])

    useEffect(() => {
        (async () => {
            if (patient) {
                const planzeileMap = {}
                const options = [];

                const response = await callApiAsync({url: planzeilenApi.getByFilter({patientId: patient.id}), auth})
                for (const planzeile of response.data.OBJECT.filter(p => p.active).sort(getSorter('planzeile', 'arzneimittel'))) {
                    planzeileMap[planzeile.id] = planzeile;
                    options.push({label: getArzneimittelLangname(planzeile.arzneimittel), value: planzeile.id});
                }

                setOptions(options);
                setPlanzeileMap(planzeileMap);
            }
        })();
    }, [patient])

    const children = <>
        <span>Patient</span>
        <AkteurPicker value={patient} onChange={setPatient} />

        <span>Planzeile</span>
        <Select onChange={planzeileId => onChange(planzeileMap[planzeileId])} value={value?.id} options={options} />
    </>

    if (divWrapped) return <div style={{display: 'grid', gridTemplateColumns: 'max-content max-content', gap: 10}}>
        {children}
    </div>

    return children;
}

export const printAnforderungenList = async (anforderungen, modus=ModusRezeptanforderung.offen, title="", api) => {
    const promiseList = anforderungen.map(async anforderung => {
        const tagList = [];
        if (anforderung.vorabgabe) {
            tagList.push("Vorabgabe");
        }

        if (modus === ModusRezeptanforderung.gesammelt) {
            if (anforderung.datumMahnung3) {
                tagList.push("Mahnstufe-3");
            } else if (anforderung.datumMahnung2) {
                tagList.push("Mahnstufe-2");
            } else if (anforderung.datumMahnung) {
                tagList.push("Mahnung");
            }
        }

        const gemahntStringArray = [];
        if (modus === ModusRezeptanforderung.offen) {
            if (anforderung.datumMahnung1) gemahntStringArray.push("1: "+moment(anforderung.datumMahnung1).format("DD.MM.YYYY"));
            if (anforderung.datumMahnung2) gemahntStringArray.push("2: "+moment(anforderung.datumMahnung2).format("DD.MM.YYYY"));
            if (anforderung.datumMahnung3) gemahntStringArray.push("3: "+moment(anforderung.datumMahnung3).format("DD.MM.YYYY"));
        }

        if (modus === ModusRezeptanforderung.gesammelt && anforderung.datumFaxempfang && !anforderung.datumAngefordert) {
            tagList.push("Voraberinnerung");
        }

        const nl2brKommentar = nl2br(anforderung.kommentar);
        const htmlKommentar = ReactDOMServer.renderToStaticMarkup(nl2brKommentar);

        let patient = anforderung.planzeile?.medikationsplan?.patient;
        const planzeileGeloescht = !patient;
        if (!patient && api) {
            const json = JSON.parse(anforderung.planzeile.json);
            const patientId = json.DELETED.PATIENT;

            if (api?.akteureIdToIndexMap?.[patientId]) {
                patient = api.akteure[api.akteureIdToIndexMap[patientId]];
            } else {
                patient = await api.loadAkteur(patientId);
            }
        }

        return `<tr>
                        <td><pre>${getUnitLangname(patient) || `K/A (PZ #${anforderung.planzeile.id})`}</pre></td>
                        <td><pre>${anforderung.arzneimittel.langname}${planzeileGeloescht ? ' (gelöscht)' : ''}</pre></td>
                        <td><pre>${getUnitLangname(anforderung.verordner, 'arzt')}</pre></td>
                        ${modus !== ModusRezeptanforderung.offen ? "" : `<td><pre>${anforderung.datumAngefordert ? moment(anforderung.datumAngefordert).format("DD.MM.YYYY") : ""}</pre></td>`}
                        ${modus !== ModusRezeptanforderung.offen ? "" : `<td><pre>${gemahntStringArray.length ? gemahntStringArray.join("\n") : ""}</pre></td>`}
                        <td><pre>${anforderung.mengeBenoetigt > 0 ? anforderung.mengeBenoetigt : anforderung.anzahlArzneimittel !== 1 ? anforderung.anzahlArzneimittel+"x"+anforderung.arzneimittel?.menge || 0 : anforderung.arzneimittel?.menge || 0}</pre></td>
                        ${modus !== ModusRezeptanforderung.offen ? "" : `<td><pre>${anforderung.datumBenoetigt ? moment(anforderung.datumBenoetigt).format("DD.MM.YYYY") : ""}</pre></td>`}
                        <td><pre>${anforderung.datumFaxempfang ? moment(anforderung.datumFaxempfang).format("DD.MM.YYYY") : ""}</pre></td>
                        <td><pre>${tagList.join(", ")}</pre></td>
                    </tr>${anforderung.kommentar ? `<tr><td colspan="5">${htmlKommentar}</td></tr>` : ""}`;
    });
    const rowList = await Promise.all(promiseList);

    const win = window.frames["printf"];
    win.document.write(`<body onload="window.print()"><h3>${title}</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Verordner</td>
            ${modus !== ModusRezeptanforderung.offen ? "" : "<td>Angefordert</td>"}
            ${modus !== ModusRezeptanforderung.offen ? "" : "<td>Gemahnt</td>"}
            <td>Menge</td>
            ${modus !== ModusRezeptanforderung.offen ? "" : "<td>Benötigt</td>"}
            <td>Fax</td>
            <td>Tags</td>
        </tr><tr><td colspan="3">Kommentar</td></tr>${rowList.join("")}</table></body>`);
    win.document.close();
}

export const ModusRezeptanforderung = {
    offen: "offen",
    gesammelt: "gesammelt",
    patient: "patient"
}


