import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Button, Checkbox, DatePicker, Input, notification, Select, Table} from 'antd'

import {defaultMedikationsplan, getUnitLangname} from './../../config/entities';

import moment from 'moment';
import DokumenteContext from "../../contexts/DokumenteContext";
import {AkteurPicker} from "../atoms/AkteurPicker";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {akteureApi, dokumenteApi, medikationsplanApi} from "../../config/apiConfig";
import {DokumentVerknuepfungTypen, DokumentVerknuepfungTypenMap, getSorter} from "../../utilities/sortUtil";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {useDropzone} from "react-dropzone";
import {Tooltip} from "../atoms/Tooltip";

import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons"
import useDebouncedClickCounter from "../../utilities/useDebouncedClickCounter";
import useDateiVorschau from "../../utilities/useDateiVorschau";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import usePagination from "../../utilities/usePagination";
import {Popover} from "../atoms/Popover";

const {Option} = Select;

export const DOCUMENT_TYPES = [
    "Medikationsplan Arzt",
    "Medikationsplan Heim",
    "Kontaktdaten",
    "Arztbrief",
    "Checkliste Inventur",
    "E-Rezept",
    "Rezept",
    "Rezeptanforderung",
    "Patientenstammblatt",
    "Vertrag",
    "Vorbestellung",
    "AMTS-Check",
    "Empfangsbestätigung Betäubungsmittel",
    "BTM Vernichtungsprotokoll",
    "Sonstiges",
];

const DokumentListeTabelle = ({ filter, setFilter, dokumente, onUpdate=()=>{}, onUpload=()=>{}, onDelete=()=>{}, setEdit=()=>{}, gueltigkeitFuer=[], scroll=false, bearbeitetStatusFuer=[] }) => {
    const dokumenteContext = useContext(DokumenteContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    const auth = useAuth();
    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();
    const {openDokument} = useDateiVorschau();

    const [editRecord, setEditRecord] = useState({});
    const [editVerknuepfung, setEditVerknuepfung] = useState({});
    const [editData, setEditData] = useState({});
    const idVerknuepfungNeu = useRef(0);

    const [typVerknuepfungNeu, setTypVerknuepfungNeu] = useState("PATIENT");
    const [verknuepfungWarneUnbearbeitet, setVerknuepfungWarneUnbearbeitet] = useState(true);

    const [heute, setHeute] = useState(null);

    const [clickCounter, onClick] = useDebouncedClickCounter(300, 2);
    const [dokumentClicked, setDokumentClicked] = useState(null);

    const [dataSource, setDataSource] = useState([]);

    const onDropDokument = useCallback(async acceptedFiles => {
        let formData = new FormData();
        acceptedFiles.map(file => {
            formData.append(file.name, file);
        });

        const dokument = await uploadDokument(formData, auth);
        onUpload(dokument);
    }, [auth]);

    const { isDragActive, getRootProps, getInputProps, isDragReject, isFileTooLarge } = useDropzone({
        onDrop: onDropDokument,
        accept: 'image/png, image/gif, image/jpeg, application/pdf',
        minSize: 0,
        // maxSize,
    });

    useEffect(() => {
        setHeute(moment().startOf("day").valueOf());
    }, [])

    useEffect(() => {
        if (clickCounter === 2 && dokumentClicked) {
            editAndOpenDokument(dokumentClicked);
        } else if (clickCounter === 1 && dokumentClicked) {
            openDokument(dokumentClicked.id, auth)
        }

        setDokumentClicked(null);
    }, [clickCounter])

    useEffect(() => {
        if (
            editRecord &&
            dokumenteContext.dokumentUpdated &&
            (
                dokumenteContext.dokumentUpdated.alt &&
                dokumenteContext.dokumentUpdated.alt.id === editRecord.id
            ) || (
                !dokumenteContext.dokumentUpdated.alt &&
                dokumenteContext.dokumentUpdated.neu &&
                dokumenteContext.dokumentUpdated.neu.id === editRecord.id
            )
        ) {
            if (dokumenteContext.dokumentUpdated.change) {
                const editRecordNeu = {...editRecord};
                const dokumentNeu = dokumenteContext.dokumentUpdated.neu;
                for (let key of dokumenteContext.dokumentUpdated.change) {
                    editRecordNeu[key] = dokumentNeu[key];
                }
                editDokument(editRecordNeu);
            } else {
                editDokument(dokumenteContext.dokumentUpdated.neu);
            }
        }
    }, [dokumenteContext.dokumentUpdated])

    useEffect(() => {
        switch (typVerknuepfungNeu) {
            case "PATIENT":
            case "SONSTIGE":
                setVerknuepfungWarneUnbearbeitet(true);
                break;
            default:
                setVerknuepfungWarneUnbearbeitet(false);
        }
    }, [typVerknuepfungNeu])

    useEffect(() => {
        setDataSource(dokumente);
    }, [dokumente])

    const erstelleMedikationsplan = async (patient) => {
        const response = await callApiAsync({auth, url: medikationsplanApi.post(), data: defaultMedikationsplan(patient), method: 'POST'});
        return  response.data.OBJECT;
    }

    const gotoMedikationsplan = async (patient) => {
        let medikationsplan = (await callApiAsync({auth, url: medikationsplanApi.getByFilter({patientId: patient.id})})).data.OBJECT[0];

        if (!medikationsplan) {
            medikationsplan = await erstelleMedikationsplan(patient);
        }

        if (medikationsplan) {
            window.open(`/medikationsplan/${medikationsplan.id}`, "parent-default");
        }
    }

    const uploadDokument = async (data, auth) => {
        try {
            let url = dokumenteApi.upload({});
            const response = await callApiAsync({auth, url, data, method: "post", headers: { 'Content-Type': 'multipart/form-data' }});

            return response.data.OBJECT;
        }
        catch (err) {
            console.log(err);
        }
    };

    const editDokument = record => {
        // clone record
        const recordClone = JSON.parse(JSON.stringify(record));

        let patientSeen = false;
        let pflegeheimSeen = false;
        let verordnerSeen = false;
        let quelleSeen = false;
        if (recordClone.verknuepfungen) {
            recordClone.verknuepfungen.forEach(v => {
                switch (v.typ) {
                    case "PATIENT":
                        patientSeen = true;
                        break;
                    case "PFLEGEHEIM":
                        pflegeheimSeen = true;
                        break;
                    case "VERORDNER":
                        verordnerSeen = true;
                        break;
                    case "QUELLE":
                        quelleSeen = true;
                        break;
                }
            });
        } else {
            recordClone.verknuepfungen = [];
        }

        setEditData(prev => ({...prev, record: record}));
        setEditRecord(recordClone);
        setEditVerknuepfung({});
        setEdit(true);

        // if (!patientSeen) erstelleDummyVerknuepfung("PATIENT", record);
        // if (!pflegeheimSeen) erstelleDummyVerknuepfung("PFLEGEHEIM", record);
        // if (!verordnerSeen) erstelleDummyVerknuepfung("VERORDNER", record);
        // if (!quelleSeen) erstelleDummyVerknuepfung("QUELLE", record);
    }

    const editAndOpenDokument = (record) => {
        editDokument(record)
        openDokument(record.id, auth);
    }

    const erstelleDummyVerknuepfung = (typ, dokument) => {
        const verknuepfungDummy = {
            id: --idVerknuepfungNeu.current,
            typ,
            dokument,
            seiteVon: 1,
            gueltigVon: heute,
            bearbeitet: !!dokument.verknuepfungen?.length && DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[typ]].bearbeitetDefault,
        };

        setEditRecord(prev => ({...prev, verknuepfungen: prev.verknuepfungen ? [...prev.verknuepfungen, verknuepfungDummy] : [verknuepfungDummy]}));
    }

    const onChangeVerknuepfung = async (vNeu, vAlt) => {
        document.body.focus();

        if (vNeu.id < 0 && editRecord.verknuepfungen?.length > 1 && vNeu.typ && vNeu.typ !== vAlt?.typ && !vNeu.bearbeitetChanged) {
            vNeu.bearbeitet = DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[vNeu.typ]].bearbeitetDefault;
        }

        setEditRecord(prev => {
            const neu = {...prev};

            neu.verknuepfungen = [...neu.verknuepfungen?.filter(v => v.id !== vAlt?.id), vNeu];

            return neu;
        });
    }

    const onChangeVerknuepfungUnit = async (v, val, last, typ, dokument) => {
        document.body.focus();

        let unit = null;
        if (val) {
            const response = await callApiAsync({auth, url: akteureApi.getById(val.id)});
            unit = response.data.OBJECT;
        }

        if (!typ) {
            typ = last?.typ || v.typ;
        }

        setEditRecord(prev => {
            const neu = {...prev};

            if (unit) {
                // alten Eintrag aus der Liste ermitteln
                if (!neu.verknuepfungen) neu.verknuepfungen = [];
                const verknuepfung = neu.verknuepfungen.find(v2 => v2.id === v.id);

                // Alten Eintrag bearbeiten oder neuen erstellen, wenn kein alter vorhanden
                if (verknuepfung) verknuepfung.unit = unit;
                else neu.verknuepfungen.push({
                    id: --idVerknuepfungNeu.current,
                    dokument,
                    typ,
                    unit: unit,
                    seiteVon: 1,
                    gueltigVon: heute,
                });

                // Verarbeite UnitLangname gesondert
                if (typ === "PATIENT") {
                    // prüfe, ob Verknüpfungen vom letzten Eintrag existieren, die entfernt werden sollen, weil keine anderen Patienten darauf beziehen
                    if (last && last.careFacility) {
                        let pflegeheimSeen = false;
                        for (let v2 of neu.verknuepfungen)
                            if (v2.unit && v2.unit.careFacility && v2.unit.careFacility.id === last.careFacility.id) {
                                pflegeheimSeen = true;
                                break;
                            }

                        // entferne Verknüpfung zu Pflegeheim, wenn kein anderer Eintrag mehr darauf bezieht
                        if (!pflegeheimSeen) {
                            var verknuepfungenFiltered = neu.verknuepfungen.filter(v2 => !v2.unit || v2.unit.id !== last.careFacility.id);
                            neu.verknuepfungen = verknuepfungenFiltered;
                        }
                    }

                    if (!!unit.careFacility) {
                        const verknuepfung = editRecord.verknuepfungen.find(v2 => (v2.unit && v2.unit.id === unit.careFacility.id) || (!v2.unit && v2.typ === "PFLEGEHEIM"));
                        if (verknuepfung) {
                            if (!verknuepfung.unit) {
                                verknuepfung.unit = unit.careFacility;
                            }
                        } else {
                            const verknuepfungPflegeheim = {
                                id: --idVerknuepfungNeu.current,
                                dokument,
                                typ: "PFLEGEHEIM",
                                unit: unit.careFacility,
                                seiteVon: 1,
                                gueltigVon: moment().startOf('day').valueOf(),
                            };

                            neu.verknuepfungen.push(verknuepfungPflegeheim);
                        }
                    }
                }
            } else {
                // gelöschten Eintrag aus der Liste entfernen
                if (!neu.verknuepfungen) neu.verknuepfungen = [];
                neu.verknuepfungen = neu.verknuepfungen.filter(v2 => v2.id !== v.id);

                // Verarbeite UnitLangname gesondert
                if (typ === "PATIENT" && last && last.careFacility) {
                    // prüfe, ob Verknüpfungen vom letzten Eintrag existieren, die entfernt werden sollen, weil keine anderen Patienten darauf beziehen
                    let pflegeheimSeen = false;
                    for (let v2 of neu.verknuepfungen)
                        if (v2.unit && v2.unit.careFacility && v2.unit.careFacility.id === last.careFacility.id) {
                            pflegeheimSeen = true;
                            break;
                        }

                    // entferne Verknüpfung zu Pflegeheim, wenn kein anderer Eintrag mehr darauf bezieht
                    if (!pflegeheimSeen) neu.verknuepfungen.filter(v2 => v2.unit.id !== last.careFacility.id);
                }
            }

            return neu;
        });
    }

    const onFilter = (value, record, dataIndex) => {
        let datum, start, ende;

        switch (dataIndex) {
            case "titel":
                return record.dokument.titel?.toLowerCase()?.includes(value.toLowerCase());
            case "typ":
                return record.typ?.toLowerCase()?.includes(value.toLowerCase());
            case "patient":
            case "pflegeheim":
            case "verordner":
            case "quelle":
                return getUnitLangname(record[dataIndex])?.toLowerCase()?.includes(value.toLowerCase());
            case "datumErstellung":
            case "datumEingang":
                datum = record[dataIndex] ? moment(record[dataIndex]) : null;
                start = value.start ? moment(value.start).startOf('day') : null;
                ende = value.ende ? moment(value.ende).endOf('day') : null;

                return (!start || (datum && datum.isSameOrAfter(start))) && (!ende || (datum && datum.isSameOrBefore(ende)))
            case "timeUploaded":
                datum = moment(record.dokument[dataIndex]);
                start = value.start ? moment(value.start).startOf('day') : null;
                ende = value.ende ? moment(value.ende).endOf('day') : null;

                return (!start || (datum && datum.isSameOrAfter(start))) && (!ende || (datum && datum.isSameOrBefore(ende)))
            case "verknuepfung":
                for (let verknuepfung of record?.verknuepfungen || []) {
                    if (getUnitLangname(verknuepfung.unit)?.toLowerCase()?.includes(value.toLowerCase())) {
                        return true
                    }
                }

                return false;
        }
    };

    const columns = () => {
         const cols = [
            {
                title: '',
                key: 'action',
                render: (text, record) => {
                    if (editRecord.id && editRecord.id === record.id) return (
                        <>
                            {/*<span>*/}
                            {/*    <Tooltip title="Dokument öffnen und bearbeiten"><button onClick={() => openDokument(record.id, auth)}><EditOutlined /></button></Tooltip>*/}
                            {/*    <Tooltip title="Änderungen speichern"><button onClick={() => saveEditRecord()}><SaveOutlined /></button></Tooltip>*/}
                            {/*</span>*/}
                        </>
                    )
                    else return <span onClick={e => e.stopPropagation()} style={{display: 'flex'}}>
                        <Tooltip title="Dokument öffnen und bearbeiten"><button onClick={e => {
                            editAndOpenDokument(record);
                        }}><EditOutlined /></button></Tooltip>

                        {!!bearbeitetStatusFuer.length && record.verknuepfungen.filter(v => bearbeitetStatusFuer.includes(v.unit.id)).map(v =>
                            <Tooltip key={v.id} title={`Verknüpfung ${DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[v.typ]].label} als ${v.bearbeitet ? "unbearbeitet" : "bearbeitet"} markieren`}><button onClick={e => {
                                toggleProcessed(v, record);
                            }}>{v.bearbeitet ? <CloseOutlined /> : <CheckOutlined />}</button></Tooltip>
                        )}

                        <span style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end'}}>
                            <Tooltip title="Dokument löschen">
                                <Popover trigger={'click'} placement={'bottom'} content={
                                    <div>
                                        <h5>Dokument löschen</h5>
                                        Soll das Dokument wirklich gelöscht werden?
                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button size={'small'} type={'primary'} onClick={() => deleteDokument(record)}>JA, löschen</Button>
                                        </div>
                                    </div>
                                }>
                                    <button onClick={() => openDokument(record.id, auth)}><DeleteOutlined /></button>
                                </Popover>
                            </Tooltip>
                        </span>
                    </span>
                },
            },
            {
                title: 'Bezeichnung',
                key: 'titel',
                render: (titel, row) => {
                    if (editRecord.id && editRecord.id === row.id) return <Input value={editRecord.dokument.titel} placeholder={editRecord.dokument.titel} onChange={e => {
                        if (e.target) {
                            const titel = e.target.value;
                            setEditRecord(prev => ({...prev, dokument: {...prev.dokument, titel}}));
                        }
                    }} style={{minWidth: "5ch"}} />
                    else return row.dokument.titel;
                },
                sorter: getSorter("dokument", "titel"),
                ...getColumnSearchProps({dataIndex: 'titel', onFilter}),
            },
            {
                title: 'Typ',
                dataIndex: 'typ',
                render: (typ, row) => {
                    // if (editRecord.id && editRecord.id === row.id) return <Input text={editRecord.typ} placeholder={typ} onChange={e => setEditRecord(prev => ({...prev, typ: e.target.text}))} />
                    if (editRecord.id && editRecord.id === row.id) return <Select value={editRecord.typ || typ} onChange={value => {
                        setEditRecord(prev => ({...prev, typ: value}));
                    }} dropdownMatchSelectWidth={false} style={{minWidth: "10ch"}}>
                        {DOCUMENT_TYPES.map((v, i) => <Option key={i} value={v}><span>{v}</span></Option>)}
                    </Select>
                    else return typ ? typ : "-";
                },
                sorter: getSorter("dokument", "typ"),
                ...getColumnSearchProps({dataIndex: 'typ', onFilter}),
            },
            {
                title: "Verknüpfungen",
                key: "verknuepfungen",
                // render: (row) => row.verknuepfungen.sort(getSorter("dokumentVerknuepfung", "typ")).map(v => <div>{getUnitLangname(v.unit)} ({v.typ})</div>),

                render: (row) => {
                    let verknuepfungen;
                    if (!editRecord.id || editRecord.id !== row.id || !editRecord.verknuepfungen) {
                        verknuepfungen = row.verknuepfungen || [];
                    } else {
                        verknuepfungen = editRecord.verknuepfungen || [];
                    }

                    return <>
                        {verknuepfungen.sort(getSorter("dokumentVerknuepfung", "typ")).map((v, i) => {
                            if (editRecord.id && editRecord.id === row.id) {
                                const verknuepfung = editRecord.verknuepfungen.find(v2 => v.id && v2.id === v.id);
                                return <AkteurPicker
                                    key={v.id}
                                    value={verknuepfung ? verknuepfung.unit : null}
                                    clearOnChange={true}
                                    onChange={async (val, last) => onChangeVerknuepfungUnit(v, val, last, v.typ, row)}
                                    containerStyle={{display: "flex", flexWrap: "wrap", minWidth: "10ch"}}
                                    deleteInSuffix={true}
                                    classNameInput={!v.unit ? "akteur-picker-empty" : ""}

                                    inputRef={input => {
                                        if (!v.unit && input) {
                                            input.focus();
                                        }
                                    }}

                                    suffix={<>
                                        <Select onChange={typ => onChangeVerknuepfung({...v, typ}, v)} defaultValue={v.typ} dropdownMatchSelectWidth={false}>
                                            {DokumentVerknuepfungTypen.map(t => !t.disabled || t.key === v.typ ? <Select.Option key={t.key} value={t.key} disabled={t.disabled}>{t.label}</Select.Option> : null)}
                                        </Select>
                                        <Button onClick={() => onChangeVerknuepfung({...v, bearbeitet: !v.bearbeitet, bearbeitetChanged: true}, v)}>{v.bearbeitet ? "Als unbearbeitet markieren" : "Als bearbeitet markieren"}</Button>
                                    </>}
                                />
                            }

                            else return <div key={v.id}>{getUnitLangname(v.unit)} ({DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[v.typ]].label})</div>;
                        })}
                        {editRecord.id && editRecord.id === row.id && <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                            <Button tabIndex={-1} onClick={() => erstelleDummyVerknuepfung(DokumentVerknuepfungTypen[0].key, row)}>+Verknüpfung</Button>
                        </div>}
                    </>
                },

                ...getColumnSearchProps({dataIndex: 'verknuepfung', onFilter}),
            },
            {
                title: 'Erstellt',
                key: 'creationTimestamp',
                render: row => {
                    if (editRecord.id && editRecord.id === row.id) return <DatePicker format={"DD.MM.YYYY"}
                                                                                      defaultValue={editRecord.datumErstellung ? moment(editRecord.datumErstellung) : null}
                                                                                      onChange={val => setEditRecord(prev => ({...prev, datumErstellung: val ? val.valueOf() : null}))}
                                                                                      style={{minWidth: "10ch"}}
                    />

                    else return row.datumErstellung ? moment(row.datumErstellung).format("dd, DD.MM.YYYY") : "-"
                },
                sorter: getSorter("dokument", "datumErstellung"),
                ...getColumnDateProps("datumErstellung", onFilter),
            },
            {
                title: 'Eingegangen',
                key: 'receptionTimestamp',
                render: row => {
                    if (editRecord.id && editRecord.id === row.id) return <DatePicker format={"DD.MM.YYYY"}
                                                                                      defaultValue={editRecord.datumEingang ? moment(editRecord.datumEingang) : null}
                                                                                      onChange={val => setEditRecord(prev => ({...prev, datumEingang: val ? val.valueOf() : null}))}
                                                                                      style={{minWidth: "10ch"}}
                    />

                    else return row.datumEingang ? moment(row.datumEingang).format("dd, DD.MM.YYYY") : "-"
                },
                sorter: getSorter("dokument", "datumEingang"),
                ...getColumnDateProps("datumEingang", onFilter),
            },
            {
                title: 'Hochgeladen',
                key: 'uploadTimestamp',
                render: row => row.dokument.timeUploaded ? moment(row.dokument.timeUploaded).format("dd, DD.MM.YYYY HH:mm") : "-",
                sorter: getSorter("dokument", "uploadTimestamp"),
                ...getColumnDateProps("timeUploaded", onFilter),
                defaultSortOrder: "descend",
            },
        ];

         if (bearbeitetStatusFuer.length) {
             cols.push(
                 {
                     title: 'Bearbeitet',
                     key: 'bearbeitet',
                     render: row => row.verknuepfungen.filter(v => v.bearbeitet && bearbeitetStatusFuer.includes(v.unit.id)).length ? "JA" : "NEIN",
                     sorter: (a, b) => a.verknuepfungen.filter(v => v.bearbeitet && bearbeitetStatusFuer.includes(v.unit.id)).length - b.verknuepfungen.filter(v => v.bearbeitet && bearbeitetStatusFuer.includes(v.unit.id)).length,
                     sortDirections: ['ascend', 'descend'],
                 }
             )
         }

         if (gueltigkeitFuer.length) {
             cols.push(
                 {
                     title: 'gültig ab',
                     render: (dokument) => {
                         const verknuepfung = dokument.verknuepfungen.find(v => gueltigkeitFuer.includes(v.unit.id));

                         if (verknuepfung) {
                             if (editRecord?.id === dokument.id) {
                                 return <DatePicker format={"DD.MM.YYYY"}
                                                    defaultValue={editVerknuepfung.gueltigVon ? moment(editVerknuepfung.gueltigVon) : verknuepfung.gueltigVon ? moment(verknuepfung.gueltigVon) : null}
                                                    onChange={val => setEditVerknuepfung(prev => ({
                                                        ...prev,
                                                        gueltigVon: val ? val.valueOf() : null
                                                    }))}
                                                    style={{minWidth: "10ch"}}
                                 />
                             } else {
                                 return verknuepfung.gueltigVon ? moment(verknuepfung.gueltigVon).format("DD.MM.YYYY") : "-"
                             }
                         } else {
                             return "-";
                         }
                     }
                 },
                 {
                     title: 'gültig bis',
                     render: (dokument) => {
                         const verknuepfung = dokument.verknuepfungen.filter(v => gueltigkeitFuer.includes(v.unit.id))[0];

                         if (verknuepfung) {
                             if (editRecord?.id === dokument.id) {
                                 return <DatePicker format={"DD.MM.YYYY"}
                                                    defaultValue={editVerknuepfung.gueltigBis ? moment(editVerknuepfung.gueltigBis) : verknuepfung.gueltigBis ? moment(verknuepfung.gueltigBis) : null}
                                                    onChange={val => setEditVerknuepfung(prev => ({
                                                        ...prev,
                                                        gueltigBis: val ? val.valueOf() : null
                                                    }))}
                                                    style={{minWidth: "10ch"}}
                                 />
                             } else {
                                 return verknuepfung.gueltigBis ? moment(verknuepfung.gueltigBis).format("DD.MM.YYYY") : "-"
                             }
                         } else {
                             return "-";
                         }
                     }
                 },
             )
         }

         return cols;
    }

    const deleteDokument = async dokument => {
        const response = await callApiAsync({auth, url: dokumenteApi.put(dokument.id), method: 'PUT', data: {...dokument, aktiv: false}});
        notification.info({message: "Das Dokument wurde erfolgreich gelöscht."});
        onDelete(dokument);
    }

    const toggleProcessed = async (verknuepfung, dokument) => {
        const response = await callApiAsync({auth, url: dokumenteApi.putVerknuepfung(verknuepfung.id), data: {...verknuepfung, bearbeitet: !verknuepfung.bearbeitet, dokument: {id: dokument.id}}, method: "PUT"});

        const verknuepfungNeu = response.data.OBJECT;
        dokument.verknuepfungen = [...dokument.verknuepfungen.filter(v => v.id !== verknuepfungNeu.id), verknuepfungNeu];

        setDataSource([...dataSource]);
    }

    const saveEditRecord = async () => {
        if (!Object.keys(editRecord).length) return;

        // dummy-Verknüpfungen entfernen
        const verknuepfungen = [...editRecord.verknuepfungen];
        for (let i = verknuepfungen.length - 1; i >= 0; i--) {
            if (!verknuepfungen[i].unit) editRecord.verknuepfungen.splice(i, 1);
        }

        if (JSON.stringify(editRecord) !== JSON.stringify(editData.record)) {
            if (editRecord.titel) {
                editRecord.dokument.titel = editRecord.titel;
                delete editRecord.titel;
            }

            // setze das Dokument für alle Verknüpfungen
            editRecord.verknuepfungen.forEach(v => v.dokument = {id: editRecord.id});

            const response = await callApiAsync({auth, url: dokumenteApi.put(editRecord.id), method: "PUT", data: editRecord});
            const dokumentMerged = response.data.OBJECT;
            onUpdate(dokumentMerged);

            setEditData({});
            setEdit(false);
            idVerknuepfungNeu.current = 0;
        }

        if (Object.keys(editVerknuepfung).length) {
            const verknuepfung = verknuepfungen.filter(v => gueltigkeitFuer.includes(v.unit.id))[0];
            const verknuepfungNeu = {...JSON.parse(JSON.stringify(verknuepfung)), dokument: { id: editRecord.id }};
            for (let key of Object.keys(editVerknuepfung)) {
                verknuepfungNeu[key] = editVerknuepfung[key];
            }

            const response = await callApiAsync({auth, url: dokumenteApi.putVerknuepfung(verknuepfungNeu.id), method: "put", data: verknuepfungNeu});
            medikationsplanContext.setVerknuepfungChanged(response.data.OBJECT);
            medikationsplanContext.setReloadVerknuepfungen(true);
        }

        setEditRecord({});
    }

    return <div {...getRootProps()} >
        <Table
            className={"table-dokument"}
            rowSelection={{
                type: "checkbox"
            }}
            pagination={usePagination()}
            rowKey={dokument => dokument.id + (dokument.key ? dokument.key : "")}
            columns={columns()}
            dataSource={dataSource}
            rowClassName={"table-row-dokument"}
            onRow={(record, index) => ({
                style: {cursor: "pointer"},
                onClick: (e) => {
                    if (!Object.keys(editRecord).length) {
                        onClick(record);
                        setDokumentClicked(record);
                    } else if (editRecord.id !== record.id) {
                        (async () => {
                            await saveEditRecord();

                            setDokumentClicked(record);
                            editAndOpenDokument(record);
                        })();
                    }
                }
            })}
            footer={editRecord.id ? () => {
                return <Button onClick={() => saveEditRecord()}><SaveOutlined /> Änderungen speichern</Button>;
            } : null}

            scroll={scroll}
        />

        {/* <pre>{JSON.stringify(text, null, 2)}</pre> */}

    </div >
};

export default DokumentListeTabelle