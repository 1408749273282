import React, {useContext, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import { dateToString } from '../../utilities/myDates'

import { useDataAccess } from '../../utilities/useDataAccess'
import { Fallback } from '../../config/fallbackPages'
import { useAuth } from '../../utilities/useAuth';
import { medikationsplanApi } from '../../config/apiConfig'
import { Button } from 'antd';
import { defaultMedikationsplan } from '../../config/entities';
import {callApiAsync} from "../../utilities/apiUtil";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getWochentag} from "../../utilities/dateUtil";
import adressbuchContext from "../../contexts/AdressbuchContext";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import {Medikationsplan} from "../pages/Medikationsplan";
import {MedikationsplanDetail} from "../organisms/MedikationsplanDetail";


export const AdressbuchMediplaene = ({ ...props }) => {
    const auth = useAuth();
    const history = useHistory();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const adressbuchContext = useContext(AdressbuchContext);

    const akteur = useDataAccess({
        ...props,
    });

    const [medikationsplaene, setMedikationsplaene] = useState(null);
    const [medikationsplanId, setMedikationsplanId] = useState(null);

    useEffect(() => {
        if (!akteur.isReady) return;
        console.log("Akteur geladen, lade jetzt den Medikationsplan für " + akteur.current.id);

        if (!medikationsplaene || medikationsplaene[0]?.patient?.id !== akteur.current.id) {
            loadMedikationsplaene(akteur.current.id);
        }
    }, [akteur.current])

    const loadMedikationsplaene = async (id) => {
        const response = await callApiAsync({auth, url: medikationsplanApi.getByFilter({ patientId: id })});
        if (response.data.RESULT === 'SUCCESS') {
            setMedikationsplaene(response.data.OBJECT);
            setMedikationsplanId(response.data.OBJECT?.[0]?.id);
        }
        else {
            console.error(JSON.stringify(response));
        }
    }
    
    const addMedikationsplan = async () => {
        const response = await callApiAsync({
            auth,
            url: medikationsplanApi.post(),
            data: defaultMedikationsplan(akteur.last),
            method: "post"
        });

        if (response.data.RESULT === 'SUCCESS') {
            const neu = [...(medikationsplaene || []), response.data.OBJECT];
            setMedikationsplaene(neu);
            setMedikationsplanId(neu[0]?.id);
        }
        else {
            console.error(JSON.stringify(response));
        }
    }



    return <>
        <div style={medikationsplanId && {display: "none"}}>
            <h4 style={{marginBottom: 100}}>Medikationspläne</h4>
            {medikationsplaene ?
                <Button type="primary" onClick={addMedikationsplan}>Neuen Medikationsplan anlegen</Button> :
                "Medikationsplan wird gesucht"
            }
            <hr />
        </div >

        {!!medikationsplanId && <MedikationsplanDetail
            medikationsplanId={medikationsplanId}
            ansicht={adressbuchContext.showMedikationsplan?.ansicht}
            selected={adressbuchContext.showMedikationsplan?.planzeileSelected}
        />}
    </>;
}
