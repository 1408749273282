import b64 from 'base64-js';

export const encodeBase64 = (stringUtf8) => {
    return window.btoa(unescape(encodeURIComponent(stringUtf8)));
}

export const encodeBase64FromByteArray = (uint8) => {
    return b64.fromByteArray(uint8);
}

export const decodeBase64 = (encoded) => {
    decodeURIComponent(escape(window.atob(encoded)));
}

export const hash = string => {
    if (!string) return 0;

    const length = string.length;
    let hash = 0, i, chr;
    if (length === 0) return hash;

    for (i = 0; i < length; i++) {
        chr   = string.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}