import {ArzneimittelPicker, getArzneimittelLangname} from "../atoms/Arzneimittel";
import {BranchesOutlined} from "@ant-design/icons";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import {Modal} from "antd";
import React, {useEffect, useState} from "react";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {Popover} from "../atoms/Popover";
import {Tooltip} from "../atoms/Tooltip";

export const ArzneimittelAlternativeIcon = ({
                                                einnahmezeit:zeit={},
                                                planzeile={},
                                                faktorEinnahmezeit=1,
                                                opacity=zeit.arzneimittel ? 1 : .3,
                                                tooltip=!zeit.arzneimittel ? "priorisiertes Arzneimittel für diese Einnahmezeit festlegen" : null,
                                                showRemove=false,
                                                popover=zeit.arzneimittel ? (
                                                    <ArzneimittelAlternativePopover arzneimittelOriginal={planzeile.arzneimittel} arzneimittelAlternative={zeit.arzneimittel} faktor={zeit.faktor ? zeit.faktor : 1} ansichtFaktor={faktorEinnahmezeit} showRemove={showRemove} />
                                                ): null,
                                                pickArzneimittel=null,
                                                initValue=zeit.arzneimittel
}) => {
    const [initialized, setInitialized] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [arzneimittelPicked, setArzneimittelPicked] = useState(initValue);

    useEffect(() => {
        setInitialized(true);
    }, [])

    useEffect(() => {
        if (initialized && pickArzneimittel) pickArzneimittel(arzneimittelPicked);
    }, [arzneimittelPicked])

    const span = (
            <span className="arzneimittel-alternative-icon" style={{paddingLeft: 3, cursor: "pointer", opacity}}
                 onMouseEnter={e => {
                     const parent = e.target.closest(".arzneimittel-alternative-icon");
                     if (!parent) return;

                     parent.style.opacity = 1;

                     const stopIcon = parent.querySelector(".anticon-stop");
                     stopIcon.style.setProperty('display', 'initial');
                 }}
                 onMouseLeave={e => {
                     const parent = e.target.closest(".arzneimittel-alternative-icon");
                     if (!parent) return;

                     parent.style.opacity = opacity;

                     const stopIcon = parent.querySelector(".anticon-stop");
                     stopIcon.style.setProperty('display', 'none');
                 }}
                 onClick={() => {
                     if (pickArzneimittel) {
                         if (arzneimittelPicked && showRemove) setArzneimittelPicked(null);
                         else setShowModal(true);
                     }
                 }}
            >
                <BranchesOutlined style={{position: "absolute", fontSize: 12, paddingLeft: 2, paddingTop: 5}}/>
                <span style={{display: showRemove ? "initial": "none"}}><StopOutlined style={{position: "absolute", fontSize: 14, paddingTop: 3, display: "none"}}/></span>
            </span>
    );

    return (
        <span>
            {tooltip ? <Tooltip title={tooltip}>{span}</Tooltip> : {popover} ? <Popover content={popover} placement={"left"}>{span}</Popover> : span}

            <ArzneimittelAlternativeModal arzneimittel={arzneimittelPicked} visible={showModal} onClose={() => setShowModal(false)} onChange={arzneimittel => {
                setArzneimittelPicked(arzneimittel);
                setShowModal(false);
            }} />
        </span>
    )
}

export const ArzneimittelAlternativePopover = ({arzneimittelOriginal=null, arzneimittelAlternative=null, faktor=1, ansichtArzneimittel=null, ansichtFaktor=1, showRemove=false}) => {
    return (
        <div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <th colSpan={2}>Priorisiertes Arzneimittel</th>
                        </tr>
                        {arzneimittelOriginal && <tr>
                            <th>Original:</th>
                            <td style={{paddingLeft: 5}}>{getArzneimittelLangname(arzneimittelOriginal)}</td>
                        </tr>}
                        {arzneimittelAlternative && <tr>
                            <th>Priorisiert:</th>
                            <td style={{paddingLeft: 5}}>{getArzneimittelLangname(arzneimittelAlternative)}</td>
                        </tr>}
                        {ansichtArzneimittel && <tr>
                            <td>Ansicht:</td>
                            <td style={{paddingLeft: 5}}>{getArzneimittelLangname(ansichtArzneimittel)}</td>
                        </tr>}
                        <tr>
                            {/*{ansichtFaktor !== 1 ? <td>Faktor Alternative:</td> : <th>Faktor:</th>}*/}
                            <th>Faktor:</th>
                            <td style={{paddingLeft: 5}}>{mengeToString(faktor)}</td>
                        </tr>
                        {/*{ansichtFaktor !== 1 && <tr>*/}
                        {/*    <td>Faktor Ansicht:</td>*/}
                        {/*    <td style={{paddingLeft: 5}}>{mengeToString(ansichtFaktor)}</td>*/}
                        {/*</tr>}*/}
                        {/*{ansichtFaktor !== 1 && <tr>*/}
                        {/*    <th>Faktor gesamt:</th>*/}
                        {/*    <th style={{paddingLeft: 5}}>{faktor / ansichtFaktor}</th>*/}
                        {/*</tr>}*/}
                    </tbody>
                </table>
            </div>

            {showRemove && <p><strong>Klicken, um Arzneimittel zu entfernen.</strong></p>}
        </div>
    )
}

const ArzneimittelAlternativeModal = ({visible=false, onClose=()=>{}, onChange=async ()=>{}, arzneimittel=null}) => {
    return (
        <Modal
            visible={visible}
            onCancel={() => {
                onClose();
            }}

            footer={null}

            width={'90%'}
        >
            <ArzneimittelPicker onChange={onChange} value={arzneimittel} syncValue />
        </Modal>
    )
}