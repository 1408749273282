import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input;

export const InputTextfeld = ({ label = null, value, onChange=()=>{}, onBlur=()=>{}, rows = 4, width=300 }) => {


    const handleChange = (e) => {
        onChange(e.target.value, label)
    }


    return <div>
        <TextArea
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            rows={rows}
            style={{ width }}
        />
    </div>

}
