import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Layout, Menu, Slider, Switch, Table, Tabs} from 'antd';
import AdressbuchDetail, {createAkteur, TAB_KEYS} from '../organisms/AdressbuchDetail';
import queryString from 'query-string';

// todo move to .css file
import { siderStyle, mainStyle } from '../../style/css'
import {useApi} from "../../utilities/useApi";
import {useHistory} from "react-router-dom";
import MedikationsplanSuche from "../organisms/MedikationsplanSuche";
import {MedikationsplanDokumente} from "../organisms/MedikationsplanDokumente";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import AdressbuchTable from "../organisms/AdressbuchTable";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";

const {Sider} = Layout;

export const Adressbuch = props => {
    const api = useApi();
    const history = useHistory();

    const adressbuchContext = useContext(AdressbuchContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    useEffect(() => {
        if (!props.match.params.akteurId) {
            adressbuchContext.setAkteur(null);
        } else if (props.match.params.akteurId === "neu") {
            if (!adressbuchContext.akteur && !adressbuchContext.neuType) history.replace("/adressbuch");
        }

        return () => {
            medikationsplanContext.reset();
        }
    }, [props.match.params.akteurId])

    useEffect(() => {
        adressbuchContext.setTab(props.match.params.tab);
    }, [props.match.params.tab])

    useEffect(() => {
        const params = {
            param1: props.match.params.contentParam1,
            param2: props.match.params.contentParam2,
        }

        adressbuchContext.setContentParams(params);
    }, [props.match.params.contentParam1, props.match.params.contentParam2])

    const onTabChange = (tab) => {
        console.log("onTabChange: "+tab);

        let isTabAllowed;
        if (tab) {
            isTabAllowed = TAB_KEYS.includes(tab);
            const url = `/adressbuch/${props.match.params.akteurId}${isTabAllowed ? "/" + tab : ""}`;
            history.replace(url);
        }

        adressbuchContext.setTab(isTabAllowed ? tab : "stammdaten");
    }

    return <Layout>
        <Sider style={{...siderStyle}} className="no-print">
            <MedikationsplanSuche />
            <div style={{flexGrow: 1}}>
                <MedikationsplanDokumente />
            </div>
        </Sider>

        <Layout style={mainStyle}>
            {props.match.params.akteurId ?
                <AdressbuchDetail
                    akteurId={props.match.params.akteurId}
                    onTabChange={onTabChange}
                    context="adressbuch"
                    getAll
                />

                :

                <AdressbuchTable />
            }
         </Layout>
    </Layout>
}