import {createContext, useRef, useState} from "react";

const initVal = {
    substitute: {},
    addSubstitut: (m2String, m2StringSubstitut, faktor)=>{},

    medicineM2Map: {},
    addMedicineM2: medicineM2 => {},

    medicineLoadingPromiseMap: {},
}

const ArzneimittelContext = createContext(initVal);
export default ArzneimittelContext;

export const useInitialState = () => {
    const [substitute, setSubstitute] = useState({});
    const [medicineM2Map, setMedicineM2Map] = useState({});

    const medicineLoadingPromiseMap = useRef({});

    const addSubstitut = (m2String, m2StringSubstitut, faktor) => {
        setSubstitute(prev => ({
            ...prev,
            [m2String]: {...(prev[m2String] || {}), [m2StringSubstitut]: faktor},
            [m2StringSubstitut]: {...(prev[m2StringSubstitut] || {}), [m2String]: (1/faktor)}
        }));
    }

    const addMedicineM2 = (medicineM2) => {
        setMedicineM2Map(prev => ({...prev, [medicineM2.m2]: medicineM2}));
    }

    return {
        substitute,
        addSubstitut,

        medicineM2Map,
        addMedicineM2,

        medicineLoadingPromiseMap: medicineLoadingPromiseMap.current,
    }
}