import moment from "moment";

export const SERVER_ADRESSE = window._env_.REACT_APP_SERVER_URL;
export const SERVER_DEPLOYMENT = window._env_.REACT_APP_SERVER_DEPLOYMENT;
export const BASE_URL_NONMP = `${SERVER_ADRESSE}/${SERVER_DEPLOYMENT}/api/`;
export const BASE_URL = `${BASE_URL_NONMP}mp/`;
export const AUTHENTICATION_API_URL = `${BASE_URL_NONMP}user/login`;



export const accountApi = {
    login: () => AUTHENTICATION_API_URL,
}


export const akteureApi = {
    getAll: (aktiv=true) => BASE_URL + `akteure?aktiv=${aktiv ? "true" : "false"}`,
    getById: (id) => BASE_URL + "akteure/" + id,
    put: (id) => BASE_URL + "akteure/" + id,
    delete: (id) => BASE_URL + "akteure/" + id,
    post: () => BASE_URL + "akteure",
    postUnit: () => BASE_URL_NONMP + "unit",

    getByPflegeheim: (heimId) => BASE_URL + "akteure?pflegeheim=" + heimId,
    getByHausarzt: (akteur) => akteur ? BASE_URL + "akteure?hausarzt=" + akteur.id : null,
    getByVerordner: (akteur) => akteur ? BASE_URL + "akteure?verordner=" + akteur.id : null,
    getVerordnerByPatient: (patientId) => patientId ? BASE_URL + "akteure/verordner/patient/" + patientId : null,
    getHausarztByPatient: (patientId) => patientId ? BASE_URL + "akteure/hausarzt/patient/" + patientId : null,

    getMedikationsplanVorhanden: () => BASE_URL + "akteure?medikationsplanVorhanden=true",

    getByNameDateOfBirthConcat: (nameDateOfBirthConcat) => BASE_URL + "akteure?nameDateOfBirthConcat=" + encodeURIComponent(nameDateOfBirthConcat),

    find: (t, r = null, i = false, g = null) => {
        const url = BASE_URL + "akteure?"
        + (r ? 'rolle=' + r + '&' : '')
        + (g ? 'gruppe=' + g + '&' : '')
        + (i ? 'institution=true&' : '')
        + 'filter=' + t;

        return url;
    }
}

export const medikationsplanApi = {
    übersicht: () => BASE_URL + "medikationsplan/",

    getById: (id) => BASE_URL + "medikationsplan/" + id,
    getAll: () => BASE_URL + "medikationsplan/",
    put: (id) => BASE_URL + "medikationsplan/" + id,
    delete: (id) => BASE_URL + "medikationsplan/" + id,
    post: () => BASE_URL + "medikationsplan/",
    postUpdateObject: () => BASE_URL + "medikationsplan/update",
    find: (t) => BASE_URL + "medikationsplan?filter=" + t,

    addByJob: (planId, jobId, patientId) => BASE_URL + "medikationsplan/" + planId + "/import/job/" + jobId + "/patient/" + patientId,

    getByFilter: (filter) => {
        if (filter && filter.patientId)
            return BASE_URL + 'medikationsplan/patient/' + filter.patientId
        return null
    },

    getBlisterbar: (datumReferenz) => {
        let query = "";
        if (datumReferenz) query = "?ref=" + moment(datumReferenz).format("YYYYMMDD");

        return BASE_URL + `medikationsplan/blisterbar` + query;
    },

    getAnsichtenByPlanId: planId => BASE_URL + "medikationsplan/" + planId + "/ansichten",
    getAnsichtenPlanzeilenByPlanIdAndAnsichtBezeichnung: (planId, ansichtBezeichnung) => BASE_URL + "medikationsplan/" + planId + "/ansichten/" + encodeURIComponent(ansichtBezeichnung),
    resetAbgabeplan: () => BASE_URL + `abgabeplan/reset`,
    getBundesMedikationsplanPDF: (planId, params) => BASE_URL + `medikationsplan/${planId}/bmp?${Object.keys(params).filter(k => !!params[k]).map(k => `${k}=${params[k]}`).join('&')}`,

    getLogs: (planId, datumReferenzStart, datumReferenzEnde) => BASE_URL + `medikationsplan/${planId}/log?start=${datumReferenzStart.format("YYYYMMDD")}&ende=${datumReferenzEnde.format("YYYYMMDD")}`,

    getZeitraeumeByUnit: (unitId) => BASE_URL + `zeitraum?unit=${unitId}`,
    getZeitraeumeByMedikationsplan: (planId) => BASE_URL + `zeitraum?medikationsplanId=${planId}`,
    zeitraumApi: () => BASE_URL + `zeitraum`,

    getAbgaben: (planId, start, ende) => !planId ? null : BASE_URL + `medikationsplan/${planId}/abgabe/${start ? moment(start).format("YYYY-MM-DD") : "default"}${ende ? "/"+moment(ende).format("YYYY-MM-DD") : ""}`,
}

export const planzeilenApi = {
    // postByParentId: (planId) => BASE_URL + "medikationsplan/" + planId + "/planzeilen",
    // getByParentId: (planId) => BASE_URL + "medikationsplan/" + planId + "/planzeilen",

    getById: (id, ignoreActiveState) => BASE_URL + `planzeile/${id}?ignoreActiveState=${ignoreActiveState ? "true" : "false"}`,
    getByFilter: (filter) => {
        if (filter && filter.planId)
            return BASE_URL + 'medikationsplan/' + filter.planId + '/planzeilen'
        else if (filter && filter.patientId)
            return BASE_URL + 'planzeile/patient/' + filter.patientId
        return null
    },
    put: (id) => BASE_URL + "planzeile/" + id,
    delete: (id) => BASE_URL + "planzeile/" + id,
    post: (meta) => {
        if (meta.planId)
            return BASE_URL + "medikationsplan/" + meta.planId + "/planzeilen"
        return null
    },

    putOrDeleteAnsicht: (planzeileId, ansichtBezeichnung) => BASE_URL + "planzeile/" + planzeileId + "/ansichten/" + encodeURIComponent(ansichtBezeichnung),
    getBedarfAnforderungen: (planzeileId) => BASE_URL + `planzeilebedarfanforderung/${planzeileId}`,

    transferAnspruch: (planzeileId, m2) => BASE_URL + `planzeile/${planzeileId}/transfer/anspruch/m2/${m2}`,
    transferBestand: (planzeileId, m2) => BASE_URL + `planzeile/${planzeileId}/transfer/bestand/m2/${m2}`,

    getDosierabschnittString: (planzeileId, parseBuendel=false, delimiterTag=", ", delimiterZeit=", ", printTypeLabel=false) => BASE_URL + `planzeile/${planzeileId}/dosierabschnittstring?delimiterTag=${encodeURIComponent(delimiterTag)}&parseBuendel=${parseBuendel ? 'true' : 'false'}&delimiterZeit=${encodeURIComponent(delimiterZeit)}&printTypeLabel=${printTypeLabel ? "true" : "false"}`,

    splitDosierschema: () => BASE_URL + 'planzeile/splitDosierschema'
}

export const buendelApi = {
    getById: (id, deep=false) => BASE_URL + `buendel?id=${id}&deep=${deep ? 'true' : 'false'}`,
    getByMedikationsplan: (medikationsplanId) => BASE_URL + `buendel?plan=${medikationsplanId}`,
    getByM2StringAndPatient: (m2String, patientId, noMerge) => BASE_URL + `buendel?m2=${m2String}&patient=${patientId}${noMerge ? '&noMerge=true' : ''}`,

    getEntitlementList: (bundleId) => BASE_URL_NONMP + `entitlement/bundle/${bundleId}`,
    resetPlanzeile: (planzeileId) => BASE_URL + `planzeile/${planzeileId}/buendel/reset`,
    resetAnspruchPOST: () => BASE_URL_NONMP + `entitlement`,
    resetBestandPOST: () => BASE_URL_NONMP + `package`,
}

export const arzneimittelApi = {
    getById: (id) => BASE_URL + "arzneimittel/" + id,
    getByM2: (m2) => BASE_URL + "arzneimittel/m2/" + m2,
    find: (t, incDeleted = false) => BASE_URL + `arzneimittel?filter=${t}&incDeleted=${incDeleted ? 'true' : 'false'}`,

    getMedicineByM2: (m2, importIfNoResult=false, persistInDB=true) => BASE_URL_NONMP + `medicine/${m2}?importIfNoResult=${importIfNoResult ? "true" : "false"}&persistInDB=${persistInDB ? "true" : "false"}`,

    getSubstitutFaktor: (m2, m2Substitut) => `${BASE_URL_NONMP}medicine/${m2}/substitute/check/${m2Substitut}/abdata`,
}

export const printjobApi = {
    getByBarcode: (barcode) => BASE_URL_NONMP + `printjob/bc/${barcode}`,
}


export const aufgabenApi = {
    übersicht: () => BASE_URL + "aufgaben/",
}


export const dokumenteApi = {
    getInfozeileById: (dokumentId) => BASE_URL + 'infomarker/dokument/' + dokumentId,
    putInfomarkerById: infomarkerId => BASE_URL + 'infomarker/' + infomarkerId,

    countNew: (patientId) => BASE_URL + 'dokumente/countnew/akteur/' + patientId,
    
    // todo: grundsätzlich Object.keys(props) für filter, pagination... verwenden
    upload: ({ patient = null, pflegeheim = null, verordner = null, quelle = null }) => {
        let result = BASE_URL + "dokumente?"
        if (verordner && verordner.id) result += 'verordnerId=' + verordner.id + '&'
        if (quelle && quelle.id) result += 'quelleId=' + quelle.id + '&'
        if (patient && patient.id) result += 'patientId=' + patient.id + '&'
        if (pflegeheim && pflegeheim.id) result += 'pflegeheimId=' + pflegeheim.id
        return result
    },

    bearbeitungBeginnen: id => BASE_URL + "dokumente/" + id + "/bearbeitung/beginnen",
    bearbeitungAbschliessen: id => BASE_URL + "dokumente/" + id + "/bearbeitung/abschliessen",
    setUnbearbeitet: id => BASE_URL + "dokumente/" + id + "/bearbeitung/setunbearbeitet",

    download: (id) => BASE_URL + "dokumente/download/" + id,
    downloadAddToken: (id, auth) => BASE_URL + "dokumente/download/" + id + "?token=" + auth.token,
    postByParentId: (patientId) => {
        if (patientId && patientId !== undefined)
            return BASE_URL + "dokumente?patientId=" + patientId
        else
            return BASE_URL + "dokumente"
    },


    // mandatory for useSearch
    getById: (id) => BASE_URL + "dokumente/meta/" + id,
    getByIdList: (idList) => BASE_URL + "dokumente/meta",

    getNew: () => BASE_URL + 'dokumente/new',
    getByPatient: (patientId) => patientId ? BASE_URL + 'dokumente/akteur/' + patientId : null,
    getVerknuepfungenByPatient: (patientId, gueltigVon, gueltigBis) => patientId ? BASE_URL + `dokumente/verknuepfung/?unitId=${patientId}&gueltigVon=${gueltigVon ? moment(gueltigVon).format("YYYY-MM-DD") : ""}&gueltigBis=${gueltigBis ? moment(gueltigBis).format("YYYY-MM-DD") : ""}` : null,
    getVerknuepfungenByUnit: (unitId, gueltigVon, gueltigBis) => unitId ? BASE_URL + `dokumente/verknuepfung/?unitId=${unitId}&gueltigVon=${gueltigVon ? moment(gueltigVon).format("YYYY-MM-DD") : ""}&gueltigBis=${gueltigBis ? moment(gueltigBis).format("YYYY-MM-DD") : ""}` : null,
    getVerknuepfungenUnbearbeitet: () => BASE_URL + `dokumente/verknuepfung/unbearbeitet`,
    getByMedikationsplan: (planId) => planId ? BASE_URL + `dokumente/medikationsplan/${planId}` : null,

    getByFilter: (filter) => {
        const queryParams = [];
        if (filter) {
            if (filter.patientId) {
                queryParams.push("patientId="+filter.patientId);
            }

            if (filter.verordnerId) {
                queryParams.push("verordnerId="+filter.verordnerId);
            }

            if (filter.quelleId) {
                queryParams.push("quelleId="+filter.quelleId);
            }

            if (filter.planzeileId) {
                queryParams.push("planzeileId="+filter.planzeileId);
            }

            if (filter.unverknuepft) {
                queryParams.push("unverknuepft=true");
            }

            if (filter.unbearbeitet) {
                queryParams.push("unbearbeitet=true");
            }
        }

        return BASE_URL + `dokumente${queryParams.length ? "?"+queryParams.join("&") : ""}`;
    },

    putVerknuepfung: (verknuepfungId) => BASE_URL + "dokumente/verknuepfung/" + verknuepfungId,
    put: (id) => BASE_URL + "dokumente/" + id,
    delete: (id) => BASE_URL + "dokumente/" + id,
    post: (meta) => {
        if (meta && meta.patientId)
            return BASE_URL + "dokumente?patientId=" + meta.patientId
        else
            return BASE_URL + "dokumente/"
    },


}



export const verblisterungApi = {
    getByFilter: (filter) => {
        if (filter && filter.patientId)
            return BASE_URL_NONMP + 'job/patient/' + filter.patientId
        else
            return null
    },

    importPanzeilen: (jobId) => {
        return BASE_URL + 'planzeile/import/job/' + jobId
    },

    getBlisterJobsByPatient: (patientId) => {
        return BASE_URL_NONMP + 'job/patient/' + patientId
    },
    getBlisterJobDetailByJobAndPatient: (jobId, patientId) => {
        return BASE_URL_NONMP + 'job/' + jobId + '/patient/' + patientId + '/entries'
    },
    createBlister: (planId, start, ende, neuimport, uuid, skipBroadcast=false, useBuendel=true) => {
        const params = [];
        if (neuimport) params.push("neuimport=true");
        if (uuid) params.push("uuid="+uuid);
        if (skipBroadcast) params.push("skipBroadcast="+(skipBroadcast ? "true" : "false"));
        if (useBuendel) params.push("buendel="+(useBuendel ? "true" : "false"));
        return BASE_URL + `medikationsplan/${planId}/blister/create/${start}/${ende}${params.length ? "?" + params.join("&") : ""}`;
    },
    getUeberschneidungen: (patientId, start, ende) => BASE_URL_NONMP + `job/person/${patientId}/range/intake/${start ? moment(start).format("YYYYMMDD") : "null"}/${ende ? moment(ende).format("YYYYMMDD") : "null"}?active=true`,
    getLetzteEinnahme: (patientId) => BASE_URL_NONMP + `job/patient/${patientId}/latestintakedate`,
    getLetzterExport: (patientId) => BASE_URL_NONMP + `job/patient/${patientId}/latestexportdate`,
    setIgnoreForAmentment: () => BASE_URL_NONMP + `job/setIgnoreForAmendment`,
    getAllBlisterTypes: () => BASE_URL_NONMP + "blister/type/all",
}

export const lagerApi = {
    getByParentId: (akteurId) => BASE_URL_NONMP + "entitlement/" + akteurId,
    checkSubstitute: (planM2, anspruchM2) => BASE_URL_NONMP + "medicine/" + planM2 + "/substitute/check/" + anspruchM2 + "/abdata",

    getPackungById: (packungId, ignoreInactive=false) => BASE_URL_NONMP + `package/${encodeURIComponent(packungId)}?ignoreInactive=${ignoreInactive ? "true" : "false"}`,

    postSyncBestand: () => BASE_URL + 'bestand/sync',
}

export const infoMarkerApi = {
    getByPlanzeile: planzeileId => BASE_URL + "infomarker?planzeileId=" + planzeileId,
    delete: infoMarkerId => BASE_URL + `infomarker/${infoMarkerId}`,
    put: infoMarkerId => BASE_URL + `infomarker/${infoMarkerId}`,
}

export const nachrichtApi = {
    getDokumentById: dokumentId => BASE_URL_NONMP + `nm/nachricht/dokument/${dokumentId}`,
    getDokumenteByIdMerged: dokumentIdList => BASE_URL_NONMP + `nm/nachricht/dokument/multiple?dokumente=${encodeURIComponent(dokumentIdList.join(","))}`,
}

export const anforderungApi = {
    getById: (anforderungId) => BASE_URL + `anforderung/${anforderungId}`,
    getDatumFuerAnforderung: (planzeileId, refDatum) => BASE_URL + `planzeile/${planzeileId}/datumanforderung${refDatum ? "?ref=" + moment(refDatum).format("YYYYMMDD") : ""}`,
    put: (anforderungId) => BASE_URL + `anforderung/${anforderungId}`,
    post: () => BASE_URL + `anforderung`,
}

export const anspruchApi = {
    reinitAnsprueche: () => BASE_URL + `anspruch/reinit`,
    resetPlanzeile: () => BASE_URL + `anspruch/reset/planzeile`,
    resetUnit: () => BASE_URL + `anspruch/verknuepfeplanzeile`,
}

export const maintenanceApi = {
    importNeueDokumente: (dirKey) => BASE_URL + `maintenance/import/dokumente/dir/${dirKey}`,
    resetPlanzeilen: (mechanism) => BASE_URL + `maintenance/reichweite/reset/${mechanism}?logInfo=true`,
    getResetStatus: () => BASE_URL + `maintenance/reichweite/reset/status`,
}

export const interaktionApi = {
    getInteraktionenByM2List: (m2List) => BASE_URL_NONMP + `interaction?m2=${m2List.join("&m2=")}`,
    getUeberschriften: (dateinr) => BASE_URL_NONMP + `interaction/headerText/${dateinr}`,
    getBewertungByPatientAndInteraktion: (patientId, interaktionId) => BASE_URL_NONMP + `interaction/assessment/${patientId}/${interaktionId}`,
    getHashesByInteraktion: (interaktionId) => BASE_URL_NONMP + `interaction/hash/${interaktionId}`,

    putBewertung: (recreateHashes=true) => BASE_URL_NONMP + `interaction/assessment${recreateHashes ? "?recreateHashes=true" : ""}`,
    deleteBewertung: (unitId, interactionId) => BASE_URL_NONMP + `interaction/assessment/${unitId}/${interactionId}`,
}

export const notizApi = {
    getById: (id) => BASE_URL + `notiz/${id}`,
    getByUnit: (unitId) => BASE_URL + `notiz/unit/${unitId}`,
    getByUnitAndGueltigkeit: (unitId, datumGueltigkeit) => BASE_URL + `notiz/unit/${unitId}/gueltigkeit/${moment(datumGueltigkeit).format("YYYYMMDD")}`,
    getUnbearbeitet: ()=> BASE_URL + `notiz/unbearbeitet`,
    post: () => BASE_URL + "notiz",
    delete: (notizId) => BASE_URL + `notiz/${notizId}`,
}

export const settingApi = {
    get: (name) => BASE_URL_NONMP + `setting/${name}`,
    post: (name, value) => BASE_URL_NONMP + `setting/${name}/${value}`,
    delete: (name) => BASE_URL_NONMP + `setting/${name}`,
}

export const webSocketApi = {
    open: (token) => `${SERVER_ADRESSE}/${SERVER_DEPLOYMENT}/socket/${token}`,
}