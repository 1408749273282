import {createContext, useEffect, useState} from "react";
import {webSocketApi} from "../config/apiConfig";
import {useAuth} from "../utilities/useAuth";

const initVal = {
    isOpen: false,
    open: (token) => {},

    addListener: (type, callback) => {},
    removeListener: (type, callback) => {},
}

const WebSocktContext = createContext(initVal);
export default WebSocktContext;

export const useInitialState = () => {
    const [webSocket, setWebSocket] = useState();
    const [listeners, setListeners] = useState({});

    useEffect(() => {
        return () => {
            if (webSocket) {
                webSocket.close();
            }
        }
    }, [webSocket])

    useEffect(() => {
        if (listeners) {}
    }, [listeners])

    const open = (token) => {
        if (webSocket) return;

        const url = webSocketApi.open(token).replace("http", "ws");
        const ws = new WebSocket(url);

        ws.onopen = (e) => {
            console.log("WebSocket.onopen: ", e);
            setWebSocket(ws);
        }

        ws.onclose = (e) => {
            console.log("WebSocket.onclose: ", e);
        }

        ws.onerror = (e) => {
            console.log("WebSocket.onerror: ", e);
        }

        ws.onmessage = (e) => {
            const data = JSON.parse(e.data);

            const callbacks = listeners[data.type];
            if (callbacks?.length) {
                for (let callback of callbacks) {
                    callback(data);
                }
            }
        }
    }

    const addListener = (type, callback) => {
        setListeners(prev => ({...prev, [type]: [...(prev[type] || []), callback]}));
    }

    const removeListener = (type, callback) => {
        setListeners(prev => ({...prev, [type]: (prev[type] || []).filter(c => c !== callback)}))
    }

    const send = (msg) => {
        if (!webSocket) return false;
        webSocket.send(JSON.stringify(msg));
        return true;
    }

    return {
        isOpen: !!webSocket,
        open,

        addListener,
        removeListener,
        send,
    }
}