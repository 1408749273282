import React, {useContext, useEffect, useRef, useState} from 'react'
import moment from 'moment';
import {Button, Checkbox, Modal, notification, Popconfirm} from 'antd'

import {useAuth} from "../../utilities/useAuth";
import {callApiAsync} from "../../utilities/apiUtil";
import {verblisterungApi} from "../../config/apiConfig";
import {InputDatum} from "../atoms/InputDatum";
import ExportOutlined from "@ant-design/icons/lib/icons/ExportOutlined";
import {
    InfoCircleFilled,
    WarningFilled
} from "@ant-design/icons";
import {getExportEnde, getExportStart} from "../../utilities/blisterJobUtil";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {ConditionalTooltip} from "../atoms/ConditionalTooltip";
import {getUnitLangname} from "../../config/entities";
import JobExportContext from "../../contexts/JobExportContext";
import {JobExportModal} from "./JobExportModal";
import useApiZeitraum, {getZeitraumDelta} from "../../utilities/useApiZeitraum";
import {naechsterWochentag} from "../../utilities/myDates";

export const BlisterJobExport = ({blisterjobs = null, triggerReload = null, updated, start, ende}) => {
    const auth = useAuth();
    const zeitraumApi = useApiZeitraum();

    const medikationsplanContext = useContext(MedikationsplanContext);
    const jobExportContext = useContext(JobExportContext);

    const [letzteEinnahme, setLetzteEinnahme] = useState(null);
    const [exportStart, setExportStart] = useState(start);
    const [exportEnde, setExportEnde] = useState(ende);
    const [exportDisabled, setExportDisabled] = useState(false);
    const [confirmAenderung, setConfirmAenderung] = useState(false);
    const [tageAusgleich, setTageAusgleich] = useState(-1);

    const [startLuecke, setStartLuecke] = useState(0);
    const [startPopoverContent, setStartPopoverContent] = useState(null);
    const [diffTage, setDiffTage] = useState(0);

    const [freigabeFehlt, setFreigabeFehlt] = useState(false);

    const exportFinishedRef = useRef(false);
    const neuimport = useRef(false);

    const [reichweiteWirdBerechnetViaContext, setReichweiteWirdBerechnetViaContext] = useState(false);
    const [reichweiteWurdeNeuBerechnet, setReichweiteWurdeNeuBerechnet] = useState(false);


    useEffect(() => {
        if (!blisterjobs || !medikationsplanContext.medikationsplan) return;

        (async () => {
            try {
                const response = await callApiAsync({
                    auth,
                    url: verblisterungApi.getLetzteEinnahme(medikationsplanContext.medikationsplan.patient.id),
                    skipErrorLog: true,
                });

                if (response.data.OBJECT === 0) {
                    setLetzteEinnahme(0);
                } else {
                    setLetzteEinnahme(naechsterWochentag(medikationsplanContext.medikationsplan.blisterStartTag, response.data.OBJECT, -1));
                }
            } catch (e) {
                setLetzteEinnahme(0);
            }
        })();
    }, [blisterjobs, medikationsplanContext.medikationsplan]);

    useEffect(() => {
        if (!exportStart) {
            setExportStart(getExportStart(medikationsplanContext.medikationsplan, letzteEinnahme));
        }
    }, [letzteEinnahme])

    useEffect(() => {
        medikationsplanContext.setExportEnde(exportEnde);
    }, [exportEnde])

    useEffect(() => {
        if (exportStart) {
            let {exportEnde, startLuecke, tageAusgleich} = getExportEnde(exportStart, medikationsplanContext.medikationsplan, letzteEinnahme);
            if (startLuecke < 0 && blisterjobs.length) {
                exportEnde = blisterjobs[0].end;
            }

            setExportEnde(exportEnde);
            setStartLuecke(startLuecke);
            setTageAusgleich(tageAusgleich);

            medikationsplanContext.setExportStart(exportStart);
        }
    }, [exportStart])

    useEffect(() => {
        if (blisterjobs && medikationsplanContext.exportStart) {
            setExportStart(medikationsplanContext.exportStart.valueOf());
        }
    }, [medikationsplanContext.exportStart, blisterjobs]);

    useEffect(() => {
        if (exportStart && exportEnde) {
            const start = moment(exportStart);
            const ende = moment(exportEnde);

            let zeitraeumeOkay = true;
            if (Object.keys(medikationsplanContext.zeitraumMap).length) {
                const delta = getZeitraumDelta(medikationsplanContext.zeitraumMap, start, ende);
                zeitraeumeOkay = !!delta?.length;
            }

            if (zeitraeumeOkay && startLuecke) {
                // berechne die Different zwischen Start und Ende
                const diffTageNeu = ende.diff(start, 'days');
                setDiffTage(diffTageNeu);

                // erstelle den Popover-Content für Start, falls nötig
                let startPopoverContentNeu = null;
                if (startLuecke > 0) {
                    startPopoverContentNeu =
                        <h6>Diese Auswahl erzeugt eine Lücke von {startLuecke} {startLuecke > 1 ? "Tagen" : "Tag"} zum
                            letzten Blister</h6>;
                } else if (startLuecke < 0) {
                    const diff = Math.min(Math.abs(startLuecke), diffTageNeu);
                    startPopoverContentNeu = <h6>Diese Auswahl erzeugt eine
                        Änderung{diffTageNeu > Math.abs(startLuecke) && <> für {diff > 1 ? "die" : "den"} ersten {diff > 1 && diff} {diff > 1 ? "Tage" : "Tag"}</>}</h6>;
                }
                setStartPopoverContent(startPopoverContentNeu);
            }

            setExportDisabled(!zeitraeumeOkay);
        }
    }, [exportStart, exportEnde, startLuecke, medikationsplanContext.zeitraumMap])

    useEffect(() => {
        if (medikationsplanContext.planzeilen) {
            for (let planzeile of medikationsplanContext.planzeilen) {
                if (!planzeile.freigabe) {
                    setFreigabeFehlt(true);
                    return;
                }
            }
        }

        setFreigabeFehlt(false);
    }, [medikationsplanContext.planzeilen])

    useEffect(() => {
        if (medikationsplanContext.berechneReichweiteAb && medikationsplanContext.reichweiteWirdBerechnet.length) {
            setReichweiteWirdBerechnetViaContext(true);
        } else if (!medikationsplanContext.berechneReichweiteAb && !medikationsplanContext.reichweiteWirdBerechnet.length) {
            if (reichweiteWirdBerechnetViaContext) {
                setReichweiteWurdeNeuBerechnet(true);
            }

            setReichweiteWirdBerechnetViaContext(false);
        }
    }, [medikationsplanContext.berechneReichweiteAb, medikationsplanContext.reichweiteWirdBerechnet])

    useEffect(() => {
        if (reichweiteWurdeNeuBerechnet && !medikationsplanContext.fehlerBeiReichweitenberechnungAb) {
            startExport();
        } else {
            medikationsplanContext.setFehlerBeiReichweitenberechnungAb(null);
            medikationsplanContext.setBerechneReichweiteAllePlanzeilen(false);
        }

        medikationsplanContext.setCheckAenderungsauftragAb(null);
        setReichweiteWurdeNeuBerechnet(false);
    }, [reichweiteWurdeNeuBerechnet])

    useEffect(() => {
        if (jobExportContext.exportListStarted.length && jobExportContext.exportListStarted.length === jobExportContext.exportListFinished.length) {
            medikationsplanContext.setExportFinished(true);
            medikationsplanContext.setExportPending(false);

            exportFinishedRef.current = true;
        }
    }, [jobExportContext.exportListStarted, jobExportContext.exportListFinished])

    useEffect(() => {
        if (exportFinishedRef.current || medikationsplanContext.displayExportResult) {
            medikationsplanContext.setDisplayExportResult(false);
            exportFinishedRef.current = false;

            const jobIds = jobExportContext.exportListResult.map(j => j.id);
            if (jobIds.length) notification.info({message: jobIds.length > 1 ? `Jobs #${jobIds.join(", #")} wurden erfolgreich erstellt.` : `Job #${jobIds[0]} wurde erfolgreich erstellt.`});
            else notification.info({message: "Für diesen Zeitraum musste kein neuer Auftrag erstellt werden."});

            jobExportContext.setExportListResult([]);

            if (triggerReload) triggerReload();
        }
    }, [jobExportContext.exportListResult, medikationsplanContext.displayExportResult])




    const exportBisterjob = () => {
        let lastExportEnde = 0;
        blisterjobs.map(j => {
            if (lastExportEnde < j.end) lastExportEnde = j.end
        });

        if (exportStart <= lastExportEnde) {
            setConfirmAenderung(true);
        } else {
            startExport();
        }
    };

    const startExport = async () => {
        if (!jobExportContext.exportListStarted.length) {
            medikationsplanContext.setExportPending(true);

            // Für einen Änderungsauftrag muss der Zeitraum neu berechnet werden
            // if (!reichweiteWurdeNeuBerechnet && startLuecke < 0) {
            //     if (!medikationsplanContext.berechneReichweiteAb) {
            //         const endeExportNeu = moment(blisterjobs[0].end).startOf('day');
            //         let startExportNeu = moment(exportStart).startOf('day');
            //         for (let job of blisterjobs) {
            //             if (moment(job.end).isBefore(startExportNeu)) {
            //                 break;
            //             }
            //
            //             const startJob = moment(job.start).startOf('day');
            //             if (startJob.isSameOrBefore(startExportNeu)) {
            //                 startExportNeu = startJob;
            //             }
            //         }
            //
            //         medikationsplanContext.setBerechneReichweiteNachExport(true);
            //         medikationsplanContext.setBerechneReichweiteAllePlanzeilen(true);
            //         medikationsplanContext.setBerechneReichweiteAb(startExportNeu.valueOf());
            //         medikationsplanContext.setBerechneReichweiteBis(endeExportNeu.add(1, 'day').valueOf());
            //         medikationsplanContext.setBerechneReichweiteAwaitFinish(true);
            //     }
            //     return;
            // }

            jobExportContext.setPatientBlisterzeitraumMap({[medikationsplanContext.medikationsplan.id]: {start: exportStart, ende: exportEnde, startLuecke, letzteEinnahme}});
            jobExportContext.setHandleExportMedikationsplaene([{...medikationsplanContext.medikationsplan, planzeilen: medikationsplanContext.planzeilen, neuimport: neuimport.current}]);
            jobExportContext.setMergetype("NONE");
        }
    }

    const buttonType = freigabeFehlt ? "danger" : "primary";
    const startTooltipText = startLuecke === 0 ? null : startLuecke > 0 ?
        `Diese Daten erstellen eine Lücke von ${startLuecke} Tagen zum letzten Blisterauftrag` :
        "Diese Daten überschneiden sich mit bereits exportieren Blisteraufträgen"

    return <div>
        <JobExportModal
            onCancel={() => medikationsplanContext.setExportPending(false)}
        />

        <h4>Blisterauftrag erstellen:</h4>
        <div>
            {/*{updated && <Tooltip title="Bitte zuerst speichern"><Button onClick={exportBisterjob} type="primary" disabled><ExportOutlined/>Blisterauftrag erstellen</Button></Tooltip>}*/}
            {freigabeFehlt && <Popconfirm title={"Sind Sie sicher, dass der Blisterauftrag erstellt werden soll?\nFür nicht freigegebene Planzeilen wird dann der zuletzt freigegebene Stand genutzt."} placement={"bottom"} okText={"JA, Blisterauftrag erstellen"} onConfirm={exportBisterjob}><ConditionalTooltip title={"Mindestens eine Planzeile ist nicht freigegeben"} ><Button loading={medikationsplanContext.exportPending} type={buttonType} disabled={exportDisabled}><ExportOutlined/>Blisterauftrag erstellen</Button></ConditionalTooltip></Popconfirm>}
            {!freigabeFehlt && <Button onClick={exportBisterjob} loading={medikationsplanContext.exportPending} type={buttonType} disabled={exportDisabled}><ExportOutlined/>Blisterauftrag erstellen</Button>}

            Start: <InputDatum
                markDay={moment(letzteEinnahme).days(1)}
                markWeekDay={medikationsplanContext.medikationsplan?.blisterStartTag}
                value={exportStart}
                onChange={setExportStart}
                allowClear={false}

                suffixIcon={startLuecke === 0 ? null : startLuecke > 0 ?
                    <WarningFilled style={{color: "red"}} /> :
                    <InfoCircleFilled style={{color: "#007bff"}} />}
                popover={startPopoverContent}
            />

            Ende: <InputDatum
                markWeekDay={medikationsplanContext.medikationsplan?.blisterStartTag}
                value={exportEnde}
                onChange={setExportEnde}
                allowClear={false}

                minDate={exportStart}
            />

            {auth.developer && <><Checkbox checked={jobExportContext.forceShowModal} onChange={() => jobExportContext.setForceShowModal(!jobExportContext.forceShowModal)} />zeige Modal</>}

            <i style={{marginLeft: 5}}>({diffTage > 7 && tageAusgleich > 0 ? `Änderung des Blisterstarts: ${tageAusgleich}+${diffTage - tageAusgleich}` : tageAusgleich < 0 ? "manuell: "+diffTage : diffTage} {diffTage > 1 ? "Tage" : "Tag"})</i>
        </div>


        <hr/>


        <Modal
            title="Überschneidung gefunden"
            visible={confirmAenderung}
            onCancel={() => setConfirmAenderung(false)}
            onOk={() => {
                setConfirmAenderung(false);
                startExport();
            }}
            width={650}

            footer={[
                <Button key={"abbrechen"} onClick={() => setConfirmAenderung(false)}>NEIN, abbrechen</Button>,
                <Button key={"neuimport"} onClick={() => {
                    setConfirmAenderung(false);
                    neuimport.current = true;
                    startExport();
                }}>NEIN, komplett neu erstellen</Button>,
                <Button key={"aenderung"} onClick={() => {
                    setConfirmAenderung(false);
                    medikationsplanContext.setExportingAmmendment(true);
                    startExport();
                }}>JA, Änderungsauftrag erstellen</Button>
            ]}
        >
            <h3>Für den gewählten Zeitraum gibt es bereits Blisteraufträge</h3>
            Soll der Zeitraum als Änderung eingestellt werden?
        </Modal>

        {/*<Modal*/}
        {/*    title="Blisterauftrag wird exportiert"*/}
        {/*    visible={medikationsplanContext.exportPending}*/}
        {/*    footer={<div/>}*/}
        {/*    onCancel={() => medikationsplanContext.setExportPending(false)}*/}
        {/*>*/}
        {/*    <h4><LoadingOutlined/> Bitte warten</h4>*/}
        {/*</Modal>*/}


    </div>

}
