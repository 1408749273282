import {createContext, useRef, useState} from "react";

const initVal = {
    patientBlisterzeitraumMap: {},
    setPatientBlisterzeitraumMap: val => {},

    handleExportMedikationsplaene: [],
    setHandleExportMedikationsplaene: val => {},

    exportStatusMap: {},
    setExportStatusMap: val => {},

    mergetype: "ALL",
    setMergetype: val => {},



    exportListStarted: [],
    setExportListStarted: val => {},

    exportListFinished: [],
    setExportListFinished: val => {},

    exportListTotal: [],
    setExportListTotal: val => {},

    exportListResult: [],
    setExportListResult: val => {},

    exportCombining: false,
    setExportCombining: () => {},

    exportFinished: false,
    setExportFinished: () => {},

    exportStarted: false,
    setExportStarted: () => {},

    forceShowModal: false,
    setForceShowModal: () => {},

    assessmentDataToLoad: 0,
    setAssessmentDataToLoad: val => {},

    assessmentDataLoaded: 0,
    setAssessmentDataLoaded: val => {},

    assessmentDataToProcess: 0,
    setAssessmentDataToProcess: val => {},

    assessmentDataProcessed: 0,
    setAssessmentDataProcessed: val => {},

    entriesToCombine: 0,
    setEntriesToCombine: val => {},

    entriesCombined: 0,
    setEntriesCombined: val => {},
}

const JobExportContext = createContext(initVal)
export default JobExportContext;

export const useInitialState = () => {
    const [patientBlisterzeitraumMap, setPatientBlisterzeitraumMap] = useState({});
    const [exportStatusMap, setExportStatusMap] = useState({});
    const [handleExportMedikationsplaene, setHandleExportMedikationsplaene] = useState([]);

    const [exportListStarted, setExportListStarted] = useState([]);
    const [exportListFinished, setExportListFinished] = useState([]);
    const [exportListTotal, setExportListTotal] = useState([]);
    const [exportListResult, setExportListResult] = useState([]);
    const [mergetype, setMergetype] = useState("ALL");
    const [exportFinished, setExportFinished] = useState(false);
    const [exportCombining, setExportCombining] = useState(false);
    const [exportStarted, setExportStarted] = useState(false);
    const [forceShowModal, setForceShowModal] = useState(false);

    const [assessmentDataToLoad, setAssessmentDataToLoad] = useState(0);
    const [assessmentDataLoaded, setAssessmentDataLoaded] = useState(0);
    const [assessmentDataToProcess, setAssessmentDataToProcess] = useState(0);
    const [assessmentDataProcessed, setAssessmentDataProcessed] = useState(0);

    const [entriesToCombine, setEntriesToCombine] = useState(0);
    const [entriesCombined, setEntriesCombined] = useState(0);

    return {
        patientBlisterzeitraumMap,
        setPatientBlisterzeitraumMap,

        handleExportMedikationsplaene,
        setHandleExportMedikationsplaene,

        exportStatusMap,
        setExportStatusMap,

        mergetype,
        setMergetype,



        exportListStarted,
        setExportListStarted,

        exportListFinished,
        setExportListFinished,

        exportListTotal,
        setExportListTotal,

        exportListResult,
        setExportListResult,

        exportFinished,
        setExportFinished,

        exportCombining,
        setExportCombining,

        exportStarted,
        setExportStarted,

        forceShowModal,
        setForceShowModal,

        assessmentDataToLoad,
        setAssessmentDataToLoad,

        assessmentDataLoaded,
        setAssessmentDataLoaded,

        assessmentDataToProcess,
        setAssessmentDataToProcess,

        assessmentDataProcessed,
        setAssessmentDataProcessed,

        entriesToCombine,
        setEntriesToCombine,

        entriesCombined,
        setEntriesCombined
    }
}