import {createContext, useState} from "react";
import {getArzneimittelLangname} from "../components/atoms/Arzneimittel";
import {getUnitLangname} from "../config/entities";
import {useAuth} from "../utilities/useAuth";
import {useApi} from "../utilities/useApi";
import {callApiAsync} from "../utilities/apiUtil";
import {BASE_URL} from "../config/apiConfig";

const initVal = {
    mitteilung: {},
    anforderungen: [],
    visible: false,

    showModal: (mitteilung, anforderungen) => {},
    resetModal: () => {},

    onSave: (mitteilung) => {},
}

const MitteilungContext = createContext(initVal)
export default MitteilungContext;

export const useInitialState = () => {
    const [mitteilung, setMitteilung] = useState(null);
    const [visible, setVisible] = useState(false);
    const [anforderungen, setAnforderungen] = useState([]);

    function showModal(mitteilung, anforderungen) {
        setMitteilung(mitteilung);
        setAnforderungen([...anforderungen]);
        setVisible(true);
    }

    function resetModal() {
        setMitteilung(null);
        setAnforderungen([]);
        setVisible(false);
    }

    async function onSave(mitteilung) {
        setMitteilung(mitteilung);
        setVisible(false);
    }

    return {
        mitteilung,
        anforderungen,
        visible,

        showModal,
        resetModal,
        onSave,
    }
}