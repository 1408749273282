import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Col, List, Row, Table} from "antd";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {useApi} from "../../utilities/useApi";
import usePagination from "../../utilities/usePagination";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import InternalPackage from "../molecules/InternalPackage";
import MedicineEntitlement from "../molecules/MedicineEntitlement";
import {getSorter} from "../../utilities/sortUtil";
import moment from "moment";
import {useAuth} from "../../utilities/useAuth";
import {getMedicineM2Name, getTeilbarkeitString} from "../../utilities/medicineM2Util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "../atoms/Tooltip";
import {notification} from "antd";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {ConditionalPopover} from "../atoms/ConditionalPopover";
import {arzneimittelApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {getRounded} from "../../utilities/formatUtil";

const MedikationsplanBuendelTable = () => {
    const auth = useAuth();
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [buendelList, setBuendelList] = useState(null);
    const [planzeilenReihenfolgeMap, setPlanzeilenReihenfolgeMap] = useState({});
    const [planzeileDatumStringMap, setPlanzeileDatumStringMap] = useState({});
    const [planzeilenAktuell, setPlanzeilenAktuell] = useState([]);

    const [rerenderDummy, setRerenderDummy] = useState({});
    const resetLoading = useRef(new Map());
    const buendelArzneimittelRef = useRef({});

    const {getColumnSearchProps} = useTableSearchFilter()

    useEffect(() => {
        (async () => {
            if (medikationsplanContext.buendelList) {
                buendelArzneimittelRef.current = {};

                const stringMap = {};
                const reihenfolgeMap = [];
                const aktuell = [];

                const heute = moment().startOf('day');

                for (let buendel of medikationsplanContext.buendelList) {
                    const m2Ref = buendel.m2Ref;
                    const planzeilen = buendel.planzeilen.filter(p => !!p);

                    const map = {};
                    let m2RefSeen = false;
                    for (let planzeile of planzeilen) {
                        const dosierschema = planzeile.dosierschema;
                        if (dosierschema.length) {
                            dosierschema.sort(getSorter("dosierabschnitt"));
                            const start = dosierschema[0].start;
                            const ende = dosierschema[dosierschema.length - 1].ende;

                            const key = `${-ende || -Number.MAX_VALUE}_${start}_${getArzneimittelLangname(planzeile.arzneimittel)}`;
                            map[key] = planzeile.id;

                            let datumString = ende ? "" : "ab ";
                            datumString += moment(start).format("DD.MM.YYYY");
                            if (ende) datumString += " - " + moment(ende).format("DD.MM.YYYY");
                            stringMap[planzeile.id] = datumString;

                            if (!ende || !moment(ende).isBefore(heute)) aktuell.push(planzeile.id)
                        } else {
                            stringMap[planzeile.id] = "Kein Dosierabschnitt";
                            map["zz_"+getArzneimittelLangname(planzeile.arzneimittel)] = planzeile.id;
                        }

                        if (planzeile.arzneimittel?.m2 === m2Ref) m2RefSeen = true;
                    }

                    if (!m2RefSeen) {
                        const response = await callApiAsync({url: arzneimittelApi.getByM2(buendel.m2Ref), auth});
                        const arzneimittel = response.data.OBJECT;
                        const index = -Object.keys(buendelArzneimittelRef.current).length -1;

                        map["zzz_"+getArzneimittelLangname(arzneimittel)] = index;
                        buendelArzneimittelRef.current[index] = arzneimittel;
                    }

                    const keys = Object.keys(map);
                    keys.sort();

                    reihenfolgeMap[buendel.id] = keys.map(key => map[key]);
                }

                setPlanzeileDatumStringMap(stringMap);
                setPlanzeilenReihenfolgeMap(reihenfolgeMap);
                setPlanzeilenAktuell(aktuell);

                const bList = medikationsplanContext.buendelList.filter(b => !!b.planzeilen.length || !!b.entitlementSet.filter(e => !!e.amount).length || !!b.internalPackageSet.length);
                bList.sort((a,b) => getSorter("planzeile", "arzneimittel")(medikationsplanContext.planzeilenMap[reihenfolgeMap[a.id]?.[0]], medikationsplanContext.planzeilenMap[reihenfolgeMap[b.id]?.[0]]));
                setBuendelList(bList);
            }
        })();
    }, [medikationsplanContext.buendelList])

    useEffect(() => {}, [rerenderDummy])

    const columns = !auth.developer ? [] : [
        {
            title: "ID",
            key: "id",
            render: (buendel) => buendel.id
        }
    ];

    async function resetPlanzeile(planzeileId) {
        const planzeile = medikationsplanContext.planzeilenMap[planzeileId];
        if (!resetLoading.current.has('planzeile')) resetLoading.current.set('planzeile', new Set());
        resetLoading.current.get('planzeile').add(planzeileId);

        const planzeileNeu = await api.resetPlanzeile(planzeileId);
        resetLoading.current.get('planzeile').delete(planzeileId);

        if (planzeile.buendelId !== planzeileNeu.buendelId) {
            const promiseList = [];
            if (planzeile.buendelId) promiseList.push(api.reloadBuendel(planzeile.buendelId));
            if (planzeileNeu.buendelId) promiseList.push(api.reloadBuendel(planzeileNeu.buendelId));
            Promise.all(promiseList).then(async () => {
                const buendelList = await api.reloadBuendelListByMedikationsplan(planzeileNeu.medikationsplan.id);
                medikationsplanContext.setBuendelList(buendelList);

                notification.info({message: 'Die Zuordnung von Bündeln wurde aktualisiert'});
            });
        } else {
            notification.info({message: 'Es gab keine Änderung bei der Zuordnung von Bündeln'});
            setRerenderDummy({});
        }
    }

    async function resetAnspruch(entitlement, medikationsplanId) {
        const entitlementKey = `${entitlement.id.person.id}:${entitlement.id.medicineM2.m2}`;
        if (!resetLoading.current.has('anspruch')) resetLoading.current.set('anspruch', new Set());
        resetLoading.current.get('anspruch').add(entitlementKey);

        const entitlementNeu = await api.resetAnspruch(entitlement);
        resetLoading.current.get('anspruch').delete(entitlementKey);

        if (entitlement.idBundle !== entitlementNeu.idBundle) {
            const promiseList = [];
            promiseList.push(api.reloadBuendel(entitlement.idBundle));
            promiseList.push(api.reloadBuendel(entitlementNeu.idBundle));
            Promise.all(promiseList).then(async () => {
                const buendelList = await api.reloadBuendelListByMedikationsplan(medikationsplanId);
                medikationsplanContext.setBuendelList(buendelList);

                notification.info({message: 'Die Zuordnung von Bündeln wurde aktualisiert'});
            });
        } else {
            notification.info({message: 'Es gab keine Änderung bei der Zuordnung von Bündeln'});
            setRerenderDummy({});
        }
    }

    async function resetBestand(internalPackage, medikationsplanId) {
        if (!resetLoading.current.has('bestand')) resetLoading.current.set('bestand', new Set());
        resetLoading.current.get('bestand').add(internalPackage.id);

        const internalPackageNeu = await api.resetBestand(internalPackage);
        resetLoading.current.get('bestand').delete(internalPackage.id);

        if (internalPackage.idBundle !== internalPackageNeu.idBundle) {
            const promiseList = [];
            promiseList.push(api.reloadBuendel(internalPackage.idBundle));
            promiseList.push(api.reloadBuendel(internalPackageNeu.idBundle));
            Promise.all(promiseList).then(async () => {
                const buendelList = await api.reloadBuendelListByMedikationsplan(medikationsplanId);
                medikationsplanContext.setBuendelList(buendelList);

                notification.info({message: 'Die Zuordnung von Bündeln wurde aktualisiert'});
            });
        } else {
            notification.info({message: 'Es gab keine Änderung bei der Zuordnung von Bündeln'});
            setRerenderDummy({});
        }
    }

    columns.push(...[
        {
            title: "Planzeilen",
            key: "planzeilen",
            render: (buendel) => {
                const planzeileIdList = planzeilenReihenfolgeMap[buendel.id];

                return planzeileIdList?.length ? <List
                    dataSource={planzeileIdList}
                    renderItem={ (planzeileId) => {
                        const pz = medikationsplanContext.planzeilenMap[planzeileId];

                        const style = {};
                        if (planzeileId < 0 || pz?.arzneimittel?.m2 === buendel.m2Ref) style.fontWeight = "bold";
                        if (planzeilenAktuell?.includes(planzeileId)) style.textDecoration = "underline";

                        const teilbarkeitString = getTeilbarkeitString(api.getMedicineM2Sync(pz?.arzneimittel?.m2, true));

                        return <div style={style} className={'buendel-table-row'}>
                            <ConditionalPopover trigger={"hover"} placement={"right"} content={planzeileId > 0 && auth.developer && !!pz && <div className={"grid-auto"}>
                                <span>PZN:</span>
                                <span>{pz?.arzneimittel.pzn}</span>

                                <span>M2:</span>
                                <span>{pz?.arzneimittel.m2}</span>

                                <span>DRF:</span>
                                <span>{pz?.arzneimittel.drf}</span>

                                <span>Hersteller:</span>
                                <span>{pz?.arzneimittel.herstellerKurz || pz?.arzneimittel.hersteller}</span>

                                <span>Teilbarkeit:</span>
                                <span>{teilbarkeitString}</span>

                                {pz?.arzneimittel?.m2 !== buendel.m2Ref && <>
                                    <span>Faktor zu Referenz-M2 ({buendel.m2Ref})</span>
                                    <span>{pz?.buendelFaktor ? mengeToString(1 / pz.buendelFaktor, {putSpace: false}) : 0}</span>
                                </>}
                            </div>}>
                                {planzeileId < 0 ?
                                    <span className={'buendel-table-row-span'}>{getArzneimittelLangname(buendelArzneimittelRef.current[planzeileId])} (Bündel-Referenz ohne Planzeile)</span> :
                                    <span className={'buendel-table-row-span'}>{getArzneimittelLangname(pz?.arzneimittel)}{pz?.autIdem ? " [aut idem]" : ""} ({planzeileDatumStringMap[planzeileId]}; {teilbarkeitString})</span>
                                }
                            </ConditionalPopover>
                            {planzeileId > 0 && <span className={'buendel-table-row-icons'}>
                                <Tooltip title={'Zuordnung dieser Planzeile zu Bündel aktualisieren'}>
                                    <FontAwesomeIcon icon={faArrowsRotate} className={'link icon-onhoverrow'} spin={resetLoading.current.get('planzeile')?.has(planzeileId)} onClick={() => resetPlanzeile(planzeileId)} />
                                </Tooltip>
                            </span>}
                        </div>
                    }}
                /> : "Keine Planzeilen im Bündel"
            },

            ...getColumnSearchProps({onFilter: (value,  buendel) => {
                    const text = buendel.planzeilen.map(planzeile => getArzneimittelLangname(medikationsplanContext.planzeilenMap[planzeile.id].arzneimittel).toLowerCase()).join(" ");
                    const terms = value.toLowerCase().split(" ");
                    return terms.every(term => {
                        const isIncluded = text.includes(term);
                        return isIncluded;
                    });
                }})
        },
        {
            title: "Anspruch",
            key: "anspruchList",
            render: (text, buendel) => {
                const entitlementList = buendel.entitlementSet.filter(e => !!e.amount);
                entitlementList.sort(getSorter("medicineEntitlement"));

                return entitlementList.length ? entitlementList.map(entitlement => <div className={'buendel-table-row'} key={entitlement.id.medicineM2.m2}>
                        <span className={'buendel-table-row-span'}><MedicineEntitlement value={entitlement} /></span>
                        <span className={'buendel-table-row-icons'}>
                            <Tooltip title={'Zuordnung dieses Anspruchs zu Bündel aktualisieren'}>
                                <FontAwesomeIcon icon={faArrowsRotate} className={'link icon-onhoverrow'} spin={resetLoading.current.get('anspruch')?.has(`${entitlement.id.person.id}:${entitlement.id.medicineM2.m2}`)} onClick={() => resetAnspruch(entitlement, buendel.idMedikationsplan)} />
                            </Tooltip>
                        </span>
                    </div>) : "Keine Ansprüche im Bündel"
            },
            //sorter: (a, b) => a.bestandList[0].packungId.localeCompare(b.bestandList[0].packungId)
        },
        {
            title: "Bestand",
            key: "bestandList",
            render: (text, buendel) => {
                const internalPackageList = buendel.internalPackageSet.filter(p => !!p && p.amountRemaining).map(p => medikationsplanContext.internalPackageMap?.[p.id] || p);
                internalPackageList.sort(getSorter("internalPackage"));

                return internalPackageList.length ? internalPackageList.filter(p => !!p.amountRemaining).map(internalPackage => <div className={'buendel-table-row'} key={internalPackage.id}>
                    <span className={'buendel-table-row-span'}><InternalPackage id={internalPackage.id} /></span>
                    <span className={'buendel-table-row-icons'}>
                        <Tooltip title={'Zuordnung dieses Bestands zu Bündel aktualisieren'}>
                            <FontAwesomeIcon icon={faArrowsRotate} className={'link icon-onhoverrow'} spin={resetLoading.current.get('bestand')?.has(internalPackage.id)} onClick={() => resetBestand(internalPackage, buendel.idMedikationsplan)} />
                        </Tooltip>
                    </span>
                </div>) : "Keine Bestände im Bündel"
            },
            //sorter: (a, b) => a.bestandList[0].packungId.localeCompare(b.bestandList[0].packungId)
        }
    ]);

    return <>
        <Table
            pagination={usePagination({pageSize: 100})}
            dataSource={buendelList}
            rowKey = "id"

            columns={columns}
        />
    </>
}

export default MedikationsplanBuendelTable;