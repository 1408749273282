import {createContext, useEffect, useRef, useState} from "react";
import { v1 as uuid } from 'uuid';
import { BuildBridgedWorker } from "../utilities/bridgedWorker";

const initVal = {
    workerCount: 1,
    setWokerCount: 1,

    scheduleWorker: ({ run, callback }) => Promise,
}

const WebWorkerContext = createContext(initVal);
export default WebWorkerContext;

export const useInitialState = () => {
    const [workerCount, setWorkerCount] = useState(1);

    const workerRef = useRef({});
    const workQueue = useRef([]);

    useEffect(() => {
        return () => {
            for (let worker of Object.values(workerRef.current)) {
                worker.terminate();
            }
        }
    }, [])

    const scheduleWorker = ({ run, callback }) => new Promise(resolve => {
        {
            if (Object.keys(workerRef.current).length < workerCount) {
                const workerId = uuid();
                const functionString = run.toString();

                if (!functionString.match(/\s*self\.post[mM]essage[(\s]/)) return null;

                const str = functionString.match(/^\s*(?:function)?\s*\(\s*\)\s*(?:=>)?\s*\{?(([\s\S](?!\}$))*[\s\S])/)[1];
                const worker = new Worker(window.URL.createObjectURL(new Blob([str], {type: 'text/javascript'})));

                worker.onmessage = (message) => {
                    worker.terminate();
                    delete workerRef.current[workerId];

                    callback(message);

                    if (workQueue.current.length && Object.keys(workerRef.current).length < workerCount) {
                        const work = workQueue.current.shift();
                        scheduleWorker({ run: work.run, callback: work.callback }).then(work.resolve);
                    }
                }

                workerRef.current[workerId] = worker;
                resolve(worker);
            } else {
                workQueue.current.push({ run, callback, resolve });
            }
        }
    });

    const scheduleBridgedWorker = () => {

    }

    return {
        workerCount,
        setWorkerCount,

        scheduleWorker,
    }
}