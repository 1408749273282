import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Collapse, Modal, notification, Tree} from "antd";
import {getSorter} from "../../utilities/sortUtil";
import uuid from "uuid/v4";
import {callApiAsync, CancelablePromise} from "../../utilities/apiUtil";
import {fetchAbgabeplan, fetchBlisterJobs} from "../../config/fetchApiConfiguration";
import moment from "moment";
import {getCurrentDosierabschnitt, mengeToString} from "../../utilities/dosierschemaUtil";
import {BASE_URL_NONMP, medikationsplanApi, planzeilenApi, verblisterungApi} from "../../config/apiConfig";
import {checkTeilbarkeit, getMedicineM2Name, getTeilbarkeitString} from "../../utilities/medicineM2Util";
import {getUnitLangname} from "../../config/entities";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {postSimuliereAbgabeDatum} from "../../config/postApiConfiguration";
import JobExportContext from "../../contexts/JobExportContext";
import {useAuth} from "../../utilities/useAuth";
import {useApi} from "../../utilities/useApi";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {Accordion} from "react-bootstrap";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {Loading} from "../templates/defaultPages";

const MAX_CONCURRENT_EXPORTS = 3;

export const JobExportModal = ({onCancel=()=>{}}) => {
    const auth = useAuth();
    const jobExportContext = useContext(JobExportContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const api = useApi();

    const [modalData, setModalData] = useState({});
    const [modalTreeNodeChecked, setModalTreeNodeChecked] = useState([]);
    const [modalTreeNodeExpanded, setModalTreeNodeExpanded] = useState([]);

    const [modalExportButtonDisabled, setModalExportButtonDisabled] = useState(false);

    const exportProcessRunning = useRef(false);
    const exportQueue = useRef([]);
    const startedRef = useRef(0);
    const finishedRef = useRef(0);
    const stationMapRef = useRef({});

    useEffect(() => {
        handleExport(jobExportContext.handleExportMedikationsplaene, {}, jobExportContext.mergetype)
    }, [jobExportContext.handleExportMedikationsplaene])

    const printTreeData = (treeData) => {
        const getString = (entry, tiefe) => {
            let titleString;
            if (React.isValidElement(entry.title)) {
                titleString = entry.title.props?.children?.join("");
            } else {
                titleString = entry.title;
            }

            let resultString = `<tr><td style="padding-left: ${20*tiefe}px">${titleString}</td></tr>`;
            for (let sub of entry.children)
                resultString += getString(sub, tiefe+1);

            return resultString;
        }

        const rowList = treeData.map(entry => getString(entry, 0));

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Export-Auswertung</h3><table>${rowList.join("")}</table></body>`);
        win.document.close();
    }

    const exportJob = (medikationsplan, uuid, originalToSubstituteMapMap={}, skipBroadcast=true) => {
        return new CancelablePromise(
            async (onSuccess, onError) => {
                const zeitraum = jobExportContext.patientBlisterzeitraumMap[medikationsplan.id];
                const startMoment = moment(zeitraum.start);
                const endeMoment = moment(zeitraum.ende);

                let url = verblisterungApi.createBlister(medikationsplan.id, startMoment.format('YYYY-MM-DD'), endeMoment.format('YYYY-MM-DD'), medikationsplan.neuimport, uuid, skipBroadcast);
                jobExportContext.setExportStatusMap(prev => {
                    const neu = {...prev};
                    neu[medikationsplan.id] = {loading: true};

                    return neu;
                })

                const setStatus = status => {
                    jobExportContext.setExportStatusMap(prev => {
                        const neu = {...prev};
                        neu[medikationsplan.id] = status;
                        return neu;
                    });
                }

                const response = await callApiAsync({auth, url, method: 'POST', data: {originalToSubstituteMapMap}, onError: async err => {
                        setStatus({error: err});
                        onError(err);
                    }});

                setStatus({ready: true});
                onSuccess(response);
            }
        );
    }

    const handleExport = async (medikationsplaene, auswertungBestaetigtMap={}, mergetype = "ALL") => {
        if (exportProcessRunning.current) return;

        jobExportContext.setExportStarted(medikationsplaene.length);
        setModalExportButtonDisabled(false);

        exportQueue.current = [];
        medikationsplaene = medikationsplaene.filter(m => !!m).sort(getSorter("medikationsplan", "patient"));
        if (medikationsplaene.length) {
            exportProcessRunning.current = true;
            const uuidString = uuid();

            // Abgaben aller Blisteraufträge auswerten
            const internalPackageMap = {};
            const patientIdToM2StringToPlanzeileSetMapMap = {};
            const patientIdToOriginalToSubstituteMapMapMap = {};
            const patientToM2StringToAbgabeListMapMap = {};

            const abgabeTreeData = [{
                title: "Alle Abgaben",
                key: "root_"+uuid(),
                children: [{
                    title: <Loading size={"sm"} />,
                    key: "dummy",
                }]
            }];

            jobExportContext.setAssessmentDataToLoad(medikationsplaene.length);
            jobExportContext.setAssessmentDataLoaded(0);
            jobExportContext.setAssessmentDataProcessed(0);

            const assessmentData = await new Promise(async (onSuccess) => {
                const assessmentMap = {};
                const processAssessment = async (medikationsplan) => {
                    // Abgaben ermitteln
                    const {start, ende} = jobExportContext.patientBlisterzeitraumMap[medikationsplan.id];
                    const url = medikationsplanApi.getAbgaben(medikationsplan.id, start, ende);
                    const abgaben = (await callApiAsync({auth, url, method: "post", data: []})).data.OBJECT;
                    if (Object.keys(abgaben).length) {
                        exportQueue.current.push(medikationsplan);
                    } else {
                        jobExportContext.setExportStatusMap(prev => {
                            const neu = {...prev};
                            neu[medikationsplan.id] = {message: "der Abgabeplan ist leer"};
                            return neu;
                        });
                        return;
                    }

                    const planzeileIdToEntityMap = {};
                    for (let planzeile of medikationsplan.planzeilen) {
                        planzeileIdToEntityMap[planzeile.id] = planzeile;
                    }

                    const m2OriginalToTeilmengeToM2SetMapMap = new Map();
                    const planzeileSet = new Set();
                    const planzeileIdToEinnahmezeitToCounterMapMap = new Map();
                    for (let planzeileId in abgaben) {
                        const abgabezeitenMap = abgaben[planzeileId];
                        planzeileSet.add(planzeileId);

                        for (let abgabezeit in abgabezeitenMap) {
                            const abgabenMap = abgabezeitenMap[abgabezeit];

                            for (let key in abgabenMap) {
                                const pair = abgabenMap[key];
                                const abgabemenge = pair.key;
                                const abgabefaktor = pair.value;

                                const m2Original = planzeileIdToEntityMap[planzeileId].arzneimittel.m2;
                                const patient = medikationsplan.patient;
                                const patientId = patient.id;

                                let m2Abgabe = m2Original;
                                let internalPackage = null;
                                if (abgabefaktor != 0) {
                                    internalPackage = internalPackageMap[key] || medikationsplanContext.internalPackageMap[key];
                                    if (!internalPackage) {
                                        internalPackage = await api.loadInternalPackage(key);
                                        internalPackageMap[key] = internalPackage;
                                    }

                                    m2Abgabe = internalPackage.batch.id.medicinePZN.medicineM2.m2;
                                    const abgabeMenge = Math.abs(abgabemenge);
                                    const teilmenge = abgabeMenge - Math.floor(abgabeMenge);

                                    if (!m2OriginalToTeilmengeToM2SetMapMap.has(m2Original)) m2OriginalToTeilmengeToM2SetMapMap.set(m2Original, new Map());
                                    if (!m2OriginalToTeilmengeToM2SetMapMap.get(m2Original).has(teilmenge)) m2OriginalToTeilmengeToM2SetMapMap.get(m2Original).set(teilmenge, new Set());
                                    m2OriginalToTeilmengeToM2SetMapMap.get(m2Original).get(teilmenge).add(m2Abgabe);
                                }

                                if (!planzeileIdToEinnahmezeitToCounterMapMap.has(planzeileId)) planzeileIdToEinnahmezeitToCounterMapMap.set(planzeileId, new Map());
                                if (!planzeileIdToEinnahmezeitToCounterMapMap.get(planzeileId).has(abgabezeit)) planzeileIdToEinnahmezeitToCounterMapMap.get(planzeileId).set(abgabezeit, 0);
                                planzeileIdToEinnahmezeitToCounterMapMap.get(planzeileId).set(abgabezeit, planzeileIdToEinnahmezeitToCounterMapMap.get(planzeileId).get(abgabezeit) + 1);

                                if (!(patientId in patientIdToM2StringToPlanzeileSetMapMap)) patientIdToM2StringToPlanzeileSetMapMap[patientId] = {};
                                if (!patientIdToM2StringToPlanzeileSetMapMap[patientId][m2Original]) patientIdToM2StringToPlanzeileSetMapMap[patientId][m2Original] = new Set();
                                patientIdToM2StringToPlanzeileSetMapMap[patientId][m2Original].add(planzeileId);

                                if (!patientToM2StringToAbgabeListMapMap[patientId]) patientToM2StringToAbgabeListMapMap[patientId] = {};
                                const m2StringToAbgabeListMap = patientToM2StringToAbgabeListMapMap[patientId];
                                if (!m2StringToAbgabeListMap[m2Abgabe]) m2StringToAbgabeListMap[m2Abgabe] = [];
                                m2StringToAbgabeListMap[m2Abgabe].push({abgabezeit, abgabemenge, abgabefaktor, internalPackage, medikationsplan});
                            }
                        }
                    }

                    const data = new Map();

                    if (m2OriginalToTeilmengeToM2SetMapMap.size > 0) {
                        data.set("m2OriginalToTeilmengeToM2SetMapMap", m2OriginalToTeilmengeToM2SetMapMap);
                    }

                    if (data.size > 0) {
                        assessmentMap[medikationsplan.id] = {...Object.fromEntries(data), medikationsplan};
                    }
                };

                // Starte die Auswertung für alle Blisteraufträge
                const medikationsplaneToProcess = [...medikationsplaene];
                const processMedikationsplanNext = async () => {
                    if (medikationsplaneToProcess.length) {
                        await processAssessment(medikationsplaneToProcess.pop());
                        jobExportContext.setAssessmentDataLoaded(prev => prev + 1);

                        await processMedikationsplanNext();
                    }
                }

                await Promise.all([
                    processMedikationsplanNext(),
                    processMedikationsplanNext(),
                    processMedikationsplanNext(),
                ])

                // Ergebnis anzeigen, wenn alle Auswertungen abgeschlossen sind
                const m2StringToEntityMap = {};
                const patientIdToEntityMap = {};
                const patientIdToPlanzeileFehltMap = {};
                const patientIdToPlanzeileDuplicateZeitenMap = {};

                jobExportContext.setAssessmentDataToProcess(Object.keys(assessmentMap).length);
                for (let medikationsplanId in assessmentMap) {
                    const assessment = assessmentMap[medikationsplanId];
                    const m2OriginalToTeilmengeToM2SetMapMap = assessment["m2OriginalToTeilmengeToM2SetMapMap"] || [];
                    const medikationsplan = assessment["medikationsplan"];
                    const patientId = medikationsplan.patient.id;

                    for (let [m2Original, teilmengeToM2SetMap] of m2OriginalToTeilmengeToM2SetMapMap) {
                        if (!m2StringToEntityMap[m2Original]) m2StringToEntityMap[m2Original] = await api.getMedicineM2(m2Original, true);

                        for (let [faktor, m2StringSet] of teilmengeToM2SetMap) {
                            for (let m2String of m2StringSet) {
                                if (faktor in (auswertungBestaetigtMap[patientId]?.[m2Original]?.[m2String]?.erlaubt || {}))
                                    continue;

                                if (!patientIdToEntityMap[patientId]) patientIdToEntityMap[patientId] = medikationsplan.patient;

                                if (!m2StringToEntityMap[m2String]) m2StringToEntityMap[m2String] = await api.getMedicineM2(m2String);

                                const istTeilbar = faktor ? checkTeilbarkeit(m2StringToEntityMap[m2String], faktor) : true;

                                if (!(patientId in patientIdToOriginalToSubstituteMapMapMap)) patientIdToOriginalToSubstituteMapMapMap[patientId] = {};
                                if (!patientIdToOriginalToSubstituteMapMapMap[patientId][m2Original]) patientIdToOriginalToSubstituteMapMapMap[patientId][m2Original] = {};
                                if (!patientIdToOriginalToSubstituteMapMapMap[patientId][m2Original][m2String]) patientIdToOriginalToSubstituteMapMapMap[patientId][m2Original][m2String] = {};
                                patientIdToOriginalToSubstituteMapMapMap[patientId][m2Original][m2String][faktor] = {
                                    text: getMedicineM2Name(m2StringToEntityMap[m2String]),
                                    textExtra: faktor ? `Teilung: ${mengeToString(faktor)}, ist ${istTeilbar ? "" : "nicht "}möglich (${getTeilbarkeitString(m2StringToEntityMap[m2String])})` : null,
                                    m2: m2String,
                                    istTeilbar: istTeilbar
                                };
                            }
                        }
                    }

                    if (assessment["planzeileFehlt"]) {
                        if (!patientIdToEntityMap[patientId]) patientIdToEntityMap[patientId] = medikationsplan.patient;
                        patientIdToPlanzeileFehltMap[patientId] = assessment["planzeileFehlt"];
                    }

                    if (assessment["planzeileIdDuplicateZeiten"]) {
                        if (!patientIdToEntityMap[patientId]) patientIdToEntityMap[patientId] = medikationsplan.patient;
                        patientIdToPlanzeileDuplicateZeitenMap[patientId] = assessment["planzeileIdDuplicateZeiten"];
                    }

                    jobExportContext.setAssessmentDataProcessed(prev => prev + 1);
                }

                const assessmentData = [];
                const defaultChecked = [];
                const defaultExpanded = [];
                const patientIdToNodeMap = {};


                const patientIdSet = new Set();
                Object.keys(patientIdToOriginalToSubstituteMapMapMap).forEach(patientId => patientIdSet.add(patientId));
                Object.keys(patientIdToPlanzeileFehltMap).forEach(patientId => patientIdSet.add(patientId));
                Object.keys(patientIdToPlanzeileDuplicateZeitenMap).forEach(patientId => patientIdSet.add(patientId));

                const patientIdList = Array.from(patientIdSet).sort((a, b) => getSorter("unit")(patientIdToEntityMap[a], patientIdToEntityMap[b]));

                for (let patientId of patientIdList) {
                    if (patientIdToOriginalToSubstituteMapMapMap[patientId]) {
                        const originalToSubstituteMapMap = patientIdToOriginalToSubstituteMapMapMap[patientId];
                        if (!patientIdToNodeMap[patientId]) {
                            patientIdToNodeMap[patientId] = {
                                title: getUnitLangname(patientIdToEntityMap[patientId]),
                                key: patientId,
                                children: [],
                                checkable: false
                            }

                            assessmentData.push(patientIdToNodeMap[patientId]);
                            defaultExpanded.push(patientId);
                        }
                        const patientData = patientIdToNodeMap[patientId];

                        for (let m2Original in originalToSubstituteMapMap) {
                            const keyPlanzeile = `${patientId}::${m2Original}`;
                            defaultExpanded.push(keyPlanzeile);

                            const planzeileSet = patientIdToM2StringToPlanzeileSetMapMap[patientId][m2Original];
                            let dosierungString = "-";
                            if (planzeileSet?.size) {
                                const response = await callApiAsync({auth, url: planzeilenApi.getDosierabschnittString(planzeileSet.values().next().value)});
                                dosierungString = response.data.MESSAGE;
                            }

                            const planzeileData = {
                                title: `${getMedicineM2Name(m2StringToEntityMap[m2Original])}, Dosierung: ${dosierungString}`,
                                key: keyPlanzeile,
                                children: [],
                                checkable: false
                            }

                            const substituteMap = originalToSubstituteMapMap[m2Original];
                            for (let m2Substitut in substituteMap) {
                                const substitute = substituteMap[m2Substitut];

                                for (let faktor in substitute) {
                                    const entry = substitute[faktor];

                                    const keySubstitute = `${patientId}::${m2Original}::${m2Substitut}::${faktor}`;
                                    if (entry.istTeilbar) defaultChecked.push(keySubstitute);
                                    defaultExpanded.push(keySubstitute);

                                    planzeileData.children.push({
                                        title: `Substitut: ${entry.text}${entry.textExtra ? ": "+entry.textExtra : ""}`,
                                        key: keySubstitute,
                                        children: [],
                                        checkable: entry.istTeilbar,
                                        faktor
                                    });
                                }
                            }

                            patientData.children.push(planzeileData);
                        }
                    }

                    if (patientIdToPlanzeileFehltMap[patientId]) {
                        for (let planzeile of patientIdToPlanzeileFehltMap[patientId]) {
                            if (auswertungBestaetigtMap[patientId]?.[planzeile.arzneimittel.m2]?.["__seen__"])
                                continue;

                            if (!patientIdToNodeMap[patientId]) {
                                patientIdToNodeMap[patientId] = {
                                    title: getUnitLangname(patientIdToEntityMap[patientId]),
                                    key: patientId,
                                    children: [],
                                    checkable: false
                                }

                                assessmentData.push(patientIdToNodeMap[patientId]);
                                defaultExpanded.push(patientId);
                            }
                            const patientData = patientIdToNodeMap[patientId];

                            const key = `${patientId}::${planzeile.arzneimittel.m2}::planzeileFehlt`;
                            patientData.children.push({
                                title: "blisterbare Planzeile fehlt in Export: " + getArzneimittelLangname(planzeile.arzneimittel),
                                key,
                                children: [],
                                checkable: false
                            });

                            defaultExpanded.push(key);
                        }
                    }

                    if (patientIdToPlanzeileDuplicateZeitenMap[patientId]) {
                        setModalExportButtonDisabled(true);
                        for (let planzeile of patientIdToPlanzeileDuplicateZeitenMap[patientId]) {
                            if (auswertungBestaetigtMap[patientId]?.[planzeile.arzneimittel.m2]?.["__seen__"])
                                continue;

                            if (!patientIdToNodeMap[patientId]) {
                                patientIdToNodeMap[patientId] = {
                                    title: getUnitLangname(patientIdToEntityMap[patientId]),
                                    key: patientId,
                                    children: [],
                                    checkable: false
                                }

                                assessmentData.push(patientIdToNodeMap[patientId]);
                                defaultExpanded.push(patientId);
                            }
                            const patientData = patientIdToNodeMap[patientId];

                            const key = `${patientId}::${planzeile.arzneimittel.m2}::einnahmeMehrfach`;
                            patientData.children.push({
                                title: <span style={{color: "red"}}>FEHLER! - Planzeile enthält mehrere Einträge zu einzelnen Einnahmezeiten: {getArzneimittelLangname(planzeile.arzneimittel)} - Reichweite muss neu berechnet werden</span>,
                                key,
                                children: [],
                                checkable: false
                            });

                            defaultExpanded.push(key);
                        }
                    }
                }

                setModalTreeNodeChecked(defaultChecked);
                setModalTreeNodeExpanded(defaultExpanded);
                onSuccess(assessmentData);
            });

            // Muss das Modal angezeigt werden?
            if (assessmentData.length || jobExportContext.forceShowModal) {
                setModalData({treeData: assessmentData, medikationsplaene, mergetype, abgabeMap: patientToM2StringToAbgabeListMapMap, abgabeTreeData});
            } else if (exportQueue.current.length) {
                jobExportContext.setExportListTotal(exportQueue.current.map(m => m.id));

                const jobMap = {hand: [], maschine: []};
                await new Promise(async (onSuccess) => {
                    const processExport = async (medikationsplan) => {
                        // starte den Export
                        startedRef.current++;
                        jobExportContext.setExportListStarted(prev => [...prev, medikationsplan.id]);

                        try {
                            const response = await exportJob(medikationsplan, uuidString, auswertungBestaetigtMap[medikationsplan.patient.id]);
                            const jobList = response.data.OBJECT.map(job => ({ ...job, medikationsplan }));

                            const blisterType = medikationsplan.maschinenverblisterung ? "maschine" : (response.data.OBJECT?.[0]?.blisterType?.name || "hand");


                            if (!jobMap[blisterType]) jobMap[blisterType] = [];
                            jobMap[blisterType].push(...jobList);
                        } finally {
                            finishedRef.current++;
                            jobExportContext.setExportListFinished(prev => [...prev, medikationsplan.id]);

                            // starte den nächsten Export, wenn die Queue noch nicht leer ist
                            if (exportQueue.current.length) {
                                processExport(exportQueue.current.shift());
                            } else if (startedRef.current === finishedRef.current) {
                                onSuccess();
                            }
                        }
                    };

                    // Starte den Export für die ersten [MAX_CONCURRENT_EXPORTS] Pläne in der Queue
                    for (let i = 0; i < MAX_CONCURRENT_EXPORTS; i++) {
                        if (exportQueue.current.length) {
                            processExport(exportQueue.current.shift());
                        }
                    }
                });

                // Blisteraufträge kombinieren
                const combineJobList = async (entry, performPost=true) => {
                    const [importType, jobList] = entry;
                    if (jobList?.length) {
                        const finished = finishedRef.current;
                        const startToEndeToEntryIdSetMap = {};

                        for (let job of jobList) {
                            const medikationsplan = job.medikationsplan;
                            const zeitraum = jobExportContext.patientBlisterzeitraumMap[medikationsplan.id];
                            const jobStart = moment(zeitraum.start).format('YYYY-MM-DD');
                            const jobEnd = moment(zeitraum.ende).format('YYYY-MM-DD');

                            // const jobStart = moment(job.start).format('YYYY-MM-DD');
                            // const jobEnd = moment(job.end).format('YYYY-MM-DD');

                            if (!startToEndeToEntryIdSetMap[jobStart]) startToEndeToEntryIdSetMap[jobStart] = {};
                            if (!startToEndeToEntryIdSetMap[jobStart][jobEnd]) startToEndeToEntryIdSetMap[jobStart][jobEnd] = {entrySet: new Set(), stationMap: {}};
                            const jobObj = startToEndeToEntryIdSetMap[jobStart][jobEnd];

                            if (!jobObj.jobId) jobObj.jobId = job.id;
                            for (let entry of job.entries) {
                                jobObj.entrySet.add(entry.id);

                                if (!jobObj.importTypeToStationMapMap) jobObj.importTypeToStationMapMap = {};
                                if (!jobObj.importTypeToStationMapMap[importType]) jobObj.importTypeToStationMapMap[importType] = {};
                                if (!jobObj.importTypeToStationMapMap[importType][entry.station]) jobObj.importTypeToStationMapMap[importType][entry.station] = {jobId: job.id, entrySet: new Set()};
                                jobObj.importTypeToStationMapMap[importType][entry.station].entrySet.add(entry.id);
                            }
                        }

                        let data;
                        const resultList = [];
                        switch (mergetype) {
                            case "DATE_RANGE":
                                Object.keys(startToEndeToEntryIdSetMap).forEach(start => {
                                    const startMap = startToEndeToEntryIdSetMap[start];
                                    const keys = Object.keys(startMap);
                                    jobExportContext.setEntriesToCombine(prev => prev + keys.length);

                                    keys.forEach(async end => {
                                        const jobObj = startMap[end];
                                        const data = {
                                            type: "MOVE_ENTRY_SET_TO_JOB",
                                            blisterJobEntryIDSet: Array.from(jobObj.entrySet),
                                            blisterJobID: jobObj.jobId
                                        };
                                        resultList.push(await callApiAsync({
                                            auth,
                                            url: BASE_URL_NONMP + `job`,
                                            method: "post",
                                            data
                                        }));
                                        jobExportContext.setEntriesCombined(prev => prev+1);
                                    });
                                });
                                break;
                            case "ALL":
                                let jobId;
                                const entryIdSet = [];

                                for (let start of Object.keys(startToEndeToEntryIdSetMap)) {
                                    const startMap = startToEndeToEntryIdSetMap[start];
                                    const keys = Object.keys(startMap);
                                    jobExportContext.setEntriesToCombine(prev => prev + keys.length);

                                    for (let end of keys) {
                                        const jobObj = startMap[end];

                                        if (!jobId) jobId = jobObj.jobId;
                                        entryIdSet.push(...Array.from(jobObj.entrySet));
                                    }
                                }

                                data = {
                                    type: "MOVE_ENTRY_SET_TO_JOB",
                                    blisterJobEntryIDSet: entryIdSet,
                                    blisterJobID: jobId
                                };
                                resultList.push(await callApiAsync({
                                    auth,
                                    url: BASE_URL_NONMP + `job`,
                                    method: "post",
                                    data
                                }));
                                jobExportContext.setEntriesCombined(prev => prev+1);
                                break;
                            case "STATION":
                                const stationMap = stationMapRef.current || {};

                                for (let start of Object.keys(startToEndeToEntryIdSetMap)) {
                                    const startMap = startToEndeToEntryIdSetMap[start];
                                    for (let end of Object.keys(startMap)) {
                                        const jobObj = startMap[end];

                                        for (let importType in jobObj.importTypeToStationMapMap) {
                                            const map = jobObj.importTypeToStationMapMap[importType];
                                            jobExportContext.setEntriesToCombine(prev => prev + Object.keys(map).length);

                                            for (let station in map) {
                                                const stationObj = map[station];
                                                const key = `${importType}_${station}_${start}_${end}`;
                                                if (!stationMap[key]) stationMap[key] = {jobId: stationObj.jobId, entries: []};
                                                stationMap[key].entries.push(...stationObj.entrySet);
                                            }
                                        }
                                    }
                                }

                                if (performPost) {
                                    for (let key in stationMap) {
                                        data = {
                                            type: "MOVE_ENTRY_SET_TO_JOB",
                                            blisterJobEntryIDSet: stationMap[key].entries,
                                            blisterJobID: stationMap[key].jobId
                                        };
                                        resultList.push(await callApiAsync({
                                            auth,
                                            url: BASE_URL_NONMP + `job`,
                                            method: "post",
                                            data
                                        }));
                                        jobExportContext.setEntriesCombined(prev => prev+1);
                                    }

                                    stationMapRef.current = {};
                                }
                                break;
                            case "NONE":
                            default:
                                jobExportContext.setEntriesToCombine(prev => prev+1);
                                data = {
                                    type: "BROADCAST_CREATE_JOBS",
                                    jobIdList: jobList.map(j => j.id),
                                    importType: importType === "maschine" ? "MACHINE" : "HAND"
                                };
                                resultList.push(await callApiAsync({
                                    auth,
                                    url: BASE_URL_NONMP + `job`,
                                    method: "post",
                                    data
                                }));
                                jobExportContext.setEntriesCombined(prev => prev+1);
                        }
                        return resultList;
                    }

                    return [];
                }

                const entries = Object.entries(jobMap);
                jobExportContext.setExportCombining(true);
                jobExportContext.setEntriesToCombine(0);
                jobExportContext.setEntriesCombined(0);
                await Promise.all(entries.map((val, index, arr) => combineJobList(val, index === arr.length-1)));
                jobExportContext.setExportListResult([].concat.apply([], Object.values(jobMap)));
                jobExportContext.setExportFinished(true);

                if (medikationsplanContext.exportingAmmendment) medikationsplanContext.setExportingAmmendment(false);
            } else {
                // Es gibt keine Abgaben, die exportiert werden müssen
                if (medikationsplanContext.exportPending) {
                    notification.info({message: 'Es gibt keine Abgaben in diesem Zeitraum.'})

                    if (medikationsplanContext.exportingAmmendment) {
                        try {
                            const response = await exportJob(medikationsplanContext.medikationsplan, null, {}, false)
                            const newJobIds = response.data.OBJECT;

                            jobExportContext.setExportListResult(newJobIds);
                            medikationsplanContext.setExportingAmmendment(false);
                        } catch (e) {
                            notification.error({message: 'Beim Export ist ein Fehler aufgetreten!'});
                            console.error(e);
                        }
                    }

                    medikationsplanContext.setExportPending(false);
                    medikationsplanContext.setDisplayExportResult(true);
                }
            }

            exportProcessRunning.current = false;

            jobExportContext.setExportStarted(false);
            jobExportContext.setExportListStarted([]);
            jobExportContext.setExportListFinished([]);
            jobExportContext.setExportListTotal([]);

            jobExportContext.setHandleExportMedikationsplaene([]);
            jobExportContext.setMergetype("ALL");
        }
    }

    const parseAbgabeList = async () => {
        setModalData(prev => ({...prev, abgabenParsing: true}));

        const abgabeTreeChildren = [];
        const patientIdList = Object.keys(modalData.abgabeMap);
        patientIdList.sort(getSorter())
        for (let patientId of patientIdList) {
            let patientNode;

            const m2StringToAbgabeListMap = modalData.abgabeMap[patientId];
            const medicineM2List = [];
            for (let m2String of Object.keys(m2StringToAbgabeListMap)) {
                medicineM2List.push(await api.getMedicineM2(m2String));
            }
            medicineM2List.sort(getSorter("medicineM2"));

            for (let medicineM2 of medicineM2List) {
                let medicineM2Node;

                if (!medicineM2) {
                    let bla;
                }

                const abgabeList = m2StringToAbgabeListMap[medicineM2.m2];
                abgabeList.sort((a,b) => getSorter("string")(a.abgabezeit, b.abgabezeit));

                for (let abgabe of abgabeList) {
                    if (!patientNode) {
                        patientNode = {
                            title: getUnitLangname(abgabe.medikationsplan.patient),
                            key: abgabe.medikationsplan.patient.id,
                            children: []
                        };
                        abgabeTreeChildren.push(patientNode);
                    }

                    if (!medicineM2Node) {
                        medicineM2Node = {
                            title: getMedicineM2Name(medicineM2),
                            key: ""+patientId+medicineM2.m2,
                            children: []
                        };
                        patientNode.children.push(medicineM2Node);
                    }

                    medicineM2Node.children.push({
                        title: `${moment(parseInt(abgabe.abgabezeit)).format("ddd DD.MM.YYYY HH:mm")}: ${mengeToString(abgabe.abgabemenge)}`,
                        key: ""+patientId+medicineM2.m2+abgabe.abgabezeit
                    });
                }
            }
        }

        const abgabeTreeData = [{
            title: "Alle Abgaben",
            key: modalData.abgabeTreeData[0].key,
            children: abgabeTreeChildren
        }];

        setModalData(prev => ({...prev, abgabenParsing: false, abgabenParsed: true, abgabeTreeData}));
    }

    return <>
        <Modal
            title={"Export-Auswertung"}
            visible={!!modalData.treeData}
            width={"75%"}
            footer={<>
                <Button onClick={() => printTreeData(modalData.treeData)}>drucken</Button>
                {!!modalExportButtonDisabled && <Button onClick={() => setModalExportButtonDisabled(false)}>Export trotz Fehlern freigeben</Button>}
                <Button disabled={modalExportButtonDisabled} onClick={() => {
                    const auswertungBestaetigtMap = {};
                    for (let patientNode of modalData.treeData) {
                        for (let originalNode of patientNode.children) {
                            const [patientId, m2Original] = originalNode.key.split("::");

                            if (!(patientId in auswertungBestaetigtMap)) auswertungBestaetigtMap[patientId] = {};
                            if (!(m2Original in auswertungBestaetigtMap[patientId])) auswertungBestaetigtMap[patientId][m2Original] = {};
                            auswertungBestaetigtMap[patientId][m2Original].__seen__ = true;

                            for (let substituteNode of originalNode.children) {
                                const m2Substitute = substituteNode.key.split("::")[2];

                                if (!(m2Substitute in auswertungBestaetigtMap[patientId][m2Original])) auswertungBestaetigtMap[patientId][m2Original][m2Substitute] = {erlaubt: {}}
                                auswertungBestaetigtMap[patientId][m2Original][m2Substitute].erlaubt[substituteNode.faktor] = modalTreeNodeChecked.includes(substituteNode.key);
                            }
                        }
                    }

                    setModalTreeNodeChecked([]);
                    setModalTreeNodeExpanded([]);
                    setModalData({});
                    handleExport(modalData.medikationsplaene, auswertungBestaetigtMap, modalData.mergetype);
                }}>Auswahl bestätigen (nicht ausgewählte Einträge werden mit dem Planzeilen-Arzneimittel exportiert)</Button>
            </>}

            onCancel={() => {
                setModalData({});
                setModalTreeNodeChecked([]);
                setModalTreeNodeExpanded([]);

                jobExportContext.setHandleExportMedikationsplaene([]);
                jobExportContext.setMergetype("ALL");
                if (onCancel) onCancel();
            }}
        >
            <Tree
                checkable
                onCheck={setModalTreeNodeChecked}
                onExpand={setModalTreeNodeExpanded}
                checkedKeys={modalTreeNodeChecked}
                expandedKeys={modalTreeNodeExpanded}

                treeData={modalData.treeData}
            />

            <Tree
                treeData={modalData.abgabeTreeData}
                onExpand={() => {
                    if (!modalData.abgabenParsed && !modalData.abgabenParsing) {
                        parseAbgabeList();
                    }
                }}
            />
        </Modal>
    </>
}