import React from 'react'

import { useFetch } from '../../utilities/useFetch';
import { fetchNachrichtenArchiv } from '../../config/fetchApiConfiguration';
import { NachrichtenListe } from './NachrichtenListe';

export const NachrichtenArchiv = (props) => {

    const { data, loading, error } = useFetch(fetchNachrichtenArchiv())

    if (error)
        return <></>

    return <NachrichtenListe
        nachrichten={data}
        loading={loading}
    />

}
