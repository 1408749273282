import React from "react";
import {Layout} from "antd";
import {mainStyle, siderStyle} from "../../style/css";
import MedikationsplanSuche from "../organisms/MedikationsplanSuche";
import {MedikationsplanDokumente} from "../organisms/MedikationsplanDokumente";
import {Dashboard} from "../organisms/Dashboard";

const {Sider} = Layout;

const Home = () => {

    return (
        <Layout>
            <Sider style={{...siderStyle}} className="no-print">
                <MedikationsplanSuche />
                <MedikationsplanDokumente />
            </Sider>

            <Layout style={mainStyle}>
                <Dashboard />
            </Layout>
        </Layout>
    )
}

export default Home;