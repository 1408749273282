import React, {useEffect, useRef, useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import {Layout, notification, ConfigProvider, Card} from 'antd';
import deDE from 'antd/es/locale/de_DE';
import packageJson from '../../package.json';
import 'antd/dist/antd.css'

import { GlobalContext, useInitialState as globalContextInit } from '../config/globalContext'
import LoginContext, { useInitialState as loginContextInit } from '../contexts/LoginContext';
import DokumenteContext, {useInitialState as dokumenteContextInit} from "../contexts/DokumenteContext";
import InteraktionenContext, {useInitialState as interaktionenContextInit} from "../contexts/InteraktionenContext";
import ApiContext, {useInitialState as apiContextInit} from "../contexts/ApiContext";
import MedikationsplanContext, {useInitialState as medikationsplanContextInit} from "../contexts/MedikationsplanContext";
import ArzneimittelContext, {useInitialState as arzneimittelContextInit} from "../contexts/ArzneimittelContext";
import NotizContext, {useInitialState as notizContextInit} from "../contexts/NotizContext";
import MitteilungContext, {useInitialState as mitteilungContextInit} from "../contexts/MitteilungContext";
import WindowContext, {useInitialState as windowContextInit} from "../contexts/WindowContext";
import JobExportContext, {useInitialState as jobExportContextInit} from "../contexts/JobExportContext";
import GlobalHotkeyContext, {useInitialState as globalHotkeyContextInit} from "../contexts/GlobalHotkeyContext";
import WebSocketContext, {useInitialState as webSocketContextInit} from "../contexts/WebSocketContext";
import AdressbuchContext, {useInitialState as adressbuchContextInit} from "../contexts/AdressbuchContext";
import WebWorkerContext , {useInitialState as webWorkerContextInit} from "../contexts/WebWorkerContext";

import { Provider } from 'react-redux'

import { Login } from './pages/Login'

import Vista from './Vista';
import Navigator from './Navigator';

import { store } from './../utilities/myRedux';
import {LoginGrowl} from "./pages/LoginGrowl";
import NotizModal from "./molecules/NotizModal";
import {InteraktionenModal} from "./molecules/InteraktionenModal";
import CustomGlobalHotKeys from "./molecules/CustomGlobalHotKeys";
import DisableOverlay from "./atoms/DisableOverlay";
import MitteilungModal from "./molecules/MitteilungModal";

/**
 * App Component
 * wird in ../index.js als erste Komponente eingebunden
 * @see {@link Navigator } 
 * @see {@link Vista }
 */
const App = () => {

  const globalContextState = globalContextInit();
  const loginContext = loginContextInit();
  const dokumenteContext = dokumenteContextInit();
  const interaktionenContext = interaktionenContextInit();
  const apiContext = apiContextInit();
  const medikationsplanContext = medikationsplanContextInit();
  const arzneimittelContext = arzneimittelContextInit();
  const notizContext = notizContextInit();
  const mitteilungContext = mitteilungContextInit();
  const windowContext = windowContextInit();
  const jobExportContext = jobExportContextInit();
  const globalHotkeyContext = globalHotkeyContextInit();
  const webSocketContext = webSocketContextInit();
  const adressbuchContext = adressbuchContextInit();
  const webWorkerContext = webWorkerContextInit();

  const { Header, Content, Footer } = Layout;
  const [notificationKey, setNotificationKey] = useState(null);

  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const workerPort = useRef();

  // Todo: this (and below) is hacky. cleanup!
  let noNavbar = window.location.href.includes("vorschau");

  useEffect(() => {
      if (!window.name) window.name = "parent-default";

      const worker = new SharedWorker(process.env.PUBLIC_URL + "/workers/openWindowsWorker.js");
      const port = worker?.port;

      if (port) {
          port.onmessage = ({data}) => {
              if (data.action === "disable") {
                  loginContext.setDisableWindow(true);
              }
          }
          port.start();

          port.postMessage({action: "connect", windowName: window.name});

          workerPort.current = port;
      }
  }, [])

    useEffect(() => {
        if (loginContext.showLoginNotification) {
            const key = `login${Date.now()}`;

            setNotificationKey(key);
            notificationApi.open({
                duration: 0,
                key,
                message: <h4>Session ist abgelaufen</h4>,
                description: <div>
                    <LoginGrowl />
                </div>
            });
        } else if(notificationKey) {
            setNotificationKey(null);

            console.log("close login notification:", notificationKey);
            notification.close(notificationKey);
        }
    }, [loginContext.showLoginNotification]);

  return (
      loginContext.disableWindow ? <DisableOverlay text={"Fenster ist inaktiv"} disable={loginContext.disableWindow} /> :

      <ConfigProvider locale={deDE}>
        <BrowserRouter>
          <LoginContext.Provider value={loginContext} >
              {notificationContextHolder}

          <GlobalContext.Provider value={globalContextState} >
          <DokumenteContext.Provider value={dokumenteContext} >
          <InteraktionenContext.Provider value={interaktionenContext} >
          <ApiContext.Provider value={apiContext} >
          <MedikationsplanContext.Provider value={medikationsplanContext}>
          <ArzneimittelContext.Provider value={arzneimittelContext}>
          <NotizContext.Provider value={notizContext}>
          <MitteilungContext.Provider value={mitteilungContext}>
          <WindowContext.Provider value={windowContext}>
          <JobExportContext.Provider value={jobExportContext}>
          <GlobalHotkeyContext.Provider value={globalHotkeyContext}>
          <AdressbuchContext.Provider value={adressbuchContext}>
          <WebSocketContext.Provider value={webSocketContext}>
          <WebWorkerContext.Provider value={webWorkerContext}>

              <CustomGlobalHotKeys>

            <Provider store={store}>
              {!loginContext.lastLogin && !loginContext.showLoginNotification && <Login />}

              {(loginContext.lastLogin || loginContext.showLoginNotification) && <>
                <Layout
                    style={{minHeight: "100vh"}}
                    onContextMenu={(e) => {
                        if (!e.ctrlKey || !e.shiftKey) {
                            e.preventDefault();
                            globalContextState.setShowContextMenu(true);
                        }
                    }}

                    onClick={e => {
                        globalContextState.setShowContextMenu(false);
                        globalContextState.setContextMenu({});
                    }}
                >
                  {noNavbar &&
                    <Vista />
                  }

                  {!noNavbar &&
                    <>
                      <Header id={"header"} style={{ position: 'fixed', height: '100px', zIndex: 1000, width: '100%', backgroundColor: "#2C7AB1" }}>
                        <Navigator />
                      </Header>
                      <Content style={{ padding: '0 50px', marginTop: 100 }}>
                        <iframe id="printf" name="printf" style={{width: "100%", display: "none"}}></iframe>
                        <Vista />
                      </Content>

                        <div style={{zIndex: 1050, lineHeight: "initial"}} id={"div-moveable-cards"}>
                            {/*<div style={{zIndex: windowContext.activeId === "card-notiz" ? 1051 : 1050}}><NotizModal cardId={"card-notiz"} /></div>*/}
                            {/*<div style={{zIndex: windowContext.activeId === "card-interaktionen" ? 1051 : 1050}}><InteraktionenModal cardId={"card-interaktionen"} /></div>*/}
                            <InteraktionenModal cardId={"card-interaktionen"} />
                            <NotizModal cardId={"card-notiz"} />
                            <MitteilungModal cardId={"card-mitteilung"} />
                        </div>
                    </>
                  }
                  <Footer style={{ textAlign: 'center'}}>
                      <hr />Niraso RX - version {packageJson.version} (woodstock)
                  </Footer>

                  {!!globalContextState.contextMenu.content && <Card
                      style={{position: "absolute", top: globalContextState.contextMenu.top, left: globalContextState.contextMenu.left}}
                      bodyStyle={{padding: 5}}
                      title={!!globalContextState.contextMenu.title ? globalContextState.contextMenu.title : undefined}
                  >
                      {globalContextState.contextMenu.content}
                  </Card>}
                </Layout>

                <div id={'portal'}></div>
              </>}
            </Provider>

              </CustomGlobalHotKeys>

          </WebWorkerContext.Provider>
          </WebSocketContext.Provider>
          </AdressbuchContext.Provider>
          </GlobalHotkeyContext.Provider>
          </JobExportContext.Provider>
          </WindowContext.Provider>
          </MitteilungContext.Provider>
          </NotizContext.Provider>
          </ArzneimittelContext.Provider>
          </MedikationsplanContext.Provider>
          </ApiContext.Provider>
          </InteraktionenContext.Provider>
          </DokumenteContext.Provider>
          </GlobalContext.Provider >
          </LoginContext.Provider>
        </BrowserRouter >
      </ConfigProvider>
  );
};

export default App
