import React, {useContext, useEffect, useRef, useState} from "react";
import {useFetch} from "../../utilities/useFetch";
import {
    medikationsplanApi,
    planzeilenApi,
    verblisterungApi
} from "../../config/apiConfig";
import {Button, DatePicker, Modal, Table, Tree} from "antd";
import {Tooltip} from "../atoms/Tooltip";
import {Popover} from "../atoms/Popover";
import moment from "moment";
import {getUnitLangname} from "../../config/entities";
import {getSorter} from "../../utilities/sortUtil";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {getExportEnde, getExportStart, getStartLuecke} from "../../utilities/blisterJobUtil";
import {ConditionalTooltip} from "../atoms/ConditionalTooltip";
import {ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {useOnClickOutside} from "../../utilities/useOnClickOutside";
import usePagination from "../../utilities/usePagination";
import JobExportContext from "../../contexts/JobExportContext";
import {JobExportModal} from "../organisms/JobExportModal";
import useApiZeitraum, {
    getCurrentZeitraum,
    getCurrentZeitraumTyp,
    getZeitraumDelta
} from "../../utilities/useApiZeitraum";
import {naechsterWochentag} from "../../utilities/myDates";
import {GlobalContext} from "../../config/globalContext";
import WebWorkerContext from "../../contexts/WebWorkerContext";
import {Link} from "react-router-dom";

export const VerblisterungDashboard = () => {
    const auth = useAuth();
    const jobExportContext = useContext(JobExportContext);
    const globalContext = useContext(GlobalContext);
    const webWorkerContext = useContext(WebWorkerContext);
    const {getColumnSearchProps} = useTableSearchFilter();
    const zeitraumApi = useApiZeitraum();

    const heute = globalContext.heute;

    const refStart = useRef();
    const refEnde = useRef();
    const refCalendar = useRef();

    const [refStartPopup, setRefStartPopup] = useState({current:null});
    const [refEndePopup, setRefEndePopup] = useState({current:null});
    const [editDatumReferenz, setEditDatumReferenz] = useState(null);
    const [datumReferenzStart, setDatumReferenzStart] = useState(heute);
    const [datumReferenzEnde, setDatumReferenzEnde] = useState(moment(heute).endOf('day'));
    const [medikationsplaeneGefiltert, setMedikationsplaeneGefiltert] = useState(null);
    const [medikationsplaeneGefiltertMap, setMedikationsplaeneGefiltertMap] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const [isExporting, setIsExporting] = useState(false);

    const [paginationInfo, setPaginationInfo] = useState(null);
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);

    const [auftragtypFilter, setAuftragtypFilter] = useState(null);
    const [blistereinstellungenFehlen, setBlistereinstellungenFehlen] = useState([]);

    const [useBuendel, setUseBuendel] = useState(true);

    const planzeilenMap = useRef({});

    const isFilteredRef = useRef({});

    const patientBlisterzeitraumMapLast = useRef(null);

    const {
        data: medikationsplaeneBlisterbar,
        loading,
        reload,
    } = useFetch(medikationsplanApi.getBlisterbar(datumReferenzEnde));

    const medikationsplaeneBlisterbarProcessTimestamp = useRef();
    const [medikationsplaeneBlisterbarMsg, setMedikationsplaeneBlisterbarMsg] = useState('Wird geladen, bitte warten.');

    const pagination = usePagination({pageSize: 20});

    useOnClickOutside(() => {
        if (editDatumReferenz) setEditDatumReferenz(null);
    }, [refStart, refStartPopup, refEnde, refEndePopup, refCalendar])

    useEffect(() => {
        const jsonString = JSON.stringify(jobExportContext.patientBlisterzeitraumMap);
        if (medikationsplaeneBlisterbar && patientBlisterzeitraumMapLast.current !== jsonString) {
            const timestamp = Date.now();
            medikationsplaeneBlisterbarProcessTimestamp.current = timestamp;

            (async () => {
                const medikationsplaeneGefiltertNeu = [];
                const medikationsplaeneGefiltertMapNeu = {};

                setBlistereinstellungenFehlen(medikationsplaeneBlisterbar.filter(m => m.verblisterungTagDifferenz <= 0));

                const patientBlisterzeitraumMapNeu = {...jobExportContext.patientBlisterzeitraumMap};
                let mapChanged = false;

                const medikationsplaeneToProcess = medikationsplaeneBlisterbar.filter(medikationsplan => !auftragtypFilter || auftragtypFilter.includes(medikationsplan.maschinenverblisterung));
                let total = medikationsplaeneToProcess.length, loaded = 0;

                const processNext = async () => {
                    if (medikationsplaeneToProcess.length) {
                        const medikationsplan = medikationsplaeneToProcess.pop();
                        await new Promise(async resolve => {
                            if (!planzeilenMap.current[medikationsplan.id]) {
                                const response = await callApiAsync({auth, url: planzeilenApi.getByFilter({planId: medikationsplan.id})});
                                planzeilenMap.current[medikationsplan.id] = response.data.OBJECT;
                            }
                            medikationsplan.planzeilen = planzeilenMap.current[medikationsplan.id];

                            let patientBlisterzeitraum = patientBlisterzeitraumMapNeu[medikationsplan.id];
                            if (!(medikationsplan.id in patientBlisterzeitraumMapNeu)) {
                                mapChanged = true;

                                patientBlisterzeitraum = null;
                                patientBlisterzeitraumMapNeu[medikationsplan.id] = patientBlisterzeitraum;

                                const processResponse = async (response) => {
                                    // Zeiträume abrufen, wenn noch nicht bekannt
                                    let zeitraumMap = {...zeitraumApi.unitIdToZeitraumMapMap};
                                    if (!zeitraumMap[medikationsplan.patient.id]) {
                                        zeitraumMap[medikationsplan.patient.id] = await zeitraumApi.getZeitraumMapByMedikationsplan(medikationsplan.id);
                                    }

                                    // Abbrechen, wenn es keine Positivzeiträume gibt
                                    if (!zeitraumMap[medikationsplan.patient.id]?.B?.length) {
                                        resolve();
                                        return;
                                    }

                                    // Ermittle Exportzeitraum ab letztem Export oder erstem Positivzeitraum, falls noch kein Export vorhanden
                                    let letzteEinnahme = response.data.OBJECT;
                                    if (letzteEinnahme > 0) letzteEinnahme = naechsterWochentag(medikationsplan.blisterStartTag, letzteEinnahme, -1);
                                    const exportStartFirst = getExportStart(medikationsplan, letzteEinnahme, !letzteEinnahme && moment(zeitraumMap[medikationsplan.patient.id].B[0].id.start).subtract(1, 'day').valueOf());
                                    let exportStart = exportStartFirst;

                                    const zeitraumDelta = getZeitraumDelta(zeitraumMap[medikationsplan.patient.id], exportStart);
                                    if (!zeitraumDelta?.length) {
                                        resolve();
                                        return;
                                    }

                                    // Setze Export-Start auf ersten blisterbaren Tag
                                    exportStart = zeitraumDelta[0][0];

                                    // Ermittle Exportzeitraum (Fortsetzung)
                                    let {exportEnde, startLuecke} = getExportEnde(exportStart, medikationsplan, letzteEinnahme);

                                    let verblisterungTag = moment(exportStart).subtract(medikationsplan.verblisterungTagDifferenz || 0, 'days').valueOf();
                                    if (heute.isAfter(verblisterungTag)) verblisterungTag = heute.valueOf();

                                    // Prüfe, ob es eine Lücke zum letzten Blister gibt und der erste blisterbare Tag nicht in der Zukunft liegt
                                    if (startLuecke > 0 && exportStart === exportStartFirst) {
                                        let diff = Math.min(moment(exportStart).diff(heute, 'days'), startLuecke);
                                        if (diff) {
                                            // ermittle den ersten blisterbaren Tag ab dem neuen Blisterstart
                                            const exportStartNeu = moment(exportStart).subtract(diff, 'days').valueOf();
                                            const zeitraumDelta = getZeitraumDelta(zeitraumMap[medikationsplan.patient.id], exportStartNeu);
                                            if (zeitraumDelta?.length) {
                                                //erster blisterbare Tag ab dem neuen Blisterstart
                                                exportStart = zeitraumDelta[0][0];

                                                if (exportStart !== exportStartFirst) {
                                                    if (datumReferenzEnde.isBefore(verblisterungTag)) {
                                                        exportEnde = naechsterWochentag(medikationsplan.blisterStartTag, exportStart, -1);
                                                    }

                                                    verblisterungTag = moment(exportStart).subtract(medikationsplan.verblisterungTagDifferenz || 0, 'days').valueOf();
                                                    if (heute.isAfter(verblisterungTag)) verblisterungTag = heute.valueOf();
                                                    startLuecke -= moment(exportStartFirst).diff(exportStart, 'days');
                                                }
                                            }
                                        }
                                    }


                                    patientBlisterzeitraum = {start: exportStart, ende: exportEnde, startLuecke, letzteEinnahme, verblisterungTag};
                                    patientBlisterzeitraumMapNeu[medikationsplan.id] = patientBlisterzeitraum;
                                }

                                let response;
                                try {
                                    response = await callApiAsync({
                                        auth,
                                        url: verblisterungApi.getLetzteEinnahme(medikationsplan.patient.id),
                                        skipErrorLog: true,
                                    });
                                } catch (e) {
                                    response = { data: { OBJECT: 0}}
                                }
                                await processResponse(response);
                            }

                            if (patientBlisterzeitraum) {
                                const momentVerblisterungTag = moment(patientBlisterzeitraum.verblisterungTag);
                                if (!datumReferenzStart.isAfter(momentVerblisterungTag) && datumReferenzEnde.isAfter(momentVerblisterungTag)) {
                                    medikationsplaeneGefiltertMapNeu[medikationsplan.id] = medikationsplan;
                                    medikationsplaeneGefiltertNeu.push({...medikationsplan, useBuendel});
                                }
                            }

                            resolve();
                        });
                        if (medikationsplaeneBlisterbarProcessTimestamp.current !== timestamp) {
                            return;
                        }

                        setMedikationsplaeneBlisterbarMsg(`Lade blisterbare Aufträge ${++loaded}/${total}`);
                        await processNext();
                    }
                }

                await Promise.all([
                    processNext(),
                    processNext(),
                    processNext(),
                ])

                if (timestamp !== medikationsplaeneBlisterbarProcessTimestamp.current) {
                    return;
                }

                setMedikationsplaeneBlisterbarMsg('Verarbeite geladene Aufträge...');

                if (mapChanged) {
                    jobExportContext.setPatientBlisterzeitraumMap(patientBlisterzeitraumMapNeu);
                }
                setMedikationsplaeneGefiltert(medikationsplaeneGefiltertNeu);
                setMedikationsplaeneGefiltertMap(medikationsplaeneGefiltertMapNeu);
            })();
        }
    }, [medikationsplaeneBlisterbar, jobExportContext.patientBlisterzeitraumMap, auftragtypFilter])

    useEffect(() => {
        jobExportContext.setPatientBlisterzeitraumMap({});
    }, [datumReferenzStart, datumReferenzEnde])

    useEffect(() => {
        if (selectedRows.length) {
            console.log("selected rows: ", selectedRows);
        }
    }, [selectedRows])

    useEffect(() => {
        setIsExporting(jobExportContext.exportListStarted.length !== jobExportContext.exportListFinished.length);
    }, [jobExportContext.exportListStarted, jobExportContext.exportListFinished])



    const onFiltered = (isSichtbar, medikationsplan, dataIndex) => {
        isFilteredRef.current = {...isFilteredRef.current, [medikationsplan.id]: isSichtbar};
    }

    const onFilterChanged = (filterArray) => {
        if (!filterArray) {
            isFilteredRef.current = {};
        }
    }

    const handleReload = () => {
        const exportStatusMapKeys = Object.keys(jobExportContext.exportStatusMap);

        jobExportContext.setPatientBlisterzeitraumMap(prev => {
            const neu = {...prev};
            for (let key of exportStatusMapKeys) delete neu[key];
            return neu;
        });
        jobExportContext.setExportStatusMap({});
        jobExportContext.setExportFinished(false);
        jobExportContext.setExportCombining(false);

        jobExportContext.setAssessmentDataToLoad(0);
        jobExportContext.setAssessmentDataLoaded(0);
        jobExportContext.setAssessmentDataProcessed(0);

        reload();
    }

    const setZeitraumAll = zeitraum => {
        jobExportContext.setPatientBlisterzeitraumMap(prev => {
            const neu = {...prev};
            for (let key of Object.keys(neu)) {
                const zeitraumPrev = neu[key];
                const startLuecke = getStartLuecke(zeitraum.start, zeitraumPrev.letzteEinnahme);

                neu[key] = {...zeitraumPrev, start: zeitraum.start, ende: zeitraum.ende, startLuecke};
            }

            return neu;
        });
    }

    const handleExport = async (medikationsplaene) => {
        if (!medikationsplaene) {
            medikationsplaene = [];
            const keys = Object.keys(isFilteredRef.current);
            if (keys.length) {
                for (let planId of keys) {
                    if (isFilteredRef.current[planId]) {
                        const medikationsplan = medikationsplaeneGefiltertMap[planId];
                        medikationsplaene.push(medikationsplan);
                    }
                }
            } else {
                medikationsplaene = medikationsplaeneGefiltert
            }
        }

        console.log('jobExportContext.setHandleExportMedikationsplaene(): ', medikationsplaene);
        jobExportContext.setHandleExportMedikationsplaene(medikationsplaene);
        jobExportContext.setMergetype("STATION");
    }

    const onChangeTable = (pagination, filters, sorter) => {
        if (!isExporting) {
            setAuftragtypFilter(filters?.auftragstyp);

            setPaginationInfo(pagination);
            setFilterInfo(filters);
            setSorterInfo(sorter);
        }
    }

    const columns = [
        {
            title: "Patient",
            dataIndex: "patient",
            render: (patient, medikationsplan) => <Tooltip title={"zum Medikationsplan"}><a href={`/medikationsplan/${medikationsplan.id}`}>{getUnitLangname(patient)}</a></Tooltip>,
            sorter: getSorter("medikationsplan", "patient"),
            ...getColumnSearchProps({dataIndex: "patient", onFilter, onFiltered, onChange: onFilterChanged}),

            filteredValue: filterInfo?.patient || null,
            sortOrder: (!sorterInfo && "ascend") || (sorterInfo.field === "patient" && sorterInfo.order),
        },
        {
            title: "Pflegeheim",
            key: "pflegeheim",
            render: (medikationsplan) => getUnitLangname(medikationsplan.patient.careFacility),
            sorter: getSorter("medikationsplan", "pflegeheim"),
            ...getColumnSearchProps({dataIndex: "pflegeheim", onFilter, onFiltered, onChange: onFilterChanged}),

            filteredValue: filterInfo?.pflegeheim || null,
            sortOrder: sorterInfo?.columnKey === "pflegeheim" && sorterInfo.order,
        },
        {
            title: "Station",
            key: "station",
            render: (medikationsplan) => getUnitLangname(medikationsplan.patient.station),
            sorter: getSorter("medikationsplan", "station"),
            ...getColumnSearchProps({dataIndex: "station", onFilter, onFiltered, onChange: onFilterChanged}),

            filteredValue: filterInfo?.station || null,
            sortOrder: sorterInfo?.columnKey === "station" && sorterInfo.order,
        },
        {
            title: "Auftragstyp",
            key: "auftragstyp",
            render: (medikationsplan) => medikationsplan.maschinenverblisterung ? "Maschinenverblisterung" : "Handverblisterung",
            sorter: (a, b) => getSorter("boolean")(a.maschinenverblisterung, b.maschinenverblisterung),

            filters: [
                {text: "Handverblisterung", value: false},
                {text: "Maschinenverblisterung", value: true},
            ],

            filteredValue: filterInfo?.auftragstyp || null,
            sortOrder: sorterInfo?.columnKey === "auftragstyp" && sorterInfo.order,
        },
        {
            title: "Blisterzeitraum",
            key: "blisterzeitraum",
            render: (medikationsplan) => {
                if (jobExportContext.patientBlisterzeitraumMap[medikationsplan.id]) {
                    const zeitraum = jobExportContext.patientBlisterzeitraumMap[medikationsplan.id];
                    return <span>
                        <ExportZeitraumColumn
                            zeitraum={zeitraum}
                            medikationsplan={medikationsplan}

                            onChange={zeitraum => jobExportContext.setPatientBlisterzeitraumMap(prev => {
                                const neu = {...prev};
                                neu[medikationsplan.id] = zeitraum;

                                // prüfe, ob Zeitraum den globalen Zeitraum ausweiten
                                // if (datumReferenzStart.isAfter(zeitraum.start)) {
                                //     setDatumReferenzStart(moment(zeitraum.start));
                                // } else if (datumReferenzEnde.isBefore(zeitraum.start)) {
                                //     setDatumReferenzEnde(moment(zeitraum.ende));
                                // }

                                return neu;
                            })}

                            onChangeAll={setZeitraumAll}
                        />
                        {jobExportContext.exportStatusMap[medikationsplan.id]?.loading && <><LoadingOutlined /> wird exportiert</>}
                        {jobExportContext.exportStatusMap[medikationsplan.id]?.ready && <><CheckOutlined /> Export erfolgreich</>}
                        {jobExportContext.exportStatusMap[medikationsplan.id]?.error && <><ExclamationCircleOutlined /> Fehler beim Export{!!jobExportContext.exportStatusMap[medikationsplan.id]?.error?.data?.MESSAGE && ` (${jobExportContext.exportStatusMap[medikationsplan.id].error.data.MESSAGE})`}</>}
                        {jobExportContext.exportStatusMap[medikationsplan.id]?.message && <><ExclamationCircleOutlined /> {jobExportContext.exportStatusMap[medikationsplan.id].message}</>}
                    </span>;
                } else {
                    return "Keine Daten";
                }
            },
            sorter: (a, b) => {
                const dateSorter = getSorter("date");
                const zeitraumA = jobExportContext.patientBlisterzeitraumMap[a.patient.id];
                const zeitraumB = jobExportContext.patientBlisterzeitraumMap[b.patient.id];

                if (zeitraumA && zeitraumB) {
                    let retVal = dateSorter(zeitraumA.start, zeitraumB.start);
                    if (retVal === 0) {
                        retVal = dateSorter(zeitraumA.ende, zeitraumB.ende);
                    }

                    return retVal;
                } else if (!zeitraumA) {
                    return 1;
                } else if (!zeitraumB) {
                    return -1;
                } else {
                    return 0;
                }
            },

            sortOrder: sorterInfo?.columnKey === "blisterzeitraum" && sorterInfo.order,
        },
        {
            title: "Blistertag",
            key: "blistertag",
            render: (medikationsplan) => {
                if (jobExportContext.patientBlisterzeitraumMap[medikationsplan.id]) {
                    const zeitraum = jobExportContext.patientBlisterzeitraumMap[medikationsplan.id];
                    return moment(zeitraum.verblisterungTag).format('DD.MM.YYYY');
                } else {
                    return "Keine Daten";
                }
            },
            sorter: (a, b) => {
                const dateSorter = getSorter("date");
                const zeitraumA = jobExportContext.patientBlisterzeitraumMap[a.id];
                const zeitraumB = jobExportContext.patientBlisterzeitraumMap[b.id];

                return dateSorter(zeitraumA?.verblisterungTag, zeitraumB?.verblisterungTag);
            },

            sortOrder: sorterInfo?.columnKey === "blistertag" && sorterInfo.order,
        }
    ]

    return <div>
        <JobExportModal />

        <h1>
            Blisterbare Medikationspläne mit Blistertag im Zeitraum <span className={"datepicker-without-input"}>
                <span onClick={e => refCalendar.current = e.target}>
                    <DatePicker
                        format={"DD.MM.YYYY"}
                        onChange={datum => {
                            const start = datum.startOf('day');
                            const ende = moment(start).endOf('day');

                            setDatumReferenzStart(start);
                            setDatumReferenzEnde(ende);
                        }}
                        disabledDate={date => heute.isAfter(date)}
                        value={datumReferenzStart}
                        open={editDatumReferenz === "start"}
                        getPopupContainer={(popupContainer) => {
                            setRefStartPopup({current: popupContainer});
                            return popupContainer.parentElement;
                        }}
                        style={{paddingTop: 20}}
                    />
                </span>
                <span onClick={() => setEditDatumReferenz("start")} style={{cursor: "pointer"}} ref={refStart}>{datumReferenzStart.format("DD.MM.YYYY")}</span>
            </span> - <span className={"datepicker-without-input"}>
                <span onClick={e => refCalendar.current = e.target}>
                    <DatePicker
                        format={"DD.MM.YYYY"}
                        onChange={datum => setDatumReferenzEnde(datum.endOf('day'))}
                        disabledDate={date => datumReferenzStart.isAfter(date)}
                        value={datumReferenzEnde}
                        open={editDatumReferenz === "ende"}
                        getPopupContainer={(popupContainer) => {
                            setRefEndePopup({current: popupContainer});
                            return popupContainer.parentElement;
                        }}
                        style={{paddingTop: 20}}
                    />
                </span>
                <span onClick={() => setEditDatumReferenz("ende")} style={{cursor: "pointer"}} ref={refEnde}>{datumReferenzEnde.format("DD.MM.YYYY")}</span>
            </span>
        </h1>
        {!!blistereinstellungenFehlen.length && <div><Popover placement={"bottom"} content={<div style={{display: 'flex', flexDirection: 'column'}}>
            <h6>Patienten ohne Blistereinstellungen:</h6>
            {blistereinstellungenFehlen.map(m => <Link to={`/adressbuch/${m.patient.id}/medikationsplan`} key={m.id}>{getUnitLangname(m.patient)}</Link>)}
        </div>}><span style={{color: "red", cursor: 'pointer'}}>Es gibt Patienten ohne Verblisterungseinstellungen!</span></Popover></div>}
        {loading ?
            <h5>Wird geladen, bitte warten.</h5> :
            !medikationsplaeneGefiltert ?
                <h5>{medikationsplaeneBlisterbarMsg}</h5> :
                <>
                    <Table
                        dataSource={medikationsplaeneGefiltert}
                        columns={columns}
                        rowKey={"id"}
                        showSorterTooltip={false}
                        onChange={onChangeTable}

                        locale={{
                            emptyText: "Keine Exporte für die angegebenen Filter"
                        }}

                        rowSelection={ !jobExportContext.exportListStarted.length && {
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRows)
                        }}

                        pagination={pagination}
                    />
                    {jobExportContext.exportFinished || jobExportContext.exportStarted ?
                        <>
                            <Button onClick={handleReload} disabled={isExporting || !!jobExportContext.exportStarted} loading={isExporting || !!jobExportContext.exportStarted}>Weitere Exporte erstellen</Button>
                            {
                                jobExportContext.exportFinished ?
                                    '' :
                                    jobExportContext.exportCombining ?
                                        <span style={{marginLeft: 15}}>Zusammenführen von Aufträgen: {jobExportContext.entriesCombined}/{jobExportContext.entriesToCombine}</span> :
                                        isExporting ?
                                            <span style={{marginLeft: 15}}>Exportieren von Aufträgen: {jobExportContext.exportListStarted.length}/{jobExportContext.exportListTotal.length}</span> :
                                            !!jobExportContext.assessmentDataToLoad && !!jobExportContext.assessmentDataProcessed ?
                                                <span style={{marginLeft: 15}}>Verarbeiten von Substitutionen: {jobExportContext.assessmentDataProcessed}/{jobExportContext.assessmentDataToProcess}</span> :
                                                <span style={{marginLeft: 15}}>Laden von Medikationsplänen: {jobExportContext.assessmentDataLoaded}/{jobExportContext.assessmentDataToLoad}</span>
                            }
                        </> :
                        <>
                            <Button disabled={!selectedRows.length} loading={!!selectedRows.length && !!jobExportContext.handleExportMedikationsplaene.length} onClick={() => handleExport(selectedRows)}>Ausgewählte Einträge als Blisterauftrag exportieren</Button>
                            <Button disabled={!!jobExportContext.handleExportMedikationsplaene?.length} loading={!!jobExportContext.handleExportMedikationsplaene?.length} onClick={() => handleExport()}>Alle Einträge als Blisterauftrag exportieren</Button>
                        </>
                    }
                </>
        }
    </div>
}

const onFilter = (value, record, dataIndex) => {
    switch (dataIndex) {
        case "auftragstyp":
            return !!record.maschinenverblisterung;
        case "pflegeheim":
            return getUnitLangname(record.patient.careFacility)
                .toLowerCase()
                .includes(value.toLowerCase());
        case "station":
            return getUnitLangname(record.patient.station)
                .toLowerCase()
                .includes(value.toLowerCase());
        case "patient":
            return getUnitLangname(record.patient)
                .toLowerCase()
                .includes(value.toLowerCase());
    }
};

const ExportZeitraumColumn = ({zeitraum, medikationsplan, onChange=(zeitraum)=>{}, onChangeAll=(zeitraum)=>{}}) => {
    const globalContext = useContext(GlobalContext);

    return <ConditionalTooltip title={zeitraum.startLuecke > 0 && `Mit diesen Daten wird eine Lücke von ${zeitraum.startLuecke} Tag${zeitraum.startLuecke > 1 ? "en" : ""} zum letzten Blisterauftrag produziert.`}>
        <Popover
            placement={"left"}
            trigger={"click"}
            content={<div>
                <h5>Export-Zeitraum anpassen:</h5>
                Start: <DatePicker
                            defaultValue={moment(zeitraum.start)}
                            format={"DD.MM.YYYY"}

                            onChange={date => {
                                const startLuecke = getStartLuecke(date, zeitraum.letzteEinnahme);
                                let ende;
                                if (date.isBefore(zeitraum.ende)) {
                                    ende = zeitraum.ende;
                                } else {
                                    const naechsterWT = moment(naechsterWochentag(medikationsplan.blisterStartTag, date, -1));
                                    if (date.isAfter(naechsterWT)) naechsterWT.add(1, 'week');
                                    ende = naechsterWT.endOf('day').valueOf();
                                }

                                onChange({...zeitraum, start: date.startOf('day').valueOf(), ende, startLuecke});
                            }}
                        /><br/>
                Ende: <DatePicker
                            defaultValue={moment(zeitraum.ende)}
                            format={"DD.MM.YYYY"}
                            disabledDate={time => time.isBefore(zeitraum.start)}

                            onChange={date => {
                                onChange({...zeitraum, ende: date.endOf('day').valueOf()})
                            }}
                        /><br/>
                <Button onClick={() => onChangeAll(zeitraum)}>Daten für alle Exporte übernehmen</Button>
            </div>}
        >
            <span style={{color: zeitraum.startLuecke > 0 ? "red" : "initial", marginRight: 10}}>
                {/*{`${zeitraum.startLuecke > 0 ? `(+${zeitraum.startLuecke}) ` : ""}${moment(zeitraum.start).format("DD.MM.YYYY")} - ${moment(zeitraum.ende).format("DD.MM.YYYY")}`}*/}
                {`${moment(zeitraum.start).format("DD.MM.YYYY")} - ${moment(zeitraum.ende).format("DD.MM.YYYY")}`}
            </span>
        </Popover>
    </ConditionalTooltip>
}