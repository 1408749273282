export const boundsType = {
    top: "top",
    bottom: "bottom",
    full: "full",
    none: "none",
};

export const isElementInViewport = (element, bounds=boundsType.bottom) => {
    const rect = element.getBoundingClientRect();

    switch (bounds) {
        case boundsType.top:
            return (
                rect.top >= document.getElementById("header").offsetHeight
            );
        case boundsType.bottom:
            return (
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            );
        default:
            return (
                rect.top >= document.getElementById("header").offsetHeight &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            );
    }
};

export const getViewportBoundsType = (element) => {
    const rect = element.getBoundingClientRect();

    const headerOffset = document.getElementById("header").offsetHeight;
    const topIsVisible = rect.top >= headerOffset;
    const bottomIsVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

    if (topIsVisible) {
        if (bottomIsVisible) {
            return boundsType.full;
        } else {
            return boundsType.top;
        }
    } else if (bottomIsVisible) {
        return boundsType.bottom;
    } else {
        return boundsType.none;
    }
}

export const scrollElementIntoViewport = (element, bounds=boundsType.top, offset) => {
    if (!offset) offset = document.getElementById("header").offsetHeight + 20;
    const rect = element.getBoundingClientRect();
    const currentBoundsType = getViewportBoundsType(element);

    switch (bounds) {
        case boundsType.top:
        case boundsType.full:
            if ([boundsType.top, boundsType.full].includes(currentBoundsType)) return;
            else window.scrollTo({behavior: "smooth", top: window.pageYOffset + rect.top - offset});
            break;
        case boundsType.bottom:
            if ([boundsType.bottom, boundsType.full].includes(currentBoundsType)) return;
            else {
                let bottomOffset = window.innerHeight - offset - rect.height;
                if (bottomOffset < offset) bottomOffset = offset;

                window.scrollTo({behavior: "smooth", top: window.pageYOffset + rect.bottom - rect.height - bottomOffset});
            }
    }
}