import React from "react";

export const CombinedIcon = ({children, fontSize=14, fontSizeSteps=5, style={}, className=""}) => {
    return <span style={{position: "relative", ...style}} className={className}>
        {Array.isArray(children) ?
            children.map((child, index) => {
                const style = index > 0 ? {position: "absolute", fontSize: fontSize + index * fontSizeSteps, left: index * fontSizeSteps * -0.5, top: index * fontSizeSteps * -0.5} : {};
                return <span key={index} style={style}>{child}</span>
            })

            :

            children
        }
    </span>
}