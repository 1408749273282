import {useEffect, useRef, useState} from 'react'
import { callApiAsync } from "./myApi_deprecated";
import { useAuth } from '../utilities/useAuth';


export const useSchnellsuche = ({
    placeholder = "",
    value = null,
    getUrlBySearchText = null,
    searchAfterChar = -1,
    searchAfterSpace = false,
    defaultResult = [],
    defaultResultByValue = null,
    styleFocus = { "width": "180px", "fontStyle": "normal", "color": "black" },
    styleBlur = { "width": "180px", "fontStyle": "italic", "color": "grey" },
    onChangeInputText = (text)=>{},
    filterSearchText = (searchText)=>searchText,
    searchAfterDelay = -1,
}) => {

    const auth = useAuth();

    const [searchText, setSearchText] = useState(value ? value : "")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [result, setResult] = useState(defaultResult)
    const [resultFiltered, setResultFiltered] = useState(defaultResult)
    const [style, setStyle] = useState(styleBlur)
    const [triggerSearchState, setTriggerSearchState] = useState(false);

    const timeoutRef = useRef();

    useEffect(() => {
        if (defaultResultByValue) triggersearch(defaultResultByValue);
        else if (value && value !== "") triggersearch();
    }, [])

    useEffect(() => {
        if (triggerSearchState) {
            triggersearch();
            setTriggerSearchState(false);
        }
    }, [triggerSearchState]);

    const Loading = async (url = null) => {
        if (!url) return
        try {
            setLoading(true)

            const response = await callApiAsync(auth, url)
            if (response.status === 200)
                if (response.data.OBJECT) {
                    setResult(response.data.OBJECT)
                    setResultFiltered(response.data.OBJECT)
                } else {
                    setResult(response.data)
                    setResultFiltered(response.data)
                }

            setLoading(false)
        }
        catch (error) {
            setError(error)
            setLoading(false)
        }
    }

    const handleChange = async (e) => {
        let newValue = e.target.value
        onChangeInputText(newValue);

        if (!newValue && newValue !== "0") {
            // setResult([])
            setResultFiltered([])
        } else {
            if (
                // api url ist bekannt
                getUrlBySearchText
            ) {
                if (
                    // soll Suche durch Leerzeichen oder Mindestzahl an Zeichen getriggert werden?
                    searchAfterChar !== -1 || searchAfterSpace
                ) {
                    if (// falls Mindestzahl erreicht oder ein leerzeichen eingegeben wurde
                        newValue.length >= searchAfterChar
                        ||
                        newValue[newValue.length - 1] === " ") {
                        triggerSearchTimeout(filterSearchText(newValue));
                    }
                } else {
                    triggerSearchTimeout(filterSearchText(newValue));
                }
            }
        }

        setSearchText(newValue)
    }

    const triggerSearchTimeout = (val) => {
        if (searchAfterDelay === 0) {
            triggersearch(val);
        } else if (searchAfterDelay > 0) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                triggersearch(val);
            }, searchAfterDelay);
        }
    }

    const triggersearch = (val) => {
        if (getUrlBySearchText) {
            if (val && val.length) {
                Loading(getUrlBySearchText(val));
            } else if (searchText.length) {
                Loading(getUrlBySearchText(filterSearchText(searchText)));
            }
        }
    }

    const handleKeyPressed = (e) => {
        if (e.charCode === 13) {
            triggersearch();
        }
    }

    const handleFocus = () => {
        // if (placeholder !== "" && searchText === placeholder)
        //     setSearchText("")
        // setStyle(styleFocus)
    }

    const handleBlur = () => {
        // if (placeholder !== "" && searchText === "") {
        //     setSearchText(placeholder)
        //     setStyle(styleBlur)
        // }
    }

    const handleSelect = (t = null, r = undefined) => {
        setSearchText(t)
        if (r !== undefined) {
            setResultFiltered(r)
        }
    }

    return { inputProps: { placeholder:placeholder, onBlur: handleBlur, style: style, onChange: handleChange, onKeyPress: handleKeyPressed, onFocus: handleFocus, value: searchText }, value: searchText, handleselect: handleSelect, result: resultFiltered, loading: loading, error: error, triggersearch: () => setTriggerSearchState(true), reset: () => {setResult(defaultResult); setResultFiltered(defaultResult)}, setValue: setSearchText }
}
