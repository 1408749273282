import React, {useEffect, useState} from 'react';
import {List, notification, Input} from "antd";
import {Tooltip} from "../atoms/Tooltip";
import {defaultMedikationsplan, getUnitLangname} from "../../config/entities";
import moment from "moment";
import {AkteurPicker} from "../atoms/AkteurPicker";
import de from "date-fns/locale/de";
import DatePicker from "react-datepicker";
import {akteureApi, medikationsplanApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {FileAddOutlined, FileTextOutlined} from "@ant-design/icons";
import {Popover} from "../atoms/Popover";

const DateiListePopover = ({ dataSource, listProps, itemProps, onClickItem = item => {}, itemContent = datei => <h6>{datei.dokument.titel || datei.dokument.dateiname}</h6>, itemPopoverSource = datei => datei }) => {
    return (
        <List
            dataSource={dataSource}
            renderItem={datei => {
                return (
                    <Popover content={<DateiListePopoverTable datei={itemPopoverSource(datei)} />} placement={"leftTop"}>
                        <List.Item {...itemProps} onClick={() => onClickItem(datei)} style={{cursor: "pointer"}} className={"datei-liste-item"}>
                            {itemContent(datei)}
                        </List.Item>
                    </Popover>
                )
            }}

            {...listProps}
        />
    );
};

const DateiListePopoverTable = ({ datei, tableProps, edit=false, editState={}, editable=false, handleChange=(key, value)=>{} }) => {
    const auth = useAuth();

    const [medikationsplan, setMedikationsplan] = useState({});

    useEffect(() => {
        if (datei && datei.verknuepfungen) {
            datei.verknuepfungen.filter(v => v.typ === "PATIENT").forEach(v => {
                const patient = v.unit;
                if (!medikationsplan[patient.id]) fetchFirstMedikationsplan(patient);
            });
        }
    }, [datei])

    const fetchFirstMedikationsplan = async patient => {
        const response = await callApiAsync({auth, url: medikationsplanApi.getByFilter({patientId: patient.id})});
        const medikationsplanListe = response.data.OBJECT;

        if (medikationsplanListe && medikationsplanListe.length) setMedikationsplan(prev => {
            const neu = {...prev};
            neu[patient.id] = medikationsplanListe[0];
            return neu;
        });
        else setMedikationsplan(prev => {
            const neu = {...prev};
            delete neu[patient.id];
            return neu;
        });
    }

    const bearbeitung = datei && datei.bearbeitungen && datei.bearbeitungen.length ? datei.bearbeitungen.sort((a, b) => {
        if (a.datumBearbeitungAbgeschlossen && !b.datumBearbeitungAbgeschlossen) return 1;
        if (!a.datumBearbeitungAbgeschlossen && b.datumBearbeitungAbgeschlossen) return -1;
        if (!a.datumBearbeitungAbgeschlossen && !b.datumBearbeitungAbgeschlossen) return 0;
        return b.datumBearbeitungAbgeschlossen - a.datumBearbeitungAbgeschlossen;
    })[0] : null;

    const erstelleMedikationsplan = async patient => {
        const response = await callApiAsync({auth, url: medikationsplanApi.post(), data: defaultMedikationsplan(patient), method: 'POST'});
        const medikationsplan = response.data.OBJECT;

        oeffneMedikationsplan(medikationsplan);
        setMedikationsplan(prev => {
            const neu = {...prev};
            neu[patient.id] = medikationsplan;
            return neu;
        });
        notification.info({message: "Der Medikationsplan wurde erfolgreich angelegt"});
    }

    const oeffneMedikationsplan = (medikationsplan) => {
        if (medikationsplan) {
            window.open(`/medikationsplan/${medikationsplan.id}`, "parent-default");
        }
    }

    return (
        (datei && <div>
            {datei.dokument.titel ? <h6>{datei.typ ? datei.typ : datei.dokument.dateiname}</h6> : ""}
            <table className={"datei-liste-popover-table"} {...tableProps} >
                <tbody>
                    <tr>
                        <td>Dateiname</td>
                        <td>{datei.dokument.dateiname}</td>
                    </tr>
                    <tr>
                        <td>Dokument-Typ</td>
                        <td>{edit ? "TODO" : datei.typ}</td>
                    </tr>
                    <tr>
                        <td>Patient</td>
                        <td>
                            {datei.verknuepfungen.filter(v => v.typ === "PATIENT").map(verknuepfung => <div key={verknuepfung.id}>{edit ? "TODO" : <>
                            {medikationsplan[verknuepfung.unit.id] ? <Tooltip title={"Medikationsplan öffnen"}><FileTextOutlined onClick={() => oeffneMedikationsplan(medikationsplan[verknuepfung.unit.id])}/></Tooltip> : <Tooltip title={"Neuen Medikationsplan erstellen und öffnen"}><FileAddOutlined onClick={() => erstelleMedikationsplan(verknuepfung.unit)} style={{cursor: "pointer"}} /></Tooltip>}
                                {getUnitLangname(verknuepfung.unit)}
                            </>}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td>Pflegeheim</td>
                        <td>
                            {datei.verknuepfungen.filter(v => v.typ === "PFLEGEHEIM").map(verknuepfung => <div>{edit ? "TODO" : getUnitLangname(verknuepfung.unit)}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td>Verordner</td>
                        <td>
                            {datei.verknuepfungen.filter(v => v.typ === "VERORDNER").map(verknuepfung => <div>{edit ? "TODO" : getUnitLangname(verknuepfung.unit)}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td>Quelle</td>
                        <td>
                            {datei.verknuepfungen.filter(v => v.typ === "QUELLE").map(verknuepfung => <div>{edit ? "TODO" : getUnitLangname(verknuepfung.unit)}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td>Erstellt</td>
                        <td>{edit ? <DatePicker
                                locale={de} todayButton="Heute" dateFormat="E dd. MMM yyyy"
                                selected={editState.datumErstellung || datei.datumErstellung}
                                onChange={val => handleChange("datumErstellung", val)}
                            /> : datei.datumErstellung ? moment(datei.datumErstellung).format("dd, DD.MM.YYYY") : "-"}</td>
                    </tr>
                    <tr>
                        <td>Eingegangen</td>
                        <td>{edit ? <DatePicker
                            locale={de} todayButton="Heute" dateFormat="E dd. MMM yyyy"
                            selected={editState.datumEingang || datei.datumEingang}
                            onChange={val => handleChange("datumErstellung", val)}
                        /> : datei.datumEingang ? moment(datei.datumEingang).format("dd, DD.MM.YYYY") : "-"}</td>
                    </tr>
                    <tr>
                        <td>Hochgeladen</td>
                        <td>{datei.dokument.timeUploaded ? moment(datei.dokument.timeUploaded).format("dd, DD.MM.YYYY") : "-"}</td>
                    </tr>
                    <tr>
                        <td>Bearbeitet</td>
                        <td>{bearbeitung && bearbeitung.datumBearbeitungAbgeschlossen ? `${moment(bearbeitung.datumBearbeitungAbgeschlossen).format("dd, DD.MM.YYYY HH:mm")} (${bearbeitung.id.unit.name})` : "-"}</td>
                    </tr>
                </tbody>
            </table>
        </div>)
    )
}

export default DateiListePopover;

export { DateiListePopoverTable }