import React from 'react'

export const EINNAHMEZEITEN_FUENFFACH = [
    { "_id": "0", "zeitfenster": { "von": "00:00:00", "bis": "07:59:59", "default": "06:00:00" }, "bezeichner": "nüchtern", "icon": "nüchtern.png" },
    { "_id": "1", "zeitfenster": { "von": "08:00:00", "bis": "11:59:59", "default": "08:00:00" }, "bezeichner": "morgens", "icon": "morgens.png" },
    { "_id": "2", "zeitfenster": { "von": "12:00:00", "bis": "15:59:59", "default": "12:00:00" }, "bezeichner": "mittags", "icon": "mittags.png" },
    { "_id": "3", "zeitfenster": { "von": "16:00:00", "bis": "19:59:59", "default": "18:00:00" }, "bezeichner": "abends", "icon": "abends.png" },
    { "_id": "4", "zeitfenster": { "von": "20:00:00", "bis": "23:59:59", "default": "22:00:00" }, "bezeichner": "zur Nacht", "icon": "zurnacht.png" }
];

export const EINNAHMEZEITEN_UHRZEITEN = [
    {
        exportValue: "10:00:00",
        bezeichnung: "10 Uhr",
    },
    {
        exportValue: "10:30:00",
        bezeichnung: <i>10:30</i>
    },
    {
        exportValue: "11:00:00",
        bezeichnung: "11 Uhr"
    },
    {
        exportValue: "12:00:00",
        bezeichnung: "12 Uhr"
    },
    {
        exportValue: "13:00:00",
        bezeichnung: "13 Uhr"
    },
]

// Wieviele Wochen soll maximal auf Reichweite geprüft werden
export const REICHWEITE_PRUEFUNG = 8;

// Globale Mindest-Reichweite. Bis hierhin wird Bedarf ermittelt
export const REICHWEITE_MINIMAL= 5;

// Bei einer Reichweite kleiner als dieser Wert wird der Benutzer über den Bestand informiert und eine Rezeptanforderung vorgeschlagen
export const REICHWEITE_ALARM = 2;







// todo ausarbeiten
const DARREICHUNGS_FORM = [
    {
        bezeichnung: "flüssige From",
        defaultEH: "ml",
    },
    {
        bezeichnung: "feste From",
        defaultEH: "St",
    }
];

