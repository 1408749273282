import React from "react";

export const nl2br = (string, configMap={}) => {
    if (!string) return null;

    const style = {};
    if (configMap.minWidth) style.minWidth=configMap.minWidth;
    return string.split('\n').map((item, i, arr) => <span key={i} style={style}>{item}{i < arr.length-1 && <br/>}</span>);
}

export const splitAtLength = (string, regex=' ', len=0) => {
    if (len < 1) return [string];

    const split = string.split(regex);
    const stringList = [];
    let joinedString;
    for (let string of split) {
        joinedString = joinedString ? `${joinedString} ${string}` : string;
        if (joinedString.length > len) {
            stringList.push(joinedString);
            joinedString = null;
        }
    }
    if (joinedString) stringList.push(joinedString);

    return stringList;
}

export function getRounded(input) {
    return Math.round(Number(input) * 100000) / 100000
}