import { useEffect, useReducer, useContext, useCallback } from 'react'
import diff from 'deep-diff';
import * as jsonpatch from 'fast-json-patch';

import { workloadReducer, SET_DATA, SET_LOADING, SET_ERROR, LOAD_FROM_CACHE, UPDATE_CURRENT, UPDATE_ARRAY_ELEMENT, SET_SAVING, UPDATE_CACHE } from './myReducer';
import { useAuth } from './useAuth';

import { GlobalContext } from '../config/globalContext';
import {callApiAsync} from "./apiUtil";



/*
 Custom Hook um Zugriff auf Daten mittels API, Vererbung oder Cache zu ermögichen
 */
export const useDataAccess = ({
    id = null,
    value = null,
    context = null,
    getAll = false,
    filter = null,
    onChange = () => { },
    handleSorting = null,
    prePostMapper = data => data,
    ...props
}, apiCall) => {

    // Initialisierung
    const auth = useAuth();
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(workloadReducer, {
        isLoading: false,
        isSaving: false,
        current: null,
        last: null,
        updated: false,
        error: null
    });

    const getCacheId = () => {
        if (id !== null) {
            // console.log("its the id ", id)
            return id;
        }
        else if (filter && filter !== {}) {
            let result = JSON.stringify(filter).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            if (result !== "") {
                // console.log("its the filter ", filter, result)
                return result;
            }
        }

        if (globalContext[context] && globalContext[context].state && globalContext[context].state.CACHE_HISTORY) {
            let result = globalContext[context].state.CACHE_HISTORY;
            // console.log("its the history ", result)
            return result;
        }

        // console.log("didnt find anything for ", context)
        return null;
    };

    const memoizedCallback = useCallback(
        () => {
            console.log("useCallback call API.getByFilter: ", filter, apiCall.getByFilter(filter));
            loadFromApi(apiCall.getByFilter(filter));
        },
        [filter],
    );

    useEffect(() => {
        // Komponente hat bereits explizite Werte erhalten
        if (value) {
            dispatch({ type: SET_DATA, payload: value, handleSorting: handleSorting });
            return;
        }


        let cacheId = getCacheId();

        // daten sind im cache
        if (
            globalContext[context] &&
            globalContext[context].state &&
            globalContext[context].state[cacheId]
        ) {
            console.log("reading context: ", context, cacheId);
            dispatch({ type: LOAD_FROM_CACHE, payload: globalContext[context].state[cacheId], handleSorting: handleSorting });
        }

        // lade bestimmtes Objekt per API
        else if (id !== null && apiCall && apiCall.getById) {
            if (state.isLoading || state.isSaving) return;
            console.log("call API.getById: ", apiCall.getById(id));
            loadFromApi(apiCall.getById(id));
        }

        // lade Collection per API
        else if (filter !== null && apiCall && apiCall.getByFilter) {
            if (state.isLoading || state.isSaving) return;
            memoizedCallback();
        }

    }, [value, id, filter]);



    // speichert Workload im Cache wenn sich dieser ändert
    useEffect(() => {
        let cacheId = getCacheId();

        // noch kein aktueller state definiert
        if (!state || state.isLoading || !state.current) return;

        // Keine Cache definiert oder kein cacheId des Objektes
        if (!cacheId || globalContext === null || globalContext[context] === null)
            return;

        // Life-Cycle: onUnmount
        return () => {
            if (!globalContext[context] || !globalContext[context].dispatch) return;
            if (globalContext[context].state && !diff(state, globalContext[context].state[cacheId]))
                return;

            //console.log("writing context", context, cacheId, state)
            globalContext[context].dispatch({ type: UPDATE_CACHE, payload: state, cacheId });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);


    // asynchroner API call
    // todo bzgl. response.status usw. anpassen, wenn Wildfly REST API entsprechend umgestellt wurde
    const loadFromApi = (url) => {

        dispatch({ type: SET_LOADING, payload: true });

        callApiAsync({auth, url})
            .then(response => {
                if (response.status === 200 && response.data.RESULT === "SUCCESS")
                    dispatch({ type: SET_DATA, payload: response.data.OBJECT, handleSorting: handleSorting });
                else
                    dispatch({ type: SET_ERROR, payload: { ...response } });
            })
            .catch(error => {
                if (error instanceof Error) {
                    dispatch({ type: SET_ERROR, payload: { fehler: error.message } });
                } else {
                    dispatch({ type: SET_ERROR, payload: { ...error } });
                }
            });

    };

    // todo muss auf aktuelle API umgestellt werden (aktuell keine ID des eingeloggten Benutzers, JSON als String speichern?)
    const saveDeltaByApi = async (id, delta) => { };



    const handleSave = () => {
        return new Promise((resolve, error) => {
            if (state.current === null) return;
            //        dispatch({ type: SET_SAVING, payload: true })
            console.info("useDataAcess.save");

            // Workload ist ein Array, also durchlaufen wir es und suchen Änderungen
            if (Array.isArray(state.current))
                state.current.map(
                    async (current, index) => {

                        const delta = diff(state.last[index], current);
                        const data = prePostMapper(current);
                        if (current !== state.last[index] && delta !== undefined) {

                            // bestehendes element im array wurde geändert
                            if (current.id) {

                                console.log(apiCall.put(current.id));
                                console.log("data: " + JSON.stringify(data));

                                const response = await callApiAsync({auth, url: apiCall.put(current.id), data, method: "put"});



                                dispatch({ type: UPDATE_ARRAY_ELEMENT, payload: response.data.OBJECT, index: index, handleSorting: handleSorting });
                                resolve(response.data.OBJECT);

                                //Todo save history
                                //saveDeltaByApi(current.id, delta)
                            }

                            // neues element wurde collection zugeordnet
                            else if (filter) {
                                const response = await callApiAsync({auth, url: apiCall.post(filter), data, method: "post"});
                                console.log(response);
                                if (response)
                                    dispatch({ type: UPDATE_ARRAY_ELEMENT, payload: response.data.OBJECT, index: index, handleSorting: handleSorting });

                                resolve(response.data.OBJECT);

                                //Todo save history
                                //saveDeltaByApi(current.id, delta)
                            }
                        }

                    }
                );


            // Workload ist Objekt und kein Array
            else {
                if (state.current.id) {
                    const data = prePostMapper(state.current);

                    console.info("api Call for Object: ", apiCall.put(state.current.id));
                    console.info("data:", JSON.stringify(data));

                    callApiAsync({auth, url: apiCall.put(state.current.id), data, method: "put"})
                        .then(response => {
                            if (response.status === 200 && response.data.RESULT === "SUCCESS") {
                                dispatch({type: SET_DATA, payload: response.data.OBJECT, handleSorting: handleSorting});
                                resolve(response.data.OBJECT);
                            } else {
                                dispatch({type: SET_ERROR, payload: {...response}});
                                error();
                            }
                        })
                        .catch(error => {
                            if (error instanceof Error) {
                                dispatch({type: SET_ERROR, payload: {fehler: error.message}});
                            } else {
                                dispatch({type: SET_ERROR, payload: {...error}});
                            }

                            error();
                        });

                } else {
                    const data = prePostMapper(state.current);

                    console.info("api Call for Object: ", apiCall.post());
                    console.info("data:", JSON.stringify(data));

                    callApiAsync({auth, url: apiCall.post(), data, method: "post"})
                        .then(response => {
                            if (response.status === 200 && response.data.RESULT === "SUCCESS") {
                                dispatch({type: SET_DATA, payload: response.data.OBJECT, handleSorting: handleSorting});
                                resolve(response.data.OBJECT);
                            } else {
                                dispatch({type: SET_ERROR, payload: {...response}});
                                error();
                            }
                        })
                        .catch(error => {
                            if (error instanceof Error) {
                                dispatch({type: SET_ERROR, payload: {fehler: error.message}});
                            } else {
                                dispatch({type: SET_ERROR, payload: {...error}});
                            }

                            error();
                        });
                }
            }
        });
    };




    const handleChange = (name, payload, operation) => {
        let newCurrent = state.current;
        if (name === null)
            newCurrent = payload;
        else
            newCurrent = patchJSON(newCurrent, name, payload, operation);
        dispatch({ type: UPDATE_CURRENT, payload: newCurrent, handleSorting: handleSorting });
        onChange(newCurrent);
    };

    const handleChangeBatch = (payloadArray, operation) => {
        let newCurrent = state.current;
        for (let load of payloadArray) {
            newCurrent = patchJSON(newCurrent, load.name, load.payload, operation);
        }
        dispatch({ type: UPDATE_CURRENT, payload: newCurrent, handleSorting: handleSorting });
        onChange(newCurrent);
    };

    const handleReset = () => {
        dispatch({ type: SET_DATA, payload: state.last, handleSorting: handleSorting });
        onChange(state.last);
    };

    const handleReload = () => {
        if (filter && apiCall && apiCall.getByFilter) {
            loadFromApi(apiCall.getByFilter(filter));
        } else if (id && apiCall && apiCall.getById) {
            loadFromApi(apiCall.getById(id));
        }
    };

    const handleDelete = async (id = null) => {
        if (id === null) return;
        try {
            dispatch({ type: "SET_LOADING", payload: true });
            const response = await callApiAsync({auth, url: apiCall.delete(id), method: "delete"});
            if (response.status === 200)
                dispatch({ type: "REMOVE_ARRAY_ELEMENT", payload: id, handleSorting: handleSorting });
            dispatch({ type: "SET_LOADING", payload: false });
        }
        catch (error) {
            console.log(error);
            dispatch({ type: "SET_ERROR", payload: { ...error } });
        }
    };



    return {
        isReady: (!state.error && !state.isLoading && !state.isSaving && state.current !== null),
        isLoading: state.isLoading,
        isSaving: state.isSaving,
        error: state.error,
        updated: state.updated,
        current: state.current,
        last: state.last,

        handleChange,
        handleSave,
        handleReset,
        handleReload,
        handleDelete,
        handleChangeBatch,
    };
};



export const patchJSON = (state, name, value, operation = "replace") => {
    return jsonpatch.applyOperation(
        Array.isArray(state) ? [...state] : { ...state },
        {
            op: operation,
            path: "/" + name,
            value: value
        }
    ).newDocument;
};
