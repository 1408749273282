import React, {useContext} from 'react'

import { Table, Tag, Button, Divider } from 'antd';
import { getUnitLangname } from '../../config/entities';

import moment from 'moment';
import NachrichtenContext from "../../contexts/NachrichtenContext";
import {useTableSearchFilter} from "../../utilities/useTableSearchFilter";
import {getSorter} from "../../utilities/sortUtil";
import usePagination from "../../utilities/usePagination";


export const NachrichtenListe = ({ nachrichten, loading }) => {
    const nachrichtenContext = useContext(NachrichtenContext);
    const [selectedRows, setSelectedRows] = React.useState([])

    const {getColumnSearchProps, getColumnDateProps} = useTableSearchFilter();

    const columns = [
        {
            title: 'Datum',
            dataIndex: 'versendeDatum',
            key: 'versendeDatum',
            render: (e, row) => {
                if (!e) e = row.erstellungsDatum;
                return <span><b>{moment(e).format('DD.MM.YYYY')}</b><br /><i>{moment(e).format('HH:mm:ss')}</i></span>;
            },

            sorter: getSorter("nachricht"),
            ...getColumnDateProps("versendeDatum"),
            defaultSortOrder: "descend",
        },
        {
            title: 'Versender',
            key: 'versender',
            render: row => getUnitLangname(row.versender),

            sorter: getSorter("nachricht", "versender"),
            ...getColumnSearchProps({dataIndex: "versender", onFilter: (value, record) => getUnitLangname(record.versender).toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'senderUID',
            dataIndex: 'senderUID',
            key: 'senderUID',

            sorter: getSorter("nachricht", "senderUID")
        },
        {
            title: 'Konto',
            dataIndex: 'konto',
            key: 'konto',
            render: konto => !konto ? <i>unbekannt</i> : konto.bezeichner,

            sorter: getSorter("nachricht", "konto"),
            ...getColumnSearchProps({dataIndex: "konto", onFilter: (value, record) => record.konto && record.konto.bezeichner.toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Typ',
            key: 'nachrichtenTyp',
            dataIndex: 'nachrichtenTyp',
            render: tag => <span>{
                tag && <Tag color='volcano' key={tag}>
                    {tag.toUpperCase()}
                </Tag>
            }</span>
        },
    ];
    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRows)
    };

    const footer = () => {
        if (setSelectedRows && selectedRows.length)
            return <div>
                <Button>Nachrichten löschen</Button>
                <Divider type="vertical" />
                <Button>Als ungelesen markieren</Button>
            </div>
        else
            return <></>
    }




    return <div>
        <Table
            columns={columns}
            dataSource={nachrichten}
            rowKey={nachricht => nachricht.id}
            loading={loading}
            rowSelection={{ ...rowSelection }}
            footer={footer}

            onRow={(nachricht, index) => {
                return {
                    style: {cursor: "pointer"},

                    onClick: (e) => nachrichtenContext.setGewaehlteNachricht(nachricht)
                }
            }}

            pagination={usePagination()}
        />
        {/* <pre>{JSON.stringify(nachrichten, null, 2)}</pre> */}
    </div>


}


