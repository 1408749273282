import React, {createContext, useEffect, useRef, useState} from "react";

const initVal = {
    activeId: "",
    setActiveId: id=>{},

    openWindows: []
}

const WindowContext = createContext(initVal)
export default WindowContext;

export const useInitialState = () => {
    const workerPort = useRef();

    const [activeId, setActiveId] = useState(null);
    const [openWindows, setOpenWindows] = useState([]);

    return {
        activeId,
        setActiveId,

        openWindows,
    }
}