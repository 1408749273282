import {dateStringToTimestamp, startOfDay} from "../utilities/myDates";
import '../config/typeDefinition'
import {BASE_URL} from "./apiConfig";


/**
 * @category Config
 * @type {DataNodeConfiguration}
 */
const medikationsplanNode = {

    api: {
        getById: (id) => id ? BASE_URL + 'medikationsplan/' + id : null,
        put: (id) => BASE_URL + 'medikationsplan/' + id,
        post: () => BASE_URL + 'medikationsplan/',
        delete: (id) => BASE_URL + 'medikationsplan/' + id,
        getByFilter: (filterArray) => {
            let url = BASE_URL + 'medikationsplan/?';
            for (var filter in filterArray)
                url += filter.key + '=' + filter.value;
            return url
        },
    }

}

/**
 * @category Config
 * @type {DataNodeConfiguration}
 */
const planzeilenNode = {

    api: {
        getByParent: (parentKey) => !parentKey ? null : BASE_URL + 'medikationsplan/' + parentKey + '/planzeilen',
        postByParent: (parentKey) => !parentKey ? null : BASE_URL + 'medikationsplan/' + parentKey + '/planzeilen',

        getById: (id) => BASE_URL + 'planzeile/' + id,
        put: (id) => BASE_URL + 'planzeile/' + id,
        post: () => BASE_URL + 'planzeile/',
        delete: (id) => BASE_URL + 'planzeile/' + id,

        getByFilter: (filterArray) => {
            if (!filterArray) return null
            if (filterArray.planId) {
                return BASE_URL + 'medikationsplan/' + filterArray.planId + '/planzeilen';
            }
        },
    },

    defaultNew: {
        freitext: "",
        dosierschema: [{
            start: dateStringToTimestamp(startOfDay(new Date())),
            imBlister: false,
            wiederholungsTag: 1,
            abgabemuster: [{
                tag: 1,
                zeiten: []
            }]
        }],
        lagerort: "APOTHEKE",
    }
}



/**
 * @category Config
 * @type {DataNodeConfiguration}
 */
const akteureNode = {

    api: {
        getById: (id) => id ? BASE_URL + 'medikationsplan/' + id : null,
        put: (id) => BASE_URL + 'medikationsplan/' + id,
        post: () => BASE_URL + 'medikationsplan/',
        delete: (id) => BASE_URL + 'medikationsplan/' + id,
        getByFilter: (filterArray) => {
            let url = BASE_URL + 'medikationsplan/?';
            for (var filter in filterArray)
                url += filter.key + '=' + filter.value;
            return url
        },
    }

}



const bestandNode = {

    api: {
        getByParent: (parentKey) => !parentKey ? null : BASE_URL + `bestand/unit/${parentKey}`,

        getById: (id) => BASE_URL + 'bestand/' + id,
        put: (id) => BASE_URL + 'bestand/' + id,
        post: () => BASE_URL + 'bestand/',
        delete: (id) => BASE_URL + 'bestand/' + id,
    }
}


export { medikationsplanNode, planzeilenNode, akteureNode, bestandNode }
