import React, {useState, useEffect, useContext, useRef} from 'react'

import moment from 'moment';

import {StatusDisplay, useDataNode} from '../../utilities/useDataNode';

import {PlanzeileArzneimittel, EditArzneimittel} from './PlanzeileArzneimittel';
import {Dosierschema} from './Dosierschema';
import {EditDosierschema, handleSmartEditGlobal} from './EditDosierschema';

import {Button, Row, Col, Popconfirm, notification, Select, Modal, Menu, Checkbox, Input} from 'antd';
import {Popover} from "../atoms/Popover";

import {Reichweite} from './Reichweite';
import {EditVerordner} from './EditVerordner';
import "../../style/global.css";
import ExportOutlined from "@ant-design/icons/lib/icons/ExportOutlined";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import WarningOutlined from "@ant-design/icons/lib/icons/WarningOutlined";
import {EyeOutlined, LinkOutlined, SettingOutlined} from "@ant-design/icons";
import useDebouncedClickCounter from "../../utilities/useDebouncedClickCounter";
import {callApiAsync} from "../../utilities/apiUtil";
import {fetchInfomarkerByPlanzeile} from "../../config/fetchApiConfiguration";
import {useAuth} from "../../utilities/useAuth";
import DokumenteContext from "../../contexts/DokumenteContext";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {
    arzneimittelApi,
    dokumenteApi,
    infoMarkerApi,
    notizApi,
    planzeilenApi,
    verblisterungApi
} from "../../config/apiConfig";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import TagOutlined from "@ant-design/icons/lib/icons/TagOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getUnitLangname} from "../../config/entities";
import {useFetch} from "../../utilities/useFetch";
import {LabeledSwitch} from "../atoms/LabeledSwitch";
import {equalsDeep} from "../../utilities/collectionUtil";
import {Einnahmehinweise} from "./EditEinnahmehinweise";
import {Tooltip} from "../atoms/Tooltip";
import {getSorter} from "../../utilities/sortUtil";
import {GlobalContext} from "../../config/globalContext";
import useDateiVorschau from "../../utilities/useDateiVorschau";
import {FormLabel} from "react-bootstrap";
import {getPostBody} from "../atoms/InfomarkerButton";
import {postInfomarker} from "../../config/postApiConfiguration";
import notizContext from "../../contexts/NotizContext";
import NotizContext from "../../contexts/NotizContext";
import {ObserveKeys} from "react-hotkeys";
import {useApi} from "../../utilities/useApi";

const {Option} = Select;

export const MedikationsplanZeile = ({
                                         style = {},
                                         value,
                                         onChange,
                                         handleSave = null,
                                         triggerSave = false,
                                         onDelete = null,

                                         hausarzt = null,

                                         abschnittFilter = null,
                                         report = null,

                                         reihenfolge = null,
                                         setReihenfolgeIndex = (planzeileId, indexNeu) => {
                                         },
                                         setReihenfolgeArzneimittel = (planzeileId, arzneimittelNeu, faktorArzneimittel) => {
                                         },

                                         setDragPlanzeileId = () => {},
                                         setDropPlanzeileId = () => {},

                                         openEditor,
                                         setOpenEditor,
                                         isFavoritenAnsicht = false,

                                         updated,
                                         setUpdated,

                                         scrollTo = () => {
                                         },
                                         kontrollModus,

                                         setTriggerAnspruchReloadAfterSave = () => {
                                         },
                                         setTriggerBestandReloadAfterSave = () => {
                                         },

                                         triggerFreigabe,
                                         addPlanzeile = () => {},
                                         abgabeplanNeuBerechnen = async () => {}
                                     }) => {

    // const [editor, setEditorState] = useState("dosierschema")
    const [infomarkerAktuell, setInfomarkerAktuell] = useState([]);
    const [editor, setEditorState] = useState(null)
    const [editorChanged, setEditorChanged] = useState(false);
    const [editorSaved, setEditorSaved] = useState({});
    const [triggerSaveEditor, setTriggerSaveEditor] = useState(false);

    const [toggleSaveState, toggleSave] = useState(false)
    const [debouncedDoubleClickCounter, debounceDoubleClick] = useDebouncedClickCounter(300, 2, true);
    const [doubleClickElement, setDoubleClickElement] = useState(null);
    const [isArzneimittelAnsicht, setIsArzneimittelAnsicht] = useState(false);
    const [kontrolliert, setKontrolliert] = useState(false);

    const [alternativeArzneimittel, setAlternativeArzneimittel] = useState({});
    const [zeigeEinstellungen, setZeigeEinstellungen] = useState(false);

    const [reihenfolgePlanzeile, setReihenfolgePlanzeile] = useState({});
    const [freigabeMitDosierabschnitten, setFreigabeMitDosierabschnitten] = useState(true);
    const [freigabePopoverVisible, setFreigabePopoverVisible] = useState(false);

    const [planzeileAlt, setPlanzeileAlt] = useState(null);
    const [planzeileJson, setPlanzeileJson] = useState({});
    const planzeilePrev = useRef(null);

    const [isChecked, setIsChecked] = useState(false);

    const [transferInfomarker, setTransferInfomarker] = useState([]);
    const [entitlementSet, setEntitlementSet] = useState(null);

    const [settingsChange, setSettingsChange] = useState({});

    const [faktorArzneimittelManuell, setFaktorArzneimittelManuell] = useState(0);
    const [arzneimittelChangeManuell, setArzneimittelChangeManuell] = useState(null);

    const [hatDosierabschnittOhneFreigabe, setHatDosierabschnittOhneFreigabe] = useState(false);

    const runAfterUpdate = useRef(null);
    const runAfterSave = useRef(null);

    const api = useApi();
    const auth = useAuth();
    const {openDokument} = useDateiVorschau();

    const verordnerLast = useRef();
    const planzeileLast = useRef();
    const planzeileRef = useRef();
    const handleSmartEditDebounceTimeout = useRef(null);

    const dokumenteContext = useContext(DokumenteContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const notizContext = useContext(NotizContext);
    const globalContext = useContext(GlobalContext);

    const dragEnterCounter = useRef(0);

    const setEditor = (newEditState) => {
        if (isFavoritenAnsicht) return;

        setEditorState(newEditState);
        if (newEditState !== openEditor) setOpenEditor(newEditState);

        if (["Alle", "Dosierschema"].includes(newEditState)) {
            setZeigeEinstellungen(true);
        } else {
            setZeigeEinstellungen(false);
        }
    }

    const {
        current: planzeile,
        status,
        handleChange,
        handleChangeMultiple,
        handleLoad,
    } = useDataNode({
        value,
        onChange
    })

    const {
        data: infoMarker,
        reload: reloadInfoMarker,
        update: updateInfoMarker,
        cancel: cancelInfomarker,
    } = useFetch(fetchInfomarkerByPlanzeile(planzeile.id))
    const [infomarkerContext, setInformarkerContext] = useState([]);

    useEffect(() => {
        if (triggerFreigabe) {
            handleFreigabe();
        }
    }, [triggerFreigabe])

    useEffect(() => {
        if (faktorArzneimittelManuell && arzneimittelChangeManuell) {
            (async () => {
                await handleArzneimittelChange(arzneimittelChangeManuell, faktorArzneimittelManuell);
                setFaktorArzneimittelManuell(0);
                setArzneimittelChangeManuell(null);
            })();
        }
    }, [faktorArzneimittelManuell, arzneimittelChangeManuell])

    useEffect(() => {
        const settingsChangedKeys = Object.keys(settingsChange);
        if (settingsChangedKeys.length) {
            if (
                Object.keys(editorSaved).length ||
                (!zeigeEinstellungen && !editor)
            ) {
                const handleChangeArray = handleSetFreigabe(false);
                for (let key of settingsChangedKeys) {
                    handleChangeArray.push([settingsChange[key], key]);
                }

                handleChangeMultiple(handleChangeArray);
                setSettingsChange({});
            }
        }

        if (!editor) {
            if (medikationsplanContext.oeffneDosierabschnitt) medikationsplanContext.setOeffneDosierabschnitt(null);
            if (medikationsplanContext.oeffneDosierabschnittPlanzeileId) medikationsplanContext.setOeffneDosierabschnittPlanzeileId(null);
        }
    }, [editorSaved, settingsChange, zeigeEinstellungen, editor])

    useEffect(() => {
        if (infoMarker) {
            medikationsplanContext.setInfoMarkerMap(prev => {
                const neu = {...prev};
                neu[planzeile.id] = infoMarker;

                return neu;
            });
        }
    }, [infoMarker])

    useEffect(() => {
        if (medikationsplanContext.infoMarkerMap?.[planzeile.id]) {
            setInformarkerContext(medikationsplanContext.infoMarkerMap[planzeile.id]);
        } else {
            setInformarkerContext([]);
        }
    }, [medikationsplanContext.infoMarkerMap])

    const openFirstInfoMarker = infoMarkerArray => {
        if (infoMarkerArray && infoMarkerArray.length) {
            const infoMarker = infoMarkerArray[0];
            const dokument = infoMarker.dokument;

            const windowNameForDokumentId = dokumenteContext.getWindowNameForDokumentId(dokument.id);
            if (windowNameForDokumentId) {
                dokumenteContext.setHighlightInfoMarker(infoMarker);
            } else {
                openDokument(dokument.id, auth, infoMarker.id ? `?im=${infoMarker.id}` : '');
            }
        }
    }

    useEffect(() => {
        return () => {
            cancelInfomarker();
        }
    }, [])

    useEffect(() => {
        if (status.ready) {
            verordnerLast.current = planzeile.verordner;
        }
    }, [status])

    useEffect(() => {
        const dosierabschnittAktuell = medikationsplanContext.dosierabschnittAktuell[planzeile.id];

        if (dosierabschnittAktuell && medikationsplanContext.isStandardAnsicht) {
                const alternativeArzneimittelNeu = {};
                dosierabschnittAktuell.abgabemuster.forEach(muster => muster.zeiten.forEach(zeit => {
                    if (zeit.arzneimittel) alternativeArzneimittelNeu[zeit.arzneimittel.id] = zeit.arzneimittel;
                }))

                setAlternativeArzneimittel(alternativeArzneimittelNeu);
        } else {
            setAlternativeArzneimittel({});
        }
    }, [medikationsplanContext.dosierabschnittAktuell, medikationsplanContext.isStandardAnsicht])

    useEffect(() => {
        const dosierabschnittAktuell = medikationsplanContext.dosierabschnittAktuell[planzeile.id];

        if (infomarkerContext && medikationsplanContext.dokumenteGueltig?.aktuell) {
            const infomarkerAktuellNeu = infomarkerContext.filter(im => medikationsplanContext.dokumenteGueltig.aktuell.includes(im.dokument.id) && (!im.dosierabschnittSet?.length || (dosierabschnittAktuell && im.dosierabschnittSet.find(d => d.id === dosierabschnittAktuell.id))));
            setInfomarkerAktuell(infomarkerAktuellNeu);
        }
    }, [infomarkerContext, medikationsplanContext.dosierabschnittAktuell, medikationsplanContext.dokumenteGueltig])

    useEffect(() => {
        if (medikationsplanContext.planzeileSelected === planzeile.id) {
            selectPlanzeile();
        }
    }, [medikationsplanContext.planzeileSelected])

    useEffect(() => {
        if (runAfterUpdate.current) {
            runAfterUpdate.current(planzeile);
            runAfterUpdate.current = null;
        }

        if (planzeile.json) {
            const planzeileJsonNeu = JSON.parse(planzeile.json);
            setPlanzeileJson(planzeileJsonNeu);
            setPlanzeileAlt(planzeileJsonNeu.planzeileAlt);
        }

        setHatDosierabschnittOhneFreigabe(!planzeile.dosierschema?.some(d => d.freigabe));

        if (infomarkerContext && transferInfomarker[1]) {
            const abschnittLast = planzeile.dosierschema.find(d => d.id === transferInfomarker[0]);

            if (abschnittLast) {
                const infomarkerSet = infomarkerContext.filter(im => im.dosierabschnittSet?.find(d2 => d2.id === abschnittLast.id));

                if (infomarkerSet.length) {
                    const abschnittSet = planzeile.dosierschema.filter(d => d.id > 0 && !transferInfomarker[1].includes(d.id));

                    if (abschnittSet.length) {
                        if (medikationsplanContext.oeffneDosierabschnitt && medikationsplanContext.oeffneDosierabschnitt < 0 && !openEditor) {
                            medikationsplanContext.setOeffneDosierabschnitt(abschnittSet[0].id);
                            medikationsplanContext.setOeffneDosierabschnittPlanzeileId(planzeile.id);
                            setEditor("Dosierschema");
                        }

                        setTransferInfomarker([]);

                        for (let im of infomarkerSet) {
                            im.dosierabschnittSet.push(...abschnittSet);
                            callApiAsync({auth, url: infoMarkerApi.put(im.id), method: "put", data: im});
                        }
                    }
                }
            }
        }

        planzeilePrev.current = planzeile;
    }, [planzeile])

    useEffect(() => {
        if (medikationsplanContext.oeffneDosierabschnittPlanzeileId === planzeile.id && editor !== "Dosierschema") {
            setEditor("Dosierschema");
        }
    }, [medikationsplanContext.oeffneDosierabschnittPlanzeileId, editor])

    useEffect(() => {
        if (planzeile?.buendelId) {
            if (Object.keys(medikationsplanContext.buendelMap).length) {
                setEntitlementSet(medikationsplanContext.buendelMap[planzeile.buendelId]?.entitlementSet?.filter(e => e.amount !== 0));
            }
        } else {
            const entitlementSetNeu = [];
            for (let buendel of Object.values(medikationsplanContext.buendelMap)) {
                entitlementSetNeu.push(...buendel.entitlementSet?.filter(e => e.amount !== 0));
            }

            setEntitlementSet(entitlementSetNeu);
        }
    }, [planzeile?.buendelId, medikationsplanContext.buendelMap])

    useEffect(() => {
        const change = medikationsplanContext.planzeilenChange.filter(zArr => zArr[0].id === planzeile.id)[0];
        if (change) {
            const handleChangeArray = [];
            for (let key of change[1]) {
                handleChangeArray.push([change[0][key], key]);
            }

            if (handleChangeArray.length) {
                handleChangeMultiple(handleChangeArray);
            }

            medikationsplanContext.setPlanzeilenChange(prev => prev.filter(zArr => zArr[0].id !== planzeile.id));
        }
    }, [medikationsplanContext.planzeilenChange])

    useEffect(() => {
        if (planzeile && Object.keys(planzeile).length) {
            const isArzneimittelAnsichtNeu = reihenfolgePlanzeile && reihenfolgePlanzeile.arzneimittel && planzeile.arzneimittel && reihenfolgePlanzeile.arzneimittel.m2 !== planzeile.arzneimittel.m2;
            setIsArzneimittelAnsicht(isArzneimittelAnsichtNeu);
        }
    }, [reihenfolgePlanzeile, planzeile])

    /**
     * Validiere Planzeile nach Änderungen
     */
    useEffect(() => {
        if (planzeile && reihenfolge && reihenfolge[planzeile.id]) {
            setReihenfolgePlanzeile(reihenfolge[planzeile.id]);
        } else {
            setReihenfolgePlanzeile({});
        }

        if (planzeile) planzeileLast.current = {...planzeile};
    }, [planzeile, reihenfolge])

    useEffect(() => {
        if (debouncedDoubleClickCounter > 1) {
            setEditor(doubleClickElement);
            if (medikationsplanContext.planzeileSelected !== planzeile.id) medikationsplanContext.setPlanzeileSelected(planzeile.id);
        } else if (debouncedDoubleClickCounter) {
            medikationsplanContext.setPlanzeileSelected(planzeile.id);
        }
    }, [debouncedDoubleClickCounter])

    useEffect(() => {
        setEditor(openEditor);
    }, [openEditor])

    useEffect(() => {
        if (!editor && Object.keys(editorSaved).length) {
            setEditorSaved({});
        } else {
            switch (editor) {
                case "Arzneimittel":
                    if (editorSaved.Arzneimittel) {
                        setEditorChanged(true);
                    }
                    break;
                case "Dosierschema":
                    if (editorSaved.Dosierschema) {
                        setEditorChanged(true);
                    }
                    break;
                case "Verordner":
                    if (editorSaved.Verordner) {
                        setEditorChanged(true);
                    }
                    break;
                case "Alle":
                    if (editorSaved.Dosierschema && editorSaved.Verordner) {
                        setEditorChanged(true);
                    }
                    break;
            }
        }
    }, [editor, editorSaved])

    useEffect(() => {
        (async () => {
            if (updated) {
                if (toggleSaveState && handleSave) {
                    const result = await handleSave();
                    if (runAfterSave.current) {
                        runAfterSave.current(result);
                        runAfterSave.current = null;
                    }
                    toggleSave(false);
                } else if (triggerSave) {
                    toggleSave(true);
                }
            }
        })();
    }, [updated, triggerSave, toggleSaveState, editor])

    useEffect(() => {
        if (dokumenteContext.zuletztGespeicherterInfoMarker && dokumenteContext.zuletztGespeicherterInfoMarker.planzeile.id === planzeile.id) {
            updateInfoMarker(prev =>
                prev ?
                    [...prev.filter(im => im.id !== dokumenteContext.zuletztGespeicherterInfoMarker.id), dokumenteContext.zuletztGespeicherterInfoMarker] :
                    [dokumenteContext.zuletztGespeicherterInfoMarker]
            );

            setKontrolliert(true);
        }
    }, [dokumenteContext.zuletztGespeicherterInfoMarker])

    useEffect(() => {
        if (dokumenteContext.zuletztGeloeschterInfoMarker && dokumenteContext.zuletztGeloeschterInfoMarker.planzeile.id === planzeile.id) {
            updateInfoMarker(prev =>
                prev ?
                    [...prev.filter(im => im.id !== dokumenteContext.zuletztGeloeschterInfoMarker.id)] :
                    []
            );
        }
    }, [dokumenteContext.zuletztGeloeschterInfoMarker])

    useEffect(() => {
        if (editorChanged) {
            setEditor(null);
            setEditorChanged(false);
            setTriggerSaveEditor(false);
        }
    }, [editorChanged])

    useEffect(() => {
        const istSichtbar = checkPlanzeileSichtbar(planzeile);

        if (istSichtbar !== medikationsplanContext.planzeilenSichtbar[planzeile.id]) {
            medikationsplanContext.setPlanzeilenSichtbar(prev => ({...prev, [planzeile.id]: istSichtbar}));
        }
    }, [medikationsplanContext.dosierabschnittAktuell, medikationsplanContext.planzeileSelected])

    useEffect(() => {
        if (medikationsplanContext.pauseGlobal && isChecked) {
            const dosierabschnitt = planzeile.dosierschema.sort(getSorter("dosierabschnitt", "start", true))[0];

            switch (medikationsplanContext.pauseGlobal.modus) {
                case "PLAN_PAUSIEREN":
                    if (dosierabschnitt.blisterPause) {
                        return
                    }
                    break;
                case "PAUSE_BEENDEN":
                    if (!dosierabschnitt.blisterPause) {
                        return;
                    }
                    break;
                default:
                    console.log("medikationsplanContext.pauseGlobal - unbekannter Modus: " + medikationsplanContext.pauseGlobal.modus, medikationsplanContext.pauseGlobal);
                    return;
            }

            console.log(`pauseGlobal für ${getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext)}`, medikationsplanContext.pauseGlobal);
            handleSmartEdit(medikationsplanContext.pauseGlobal.modus, dosierabschnitt, medikationsplanContext.pauseGlobal.datum, planzeile, medikationsplanContext.pauseGlobal.datum2, true, false);
        }
    }, [medikationsplanContext.pauseGlobal])

    useEffect(() => {
        if (medikationsplanContext.absetzenGlobal && isChecked) {
            const dosierabschnitt = planzeile.dosierschema.sort(getSorter("dosierabschnitt", "start", true))[0];

            console.log(`absetzenGlobal für ${getArzneimittelLangname(planzeile.arzneimittel, planzeile.arzneimittelFreitext)}`, medikationsplanContext.absetzenGlobal.format("DD.MM.YYYY"));
            handleSmartEdit("ABSETZEN", dosierabschnitt, medikationsplanContext.absetzenGlobal.valueOf(), planzeile);
        }
    }, [medikationsplanContext.absetzenGlobal])

    useEffect(() => {
        if (medikationsplanContext.resetGlobal && isChecked) {
            resetPlanzeileAlt();
        }
    }, [medikationsplanContext.resetGlobal])

    useEffect(() => {
        setIsChecked(medikationsplanContext.planzeilenSichtbar[planzeile.id] && medikationsplanContext.planzeilenChecked.includes(planzeile.id));
    }, [medikationsplanContext.planzeilenChecked])



    const checkPlanzeileSichtbar = (planzeile) => {
        return medikationsplanContext.isPlanzeileSichtbar(planzeile.id, globalContext.heute);
    }

    const selectPlanzeile = () => {
        const geoeffneteDokumente = dokumenteContext.getGeoeffneteDokumente();
        if (geoeffneteDokumente && geoeffneteDokumente.length && infomarkerContext && infomarkerContext.length) {
            let dokumentGefunden = false;
            for (let im of infomarkerContext) {
                if (geoeffneteDokumente.includes(""+im.dokument.id)) {
                    dokumenteContext.setHighlightInfoMarker(im);
                    dokumentGefunden = true;
                    break;
                }
            }

            if (!dokumentGefunden) {
                dokumenteContext.setHighlightInfoMarker(null);
            }
        }
    }

    const checkPlanzeileChanged = (properties) => {
        if (!planzeileLast.current) return false;
        return !equalsDeep(planzeile, planzeileLast.current, properties);
    }

    const handleSetFreigabe = (val, handleChangeArray = [] ) => {
        handleChangeArray.push([val, "freigabe"]);
        if (val) {
            if (planzeile.json) {
                const json = JSON.parse(planzeile.json);
                delete json.planzeileAlt;

                if (!Object.keys(json).length) {
                    handleChangeArray.push([null, "json"]);
                } else {
                    handleChangeArray.push([JSON.stringify(json), "json"]);
                }
            }
        } else if (planzeile.freigabe) {
            const json = JSON.parse(planzeile.json || "{}");
            if (!json.planzeileAlt) {
                json.planzeileAlt = planzeile;
                handleChangeArray.push([JSON.stringify(json), "json"]);
            }
        }

        return handleChangeArray;
    }

    const handleDosierschemaChange = async (schema=null, debounceChange=false) => {
        if (!schema) return;


        const handleChangeMultipleArray = planzeile.freigabe ? [
            ...handleSetFreigabe(false),
        ] : [];

        /****
        ** Hier kann das Dosierschema angepasst werden
        ****/

        handleChangeMultipleArray.push([schema, "dosierschema"]);

        handleChangeMultiple(handleChangeMultipleArray);
        setEditorChanged(true);
    }

    const handleArzneimittelChange = (arzneimittelNeu, faktorManuell) => {
        return new Promise(async (resolve) => {
            const handleChangeArray = [];

            let faktorArzneimittel;
            let planzeileNextId;

            // Arzneimittel ist geändert
            if (planzeile.arzneimittel?.pzn !== arzneimittelNeu?.pzn || planzeile.arzneimittel?.m2 !== arzneimittelNeu.m2) {
                if (!faktorManuell && arzneimittelNeu?.pzn && planzeile.arzneimittel?.pzn && planzeile.arzneimittel.m2.startsWith('pzn_') && planzeile.arzneimittel.pzn === arzneimittelNeu?.pzn) {
                    faktorManuell = 1;
                }

                // Planzeile ist neu oder Planzeilen-Arzneimittel war noch nicht gesetzt
                if (planzeile.id < 0 || !planzeile.arzneimittel) {

                    handleChangeArray.push([arzneimittelNeu, "arzneimittel"]);
                }

                // Nicht Standard-Ansicht
                else if (!medikationsplanContext.isStandardAnsicht) {
                    if (planzeile.arzneimittel?.m2 !== arzneimittelNeu?.m2) {
                        // Prüfe autIdem
                        if (planzeile.autIdem) {
                            notification.open({
                                type: "error",
                                message: `Das Arzneimittel darf nicht ausgetauscht werden, weil 'aut idem' angehakt ist.`
                            });

                            resolve(false);
                            return;
                        } else {
                            const response = await callApiAsync({
                                auth,
                                url: arzneimittelApi.getSubstitutFaktor(planzeile.arzneimittel.m2, arzneimittelNeu.m2),
                                onError: res => {
                                    if (res.data.MESSAGE === "medicine item is no substitute.") {
                                        notification.open({
                                            type: "error",
                                            message: `${getArzneimittelLangname(arzneimittelNeu)} ist kein Substitut für ${getArzneimittelLangname(planzeile.arzneimittel)}`
                                        });
                                    }
                                }
                            });

                            const obj = response.data.OBJECT;
                            faktorArzneimittel = getFaktorGerundet(obj);
                            if (!faktorArzneimittel) {
                                notification.open({
                                    type: "error",
                                    message: `${getArzneimittelLangname(arzneimittelNeu)} ist kein Substitut für ${getArzneimittelLangname(planzeile.arzneimittel)}, da die Abweichung der Wirkstoff-Faktoren zu groß ist.`
                                });

                                resolve(false);
                                return;
                            }
                        }
                    }

                    setEditorChanged(true);
                    setReihenfolgeArzneimittel(planzeile.id, arzneimittelNeu, faktorArzneimittel ? faktorArzneimittel : 1);
                    resolve(true);
                    return;
                }

                // ist Standard-Ansicht
                else if (medikationsplanContext.isStandardAnsicht) {
                    const res = await splitDosierschemaWegenArzneimittel(arzneimittelNeu, globalContext.heute, faktorManuell);
                    if (!res) {
                        resolve(false);
                        return;
                    }

                    handleChange(res.planzeile);
                }
            }

            if (!planzeile.arzneimittel || planzeile.arzneimittel.m2 !== arzneimittelNeu.m2) {
                setTriggerBestandReloadAfterSave(true);
                setTriggerAnspruchReloadAfterSave(true);
            }

            if (planzeile.reichweiteBestand || planzeile.reichweiteAnspruch) {
                handleChangeArray.push([null, "reichweiteAnspruch"]);
                handleChangeArray.push([null, "reichweiteBestand"]);
            }

            handleChangeArray.push([0, "bedarf"]);


            // const handleSetFreigabeRet = handleSetFreigabe(false);
            // if (planzeileNextId) {
            //     const jsonArray = handleSetFreigabeRet.find(a => a[1] === "json");
            //     if (jsonArray) {
            //         const json = JSON.parse(jsonArray[0]);
            //         json.PLANZEILE_NEXT = planzeileNextId;
            //         jsonArray[0] = JSON.stringify(json);
            //     } else {
            //         const json = planzeile.json ? JSON.parse(planzeile.json) : {};
            //         json.PLANZEILE_NEXT = planzeileNextId;
            //         handleChangeArray.push([JSON.stringify(json), "json"]);
            //     }
            // }
            // handleChangeArray.push(...handleSetFreigabeRet);

            handleChangeMultiple(handleChangeArray);

            setEditorChanged(true);
            resolve(true);
        });
    }

    const getFaktorGerundet = (faktorObj, getKehrwert=false) => {
        // ermittle Minimal- und Maximalfaktor
        let min, max;
        for (let key of Object.keys(faktorObj)) {
            const faktor = faktorObj[key];
            if (!min || min > faktor) min = faktor;
            if (!max || max < faktor) max = faktor;
        }

        // Stelle sicher, dass Minimal- und Maximalfaktoren jeweils nicht weiter auseinander sind
        // als 0,2% zum nächsten Rundungswert für Faktoren (0,125er oder 0,333er Schritte)
        const minGerundet = Math.round(min * 8) / 8;
        const maxGerundet = Math.round(max * 8) / 8;
        const minGerundetDrittel = Math.round(min * 3) / 3;
        const maxGerundetDrittel = Math.round(max * 3) / 3;

        // Prüfe, ob die Rundung in 0,125er Schritten erlaubt ist
        let okayGerundet = false;
        if (minGerundet === maxGerundet) {
            // Die erlaubte Abweichung von 0,2% des gerundeten Werts
            const deltaErlaubt = minGerundet / 500

            const minDelta = Math.abs(min - minGerundet);
            const maxDelta = Math.abs(max - maxGerundet);
            okayGerundet = minDelta <= deltaErlaubt && maxDelta <= deltaErlaubt;
        }

        // Prüfe, ob die Rundung in 0,333er Schritten erlaubt ist
        let okayGerundetDrittel = false;
        if (minGerundetDrittel === maxGerundetDrittel) {
            // Die erlaubte Abweichung von 0,2% des gerundeten Werts
            const deltaErlaubt = minGerundetDrittel / 500

            const minDelta = Math.abs(min - minGerundetDrittel);
            const maxDelta = Math.abs(max - maxGerundetDrittel);
            okayGerundetDrittel = minDelta <= deltaErlaubt && maxDelta <= deltaErlaubt;
        }

        // Abbrechen, wenn beide oder keine der Rundungen erlaubt wären
        if ((okayGerundet && okayGerundetDrittel && minGerundet !== minGerundetDrittel) || (!okayGerundet && !okayGerundetDrittel)) {
            return 0;
        } else {
            const faktor = okayGerundet ? minGerundet : minGerundetDrittel;
            const faktorArzneimittel = getKehrwert ? (faktor === 0 ? 0 : 1 / faktor) : faktor;

            // runden auf 5 Nachkommastellen
            return Math.trunc(faktorArzneimittel * 100000) / 100000;
        }
    }

    const reverseSplitDosierschemaWegenArzneimittel = async () => {
        const planzeileParent = JSON.parse(JSON.stringify(medikationsplanContext.planzeilenMap[planzeileJson.DOSISGENAU_ANFORDERN["1"]]));
        const jsonPlanzeileParent = JSON.parse(planzeileParent.json);

        let faktor = null;
        for (let key of Object.keys(jsonPlanzeileParent.DOSISGENAU_ANFORDERN)) {
            if (jsonPlanzeileParent.DOSISGENAU_ANFORDERN[key] === planzeile.id) {
                delete jsonPlanzeileParent.DOSISGENAU_ANFORDERN[key];
                faktor = parseFloat(key);
                break;
            }
        }

        if (faktor) {
            const dosierschemaParent = planzeileParent.dosierschema;
            dosierschemaParent.sort(getSorter("dosierabschnitt", "start"));

            const dosierschema = planzeile.dosierschema;
            dosierschema.sort(getSorter("dosierabschnitt", "start"));

            let isChanged = false;
            for (let i=0; i<dosierschema.length; i++) {
                const dosierabschnitt = dosierschema[i];
                const dosierabschnittParent = dosierschemaParent[i];

                for (let tag of dosierabschnitt.abgabemuster) {
                    let tagParent = null;

                    for (let zeit of tag.zeiten) {
                        if (zeit.menge) {
                            if (!tagParent) {
                                tagParent = dosierabschnittParent.abgabemuster.find(t => t.tag === tag.tag);
                            }

                            const zeitParent = tagParent.zeiten.find(z => z.fachNummer === zeit.fachNummer);
                            if (zeitParent) {
                                zeitParent.menge += zeitParent.menge * faktor;
                            } else {
                                tagParent.zeiten.push({...zeit, menge: zeit.menge * faktor});
                            }

                            isChanged = true;
                            dosierabschnittParent.freigabe = false;
                        }
                    }
                }
            }

            if (isChanged) {
                planzeileParent.freigabe = false;
                planzeileParent.dosisgenauAnfordern = false;
                planzeileParent.reichweiteBestand = null;
                planzeileParent.reichweiteAnspruch = null;

                if (!jsonPlanzeileParent.planzeileAlt) {
                    jsonPlanzeileParent.planzeileAlt = medikationsplanContext.planzeilenMap[planzeileParent.id];
                }

                planzeileParent.json = JSON.stringify(jsonPlanzeileParent);
                medikationsplanContext.setPlanzeilenChange(prev => [...prev, [planzeileParent, ["dosierschema", "freigabe", "dosisgenauAnfordern", "reichweite", "json"]]]);
            }
        }
    }

    const splitDosierschemaWegenArzneimittel = async (arzneimittel, datumAb, faktorManuell=0) => {
        const data = {
            planzeileId: planzeile.id,
            arzneimittelId: arzneimittel.id,
            datumAb: moment(datumAb).format('YYYYMMDD'),
            faktorManuell,

            heute: moment(globalContext.heute).format('YYYYMMDD')
        }
        const response = await callApiAsync({auth, url: planzeilenApi.splitDosierschema(), method: 'post', data})
        const obj = response.data.OBJECT;
        if (!Object.keys(obj).length) return null;

        if (obj.planzeileNeu) {
            const planzeileNeu = obj.planzeileNeu;
            const patientId = planzeileNeu.medikationsplan.patient.id;

            addPlanzeile(planzeileNeu);

            if (obj.infoMarkerNeu?.length) {
                medikationsplanContext.setInfoMarkerMap(prev => ({...prev, [planzeileNeu.id]: [...(prev[planzeileNeu.id] || []), ...obj.infoMarkerNeu]}));
            }

            if (obj.notizNeu) for (let neu of obj.notizNeu) {
                notizContext.setNotizMap(prev => ({...prev, [patientId]: {...prev[patientId], [neu.id]: neu}}));
            }
        }

        return obj;
    }

    const handleVerordnerChange = (e) => {
        handleChange(e, "verordner")
        setEditorChanged(true);
    }


    const handleChangeAndSave = (e, label) => {
        handleChange(e, label)
        setEditor(null)
        toggleSave(true)
    }

    const handleDelete = () => {
        medikationsplanContext.setPlanzeileSelected(null);

        if (onDelete && typeof onDelete === 'function') {
            onDelete(planzeile.id);

            if (planzeileJson.DOSISGENAU_ANFORDERN) {
                if (planzeileJson.DOSISGENAU_ANFORDERN["1"]) {
                    reverseSplitDosierschemaWegenArzneimittel();
                } else {
                    for (let key of Object.keys(planzeileJson.DOSISGENAU_ANFORDERN)) {
                        onDelete(planzeileJson.DOSISGENAU_ANFORDERN[key]);
                    }
                }
            }
        }
    }


    /**
     * Manuelle Sortierung
     */

    const sortiereHoch = () => {
        if (!report || !report.handsortiert || !handleChange) return
        if (!reihenfolgePlanzeile.id || reihenfolgePlanzeile.reihenfolge === 0) return

        const reihenfolgeIndex = reihenfolgePlanzeile.reihenfolge;
        const planzeileIdVorher = Object.keys(reihenfolge).find(key => reihenfolge[key].reihenfolge === reihenfolgeIndex - 1);

        if (planzeileIdVorher) setReihenfolgeIndex(planzeileIdVorher, reihenfolgeIndex);
        setReihenfolgeIndex(planzeile.id, reihenfolgeIndex - 1);
    }

    const sortiereRunter = () => {
        if (!report || !report.handsortiert || !handleChange) return
        if (!reihenfolgePlanzeile.id || reihenfolgePlanzeile.reihenfolge === reihenfolge.length - 1) return

        const reihenfolgeIndex = reihenfolgePlanzeile.reihenfolge;
        const planzeileIdVorher = Object.keys(reihenfolge).find(key => reihenfolge[key].reihenfolge === reihenfolgeIndex + 1);

        if (planzeileIdVorher) setReihenfolgeIndex(planzeileIdVorher, reihenfolgeIndex);
        setReihenfolgeIndex(planzeile.id, reihenfolgeIndex + 1);
    }


    /**
     * Planzeile und alle Dosierabschnitte freigeben
     */
    const handleFreigabe = () => {
        const triggeredByParent = triggerFreigabe;
        setFreigabePopoverVisible(false);

        if (!planzeile.freigabe) {
            let minStart = null;
            const heute = moment().startOf('day');
            let dosierschemaNeu = freigabeMitDosierabschnitten ? planzeile.dosierschema.map(
                abschnitt => {
                    const start = heute.isBefore(abschnitt.start) ? moment(abschnitt.start) : heute;
                    if (!abschnitt.freigabe && (!minStart || start.isBefore(minStart))) {
                        minStart = start;
                    }

                    return {...abschnitt, freigabe: true};
                }
            ) : planzeile.dosierschema;

            handleChangeMultiple([
                ...handleSetFreigabe(true),
                [moment().valueOf(), "freigeberTimestamp"],
                [dosierschemaNeu, "dosierschema"],
                [null, "reichweite"],
                [false, "freigabeGesperrt"],
                [0, "bedarf"],
                [-1, "bedarfQuartal"],
            ]);
            setEditor(null);
            runAfterUpdate.current = (val) => {
                toggleSave(true);

                runAfterSave.current = (val) => {
                    const contiunue = () => {
                        if (minStart) {
                            medikationsplanContext.setCheckAenderungsauftragAb(prev => {
                                return !prev || minStart.isBefore(prev) ? minStart : prev;
                            });
                        }
                    }

                    if (!triggeredByParent) {
                        abgabeplanNeuBerechnen().then(contiunue);
                    } else {
                        contiunue();
                    }
                };
            };
        }
    }

    const onDragEnterPlanzeile = (event) => {
        event.preventDefault();

        if (!dragEnterCounter.current) {
            document.getElementById(`planzeile-${planzeile.id}`).classList.add("drag-over");
        }

        dragEnterCounter.current++;
        scrollTo(planzeile);
    }

    const onDragLeavePlanzeile = (e) => {
        if (!--dragEnterCounter.current) {
            document.getElementById(`planzeile-${planzeile.id}`).classList.remove("drag-over");
        }
    }

    const onDropPlanzeile = (e) => {
        dragEnterCounter.current = 0;
        setDropPlanzeileId(planzeile.id);

        document.getElementById(`planzeile-${planzeile.id}`).classList.remove("drag-over");
        for (let planzeile of document.getElementsByClassName("planzeile dragging")) {
            planzeile.classList.remove("dragging");
        }
    }

    const onDragStartPlanzeile = (e) => {
        if (!editor) {
            medikationsplanContext.setPlanzeileSelected(planzeile.id);
            setDragPlanzeileId(planzeile.id);

            for (let planzeile of document.getElementsByClassName("planzeile dragging")) {
                planzeile.classList.remove("dragging");
            }

            document.getElementById(`planzeile-${planzeile.id}`).classList.add("dragging");
        }
    }

    const sperreFreigabe = () => {
        handleChange(true, 'freigabeGesperrt');
    }

    const resetPlanzeileAlt = () => {
        if (!planzeileAlt) return;

        planzeileAlt.reichweiteBestand = null;
        planzeileAlt.reichweiteAnspruch = null;

        setFreigabePopoverVisible(false);
        handleChange(planzeileAlt);
    }

    const handleSmartEdit = async (modus=null, abschnitt=null, datum=null, planzeile=null, datum2=null, debounceChange=false, oeffneDosierabschnitt=true) => {
        if (handleSmartEditDebounceTimeout.current) {
            clearTimeout(handleSmartEditDebounceTimeout.current);
            handleSmartEditDebounceTimeout.current = null;
        }

        const performAction = () => {
            const neuerDosierabschnittIdCurrent = medikationsplanContext.dosierabschnittNeuId.current--;
            const dosierschema = planzeile.dosierschema;

            let [abschnittLast, abschnittNeu] = handleSmartEditGlobal(modus, abschnitt, datum, dosierschema, neuerDosierabschnittIdCurrent, datum2);

            // Prüfe, ob die Planzeile dosisgenau verknüpft ist
            if (abschnittLast && !abschnittLast.deleteId && planzeile.json) {
                const json = JSON.parse(planzeile.json);
                const dosisgenauAnfordern = json.DOSISGENAU_ANFORDERN;
                if (dosisgenauAnfordern) {
                    // Die Planzeile ist dosisgenau verknüpft => Änderung auf alle verknüpften Planzeilen anwenden
                    const start = abschnittLast.start || datum;

                    const planzeilenNeu = {};
                    for (let key of Object.keys(dosisgenauAnfordern)) {
                        const planzeileSub = medikationsplanContext.planzeilenMap[dosisgenauAnfordern[key]];
                        const dosierschemaSub = planzeileSub.dosierschema;
                        const abschnittSub = dosierschemaSub.filter(s => s.start === start)[0];

                        // Änderung auf Sub-Planzeile anwenden
                        let [abschnittLastSub, abschnittNeuSub] = handleSmartEditGlobal(modus, abschnittSub, datum, dosierschemaSub, null, datum2);
                        const dosierschemaNeu = dosierschemaSub.map(s => {
                            if (abschnittNeuSub && s.id === abschnittNeuSub.id) {
                                return null;
                            }

                            if (abschnittLastSub.deleteId) {
                                if (s.id === abschnittLastSub.deleteId) {
                                    return null;
                                }
                            } else {
                                if (s.id === abschnittLastSub.id) {
                                    return abschnittLastSub;
                                }
                            }

                            return s;
                        }).filter(s => s !== null);

                        if (abschnittNeuSub) {
                            dosierschemaNeu.push(abschnittNeuSub);
                        }

                        // Sub-Planzeile speichern
                        const json = JSON.parse(planzeileSub.json || "{}");
                        if (!json.planzeileAlt) {
                            json.planzeileAlt = planzeileSub;
                        }

                        medikationsplanContext.setPlanzeilenChange(prev => [...prev.filter(e => e[0].id !== planzeileSub.id), [{
                            ...planzeileSub,
                            dosierschema: dosierschemaNeu,
                            freigabe: false,
                            reichweite: null,
                            json: JSON.stringify(json)
                        }, ["dosierschema", "freigabe", "reichweite", "json"]]]);
                    }

                    medikationsplanContext.setPlanzeilen(prev => [...prev.filter(p => !planzeilenNeu[p.id]), ...Object.keys(planzeilenNeu).map(key => planzeilenNeu[key])]);
                }
            }

            if (oeffneDosierabschnitt) {
                if (abschnittNeu) {
                    medikationsplanContext.setOeffneDosierabschnitt(abschnittNeu.id);
                    medikationsplanContext.setOeffneDosierabschnittPlanzeileId(planzeile?.id);
                } else if (abschnittLast && !abschnittLast.deleteId) {
                    medikationsplanContext.setOeffneDosierabschnitt(abschnittLast.id);
                    medikationsplanContext.setOeffneDosierabschnittPlanzeileId(planzeile?.id);
                }
            }

            let abschnittLastSeen = false;
            let abschnittNeuSeen = false;
            const dosierschemaNeu = planzeile.dosierschema.map(s => {
                if (abschnittNeu && !abschnittNeuSeen && s.id === abschnittNeu.id) {
                    abschnittNeuSeen = true;
                    return abschnittNeu;
                } else if (abschnittLast && !abschnittLastSeen && s.id === abschnittLast.id) {
                    abschnittLastSeen = true;
                    return abschnittLast;
                } else if (abschnittLast && !abschnittLastSeen && s.id === abschnittLast.deleteId) {
                    abschnittLastSeen = true;
                    return null;
                } else {
                    return s;
                }
            }).filter(s => s !== null);

            if (abschnittLast && !abschnittLastSeen) {
                dosierschemaNeu.push(abschnittLast);
            }

            if (abschnittLast && abschnittNeu?.id < 0) {
                const infoMarker = medikationsplanContext.infoMarkerMap[planzeile.id].find(im => !!im.dosierabschnittSet?.find(d => d.id === abschnittLast.id));
                if (infoMarker) {
                    setTransferInfomarker([abschnittLast.id, dosierschemaNeu.filter(d => d.id > 0).map(d => d.id)]);
                }

                if (!abschnittNeuSeen) {
                    dosierschemaNeu.push(abschnittNeu);
                }
            }

            handleDosierschemaChange(dosierschemaNeu, debounceChange);
        }

        performAction();
    }

    const handleContextMenu = (top, left) => {
        let contextMenuObj = null;
        let developerItems = null;

        if (auth.developer) {
            developerItems = <>
                <Menu.Item onClick={() => console.log(planzeile)}>Log Planzeile -> Console</Menu.Item>
                <Menu.Item onClick={() => navigator.clipboard.writeText(planzeile.arzneimittel.pzn)}>Copy PZN</Menu.Item>
                <Menu.Item onClick={() => navigator.clipboard.writeText(planzeile.arzneimittel.m2)}>Copy M2</Menu.Item>
            </>
        }

        //todo Kontextmenü hier erstellen
        contextMenuObj = null;

        if (developerItems && !contextMenuObj) {
            contextMenuObj = {
                top,
                left,
                title: planzeile.arzneimittel ? getArzneimittelLangname(planzeile.arzneimittel) : "[kein Arzneimittel eingetragen]",
                content: <Menu>
                    {developerItems}
                </Menu>
            }
        }

        if (contextMenuObj) {
            globalContext.setContextMenu(prev => {
                return contextMenuObj;
            });
        }
    }

    const planzeileRow = (arzneimittel = null) => {
        return <Row type="flex" key={arzneimittel ? arzneimittel.id : "default"}
            onContextMenu={e => {
                if (!auth.developer || !e.getModifierState("Control") || !e.getModifierState("Shift")) {
                    handleContextMenu(e.pageY, e.pageX);
                }
            }}
        >

            {/* Arzneimittel, aber hand sortiert */}
            {report && report.handsortiert && <>
                {!arzneimittel ? <Col span={1} className="planzeile-info-links">
                    <div style={{height: 40}}>
                        {!!infomarkerAktuell.length &&
                        <Tooltip title={`Für diese Planzeile existieren aktuell ${infomarkerAktuell.length} Verknüpfungen`}><TagOutlined/>{infomarkerAktuell.length > 1 ? infomarkerAktuell.length : ""}</Tooltip>}
                        <Checkbox style={{float: "right"}} checked={isChecked} onChange={e => {
                            if (isChecked) {
                                medikationsplanContext.setPlanzeilenChecked(prev => prev.filter(id => planzeile.id !== id));
                            } else {
                                medikationsplanContext.setPlanzeilenChecked(prev => [...prev, planzeile.id]);
                            }
                        }} />

                        {!!auth.developer && <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap"}}>
                            {planzeile.id === medikationsplanContext.planzeileSelected && <div>
                                <div>R{reihenfolgePlanzeile.reihenfolge || 0}</div>
                                <div>B{planzeile.buendelId || 0}</div>
                            </div>}
                            <span>#{planzeile.id}</span>
                        </div>}
                    </div>

                    {kontrollModus && <>
                        {!kontrolliert && <Tooltip title="Zeile ist kontrolliert" placement="bottom"><Button
                            onClick={() => setKontrolliert(true)}><CheckOutlined/></Button></Tooltip>}
                        {kontrolliert && <Tooltip title="Zeile ist nicht kontrolliert" placement="bottom"><Button
                            onClick={() => setKontrolliert(false)}><CloseOutlined/></Button></Tooltip>}
                    </>}
                </Col> : <Col span={1}/>}

                <Col
                    className={"planzeile-available"}
                    span={5} onClick={() => {
                        setDoubleClickElement("Arzneimittel");
                        debounceDoubleClick("Arzneimittel");
                    }}

                    draggable="true" onDragStart={onDragStartPlanzeile}
                >
                    {isArzneimittelAnsicht && <div style={{float: "right"}}><Popover
                        trigger={"hover"}
                        content={<table>
                            <tbody>
                                <tr>
                                    <th colSpan={2}>Anderes Arzneimittel in Ansicht</th>
                                </tr>
                                <tr>
                                    <th>Original:</th>
                                    <td>{getArzneimittelLangname(planzeile.arzneimittel)}</td>
                                </tr>
                                <tr>
                                    <th>Faktor:</th>
                                    <td>
                                        {mengeToString(reihenfolgePlanzeile.faktorArzneimittel || 1)}
                                        <Button size={"small"} style={{position: "absolute", right: 15}} onClick={e => {
                                            e.stopPropagation();
                                            setReihenfolgeArzneimittel(planzeile.id, null, null);
                                        }}>zurücksetzen</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>}
                    ><EyeOutlined/></Popover></div>}

                    <PlanzeileArzneimittel
                        planzeile={planzeile}
                        arzneimittel={arzneimittel || reihenfolgePlanzeile.arzneimittel || planzeile.arzneimittel}
                        freitext={planzeile.arzneimittelFreitext}
                    />
                </Col>
            </>}

            {/* Arzneimittel, aber nach muster sortiert */}
            {(!report || !report.handsortiert) && <>
                {!arzneimittel ? <Col span={1} className="planzeile-info-links">
                    {/*<Button onClick={sortiereHoch}><UpSquareOutlined/></Button><br/>*/}
                    {/*<Button onClick={sortiereRunter}><DownSquareOutlined/></Button>*/}
                    <div style={{height: 40}}>
                        {infomarkerContext && !!infomarkerContext.length &&
                        <Tooltip title="Diese Planzeile ist mit einem Dokument verknüpft"><TagOutlined/></Tooltip>}
                    </div>

                    {kontrollModus && <>
                        {!kontrolliert && <Tooltip title="Zeile ist kontrolliert" placement="bottom"><Button
                            onClick={() => setKontrolliert(true)}><CheckOutlined/></Button></Tooltip>}
                        {kontrolliert && <Tooltip title="Zeile ist nicht kontrolliert" placement="bottom"><Button
                            onClick={() => setKontrolliert(false)}><CloseOutlined/></Button></Tooltip>}
                    </>}

                    {/*{*/}
                    {/*    planzeileJson && planzeileJson.DOSISGENAU_ANFORDERN && planzeileJson.DOSISGENAU_ANFORDERN["1"] &&*/}
                    {/*    <LinkOutlined style={{top: -10, left: 50, position: "absolute", fontSize: 20}} />*/}
                    {/*}*/}

                    {!!auth.developer && <span style={{float: "right", marginRight: 5}}>#{planzeile.id}</span>}
                </Col> : <Col span={1}/>}

                <Col className={"planzeile-available"} span={5} onClick={() => {
                    setDoubleClickElement("Arzneimittel");
                    debounceDoubleClick("Arzneimittel");
                }}>
                    <PlanzeileArzneimittel
                        planzeile={planzeile}
                        arzneimittel={reihenfolgePlanzeile.arzneimittel || planzeile.arzneimittel}
                        freitext={planzeile.arzneimittelFreitext}
                    />
                </Col>
            </>
            }

            {/* Dosierschema */}
            <Col
                span={6}
                className={"planzeile-available"}
                onClick={() => {
                    setDoubleClickElement("Alle");
                    debounceDoubleClick("Alle");
                }}
            >
                <Dosierschema
                    value={planzeile.dosierschema}
                    abschnittFilter={abschnittFilter}

                    faktorMenge={isArzneimittelAnsicht && reihenfolgePlanzeile.faktorArzneimittel ? 1/reihenfolgePlanzeile.faktorArzneimittel : 1}
                    planzeile={planzeile}
                    planzeileAlt={planzeileAlt}
                    arzneimittel={medikationsplanContext.isStandardAnsicht ? arzneimittel || planzeile.arzneimittel : null}
                />
            </Col>


            {/* Einnahmehinweise */}
            <Col className={"planzeile-available"} span={4}
                 onClick={() => {
                     setDoubleClickElement("Alle");
                     debounceDoubleClick("Alle");
                 }}>
                <Einnahmehinweise
                    planzeile={planzeile}
                />

            </Col>


            {/* Reichweite */}
            {arzneimittel ? <></> : <Col className={"planzeile-available"} span={6} onClick={() => {
                setDoubleClickElement("Verordner");
                debounceDoubleClick("Verordner");
            }}>
                <Reichweite
                    hausarzt={hausarzt}
                    planzeile={planzeile}
                />
            </Col>}


            {/* Apotheker - Bereich */}
            {isFavoritenAnsicht || arzneimittel ? <></> : <Col span={2}>
                {!planzeile.freigabe &&
                <Popover
                    content={<div>
                        <h6>Soll die Planzeile wirklich freigegeben weden?</h6>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <LabeledSwitch
                                checked={"freigabeGesperrt" in settingsChange ? settingsChange.freigabeGesperrt : planzeile.freigabeGesperrt}
                                onChange={val => {
                                    setSettingsChange(prev => ({...prev, freigabeGesperrt: val}));
                                }}
                                label={"Freigabe vorübergehend ausgesetzt"}
                            />
                            <LabeledSwitch label={"Alle Dosierabschnitte freigeben"} checked={freigabeMitDosierabschnitten} onChange={setFreigabeMitDosierabschnitten} />
                        </div>

                        <div style={{marginTop: 20, display: "flex", gap: 20}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                {!!planzeileAlt && <Button size={"small"} onClick={resetPlanzeileAlt}>NEIN, Änderungen zurücksetzen</Button>}
                                <Button size={"small"} onClick={e => setFreigabePopoverVisible(false)}>NEIN, abbrechen</Button>
                            </div>

                            <div style={{textAlign: "right"}}>
                                <Button size={"small"} type={"primary"} style={{right: 0}} onClick={handleFreigabe}>JA, Planzeile freigeben</Button>
                            </div>
                        </div>
                    </div>}

                    trigger={"click"}
                    placement={"left"}
                    visible={freigabePopoverVisible}
                    onVisibleChange={setFreigabePopoverVisible}
                >
                    <Button style={{backgroundColor: planzeile.freigabeGesperrt ? "#d20000" : "#e0a44b"}}><ExportOutlined/></Button>
                </Popover>
                }

                <Button onClick={() => setZeigeEinstellungen(!zeigeEinstellungen)}
                        type={zeigeEinstellungen && "primary"}>< SettingOutlined/></Button>
            </Col>}
        </Row>
    }

    const classNames = ["planzeile"];
    if (!medikationsplanContext.planzeilenSichtbar[planzeile.id]) classNames.push("unsichtbar");
    if (!medikationsplanContext.dosierabschnittAktuell[planzeile.id]?.imBlister) classNames.push("nicht-verblistert");
    if (medikationsplanContext.dosierabschnittAktuell[planzeile.id]?.blisterPause) classNames.push("pausiert");
    if (kontrollModus && kontrolliert) classNames.push("planzeile-kontrolliert");
    if (medikationsplanContext.planzeileSelected === planzeile.id) classNames.push("planzeile-selected");
    if ("unklar" in settingsChange) {
        if (settingsChange.unklar) classNames.push("unklar");
    } else if (planzeile.unklar) classNames.push("unklar");

    return <StatusDisplay status={status}>
        <div id={`planzeile-${planzeile.id}`}
             className={classNames.join(' ')}
             style={{...style}} onDragEnter={onDragEnterPlanzeile} onDragLeave={onDragLeavePlanzeile}
             onDrop={onDropPlanzeile} onDragOver={e => e.preventDefault()} ref={planzeileRef}>
            {planzeile && planzeileRow()}

            {alternativeArzneimittel && Object.keys(alternativeArzneimittel).map(arzneimittelId => planzeileRow(alternativeArzneimittel[arzneimittelId]))}

            {/**
             *  Editor-Modal
             */}
            {(zeigeEinstellungen || (editor && editor !== "Freigabe")) &&
            <Modal
                footer={null}
                onCancel={() => {
                    if (editor) setTriggerSaveEditor(true);
                    else setZeigeEinstellungen(false);
                }}
                visible={true}
                centered={true}
                width="90%"
                title={"Einstellungen für " + (planzeile.arzneimittel ? (reihenfolgePlanzeile.arzneimittel ? getArzneimittelLangname(reihenfolgePlanzeile.arzneimittel) : getArzneimittelLangname(planzeile.arzneimittel)) : "neue Planzeile")}
            >
                {
                    editor === "Arzneimittel" &&
                    <EditArzneimittel
                        entitlementset={entitlementSet}

                        value={reihenfolgePlanzeile.arzneimittel || planzeile.arzneimittel}
                        onChange={(val) => {
                            return handleArzneimittelChange(val);
                        }}
                        onClose={() => {
                            setEditor(null);
                        }}
                        freitext={planzeile.arzneimittelFreitext}
                        handleFreitextChange={text => {
                            // Freitext nur zulassen, wenn in Apothekenansicht oder noch kein Arzneimittel gesetzt wurde
                            if (!medikationsplanContext.isStandardAnsicht && !!planzeile.arzneimittel) {
                                notification.error({message: "Ein Freitext kann nur in der Apotheken-Ansicht geändert werden oder, wenn zuvor noch kein Arzneimittel ausgewählt wurde."})
                            } else {
                                handleChangeMultiple([
                                    [text, "arzneimittelFreitext"],
                                    [null, "arzneimittel"],
                                    [planzeile.dosierschema.map(d => ({...d, imBlister: false})), "dosierschema"]
                                ]);
                                setEditor(null);
                            }
                        }}

                        triggerSave={triggerSaveEditor || triggerSave}

                        setSaved={(val) => {
                            setEditorSaved(prev => ({...prev, Arzneimittel: val}))
                        }}
                    />
                }

                {["Dosierschema", "Alle"].includes(editor) &&
                <EditDosierschema
                    value={planzeile.dosierschema}
                    onChange={handleDosierschemaChange}
                    onClose={() => {
                        setEditor(null);
                    }}
                    blisterTag={planzeile.medikationsplan.blisterStartTag}

                    planzeile={planzeile}
                    patientId={planzeile.medikationsplan.patient.id}

                    triggerSave={triggerSaveEditor || triggerSave}
                    setUpdated={() => {
                        setUpdated()
                    }}
                    faktorMenge={reihenfolgePlanzeile.faktorArzneimittel || 1}
                    arzneimittel={reihenfolgePlanzeile.arzneimittel}

                    setSaved={(val) => {
                        setEditorSaved(prev => ({...prev, Dosierschema: val}))
                    }}

                    handleSmartEdit={(modus, abschnitt, datum) => handleSmartEdit(modus, abschnitt, datum, planzeile)}
                />
                }

                {
                    ["Verordner", "Alle"].includes(editor) &&

                    <EditVerordner
                        value={planzeile.verordner}
                        onChange={handleVerordnerChange}
                        onClose={() => {
                            setEditor(null);
                        }}

                        triggerSave={triggerSaveEditor || triggerSave}
                        setUpdated={() => {
                            // setEditorChanged(true);
                            setUpdated();
                        }}

                        setSaved={(val) => {
                            setEditorSaved(prev => ({...prev, Verordner: val}));
                        }}
                    />


                }

                {
                    zeigeEinstellungen &&

                    <div style={{marginTop: 10}}>
                        <Row>
                            <Col span={10}>
                                <h6><LabeledSwitch
                                    checked={"exportOhneBestand" in settingsChange ? settingsChange.exportOhneBestand : planzeile.exportOhneBestand}
                                    onChange={val => {
                                        setSettingsChange(prev => ({...prev, exportOhneBestand: val}));
                                        // handleChange(val, "exportOhneBestand");
                                    }}

                                    label="Arzneimittel-Favorit exportieren, wenn kein Bestand?"
                                /></h6>

                                <h6><LabeledSwitch
                                    checked={"bedarfsmedikation" in settingsChange ? settingsChange.bedarfsmedikation : planzeile.bedarfsmedikation}
                                    onChange={val => {
                                        setSettingsChange(prev => ({...prev, bedarfsmedikation: val}));
                                        // handleChange(val, "bedarfsmedikation");
                                    }}
                                    label={"Ist dieses Arzneimittel eine Bedarfsmedikation?"}
                                /></h6>

                                <h6><LabeledSwitch
                                    checked={"autIdem" in settingsChange ? settingsChange.autIdem : planzeile.autIdem}
                                    onChange={val => {
                                        setSettingsChange(prev => ({...prev, autIdem: val}));
                                        // handleChange(val, "autIdem");
                                    }}
                                    label={"Planzeile ist 'aut idem'."}
                                /></h6>

                                <h6><LabeledSwitch
                                    checked={"unklar" in settingsChange ? settingsChange.unklar : planzeile.unklar}
                                    onChange={val => {
                                        setSettingsChange(prev => ({...prev, unklar: val}));
                                    }}
                                    label={"Planzeile ist unklar."}
                                /></h6>

                                <h6>Lagerort des Arzneimittels:</h6>
                                <Select value={"lagerort" in settingsChange ? settingsChange.lagerort : planzeile.lagerort} defaultValue={"UNDEFINIERT"} onChange={val => {
                                    setSettingsChange(prev => ({...prev, lagerort: val}));
                                    // handleChange(val, "lagerort");
                                }}>
                                    <Option key={0} value={"UNDEFINIERT"}>Keine Angabe</Option>
                                    <Option key={1} value={"APOTHEKE"}>Apotheke</Option>
                                    <Option key={2} value={"PATIENT"}>Patient</Option>
                                    <Option key={3} value={"HEIM"}>Heim</Option>
                                    <Option key={4} value={"STATION"}>Station</Option>
                                </Select>
                            </Col>

                            <Col span={10}>
                                {planzeile.freigabe && <h6>Freigegeben
                                    von {planzeile.freigeber ? getUnitLangname(planzeile.freigeber) : "K/A"}</h6>}
                                {planzeile.editor &&
                                <h6>Zuletzt bearbeitet von {getUnitLangname(planzeile.editor)}</h6>}
                            </Col>

                            <Col span={4} style={{textAlign: "right"}}>
                                {/*{auth.administrator &&*/}
                                    <Popconfirm
                                        placement="left"
                                        title={<>
                                            Sind Sie sicher, dass Sie diese Planzeile löschen wollen?
                                        </>}
                                        onConfirm={handleDelete}
                                        okText="Ja, löschen"
                                        cancelText="Nein"
                                    >
                                        <Button type={"primary"} danger>Planzeile löschen</Button>
                                    </Popconfirm>
                                {/*}*/}

                            </Col>

                        </Row>
                    </div>
                }
            </Modal>
            }
        </div>
    </StatusDisplay>
}
