import React from "react";
import {Col, Row} from "antd";
import {getMedicineM2Name, getTeilbarkeitString} from "../../utilities/medicineM2Util";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {ConditionalPopover} from "../atoms/ConditionalPopover";
import {useAuth} from "../../utilities/useAuth";

const MedicineEntitlement = ({value}) => {
    const auth = useAuth();

    const teilbarkeitString = getTeilbarkeitString(value.id.medicineM2);

    return <Row>
        <ConditionalPopover trigger={"hover"} placement={"left"} content={auth.developer && <div className={"grid-auto"}>
            <span>M2:</span>
            <span>{value.id.medicineM2.m2}</span>

            <span>DRF:</span>
            <span>{value.id.medicineM2.drf} ({value.id.medicineM2.drfShort})</span>

            <span>Teilbarkeit:</span>
            <span>{teilbarkeitString}</span>
        </div>}><Col span={23}>{getMedicineM2Name(value.id.medicineM2)} (x{value.factorBundle ? mengeToString(1/value.factorBundle, {putSpace: false}) : 0}; {teilbarkeitString})</Col></ConditionalPopover>
        <Col span={1}><span style={{whiteSpace: "nowrap", marginLeft: 5}}>{value.amount}</span></Col>
    </Row>
}

export default MedicineEntitlement;