import React, {useContext, useEffect, useRef} from 'react'
import {Input, Button, List, notification} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import hotkeys from 'hotkeys-js';
import { useState } from 'react';
import { useFetch } from './../../utilities/useFetch';
import { fetchMediplanSuchergebnisByQuery, fetchMediplanSuchergebnisByHeim, fetchMediplanSuchergebnisByStation } from '../../config/fetchApiConfiguration';
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {FileTextOutlined, LoadingOutlined, StopOutlined} from "@ant-design/icons";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import useDebounce from "../../utilities/useDebounce";
import {CombinedIcon} from "../atoms/CombinedIcon";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {akteureApi, arzneimittelApi, medikationsplanApi, printjobApi} from "../../config/apiConfig";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getAkteurLangname, getUnitLangname} from "../../config/entities";


/**
  * @category Organisms
  * @method MedikationsplanSuche
  * @see {@link Medikationsplan}
 */
const MedikationsplanSuche = () => {
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();

    const medikationsplanContext = useContext(MedikationsplanContext);

    const [searchTerm, setSearchTerm] = useState("")
    const [searchTermFiltered, setSearchTermFiltered] = useState("")
    const [selectedFocus, setSelectedFocus] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [resultList, setResultList] = useState([])
    const [suchergebnisMedikationsplaene, setSuchergebnisMedikationsplaene] = useState(null);

    const [styleOverrideResultList, setStyleOverrideResultList] = useState({});

    const searchTermDebounced = useDebounce(searchTerm, 300, {instantEmpty: true});

    const sucheRef = useRef(null)
    hotkeys('f3', function (event, handler) {
        event.preventDefault();
        if (sucheRef.current)
            sucheRef.current.input.focus();
    });

    const {data, loading, reload} = useFetch(fetchMediplanSuchergebnisByQuery(searchTermFiltered));

    useEffect(() => {
        if (medikationsplanContext.searchList?.akteur) {
            const item = medikationsplanContext.searchList.akteur;
            const langname = getAkteurLangname(item);
            const bla = medikationsplanContext.searchList;
        } else {
            const moep = ":(";
        }
    }, [medikationsplanContext.searchList])

    useEffect(() => {
        setSearchTerm("");
    }, [location])

    useEffect(() => {
        if (!searchTerm) {
            setResultList([]);
        }

        setStyleOverrideResultList({});
    }, [searchTerm])

    useEffect(() => {
        if (searchTermDebounced) {
            const match = searchTermDebounced.match(/BJ-([0-9]?)([A-Za-z0-9]{9})-([A-Z]{2})/);
            if (match) {
                (async (barcode) => {
                    const url = printjobApi.getByBarcode(barcode);
                    const r = await callApiAsync({url, auth});

                    const data = r.data.OBJECT?.data;
                    if (data) {
                        const r2 = await callApiAsync({auth, url: medikationsplanApi.getByFilter({patientId: data.split(":")[3]})});
                        const mediplanId = r2.data.OBJECT?.[0]?.id;
                        if (mediplanId) {
                            gotoMedikationsplan(mediplanId);
                        }
                    }
                })(match[0]);

                setSearchTerm("");
            } else {
                setSearchTermFiltered(searchTermDebounced);
            }
        }
    }, [searchTermDebounced])

    useEffect(() => {
        if (suchergebnisMedikationsplaene) {
            const resultListNeu = suchergebnisMedikationsplaene.map(medikationsplan => ({
                medikationsplanId: medikationsplan.id,
                akteurId: medikationsplan.patient.id,
                akteurVorname: medikationsplan.patient.name,
                akteurNachname: medikationsplan.patient.surname,
            }));

            setResultList([
                {
                    akteurId: resultList[selectedIndex].akteurId,
                    akteurVorname: `${resultList[selectedIndex].akteurVorname} [Adressbuch]`,
                    akteurNachname: ""
                },
                ...resultListNeu
            ]);
            setSelectedIndex(0);
        }
    }, [suchergebnisMedikationsplaene])

    const handelChange = (e) => {
        setSearchTerm(e.target.value);
        setSelectedFocus(false);
    }

    useEffect(() => {
        if (!loading) {
            if (data) {
                // Erlaubte Akteur-Typen
                const akteurTypFilter = ["Institution", "Person"];

                const resultListNeu = [];
                for (let eintrag of data) {
                    if (akteurTypFilter.includes(eintrag.akteurTyp)) {
                        resultListNeu.push(eintrag);
                        if (resultListNeu.length === 15) {
                            break;
                        }
                    }
                }

                setResultList(resultListNeu);
            } else {
                setResultList([]);
            }
        }
    }, [data, loading])


    const handelSubmit = () => {
        reload()
    }

    const handelKeyDown = (e) => {
        const key = e.key;

        switch (key) {
            case "ArrowDown":
                e.preventDefault();
                handleIndexChange(+1);
                break;
            case "ArrowUp":
                e.preventDefault();
                handleIndexChange(-1);
                break;
            case "Enter":
                // Suchergebnis wurde ausgewählt
                if (selectedFocus) {
                    e.preventDefault();
                    handleAkteurLink(resultList[selectedIndex]);
                } else {
                    handelSubmit();
                    setSelectedIndex(null);
                }
                break;
            default:
                setSelectedIndex(null);
                setSelectedFocus(false);
        }
    }

    const gotoMedikationsplan = medikationsplanId => {
        history.push('/medikationsplan/' + medikationsplanId);
        setSearchTerm("");
    }

    const handleAkteurLink = (akteur) => {
        let searchList = {};
        let rollen = [], medikationsplanId, akteurId;
        if (akteur) {
            akteurId = akteur.akteurId;

            if (akteur.akteurRollen) {
                rollen = akteur.akteurRollen;
            }

            if (akteur.medikationsplanId) {
                medikationsplanId = akteur.medikationsplanId;
            }
        }

        let showAdressbuch = !!akteurId;

        // gewähltes Ergebnis verweist auf Pflegeheim oder Station
        if (rollen.includes("Station")) {
            if (sucheMedikationsplaene(fetchMediplanSuchergebnisByStation(akteurId))) {
                setStyleOverrideResultList({position: "relative"});
                searchList = {akteur};

                showAdressbuch = false;
            }
        } else if (rollen.includes("Pflegeheim")) {
            if (sucheMedikationsplaene(fetchMediplanSuchergebnisByHeim(akteurId))) {
                setStyleOverrideResultList({position: "relative"});
                searchList = {akteur};

                showAdressbuch = false;
            }
        }

        // gewähtles Ergebnis verweist auf UnitLangname mit Medikationsplan
        else if (medikationsplanId) {
            gotoMedikationsplan(medikationsplanId);
            searchList = prev => {
                if (!prev?.active && prev?.akteur && prev?.medikationsplaene?.length) {
                    return ({...prev, active: true});
                }

                return {};
            };
        }

        // Adressbuch zu diesem Eintrag öffnen
        else if (showAdressbuch) {
            history.push('/adressbuch/' + akteurId);
            setSearchTerm("");
        }

        medikationsplanContext.setSearchList(searchList);
    }

    const sucheMedikationsplaene = async (url) => {
        const response = await callApiAsync({auth, url});
        const medikationsplaene = response.data.OBJECT;
        if (medikationsplaene.length) {
            medikationsplaene.sort((a,b) => getUnitLangname(a.patient).localeCompare(getUnitLangname(b.patient)));
            setSuchergebnisMedikationsplaene(medikationsplaene);
            medikationsplanContext.setSearchList(prev => ({...prev, medikationsplaene}));
        } else {
            notification.info({message: "Keine Medikationspläne gefunden für diesen Eintrag."});
        }

        return !!medikationsplaene.length;
    }


    const handleIndexChange = (delta = 0) => {
        let newIndex = null
        setSelectedFocus(true)

        if (delta > 0 && resultList && resultList.length) {
            newIndex = (selectedIndex !== null) ? selectedIndex + delta : delta - 1
            if (newIndex >= resultList.length)
                newIndex = resultList.length - 1
        }

        if (delta < 0 && selectedIndex !== null && resultList && resultList.length) {
            newIndex = selectedIndex + delta
            if (newIndex < 0)
                newIndex = 0
        }

        setSelectedIndex(newIndex);
    }

    return <div style={{position: "relative", marginBottom: 20}}>

        {/*<Button*/}
        {/*    onClick={() => history.push('/medikationsplan/uebersicht')}*/}
        {/*    style={{ width: "100%", backgroundColor: "#51B588", color: "#fff" }}*/}
        {/*>*/}
        {/*    Übersicht*/}
        {/*</Button>*/}


        <Input
            value={searchTerm}
            onChange={handelChange}
            onKeyDown={handelKeyDown}
            ref={sucheRef}
            allowClear
            placeholder={"Suche"}
            prefix={loading && searchTermFiltered.length ? <LoadingOutlined /> : <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        />

        {!!resultList.length && <List
            className={"suche-medikationsplan-list"}
            style={styleOverrideResultList}
            size="small"
            pagination={null}
            dataSource={resultList}
            locale={{emptyText: <></>}}
            renderItem={(item, i) => {
                let classNames = "suche-medikationsplan-item";

                // ist ausgewählt und fokusiert
                if (i === selectedIndex) {
                    classNames += " selected";
                }

                return (<List.Item>
                    <span
                        style={{paddingLeft: 2}}
                        className={classNames}
                        onClick={() => {
                            setSelectedIndex(i);
                            handleAkteurLink(item);
                        }}
                        onMouseEnter={(e) => {
                            e.target.classList.add("hovered");
                        }}
                        onMouseLeave={(e) => {
                            e.target.classList.remove("hovered");
                        }}
                    >
                        {/*{!!item.medikationsplanId && <FileTextOutlined style={{marginRight: 5}} />}*/}
                        {!!item.medikationsplanId && <CombinedIcon className={"icon"}><FileTextOutlined /></CombinedIcon>}
                        {!item.medikationsplanId && item.akteurTyp === "Person" && <CombinedIcon className={"icon"}><FileTextOutlined /><CloseOutlined /></CombinedIcon>}
                        {getAkteurLangname(item)}
                    </span>
                </List.Item>)

            }}
        />}

        {!!medikationsplanContext.searchList?.akteur && medikationsplanContext.searchList.active && <span className={"suche-medikationsplan-item selected"}>
            {getAkteurLangname(medikationsplanContext.searchList.akteur)}
        </span>}


    </div>
}

export default MedikationsplanSuche
