import React, {useEffect, useState} from 'react'
import 'moment/locale/de'

import {Col, Input, notification, Row, Table} from 'antd';

import {useDataNode} from './../../utilities/useDataNode';
import {EINNAHMEZEITEN_FUENFFACH} from './../../config/misc';
import {InputMenge} from './../atoms/InputMenge';
import {callApiAsync} from "../../utilities/apiUtil";
import {arzneimittelApi} from "../../config/apiConfig";
import {ArzneimittelAlternativeIcon} from "./ArzneimittelAlternative";
import {useAuth} from "../../utilities/useAuth";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {WEEKDAYS_ALL} from "../../utilities/dateUtil";
import {checkTeilbarkeitAbgabezeit} from "../../utilities/medicineM2Util";
import {useApi} from "../../utilities/useApi";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";

/**
 * Read-Only Anzeige eines Abgabemusters
 * @param {Object} abgabemuster 
 * @param {Object} wiederholungsTag 1 = täglich, 7 = wöchentlich, 30 = monatlich. sonst auch frei wählbar
 * @param {Object} start 
 */
export const Abgabemuster = ({ abgabemuster, wiederholungsTag, start, faktorMenge = 1, planzeile, arzneimittel }) => {

    return (<div>
        {
            abgabemuster && abgabemuster.map((e, i) => {

                return <Abgabetag
                    key={i}
                    abgabetag={e}
                    start={start}
                    wiederholungsTag={wiederholungsTag}

                    faktorMenge={faktorMenge}
                    planzeile={planzeile}
                    arzneimittel={arzneimittel}
                />

            })
        }
    </div >)

}



const Abgabetag = ({ abgabetag, wiederholungsTag, start, faktorMenge, planzeile, arzneimittel, span }) => {


    return (<div>
        <hr />
        <Row>
            <Col span={span}>
                {(wiederholungsTag === 1) &&
                    <h6>
                        täglich
                    </h6>
                }


                {(wiederholungsTag === 7) &&
                    <h6>
                        {WEEKDAYS_ALL[abgabetag.tag - 1].bezeichnung}
                    </h6>
                }


                {(wiederholungsTag !== 1 && wiederholungsTag !== 7) &&
                    <h6>
                        <Input
                            value={abgabetag.tag}
                        />
                    </h6>
                }
            </Col>

            <Col span={18}>
                {abgabetag.sollZeitgenau &&
                    <EinnahmeZeiten
                        zeiten={abgabetag.zeiten}
                        einheit={planzeile?.arzneimittel?.einheit}
                        planzeile={planzeile}

                        arzneimittel={arzneimittel}
                        faktorMenge={faktorMenge}
                    />
                }

                {!abgabetag.sollZeitgenau &&
                    <EinnahmeZeitenFaecherEdit
                        value={abgabetag.zeiten}

                        faktorMenge={faktorMenge}
                        planzeile={planzeile}
                        arzneimittel={arzneimittel}
                        einheit={planzeile?.arzneimittel?.einheit}
                    />
                }

            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
                {/* {(wiederholungsTag !== 1) &&
                    <Button onClick={() => handleChange(null)} ><DangerOutlined /></Button>
                } */}
            </Col>

        </Row>

    </div >)

}





const EinnahmeZeitenFaecherEdit = ({ value, onChange, faktorMenge, planzeile, arzneimittel, einheit }) => {

    const {
        current: zeiten,
        status,
        handleChange,
    } = useDataNode({
        value,
        onChange,
    })


    const zeitenSortiert = zeiten
        .reduce((result, zeit, index) => {
            result[zeit.fachNummer] = { index, zeit }
            return result
        },
            EINNAHMEZEITEN_FUENFFACH.map((e, index) => {
                return {
                    zeit: {
                        menge: "0",
                        zeit: e.zeitfenster.default,
                        fachNummer: e._id
                    },
                    index: -1 * (index + 1)
                }
            })
        )



    if (!status.ready) return <></>

    return (<Row>
        <EinnahmeZeitenFachEdit
            value={zeitenSortiert[0].zeit}
            onChange={e => handleChange(e, zeitenSortiert[0].index)}
            faktorMenge={faktorMenge}
            arzneimittel={arzneimittel}
            planzeile={planzeile}
            span={4}
            einheit={einheit}
        />
        <Col span={1}/>

        <EinnahmeZeitenFachEdit span={4} value={zeitenSortiert[1].zeit} onChange={e => handleChange(e, zeitenSortiert[1].index)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} einheit={einheit} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} value={zeitenSortiert[2].zeit} onChange={e => handleChange(e, zeitenSortiert[2].index)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} einheit={einheit} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} value={zeitenSortiert[3].zeit} onChange={e => handleChange(e, zeitenSortiert[3].index)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} einheit={einheit} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} value={zeitenSortiert[4].zeit} onChange={e => handleChange(e, zeitenSortiert[4].index)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} einheit={einheit} />

    </Row>)

}


const EinnahmeZeitenFachEdit = ({ value, onChange, faktorMenge, planzeile, arzneimittel, span, einheit }) => {

    const auth = useAuth();
    const api = useApi();

    const [istTeilbar, setIstTeilbar] = useState(true);

    const {
        current: zeit,
        status,
        handleChange,
    } = useDataNode({
        value,
        onChange,
    })

    useEffect(() => {
        setIstTeilbar(checkTeilbarkeitAbgabezeit({abgabeZeit: zeit, planzeile, api}));
    }, [zeit])

    if (!status.ready) return <></>

    const faktor = zeit.faktor ? 1/zeit.faktor : 1/faktorMenge;
    return <Col span={span} style={{ textAlign: "center" }}>
        <div>
            <InputMenge
                disabled={true}
                label="menge"
                value={zeit.menge}
                onChange={handleChange}

                faktorMenge={faktor}
                faktorLabel={!!faktor && (() => getArzneimittelLangname(planzeile.arzneimittel))}
                styleClass={[...(zeit.faktor ? ["input-menge-alternative"] : []), ...(!istTeilbar ? ["input-menge-nicht-teilbar"] : [])]}

                einheit={einheit}
                suffix={!istTeilbar && zeit.teilungExplizitErlaubt ? "!" : null}
            />

            <div>
                {zeit.zeit.substr(0, 5)}
                <ArzneimittelAlternativeIcon
                    einnahmezeit={zeit}
                    planzeile={planzeile}
                    showRemove={true}

                    pickArzneimittel={async arzneimittel => {
                        if (planzeile && planzeile.arzneimittel && arzneimittel) {
                            const response = await callApiAsync({auth, url: arzneimittelApi.getSubstitutFaktor(planzeile.arzneimittel.m2, arzneimittel.m2), onError: [{message: "medicine item is no substitute.", callback: () => {
                                        notification.error({message: "Das ausgewählte Arzneimittel ist kein gültiges Substitut."});
                                    }}]});

                            handleChange(arzneimittel, "arzneimittel");
                            handleChange(1 / response.data.OBJECT[Object.keys(response.data.OBJECT)[0]], "faktor");
                        } else {
                            handleChange(arzneimittel, "arzneimittel");
                            handleChange(null, "faktor");
                        }
                    }}
                />
            </div>
        </div>
    </Col>

}



const EinnahmeZeiten = ({ zeiten, einheit, planzeile, arzneimittel, faktorMenge }) => {
    const api = useApi();

    const [sortedZeiten, setSortedZeiten] = useState([]);
    const [zeitTeilbarMap, setZeitTeilbarMap] = useState(null);

    useEffect(() => {
        setSortedZeiten(zeiten.sort(
            (e1, e2) => e1.zeit > e2.zeit
        ));

        const map = {};
        for (let abgabeZeit of zeiten) {
            map[abgabeZeit.zeit] = checkTeilbarkeitAbgabezeit({abgabeZeit, planzeile, api});
        }
        setZeitTeilbarMap(map);
    }, [zeiten])

    return (<Table
        dataSource={sortedZeiten}
        columns={[
            {
                title: 'genaue Uhrzeit',
                dataIndex: 'zeit',
            },
            {
                title: 'Menge',
                render: (abgabeZeit) => {
                    const istTeilbar = !zeitTeilbarMap || zeitTeilbarMap[abgabeZeit.zeit];
                    return `${mengeToString(+abgabeZeit.menge * (faktorMenge || 1))} ${einheit ? einheit : ""}${!istTeilbar && abgabeZeit.teilungExplizitErlaubt ? "!" : ""}`
                },
                onCell: (abgabeZeit) => {
                    const style = {};
                    const istTeilbar = !zeitTeilbarMap || zeitTeilbarMap[abgabeZeit.zeit];

                    if (!istTeilbar) style.backgroundColor = "#FFADD2FF";

                    return { style }
                },
            },
        ]}

    />)

}