import React, {createContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import {callApiAsync} from "../utilities/apiUtil";
import {accountApi, SERVER_ADRESSE} from "../config/apiConfig";
import {fetchAktuelleNutzerDaten} from "../config/fetchApiConfiguration";

const REFRESH_TOKEN_MIN_TIME_LEFT = 60 * 1000;
const REFRESH_TOKEN_MAX_TIME_LEFT = 10 * 60 * 1000;

const initVal = {
    showLoginNotification: false,
    setShowLoginNotification: () => {},
    setLoginTimeout: () => {},
    lastLogin: null,
    setLastLogin: () => {},
    loginState: 'NO_LOGIN',
    setLoginState: () => {},

    auth: {
        userId: -1,
        administrator: false,
        developer: false,
        userName: "",
        userRoles: [],
        token: "",
        expires: "",

        error: {},
        success: false,
        loading: false,

        handleLogin: () => {},
        handleLogout: () => {},
        refreshToken: () => {},
        addLoginListener: (listener={onSuccess:()=>{}, onError:()=>{}, url:"none"}) => {},
    }
};

const ITEM_AUTH_KEY = 'AUTH';

const LoginContext = createContext(initVal);
export default LoginContext;

export const useInitialState = () => {

    const loginTimeoutRef = useRef(null);
    const [showLoginNotification, setShowLoginNotification] = useState(false);
    const [disableWindow, setDisableWindow] = useState(false);
    const [loginTimeout, setLoginTimeout] = useState(0);
    const [lastLogin, setLastLogin] = useState(null);

    const [loginState, setLoginStateState] = useState('NO_LOGIN');
    const loginStateRef = useRef("");
    const loginListeners = useRef({});

    const [authObject, setAuthObject] = useState(null);
    const [error, seterror] = useState(null);
    const [success, setsuccess] = useState(false);
    const [loading, setloading] = useState(false);

    const [authReturn, setAuthReturn] = useState({});


    useEffect(() => {
        if (disableWindow) {
            console.log("disabling window");
        }
    }, [disableWindow])

    useEffect(() => {
        if (loginTimeout === 0) return;

        if (loginTimeoutRef.current) {
            clearTimeout(loginTimeoutRef.current);
        }

        loginTimeoutRef.current = setTimeout(() => {
            localStorage.removeItem(ITEM_AUTH_KEY);
            setAuthObject(null);
        }, loginTimeout);
    }, [loginTimeout]);

    // Listener für localStorage bei Änderungen von anderem Fenster
    useEffect(() => {
        const getLocalStorage = () => {
            if (disableWindow) {
                return;
            }

            const itemStored = localStorage.getItem(ITEM_AUTH_KEY);
            const authObjectNew = itemStored ? JSON.parse(itemStored) : null;

            setAuthObject(authObjectNew);
        };

        getLocalStorage();
        window.addEventListener('storage', getLocalStorage);
        return () => window.removeEventListener('storage', getLocalStorage);
    }, []);

    // Wenn sich der Eintrag im localStorage Ändert
    useEffect(() => {
        if (authObject) {
            const loginTimeoutNeu = moment(authObject.expiry).diff(Date.now());
            if (loginTimeoutNeu < 0) return;

            setLoginTimeout(loginTimeoutNeu);
            setLastLogin(authObject.lastLogin);
            setLoginState('LOGIN');
            setShowLoginNotification(false);

            Object.values(loginListeners.current).forEach(async listener => {
                handleOnSuccess(listener, getAuthReturn(authObject));
            });
            loginListeners.current = {};
        } else {
            setLastLogin(null);
            setLoginState('NO_LOGIN');
            if (loginState === 'LOGIN') setShowLoginNotification(true);
        }
    }, [authObject]);

    useEffect(() => {
        const authReturnNeu = getAuthReturn(authObject);
        setAuthReturn(authReturnNeu);
    }, [authObject, error, success, loading])

    const getAuthReturn = (authObject, skipTokenRefresh=false) => {
        return {
            userId: authObject?.user?.id || null,
            administrator: authObject?.user?.administrator || null,
            developer: authObject?.user?.developer || null,
            userName: authObject?.user?.name || null,
            userRoles: authObject?.user?.roles || null,
            token: authObject?.token || null,
            expires: authObject?.expiry || null,

            error,
            success,
            loading,

            skipTokenRefresh,

            handleLogin,
            handleLogout,
            refreshToken,
            addLoginListener,
        }
    }

    const setLoginState = (stateNeu) => {
        if (loginStateRef.current !== stateNeu) {
            loginStateRef.current = stateNeu;
            setLoginStateState(stateNeu);
        }
    }

    const handleLogout = () => {
        localStorage.removeItem(ITEM_AUTH_KEY);

        window.location.href = window.location.href + '#logout';
        window.location.reload();
    };
    
    const handleOnSuccess = (listener, authNeu) => {
        if (listener.onSuccessArray) {
            listener.onSuccessArray.forEach(onSuccess => onSuccess(authNeu))
        } else {
            listener.onSuccess(authNeu);
        }
    }
    
    const handleOnError = (listener, error) => {
        if (listener.onErrorArray) {
            listener.onErrorArray.forEach(onError => onError(error))
        } else {
            listener.onError(error);
        }
    }

    const handleLogin = async (username = null, password = null, redirect = true, onSuccess=()=>{}, onError=()=>{}) => {
        if (disableWindow) {
            return null;
        }

        if (loginStateRef.current === "LOGIN") {
            onSuccess();
        } else {
            addLoginListener({url: "login", onSuccess, onError});

            if (loginStateRef.current === "NO_LOGIN") {
                setLoginState("PENDING");

                try {
                    let response;
                    // Auth über Login Form
                    if (username && password) {

                        response = await callApiAsync({auth: { username: username, password: password }, url: accountApi.login()});

                        if (response.data.RESULT !== "SUCCESS") {
                            seterror({ message: "Fehler bei der Anmeldung", description: "Sind Benutzername und Passwort korrekt?" });
                            setloading(false);

                            Object.values(loginListeners.current).forEach(async listener => handleOnError(listener, response));
                            return null;
                        }

                    }

                    // falls gültiges token in cookie gespeichert, verwende dies
                    else if (authObject) {
                        response = await callApiAsync({auth: { token: authObject.token, addLoginListener, skipTokenRefresh: true }, url: accountApi.login()});

                        if (response.data.RESULT !== "SUCCESS") {
                            seterror({ message: "Kann Token nicht erneuern" });
                            setloading(false);

                            Object.values(loginListeners.current).forEach(async listener => handleOnError(listener, response));
                            return null;
                        }
                    }

                    // weder username/password noch token
                    else {
                        Object.values(loginListeners.current).forEach(async listener => handleOnError(listener, response));
                        return null;
                    }

                    const authObjectNew = {lastLogin: moment().format(), token: response.data.AUTH_TOKEN, expiry: moment(response.data.AUTH_TOKEN_EXPIRATION_DATE).format()};
                    const responseUserInfo = await callApiAsync({auth: { token: authObjectNew.token, addLoginListener }, url: fetchAktuelleNutzerDaten()});

                    let userUnit = {};
                    if (responseUserInfo.data.RESULT === "SUCCESS") {
                        userUnit = { ...responseUserInfo.data.OBJECT };
                        userUnit.administrator = isAdministrator(userUnit);
                        userUnit.developer = isDeveloper(userUnit);
                    }

                    authObjectNew.user = userUnit;
                    setsuccess(true);

                    localStorage.setItem(ITEM_AUTH_KEY, JSON.stringify(authObjectNew));
                    setAuthObject(authObjectNew);

                    if (redirect) {
                        loginStateRef.current = [];
                        window.location.reload();
                    }

                    return authObjectNew.token;
                } catch (error) {
                    setLoginState("NO_LOGIN");

                    if (error.message === "Network Error")
                        seterror({ message: error.message, description: "Der Server an " + SERVER_ADRESSE + " ist nicht erreichbar" });
                    else
                        seterror({ message: error.message, description: JSON.stringify(error) });
                    setloading(false);

                    Object.values(loginListeners.current).forEach(async listener => handleOnError(listener, error));
                    loginListeners.current = {};

                    return null;
                }
            }
        }
    };

    const refreshToken = () => {
        const now = Date.now();
        const diffExpiry = authObject ? moment(authObject.expiry).diff(now) : -1;
        if (diffExpiry < REFRESH_TOKEN_MIN_TIME_LEFT) {
            localStorage.removeItem(ITEM_AUTH_KEY);
            setAuthObject(null);
            return Promise.resolve(null);
        }

        // solange das Token noch nicht erneuert werden soll
        if (diffExpiry > REFRESH_TOKEN_MAX_TIME_LEFT) {
            return Promise.resolve(authObject.token);
        }

        // anmelden ohne benutzername, also mit aktuellem token, aber nicht weiterleiten
        return new Promise( (onSuccess, onError) => {
            if (loginStateRef.current !== "PENDING") setLoginState("NO_LOGIN");
            handleLogin(null, null, false, (authNeu) => onSuccess(authNeu.token), onError);
        });
    };

    const addLoginListener = (listener) => {
        if (loginStateRef.current !== "LOGIN") {
            if (listener) {
                if (authObject && authObject.expiry && moment(authObject.expiry).diff(Date.now()) > 0) {
                    if (loginListeners.current[listener.url]) {
                        const listenerOld = loginListeners.current[listener.url];
                        const onSuccessArray = listenerOld.onSuccessArray ? [...listenerOld.onSuccessArray, listener.onSuccess] : [listener.onSuccess];
                        const onErrorArray = listenerOld.onErrorArray ? [...listenerOld.onErrorArray, listener.onError] : [listener.onError];

                        loginListeners.current[listener.url] = {onSuccessArray, onErrorArray};
                    } else {
                        loginListeners.current[listener.url] = {
                            onSuccessArray: [listener.onSuccess],
                            onErrorArray: [listener.onerror]
                        };
                    }
                }

                return true;
            }
        }

        return false;
    }

    const isAdministrator = unit => {
        const administratorNames = ["niebergall", "raber", "schnitzler"];
        return administratorNames.includes(unit.name.toLowerCase());
    }

    const isDeveloper = unit => {
        const developerNames = ["niebergall"];
        return developerNames.includes(unit.name.toLowerCase());
    }

    return {
        showLoginNotification,
        setShowLoginNotification,
        disableWindow,
        setDisableWindow,
        setLoginTimeout,
        lastLogin,
        setLastLogin,
        loginState,
        setLoginState,
        auth: authReturn
    };
};