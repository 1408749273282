import {useCallback, useContext} from "react";
import {
    fetchPlanzeilenFreigabeFehlt,
    fetchPlanzeilenReichweiteAlarm,
    fetchPlanzeilenVorab,
    fetchPlanzeilenAnforderungNoetig, fetchPlanzeilenUnklar
} from "../config/fetchApiConfiguration";
import ApiContext from "../contexts/ApiContext";
import {useAuth} from "./useAuth";
import {callApiAsync} from "./apiUtil";
import {REICHWEITE_ALARM, REICHWEITE_MINIMAL} from "../config/misc";
import moment from "moment";
import {GlobalContext} from "../config/globalContext";

export const useApiPlanzeilen = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);
    const globalContext = useContext(GlobalContext);

    const loadPlanzeilenVorab = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchPlanzeilenVorab(), refreshToken});
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenVorab: response.data.OBJECT}));
    }

    const removePlanzeileVorab = planzeile => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenVorab: prev.planzeilenVorab.filter(p => p.id !== planzeile.id)}));
    }

    const loadPlanzeilenFreigabeFehlt = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchPlanzeilenFreigabeFehlt(), refreshToken});
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenFreigabeFehlt: response.data.OBJECT}));

        const patientenFreigabeFehlt = {};
        for (let planzeile of response.data.OBJECT) {
            if (!planzeile.medikationsplan) {
                continue;
            }

            if (!patientenFreigabeFehlt[planzeile.medikationsplan.patient.id]) {
                patientenFreigabeFehlt[planzeile.medikationsplan.patient.id] = {id: planzeile.medikationsplan.patient.id, patient: planzeile.medikationsplan.patient, medikationsplan: planzeile.medikationsplan};
            }
        }
        apiContext.setPlanzeilenData(prev => ({...prev, patientenFreigabeFehlt: Object.values(patientenFreigabeFehlt)}));
    }

    const removePlanzeileFreigabeFehlt = planzeile => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenFreigabeFehlt: prev.planzeilenFreigabeFehlt.filter(p => p.id !== planzeile.id)}));
    }

    const loadPlanzeilenUnklar = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchPlanzeilenUnklar(), refreshToken});
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenUnklar: response.data.OBJECT}));
    }

    const removePlanzeileUnklar = planzeile => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenUnklar: prev.planzeilenUnklar.filter(p => p.id !== planzeile.id)}));
    }

    const loadPlanzeilenAnforderungenNoetig = async (datumAnforderung, refreshToken=true) => {
        // const response = await callApiAsync({auth, url: fetchPlanzeilenReichweiteAlarm(REICHWEITE_ALARM, globalContext.heute.format("YYYYMMDD")), refreshToken});
        const response = await callApiAsync({auth, url: fetchPlanzeilenAnforderungNoetig(moment(datumAnforderung).format("YYYYMMDD")), refreshToken});
        updatePlanzeilenAnforderungenNoetig(response.data.OBJECT);
    }

    const updatePlanzeilenAnforderungenNoetig = planzeilenAnforderungenNoetig => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenAnforderungenNoetig}));
    }

    const removePlanzeileAnforderungenNoetig = planzeile => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenAnforderungenNoetig: prev.planzeilenAnforderungenNoetig.filter(p => p.id !== planzeile.id)}));
    }

    const loadPlanzeilenAnforderungenMoeglich = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: fetchPlanzeilenReichweiteAlarm(REICHWEITE_MINIMAL, globalContext.heute.format("YYYYMMDD")), refreshToken});
        const filtered = response.data.OBJECT.filter(planzeile => moment(planzeile.reichweiteAnspruch).diff(moment(), "weeks") >= REICHWEITE_ALARM);
        updatePlanzeilenAnforderungenMoeglich(filtered);
    }

    const updatePlanzeilenAnforderungenMoeglich = planzeilenAnforderungenMoeglich => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenAnforderungenMoeglich}));
    }

    const removePlanzeileAnforderungenMoeglich = planzeile => {
        apiContext.setPlanzeilenData(prev => ({...prev, planzeilenAnforderungenMoeglich: prev.planzeilenAnforderungenMoeglich.filter(p => p.id !== planzeile.id)}));
    }

    return {
        planzeilenVorab: apiContext.planzeilenData.planzeilenVorab ? apiContext.planzeilenData.planzeilenVorab : [],
        planzeilenVorabLoaded: !!apiContext.planzeilenData.planzeilenVorab,
        removePlanzeileVorab,
        loadPlanzeilenVorab,

        patientenFreigabeFehlt: apiContext.planzeilenData.patientenFreigabeFehlt ? apiContext.planzeilenData.patientenFreigabeFehlt : [],
        planzeilenFreigabeFehlt: apiContext.planzeilenData.planzeilenFreigabeFehlt ? apiContext.planzeilenData.planzeilenFreigabeFehlt : [],
        planzeilenFreigabeFehltLoaded: !!apiContext.planzeilenData.planzeilenFreigabeFehlt,
        removePlanzeileFreigabeFehlt,
        loadPlanzeilenFreigabeFehlt,

        planzeilenUnklar: apiContext.planzeilenData.planzeilenUnklar ? apiContext.planzeilenData.planzeilenUnklar : [],
        planzeilenUnklarLoaded: !!apiContext.planzeilenData.planzeilenUnklar,
        removePlanzeileUnklar,
        loadPlanzeilenUnklar,

        planzeilenAnforderungenNoetig: apiContext.planzeilenData.planzeilenAnforderungenNoetig ? apiContext.planzeilenData.planzeilenAnforderungenNoetig : [],
        planzeilenAnforderungenNoetigLoaded: !!apiContext.planzeilenData.planzeilenAnforderungenNoetig,
        removePlanzeileAnforderungenNoetig,
        loadPlanzeilenAnforderungenNoetig,

        planzeilenAnforderungenMoeglich: apiContext.planzeilenData.planzeilenAnforderungenMoeglich ? apiContext.planzeilenData.planzeilenAnforderungenMoeglich : [],
        planzeilenAnforderungenMoeglichLoaded: !!apiContext.planzeilenData.planzeilenAnforderungenMoeglich,
        removePlanzeileAnforderungenMoeglich,
        loadPlanzeilenAnforderungenMoeglich,
    }
}