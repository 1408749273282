import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useSchnellsuche } from '../../utilities/useSchnellsuche'
import { akteureApi } from '../../config/apiConfig'
import {defaultAkteur, getUnitLangname} from '../../config/entities'
import {Button, Dropdown, Input, Menu, notification, Popover} from "antd";
import {useAuth} from "../../utilities/useAuth";
import {callApiAsync} from "../../utilities/apiUtil";
import {ConditionalTooltip} from "./ConditionalTooltip";
import {useOutsideAlerter} from "../../utilities/useOutsideAlerter";
import {getApplicationKeyMap, HotKeys} from "react-hotkeys";
import * as moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import {Tooltip} from "./Tooltip";
import {createPortal} from "react-dom";

export const AkteurPicker = ({
                                 value,
                                 onChange=()=>{},
                                 clearOnChange=false,
                                 rolle = null,
                                 insitution = false,
                                 inputRef,
                                 inputStyle = {},
                                 classNameInput,
                                 erstelleNeu = null,
                                 onChangeInputText=(text)=>{},
                                 placeholder,
                                 wrap="none",
                                 display="flex",
                                 containerStyle={},
                                 classNameContainer,
                                 suffix=null,
                                 deleteInSuffix=false,
                                 setSchnellsucheRef=null,
                                 autofocus=false,
}) => {
    const auth = useAuth();

    // const [freitext, setFreitext] = useState(freitext)
    const akteurLast = useRef(null);
    const [akteur, setAkteur] = useState(value);
    const [focusState, setFocusState] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(true);
    const [erstelleNeuSelectVisible, setErstelleNeuSelectVisible] = useState(false);
    const [btnNewDisabled, setBtnNewDisabled] = useState(true);
    const [btnDeleteDisabled, setBtnDeleteDisabled] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const dropdownRef = useRef();
    const dropdownParentRef = useRef();
    const erstelleNeuPopoverRef = useRef();

    const clickOutside = useOutsideAlerter(dropdownRef);
    const clickOutsideErstelleNeuPopover = useOutsideAlerter(erstelleNeuPopoverRef);

    const schnellsuche = useSchnellsuche({
        placeholder: getUnitLangname(akteur),
        searchAfterDelay: 500,
        getUrlBySearchText: (t) => akteureApi.find(t, rolle, insitution),

        filterSearchText: searchText => {
            const searchTextFiltered = searchText.replace(",", " ");
            return searchTextFiltered;
        },

        onChangeInputText: text => {
            if (!!text) {
                setBtnNewDisabled(false);
            } else {
                setBtnNewDisabled(true);
            }

            onChangeInputText(text);
        },
    })

    if (setSchnellsucheRef) setSchnellsucheRef.current = schnellsuche.setValue;

    useEffect(() => {
        setAkteur(value)
    }, [value])

    useEffect(() => {
        akteurLast.current = akteur;
        if (akteur) {
            setBtnDeleteDisabled(false);
            setBtnNewDisabled(true);
        } else {
            setBtnDeleteDisabled(true);
        }
    }, [akteur])

    useEffect(() => {
        if (schnellsuche.value) {
            onChangeInputText(schnellsuche.value);
        }
    }, [schnellsuche.value])

    useEffect(() => {
        if (focusState && schnellsuche.result && !!schnellsuche.result.length) {
            setDropdownVisible(true);
        }
    }, [schnellsuche.result, focusState])

    useEffect(() => {
        if (clickOutside) {
            setDropdownVisible(false);
        }
    }, [clickOutside])

    useEffect(() => {
        if (clickOutsideErstelleNeuPopover) {
            setErstelleNeuSelectVisible(false);
        }
    }, [clickOutsideErstelleNeuPopover])

    const keyHandler = (e, key) => {
        e.preventDefault();

        const max = schnellsuche.result.length - 1;
        const current = selectedKeys.length ? parseInt(selectedKeys[0]) : -1;

        switch (key) {
            case "UP":
                if (current > 0) {
                    setSelectedKeys([`${current - 1}`])
                } else {
                    setSelectedKeys([]);
                }
                break;
            case "DOWN":
                if (current < max) {
                    setSelectedKeys([`${current + 1}`])
                }
                break;
        }
    }

    const handleChange = (e) => {
        schnellsuche.handleselect(clearOnChange ? "" : getUnitLangname(e), []);
        onChange(e, akteurLast.current);
    }

    const handleNeu = async (type) => {
        let akteurNeu;
        let nameSplit;
        let title = null;
        switch (type) {
            case "praxis":
                if (schnellsuche.value) {
                    akteurNeu = {
                        ...defaultAkteur(type),
                        name: schnellsuche.value
                    };
                }
                break;
            case "arzt":
            // title = "Dr. med.";
            case "patient":
                if (schnellsuche.value) {
                    nameSplit = schnellsuche.value.split(",");
                    akteurNeu = {
                        ...defaultAkteur(type),
                        surname: nameSplit[0].trim(),
                        name: nameSplit.length > 1 ? nameSplit[1].trim() : null
                    };
                }
                break;
            default:
                akteurNeu = {...defaultAkteur(type), name: schnellsuche.value};
                break;
        }

        if (akteurNeu) {
            const response = await callApiAsync({auth, url: akteureApi.post(), method: 'POST', data: akteurNeu});
            const akteur = response.data.OBJECT;

            handleChange(akteur);
            notification.info({message: `Neue ${akteur.type === "Person" ? "Person" : "Einrichtung"} angelegt mit ${akteur.type === "Person" ? "dem Namen" : "der Bezeichnung"} ${getUnitLangname(akteur)}`});
        }
    }

    let neuTooltip = null;
    if (erstelleNeu) {
        if (erstelleNeu.select) {
            const labels = erstelleNeu.select.reduce((labels, elem) => [...labels, elem.label], []).sort();
            neuTooltip = "Erstellt einen neuen Eintrag vom Typ " + labels.join(" oder ");
        } else {
            switch (erstelleNeu.type) {
                case "patient":
                case "arzt":
                    neuTooltip = "Erstellt einen neuen Eintrag mit angegebenem Namen im Format [Nachname, Vorname]";
                    break;
                case "praxis":
                case "einrichtung":
                default:
                    neuTooltip = "Erstellt einen neuen Eintrag mit angegebener Bezeichnung";
                    break;
            }
        }
    }

    const keyMap = getApplicationKeyMap();

    return <div style={{whiteSpace: "nowrap"}} className={"divAkteurPicker"}>
        <span style={{display, ...containerStyle}} className={classNameContainer}>
            <Dropdown
                overlay={(
                    <div ref={dropdownRef} className={"akteurPickerDropdown"} style={{ maxHeight: 300, overflowY: "auto" }}>
                        <Menu selectable={true} selectedKeys={selectedKeys}>
                            {schnellsuche.result && schnellsuche.result.map((r, i) => {
                                return <Menu.Item key={i} onClick={() => handleChange(r)}>
                                    <span>{getUnitLangname(r)}{!!r.dateOfBirth && ` (*${moment(r.dateOfBirth).format("DD.MM.YYYY")})`}</span>
                                </Menu.Item>
                            })}
                        </Menu>
                    </div>
                )}

                getPopupContainer={() => dropdownParentRef.current}

                visible={dropdownVisible}
            >
                <>
                    <Input
                        {...schnellsuche.inputProps}
                        loading=""
                        handleselect=""
                        style={{...schnellsuche.style, ...inputStyle}}
                        placeholder={value ? getUnitLangname(value) : placeholder}
                        onFocus={() => setFocusState(true)}
                        onBlur={() => setFocusState(false)}

                        ref={inputRef}

                        allowClear={true}
                        onChange={e => {
                            schnellsuche.handleselect("", []);
                            schnellsuche.inputProps.onChange(e);
                        }}

                        className={classNameInput}
                        autoFocus={autofocus}

                        onKeyDown={e => {
                            switch (e.key) {
                                case "ArrowDown":
                                    keyHandler(e, "DOWN");
                                    break;
                                case "ArrowUp":
                                    keyHandler(e, "UP");
                                    break;
                                case "Enter":
                                    if (!!selectedKeys.length) {
                                        handleChange(schnellsuche.result[parseInt(selectedKeys[0])]);
                                    }
                                    break;
                            }
                        }}

                        suffix={!!suffix || deleteInSuffix ? <>{suffix} {deleteInSuffix ? <Tooltip title={"Verknüpfung löschen"}><a
                            tabIndex={-1}
                            onClick={() => {
                                schnellsuche.handleselect("", []);
                                onChangeInputText(null);
                                onChange(null);
                            }}
                            style={{marginLeft: 5}}
                        ><DeleteOutlined /></a></Tooltip> : ""}</> : undefined}
                    />

                    {createPortal(<div ref={dropdownParentRef} />, document.getElementById('portal'))}
                </>
            </Dropdown>

            {wrap === "buttons" && <br/>}
            {!deleteInSuffix && <Button
                disabled={btnDeleteDisabled}
                tabIndex={-1}
                onClick={() => {
                    schnellsuche.handleselect("", []);
                    onChangeInputText(null);
                    onChange(null);
                }}
            >löschen</Button>}
            {!!erstelleNeu && !erstelleNeu.select && <ConditionalTooltip placement={"right"} title={neuTooltip}><Button disabled={btnNewDisabled} tabIndex={-1} onClick={async () => {
                if (!schnellsuche.value) {
                    alert("Bitte geben Sie einen Namen ein");
                } else {
                    handleNeu(erstelleNeu.type);
                }
            }}>neu</Button></ConditionalTooltip>}
            {erstelleNeu?.select && <Popover visible={erstelleNeuSelectVisible} content={<div ref={erstelleNeuPopoverRef}>
                <h6>Erstelle Eintrag von Typ:</h6>
                {erstelleNeu.select.map((val, i) => <Tooltip key={i} placement={"right"} title={val.tooltip || neuTooltip}><Button tabIndex={-1} key={i} onClick={() => {
                    setErstelleNeuSelectVisible(false);
                    handleNeu(val.type);
                }}>{val.label}</Button></Tooltip>)}
            </div>}><Button disabled={btnNewDisabled} tabIndex={-1} onClick={async () => {
                if (!schnellsuche.value) {
                    alert("Bitte geben Sie einen Namen ein");
                } else {
                    setErstelleNeuSelectVisible(true);
                }
            }}>neu</Button></Popover>}
        </span>
    </div>

}



export const AkteurPickerListe = ({ value, onChange, rolle = null }) => {

    // const [freitext, setFreitext] = useState(freitext)
    const [akteure, setAkteure] = useState(value)

    useEffect(() => {
        setAkteure(value)
    }, [value])

    const schnellsuche = useSchnellsuche({
        placeholder: "(neuer Eintrag)",
        value: "",
        searchAfterDelay: 300,
        getUrlBySearchText: (t) => akteureApi.find(t, rolle),

        filterSearchText: searchText => {
            const searchTextFiltered = searchText.replace(",", " ");
            return searchTextFiltered;
        },
    })

    const handleDelete = (e) => {
        let newAkteure = akteure.filter(a => a.id !== e.id)
        setAkteure(newAkteure)
        onChange(newAkteure)
    }

    const handleInsert = (e) => {
        let newAkteure
        if (akteure && Array.isArray(akteure))
            newAkteure = [e, ...akteure.filter(a => a.id !== e.id)]
        else
            newAkteure = [e]

        setAkteure(newAkteure)
        onChange(newAkteure)
        schnellsuche.handleselect("", []);
    }

    return <div>
        {akteure && akteure.map((r, i) =>
            <div key={i} onClick={() => { }}>
                <button
                    onClick={() => {
                        handleDelete(r)
                    }}
                >löschen</button>
                {getUnitLangname(r)}
            </div>
        )}
        <Input {...schnellsuche.inputProps} loading={schnellsuche.loading} handleselect="" />
        {/* <button
            onClick={() => {
                schnellsuche.handleselect("", []);
                onChange([])
            }}
        >leeren</button> */}

        <div style={{
            zIndex: 2000000,
            position: "absolute",
            backgroundColor: "#ccc",
            border: '2px solid black',
            visibility: (schnellsuche.result && schnellsuche.result.length) ? 'visible' : 'hidden'
        }}>
            {schnellsuche.result && schnellsuche.result.map((r, i) =>
                <div key={i} onClick={() => { handleInsert(r) }}>
                    {getUnitLangname(r)}
                </div>
            )}
        </div>

    </div>

}


