import React from "react";
import {useHistory} from "react-router-dom";
import {callApiAsync} from "./apiUtil";
import {medikationsplanApi} from "../config/apiConfig";
import {defaultMedikationsplan} from "../config/entities";
import {useAuth} from "./useAuth";

const useNavigation = () => {
    const auth = useAuth();
    const history = useHistory();

    const erstelleMedikationsplan = async (patient) => {
        const response = await callApiAsync({auth, url: medikationsplanApi.post(), data: defaultMedikationsplan(patient), method: 'POST'});
        return  response.data.OBJECT;
    }

    const gotoMedikationsplan = async (patient, tab) => {
        let medikationsplan = (await callApiAsync({auth, url: medikationsplanApi.getByFilter({patientId: patient.id})})).data.OBJECT[0];

        if (!medikationsplan) {
            medikationsplan = await erstelleMedikationsplan(patient);
        }

        if (medikationsplan && history) {
            history.push(`/adressbuch/${patient.id}/medikationsplan${!!tab ? `/${tab}` : ""}`);
        }
    }

    return {
        erstelleMedikationsplan,
        gotoMedikationsplan,
    }
}

export default useNavigation;