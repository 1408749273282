import React, {useContext} from "react";
import ArzneimittelContext from "../contexts/ArzneimittelContext";
import {useAuth} from "./useAuth";
import {callApiAsync, CancelablePromise} from "./apiUtil";
import {arzneimittelApi} from "../config/apiConfig";

const useApiArzneimittel = () => {
    const auth = useAuth();
    const arzneimittelContext = useContext(ArzneimittelContext);

    const getMedicineM2 = async (m2String, importIfNoResult=false, persistInDB=true) => {
        if (!m2String) return null;

        if (m2String in arzneimittelContext.medicineM2Map)
            return arzneimittelContext.medicineM2Map[m2String];

        if (m2String.startsWith("pzn_")) {
            importIfNoResult = false;
        }

        if (!(m2String in arzneimittelContext.medicineLoadingPromiseMap)) {
            arzneimittelContext.medicineLoadingPromiseMap[m2String] = new CancelablePromise(async resolve => {
                const response = await callApiAsync({
                    auth,
                    url: arzneimittelApi.getMedicineByM2(m2String, importIfNoResult, persistInDB),
                    onError: e => {
                        return e.data?.EXCEPTION_CLASS === "de.kushiworks.java.verblisterung.ejb.db.util.CustomExceptions$NoResultException";
                    }
                });
                const medicineM2 = response.data.OBJECT;

                arzneimittelContext.addMedicineM2(medicineM2);
                resolve(medicineM2);

                delete arzneimittelContext.medicineLoadingPromiseMap[m2String];
            });
        }
        return await arzneimittelContext.medicineLoadingPromiseMap[m2String];
    }

    const getMedicineM2Sync = (m2String, importIfNoResult=false, persistInDB=true) => {
        if (m2String in arzneimittelContext.medicineM2Map) {
            return arzneimittelContext.medicineM2Map[m2String];
        }

        getMedicineM2(m2String, importIfNoResult, persistInDB);
        return null;
    }

    return {
        medicineM2Map: arzneimittelContext.medicineM2Map,

        getMedicineM2,
        getMedicineM2Sync,
    }
}

export default useApiArzneimittel;