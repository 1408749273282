import React, {useContext, useEffect, useRef, useState} from "react";
import {useAuth} from "./useAuth";
import {callApiAsync, CancelablePromise} from "./apiUtil";
import {medikationsplanApi} from "../config/apiConfig";
import {getSorter} from "./sortUtil";
import {ZeitraumTyp} from "../components/organisms/MedikationsplanZeitraeume";
import moment from "moment";
import MedikationsplanContext from "../contexts/MedikationsplanContext";
import ApiContext from "../contexts/ApiContext";

let getZeitraumByMedikationsplanCalledLast = null;

const useApiZeitraum = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const apiContext = useContext(ApiContext);

    useEffect(() => {
        if (medikationsplanContext.medikationsplan && medikationsplanContext.medikationsplan.id !== getZeitraumByMedikationsplanCalledLast) {
            getZeitraumByMedikationsplanCalledLast = medikationsplanContext.medikationsplan.id;
            getZeitraumMapByMedikationsplan(medikationsplanContext.medikationsplan.id).then(medikationsplanContext.setZeitraumMap);
        }
    }, [medikationsplanContext.medikationsplan])

    useEffect(() => {
        for (let medikationsplanId in (apiContext.zeitraumData.medikationsplanIdToUnitIdMap || [])) {
            if (medikationsplanId in apiContext.refs.zeitraumByMedikationsplanLoadingMap) {
                delete apiContext.refs.zeitraumByMedikationsplanLoadingMap[medikationsplanId];
            }
        }
    }, [apiContext.zeitraumData.medikationsplanIdToUnitIdMap])

    const getZeitraumMapByUnit = async (unitId) => {
        if (!unitId) return {};

        if (!apiContext.refs.zeitraumByUnitLoadingMap) apiContext.refs.zeitraumByUnitLoadingMap = {};

        if (unitId in ( apiContext.zeitraumData.unitIdToZeitraumMapMap || {})) {
            return apiContext.zeitraumData.unitIdToZeitraumMapMap[unitId];
        }

        if (!(unitId in apiContext.refs.zeitraumByUnitLoadingMap)) apiContext.refs.zeitraumByUnitLoadingMap[unitId] = new CancelablePromise(async resolve => {
            const zeitraumList = (await callApiAsync({url: medikationsplanApi.getZeitraeumeByUnit(unitId), auth})).data.OBJECT.sort(getSorter("zeitraum"));

            const map = {};
            for (let zeitraum of zeitraumList) {
                if (!map[zeitraum.id.typ]) map[zeitraum.id.typ] = [];
                map[zeitraum.id.typ].push(zeitraum);
            }

            apiContext.setZeitraumData(prev => ({
                    ...prev,
                    unitIdToZeitraumMapMap: {...prev.unitIdToZeitraumMapMap, [unitId]: map}
                }
            ));

            resolve(map);
        });

        return apiContext.refs.zeitraumByUnitLoadingMap[unitId];
    }

    const getZeitraumMapByMedikationsplan = async (medikationsplanId) => {
        if (!apiContext.refs.zeitraumByMedikationsplanLoadingMap) apiContext.refs.zeitraumByMedikationsplanLoadingMap = {};

        if (medikationsplanId in apiContext.refs.zeitraumByMedikationsplanLoadingMap) {
            return await apiContext.refs.zeitraumByMedikationsplanLoadingMap[medikationsplanId];
        }

        if (medikationsplanId in ( apiContext.zeitraumData.medikationsplanIdToUnitIdMap || {})) {
            return apiContext.zeitraumData.unitIdToZeitraumMapMap[apiContext.zeitraumData.medikationsplanIdToUnitIdMap[medikationsplanId]];
        }

        apiContext.refs.zeitraumByMedikationsplanLoadingMap[medikationsplanId] = new CancelablePromise(async resolve => {
            let unitId;
            const map = {};
            for (let key of Object.keys(ZeitraumTyp)) map[key] = [];
            for (let zeitraum of (await callApiAsync({url: medikationsplanApi.getZeitraeumeByMedikationsplan(medikationsplanId), auth})).data.OBJECT.sort(getSorter("zeitraum"))) {
                map[zeitraum.id.typ].push(zeitraum);
                unitId = zeitraum.id.unitId;
            }

            apiContext.setZeitraumData(prev => ({...prev,
                unitIdToZeitraumMapMap: {...prev.unitIdToZeitraumMapMap, [unitId]: map},
                medikationsplanIdToUnitIdMap: {...prev.medikationsplanIdToUnitIdMap, [medikationsplanId]: unitId}}
            ));
            resolve(map);
        });

        return apiContext.refs.zeitraumByMedikationsplanLoadingMap[medikationsplanId];
    }

    const updateZeitraumMap = async (zeitraumNeu, zeitraumAlt) => {
        if (!zeitraumNeu && !zeitraumAlt) return null;
        const unitId = zeitraumNeu?.id?.unitId || zeitraumAlt?.id?.unitId;

        return new CancelablePromise(resolve => {
            apiContext.setZeitraumData(prev => {
                let zeitraumMap = {};
                if (unitId in (prev.unitIdToZeitraumMapMap || {})) {
                    zeitraumMap = {...prev.unitIdToZeitraumMapMap[unitId]};
                }

                let typ;
                if (zeitraumNeu) {
                    // Zeitraum wurde aktualisiert
                    typ = zeitraumNeu.id.typ;
                    if (zeitraumAlt?.id?.typ !== typ) {
                        // ZeitraumTyp hat sich geändert oder Zeitraum ist neu
                        if (zeitraumAlt) zeitraumMap[zeitraumAlt.id.typ] = zeitraumMap[zeitraumAlt.id.typ].filter(z => z.id.start !== zeitraumAlt.id.start);
                        zeitraumMap[typ] = [...(zeitraumMap[typ] || []), zeitraumNeu];
                    } else {
                        zeitraumMap[typ] = [...zeitraumMap[typ].filter(z => z.id.start !== zeitraumAlt.id.start), zeitraumNeu];
                    }
                } else {
                    // Zeitraum wurde gelöscht
                    typ = zeitraumAlt.id.typ;
                    zeitraumMap[typ] = zeitraumMap[typ].filter(z => z.id.start !== zeitraumAlt.id.start);
                }

                zeitraumMap[typ].sort(getSorter("zeitraum"));

                resolve(zeitraumMap);
                if (unitId === medikationsplanContext.medikationsplan?.patient?.id) medikationsplanContext.setZeitraumMap(zeitraumMap);
                return {...prev, unitIdToZeitraumMapMap: {...prev.unitIdToZeitraumMapMap, [unitId]: zeitraumMap}};
            });
        });
    }

    return {
        medikationsplanIdToUnitIdMap: apiContext.zeitraumData.medikationsplanIdToUnitIdMap || {},
        unitIdToZeitraumMapMap: apiContext.zeitraumData.unitIdToZeitraumMapMap || {},

        getZeitraumMapByUnit,
        getZeitraumMapByMedikationsplan,
        updateZeitraumMap,
    }
}

export default useApiZeitraum;

export const getZeitraumDelta = (zeitraumMap, start, ende) => {
    start = moment(start);
    ende = ende ? moment(ende) : null;

    const zeitraumList = [];
    const processZeitraeumePositiv = (zeitraeume) => {
        let startZeitraum, endeZeitraum;

        for (let zeitraum of zeitraeume || []) {
            startZeitraum = moment(zeitraum.id.start);
            endeZeitraum = zeitraum.ende ? moment(zeitraum.ende).endOf('day') : null;

            if (endeZeitraum?.isBefore(start)) {
                continue;
            }
            if (ende && startZeitraum.isSameOrAfter(ende)) {
                break;
            }

            // Blisterspanne ist komplett blisterbar
            if (endeZeitraum && (!ende || endeZeitraum.isBefore(ende))) {
                zeitraumList.push([Math.max(start.valueOf(), startZeitraum.valueOf()), endeZeitraum.valueOf()]);
                start = moment(zeitraum.ende).add(1, 'day').startOf('day');
            }

            else {
                zeitraumList.push([Math.max(start.valueOf(), startZeitraum.valueOf()), ende ? ende.valueOf() : null]);
            }

            // if (!endeZeitraum || endeZeitraum.isSameOrAfter(ende)) {
            //     zeitraumList.push([Math.max(start.valueOf(), startZeitraum.valueOf()), ende ? ende.valueOf() : null]);
            //     break;
            // }
            //
            // // Blisterspanne ist nicht komplett blisterbar => diesen Zeitabschnitt merken und dann weiter prüfen
            // zeitraumList.push([Math.max(start.valueOf(), startZeitraum.valueOf()), endeZeitraum.valueOf()]);
            // start = moment(zeitraum.ende).add(1, 'day').startOf('day');
        }
    }
    processZeitraeumePositiv(zeitraumMap.B);

    // Kein blisterbarer Zeitraum => Medikationsplan überspringen
    if (!zeitraumList.length) {
        return [];
    }

    // Verrechne alle Negativzeiträume mit den Positivzeiträumen
    const processZeitraeumeNegativ = (zeitraeumePositiv, zeitraeumeNegativ) => {
        if (!zeitraeumePositiv?.length) return [];
        if (!zeitraeumeNegativ?.length) return zeitraeumePositiv;

        const zeitraumListDelta = [];
        let startPositiv, endePositiv, startNegativ, endeNegativ, pushPositiv;

        outer:for (let positiv of zeitraeumePositiv || []) {
            startPositiv = moment(positiv[0]);
            endePositiv = positiv[1] ? moment(positiv[1]) : null;

            for (let negativ of zeitraeumeNegativ || []) {
                endeNegativ = negativ.ende ? moment(negativ.ende).endOf('day') : null;
                if (endeNegativ?.isBefore(startPositiv)) continue;

                startNegativ = moment(negativ.id.start);
                if (endePositiv && startNegativ.isAfter(endePositiv)) break;

                if (endeNegativ && (!endePositiv || endeNegativ.isBefore(endePositiv))) {
                    if (startPositiv.isBefore(startNegativ)) {
                        zeitraumListDelta.push([startPositiv.valueOf(), moment(startNegativ).endOf('day').subtract(1, 'day').valueOf()]);
                    }

                    startPositiv = moment(endeNegativ).startOf('day').add(1, 'day');
                }

                else {
                    if (startPositiv.isBefore(startNegativ)) {
                        zeitraumListDelta.push([startPositiv.valueOf(), moment(startNegativ).endOf('day').subtract(1, 'day').valueOf()]);
                    }

                    continue outer;
                }

                // pushPositiv = false;
                // if (startNegativ.isSameOrAfter(endePositiv)) {
                //     zeitraumListDelta.push([startPositiv.valueOf(), endePositiv.valueOf()]);
                //     break;
                // }
                //
                // if (startPositiv.isBefore(startNegativ)) {
                //     zeitraumListDelta.push([startPositiv.valueOf(), startNegativ.endOf('day').valueOf()]);
                // }
                //
                // if (endeNegativ?.isBefore(endePositiv)) {
                //     startPositiv = moment(endeNegativ).add(1, 'day').startOf('day');
                // } else {
                //     break;
                // }
            }

            zeitraumListDelta.push([startPositiv.valueOf(), endePositiv ? endePositiv.valueOf() : null]);
        }

        return zeitraumListDelta;
    }
    let delta = processZeitraeumeNegativ(zeitraumList, zeitraumMap.K);
    if (!delta.length) {
        return [];
    }

    return processZeitraeumeNegativ(delta, zeitraumMap.P);
}

export const getZeitraumContainsDate = (zeitraumList, datum, tageFrueherErlaubt=0, tageSpaeterErlaubt=0) => {
    if (!datum || !zeitraumList) return null;
    datum = datum ? moment(datum).startOf('day') : moment().startOf('day');

    for (let zeitraum of zeitraumList) {
        const start = moment(zeitraum.id.start).subtract(tageFrueherErlaubt, "days");
        if (datum.isBefore(start)) {
            return null;
        }

        if (!zeitraum.ende) {
            return zeitraum;
        } else {
            const ende = moment(zeitraum.ende).add(tageSpaeterErlaubt, "days");
            if (!datum.isAfter(ende)) {
                return zeitraum
            }
        }
    }
}

export const getCurrentZeitraum = (zeitraumMap, current, tageFrueherErlaubt=0, tageSpaeterErlaubt=0) => {
    if (!zeitraumMap) return null;
    current = current ? moment(current).startOf('day') : moment().startOf('day');

    let zeitraum = getZeitraumContainsDate(zeitraumMap.P, current, tageFrueherErlaubt, tageSpaeterErlaubt);
    if (!zeitraum) zeitraum = getZeitraumContainsDate(zeitraumMap.K, current, tageFrueherErlaubt, tageSpaeterErlaubt);
    if (!zeitraum) zeitraum = getZeitraumContainsDate(zeitraumMap.U, current, tageFrueherErlaubt, tageSpaeterErlaubt);
    if (!zeitraum) zeitraum = getZeitraumContainsDate(zeitraumMap.B, current, tageFrueherErlaubt, tageSpaeterErlaubt);
    return zeitraum;
}

export const getCurrentZeitraumTyp = (zeitraumMap, current, tageFrueherErlaubt=0, tageSpaeterErlaubt=0) => {
    current = current ? moment(current).startOf('day') : moment().startOf('day');
    const currentZeitraum = getCurrentZeitraum(zeitraumMap, current, tageFrueherErlaubt, tageSpaeterErlaubt);

    let typ;
    if (currentZeitraum) {
        typ = currentZeitraum.id.typ;
        if (current.isBefore(currentZeitraum.id.start)) {
            typ += "_B";
        } else if (currentZeitraum.ende && current.isAfter(currentZeitraum.ende)) {
            typ += "_A";
        }
    }

    return typ;
}

export const getZeitraumColor = (currentZeitraumTyp, alpha=1, typesAllowed) => {
    switch (currentZeitraumTyp) {
        case "U":
            return !typesAllowed || typesAllowed.includes(currentZeitraumTyp) ? `rgba(210,0,0,${alpha})` : null; // rot
        case "B":
            return !typesAllowed || typesAllowed.includes(currentZeitraumTyp) ? `rgba(81,181,136,${alpha})` : null; // grün
        case "K":
        case "U_B":
            return !typesAllowed || typesAllowed.includes(currentZeitraumTyp) ? `rgba(210,210,0,${alpha})` : null; // gelb
        case "P":
        default:
            return !typesAllowed || typesAllowed.includes(currentZeitraumTyp) ? `rgba(143,143,143,${alpha})` : null; // grau
    }
}

export const getCurrentZeitraumColor = (zeitraumMap, alpha=1, datum, typesAllowed, tageFrueherErlaubt=0, tageSpaeterErlaubt=0) => {
    const currentZeitraumTyp = getCurrentZeitraumTyp(zeitraumMap, datum, tageFrueherErlaubt, tageSpaeterErlaubt);
    return getZeitraumColor(currentZeitraumTyp, alpha, typesAllowed);
}