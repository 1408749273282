import React, {useContext, useEffect, useState} from "react";
import NotizTable from "../molecules/NotizTable";
import NotizContext from "../../contexts/NotizContext";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import {useApi} from "../../utilities/useApi";

const AdressbuchNotizen = ({}) => {
    const api = useApi();
    const notizContext = useContext(NotizContext);
    const adressbuchContext = useContext(AdressbuchContext);

    const [notizen, setNotizen] = useState(null);
    const [unitMap, setUnitMap] = useState({});

    useEffect(() => {
        if (adressbuchContext.akteur) {
            if (notizContext.notizMap[adressbuchContext.akteur.id]) {
                const notizenNeu = Object.values(notizContext.notizMap[adressbuchContext.akteur.id]);
                setNotizen(notizenNeu);
            } else {
                api.loadNotizenForUnit(adressbuchContext.akteur.id);
            }
        }
    }, [notizContext.notizMap, adressbuchContext.akteur])

    useEffect(() => {
        if (adressbuchContext.akteur) {
            setUnitMap({[adressbuchContext.akteur.id]: adressbuchContext.akteur});
        }
    }, [adressbuchContext.akteur])

    return <NotizTable
        dataSource={notizen}
        unitMap={unitMap}

        showColumns={["titel", "faelligkeit", "gueltigVon", "gueltigBis", "bearbeitet"]}
        defaultSortColumn={"faelligkeit"}
    />
}

export default AdressbuchNotizen;