import {useState, useEffect, useRef} from 'react'
import { callApiAsync } from "../utilities/apiUtil";
import { useAuth } from '../utilities/useAuth';

export const useFetch = (url = null, sorter, { onError } = {}) => {

    const auth = useAuth();
    const task = useRef();
    const lastUrl = useRef();

    const [data, setDataState] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const insert = (eintrag) => {
        let dataNeu;
        if (Array.isArray(data))
            dataNeu = [...data, eintrag];
        else
            dataNeu = eintrag;

        setData(dataNeu);
    };

    const fetchData = async () => {
        if (!url) {
            setDataState(null);
            return;
        }

        setLoading(true);

        task.current = callApiAsync({ auth, url, onError })
            .then(response => {
                setData(response.data.OBJECT);
                setLoading(false);
            })
            .catch(err =>
                setError(JSON.stringify(err))
            );

        return task.current;
    };

    const cancel = () => {
        if (task.current) {
            task.current.cancel();
        }
    }

    useEffect(() => {
        if (lastUrl.current === url) return;
        lastUrl.current = url;

        fetchData();
    }, [url]);

    useEffect(() => {
        if (data && sorter) setData(data);
    }, [sorter])

    const setData = (dataNeu) => {
        if (sorter && dataNeu) dataNeu.sort(sorter);
        setDataState(dataNeu);
    }

    return {
        data,
        loading,
        error,

        insert,
        reload: fetchData,
        update: setData,
        cancel,
    }
};
