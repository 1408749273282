import {useCallback, useContext, useEffect, useRef, useState} from "react";
import ApiContext from "../contexts/ApiContext";
import {useAuth} from "./useAuth";
import {akteureApi} from "../config/apiConfig";
import {getSorter} from "./sortUtil";
import {callApiAsync} from "./apiUtil";
import MedikationsplanContext from "../contexts/MedikationsplanContext";
import {fetchBlisterJobFehlbestaende, fetchBlisterJobLieferengpass} from "../config/fetchApiConfiguration";

export const useApiAkteure = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    useEffect(() => {
        if (medikationsplanContext.medikationsplan) {
            apiContext.setAkteureData(prev => {
                return ({...prev, patientIdToMedikationsplanMap: {...(prev.patientIdToMedikationsplanMap || {}), [medikationsplanContext.medikationsplan.patient.id]: medikationsplanContext.medikationsplan}});
            });
        }
    }, [medikationsplanContext.medikationsplan])

    const akteurLoadingRef = useRef(new Map());

    const loadAkteure = async (aktiv= true, refreshToken=true) => {
        const response = await callApiAsync({auth, url: akteureApi.getAll(aktiv), refreshToken});
        const sorted = response.data.OBJECT.sort(getSorter("unit", "name"));

        const akteureIdToIndexMap = {};
        for (let i=0; i<sorted.length; i++) {
            akteureIdToIndexMap[sorted[i].id] = i;
        }

        apiContext.setAkteureData(prev => {
            return aktiv ?
                ({...prev, akteure: sorted, akteureIdToIndexMap}) :
                ({...prev, akteureInaktiv: sorted, akteureIdToIndexMapInaktiv: akteureIdToIndexMap});
        });

        return [sorted, akteureIdToIndexMap];
    }

    const loadAkteur = async (akteurId, refreshToken=true) => {
        if (!akteurLoadingRef.current.has(akteurId)) {
            akteurLoadingRef.current.set(akteurId, new Promise(async (resolve) => {
                const akteur = (await callApiAsync({auth, url: akteureApi.getById(akteurId), refreshToken}))?.data.OBJECT;
                if (!akteur) return null;

                apiContext.setAkteureData(prev => {
                    const sorted = [...((akteur.active ? prev.akteure : prev.akteureInaktiv) || []).filter(a => a.id !== akteurId), akteur].sort(getSorter("unit", "name"));
                    const akteureIdToIndexMap = {};
                    for (let i=0; i<sorted.length; i++) {
                        akteureIdToIndexMap[sorted[i].id] = i;
                    }

                    const n = akteur.active ?
                        ({...prev, akteure: sorted, akteureIdToIndexMap}) :
                        ({...prev, akteureInaktiv: sorted, akteureIdToIndexMapInaktiv: akteureIdToIndexMap});

                    akteurLoadingRef.current.delete(akteurId);
                    return n;
                });

                resolve(akteur);
            }));
        }

        return akteurLoadingRef.current.get(akteurId);
    }

    const updateAkteur = async (akteur) => {
        if (!akteur.id) {
            return [];
        }

        let akteure = [...(apiContext.akteureData?.akteure || [])];
        let akteureIdToIndexMap = {...apiContext.akteureData?.akteureIdToIndexMap};

        if (!akteure?.length) {
            [akteure, akteureIdToIndexMap] = await loadAkteure()
        }

        if (akteureIdToIndexMap[akteur.id]) {
            const index = akteureIdToIndexMap[akteur.id];
            akteure[index] = akteur;
        } else {
            akteure.push(akteur);
            akteure = akteure.sort(getSorter("unit", "name"));

            for (let i=0; i<akteure.length; i++) {
                akteureIdToIndexMap[akteure[i].id] = i;
            }
        }

        apiContext.setAkteureData(prev => {
            return ({...prev, akteure, akteureIdToIndexMap});
        });

        return [akteure, akteureIdToIndexMap];
    }

    const loadPatientenMitMedikationsplan = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: akteureApi.getMedikationsplanVorhanden(), refreshToken});

        const data = response.data.OBJECT.map(d => ({...d.key, medikationsplanId: d.value}));
        const sorted = data.sort(getSorter("unit", "name"));

        apiContext.setAkteureData(prev => {
            return ({...prev, patientenMitMedikationsplan: sorted});
        });
    }

    const loadPatientenMitFehlbestaenden = async (refreshToken=true, excludeLieferengpass=false, highPriority=false) => {
        const url = fetchBlisterJobFehlbestaende(excludeLieferengpass, highPriority);
        const response = await callApiAsync({auth, url, refreshToken});
        apiContext.setAkteureData(prev => ({...prev, patientenMitFehlbestaenden: response.data.OBJECT}));

        return response.data.OBJECT;
    }

    const loadPatientenMitLieferengpaessen = async (refreshToken=true, highPriority=false) => {
        const url = fetchBlisterJobLieferengpass(highPriority);
        const response = await callApiAsync({auth, url, refreshToken});
        apiContext.setAkteureData(prev => ({...prev, patientenMitLieferengpaessen: response.data.OBJECT}));

        return response.data.OBJECT;
    }

    return {
        patientIdToMedikationsplanMap: apiContext.akteureData.patientIdToMedikationsplanMap || {},

        akteure: apiContext.akteureData.akteure ? apiContext.akteureData.akteure : [],
        akteureInaktiv: apiContext.akteureData.akteureInaktiv ? apiContext.akteureData.akteureInaktiv : [],
        akteureLoaded: !!apiContext.akteureData.akteure,
        akteureLoadedInaktiv: !!apiContext.akteureData.akteureInaktiv,
        loadAkteure,
        loadAkteur,
        updateAkteur,

        akteureIdToIndexMap: apiContext.akteureData.akteureIdToIndexMap ? apiContext.akteureData.akteureIdToIndexMap : {},

        patientenMitMedikationsplan: apiContext.akteureData.patientenMitMedikationsplan ? apiContext.akteureData.patientenMitMedikationsplan : [],
        patientenMitMedikationsplanLoaded: !!apiContext.akteureData.patientenMitMedikationsplan,
        loadPatientenMitMedikationsplan,

        loadPatientenMitFehlbestaenden,
        patientenMitFehlbestaendenLoaded: !!apiContext.akteureData.patientenMitFehlbestaenden,
        patientenMitFehlbestaenden: apiContext.akteureData.patientenMitFehlbestaenden || [],

        loadPatientenMitLieferengpaessen,
        patientenMitLieferengpaessenLoaded: !!apiContext.akteureData.patientenMitLieferengpaessen,
        patientenMitLieferengpaessen: apiContext.akteureData.patientenMitLieferengpaessen || [],
    }
}