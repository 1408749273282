import React, {useContext, useEffect, useMemo, useState} from 'react'

import { ArzneimittelPicker } from '../atoms/Arzneimittel'
import {Row, Col} from "antd";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getTeilbarkeitString} from "../../utilities/medicineM2Util";
import {useApi} from "../../utilities/useApi";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faBan, faCapsules} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "../atoms/Tooltip";

export const PlanzeileArzneimittel = ({ arzneimittel, freitext, planzeile }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const api = useApi();

    const [teilbarkeitString, setTeilbarkeitString] = useState("");
    const [anspruchGefunden, setAnspruchGefunden] = useState(true);
    const [anspruchWirkstoffgleicheAnternativeGefunden, setAnspruchWirkstoffgleicheAlternativeGefunden] = useState(false);

    const [langnameStyle, setLangnameStyle] = useState({});
    const [langnameTooltip, setLangnameTooltip] = useState(null);

    useEffect(() => {
        if (arzneimittel) {
            (async () => {
                const medicineM2 = await api.getMedicineM2(arzneimittel.m2, true, false);
                if (medicineM2) {
                    setTeilbarkeitString(getTeilbarkeitString(medicineM2));
                }
            })();

            setLangnameStyle(
                arzneimittel.vertriebsstatus === 'GELOESCHT' && {color: 'rgba(255,0,0,0.6)'} ||
                (arzneimittel.vertriebsstatus === 'AUSSER_VERTRIEB' || arzneimittel.vertriebsstatus === 'ZURUECKGEZOGEN')  && {color: 'rgb(243,130,38)'} ||
                {}
            );

            setLangnameTooltip(
                (arzneimittel.vertriebsstatus === 'GELOESCHT' && 'Das Arzneimittel existiert nicht mehr!') ||
                (arzneimittel.vertriebsstatus === 'AUSSER_VERTRIEB' && 'Das Arzneimittel ist außer Vertrieb!') ||
                (arzneimittel.vertriebsstatus === 'ZURUECKGEZOGEN' && 'Das Arzneimittel ist zurückgezogen!') ||
                null
            )
        }
    }, [arzneimittel])

    useEffect(() => {
        if (planzeile && Object.keys(medikationsplanContext.buendelMap).length) {
            const buendel = medikationsplanContext.buendelMap[planzeile.buendelId];
            if (buendel) {
                const entitlementSet = buendel.entitlementSet;
                if (entitlementSet.length) {
                    const anspruchGefundenNeu = entitlementSet.some(e => e.id.medicineM2.m2 === arzneimittel.m2 && e.amount !== 0);
                    const wirkstoffgleicheAlternativeNeu = !anspruchGefundenNeu && entitlementSet.some(e => e.id.medicineM2.m2 !== arzneimittel.m2 && e.factorBundle === planzeile.buendelFaktor && e.amount !== 0);

                    setAnspruchGefunden(anspruchGefundenNeu);
                    setAnspruchWirkstoffgleicheAlternativeGefunden(wirkstoffgleicheAlternativeNeu);
                }
            }
        }
    }, [arzneimittel, planzeile, medikationsplanContext.buendelMap])

    return <div style={{marginRight: "20px"}}>

        {arzneimittel && <>
            <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1}}>
                    <Tooltip title={langnameTooltip}>
                        <b style={langnameStyle}>{arzneimittel.langname}</b>
                    </Tooltip>
                </div>
                <span className={'planzeile-arzneimittel-icons'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {!anspruchGefunden && !anspruchWirkstoffgleicheAnternativeGefunden && <Tooltip title={'Kein Anspruch für Planzeilenarzneimittel vorhanden'}><span className={'fa-layers fa-fw fa-lg'}>
                        <FontAwesomeIcon icon={faCapsules} transform="shrink-6" />
                        <FontAwesomeIcon icon={faBan} rotation={90} style={{color: 'rgba(255,0,0,0.6)'}} />
                    </span></Tooltip>}
                    {!anspruchGefunden && anspruchWirkstoffgleicheAnternativeGefunden && <Tooltip title={'Kein Anspruch für Planzeilenarzneimittel, aber wirkstoffgleiche Alternative vorhaden'}><span className={'fa-layers fa-fw fa-lg'}>
                        <FontAwesomeIcon icon={faArrowsRotate} style={{color: 'rgb(243,130,38)'}} />
                        <FontAwesomeIcon icon={faCapsules} transform="shrink-8" />
                    </span></Tooltip>}
                </span>
            </div>

            {medikationsplanContext.planzeileSelected === planzeile.id && <Row>
                <Col span={24} style={{paddingBottom: 5}}>
                    <i>{arzneimittel.hersteller}</i>
                </Col>

                <Col span={12}>
                    PZN: <i>{("00000000"+arzneimittel.pzn).slice(-8)} {arzneimittel.normgroesse}</i>
                    <br />
                    M2: <i>{arzneimittel.m2}</i>
                </Col>

                <Col span={12}>
                    {teilbarkeitString}
                    <br />
                    {planzeile && planzeile.dosisgenauAnfordern && <b>dosisgenau</b>}
                </Col>
            </Row>}
        </>}

        {freitext && freitext !== "" && <div>Freitext:<br /> <i>{freitext}</i></div>}
    </div>
}



export const EditArzneimittel = ({ value, onChange, onClose, freitext, handleFreitextChange, triggerSave, setSaved=()=>{}, entitlementset }) => {

    const [resetTrigger, setResetTrigger] = useState(false);

    useEffect(() => {
        if (triggerSave) setSaved(true);
    }, [triggerSave])

    return <div style={{
        borderStyle: "solid",
        borderWidth: "3px",
        borderRadius: "10px",
        borderColor: "#97D3B8",
        padding: "5px"
    }}>
        {/*<div style={{textAlign: "right"}}>*/}
        {/*    <Button onClick={onClose}><CloseOutlined /></Button>*/}
        {/*</div>*/}
        <ArzneimittelPicker
            entitlementset={entitlementset}

            schnellauswahl

            value={value}
            onChange={async val => {
                const changeResult = await onChange(val);
                if (!changeResult) {
                    setResetTrigger(true);
                }
            }}
            freitext={freitext}
            handleFreitext={handleFreitextChange}
            resetTrigger={[resetTrigger, () => setResetTrigger(false)]}

            width={'90%'}
        />
    </div>
}
