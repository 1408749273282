import React from 'react'

export const Hilfe = props => {

    return (
        <div>
            <h1>Hilfe</h1>
            <Hilfetext {...props} thema={props.match.params._filter} />
        </div>
    )
}


const Hilfetext = (props) => {

    switch (props.thema) {
        case "schnellsuche":
            return <div>
                <h2>zum Thema Schnellsuche</h2>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </div>

        default:
            return <div>
                <h2>Übersicht Hilfethemen (tbd)</h2>
                <pre>{JSON.stringify(props, null, 2)}</pre>
            </div>
    }
}
