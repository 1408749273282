import {createContext, useRef, useState} from "react";

const initVal = {
    refs: {},

    anforderungenData: {},
    setAnforderungenData: val => {},

    planzeilenData: {},
    setPlanzeilenData: val => {},

    dokumenteData: {},
    setDokumenteData: val => {},

    akteureData: {},
    setAkteureData: val => {},

    notizenData: {},
    setNotizenData: val => {},

    zeitraumData: {},
    setZeitraumData: val => {},

    bestaendeData: {},
    setBestaendeData: val => {},

    anspruecheData: {},
    setAnspruecheData: val => {},

    buendelData: {},
    setBuendelData: val => {},

    problemfaelleData: {},
    setProblemfaelleData: val => {},
}

const ApiContext = createContext(initVal);
export default ApiContext;

export const useInitialState = () => {
    const [anforderungenData, setAnforderungenData] = useState({});
    const [planzeilenData, setPlanzeilenData] = useState({});
    const [dokumenteData, setDokumenteData] = useState({});
    const [akteureData, setAkteureData] = useState({});
    const [notizenData, setNotizenData] = useState({});
    const [zeitraumData, setZeitraumData] = useState({});
    const [bestaendeData, setBestaendeData] = useState({});
    const [anspruecheData, setAnspruecheData] = useState({});
    const [buendelData, setBuendelData] = useState({});
    const [problemfaelleData, setProblemfaelleData] = useState({});

    const refs = useRef({});

    return {
        refs: refs.current,

        anforderungenData,
        setAnforderungenData,

        planzeilenData,
        setPlanzeilenData,

        dokumenteData,
        setDokumenteData,

        akteureData,
        setAkteureData,

        notizenData,
        setNotizenData,

        zeitraumData,
        setZeitraumData,

        bestaendeData,
        setBestaendeData,

        anspruecheData,
        setAnspruecheData,

        buendelData,
        setBuendelData,

        problemfaelleData,
        setProblemfaelleData,
    }
}