import React, {useContext, useEffect, useState} from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Input, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../utilities/useAuth'
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {LoginOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";

export const Login = () => {
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [showLogoutMessage, setShowLogoutMessage] = useState(false);

    const auth = useAuth();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        const hashNew = hash.replaceAll("#logout", "");

        if (hash !== hashNew) {
            window.history.replaceState(null, null, location.pathname+location.search+hashNew);
            setShowLogoutMessage(true);
        }
    }, [location])

    const handleSubmit = e => {
        e.preventDefault();
        auth.handleLogin(username, password);
    };

    const logo = require('../../style/icons/logo.png');

    return (
        <Container>
            <Row style={{ height: "100px" }}>
                <Col sm="4">

                    {showLogoutMessage &&
                        <Alert
                            message="Sie wurden erfolgreich abgemeldet!"
                            showIcon
                        />
                    }

                    {auth.success &&
                        <Alert
                            message="Die Anmeldung war erfolgreich!"
                            description="Sie werden automatisch weiter geleitet"
                            showIcon
                        />
                    }

                    {!auth.success && auth.error &&
                        <Alert
                            message={auth.error.message}
                            description={auth.error.description}
                            type={auth.error.type ? auth.error.type : "warning"}
                            showIcon
                        />
                    }
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm="1">
                        <img src={logo} width="64" alt="Logo" />
                    </Col>
                    <Col sm="3">
                        <h1>Niraso RX</h1>
                    </Col>
                </Row>

                <Row>
                    <Col sm="4">
                        <h3>Bitte melden Sie sich an:</h3>
                        <Input
                            name="username"
                            autoComplete="username"
                            value={username} onChange={e => setusername(e.target.value)}
                            placeholder="Benutzername"
                            suffix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        />
                        <Input.Password
                            autoComplete="current-password"
                            name="current-password"
                            value={password} onChange={e => setpassword(e.target.value)}
                            placeholder="Passwort"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="2"><Button type="primary" icon={<LoginOutlined />} htmlType="submit" loading={auth.loading} >anmelden</Button></Col>
                    <Col sm="2"><Link to='/passwortreset'>Passwort zurücksetzen</Link></Col>
                </Row>
            </Form>
        </Container>
    );
};


