import { createStore } from 'redux'


// todo Redux Store - auslagern
const initialReduxState = {
    current: "Current!",
    history: "History!"
}


const rootReducer = (state, action) => {
    switch (action.type) {
        case "NEW_CURRENT":
            return { ...state, current: action.payload }

        default:
            // console.info('undefined action type', action, state)
            return state;
    }
}

export const store = createStore(rootReducer, initialReduxState)
