import React, {useContext} from "react";
import {useAuth} from "./useAuth";
import {callApiAsync} from "./apiUtil";
import {verblisterungApi} from "../config/apiConfig";
import MedikationsplanContext from "../contexts/MedikationsplanContext";

const useApiMedikationsplan = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const loadBlisterTypes = () => {
        callApiAsync({auth, url: verblisterungApi.getAllBlisterTypes()}).then(response => {
            medikationsplanContext.setBlisterTypes(response.data.OBJECT);
        });
    }

    return {
        loadBlisterTypes
    }
}

export default useApiMedikationsplan;