import React, {useEffect, useState} from 'react'

import {Tabs, Button} from 'antd';

import { AkteurPicker } from './../atoms/AkteurPicker';
import {defaultAkteur} from "../../config/entities";
import {callApiAsync} from "../../utilities/apiUtil";
import {akteureApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import {Tooltip} from "../atoms/Tooltip";


const { TabPane } = Tabs;


export const EditVerordner = ({ value, onChange, onClose, triggerSave, setUpdated, freitext="", setSaved=()=>{} }) => {
    const auth = useAuth();

    const [verordner, setVerordner] = useState(value);
    const [updated, setUpdatedState] = useState(false);
    const [searchText, setSearchText] = useState(freitext);
    const [freitextCleaned, setFreitextCleaned] = useState("");

    useEffect(() => {
        if (freitext) setFreitextCleaned(freitext.replace(/(Drs?\. ?|med\. ?)/, ""));
    }, [freitext])

    const handeSave = () => {
        if (updated) onChange(verordner);
        setSaved(true);
    }

    useEffect(() => {
        if (triggerSave) handeSave();
    }, [triggerSave])

    const changeVerordner = verordner => {
        setVerordner(verordner);
        setUpdatedState(true);
        setUpdated(true);
    }

    const createVerordner = async (type) => {
        let akteur;
        if (type === "arzt") {
            akteur = {...defaultAkteur(type), title: "Dr. med.", surname: getArztNachname(searchText)};
        } else if (type === "praxis") {
            akteur = {...defaultAkteur(type), name: searchText};
        } else {
            return;
        }

        const response = await callApiAsync({auth, url: akteureApi.post(), method: "POST", data: akteur});
        changeVerordner(response.data.OBJECT);
    }

    const getArztNachname = text => {
        return text.replace(/([Dd]rs?\. ?|med\. ?)/, "");
    }

    return <div style={{
        borderStyle: "solid",
        borderWidth: "3px",
        borderRadius: "10px",
        borderColor: "#97D3B8",
        padding: "5px"
    }}>

        <Tabs
            style={{overflow: "visible"}}
            defaultActiveKey="edit"
            // tabBarExtraContent={
            //     <div>
            //         {/*<Button onClick={handeSave} disabled={!updated}><SaveOutlined /></Button>*/}
            //         {/*<Button onClick={onClose}><CloseOutlined /> schließen</Button>*/}
            //     </div>}
        >

            <TabPane tab="Verordner" key="edit">
                <div style={{ height: 100 }}>
                    {!verordner && searchText &&
                        <Tooltip title={`Legt einen Arzt mit dem Titel 'Dr. med.' und dem Nachnamen '${getArztNachname(searchText)}' an`}>
                            <Button onClick={() => createVerordner('arzt')}>eingegebenen Text als Arzt anlegen</Button>
                        </Tooltip>
                    }

                    {!verordner && searchText &&
                        <Tooltip title={`Legt eine Arztpraxis mit der Bezeichnung '${searchText}' an`}>
                            <Button onClick={() => createVerordner('praxis')}>eingegebenen Text als Arztpraxis anlegen</Button>
                        </Tooltip>
                    }

                    <AkteurPicker
                        value={verordner}
                        onChange={val => changeVerordner(val)}
                        onChangeInputText={text => setSearchText(text)}
                        placeholder={freitextCleaned}
                        inputStyle={{width: 250}}
                    />
                </div>

            </TabPane>

            <TabPane tab="Vertreter" key="vorschau">
                TBD: Liste aller Vertreter
            </TabPane>
        </Tabs>

    </div >
}
