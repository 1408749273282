import {BASE_URL, BASE_URL_NONMP} from "../config/apiConfig";
import {callApiAsync} from "./apiUtil";
import {useAuth} from "./useAuth";
import {useContext, useEffect, useRef} from "react";
import ApiContext from "../contexts/ApiContext";
import {subAmountStringToDouble} from "./blisterJobUtil";
import {getMedicineM2Name} from "./medicineM2Util";
import moment from "moment";
import {useApiAkteure} from "./useApiAkteure";
import {useApiAnforderungen} from "./useApiAnforderungen";
import {signal} from "@preact/signals-react";

const isLoading = signal(false);
export const useApiProblemfaelle = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);
    const apiAkteure = useApiAkteure();
    const apiAnforderungen = useApiAnforderungen();

    const today = moment().startOf('day');

    useEffect(() => {
        if (apiContext.problemfaelleData.fehlbestandEntries && apiContext.problemfaelleData.lieferengpassEntries) {
            const map = new Map();
            for (const lieferengpass of apiContext.problemfaelleData.lieferengpassEntries) {
                if (!map.has(lieferengpass.patient.id)) map.set(lieferengpass.patient.id, new Map());
                if (!map.get(lieferengpass.patient.id).has(lieferengpass.m2)) map.get(lieferengpass.patient.id).set(lieferengpass.m2, true);
            }

            if (map.size) {
                const fehlbestandEntriesNeu = apiContext.problemfaelleData.fehlbestandEntries.filter(e => !map.get(e.patient.id)?.get(e.m2));

            }
        }
    }, [apiContext.problemfaelleData.lieferengpassEntries])

    const loadProblemfaelle = async (refreshToken, highPriority) => {
        if (isLoading.value) return;

        isLoading.value = true;
        const promiseList = [];
        promiseList.push(apiAnforderungen.loadAnforderungenProblemfaelle(refreshToken, highPriority));

        promiseList.push(loadFehlbestandEntries(refreshToken, true, highPriority));
        promiseList.push(loadAuffaelligkeitenBeiAnspruchUndBestand(refreshToken, highPriority));
        promiseList.push(loadLieferengpassEntries(refreshToken, highPriority));

        promiseList.push(loadHandlungsbedarfVertriebsstatus(refreshToken, highPriority));
        promiseList.push(loadHandlungsbedarfArzneimittelNichtInBestand(refreshToken, highPriority));

        const resultList = await Promise.all(promiseList);
        let problemfaelleCount = 0;
        problemfaelleCount += resultList[0].ueberfaelligFuerVerblisterung?.length || 0;
        problemfaelleCount += resultList[0].patientenMitUnterversorgung?.length || 0;
        problemfaelleCount += resultList[0].gefaxteRezepteUeberfaellig?.length || 0;
        problemfaelleCount += resultList[0].automatischesMahnwesenAusgeschoepft?.length || 0;
        problemfaelleCount += resultList[0].anforderungenTrotzAusreichendemBestand?.length || 0;
        problemfaelleCount += resultList[0].planzeileGeloescht?.length || 0;
        problemfaelleCount += resultList[0].patientInKrankenhaus?.length || 0;
        problemfaelleCount += resultList[0].patientVerstorben?.length || 0;
        problemfaelleCount += resultList[0].patientGeloescht?.length || 0;

        problemfaelleCount += resultList[1].length || 0;

        problemfaelleCount += resultList[2].length || 0;

        problemfaelleCount += resultList[3].length || 0;

        problemfaelleCount += resultList[4].length || 0;

        problemfaelleCount += resultList[5].length || 0;

        apiContext.setProblemfaelleData(prev => ({...prev, problemfaelleCount}));

        isLoading.value = false;
        return problemfaelleCount;
    }

    const loadFehlbestandEntries = async (refreshToken=true, excludeLieferengpass=false, highPriority=false) => {
        const response = await apiAkteure.loadPatientenMitFehlbestaenden(refreshToken, excludeLieferengpass, highPriority);

        const resultMap = new Map();
        for (const job of response) {
            for (const entry of job.entries) {
                const mapEntry = resultMap.get(entry.medicineM2?.m2) || {};
                let amount = mapEntry.amount || 0;

                for (const day of entry.days) {
                    if (today.isAfter(day.id.date)) continue;

                    amount += day.amount + subAmountStringToDouble(day.subAmount);
                }

                if (amount) {
                    if (!mapEntry.m2) {
                        if (!entry.medicineM2) continue;
                        mapEntry.m2 = entry.medicineM2.m2;
                        mapEntry.m2Entity = entry.medicineM2;
                        mapEntry.name = getMedicineM2Name(entry.medicineM2);
                        mapEntry.patient = entry.person;
                    }

                    if (!mapEntry.dateCreated || mapEntry.dateCreated > job.creationDate) {
                        mapEntry.dateCreated = job.creationDate;
                        mapEntry.reason = job.comment;
                    }

                    mapEntry.amount = amount;
                    resultMap.set(mapEntry.m2, mapEntry);
                }
            }
        }

        const fehlbestandEntries = Array.from(resultMap.values());
        apiContext.setProblemfaelleData(prev => ({...prev, fehlbestandEntries}));
        return fehlbestandEntries;
    }

    const loadLieferengpassEntries = async (refreshToken=true, highPriority=false) => {
        const response = await apiAkteure.loadPatientenMitLieferengpaessen(refreshToken, highPriority);

        const resultMap = new Map();
        for (const job of response) {
            for (const entry of job.entries) {
                const mapEntry = resultMap.get(entry.medicineM2.m2) || {};
                let amount = mapEntry.amount || 0;

                for (const day of entry.days) {
                    if (today.isAfter(day.id.date)) continue;

                    amount += day.amount + subAmountStringToDouble(day.subAmount);
                }

                if (amount) {
                    if (!mapEntry.m2) {
                        mapEntry.m2 = entry.medicineM2.m2;
                        mapEntry.m2Entity = entry.medicineM2;
                        mapEntry.name = getMedicineM2Name(entry.medicineM2);
                        mapEntry.patient = entry.person;
                    }

                    if (!mapEntry.dateCreated || mapEntry.dateCreated > job.creationDate) {
                        mapEntry.dateCreated = job.creationDate;
                        mapEntry.reason = job.comment;
                    }

                    mapEntry.amount = amount;
                    resultMap.set(mapEntry.m2, mapEntry);
                }
            }
        }

        const lieferengpassEntries = Array.from(resultMap.values());
        apiContext.setProblemfaelleData(prev => ({...prev, lieferengpassEntries}));
        return lieferengpassEntries;
    }

    const loadAuffaelligkeitenBeiAnspruchUndBestand = async (refreshToken=true, highPriority=false) => {
        const url = BASE_URL_NONMP + `maintenance/list/anspruchungleichbestand?highPriority=${highPriority ? 'true' : 'false'}`
        const response = await callApiAsync({url, auth, refreshToken});

        const auffaelligkeitenBeiAnspruchUndBestand = [];
        for (const entry of response.data.OBJECT) {
            const av = entry.anspruch + entry.vorabAnforderung;
            const bb = entry.bestand + entry.bestellung;

            if (av !== bb) {
                auffaelligkeitenBeiAnspruchUndBestand.push(entry);
            }
        }

        apiContext.setProblemfaelleData(prev => ({...prev, auffaelligkeitenBeiAnspruchUndBestand}));
        return auffaelligkeitenBeiAnspruchUndBestand;
    }

    const loadFehlmengenAufgrundVonLieferengpaessen = (refreshToken=true) => {

    }

    const loadHandlungsbedarfVertriebsstatus = async (refreshToken=true, highPriority=false) => {
        const url = BASE_URL + `planzeile/problemfaelle/vertriebsstatus?highPriority=${highPriority ? 'true' : 'false'}`
        const handlungsbedarfVertriebsstatus = (await callApiAsync({url, auth, refreshToken})).data.OBJECT;
        apiContext.setProblemfaelleData(prev => ({...prev, handlungsbedarfVertriebsstatus}))

        return handlungsbedarfVertriebsstatus;
    }
    const loadHandlungsbedarfArzneimittelNichtInBestand = async (refreshToken=true, highPriority=false) => {
        const url = BASE_URL + `planzeile?arzneimittelnichtinbestand=true&highPriority=${highPriority ? 'true' : 'false'}`
        const data = (await callApiAsync({url, auth, refreshToken})).data.OBJECT

        const bestandMap = new Map();
        const patientIdMap = new Map();
        for (const entry of data) {
            patientIdMap.set(entry.patient, entry.patientId);
            if (entry.bestand.some(b => b.substituteFactor === 1)) {
                if (!bestandMap.has(entry.patient)) bestandMap.set(entry.patient, new Set());
                bestandMap.get(entry.patient).add(entry.arzneimittel);
            }
        }

        const handlungsbedarfArzneimittelNichtInBestand = Array.from(bestandMap.keys()).map(patient => ({patient, patientId: patientIdMap.get(patient), planzeilen: Array.from(bestandMap.get(patient))}));
        apiContext.setProblemfaelleData(prev => ({...prev, handlungsbedarfArzneimittelNichtInBestand}))

        return handlungsbedarfArzneimittelNichtInBestand;
    }

    return {
        loadProblemfaelle,
        problemfaelleCount: apiContext.problemfaelleData.problemfaelleCount || 0,

        loadFehlbestandEntries,
        fehlbestandEntriesLoaded: !!apiContext.problemfaelleData.fehlbestandEntries,
        fehlbestandEntries: apiContext.problemfaelleData.fehlbestandEntries || [],

        loadLieferengpassEntries,
        lieferengpassEntriesLoaded: !!apiContext.problemfaelleData.lieferengpassEntries,
        lieferengpassEntries: apiContext.problemfaelleData.lieferengpassEntries || [],

        loadAuffaelligkeitenBeiAnspruchUndBestand,
        auffaelligkeitenBeiAnspruchUndBestandLoaded: !!apiContext.problemfaelleData.auffaelligkeitenBeiAnspruchUndBestand,
        auffaelligkeitenBeiAnspruchUndBestand: apiContext.problemfaelleData.auffaelligkeitenBeiAnspruchUndBestand || [],

        loadFehlmengenAufgrundVonLieferengpaessen,
        fehlmengenAufgrundVonLieferengpaessenLoaded: !!apiContext.problemfaelleData.fehlmengenAufgrundVonLieferengpaessen,
        fehlmengenAufgrundVonLieferengpaessen: apiContext.problemfaelleData.fehlmengenAufgrundVonLieferengpaessen || [],

        loadHandlungsbedarfVertriebsstatus,
        handlungsbedarfVertriebsstatusLoaded: !!apiContext.problemfaelleData.handlungsbedarfVertriebsstatus,
        handlungsbedarfVertriebsstatus: apiContext.problemfaelleData.handlungsbedarfVertriebsstatus || [],

        loadHandlungsbedarfArzneimittelNichtInBestand,
        handlungsbedarfArzneimittelNichtInBestandLoaded: !!apiContext.problemfaelleData.handlungsbedarfArzneimittelNichtInBestand,
        handlungsbedarfArzneimittelNichtInBestand: apiContext.problemfaelleData.handlungsbedarfArzneimittelNichtInBestand || [],
    }
}