import axios, {CancelTokenStatic as CancelToken} from 'axios'
import {notification } from 'antd';
import {useAuth} from "./useAuth";
import moment from "moment";
import {encodeBase64} from "./encodingUtil";

export class CancelablePromise extends Promise {
    setCancelFunction(val) {
        this.cancelFunction = val;
    }

    cancel(message) {
        if (this.cancelFunction) {
            this.cancelFunction(message);
        }
    }
}

/**
 * ruft API auf Wildfly auf
 * bereinigt body bzgl attributen mit wert null
 *
 * @param {*} param0
 */
export const callApiAsync = ({auth = {}, url = null, data = {}, method = 'get', headers = {}, onError:onErrorProp = null, throwOnError = false, successOnError = false, refreshToken = true, timeout = 0, skipErrorLog = false}) => {
    // console.log(`callApiAsync: url ${url} refreshToken ${refreshToken}`);

    let cancelFunction;
    const cancelablePromise = new CancelablePromise(
        (onSuccess, onError) => {

            const onSuccessAxios = res => {
                const handleError = (throwOnError=true) => {
                    if (!skipErrorLog) {
                        console.log("Fehler API-call:", res);
                        notification.open({
                            type: "error",
                            message: "Fehler",
                            description: "Ein Fehler ist aufgetreten beim Aufruf der API."
                        });

                        if (res.data.EXCEPTION) {
                            const e = res.data.EXCEPTION;
                            if (e.stackTrace) {
                                for (let entry of e.stackTrace) {
                                    if (entry.lineNumber >= 0 && entry.classLoaderName && entry.classLoaderName.startsWith("deployment")) {
                                        console.log("error: " + res.data.EXCEPTION_CLASS, entry);
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    if (throwOnError) onError(res);
                };

                if (res.statusText === "OK" && res.data.RESULT === "SUCCESS") {
                    onSuccess(res);
                } else {
                    const processHandler = async (handler) => {
                        if (handler) {
                            const type = handler.constructor.name;
                            if (type === 'Function') {
                                return handler(res);
                            } else if (type === 'AsyncFunction') {
                                const response = await handler(res);
                                return {asyncResponse: response};
                            } else if (handler.message === res.data.MESSAGE) {
                                if (handler.callback) {
                                    if (handler.callback.constructor.name === "AsyncFunction") {
                                        const response = await handler.callback(res);
                                        return {asyncResponse: response};
                                    } else {
                                        handler.callback(res);
                                    }
                                } else {
                                    console.error("no callback provided");
                                }

                                return true;
                            }
                        }

                        return false;
                    }

                    if (!onErrorProp) {
                        handleError();
                        if (successOnError) onSuccess(res);
                    } else if (Array.isArray(onErrorProp)) {
                        for (let handler of onErrorProp) {
                            processHandler(handler)
                                .then(res2 => {
                                    if (res2 && res2.asyncResponse) {
                                        if (res2.statusText === "OK" && res2.data.RESULT === "SUCCESS") {
                                            onSuccess(res2);
                                        } else {
                                            handleError(throwOnError);
                                            if (successOnError) onSuccess(res);
                                        }
                                    } else if (!res2 || !res2.asyncResponse) {
                                        handleError(throwOnError);
                                        if (successOnError) onSuccess(res);
                                    } else if (successOnError) onSuccess(res);
                                });
                        }
                    } else {
                        processHandler(onErrorProp)
                            .then(res2 => {
                                if (res2 && res2.asyncResponse) {
                                    if (res2.statusText === "OK" && res2.data.RESULT === "SUCCESS") {
                                        onSuccess(res2);
                                    } else {
                                        handleError(throwOnError);
                                        if (successOnError) onSuccess(res);
                                    }
                                } else if (!res2) {
                                    handleError(throwOnError);
                                    if (successOnError) onSuccess(res);
                                } else if (successOnError) onSuccess(res);
                            });
                    }
                }
            };

            const onErrorAxios = (err) => {
                if (axios.isCancel(err)) {
                    console.log("Anfrage abgebrochen: " + url);
                    return;
                }

                console.error(err);
                notification.open({
                    duration: 20,
                    message: "Fehler aufgetreten :/",
                    description: JSON.stringify(err)
                });

                if (err.response && err.response.status === 401) {
                    if (
                        !auth.addLoginListener({onSuccess: (authNeu) => {
                            callApiAsync({auth: authNeu, url, data, method, headers, onErrorProp, refreshToken}).then(onSuccess, onError);
                        }, onError, url})
                    ) {
                        performCall();
                    }
                } else {
                    onError(err);
                }
            };

            const performCall = (headers) => {
                if (!headers) {
                    onError({response: {status: 500, data: {MESSAGE: "no Authorization header"}}});
                }

                axios({
                    method,
                    url,
                    data,
                    headers,
                    timeout,
                    responseType: 'json',
                    cancelToken: new axios.CancelToken(c => {
                        cancelFunction = c;
                    }),
                }).then(onSuccessAxios, onErrorAxios);
            };

            // Bascic Auth to retrieve JWT
            if (auth.username && auth.password) {
                headers["Authorization"] = 'Basic ' + encodeBase64(auth.username + ':' + auth.password);
                performCall(headers);
            }

            // use Auth Token
            else if (auth.token) {
                removeEmpty(data);

                if (refreshToken && auth.refreshToken) {
                    auth.refreshToken()
                        .then((token) => {
                            if (token) {
                                headers["Authorization"] = 'Bearer ' + token;
                                performCall(headers);
                            }
                        })
                        .catch(onErrorAxios);
                } else {
                    headers["Authorization"] = 'Bearer ' + auth.token;
                    performCall(headers);
                }
            } else {
                const listenerAdded = auth.addLoginListener({onSuccess: async (authNeu) => {
                        callApiAsync({auth: authNeu, url, data, method, headers, onErrorProp}).then(onSuccess, onError);
                    }, onError, url});

                if (!listenerAdded) {
                    performCall(headers);
                }
            }
        });

    if (cancelFunction) {
        cancelablePromise.setCancelFunction(cancelFunction);
    }

    return cancelablePromise;
}


// entfernt null attribute aus JSON objekt
// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
export const removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
        else if (obj[key] == null) delete obj[key];
    });
};
