import {useContext, useEffect, useState} from "react";
import ApiContext from "../contexts/ApiContext";
import {useAuth} from "./useAuth";
import {callApiAsync} from "./apiUtil";
import {dokumenteApi} from "../config/apiConfig";
import DokumenteContext from "../contexts/DokumenteContext";

export const useApiDokumente = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);
    const dokumenteContext = useContext(DokumenteContext);

    const [dokumenteNeuFilter, setDokumenteNeuFilter] = useState({unverknuepft: true})
    const [dokumenteNeuLoaded, setDokumenteNeuLoaded] = useState(false);
    const [verknuepfungenUnbearbeitetPatienten, setVerknuepfungenUnbearbeitetPatienten] = useState([]);

    useEffect(() => {
        return;
    }, [dokumenteContext.dokumenteListeNeu])

    useEffect(() => {
        if (apiContext.dokumenteData.verknuepfungenUnbearbeitet) {
            const verknuepfungenUnbearbeitetPatientenMap = {};
            for (let verknuepfung of apiContext.dokumenteData.verknuepfungenUnbearbeitet) {
                if (verknuepfung.unit) {
                    const typSet = verknuepfungenUnbearbeitetPatientenMap[verknuepfung.unit.id]?.types || new Set();
                    typSet.add(verknuepfung.dokument.typ);

                    verknuepfungenUnbearbeitetPatientenMap[verknuepfung.unit.id] = { unit: verknuepfung.unit, types: typSet };
                }
            }

            setVerknuepfungenUnbearbeitetPatienten(Object.values(verknuepfungenUnbearbeitetPatientenMap));
        }
    }, [apiContext.dokumenteData.verknuepfungenUnbearbeitet])

    const loadDokumenteNeu = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: dokumenteApi.getByFilter(dokumenteNeuFilter), refreshToken});
        apiContext.setDokumenteData(prev => ({...prev, neu: response.data.OBJECT}));

        setDokumenteNeuLoaded(true);
        dokumenteContext.setDokumenteListeNeu(response.data.OBJECT);
        return response.data.OBJECT;
    }

    const loadDokumenteUnbearbeitet = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: dokumenteApi.getByFilter({unbearbeitet: true}), refreshToken});
        apiContext.setDokumenteData(prev => ({...prev, dokumenteUnbearbeitet: response.data.OBJECT}));
    }

    const loadVerknuepfungenUnbearbeitet = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: dokumenteApi.getVerknuepfungenUnbearbeitet(), refreshToken});
        apiContext.setDokumenteData(prev => ({...prev, verknuepfungenUnbearbeitet: response.data.OBJECT}));
    }

    return {
        dokumenteNeu: dokumenteContext.dokumenteListeNeu || [],
        dokumenteNeuLoaded,
        loadDokumenteNeu,
        dokumenteNeuFilter,
        setDokumenteNeuFilter,

        dokumenteUnbearbeitet: apiContext.dokumenteData.dokumenteUnbearbeitet ? apiContext.dokumenteData.dokumenteUnbearbeitet : [],
        dokumenteUnbearbeitetLoaded: !!apiContext.dokumenteData.dokumenteUnbearbeitet,
        loadDokumenteUnbearbeitet,

        verknuepfungenUnbearbeitet: apiContext.dokumenteData.verknuepfungenUnbearbeitet ? apiContext.dokumenteData.verknuepfungenUnbearbeitet : [],
        verknuepfungenUnbearbeitetLoaded: !!apiContext.dokumenteData.verknuepfungenUnbearbeitet,
        loadVerknuepfungenUnbearbeitet,

        verknuepfungenUnbearbeitetPatienten,
    }
}