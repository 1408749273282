import React from "react";
import {Table} from "antd";
import {getUnitLangname} from "../../config/entities";
import {getSorter} from "../../utilities/sortUtil";
import {getColumnSearchProps} from "../../utilities/useTableSearchFilter";
import usePagination from "../../utilities/usePagination";
import {useApi} from "../../utilities/useApi";

const MedikationsplanTable = ({}) => {
    const api = useApi();
    const pagination = usePagination({pageSize: 20});

    const onFilter = (value, record, dataIndex) => {
        switch (dataIndex) {
            case "Name":
                return getUnitLangname(record)
                    .toLowerCase()
                    .includes(value.toLowerCase());
            case "Heim":
                return getUnitLangname(record.careFacility)
                    .toLowerCase()
                    .includes(value.toLowerCase());
            case "Station":
                return getUnitLangname(record.station)
                    .toLowerCase()
                    .includes(value.toLowerCase());
        }
    };

    return <>
        <h6>Übersicht aller Patienten mit Medikationsplänen</h6>
        <Table
            rowKey={"id"}
            dataSource={api.patientenMitMedikationsplan}

            pagination={pagination}

            columns={[
                {
                    key: "name",
                    title: "Name",
                    render: (unit, index) => getUnitLangname(unit),
                    sorter: getSorter("unit", "name"),
                    ...getColumnSearchProps({dataIndex: "Name", onFilter}),
                },
                {
                    key: "heim",
                    title: "Heim",
                    render: (unit, index) => {
                        return getUnitLangname(unit.careFacility)
                    },
                    sorter: getSorter("unit", "heim"),
                    ...getColumnSearchProps({dataIndex: "Heim", onFilter}),
                },
                {
                    key: "station",
                    title: "Station",
                    render: (unit, index) => getUnitLangname(unit.station),
                    sorter: getSorter("unit", "station"),
                    ...getColumnSearchProps({dataIndex: "Station", onFilter}),
                }
            ]}

            onRow={(unit, index) => ({
                style: {cursor: "pointer"},
                onClick: () => {
                    window.location = '/medikationsplan/' + unit.medikationsplanId;
                }
            })}
        />
    </>
}

export default MedikationsplanTable;