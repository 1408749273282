import { useState } from 'react'
import { callApiAsync } from "./myApi_deprecated";
import { useAuth } from '../utilities/useAuth';

import { notification } from 'antd';

export const usePost = (url = null, body = {}, onSuccess = null) => {

    const auth = useAuth();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);


    const postData = async (prop) => {
        if (prop) body = prop;
        if (!url) return null;

        console.info("call postData at", url);
        if (body)
            console.info("body", body);


        setSending(true);

        // todo remove callApiAsync and use axios directly
        const response = await callApiAsync(auth, url, body, "post");

        if (response && response.data && response.data.RESULT === 'SUCCESS') {
            setData(response.data.OBJECT);
            if (onSuccess)
                onSuccess(response.data.OBJECT);
            setSending(false);
            setSuccess(true)
        } else {
            setError(JSON.stringify(response));
            console.error(response);
            notification.open({
                duration: 20,
                message: "Fehler aufgetreten :/",
                description: JSON.stringify(response)
            });
        }

        return response.data.OBJECT ? response.data.OBJECT : null;
    };


    return { data, sending, success, error, handleSend: postData };

};
