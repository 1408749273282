import React, {useEffect, useState} from "react";
import {mainStyle, siderStyle} from "../../style/css";
import MedikationsplanSuche from "../organisms/MedikationsplanSuche";
import {Badge, Col, Collapse, Layout, Row, Table,} from "antd";
import {useApi} from "../../utilities/useApi";
import {ModusRezeptanforderung, Rezeptanforderungen} from "../organisms/Rezeptanforderungen";
import {getMedicineM2Name} from "../../utilities/medicineM2Util";
import {getUnitLangname, getUnitNameDateOfBirthConcat} from "../../config/entities";
import {format} from "date-fns";
import {getSorter} from "../../utilities/sortUtil";
import {getColumnSearchProps} from "../../utilities/useTableSearchFilter";
import {Link} from "react-router-dom";
import {getLinkUrlToMedikationsplan} from "../../utilities/linkUtil";

const {Sider} = Layout;
const Problemfaelle = ({}) => {
    const api = useApi();

    const [akteureMap, setAkteureMap] = useState({});

    useEffect(() => {
        api.loadProblemfaelle(true, true);
    }, [])

    useEffect(() => {
        for (const entry of api.fehlbestandEntries) {
            if (!akteureMap[entry.patient.id]) {
                api.loadAkteur(entry.patient.id).then(akteur => setAkteureMap(prev => ({...prev, [entry.patient.id]: akteur})));
            }
        }
    }, [api.fehlbestandEntries])

    const fehlbestandColumns = [
        {
            title: 'Patient',
            key: 'Patient',
            render: entry => <Link to={getLinkUrlToMedikationsplan(entry.patient.id)}>{getUnitNameDateOfBirthConcat(entry.patient)}</Link>,

            defaultSortOrder: "ascend",
            sorter: (a, b) => getSorter("unit")(a.patient, b.patient),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, entry) => getUnitLangname(entry.patient).toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Arzneimittel',
            key: 'Arzneimittel',
            render: entry => getMedicineM2Name(entry.m2Entity),

            sorter: (a, b) => getSorter("medicineM2")(a.m2Entity, b.m2Entity),
            ...getColumnSearchProps({dataIndex: "m2Entity", onFilter: (value, entry) => getMedicineM2Name(entry.m2Entity).toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Fehlmenge',
            key: 'Fehlmenge',
            render: entry => entry.amount
        },
        {
            title: 'Erstellt',
            key: 'Erstellt',
            render: entry => format(entry.dateCreated, 'dd.MM.yyyy'),

            sorter: (a, b) => getSorter("date")(a.dateCreated, b.dateCreated),
        },
        {
            title: 'Grund',
            key: 'Grund',
            render: entry => entry.reason,

            sorter: (a, b) => a.reason.localeCompare(b.reason),
        },
        {
            title: 'Heim',
            key: 'Heim',
            render: entry => {
                const akteur = akteureMap[entry.patient.id];
                if (akteur) {
                    return getUnitLangname(akteur.careFacility);
                }
            },

            sorter: (a, b) => getSorter("unit")(a.careFacility, b.careFacility),
            ...getColumnSearchProps({dataIndex: "careFacility", onFilter: (value, entry) => getUnitLangname(akteureMap[entry.patient.id]?.careFacility)?.toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Station',
            key: 'Station',
            render: entry => {
                const akteur = akteureMap[entry.patient.id];
                if (akteur) {
                    return getUnitLangname(akteur.station);
                }
            },

            sorter: (a, b) => getSorter("unit")(a.station, b.station),
            ...getColumnSearchProps({dataIndex: "station", onFilter: (value, entry) => getUnitLangname(akteureMap[entry.patient.id]?.station).toLowerCase().includes(value.toLowerCase())}),
        },
    ]

    const auffaelligkeitenBeiAnspruchUndBestandColumns = [
        {
            title: 'Patient',
            key: 'Patient',
            render: entry => {
                return <Link to={getLinkUrlToMedikationsplan(entry.patientId)}>{entry.patient}</Link>
            },

            sorter: (a, b) => getSorter("unit")(a.patient, b.patient),
            defaultSortOrder: "ascend",
        },
        {
            title: 'Station',
            key: 'Station',
            render: entry => entry.station
        },
        {
            title: 'Arzneimittel',
            key: 'Arzneimittel',
            render: entry => entry.arzneimittel
        },
        {
            title: 'Anspruch',
            key: 'Anspruch',
            render: entry => entry.anspruch
        },
        {
            title: 'Vorab',
            key: 'Vorab',
            render: entry => entry.vorabAnforderung
        },
        {
            title: 'Bestand',
            key: 'Bestand',
            render: entry => entry.bestand
        },
        {
            title: 'Bestellung',
            key: 'Bestellung',
            render: entry => entry.bestellung
        },
    ];

    const handlungsbedarfVertriebsstatusColumns = [
        {
            title: 'Patient',
            key: 'patientName',
            render: entry => {
                return <Link to={getLinkUrlToMedikationsplan(entry.patientId)}>{entry.patientName}</Link>;
            },

            defaultSortOrder: "ascend",
            sorter: (a, b) => a.patientName.localeCompare(b.patientName),
            ...getColumnSearchProps({dataIndex: "patientName", onFilter: (value, entry) => entry.patientName.toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Arzneimittel',
            key: 'arzneimittelLangname',
            render: entry => entry.arzneimittelLangname,

            sorter: (a, b) => a.arzneimittelLangname.localeCompare(b.arzneimittelLangname),
            ...getColumnSearchProps({dataIndex: "arzneimittelLangname", onFilter: (value, entry) => entry.arzneimittelLangname.toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Vertriebsstatus',
            key: 'vertriebsstatus',
            render: entry => entry.vertriebsstatus
        },
    ];

    const handlungsbedarfArzneimittelNichtInBestandColumns = [
        {
            title: 'Patient',
            key: 'Patient',
            render: entry => {
                if (entry.patientId) {
                    return <Link key={entry.patientId} to={getLinkUrlToMedikationsplan(entry.patientId)}>{entry.patient}</Link>;
                }

                return entry.patient
            },

            defaultSortOrder: "ascend",
            sorter: (a, b) => a.patient.localeCompare(b.patient),
            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, entry) => entry.patient.toLowerCase().includes(value.toLowerCase())}),
        },
        {
            title: 'Planzeilen',
            key: 'Planzeilen',
            render: entry => entry.planzeilen.map(p => <div key={p}>{p}</div>),

            // sorter: (a, b) => a.arzneimittel.localeCompare(b.arzneimittel),
            // ...getColumnSearchProps({dataIndex: "arzneimittel", onFilter: (value, entry) => entry.arzneimittel.toLowerCase().includes(value.toLowerCase())}),
        }
    ];

    const titleList = [
        'Überfällig für Verblisterung ("benötigt bis"-Datum in Vergangenheit)',
        'Patienten mit Unterversorgung (Reichweite der Ansprüche in Vergangenheit)',
        'gefaxte Rezepte seit mehr als 9 Tagen überfällig',
        'automatisches Mahnwesen ausgeschöpft',
        'Anforderungen trotz ausreichendem Bestand (Anforderung nicht mehr in aktueller Woche nötig)',
        'gelöschte/abgesetzte Planzeilen',
        'Patient im Krankenhaus',
        'Patient verstorben',
        'Patient gelöscht',
        'Patienten mit Fehlbeständen',
        'Fehlbestände aufgrund von Lieferengpässen',
        'Äuffälligkeiten bei Anspruch und Bestand',
        'Planzeilen mit Handlungsbedarf aufgrund von Arzneimittel-Vertriebsstatus',
        'Patienten mit Planzeilenarzneimittel nicht in Bestand, aber 1:1 Austausch möglich'
    ]

    return <Layout>
        <Sider style={{...siderStyle}} className="no-print">
            <MedikationsplanSuche />
        </Sider>

        <Layout style={mainStyle}>
            <Row>
                <Col span={24}>
                    <Collapse accordion={true}>
                        <Collapse.Panel showArrow={false} key={titleList[0]} header={<Badge count={api.anforderungenUeberfaelligFuerVerblisterung.length}><span style={{marginRight: 20}}>{titleList[0]}</span></Badge>}>
                            {/*Benötigt bis datum <= heute*/}
                            <div style={{overflowX: "scroll"}}>
                                <Rezeptanforderungen
                                    title={titleList[0]}
                                    anforderungen={api.anforderungenUeberfaelligFuerVerblisterung}
                                    modus={ModusRezeptanforderung.offen}
                                />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[1]} header={<Badge count={api.anforderungenPatientenMitUnterversorgung.length}><span style={{marginRight: 20}}>{titleList[1]}</span></Badge>}>
                            {/*Reichweiten <= heute*/}
                            <div style={{overflowX: "scroll"}}>
                                <Rezeptanforderungen
                                    title={titleList[1]}
                                    anforderungen={api.anforderungenPatientenMitUnterversorgung}
                                    modus={ModusRezeptanforderung.offen}
                                />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[2]} header={<Badge count={api.anforderungenGefaxteRezepteUeberfaellig.length}><span style={{marginRight: 20}}>{titleList[2]}</span></Badge>}>
                            {/*Faxdatum mehr als 10 Tage zurück*/}
                            <div style={{overflowX: "scroll"}}>
                                <Rezeptanforderungen
                                    title={titleList[2]}
                                    anforderungen={api.anforderungenGefaxteRezepteUeberfaellig}
                                    modus={ModusRezeptanforderung.offen}
                                />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[3]} header={<Badge count={api.anforderungenAutomatischesMahnwesenAusgeschoepft.length}><span style={{marginRight: 20}}>{titleList[3]}</span></Badge>}>
                            {/*3. Mahnstufe überfällig*/}
                            <div style={{overflowX: "scroll"}}>
                                <Rezeptanforderungen
                                    title={titleList[3]}
                                    anforderungen={api.anforderungenAutomatischesMahnwesenAusgeschoepft}
                                    modus={ModusRezeptanforderung.offen}
                                />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={'Unnötige Anforderungen'} header={<Badge count={
                            api.anforderungenTrotzAusreichendemBestand.length +
                            api.anforderungenGeloeschtePlanzeile.length +
                            api.anforderungenPatientInKrankenhaus.length +
                            api.anforderungenPatientVerstorben.length +
                            api.anforderungenPatientGeloescht.length
                        }><span style={{marginRight: 20}}>Unnötige Anforderungen</span></Badge>}>
                            <Collapse>
                                <Collapse.Panel showArrow={false} key={titleList[4]} header={<Badge count={api.anforderungenTrotzAusreichendemBestand.length}><span style={{marginRight: 20}}>{titleList[4]}</span></Badge>}>
                                    {/*gelbe Medikamentennamen*/}
                                    <div style={{overflowX: "scroll"}}>
                                        <Rezeptanforderungen
                                            title={titleList[4]}
                                            anforderungen={api.anforderungenTrotzAusreichendemBestand}
                                            modus={ModusRezeptanforderung.offen}
                                        />
                                    </div>
                                </Collapse.Panel>

                                <Collapse.Panel showArrow={false} key={titleList[5]} header={<Badge count={api.anforderungenGeloeschtePlanzeile.length}><span style={{marginRight: 20}}>{titleList[5]}</span></Badge>}>
                                    {/*rote Medikamentennamen*/}
                                    <div style={{overflowX: "scroll"}}>
                                        <Rezeptanforderungen
                                            title={titleList[5]}
                                            anforderungen={api.anforderungenGeloeschtePlanzeile}
                                            modus={ModusRezeptanforderung.offen}
                                        />
                                    </div>
                                </Collapse.Panel>

                                <Collapse.Panel showArrow={false} key={titleList[6]} header={<Badge count={api.anforderungenPatientInKrankenhaus.length}><span style={{marginRight: 20}}>{titleList[6]}</span></Badge>}>
                                    <div style={{overflowX: "scroll"}}>
                                        <Rezeptanforderungen
                                            title={titleList[6]}
                                            anforderungen={api.anforderungenPatientInKrankenhaus}
                                            modus={ModusRezeptanforderung.offen}
                                        />
                                    </div>
                                </Collapse.Panel>

                                <Collapse.Panel showArrow={false} key={titleList[7]} header={<Badge count={api.anforderungenPatientVerstorben.length}><span style={{marginRight: 20}}>{titleList[7]}</span></Badge>}>
                                    <div style={{overflowX: "scroll"}}>
                                        <Rezeptanforderungen
                                            title={titleList[7]}
                                            anforderungen={api.anforderungenPatientVerstorben}
                                            modus={ModusRezeptanforderung.offen}
                                        />
                                    </div>
                                </Collapse.Panel>

                                <Collapse.Panel showArrow={false} key={titleList[8]} header={<Badge count={api.anforderungenPatientGeloescht.length}><span style={{marginRight: 20}}>{titleList[8]}</span></Badge>}>
                                    <div style={{overflowX: "scroll"}}>
                                        <Rezeptanforderungen
                                            title={titleList[8]}
                                            anforderungen={api.anforderungenPatientGeloescht}
                                            modus={ModusRezeptanforderung.offen}
                                        />
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[9]} header={<Badge count={api.fehlbestandEntries.length}><span style={{marginRight: 20}}>{titleList[9]}</span></Badge>}>
                            {/*welche Medikament, mit Menge an Fehlbestand, Datum eintritt des Fehlbestands, Grund des Fehlbestands, Verordner, welches Heim und welche Station*/}
                            <div style={{overflowX: "scroll"}}>
                                <Table dataSource={api.fehlbestandEntries} columns={fehlbestandColumns} />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[10]} header={<Badge count={api.lieferengpassEntries.length}><span style={{marginRight: 20}}>{titleList[10]}</span></Badge>}>
                            {/*welche Medikament, mit Menge an Fehlbestand, Datum eintritt des Fehlbestands, Grund des Fehlbestands, Verordner, welches Heim und welche Station*/}
                            <div style={{overflowX: "scroll"}}>
                                <Table dataSource={api.lieferengpassEntries} columns={fehlbestandColumns} />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[11]} header={<Badge count={api.auffaelligkeitenBeiAnspruchUndBestand.length}><span style={{marginRight: 20}}>{titleList[11]}</span></Badge>}>
                            {/*Quintessenz der Auswertung Anspruch + Vorab <> Bestand + Bestelleung*/}
                            <div style={{overflowX: "scroll"}}>
                                <Table dataSource={api.auffaelligkeitenBeiAnspruchUndBestand} columns={auffaelligkeitenBeiAnspruchUndBestandColumns} />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[12]} header={<Badge count={api.handlungsbedarfVertriebsstatus.length}><span style={{marginRight: 20}}>{titleList[12]}</span></Badge>}>
                            <div style={{overflowX: "scroll"}}>
                                <Table dataSource={api.handlungsbedarfVertriebsstatus} columns={handlungsbedarfVertriebsstatusColumns} />
                            </div>
                        </Collapse.Panel>

                        <Collapse.Panel showArrow={false} key={titleList[13]} header={<Badge count={api.handlungsbedarfArzneimittelNichtInBestand.length}><span style={{marginRight: 20}}>{titleList[13]}</span></Badge>}>
                            <div style={{overflowX: "scroll"}}>
                                <Table dataSource={api.handlungsbedarfArzneimittelNichtInBestand} columns={handlungsbedarfArzneimittelNichtInBestandColumns} />
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        </Layout>
    </Layout>
}

export default Problemfaelle;