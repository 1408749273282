import React, {useContext} from "react";
import DokumenteContext from "../contexts/DokumenteContext";
import {callApiAsync} from "./apiUtil";
import {dokumenteApi} from "../config/apiConfig";

const useDateiVorschau = () => {
    const dokumenteContext = useContext(DokumenteContext);

    const dateiVorschau = ({ dokumentId = null, patientId = null, planzeileId = null, urlAddon="" }) => {
        let targetName;
        if (dokumentId) {
            targetName = `dokumenteViewer-dokument`;
            const url = '/vorschau/dokument/' + dokumentId + urlAddon;
            window.open(url, targetName, 'location=0,menubar=0');
        }

        else if (patientId) {
            targetName = `dokumenteViewer-patient-${patientId}`;
            window.open('/vorschau/patient/' + patientId + urlAddon, targetName, 'location=0,menubar=0');
        }

        else if (planzeileId) {
            targetName = `dokumenteViewer-planzeile-${planzeileId}`;
            window.open('/vorschau/planzeile/' + planzeileId + urlAddon, targetName, 'location=0,menubar=0');
        }
    };

    const openDokument = (dokumentId, auth=null, urlAddon="") => {
        dateiVorschau({ dokumentId, urlAddon });
        if (auth) callApiAsync({auth, url: dokumenteApi.bearbeitungBeginnen(dokumentId)});
        // dokumenteContext.setLocalStorage();
    }

    return {
        dateiVorschau,
        openDokument
    }
}

export default useDateiVorschau;