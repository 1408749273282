import React, {useEffect, useState} from "react";
import {Dropdown, Input, Menu} from "antd";
import Spinner from "react-bootstrap/Spinner";

const SearchInputDropdown = ({value, onChange=()=>{}, handleChange=()=>{}, overlayItems=[], selected, loading=false, onFocusChange=()=>{}}) => {
    const [inputValue, setInputValue] = useState();
    useEffect(() => setInputValue(value), [value]);

    return <Dropdown
        visible={!!overlayItems.length}
        overlay={<Menu selectable={true} selectedKeys={[selected]}>
            {overlayItems.map(i => <Menu.Item key={i.key} onClick={() => handleChange(i)}>{i.item}</Menu.Item>)}
        </Menu>}
        >
        <div>
            <Input value={value === undefined ? inputValue : value}  onChange={e => {
                if (value === undefined) setInputValue(e.target.value);
                onChange(e.target.value);
            }} onFocus={() => onFocusChange(true)} onBlur={() => onFocusChange(false)} />
            {loading ? <Spinner animation={"border"} size={"sm"} style={{position: "absolute"}} /> : <></>}
        </div>
    </Dropdown>
}

export default SearchInputDropdown;