import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "./useAuth";
import {callApiAsync} from "./apiUtil";
import {akteureApi, notizApi} from "../config/apiConfig";
import NotizContext from "../contexts/NotizContext";
import ApiContext from "../contexts/ApiContext";
import {MedikationsplanBestandTable} from "../components/organisms/MedikationsplanBestandTable";


export const useApiNotizen = () => {
    const auth = useAuth();
    const notizContext = useContext(NotizContext);
    const apiContext = useContext(ApiContext);

    useEffect(() => {
        (async () => {
            if (notizContext.notizSavedLatest) {
                // Die Notiz war als unbearbeitete Notiz bekannt
                if ((apiContext.notizenData.notizenUnbearbeitetIdList || []).includes(notizContext.notizSavedLatest.id)) {
                    const unitIdList = [...(notizContext.notizSavedLatest.unitSet || []).map(e => e.id.unitId), ...(notizContext.notizSavedLatest.interaktionSet || []).map(e => e.id.unitId)];

                    for (let unitId of unitIdList) {
                        const notizenUnbearbeitet = apiContext.notizenData.notizenUnbearbeitet.filter(n => n.id !== notizContext.notizSavedLatest.id);
                        const notizenUnbearbeitetIdList = apiContext.notizenData.notizenUnbearbeitetIdList.filter(id => id !== notizContext.notizSavedLatest.id);

                        const unbearbeitetList = ((apiContext.notizenData.unitUnbearbeitetMap[unitId] || [])[1] || []).filter(n => n.id !== notizContext.notizSavedLatest.id);

                        // Update eintragen, wenn die Notiz nicht als bearbeitet markiert wurde
                        if (!([...(notizContext.notizSavedLatest.unitSet || []), ...(notizContext.notizSavedLatest.interaktionSet || [])]).find(u => u.id.unitId === unitId)?.bearbeitet) {
                            notizenUnbearbeitet.push(notizContext.notizSavedLatest);
                            unbearbeitetList.push(notizContext.notizSavedLatest);
                            notizenUnbearbeitetIdList.push(notizContext.notizSavedLatest.id);
                        }

                        let unitUnbearbeitetMap;
                        if (!!unbearbeitetList.length) {
                            let unit = (apiContext.notizenData.unitUnbearbeitetMap[unitId] || [])[0];
                            if (!unit) {
                                if (unit.id !== 0) {
                                    unit = (await callApiAsync({
                                        auth,
                                        url: akteureApi.getById(unitId)
                                    })).data.OBJECT;
                                }
                            }

                            unitUnbearbeitetMap = {...apiContext.notizenData.unitUnbearbeitetMap, [unitId]: [unit, unbearbeitetList]}
                        } else {
                            unitUnbearbeitetMap = {...apiContext.notizenData.unitUnbearbeitetMap};
                            delete unitUnbearbeitetMap[unitId];
                        }

                        apiContext.setNotizenData(prev => ({...prev, notizenUnbearbeitet, notizenUnbearbeitetIdList, unitUnbearbeitetMap}));
                    }
                }

                // Die Notiz war bisher noch nicht bekannt und ist nicht bearbeitet
                else {
                    switch (notizContext.notizSavedLatest.type) {
                        case "UNIT":
                            const unitMap = {};
                            for (let e of notizContext.notizSavedLatest.unitSet || []) unitMap[e.id.unitId] = e;
                            for (let e of notizContext.notizSavedLatest.interaktionSet || []) unitMap[e.id.unitId] = e;

                            for (let unitId of Object.keys(unitMap)) {
                                if (unitId >= 0 && !unitMap[unitId]?.bearbeitet) {
                                    const unit = unitId === "0" ? null : (await callApiAsync({auth, url: akteureApi.getById(unitId)})).data.OBJECT;
                                    const notizenUnbearbeitet = [...(apiContext.notizenData.notizenUnbearbeitet || []), notizContext.notizSavedLatest];
                                    const notizenUnbearbeitetIdList = [...(apiContext.notizenData.notizenUnbearbeitetIdList || []), notizContext.notizSavedLatest.id];

                                    const unbearbeitetList = [...(apiContext.notizenData.unitUnbearbeitetMap?.[unitId] || []), notizContext.notizSavedLatest];
                                    const unitUnbearbeitetMap = {
                                        ...apiContext.notizenData.unitUnbearbeitetMap,
                                        [unitId]: [unit, unbearbeitetList]
                                    }

                                    apiContext.setNotizenData(prev => ({
                                        ...prev,
                                        notizenUnbearbeitet,
                                        notizenUnbearbeitetIdList,
                                        unitUnbearbeitetMap
                                    }));
                                }
                            }
                            break;
                    }
                }
            }
        })();
    }, [notizContext.notizSavedLatest])

    const loadNotizenForUnit = async (unitId, refreshToken=true) => {
        const response = await callApiAsync({url: notizApi.getByUnit(unitId), auth, refreshToken});
        const notizen = {};
        for (let notiz of response.data.OBJECT) {
            notizen[notiz.id] = notiz;
        }

        notizContext.setNotizMap(prev => ({...prev, [unitId]: notizen}));
        return notizen;
    }

    const loadNotizenUnbearbeitet = async (refreshToken=true) => {
        const response = await callApiAsync({auth, url: notizApi.getUnbearbeitet(), refreshToken});
        const data = response.data.OBJECT;

        const unitInteraktionenMap = {};
        const unitIdMap = {};
        const notizenUnbearbeitetIdList = [];
        const notizenUnbearbeitet = [];
        outer:for (let notiz of data) {
            switch (notiz.type) {
                case "UNIT":
                    for (let unit of notiz.unitSet) {
                        if (unit.id.unitId !== 0) {
                            unitIdMap[unit.id.unitId] = [...(unitIdMap[unit.id.unitId] || []), notiz];
                        }
                    }
                    break;
                case "INTERAKTION":
                    for (let interaktion of notiz.interaktionSet || []) {
                        if (interaktion.bearbeitet) {
                            const id = interaktion.id.notizId;
                        }

                        if (!unitInteraktionenMap[interaktion.id.unitId]) unitInteraktionenMap[interaktion.id.unitId] = {};
                        unitInteraktionenMap[interaktion.id.unitId][interaktion.id.interaktionId] = notiz;
                    }
            }

            notizenUnbearbeitetIdList.push(notiz.id);
            notizenUnbearbeitet.push(notiz);
        }

        const requestData = {
            type: "GET_UNIT_BY_ID_LIST",
            unitIdList: [...Object.keys(unitIdMap), ...Object.keys(unitInteraktionenMap)]
        }
        const response2 = await callApiAsync({auth, url: akteureApi.postUnit(), method: "post", data: requestData, refreshToken});
        const unitUnbearbeitetMap = {};
        for (let unit of response2.data.OBJECT) {
            unitUnbearbeitetMap[unit.id] = [unit, unitIdMap[unit.id] || [], unitInteraktionenMap[unit.id] || {}];
        }

        apiContext.setNotizenData(prev => ({...prev, notizenUnbearbeitet, notizenUnbearbeitetIdList, unitUnbearbeitetMap}));
    }

    return {
        loadNotizenForUnit,

        loadNotizenUnbearbeitet,
        notizenUnbearbeitetLoaded: !!apiContext.notizenData.notizenUnbearbeitet,
        notizenUnbearbeitet: apiContext.notizenData.notizenUnbearbeitet || [],
        notizenUnbearbeitetUnitMap: apiContext.notizenData.unitUnbearbeitetMap,
    }
}