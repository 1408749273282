import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import React, {useEffect, useRef, useState} from "react";
import {Input, DatePicker, Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import locale_de from "antd/es/date-picker/locale/de_DE";
import {Tooltip} from "../components/atoms/Tooltip";

export const useTableSearchFilter = () => {
    return {getColumnSearchProps, getColumnDateProps, TableSearchInput, handleSearch}
}

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm(selectedKeys);
};

export const getColumnSearchProps = ({dataIndex, onFilter = (value, record, dataIndex) => record[dataIndex].toLowerCase().includes(value.toLowerCase()), splitKeys=false, onChange=()=>{}, initialValue="", onFilterDropdownVisibleChange=(visible)=>{}, onFiltered=(isSichtbar, record, dataIndex)=>{}}) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        let isFinished = false;

        return <TableSearchInput
            initialValue={initialValue}
            dataIndex={dataIndex}
            selectedKeys={selectedKeys}
            clearFilters={() => {
                if (!isFinished) {
                    clearFilters();
                    onChange(null);
                }

                isFinished = true
            }}
            setSelectedKeys={setSelectedKeys}
            confirm={(val) => {
                if (!isFinished) {
                    confirm();
                    onChange(val);
                }

                isFinished = true
            }}
            splitKeys={splitKeys}
        />
    },
    onFilterDropdownVisibleChange,
    filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
        const isSichtbar = onFilter(value, record, dataIndex);
        onFiltered(isSichtbar, record, dataIndex);

        return isSichtbar;
    },
});

export const getColumnDateProps = (dataIndex, onFilter = (value, record, dataIndex) => {}, onChange = (start, ende) => {}) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        const value = selectedKeys && selectedKeys[0] ? [selectedKeys[0].start, selectedKeys[0].ende] : null;
        return (
            <>
                <DatePicker.RangePicker
                    value={value}
                    onCalendarChange={range => {
                        let obj = {};

                        if (range) {
                            obj = {start: range[0] ? range[0].startOf('day') : null, ende: range[1] ? range[1].endOf('day') : null}
                            setSelectedKeys([obj]);
                        } else {
                            setSelectedKeys(null);
                            confirm();
                        }

                        onChange(obj["start"], obj["ende"]);
                    }}

                    locale={locale_de}
                    allowEmpty
                />
                <Button onClick={confirm}>Filter anwenden</Button>
            </>
        )
    },
    filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => onFilter(value, record, dataIndex),
});

export const TableSearchInput = ({dataIndex, selectedKeys, clearFilters, setSelectedKeys, confirm, splitKeys, initialValue}) => {
    const inputRef = useRef();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        const initialValue1 = initialValue;
    }, [initialValue])

    useEffect(() => {
        inputRef.current.focus();
    })

    return <div style={{ padding: 8 }}>
        <Input
            placeholder={`Suche ${dataIndex}`}
            value={value}

            onChange={(e) => {
                setValue(e.target.value);
                setSelectedKeys(e.target.value ? (splitKeys ? e.target.value.split(/[, ]/).filter(v => v !== '') : [e.target.value]) : []);
            }}
            onPressEnter={(e) => handleSearch(selectedKeys, confirm, dataIndex)}
            onBlur={e => setTimeout(() => handleSearch(selectedKeys, confirm, dataIndex), 100)}

            style={{ width: 188, marginBottom: 8, display: 'block' }}
            addonAfter={<Tooltip title={"Filter entfernen"}><CloseOutlined onClick={() => {
                setValue(null);
                setSelectedKeys([]);
                clearFilters();
                handleSearch([], confirm, dataIndex);
            }} style={{cursor: "pointer"}} /></Tooltip>}

            ref={inputRef}
        />
    </div>
}