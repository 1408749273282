import React, {useEffect} from 'react'

import { useFetch } from '../../utilities/useFetch';
import { fetchGesammelteAnforderungen } from '../../config/fetchApiConfiguration';
import {modus, ModusRezeptanforderung, Rezeptanforderungen} from './Rezeptanforderungen';
import {useApi} from "../../utilities/useApi";
import {useAuth} from "../../utilities/useAuth";

export const RezeptanforderungenGesammelt = ({ gewaehlteNachricht, setGewaehlteNachricht, ...props }) => {

    const { anforderungenGesammelt, anforderungenGesammeltLoaded, loadAnforderungenGesammelt } = useApi();

    useEffect(() => {
        if (!anforderungenGesammeltLoaded) {
            loadAnforderungenGesammelt();
        }
    }, [])

    return <Rezeptanforderungen
        title={"Anforderungen gesammelt"}
        anforderungen={anforderungenGesammelt}
        loading={!anforderungenGesammeltLoaded}
        modus={ModusRezeptanforderung.gesammelt}
    />

}
