import React, {useContext, useEffect} from "react";
import {Select} from "antd";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {callApiAsync} from "../../utilities/apiUtil";
import {verblisterungApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";

const BlisterTypeSelect = ({ onChange, defaultValue }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);

    return <Select dropdownMatchSelectWidth={false} onChange={onChange} defaultValue={defaultValue} disabled={!medikationsplanContext.blisterTypes} loading={!medikationsplanContext.blisterTypes}>
        <Select.Option value={0}>Keine Voreinstellung</Select.Option>
        {(medikationsplanContext.blisterTypes || []).map(blisterType => {
            return <Select.Option key={blisterType.id} value={blisterType.id}>{blisterType.name}</Select.Option>
        })}
    </Select>
}

export default BlisterTypeSelect;