import React from 'react'
import DokumentListe from '../organisms/DokumentListe';

export const Dokumente = props => {

    return <div>
        <DokumentListe />
    </div>

}
