import React, {useContext, useEffect, useState} from "react";
import {Loading} from "../organisms/StatusDisplay";
import DokumentListeTabelle from "./DokumentListeTabelle";
import useDateiVorschau from "../../utilities/useDateiVorschau";
import {useFetch} from "../../utilities/useFetch";
import {dokumenteApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import AdressbuchContext from "../../contexts/AdressbuchContext";

const AdressbuchDokumente = ({}) => {
    const auth = useAuth();
    const adressbuchContext = useContext(AdressbuchContext);

    const {openDokument} = useDateiVorschau();
    const [dokumentListe, setDokumentListe] = useState(null);
    const {data: verknuepfungen, reload: reloadDokumente} = useFetch(dokumenteApi.getVerknuepfungenByUnit(adressbuchContext.akteur?.id));

    useEffect(() => {
        if (verknuepfungen?.length >= 0) {
            if (verknuepfungen?.length > 0) (async () => {
                const dokumentIdList = verknuepfungen.map(v => v.dokument.id);
                const dokumentListeNeu = (await callApiAsync({url: dokumenteApi.getByIdList(dokumentIdList), method: "POST", data: dokumentIdList, auth})).data.OBJECT;
                setDokumentListe(dokumentListeNeu);
            })()

            else setDokumentListe([]);
        }
    }, [verknuepfungen])

    const unit = adressbuchContext.akteur;

    return <>
        <h6 style={{marginTop: 20}}>Dokumente</h6>
        {dokumentListe === null ? <Loading /> : dokumentListe?.length ?
            <DokumentListeTabelle
                dokumente={dokumentListe}
                onDelete={reloadDokumente}
                onUpdate={reloadDokumente}
                onUpload={reloadDokumente}

                bearbeitetStatusFuer={[unit?.id]}
                gueltigkeitFuer={[unit?.id]}

                scroll={{ x: "max-content" }}
            /> : "[Keine Dokumente gefunden]"}
    </>
}

export default AdressbuchDokumente;