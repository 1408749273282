import {createContext, useState} from "react";

const initVal = {
    ueberschriftenInt: null,
    setUeberschriftenInt: val => {},

    ueberschriftenItx: null,
    setUeberschriftenItx: val => {},

    showModal: false,
    setShowModal: val => {},

    interaktionen: null,
    setInteraktionen: val => {},

    notizenMap: {},
    setNotizenMap: val => {},

    interaktionenTextMap: null,
    setInteraktionenTextMap: val => {},

    hashesLoading: false,
    setHashesLoading: val => {},
}

const InteraktionenContext = createContext(initVal);
export default InteraktionenContext;

export const useInitialState = () => {
    const [ueberschriftenInt, setUeberschriftenInt] = useState(null);
    const [ueberschriftenItx, setUeberschriftenItx] = useState(null);
    const [showModal, setShowModal] = useState(null);
    const [interaktionen, setInteraktionen] = useState(null);
    const [interaktionenTextMap, setInteraktionenTextMap] = useState(null);
    const [hashesLoading, setHashesLoading] = useState(false);
    const [notizenMap, setNotizenMap] = useState({});

    return {
        ueberschriftenInt,
        setUeberschriftenInt,

        ueberschriftenItx,
        setUeberschriftenItx,

        showModal,
        setShowModal,

        interaktionen,
        setInteraktionen,

        notizenMap,
        setNotizenMap,

        interaktionenTextMap,
        setInteraktionenTextMap,

        hashesLoading,
        setHashesLoading,
    }
}