import React, {useContext, useEffect, useState} from "react";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import useApiZeitraum, {getCurrentZeitraumColor} from "../../utilities/useApiZeitraum";
import {Affix} from "antd";
import {getUnitLangname} from "../../config/entities";
import moment from "moment";
import {GlobalContext} from "../../config/globalContext";

export const UnitNametag = ({ unit, hasMedikationsplan=false, top=215, display=true }) => {
    const globalContext = useContext(GlobalContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const adressbuchContext = useContext(AdressbuchContext);
    const zeitraumApi = useApiZeitraum();

    const [bgColor, setBgColor] = useState("#51B588");

    const anzeigeDatum = hasMedikationsplan ? medikationsplanContext.anzeigeDatum : globalContext.heute;

    useEffect(() => {
        if (!unit.active) {
            setBgColor("#e22929");
        } else {
            zeitraumApi.getZeitraumMapByUnit(unit.id).then(zeitraumMap => {
                const color = getCurrentZeitraumColor(zeitraumMap, 1, anzeigeDatum);
                setBgColor(color);
            });
        }
    }, [anzeigeDatum, zeitraumApi.unitIdToZeitraumMapMap[unit.id]])

    return (
        <Affix offsetTop={100} style={{width: "max-content", verticalAlign: "middle", position: "absolute", top, ...(display ? {} : {display: "none"})}}>
                <span style={{ fontWeight: "bold", fontSize: "16pt", border: "2px solid #5089B1", backgroundColor: bgColor, color: "#fff", borderRadius: "10px", padding: "0px 10px", marginBottom: 10, whiteSpace: "nowrap" }}>
                    {getUnitLangname(unit)}
                    {!!unit.dateOfBirth && <span style={{ fontSize: "12px", marginLeft: "20px" }}>
                        * {moment(unit.dateOfBirth).format("DD.MM.YYYY")} ({moment().diff(unit.dateOfBirth, 'years')})
                    </span>}

                    {hasMedikationsplan && medikationsplanContext.medikationsplan && !medikationsplanContext.medikationsplan.active && <span style={{position: "absolute", marginLeft: 100, marginTop: -10, padding: 10, fontWeight: "bold", color: "#e22929", backgroundColor: "rgba(240,242,245,.7)"}}>Medikationsplan ist inaktiv!</span>}
                </span>
        </Affix>
    )
}