import React, {useContext, useEffect, useRef, useState} from 'react'

import {maintenanceApi} from '../../config/apiConfig'
import DokumentListeTabelle from '../molecules/DokumentListeTabelle';
import {Button} from "antd";
import {useAuth} from "../../utilities/useAuth";
import {callApiAsync} from "../../utilities/apiUtil";
import {useApi} from "../../utilities/useApi";
import DokumenteContext from "../../contexts/DokumenteContext";
import FaxempfangEintragenModal from "./FaxempfangEintragenModal";

const DokumentListe = () => {
    const auth = useAuth();
    const api = useApi();

    const dokumenteContext = useContext(DokumenteContext);
    const [dokumenteBearbeitet, setDokumenteBearbeitet] = useState([]);
    const [dokumenteListe, setDokumenteListe] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [faxempfangEintragen, setFaxempfangEintragen] = useState(false);

    const dokumenteBearbeitetIds = useRef(new Set());

    useEffect(() => {
        dokumenteContext.setDokumenteListeContext("neu");
    }, [])

    useEffect(() => {
        if (dokumenteContext.dokumenteListe.length + dokumenteBearbeitet.length + (isEditing ? 1 : 0) !== dokumenteListe.length) {
            let dokumenteListeNeu = [...dokumenteContext.dokumenteListe.filter(d => !dokumenteBearbeitetIds.current.has(d.id)), ...dokumenteBearbeitet].sort((a, b) => a.id - b.id);
            setDokumenteListe(dokumenteListeNeu);
        }
    }, [dokumenteContext.dokumenteListe, dokumenteBearbeitet, isEditing])

    useEffect(() => {
        if (dokumenteContext.dokumentUpdated) {
            const dokument = dokumenteContext.dokumentUpdated.neu;
            if (dokument && dokumenteBearbeitetIds.current.has(dokument.id)) {
                dokument.key = `${dokument.id}-b`;
                setDokumenteBearbeitet(prev => {
                    const neu = prev.filter(d => d.id !== dokument.id);
                    neu.push(dokument);
                    return neu;
                })

            }
        }
    }, [dokumenteContext.dokumentUpdated])

    const handleDokumenteImport = async () => {
        const response = await callApiAsync({auth, url: maintenanceApi.importNeueDokumente("default")});
        if (response.data.MESSAGE && response.data.MESSAGE !== "no new documents to import.") {
            api.loadDokumenteNeu();
        }
    }

    return <>
        <FaxempfangEintragenModal visible={faxempfangEintragen} onVisibleChange={visible => setFaxempfangEintragen(visible)} />

        <DokumentListeTabelle
            setFilter={neu => api.setDokumenteNeuFilter({...neu, unverknuepft: true})}
            filter={api.dokumenteNeuFilter}
            dokumente={dokumenteListe}

            onUpload={dokument => dokumenteContext.addDokumente(dokument)}
            onDelete={dokument => dokumenteContext.removeDokumentId(dokument.id)}
            onUpdate={dokument => {
                dokumenteContext.putDokument(dokument);

                dokumenteBearbeitetIds.current.add(dokument.id);
                setDokumenteBearbeitet(prev => {
                    const neu = [...prev.filter(d => d.id !== dokument.id)];
                    if (dokument.verknuepfungen.filter(v => v.typ === "PATIENT").length) {
                        dokument.key = dokument.id + "-b";
                        neu.push(dokument);
                    }

                    return neu;
                });
            }}
            setEdit={setIsEditing}
        />
        <Button onClick={handleDokumenteImport}>Neue Dokumente importieren</Button>
        <Button onClick={() => setFaxempfangEintragen(true)}>Faxempfang eintragen</Button>
    </>

};

export default DokumentListe;