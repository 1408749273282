import React, {useContext, useEffect, useState} from 'react'

import { Button } from 'antd';

import { usePost } from './../../utilities/usePost';

import DokumenteContext from "../../contexts/DokumenteContext";
import {postInfomarker} from "../../config/postApiConfiguration";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";


export const InfomarkerButton = ({ infoMarker, planzeileId, dosierabschnittId, name, index }) => {

    const [body, setBody] = useState(null);

    const dokumenteContext = useContext(DokumenteContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    useEffect(() => {
        const postBody = getPostBody(infoMarker, planzeileId, dosierabschnittId, medikationsplanContext.isStandardAnsicht ? null : medikationsplanContext.ansicht);
        setBody(postBody);
    }, [infoMarker, planzeileId, dosierabschnittId]);

    const highlightInfomarker = () => {
        localStorage.setItem("highlightInfoMarker", JSON.stringify({...infoMarker, index, type:"neu"}))
    };

    const onSuccess = (data) => {
        dokumenteContext.removeInfoMarkerIndex(index);
        dokumenteContext.setZuletztGespeicherterInfoMarker(data);
    };

    const postInfoMarker = usePost(postInfomarker, body, onSuccess);

    useEffect(() => {
        if (body) postInfoMarker.handleSend();
    }, [body])

    if (!infoMarker || !infoMarker.marker || !infoMarker.marker.length || !planzeileId)
        return <></>;

    return <div>
        {name}:
        <Button onClick={() => highlightInfomarker()}>anzeigen</Button>
        <Button onClick={() => postInfoMarker.handleSend()}>verknüpfen</Button>
        {/* {JSON.stringify(infoMarker)} */}
    </div>
};

export const getPostBody = (im, zeileId, abschnittId, ansicht) => {
    const postBody = {
        ...im,

        "planzeile": { "id": zeileId },
        ansicht
    }

    if (abschnittId) postBody.dosierabschnittSet = [...(postBody.dosierabschnittSet || []), { "id": abschnittId }];
    return postBody;
};