import React from 'react'

import { useAuth } from '../../utilities/useAuth'

export const Benutzer = props => {
    const auth = useAuth()
    return <div>
        <h1>Benutzer Einstellungen</h1>
        <p>Willkommen, {auth.langname}</p>
        <p>Ihr Zugriffslevel: {auth.role}</p>
        <h2>todo</h2>
        <ul>
            <li>Passwort ändern</li>
            <li>Name ändern</li>
        </ul>

    </div>

}