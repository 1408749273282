import React, {useEffect, useRef, useState} from 'react'

import { DatePicker, Select, Input, TimePicker } from 'antd';

import moment from 'moment';
import 'moment/locale/de'
import { EINNAHMEZEITEN_UHRZEITEN } from '../../config/misc';
moment.locale('de');

export const InputUhrzeit = ({ value, label, onChange, format="HH:mm:ss", inputFormat="HH:mm" }) => {

    const inputValue = useRef(moment(value, format));

    useEffect(() => {
        inputValue.current = moment(value, format);
    }, [value])

    return (<TimePicker
        format={inputFormat}
        onChange={e => {
            if (e) {
                inputValue.current = e;
            } else {
                inputValue.current = moment().startOf('day');
            }
        }}
        onOpenChange={e => {
            if (!e) {
                onChange(inputValue.current.format(format), label);
            }
        }}
        onSelect={e => inputValue.current = e}
        value={inputValue.current}
    />)


    // const { Option } = Select;
    // return <Select
    //     defaultValue={text}
    //     style={{ width: 120 }}
    //     onChange={e => onChange(e, label)}>

    //     {EINNAHMEZEITEN_UHRZEITEN.map((z, i) =>
    //         <Option text={z.exportValue}>{z.bezeichnung}</Option>
    //     )}

    // </Select>

}
