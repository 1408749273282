import React, {useContext, useEffect, useState} from "react";
import {medikationsplanApi} from "../../config/apiConfig";
import {Button, DatePicker, Select, Table} from "antd";
import moment from "moment";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import useApiZeitraum from "../../utilities/useApiZeitraum";

export const MedikationsplanZeitraeume = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const zeitraumApi = useApiZeitraum();

    const [zeitraumEdit, setZeitraumEdit] = useState({});
    const [zeitraumNeu, setZeitraumNeu] = useState(null);
    const [zeitraumEditIndex, setZeitraumEditIndex] = useState(-1);

    useEffect(() => {
        if (medikationsplanContext.medikationsplan) {
            setZeitraumNeu({
                unitId: medikationsplanContext.medikationsplan.patient.id,
                typ: "B",
                start: moment().startOf('day').valueOf()
            });
        }
    }, [medikationsplanContext.medikationsplan])

    const deleteZeitraum = async (zeitraum, changeList=true) => {
        if (zeitraum == null) return;

        medikationsplanContext.setZeitraeumeLoaded(false);
        await callApiAsync({url: medikationsplanApi.zeitraumApi(), method: "DELETE", data: zeitraum, auth});
        await zeitraumApi.updateZeitraumMap(null, zeitraum);
    }

    const postZeitraum = async (zeitraumAlt, editObject) => {
        if (editObject && Object.keys(editObject).length) {
            let zeitraumNeu = {...zeitraumAlt};

            outer: for (let key in editObject) {
                switch (key) {
                    case "unitId":
                    case "typ":
                        await deleteZeitraum(zeitraumAlt, false);
                        zeitraumNeu.id = {...zeitraumNeu.id, [key]: editObject[key]};
                        break;
                    case "start":
                        await deleteZeitraum(zeitraumAlt, false);

                        if (!editObject[key]) {
                            zeitraumNeu = null;
                            break outer;
                        } else {
                            zeitraumNeu.id = {...zeitraumNeu.id, start: editObject[key]};
                            if (zeitraumAlt && moment(editObject[key]).isAfter(zeitraumAlt.ende)) zeitraumNeu.ende = null;
                        }
                        break;
                    case "ende":
                        if (editObject[key] && zeitraumAlt && moment(editObject[key]).isBefore(zeitraumAlt.id.start)) return;
                        zeitraumNeu.ende = editObject[key];
                        break;
                }
            }

            if (zeitraumNeu) {
                medikationsplanContext.setZeitraeumeLoaded(false);
                const response2 = await callApiAsync({
                    url: medikationsplanApi.zeitraumApi(),
                    method: "POST",
                    data: zeitraumNeu,
                    auth
                });

                await zeitraumApi.updateZeitraumMap(response2.data.OBJECT, zeitraumAlt);
            }
        }

        setZeitraumEditIndex(-1);
        setZeitraumEdit({});
    }

    const columns = [
        {
            title: 'Start',
            key: 'start',
            width: 150,
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <DatePicker
                        defaultValue={moment(zeitraum.id.start)}
                        onChange={value => setZeitraumEdit(prev => ({...prev, start: value.startOf('day').valueOf()}))}
                        format={"DD.MM.YYYY"}
                        allowClear={false}
                    />
                } else {
                    return moment(zeitraum.id.start).format("DD.MM.YYYY")
                }
            },
        },
        {
            title: 'Ende',
            key: 'ende',
            width: 150,
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <DatePicker
                        defaultValue={zeitraum.ende ? moment(zeitraum.ende) : null}
                        onChange={value => setZeitraumEdit(prev => ({...prev, ende: value ? value.endOf('day').valueOf() : null}))}
                        format={"DD.MM.YYYY"}
                    />
                } else {
                    return zeitraum.ende ? moment(zeitraum.ende).format("DD.MM.YYYY") : "-"
                }
            },
        },
        {
            title: 'Typ',
            key: 'typ',
            width: 200,
            render: (zeitraum, _, index) => ZeitraumTyp[zeitraum.id.typ].label
        },
        {
            title: 'Aktion',
            key: 'aktion',
            render: (zeitraum, _, index) => {
                if (index === zeitraumEditIndex) {
                    return <Button size={"small"} onClick={() => postZeitraum(zeitraum, zeitraumEdit)}>speichern</Button>
                } else {
                    return <>
                        <Button size={"small"} onClick={() => { setZeitraumEdit({}); setZeitraumEditIndex(index) }}>bearbeiten</Button>
                        <Button size={"small"} onClick={() => deleteZeitraum(zeitraum)}>löschen</Button>
                    </>
                }
            }
        }
    ];

    return <>
        <h3>Medikationsplanweite Zeiträume</h3>
        {!!zeitraumNeu && <div>
            <span style={{marginRight: 10}}>Neuer Zeitraum:</span>
            <span>Start: <DatePicker
                defaultValue={moment(zeitraumNeu.start)}
                onChange={value => setZeitraumNeu(prev => ({...prev, start: value.startOf('day').valueOf()}))}
                format={"DD.MM.YYYY"}
                allowClear={false}
            /></span>
            <span>Ende: <DatePicker
                defaultValue={zeitraumNeu.ende ? moment(zeitraumNeu.ende) : null}
                onChange={value => setZeitraumNeu(prev => ({...prev, ende: value ? value.endOf('day').valueOf() : null}))}
                format={"DD.MM.YYYY"}
            /></span>
            <span>Typ: <Select value={zeitraumNeu.typ} dropdownMatchSelectWidth={false} onChange={value => setZeitraumNeu(prev => ({...prev, typ: value}))}>
                    {Object.keys(getZeitraumTypMapForContext("medikationsplan")).map(typ => <Select.Option value={typ} key={typ}>{ZeitraumTyp[typ].label}</Select.Option>)}
                </Select></span>
            <Button onClick={() => postZeitraum(null, zeitraumNeu)}>speichern</Button>
        </div>}
        <Table
            rowKey={zeitraum => `${zeitraum.id.typ}_${zeitraum.id.start}`}
            style={{width: 800}}

            columns={columns}
            dataSource={medikationsplanContext.zeitraumList}
            pagination={false}
            loading={!medikationsplanContext.zeitraeumeLoaded}
        />
    </>
}
export default MedikationsplanZeitraeume;

export const ZeitraumTyp = {
    B: {
        label: "Verblisterung",
        sortOrder: 0,
        useForContext: ["medikationsplan"]
    },
    P: {
        label: "Allgemeine Pause",
        sortOrder: 1,
        useForContext: ["medikationsplan"]
    },
    K: {
        label: "Krankenhaus",
        sortOrder: 2,
        useForContext: ["medikationsplan"]
    },
    U: {
        label: "Urlaub",
        sortOrder: 3,
        useForContext: ["adressbuchArzt"]
    }
}

export const getZeitraumTypMapForContext = (context) => {
    const map = {};
    for (let typ in ZeitraumTyp) {
        if (ZeitraumTyp[typ].useForContext.includes(context)) {
            map[typ] = ZeitraumTyp[typ];
        }
    }

    return map;
}