import React, {useEffect, useRef, useState} from 'react'

import {Input} from 'antd';
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {ConditionalPopover} from "./ConditionalPopover";
import {Loading} from "../templates/defaultPages";

export const InputMenge = ({ value, label, onChange, disabled=false, faktorMenge, styleClass=[], einheit, suffix, loading=false, faktorLabel }) => {
    const getValue = (valueString) => {
        if (!valueString) valueString = "0";

        const split = valueString.split(/[,.]/);
        let newVal = split.length === 1 ? split[0] : `${split.splice(0, 1)[0]}.${split.join("")}`;
        if (newVal.substring(newVal.length-1) === ".") {
            setKommaEntfernt(true);
            newVal = newVal.substring(0, newVal.length-1);
        } else {
            setKommaEntfernt(false);
        }

        if (!isNaN(newVal)) {
            // Drittel oder Sechstel?
            const indexKomma = newVal.indexOf(".");
            if (indexKomma > -1) {
                const ersteNachkommaStelle = newVal[indexKomma+1];
                switch (ersteNachkommaStelle) {
                    case "3":
                        newVal = Math.trunc(newVal) + 1/3;
                        break;
                    case "6":
                        newVal = Math.trunc(newVal) + 2/3;
                        break;
                }
            }

            newVal /= faktorMenge;
            newVal = Math.trunc(newVal * 100000) / 100000;
        } else {
            newVal = 0;
        }

        return newVal +"";
    }

    const setValueString = value => {
        setInputValue((value * faktorMenge +"").replace(".", ","));
    }

    const handleChange = () => {
        const newVal = getValue(inputValue);
        onChange(newVal, label)
        setValueString(newVal);
    }

    const [kommaEntfernt, setKommaEntfernt] = useState(false);
    const [inputStyleClass, setInputStyleClass] = useState("");

    const [inputValue, setInputValue] = useState("");
    const [focused, setFocused] = useState(false);

    const inputRef = useRef();

    useEffect(() => {
        if (focused) {
            inputRef.current.select();
        }
    }, [focused])

    useEffect(() => {
        setValueString(value);
    }, [value])

    useEffect(() => {
        let classString = !value || value === "0" || value === "" ? "input-menge input-menge-leer" : "input-menge";
        if (styleClass.length) classString += " " + styleClass.join(" ");
        if (disabled) classString += " disabled";

        setInputStyleClass(classString);
    }, [styleClass, disabled])

    let popoverFaktorLabel = '';
    if (faktorLabel) {
        popoverFaktorLabel = ' zu '+faktorLabel()
    }

    const popoverContent = (
        <table>
            <tbody>
                <tr>
                    <th colSpan={2}>Ein Umrechnungsfaktor wird genutzt{popoverFaktorLabel}</th>
                </tr>
                <tr>
                    <td>Faktor:</td>
                    <td>{mengeToString(faktorMenge)}</td>
                </tr>
                <tr>
                    <td>Original-Menge:</td>
                    <td>{mengeToString(value)}</td>
                </tr>
            </tbody>
        </table>
    )

    return <span>
        <ConditionalPopover content={!focused && faktorMenge !== 1 && inputValue !== "0" && popoverContent}>
            <Input
                ref={inputRef}

                disabled={disabled || loading}
                value={inputValue + (!focused && suffix?.length ? suffix : "")}
                onChange={e => setInputValue(e.target.value)}
                className={inputStyleClass}

                onFocus={e => {
                    setFocused(true);
                }}
                onBlur={e => {
                    setFocused(false);
                    handleChange();
                }}
                onPressEnter={e => e.target.blur()}

                addonAfter={loading ? <Loading size={'sm'} /> : einheit}
            />
        </ConditionalPopover>
    </span>

}
