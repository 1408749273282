import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDataAccess} from './../../utilities/useDataAccess';
import {Fallback} from '../../config/fallbackPages';
import {akteureApi} from '../../config/apiConfig'
import {AdressbuchUnit} from '../molecules/AdressbuchUnit';
import {AdressbuchPerson} from '../molecules/AdressbuchPerson';
import {AdressbuchInstitution} from '../molecules/AdressbuchInsitution';
import {AdressbuchMediplaene} from '../molecules/AdressbuchMediplaene';
import {Tabs, Popconfirm, Button, Dropdown, Menu, notification, Modal} from 'antd';
import {defaultAkteur, getUnitNameDateOfBirthConcat} from '../../config/entities';
import {useAuth} from '../../utilities/useAuth';
import {AdressbuchAdressen} from '../molecules/AdressbuchAdressen';
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import UserDeleteOutlined from "@ant-design/icons/lib/icons/UserDeleteOutlined";
import {IdcardOutlined, SettingOutlined} from "@ant-design/icons";
import UnorderedListOutlined from "@ant-design/icons/lib/icons/UnorderedListOutlined";
import {AdressbuchSonstiges} from "../molecules/AdressbuchSonstiges";
import queryString from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {useApi} from "../../utilities/useApi";
import ArztZeitraeume from "./ArztZeitraeume";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import {callApiAsync} from "../../utilities/apiUtil";
import AdressbuchDokumente from "../molecules/AdressbuchDokumente";
import AdressbuchNotizen from "./AdressbuchNotizen";
import {UnitNametag} from "../molecules/UnitNameTag";
import {MessageModal} from "./MessageModal";

const {TabPane} = Tabs;

const AdressbuchDetail = ({ tabKey="tab", tab:tabProp, onTabChange, akteurId }) => {
    const auth = useAuth();
    const history = useHistory();
    const api = useApi();
    const adressbuchContext = useContext(AdressbuchContext);
    const location = useLocation();

    const medikationsplanContext = useContext(MedikationsplanContext);
    const akteur = useDataAccess({value: api.akteure[api.akteureIdToIndexMap[akteurId]], id: akteurId !== 'neu' ? akteurId : null}, akteureApi);

    const [showMedikationsplan, setShowMedikationsplan] = useState(false);
    const [tab, setTab] = useState(tabProp);
    const [type, setType] = useState(null);
    const [queryObj, setQueryObj] = useState({});

    const saveTimeout = useRef(null);
    const messageModalRef = useRef(null);

    useEffect(() => {
        if (akteur.updated) {
            const bla = 'blubbb';
        }
    }, [akteur.updated])

    useEffect(() => {
        medikationsplanContext.setPatientStammdatenLoaded(true);
        return () => medikationsplanContext.setPatientStammdatenLoaded(false);
    }, [])

    useEffect(() => {
        setQueryObj(queryString.parse(location.search));

        if (akteurId === "neu") {
            const akteurNeu = createAkteur(queryString.type || 'patient');
            adressbuchContext.setNeuType(akteurNeu);
        }
    }, [location.hash])

    useEffect(() => {
        if (akteurId === "neu") {
            if (adressbuchContext.neuType) {
                akteur.handleChange(null, adressbuchContext.neuType);
                setShowMedikationsplan(false);

                adressbuchContext.setAkteur(adressbuchContext.neuType);
                adressbuchContext.setNeuType(null);
            }
        } else if (akteur?.current?.id+"" === akteurId) {
            if ((akteur.current.roles || []).find(role => role.name === "Patient")) {
                setShowMedikationsplan(true);
            } else {
                setShowMedikationsplan(false);
            }

            if (akteur.current.roles?.length) {
                const role = akteur.current.roles.find(role => ["Patient", "Arztpraxis"].includes(role.name));
                if (role) {
                    setType(role.name);
                }
            }
        } else {
            api.loadAkteur(akteurId).then(adressbuchContext.setAkteur);
        }

        if (saveTimeout.current) clearTimeout(saveTimeout.current);
        if (akteur.current?.performSave) {
            performSave();
        } else if (akteur.current?.checkAkteurNameDateOfBirthConcat) {
            checkAkteurNameDateOfBirthConcat();
        } else if (akteur.updated) {
            saveTimeout.current = setTimeout(() => akteur.handleChange('checkAkteurNameDateOfBirthConcat', true), 1000);
        }
    }, [akteur?.current, akteurId])

    useEffect(() => {
        onTabChange(tab);
    }, [tab])

    useEffect(() => {
        setTab(tabProp);
    }, [tabProp])

    useEffect(() => {
        if (medikationsplanContext.changePatient?.length) {
            for (let change of medikationsplanContext.changePatient) {
                akteur.handleChange(change[0], change[1], change[2] || "replace");
            }
        }
    }, [medikationsplanContext.changePatient])



    const checkAkteurNameDateOfBirthConcat = () => {
        // Prüfe nameDateOfBirthConcat, wenn type = Person
        if (akteur.current.type === 'Person' && akteur.current.nameDateOfBirthConcat !== getUnitNameDateOfBirthConcat(akteur.current)) {
            const unitNameDateOfBirthConcat = getUnitNameDateOfBirthConcat(akteur.current);
            callApiAsync({auth, url: akteureApi.getByNameDateOfBirthConcat(unitNameDateOfBirthConcat)}).then(response => {
                if (response.data.OBJECT.length) {
                    const notificationObj = {message: "Es existiert bereits ein Eintrag für diese Daten. Der Speichervorgang wird abgebrochen."};
                    if (response.data.OBJECT.length === 1) {
                        const eintrag = response.data.OBJECT[0];
                        if (eintrag?.id) {
                            notificationObj.btn = <Button type="primary" onClick={() => history.replace("/adressbuch/" + eintrag.id)}>Eintrag anzeigen</Button>
                        }
                    }

                    notification.error(notificationObj);
                } else {
                    akteur.handleChange('nameDateOfBirthConcat', unitNameDateOfBirthConcat);
                }
            });
        } else {
            akteur.handleChange('performSave', true);
        }
    };

    const performSave = async () => {
        const akteurNeu = await akteur.handleSave();

        await api.updateAkteur(akteurNeu);
        adressbuchContext.setAkteur(akteurNeu);
        if (akteurNeu.id && history.location.pathname === "/adressbuch/neu") {
            history.replace("/adressbuch/"+akteurNeu.id);
        }

        if (medikationsplanContext.medikationsplan && medikationsplanContext.medikationsplan.patient.id === akteurNeu.id) {
            medikationsplanContext.setUpdatePatient(akteurNeu);
        }
    };

    if (!akteur.isReady)
        return Fallback(akteur,
            <h6>Eintrag wird geladen</h6>
        );

    return <>
        <MessageModal ref={messageModalRef} akteur={akteur.current} />
        <UnitNametag unit={akteur.current} hasMedikationsplan={showMedikationsplan} top={(tab || adressbuchContext.tab) === "medikationsplan" ? 215 : 160} />

        <Tabs

            onChange={setTab}
            activeKey={tab || adressbuchContext.tab || "stammdaten"}

            tabBarExtraContent={
                <div>
                    <Button onClick={messageModalRef.current?.createMessage}>+Nachricht</Button>
                    <Dropdown overlay={
                        <Menu onClick={e => {
                            adressbuchContext.setNeuType(createAkteur(e.key));
                            history.replace("/adressbuch/neu?type="+e.key);
                        }}>
                            <Menu.Item key="personOhneRolle"><UserOutlined /> Person</Menu.Item>
                            <Menu.Item key="einrichtungOhneRolle"><HomeOutlined /> Institution</Menu.Item>
                        </Menu>
                    }>
                        <Button><UserAddOutlined />Neu: <DownOutlined /></Button>
                    </Dropdown>

                    {!akteur.updated ?
                        <Button type="dashed" disabled><SaveOutlined />speichern</Button>

                        :

                        <Button onClick={() => {
                            akteur.handleChange('checkAkteurNameDateOfBirthConcat', true)
                        }} type="primary"><SaveOutlined />speichern</Button>
                    }

                    {akteur.updated &&
                        <Button onClick={() => akteur.handleReload()}><ReloadOutlined />reload</Button>
                    }
                    {!akteur.updated &&
                        <Button type="dashed" disabled><ReloadOutlined />reload</Button>
                    }

                    <Popconfirm
                        placement="bottom"
                        title={"Sind Sie sicher, dass der Adressbuch-Eintrag gelöscht werden soll?"}
                        onConfirm={() => {
                            deleteAkteur(auth, akteur.current.id)
                        }}
                        okText="Ja, löschen"
                        cancelText="Nein"
                    >
                        <Button type="danger"><UserDeleteOutlined />löschen</Button>
                    </Popconfirm>

                </div>
            }>



            {(akteur.current.type === 'Person') &&
                <TabPane tab={<span><UserOutlined />Person</span>} key="stammdaten" style={{paddingTop: 40}}>
                    {!akteur.current.active && <h3 style={{color: "red"}}>Eintrag ist inaktiv! <Button onClick={() => akteur.handleChangeBatch([{name: "active", payload: true}, {name: 'checkAkteurNameDateOfBirthConcat', payload: true}])}>reaktivieren</Button></h3>}
                    <AdressbuchPerson
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>
            }

            {(akteur.current.type === 'Institution') &&
                <TabPane tab={<span><UserOutlined />Institution</span>} key="stammdaten" style={{paddingTop: 40}}>
                    {!akteur.current.active && <h3 style={{color: "red"}}>Eintrag ist inaktiv!</h3>}
                    <AdressbuchInstitution
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>
            }

            {!!akteur.current.id && <>
                <TabPane tab={<span><IdcardOutlined />Adressen</span>} key="adressen" style={{paddingTop: 40}}>
                    <AdressbuchAdressen
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>

                <TabPane tab={<span><SettingOutlined />Rollen und Rechte</span>} key="rollen" style={{paddingTop: 40}}>
                    <AdressbuchUnit
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>

                {type === "Arztpraxis" && <TabPane tab={<span><SettingOutlined />Zeiträume</span>} key={"zeitraeume"} style={{paddingTop: 40}}>
                    <ArztZeitraeume
                        unit={akteur.current}
                    />
                </TabPane>}


                {showMedikationsplan && <TabPane tab={<span><UnorderedListOutlined />Medikationsplan</span>} key="medikationsplan">
                    <AdressbuchMediplaene
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>}


                <TabPane tab={<span><UnorderedListOutlined />Dokumente</span>} key="dokumente" style={{paddingTop: 40}}>
                    <AdressbuchDokumente />
                </TabPane>


                <TabPane tab={<span><UnorderedListOutlined />Notizen</span>} key="notizen" style={{paddingTop: 40}}>
                    <AdressbuchNotizen />
                </TabPane>


                <TabPane tab={<span><UnorderedListOutlined />Sonstiges</span>} key="sonstiges" style={{paddingTop: 40}}>
                    <AdressbuchSonstiges
                        value={akteur.current}
                        onChange={e => akteur.handleChange(null, e)}
                    />
                </TabPane>
            </>}

        </Tabs>
    </>
};

export default AdressbuchDetail;

export const createAkteur = (type = 'personOhneRolle', props={}) => {
    const newValue = {...defaultAkteur(type), ...props};
    return newValue;
};

export const deleteAkteur = async (auth, id = null) => {

    if (!id) return;
    console.info("Lösche Akteur mit ID " + id);

    const response = await callApiAsync({auth, url: akteureApi.delete(id), data: {}, method: "delete"});
    if (response.data.RESULT === 'SUCCESS') {
        window.location.href = '/adressbuch';
    } else {
        console.error(JSON.stringify(response));
    }
};

export const TAB_KEYS = [
    "adressen",
    "rollen",
    "zeitraeume",
    "medikationsplan",
    "dokumente",
    "notizen",
    "sonstiges",
]