import {createContext, useContext, useEffect, useRef, useState} from "react";
import MedikationsplanContext from "./MedikationsplanContext";

const initVal = {
    ref: {},
    notiz: {},
    setNotiz: val=>{},

    notizMap: {},
    setNotizMap: val=>{},

    notizSavedLatest: null,
    setNotizSavedLatest: val => {},

    visible: false,
    setVisible: (visibleState)=>{},

    akteur: {},
    setAkteur: val=>{},

    planzeilenVerfuegbar: {},
    setPlanzeilenVerfuegbar: val=>{},

    planzeilenAusgewaehlt: [],
    setPlanzeileAusgewaehlt: val=>{},
    addPlanzeileAusgewaehlt: val=>{},
    removePlanzeileAusgewaehlt: val=>{},
}

const NotizContext = createContext(initVal)
export default NotizContext;

export const useInitialState = () => {
    const [notiz, setNotiz] = useState({});
    const [notizMap, setNotizMap] = useState({});
    const [visible, setVisible] = useState(false);
    const [akteur, setAkteur] = useState(null);
    const [planzeilenVerfuegbar, setPlanzeilenVerfuegbar] = useState({});
    const [planzeilenAusgewaehlt, setPlanzeilenAusgewaehlt] = useState([]);

    const [notizSavedLatest, setNotizSavedLatest] = useState(null);

    const settingMedikationsplan = useRef(false);
    const gettingPlanzeilenForPatient = useRef(null);

    const setPlanzeileAusgewaehlt = planzeileId => {
        setPlanzeilenAusgewaehlt(planzeileId ? [""+planzeileId] : []);
    }

    const addPlanzeileAusgewaehlt = planzeileId => {
        if (planzeileId) {
            planzeileId = ""+planzeileId;
            setPlanzeilenAusgewaehlt(prev => {
                const neu = prev.filter(i => i !== planzeileId);
                neu.push(planzeileId)
                return neu;
            });
        }
    }

    const removePlanzeileAusgewaehlt = planzeileId => {
        setPlanzeilenAusgewaehlt(prev => [...prev.filter(id => id !== ""+planzeileId)]);
    }

    return {
        ref: {
            settingMedikationsplan,
            gettingPlanzeilenForPatient,
        },

        notiz,
        setNotiz,

        notizMap,
        setNotizMap,

        notizSavedLatest,
        setNotizSavedLatest,

        visible,
        setVisible,

        akteur,
        setAkteur,

        planzeilenVerfuegbar,
        setPlanzeilenVerfuegbar,

        planzeilenAusgewaehlt,
        setPlanzeileAusgewaehlt,
        addPlanzeileAusgewaehlt,
        removePlanzeileAusgewaehlt,
    }
}