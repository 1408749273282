import {createContext, useRef, useState} from "react";

const initVal = {
    akteur: null,
    setAkteur: ()=>{},

    showMedikationsplan: {medikationsplanId:null, ansicht:null, planzeileSelected:null},
    setShowMedikationsplan: ()=>{},

    tab: null,
    setTab: ()=>{},

    contentParams: {},
    setContentParams: ()=>{},

    neuType: null,
    setNeuType: ()=>{},
}

const AdressbuchContext = createContext(initVal);
export default AdressbuchContext;

export const useInitialState = () => {
    const [akteur, setAkteur] = useState(null);
    const [showMedikationsplan, setShowMedikationsplan] = useState(null);
    const [tab, setTab] = useState(null);
    const [contentIdString, setContentIdString] = useState(null);
    const [contentParams, setContentParams] = useState({});
    const [neuType, setNeuType] = useState(null);

    return {
        akteur,
        setAkteur,

        showMedikationsplan,
        setShowMedikationsplan,

        tab,
        setTab,

        contentIdString,
        setContentIdString,

        contentParams,
        setContentParams,

        neuType,
        setNeuType,
    }
}