import React, {useCallback, useEffect, useRef, useState} from 'react'

import {useAuth} from './useAuth';

import {Error, Loading, Saving} from './../components/organisms/StatusDisplay';

import {callApiAsync, removeEmpty} from './apiUtil';
import {MedikationsplanBestand} from "../components/molecules/MedikationsplanBestand";


export const useDataNode = ({
    dataNode = null,
    key:propKey = null,
    isCollection: propIsCollection = false,

    value = null,
    onChange = null,

    // sorter für collections
    sorter = null,

    // cacheSlice = null,
    undo = false,

    idAttribute = "id",
    initialNewId = -1,
    stepNewId= (id) => id-1,

    skipInitialLoad = false,

    onDelete = null
}) => {


    const auth = useAuth();

    const [isCollection, setIsCollection] = useState(propIsCollection);

    const [init, setInit] = useState(false);
    const [current, setCurrentState] = useState(value);
    const [status, setStatus] = useState({ ready: !!value, loading: !value, saving: false });

    const [history, setHistory] = useState([]);

    const [updated, setUpdated] = useState(false);
    const [newId, setNewId] = useState(initialNewId);

    const [triggerSave, setTriggerSave] = useState(null);
    const [savedAfterTrigger, setSavedAfterTrigger] = useState(null);

    const [broadcastChange, setBroadcastChange] = useState(false);
    const [deletedResponseMap, setDeletedResponseMap] = useState({});


    /*********************************************
        Status
    *********************************************/
    const setReady = (e) => {
        setStatus({
            ...status,
            ready: e
        });
    };

    const setLoading = (e) => {
        setStatus({
            ...status,
            loading: e,
            ready: !e
        });
    };

    const setSaving = (e) => {
        setStatus({
            ...status,
            saving: e,
            ready: !e
        });
    };

    const setError = (e) => {
        // console.error(JSON.stringify(e))
        setStatus({ ...status, ready: false, error: e });
    };


    const setUpdatedIndex = (keyList, valuePresent) => {
        if (keyList && !Array.isArray(keyList)) keyList = [keyList];

        setUpdated(prev => {
            const neu = prev ? [...prev.filter(alt => !keyList.includes(alt))] : [];
            if (valuePresent) {
                if (Array.isArray(keyList)) keyList.forEach(key => neu.push(key));
                else neu.push(keyList);
            }

            return neu;
        });
    };


    /*********************************************
        Daten - Quelle per text ODER Api
    *********************************************/
    useEffect(() => {
        // Komponente hat bereits explizite Werte erhalten
        if (value !== null) {
            if (Array.isArray(value)) setIsCollection(true);
            setCurrent(value);
            return;
        }
        if (propKey !== null && !skipInitialLoad) {
            handleLoad();
        }
    }, [value, propKey]);


    /*********************************************
        trigger save
     *********************************************/
    useEffect(() => {
        if (!updated || !updated.length) return;
        if (triggerSave) {
            (async () => {
                if (triggerSave === true) setSavedAfterTrigger(await handleSave());
                else {
                    const savedEntries = [];
                    const ret = await Promise.all(triggerSave.map(async key => savedEntries.push(await handleSaveCollectionElement(key))));
                    setSavedAfterTrigger(savedEntries);
                }
            })();
        }
    }, [triggerSave, updated])

    useEffect(() => {
        if (broadcastChange) {
            setBroadcastChange(false);

            if (onChange) onChange(current);
        }
    }, [current, broadcastChange])






    const setCurrent = (newData) => {
        setCurrentState(prev => {
            let neu;

            if (newData && typeof newData === 'function') {
                neu = newData(prev);
            } else {
                neu = newData;
            }

            if (sorter && typeof sorter === 'function') {
                neu = neu.sort(sorter);
            }

            return neu;
        });
    }

    const handleChangeMultiple = (valueKeyList, debugMessage = null) => {
        if (debugMessage) {
            console.log("debugMessage", debugMessage);
            console.log("valueKeyList", valueKeyList);
        }

        pushHistory();

        handleReplace(valueKeyList);

        if (isCollection) {
            const keyList = valueKeyList.reduce((list, valueKeyPair) => {
                list.push(!Array.isArray(valueKeyPair) ? valueKeyPair[idAttribute] : valueKeyPair[1] ? valueKeyPair[1] : valueKeyPair[0][idAttribute]);
                return list;
            }, []);
            setUpdatedIndex(keyList, true);
        } else {
            setUpdated(true);
        }
    }

    const handleChangeNoTriggerUpdated = (newValue, key = null, debugMessage = null) => {
        handleChange(newValue, key, debugMessage, false);
    }

    const handleChange = (newValue, key = null, debugMessage = null, triggerUpdated=true) => {
        if (debugMessage) {
            console.log("debugMessage", debugMessage);
            console.log("newValue", newValue);
            console.log("key", key);
        }

        pushHistory();

        handleReplace([[newValue, key]], debugMessage);

        if (isCollection) {
            let keyList = null;
            if (!!key) {
                keyList = [key];
            } if (newValue && !!newValue[idAttribute]) {
                keyList = [newValue[idAttribute]];
            }

            if (triggerUpdated && !!keyList) setUpdatedIndex(keyList, true);
        } else if (triggerUpdated) {
            setUpdated(true);
        }
    }

    const handleReplace = (valueKeyList) => {
        let newState = current;
        if (isCollection) {
            setCurrent(prev => {
                newState = [...prev];
                const indexMap = newState.reduce((map, entry, index) => {
                    map[entry[idAttribute]] = index;
                    return map;
                }, {});

                for (let valueKeyPair of valueKeyList) {
                    const newValue = Array.isArray(valueKeyPair) ? valueKeyPair[0] : valueKeyPair;
                    const key = Array.isArray(valueKeyPair) && (valueKeyPair[1] || valueKeyPair[1] === 0) ? valueKeyPair[1] : newValue[idAttribute];
                    let entryFound = false;

                    const index = indexMap[key];
                    if (index >= 0) {
                        entryFound = true;
                        newState[index] = newValue;
                    }

                    if (newValue && !entryFound) {
                        newState.push(newValue);
                    }
                }

                return newState.filter(e => e !== null);
            });
        }

        else {
            setCurrent(prev => {
                // todo: klären, warum {...prev} nicht funktioniert
                // initialisiere neuen state
                newState = {};
                const keys = Object.keys(prev);
                for (let k of keys) newState[k] = prev[k];

                for (let valueKeyPair of valueKeyList) {
                    const newValue = valueKeyPair[0];

                    // Wenn das komplette Objekt ersetzt werden soll
                    if (valueKeyList.length === 1 && !valueKeyPair[1]) {
                        if (newValue !== null) {
                            newState = newValue;
                        } else {
                            setReady(false);
                            newState = null;
                        }

                        break;
                    }

                    const key = valueKeyPair[1];
                    newState[key] = newValue;
                }

                return newState;
            });
        }

        setBroadcastChange(true);
    };


    /**
     * falls Collection: füge newElement zu [...state] hinzu
     * sonst: ersetze {...state} durch newElement
     * @param {Object} newElement
     * @param addAtEnd Angabe, ob Element am Ende (true) oder am Anfang (false, default) eingefügt werden soll
     */
    const handleNew = ({newElement = null, addAtEnd=false, triggerSave=false, changeNewId=false, addProps={}, performOnThis=()=>{}}) => {

        pushHistory();

        let newState;

        if (isCollection) {
            if (!newElement && dataNode !== null && dataNode.defaultNew) {
                newElement = {...dataNode.defaultNew};
            }

            newElement[idAttribute] = newId;
            newElement = {...newElement, ...addProps};
            performOnThis(newElement);

            setCurrent(prev => {
                if (addAtEnd) newState = [...prev, newElement];
                else newState = [newElement, ...prev];

                return newState;
            })

            setUpdatedIndex(newId, true);
            if (changeNewId) setNewId(prev => stepNewId(prev));
        }

        else {
            newState = newElement;
            setCurrent(newState)

            setUpdated(true);
        }

        setBroadcastChange(true);

        if (triggerSave && newElement[idAttribute]) setTriggerSave([newElement[idAttribute]]);
        return newElement;
    };



    const handleLoad = async (skipLoadingCheck = false) => {

        setUpdated(isCollection ? [] : false);

        if (reloadFromCache(propKey)) {
            return;
        }

        if (!skipLoadingCheck && init && (status.loading || status.saving)) return;
        setInit(true)

        // lade bestimmtes Objekt per API
        if (!isCollection) {
            if (propKey !== null && dataNode && dataNode.api.getById) {
                // console.info("call API.getById")
                return await handleApiGet(dataNode.api.getById(propKey));
            }
        }

        // lade Collection per API
        else {
            if (propKey !== null && dataNode && dataNode.api.getByParent) {
                // console.info("call API.getByParent ")
                return await handleApiGet(dataNode.api.getByParent(propKey));
            }
        }

        return null;
    };



    const handleSaveCollectionElement = async (collectionKey = null) => {
        setTriggerSave(false);

        if (collectionKey) {
            const element = current.filter(e => e[idAttribute] === collectionKey)[0];

            if (element) {
                if (collectionKey > 0) {
                    return await handleApiPut(dataNode.api.put(collectionKey), element);
                } else {
                    return await handleApiPost(dataNode.api.postByParent(propKey), element);

                    // lösche alten Eintrag
                    setCurrent(prev => prev.filter(alt => alt[idAttribute] !== collectionKey));
                }
            }
        }

        return null;
    };


    const handleSave = async (specificKey) => {
        setTriggerSave(false);

        // durchlaufe Collection und speichere die geänderten Datensätze
        const deletedResponseMapNeu = {};
        let newElementArray = [];
        if (isCollection) {

            if (!updated || !updated.length) return;

            // iteriere durch aktualisierungen
            const ret = await Promise.all(updated.map(
                async (key) => {
                    if (specificKey && specificKey !== key) return false;

                    // console.log("update collection element with key ", index)
                    let foundElement = false;
                    const ret = await Promise.all(current.map(async (e, index) => {
                        if (e && e[idAttribute] === key) {

                            if (idAttribute !== "id" || key > 0) {
                                const data = await handleApiPut(dataNode.api.put(key), e);
                                if (data) newElementArray.push(data);
                            }

                            else {
                                const data = await handleApiPost(dataNode.api.postByParent(propKey), e);
                                if (data) newElementArray.push(data);

                                // lösche alten Eintrag
                                setCurrent(prev => prev.filter(alt => alt[idAttribute] !== key));
                            }

                            foundElement = true
                        }
                    }));

                    if (!foundElement && key && key !== -1) {
                        const response = await handleApiDelete(dataNode.api.delete(key));
                        if (response) {
                            deletedResponseMapNeu[key] = response;
                        }

                        setCurrent(prev => {
                            return prev.filter(alt => alt[idAttribute] !== key)
                        });
                    }

                }
            ));

            setDeletedResponseMap(deletedResponseMapNeu);
            setUpdated([]);
        }

        // speichere Objekt
        // todo post new object
        else {
            if (current[idAttribute]) {
                const data = await handleApiPut(dataNode.api.put(propKey), current);
                if (data) newElementArray.push(data);
            } else {
                const data = await handleApiPost(dataNode.api.post(), current);
                if (data) newElementArray.push(data);
            }

            setUpdated(false);
        }

        return newElementArray;
    };



    const handleDelete = (key) => {
        if (key) handleChange(null, key);
        else setCurrent(null, value[idAttribute]);
    };




    /*********************************************
        Caching
    *********************************************/

    const cachedCurrent = null;
    const getKeys = () => null;
    const reloadFromCache = () => false;



    /*********************************************
        API Zugriffe
    *********************************************/

    const handleApiGet = useCallback(async (url) => {

        setLoading(true);
        if (!url) return null;

        console.info("handleApiGet call at " + url);

        const response = await callApiAsync({auth, url})
            .then(response => {
                if (response.data.RESULT === 'SUCCESS') {
                    let newData = response.data.OBJECT;

                    setCurrent(newData);
                    setUpdated(false);
                    setLoading(false);
                }
                else {
                    setError(response);
                    console.error(response)
                }

                return response;
            })
            .catch(error => {
                setError(error);
                console.error(error)

                return error;
            })

        return response;
    }, [propKey]);


    const handleApiPut = async (url, data) => {

        if (!url) return null;

        removeEmpty(data);

        console.info("handleApiPut call at " + url);
        console.info("data", data);
        setSaving(true);

        try {
            const response = await callApiAsync({auth, url, method: 'put', data});
            if (response.data.RESULT === 'SUCCESS') {

                // Ein Element der Collection wurde gespeichert
                if (isCollection) {
                    setCurrent(prev => prev.map(alt => alt[idAttribute] === data[idAttribute] ? response.data.OBJECT : alt));
                    setUpdated(updated.filter(e => e !== data[idAttribute]));

                    setUpdatedIndex(data[idAttribute], false);
                    setSaving(false);
                }


                // Hauptobjekt wurde gespeichert
                else {
                    // updateCurrent(response.data.OBJECT);
                    // setUpdated(false);

                    // todo resetHistory()
                    setHistory([]);
                    setUndoAvailable(false);

                    setSaving(false);
                }

                return response.data.OBJECT;
            } else {
                setError(response);
                console.error(response);
            }
        } catch (error) {
            setError(error);
            console.error(error);
        }

        return null;
    };



    const handleApiPost = async (url, data) => {

        if (!url) return null;

        console.info("handleApiPost call at " + url);
        setSaving(true);

        try {
            const response = await callApiAsync({auth, url, data, method: 'post'});
            if (response.data.RESULT === 'SUCCESS') {
                // Ein neues Element wurde der Collection hinzugefügt
                if (isCollection) {
                    const neu = response.data.OBJECT;
                    setCurrent(prev => [...prev, neu]);

                    setUpdated(prev => prev.filter(e => e !== data[idAttribute]));
                    setSaving(false);
                }

                // Neues Hauptobjekt wurde gespeichert
                else {
                    // setCurrent(response.data.OBJECT);
                    // setUpdated(false);

                    // todo resetHistory()
                    setHistory([]);
                    setUndoAvailable(false);

                    setSaving(false);
                }

                return response.data.OBJECT;
            } else {
                setError(response);
                console.error(response);
            }
        } catch (error) {
            setError(error);
            console.error(error);
        }

        return null;
    };





    const handleApiDelete = async (url) => {

        if (!url) return null;

        console.info("handleApiDelete call at " + url);
        setSaving(true);

        let response = null;
        try {
            response = await callApiAsync({auth, url, method: 'delete'});
            if (isCollection) {
                setSaving(false);
            }

            else {
                setSaving(false);
            }
        } catch(error) {
            setError(error);
            console.error(error);
        }

        return response;
    };






    /*********************************************
        History
    *********************************************/
    const _MAX_HISTORY = 20;

    const [undoAvailable, setUndoAvailable] = useState(false);
    //const [historyIndex, setHistoryIndex] = useState(null)

    const pushHistory = () => {
        if (!undo || isCollection) return;

        // In History-Array alte Werte abschneiden, wenn maximale Grenze erreicht
        if (history.length > _MAX_HISTORY)
            setHistory([...history.splice(1, _MAX_HISTORY), { ...current }]);
        else
            setHistory([...history, { ...current }]);

        //setHistoryIndex(history.length)

        setUndoAvailable(true);
    };

    const handleUndo = () => {
        let newState = JSON.parse(JSON.stringify(history[history.length - 1]));
        const newHistory = history.splice(0, history.length - 1);

        setHistory(newHistory);

        if (newHistory.length === 0)
            setUndoAvailable(false);

        setCurrent(newState);
    };



    /*********************************************
        Return Objekt
    *********************************************/
    return {
        current,
        status,
        setLoading,
        setSaving,
        setReady,
        setError,
        chachedIdList: getKeys(),

        handleChange,
        handleChangeMultiple,
        handleChangeNoTriggerUpdated,
        handleSave,
        handleNew,
        handleDelete,
        handleLoad,

        handleSaveCollectionElement,

        handleUndo,
        undoAvailable,
        history,
        updated,

        cachedCurrent,

        setTriggerSave,
        savedAfterTrigger,
        deletedResponseMap,
    }

};

/*********************************************
 jsxComponent als Fallback
 Sonst Defaults (s.u.) für
 - Fehler
 - Loading (/Saving?)
 *********************************************/
export const StatusDisplay = ({ children, status, showLoading=true, showSaving=true, showError=false }) => {
    return status.error && showError ?
            <Error msg={status.error} /> :
            status.saving && showSaving ?
                <Saving status={status} /> :
                status.loading && showLoading ?
                    <Loading /> :
                    children ?
                        children :
                        <></>
};