import React, {useContext, useEffect, useRef, useState} from 'react'
import {ReichweiteBar} from './../atoms/ReichweiteBar';
import moment from 'moment';
import {Verordner} from './Verordner';
import {Button, Row, Col, Modal} from 'antd';

import {REICHWEITE_MINIMAL, REICHWEITE_PRUEFUNG} from "../../config/misc";
import {useFetch} from "../../utilities/useFetch";
import {fetchOffeneAnforderungenByPlanzeile} from "../../config/fetchApiConfiguration";
import {getUnitLangname} from "../../config/entities";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {anforderungApi, nachrichtApi} from "../../config/apiConfig";
import {getAnforderungVoransicht} from "../../config/postApiConfiguration";
import {ConditionalPopover} from "../atoms/ConditionalPopover";
import {nl2br} from "../../utilities/formatUtil";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {Loading} from "../templates/defaultPages";
import {useApi} from "../../utilities/useApi";
import {getZeitraumDelta} from "../../utilities/useApiZeitraum";
import {checkTeilbarkeit} from "../../utilities/medicineM2Util";

export const Reichweite = ({planzeile, hausarzt}) => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const api = useApi();

    const [quartalsendeAnforderung, setQuartalsEndeAnforderung] = useState(null);
    const [ignoreReichweite, setIgnoreReichweite] = useState(false);

    const [reichweiteElement, setReichweiteElement] = useState(<></>);

    const [buendel, setBuendel] = useState(null);

    const checkingZeitraum = useRef(false);

    // Aus Panzeile auslesen
    const reichweiteWochen = planzeile.datumRezeptBenoetigtBis ? moment(planzeile.datumRezeptBenoetigtBis).diff(moment(), "weeks") : null;

    useEffect(() => {
        if (planzeile && planzeile.id > 0) {
            callApiAsync({auth, url: anforderungApi.getDatumFuerAnforderung(planzeile.id)})
                .then(response => {
                    setQuartalsEndeAnforderung(moment(response.data.OBJECT));
                });
        }

        const json = JSON.parse(planzeile.json || "{}");
        if (json.ignoreReichweiteNull && !planzeile.reichweiteAnspruch) {
            setIgnoreReichweite(true);
        }
    }, [planzeile])

    useEffect(() => {
        setBuendel(medikationsplanContext.buendelMap[planzeile.buendelId]);
    }, [planzeile, medikationsplanContext.buendelMap])

    useEffect(() => {
        if (quartalsendeAnforderung) {
            const reichweite = planzeile.reichweiteAnspruch ? moment(planzeile.reichweiteAnspruch) : null;
            if (reichweite && reichweite.isAfter(quartalsendeAnforderung)) {
                if (quartalsendeAnforderung.diff(moment(), "weeks") < REICHWEITE_PRUEFUNG) {
                    callApiAsync({
                        auth,
                        url: anforderungApi.getDatumFuerAnforderung(planzeile.id, quartalsendeAnforderung)
                    })
                        .then(response => {
                            setQuartalsEndeAnforderung(moment(response.data.OBJECT));
                        });
                }
            }
        }
    }, [quartalsendeAnforderung])

    useEffect(() => {
        if (buendel && medikationsplanContext.medikationsplan) {
            if (!planzeile.datumRezeptBenoetigtBis) {
                if (planzeile.bedarfsmedikation) {
                    setReichweiteElement(<p><b>Bedarfsmedikation</b></p>);
                } else {
                    if (medikationsplanContext.planzeilenVerblistert[planzeile.id]) {
                        if (ignoreReichweite) {
                            if (!buendel?.entitlementSet?.length) {
                                setReichweiteElement(<p><b>Keine Ansprüche mit Planzeile verknüpft</b></p>);
                            } else {
                                setReichweiteElement(<></>);
                            }
                        } else {
                            if (buendel?.entitlementSet?.length) {
                                const last = medikationsplanContext.reihenfolgeMap[planzeile.id]?.verknuepft?.last;
                                if (last && !medikationsplanContext.dosierabschnittAktuell[planzeile.id]) {
                                    setReichweiteElement(<p><b>Planzeile ist Vorgängerplanzeile</b></p>);
                                } else {
                                    checkZeitraum();
                                }
                            } else {
                                setReichweiteElement(<p><b>Keine Ansprüche mit Planzeile verknüpft</b></p>);
                            }
                        }
                    } else {
                        setReichweiteElement(<p><b>Planzeile wird nicht verblistert</b></p>);
                    }
                }
            } else {
                if (reichweiteWochen >= REICHWEITE_PRUEFUNG || reichweiteWochen < 1) {
                    setReichweiteElement(<></>);
                } else if (reichweiteWochen === 1) {
                    setReichweiteElement(<p><b>Nur noch eine Woche!</b></p>);
                } else {
                    setReichweiteElement(
                        <p>{moment(planzeile.datumRezeptBenoetigtBis).format("DD.MM.YYYY")} ({reichweiteWochen} Wochen)</p>);
                }
            }
        }
    }, [buendel, ignoreReichweite, medikationsplanContext.planzeilenVerblistert, medikationsplanContext.medikationsplan])

    const checkZeitraum = async () => {
        if (checkingZeitraum.current) return;

        checkingZeitraum.current = true;

        const zeitraumMap = await api.getZeitraumMapByMedikationsplan(medikationsplanContext.medikationsplan.id);
        const zeitraumDelta = getZeitraumDelta(zeitraumMap, moment().startOf('day'));

        if (zeitraumDelta.length) {
            setReichweiteElement(null);
        } else {
            setReichweiteElement(<p><b>Verblisterung ist ausgesetzt</b></p>);
        }

        checkingZeitraum.current = false;
    }

        return (
    <div>
        <Row>
            <Col span={2}>{planzeile.buendelId ? <AnspruchSumme ansprueche={buendel?.entitlementSet} planzeile={planzeile} quartalsendeAnforderung={quartalsendeAnforderung} /> : "-"}</Col>

            <Col span={22}>
                <ReichweiteBar
                    wochen={ignoreReichweite ? -1 : reichweiteWochen}
                    value={ignoreReichweite ? null : planzeile.datumRezeptBenoetigtBis}
                    style={{marginTop: 5}}
                />

                {reichweiteElement}

                {medikationsplanContext.planzeileSelected === planzeile.id && <>
                    <Verordner
                        hausarzt={hausarzt}
                        value={planzeile.verordner}
                    />

                    <Button onClick={() => medikationsplanContext.handleErstelleAnforderung(planzeile, auth, buendel?.entitlementSet)}>
                        erstelle Anforderung
                    </Button>
                </>}
            </Col>
        </Row>
    </div>)
}

const AnspruchSumme = ({ansprueche, children, planzeile, quartalsendeAnforderung}) => {
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [menge, setMenge] = useState(null);
    const [mengeGeteilt, setMengeGeteilt] = useState(-1);
    const [mengeNichtTeilbar, setMengeNichtTeilbar] = useState(-1);

    const [hatSubstitutUngeteilt, setHatSubstitutUngeteilt] = useState(false);

    const [buendel, setBuendel] = useState({});

    useEffect(() => {
        if (planzeile?.buendelId) {
            const bndl = medikationsplanContext.buendelMap[planzeile.buendelId];
            if (bndl) setBuendel(bndl);
        }
    }, [planzeile, medikationsplanContext.buendelMap])

    useEffect(() => {
        if (ansprueche && buendel) {
            let menge = 0;
            let mengeGeteilt = 0;
            let mengeNichtTeilbar = 0;

            let hatSubstitutUngeteilt = false;
            for (let anspruch of ansprueche) {
                const faktor = Math.round((anspruch.factorBundle ? (1 / anspruch.factorBundle) : 0) * planzeile.buendelFaktor * 1000)/1000;

                let faktor1 = false;
                let faktorGroesser1 = false;
                let faktorKleiner1 = false;

                function processMenge(menge) {
                    const teilmenge = menge - Math.floor(menge);
                    const verrechnungsmenge = teilmenge ? teilmenge : menge;
                    const faktorZeit = faktor / verrechnungsmenge;
                    // if (faktorZeit === 1 || (faktor === 1 && (teilmenge === 0 || checkTeilbarkeit(anspruch.id?.medicineM2, faktor / verrechnungsmenge)))) {
                    if ((faktor === 1 && (teilmenge === 0 || checkTeilbarkeit(anspruch.id?.medicineM2, faktor / verrechnungsmenge)))) {
                        faktor1 = true;
                    } else if (checkTeilbarkeit(anspruch.id?.medicineM2, faktor / verrechnungsmenge)) {
                        if (faktorZeit > 1) {
                            faktorGroesser1 = true; // Tablette muss geteilt werden
                        } else {
                            faktorKleiner1 = true; // Tablette muss nicht geteilt werden
                        }
                    }
                }

                const dosierabschnittAktuell = medikationsplanContext.dosierabschnittAktuell?.[planzeile.id];
                if (dosierabschnittAktuell) {
                    // Wenn Dosierabschnitt Einträge hat
                    if (dosierabschnittAktuell.abgabemuster?.length) for (const tag of dosierabschnittAktuell.abgabemuster) {
                        for (const zeit of tag.zeiten) {
                            const menge = +zeit.menge;
                            processMenge(menge);
                        }
                    }

                    // Dosierabschnitt hat keine Einträge
                    else {
                        processMenge(1);
                    }
                }

                if (faktorGroesser1) {
                    mengeGeteilt += anspruch.amount / faktor;
                    hatSubstitutUngeteilt = true;
                } else if (faktorKleiner1) {
                    mengeGeteilt += anspruch.amount / faktor;
                } else if (faktor1) {
                    menge += anspruch.amount * faktor;
                }
            }

            setMenge(menge);
            setMengeGeteilt(mengeGeteilt);
            setMengeNichtTeilbar(mengeNichtTeilbar);
            setHatSubstitutUngeteilt(hatSubstitutUngeteilt);
        }
    }, [ansprueche, buendel])

    return <ConditionalPopover placement={"right"} content={menge === null || !ansprueche ? null : <table>
        <tbody>
            <tr>
                <th colSpan={2}>Anspruch für {getArzneimittelLangname(planzeile.arzneimittel)}</th>
            </tr>
            <tr>
                <th>Verfügbar{hatSubstitutUngeteilt && " (substituiert)"}</th>
                <td style={{paddingLeft: 15}}>{menge}</td>
            </tr>
            {!!medikationsplanContext.vorabMap[planzeile.id] && <tr>
                <th>Vorab</th>
                <td style={{paddingLeft: 15}}>{medikationsplanContext.vorabMap[planzeile.id]}</td>
            </tr>}
            {mengeGeteilt > 0 && <tr>
                <th>Verfügbar andere Dosierung</th>
                <td style={{paddingLeft: 15}}>{mengeGeteilt}</td>
            </tr>}
            {mengeNichtTeilbar > 0 && <tr>
                <th colSpan={2}>Nicht teilbare Bestände vorhanden</th>
            </tr>}
            <tr>
                <th>Bedarf pro Quartal (ca.)</th>
                <td style={{paddingLeft: 15}}>{planzeile.bedarfQuartal > 0 ? planzeile.bedarfQuartal : "K/A"}</td>
            </tr>

            {!!planzeile.bedarf && moment(planzeile.reichweiteAnspruch).isSameOrBefore(quartalsendeAnforderung) && <tr>
                <th>Benötigt bis Quartalsende Q{(quartalsendeAnforderung.format("Q") + 3) % 4}<br/>({quartalsendeAnforderung.format("DD.MM.YYYY")})</th>
                <td style={{paddingLeft: 15}}>{planzeile.bedarf}</td>
            </tr>}

            {!!planzeile.reichweiteAnspruch && <tr>
                <th>Anspruch geht aus am</th>
                <td style={{paddingLeft: 15}}>{moment(planzeile.reichweiteAnspruch).format("DD.MM.YYYY")}</td>
            </tr>}

            {!!planzeile.datumRezeptBenoetigtBis && <tr>
                <th>Rezept benötigt am</th>
                <td style={{paddingLeft: 15}}>{moment(planzeile.datumRezeptBenoetigtBis).format("DD.MM.YYYY")}</td>
            </tr>}
        </tbody></table>
    }>
        {menge === null ? <Loading text={null} size={"sm"} /> : children ? children : <pre>{menge || mengeGeteilt}</pre>}
    </ConditionalPopover>
}


const AnforderungButton = ({planzeile, ansprueche, onClick=()=>{}, onFocus=()=>{}, onMouseEnter=()=>{}, onMouseLeave=()=>{}}) => {

    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const {data: anforderungen, loading, error, update} = useFetch(fetchOffeneAnforderungenByPlanzeile(planzeile.id));
    const [visible, setVisible] = React.useState(false);
    const [pdfVisible, setPdfVisible] = useState(false);

    useEffect(() => {
        medikationsplanContext.setAnforderungen(prev => {
            const neu = {...prev};
            neu[planzeile.id] = anforderungen;
            return neu;
        });
    }, [anforderungen])

    if (loading)
        return <Button disabled>Anforderungen werden geladen</Button>

    if (anforderungen && Array.isArray(anforderungen) && anforderungen.length)
        return <span style={{border: "1px sloid red"}}>
            <Modal
                title="Rezeptanforderung"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                {anforderungen.map(anforderung =>
                    <div key={anforderung.id}>
                        <p>
                            <b>Arzneimittel:</b> {getArzneimittelLangname(anforderung.arzneimittel)}
                            {
                                anforderung.mengeBenoetigt === 0 &&
                                <i style={{marginLeft: 5}}>({anforderung.arzneimittel.menge} {anforderung.arzneimittel.einheit}{anforderung.arzneimittel.normgroesse ? ", "+anforderung.arzneimittel.normgroesse : ""})</i>
                            }
                        </p>
                        <p><b>Arzt:</b> {getUnitLangname(anforderung.verordner, "arzt")}</p>

                        {anforderung.kommentar && <p><b>Kommentar:</b><br/>{nl2br(anforderung.kommentar)}</p>}

                        {!anforderung.datumAngefordert &&
                        <h3>noch nicht versandt!</h3>
                        }

                        {!anforderung.datumAngefordert &&
                        <Button onClick={() => window.open(getAnforderungVoransicht(anforderung.id) + "?token=" + auth.token)}>Voransicht dieser Anforderung anzeigen</Button>
                        }

                        {anforderung.datumAngefordert &&
                        <p><b>Angefordert:</b> {moment(anforderung.datumAngefordert).format("DD.MM.YYYY HH:mm")}</p>
                        }

                        {anforderung.nachricht && <Button onClick={() => { setVisible(false); window.open(nachrichtApi.getDokumentById(anforderung.nachricht.anhangListe[0].id) + "?token=" + auth.token) }}>PDF anzeigen</Button>}
                    </div>
                )}
            </Modal>

            <Button
                onClickCapture={e => setVisible(true)}
            >Rezeptanforderung existiert </Button>
        </span>

    return <Button onClick={() => medikationsplanContext.handleErstelleAnforderung(planzeile, auth, ansprueche)}>
        erstelle Anforderung
    </Button>
}