import React, {useContext, useEffect, useRef, useState} from "react";
import {useAuth} from "./useAuth";
import MedikationsplanContext from "../contexts/MedikationsplanContext";
import {arzneimittelApi} from "../config/apiConfig";
import {callApiAsync, CancelablePromise} from "./apiUtil";
import ArzneimittelContext from "../contexts/ArzneimittelContext";
import {fetchAnsprueche} from "../config/fetchApiConfiguration";

const useApiAnsprueche = () => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const arzneimittelContext = useContext(ArzneimittelContext);

    const loadingArzneimittel = useRef({});
    const checkingAnspruecheSubstitute = useRef(null);
    const resolveAnspruchMap = useRef(null);

    useEffect(() => {
        if (resolveAnspruchMap.current) {
            resolveAnspruchMap.current(medikationsplanContext.anspruchMap);
            resolveAnspruchMap.current = null;
        }
    }, [medikationsplanContext.anspruchMap])

    const loadAnsprueche = async (patientId) => {
        if (!patientId) return null;

        medikationsplanContext.setAnspruecheLoaded(false);

        const response = await callApiAsync({auth, url: fetchAnsprueche(patientId)});
        const anspruchListNeu = response.data.OBJECT;

        if (anspruchListNeu.length) {
            medikationsplanContext.setAnspruchList(anspruchListNeu);
            return new CancelablePromise((resolve) => {
                resolveAnspruchMap.current = resolve;
            });
        } else {
            return new Promise(resolve => resolve({}));
        }
    }

    const checkAnspruecheSubstitute = async (ansprueche) => {
        if (checkingAnspruecheSubstitute.current) {
            checkingAnspruecheSubstitute.current.cancel();
        }

        checkingAnspruecheSubstitute.current = new CancelablePromise(async (resolve) => {
            if (!ansprueche) ansprueche = medikationsplanContext.anspruchList;

            const anspruchListNeu = [...ansprueche];
            for (let anspruch of anspruchListNeu) {
                const m2StringPlanzeile = anspruch.planzeileId && medikationsplanContext.planzeilenMap[anspruch.planzeileId]?.arzneimittel?.m2;
                if (m2StringPlanzeile && m2StringPlanzeile !== anspruch.m2String) {
                    let faktor = arzneimittelContext.substitute[m2StringPlanzeile]?.[anspruch.m2String];
                    if (faktor === undefined) {
                        const url = arzneimittelApi.getSubstitutFaktor(m2StringPlanzeile, anspruch.m2String);
                        if (!loadingArzneimittel.current[url]) {
                            loadingArzneimittel.current[url] = new CancelablePromise(async (resolve) => {
                                const response = await callApiAsync({url, auth});
                                const faktor = response.data.OBJECT[Object.keys(response.data.OBJECT)[0]];

                                arzneimittelContext.addSubstitut(m2StringPlanzeile, anspruch.m2String, faktor);
                                resolve(faktor);
                            });
                        }

                        faktor = await loadingArzneimittel.current[url];
                        delete loadingArzneimittel.current[url];
                    }

                    anspruch.substitutFaktor = faktor;
                } else {
                    const bla = "blubb";
                }
            }

            medikationsplanContext.setAnspruchList(anspruchListNeu);
            medikationsplanContext.setAnspruchSubstituteChecked(true);

            resolve(true);
            checkingAnspruecheSubstitute.current = null;
        });

        return checkingAnspruecheSubstitute.current;
    }

    return {
        loadAnsprueche,
        checkAnspruecheSubstitute,
    }
}

export default useApiAnsprueche;