import React, {useContext, useEffect, useRef, useState} from 'react';
import queryString from 'query-string';
import {Row, Button, Layout, notification, Menu} from 'antd';
import {Tooltip} from "../atoms/Tooltip";

import { useAuth } from '../../utilities/useAuth';

import {dokumenteApi, infoMarkerApi} from '../../config/apiConfig'
import { DokumentAnsicht } from './../organisms/DokumentAnsicht';
import { Infomarker } from './../organisms/Infomarker';
import { DateiInfo } from './../organisms/DateiInfo';
import { callApiAsync } from "../../utilities/apiUtil";

import PopupDokumenteContext, {useInitialState} from "../../contexts/PopupDokumenteContext";
import DokumenteContext from "../../contexts/DokumenteContext";
import {
    ColumnHeightOutlined, ColumnWidthOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    RedoOutlined,
    UndoOutlined,
    PrinterOutlined,
} from "@ant-design/icons";
import {pdfGroesseAngleichenDefault} from "../organisms/DokumentAnsicht";
import {ANSICHT_STANDARD} from "../organisms/MedikationsplanZeilen";
import {getDosierabschnittDateRangeString} from "../../utilities/dosierschemaUtil";

const {Content, Sider} = Layout;

export const PopupDokumenteAnsicht = props => {
    const auth = useAuth();

    const printIframeRef = useRef();

    const [siderCollapsed, setSiderCollapsed] = useState(true);

    const [anzeigeDokument, setAnzeigeDokument] = useState(null);
    const [anzeigeDokumentJson, setAnzeigeDokumentJson] = useState(null);
    const [infomarker, setInfomarker] = useState([]);
    const [filter, setFilter] = useState({});

    const [finishedLoading, setFinishedLoading] = useState({});
    const [highlightInfoMarker, setHighlightInfoMarker] = useState(null);
    const [locationQuery, setLocationQuery] = useState(null);

    const popupDokumenteContext = useInitialState();
    const dokumenteContext = useContext(DokumenteContext);

    const pdfRotateLast = useRef(-1);
    const [pdfRotate, setPdfRotate] = useState(0);
    const pdfRotateOptions = [0, 90, 180, 270];

    const [pdfGroesseAngleichen, setPdfGroesseAngleichen] = useState(pdfGroesseAngleichenDefault);
    const [redrawDokument, setRedrawDokument] = useState(false);

    const [dokumentInfoEdit, setDokumentInfoEdit] = useState(false);

    const beforeUnload = () => {
        if (window.name) dokumenteContext.setWindowClosed(window.name);
    }

    // URL -> API aufruf / anzeige definition
    useEffect(() => {
        if (props.match.params.dokumentId) {
            loadDokumentById(props.match.params.dokumentId);
            setFilter(null);
        }

        if (props.match.params.planzeileId) {
            setAnzeigeDokument(null);
            setInfomarker([]);
            setFilter({ planzeileId: props.match.params.planzeileId });
        }

        if (props.match.params.patientId) {
            setAnzeigeDokument(null);
            setInfomarker([]);
            setFilter({ patientId: props.match.params.patientId });
        }

        window.addEventListener("beforeunload", beforeUnload);
        return () => window.removeEventListener("beforeunload", beforeUnload);
    }, []);

    useEffect(() => {
        if (props.location) {
            setLocationQuery(queryString.parse(props.location.search));
        }
    }, [props.location])

    useEffect(() => {
        if (locationQuery && locationQuery.im) {
            const im = infomarker.filter(im => im.id == locationQuery.im);
            if (im.length) {
                const locationQueryNew = {...locationQuery};
                delete locationQueryNew.im;

                setLocationQuery(locationQueryNew)
                setHighlightInfoMarker(im[0]);
            }
        }
    }, [infomarker])

    useEffect(anzeigeDokumentAlt => {
        if (anzeigeDokument) {
            const json = anzeigeDokument.json ? JSON.parse(anzeigeDokument.json) : {};
            setAnzeigeDokumentJson(json);

            if (json.pageOrder) {
                popupDokumenteContext.setPageOrder(json.pageOrder);
            } else {
                popupDokumenteContext.setPageOrder([1]);
            }

            loadInfoMarker(anzeigeDokument.id);

            const filterNeu = filter || {};
            delete filterNeu.planzeile;
            if (anzeigeDokument.patient && (!filterNeu.patient || filterNeu.patient !== anzeigeDokument.patient.id)) filterNeu.patient = anzeigeDokument.patient;
            setFilter(filterNeu);

            if (window.name) {
                dokumenteContext.setDokumentOpened(anzeigeDokument.id, window.name);
                if (anzeigeDokumentAlt) dokumenteContext.setDokumentClosed(anzeigeDokumentAlt.id);
                return () => dokumenteContext.setDokumentClosed(anzeigeDokument.id);
            }
        }
    }, [anzeigeDokument])

    useEffect(() => {}, [filter])

    useEffect(() => {
        if (dokumenteContext.zuletztGespeicherterInfoMarker) {
            const newVar = [...infomarker, dokumenteContext.zuletztGespeicherterInfoMarker];
            setInfomarker(newVar);
        }
    }, [dokumenteContext.zuletztGespeicherterInfoMarker]);

    useEffect(() => {
        if (popupDokumenteContext.selectedInfoMarker) setFilter({...filter, planzeile: popupDokumenteContext.selectedInfoMarker.planzeile});
        else setFilter(prev => {
            const neu = {...prev};
            delete neu.planzeile;
            return neu;
        });
    }, [popupDokumenteContext.selectedInfoMarker])

    useEffect(() => {
        if (anzeigeDokument && anzeigeDokumentJson && popupDokumenteContext?.pageOrder !== anzeigeDokumentJson?.pageOrder) {
            const data = {...anzeigeDokument, json: JSON.stringify({...anzeigeDokumentJson, pageOrder: popupDokumenteContext.pageOrder})};

            callApiAsync({
                auth,
                url: dokumenteApi.put(anzeigeDokument.id),
                method: "PUT",
                data
            }).then(response => {
                const dokument = response.data.OBJECT;
                dokumenteContext.putDokument(dokument, ["json"]);
            });
        }
    }, [popupDokumenteContext.pageOrder])

    useEffect(() => {
        if (highlightInfoMarker && finishedLoading) {
            for (let pageNum of popupDokumenteContext.pageOrder) {
                if (!finishedLoading[pageNum]) {
                    return;
                }
            }

            popupDokumenteContext.setSelectedInfoMarker(highlightInfoMarker)
            setHighlightInfoMarker(null);
        }
    }, [highlightInfoMarker, finishedLoading])

    useEffect(() => {
        setSiderCollapsed(true);

        if (anzeigeDokument && pdfRotateLast.current > -1 && pdfRotateLast.current !== pdfRotate) {
            const data = {...anzeigeDokument, json: JSON.stringify({...anzeigeDokumentJson, rotation: pdfRotate})};

            callApiAsync({
                auth,
                url: dokumenteApi.put(anzeigeDokument.id),
                method: "PUT",
                data
            }).then(response => {
                const dokument = response.data.OBJECT;
                dokumenteContext.putDokument(dokument, ["json"]);
            });
        }

        pdfRotateLast.current = pdfRotate;
    }, [pdfRotate])

    useEffect(() => {
        if (
            anzeigeDokument &&
            dokumenteContext.dokumentUpdated &&
            dokumenteContext.dokumentUpdated.alt &&
            dokumenteContext.dokumentUpdated.alt.id === anzeigeDokument.id
        ) {
            setAnzeigeDokument(dokumenteContext.dokumentUpdated.neu);
        }
    }, [dokumenteContext.dokumentUpdated])

    useEffect(() => {
        if (anzeigeDokument && popupDokumenteContext.removeInfoMarker?.dokument?.id === anzeigeDokument?.id) {
            removeInfoMarker(popupDokumenteContext.removeInfoMarker);
        }
    }, [popupDokumenteContext.removeInfoMarker])


    const loadDokumentById = async (dokumentId) => {
        const responseDokumente = await callApiAsync({auth, url: dokumenteApi.getById(dokumentId)});
        if (responseDokumente.data.RESULT === "SUCCESS") {

            const dokument = responseDokumente.data.OBJECT;
            if (dokumenteContext.dokumenteListe) dokumenteContext.setDokumenteListe([...dokumenteContext.dokumenteListe.filter(dok => dok.id !== dokument.id), dokument]);
            else dokumenteContext.setDokumenteListe([dokument]);

            setAnzeigeDokument(dokument);

            if (dokument.json) {
                const json = JSON.parse(dokument.json);
                if (!isNaN(json.rotation)) {
                    pdfRotateLast.current = json.rotation;
                    setPdfRotate(json.rotation);
                }
            }
        }
    };

    const loadInfoMarker = async dokumentId => {
        const responseInfomarker = await callApiAsync({auth, url: dokumenteApi.getInfozeileById(dokumentId)});
        setInfomarker(responseInfomarker.data.OBJECT);
    };

    const updateInfomarker = async infomarkerEdit => {
        const infomarkerNeu = [...infomarker];
        const index = infomarkerNeu.findIndex(marker => marker.id === infomarkerEdit.id);

        if (index > -1) {
            infomarkerNeu[index] = infomarkerEdit;

            const response = await callApiAsync({auth, url: dokumenteApi.putInfomarkerById(infomarkerEdit.id), method: 'put', data: infomarkerEdit});
            setInfomarker(infomarkerNeu);
        }
    };

    const deleteInfomarker = async delInfomarker => {
        const response = await callApiAsync({auth, url: infoMarkerApi.delete(delInfomarker.id), method: 'DELETE'});

        notification.info({message: "Die Verknüpfung wurde erfolgreich gelöscht"});
        const infomarkerNeu = infomarker.filter(im => im.id !== delInfomarker.id);
        setInfomarker(infomarkerNeu);

        dokumenteContext.setZuletztGeloeschterInfoMarker(delInfomarker);
    };

    const onEditDokument = async editState => {
        if (Object.keys(editState).length) {
            const editRecord = {...anzeigeDokument};

            if (editState.titel) editRecord.dokument.titel = editState.titel;
            if (editState.patient) editRecord.patient = editState.patient;
            if (editState.pflegeheim) editRecord.pflegeheim = editState.pflegeheim;
            if (editState.verordner) editRecord.verordner = editState.verordner;
            if (editState.quelle) editRecord.quelle = editState.quelle;
            if (editState.datumErstellung) editRecord.datumErstellung = editState.datumErstellung.getTime();
            if (editState.datumEingang) editRecord.datumEingang = editState.datumEingang.getTime();

            const response = await callApiAsync({
                auth,
                url: dokumenteApi.put(editRecord.id),
                method: "PUT",
                data: editRecord
            });
            dokumenteContext.putDokument(response.data.OBJECT);
        }
    }

    const removeInfoMarker = im => {
        // bereits gespeicherter infoMarker
        if (im?.id) {
            const delMarkerArray = infomarker.filter(marker => marker.id === im.id);
            const delMarker = delMarkerArray.length ? delMarkerArray[0] : null;

            if (delMarker) deleteInfomarker(delMarker);
        } else {
            const imNeu = dokumenteContext.infoMarkerNeu.filter(marker => marker.marker[0] !== im.marker[0]);
            if (imNeu !== dokumenteContext.infoMarkerNeu) dokumenteContext.setInfoMarkerNeu(imNeu);
        }

        popupDokumenteContext.setImContextMenu(null);
    };

    const printDocument = () => {
        window.open(dokumenteApi.downloadAddToken(anzeigeDokument.id, auth), "_blank");
    }


    return <Row>
        <PopupDokumenteContext.Provider value={popupDokumenteContext}>

            <Layout>
                <Content>
                    <div>Datei: {anzeigeDokument?.dokument?.dateiname || ""}</div>
                    {popupDokumenteContext.pageOrder.map(pageNumber => {
                        return <DokumentAnsicht
                            key={pageNumber}
                            pageNumber={pageNumber}

                            auth={auth}
                            anzeigeDokument={anzeigeDokument}
                            infomarker={infomarker}
                            updateInfomarker={updateInfomarker}
                            finishedLoading={finishedLoading[pageNumber] || false}
                            setFinishedLoading={(val) => setFinishedLoading(prev => ({...prev, [pageNumber]: val}))}
                            closeSider={() => setSiderCollapsed(true)}
                            pdfRotate={pdfRotate}
                            pdfGroesseAngleichen={pdfGroesseAngleichen}

                            redrawDokument={redrawDokument}
                            setRedrawDokument={setRedrawDokument}
                        />
                    })}
                    {siderCollapsed && <div style={{position: "fixed", right: 0, top: 0}}>
                        {dokumenteContext.isPDF && <>
                            <Tooltip placement="bottom" title="Dokument der Seitenhöhe anpassen"><Button onClick={() => {
                                if (pdfGroesseAngleichen !== "bodyHeight") setPdfGroesseAngleichen("bodyHeight");
                                else setRedrawDokument(true);
                            }}><ColumnHeightOutlined /></Button></Tooltip>
                            <Tooltip placement="bottom" title="Dokument der Seitenbreite anpassen"><Button onClick={() => {
                                if (pdfGroesseAngleichen !== "bodyWidth") setPdfGroesseAngleichen("bodyWidth");
                                else setRedrawDokument(true);
                            }}><ColumnWidthOutlined /></Button></Tooltip>
                            <Tooltip placement="bottom" title="Dokument gegen den Uhrzeigersinn rotieren"><Button onClick={() => {
                                let index = (pdfRotateOptions.indexOf(pdfRotate)-1) % pdfRotateOptions.length;
                                if (index < 0) index += pdfRotateOptions.length;
                                setPdfRotate(pdfRotateOptions[index])}}><UndoOutlined/></Button></Tooltip>
                            <Tooltip placement="bottom" title="Dokument im Uhrzeigersinn rotieren"><Button onClick={() => {setPdfRotate(pdfRotateOptions[(pdfRotateOptions.indexOf(pdfRotate)+1) % pdfRotateOptions.length])}}><RedoOutlined/></Button></Tooltip>
                        </>}

                        <Tooltip placement="bottom" title="Dokument drucken"><Button onClick={() => printDocument()}><PrinterOutlined/></Button></Tooltip>
                        <Tooltip placement="bottom" title="Menü öffnen"><Button onClick={() => setSiderCollapsed(!siderCollapsed)}><MenuFoldOutlined/></Button></Tooltip>
                    </div>}

                    {/* Kontextmenü für InfoMarker */}
                    {popupDokumenteContext.imContextMenu &&
                    <div id={"div-im-context-menu"} style={{zIndex: 200, position: "absolute", top: popupDokumenteContext.imContextMenu.event.pageY, left: popupDokumenteContext.imContextMenu.event.pageX, border: "1px solid black"}}>
                        <Menu mode="inline">
                            <Menu.Item key="1" onClick={e => removeInfoMarker(popupDokumenteContext.imContextMenu.infoMarker)}>Verknüpfung löschen</Menu.Item>

                            {
                                popupDokumenteContext.imContextMenu.infoMarker.dosierabschnittSet?.length > 1 &&
                                popupDokumenteContext.imContextMenu.infoMarker.dosierabschnittSet.map(d => <Menu.Item key={"d" + d.id} onClick={e => {
                                    window.open(
                                        `/medikationsplan/${popupDokumenteContext.imContextMenu.infoMarker.planzeile.medikationsplan.id}/${encodeURIComponent(popupDokumenteContext.imContextMenu.infoMarker.ansicht ? popupDokumenteContext.imContextMenu.infoMarker.ansicht : ANSICHT_STANDARD)}/${popupDokumenteContext.imContextMenu.infoMarker.planzeile.id}?dosierabschnitt=${d.id}`,
                                        "parent-default")
                                }}>Abschnitt {getDosierabschnittDateRangeString(d)}</Menu.Item>)
                            }
                        </Menu>
                    </div>
                    }
                </Content>
                <Sider
                    collapsible
                    collapsed={siderCollapsed}
                    onCollapse={(collapsed, type) => setSiderCollapsed(collapsed)}
                    collapsedWidth={0}
                    width={800}

                    id={"sider-dokument-popup"}
                    style={{height: document.body.offsetHeight}}
                >
                    <Infomarker infomarker={infomarker} onClick={infoMarker => setSiderCollapsed(true)} onRemove={deleteInfomarker} />
                    {/*<DateiAuswahl filter={filter} setFilter={setFilter} anzeigeDokument={anzeigeDokument} setAnzeigeDokument={setAnzeigeDokument} finishedLoading={finishedLoading} />*/}

                    <br/>
                    <DateiInfo anzeigeDokument={anzeigeDokument} filter={filter} setFilter={setFilter} onEdit={onEditDokument} setEdit={setDokumentInfoEdit} />

                    {!siderCollapsed && <Button onClick={() => setSiderCollapsed(!siderCollapsed)} style={{position: "fixed", right: 0, top: 0}}><MenuUnfoldOutlined/></Button>}
                </Sider>
            </Layout>

        </PopupDokumenteContext.Provider>
    </Row>
};








