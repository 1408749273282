import React, {useContext, useEffect, useRef, useState} from 'react'

import { useDataNode } from './../../utilities/useDataNode';

import {Tabs, Button, Row, Col, Affix, notification, Modal, InputNumber, Select} from 'antd';

import { medikationsplanNode } from './../../config/dataNodeConfiguration';
import { getUnitLangname } from './../../config/entities';

import { BlisterJobListe } from './BlisterJobListe';
import { BlisterJobExport } from './BlisterJobExport';

import moment from 'moment';
import { InputWochentag } from '../atoms/InputWochentag';
import {Link, useHistory} from 'react-router-dom';
import { InputBoolean } from '../atoms/InputBoolean';
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {StatusDisplay} from "../../utilities/useDataNode";
import queryString from 'query-string';
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {akteureApi} from "../../config/apiConfig";
import useDebounce from "../../utilities/useDebounce";
import MedikationsplanKopfLogs from "../molecules/MedikationsplanKopfLogs";
import {MedikationsplanKopfAbgaben} from "./MedikationsplanKopfAbgaben";
import {getWochentag} from "../../utilities/dateUtil";
import MedikationsplanZeitraeume from "./MedikationsplanZeitraeume";
import {Popover} from "../atoms/Popover";
import useApiZeitraum, {getCurrentZeitraumColor} from "../../utilities/useApiZeitraum";
import MedikationsplanBuendelTable from "./MedikationsplanBuendelTable";
import MedikationsplanAnforderungenTable from "./MedikationsplanAnforderungenTable";
import BlisterTypeSelect from "../atoms/BlisterTypeSelect";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import MedikationsplanExternalPackage from "./MedikationsplanExternalPackage";

const { TabPane } = Tabs;


export const MedikationsplanKopf = ({ value, changed, onChange, handleSave: handleSaveParent, handleLoad, handleNew, disableNeueZeile, tabChanged, setTabChanged, saving, setSaving }) => {
    const history = useHistory();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const adressbuchContext = useContext(AdressbuchContext);
    const auth = useAuth();

    const [tab, setTab] = useState("uebersicht");
    const [blisterjobs, setBlisterjobs] = useState(null)
    const blisterJobsFetched = useRef(false);
    const [triggerSave, setTriggerSave] = useState(false);

    const [loadingNeuePlanzeile, setLoadingNeuePlanzeile] = useState(false);

    const checkAenderungsauftragAbDebounced = useDebounce(medikationsplanContext.checkAenderungsauftragAb, 100);
    const [showAenderungsauftragModal, setShowAenderungsauftragModal] = useState(false);

    const [medikationsplanPrev, setMedikationsplanPrev] = useState(null);
    const [medikationsplanNext, setMedikationsplanNext] = useState(null);

    const [verblisterungWochentag, setVerblisterungWochentag] = useState(null);
    const [verblisterungTagDifferenz, setVerblisterungTagDifferenz] = useState(value?.verblisterungTagDifferenz);

    const {
        current: medikationsplan,
        status,
        handleChange,
        handleChangeNoTriggerUpdated,
    } = useDataNode({
        dataNode: medikationsplanNode,
        value,
        onChange
    })

    useEffect(() => {
        if (medikationsplanContext.medikationsplan && medikationsplanContext.searchList?.active) {
            const medikationsplaene = medikationsplanContext.searchList.medikationsplaene;
            for (let i=medikationsplaene.length-1; i>=0; i--) {
                if (medikationsplaene[i].id === medikationsplanContext.medikationsplan.id) {
                    if (i > 0) {
                        setMedikationsplanPrev(medikationsplaene[i-1]);
                    }

                    if (i < medikationsplaene.length - 1) {
                        setMedikationsplanNext(medikationsplaene[i+1]);
                    }

                    break;
                }
            }
        }
    }, [medikationsplanContext.searchList, medikationsplanContext.medikationsplan])

    useEffect(() => {
        let verblisterungTag = medikationsplan?.blisterStartTag - verblisterungTagDifferenz;
        setVerblisterungWochentag(getWochentag(verblisterungTag));
    }, [verblisterungTagDifferenz])

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        medikationsplanContext.setShowPlanzeileInvisible("showAll" in query && query.showAll !== false);
        setTab(adressbuchContext.contentParams.param1 || "uebersicht");
    }, [adressbuchContext.contentParams, history.location.search])

    useEffect(() => {
        switch (tab) {
            case "uebersicht":
                medikationsplanContext.setMedikationsplanVisible(true);
                break;
            default:
                medikationsplanContext.setMedikationsplanVisible(false);
        }
    }, [tab])

    useEffect(() => {
        if (blisterjobs) {
            blisterJobsFetched.current = true;
            medikationsplanContext.setBlisterjobs(blisterjobs);
        }
    }, [blisterjobs])

    useEffect(() => {
        if (!tabChanged) setTriggerSave(false);
    }, [tabChanged])

    useEffect(() => {
        if (medikationsplanContext.neuePlanzeile) {
            setLoadingNeuePlanzeile(false);
        }
    }, [medikationsplanContext.neuePlanzeile])

    useEffect(() => {
        if (!medikationsplanContext.disableCheckAenderungModal && medikationsplanContext.checkAenderungsauftragAb === checkAenderungsauftragAbDebounced && medikationsplanContext.exportStart !== medikationsplanContext.checkAenderungsauftragAb) {
            setShowAenderungsauftragModal(true);
        }
    }, [medikationsplanContext.checkAenderungsauftragAb, checkAenderungsauftragAbDebounced, medikationsplanContext.disableCheckAenderungModal])

    useEffect(() => {
        if (!showAenderungsauftragModal) {
            medikationsplanContext.setCheckAenderungsauftragAb(null);
        }
    }, [showAenderungsauftragModal])



    const onClickNeuePlanzeile = () => {
        setLoadingNeuePlanzeile(true);
        handleNew();
    }

    const setBlisterType = (blisterType_id) => {
        if (!blisterType_id) blisterType_id = null;
        updatePatient({...medikationsplan?.patient, blisterType_id});

        // if (medikationsplanContext.patientStammdatenLoaded) {
        //     medikationsplanContext.setChangePatient([["blisterType_id", blisterType_id], ['checkAkteurNameDateOfBirthConcat', true]]);
        // } else {
        //     updatePatient({...medikationsplan?.patient, blisterType_id});
        // }
    }

    const updatePatient = async patientNew => {
        const response = await callApiAsync({auth, url: akteureApi.put(patientNew.id), method: "put", data: patientNew});
        handleChangeNoTriggerUpdated(response.data.OBJECT, "patient");
    }

    return <StatusDisplay status={status}>
        <Tabs
            defaultActiveKey="0"
            tabBarExtraContent={
                <Affix offsetTop={110}>
                    {tab !== "adressbuch" && <Button onClick={onClickNeuePlanzeile} disabled={disableNeueZeile} loading={loadingNeuePlanzeile}><PlusCircleOutlined /> neue Planzeile einfügen</Button>}
                </Affix>
            }
            onChange={key => {
                const query = queryString.parse(history.location.search);

                const split = history.location.pathname.split("/")
                let url = `${split.slice(0, 4).join("/")}`;
                if (key !== "uebersicht") {
                    url += `/${key}`;
                }

                history.push(queryString.stringifyUrl({url, query}));
            }}
            activeKey={tab}
        >

            <TabPane tab="Medikationsplan" key="uebersicht">
                <Row>
                    <Col span={8} style={{paddingTop: 40}}>
                        {auth.developer &&
                            <Row>
                                <Col span={4}>
                                    ID
                                </Col>
                                <Col span={20}>
                                    <b>{medikationsplan.id}</b>
                                </Col>
                            </Row>
                        }

                        {medikationsplan?.patient.careFacility &&
                            <Row>
                                <Col span={4}>
                                    Pflegeheim
                                </Col>
                                <Col span={20}>
                                    <Link to={'/adressbuch/' + medikationsplan.patient.careFacility.id} >
                                        <b>{getUnitLangname(medikationsplan.patient.careFacility)}</b>
                                    </Link>
                                </Col>
                            </Row>
                        }

                        {medikationsplan?.patient.station &&
                            <Row>
                                <Col span={4}>
                                    Station
                            </Col>
                                <Col span={20}>
                                    <Link to={'/adressbuch/' + medikationsplan.patient.station.id} >
                                        <b>{getUnitLangname(medikationsplan.patient.station)}</b>
                                    </Link>
                                </Col>
                            </Row>
                        }

                        {medikationsplan?.patient.hausarzt &&
                            <Row>
                                <Col span={4}>
                                    Hausarzt
                            </Col>
                                <Col span={20}>
                                    <Link to={'/adressbuch/' + medikationsplan.patient.hausarzt.id} >
                                        <b>{getUnitLangname(medikationsplan.patient.hausarzt, "arzt")}</b>
                                    </Link>
                                </Col>
                            </Row>
                        }

                        {medikationsplanPrev &&
                            <Row>
                                <Col span={4}>
                                    Patient &#129092;
                                </Col>
                                <Col span={20}>
                                    <a onClick={() => history.push("/medikationsplan/" + medikationsplanPrev.id)}><b>{getUnitLangname(medikationsplanPrev.patient)}</b></a>
                                </Col>
                            </Row>
                        }

                        {medikationsplanNext &&
                            <Row>
                                <Col span={4}>
                                    Patient &#129094;
                                </Col>
                                <Col span={20}>
                                    <a onClick={() => history.push("/medikationsplan/" + medikationsplanNext.id)}><b>{getUnitLangname(medikationsplanNext.patient)}</b></a>
                                </Col>
                            </Row>
                        }

                    </Col>

                    <Col span={10}>
                        {medikationsplan?.patient.generalNote && <div>
                            <h6>Allgemeiner Hinweis</h6>
                            <pre>{medikationsplan?.patient.generalNote}</pre>
                        </div>}

                        {medikationsplan?.patient.dosageNote && <div>
                            <h6>Allgemeiner Hinweis zur Dosierung</h6>
                            <pre>{medikationsplan?.patient.dosageNote}</pre>
                        </div>}

                    </Col>


                    <Col span={6} style={{ textAlign: "right" }}>

                        <Popover placement={"left"} content={<div>
                            <InputBoolean
                                label="maschinenverblisterung"

                                wahr="Maschinenverblisterung"
                                falsch="Handverblisterung"

                                value={medikationsplan?.maschinenverblisterung}
                                onChange={handleChange}
                            />
                            <br />

                            Begleitblatt-Reihenfolge wie Ansicht:
                            <Select value={medikationsplan?.ansichtBegleitblatt} onChange={val => handleChange(val, "ansichtBegleitblatt")} dropdownMatchSelectWidth={false}>
                                {medikationsplanContext.ansichten.map(ansicht => {
                                    return <Select.Option key={ansicht} value={ansicht}>{ansicht}</Select.Option>
                                })}
                            </Select>
                            <br />

                            Blister beginnt:
                            <InputWochentag
                                value={medikationsplan?.blisterStartTag}
                                onChange={handleChange}
                                label="blisterStartTag"
                            />
                            <br />

                            Wird verblistert:
                            <InputNumber
                                value={verblisterungTagDifferenz}
                                onChange={(value) => {
                                    setVerblisterungTagDifferenz(value)
                                }}

                                min={0}
                                onBlur={val => {
                                    if (document.activeElement !== val.target && verblisterungTagDifferenz !== medikationsplan?.verblisterungTagDifferenz) {
                                        handleChange(verblisterungTagDifferenz, "verblisterungTagDifferenz");
                                    }
                                }}
                                style={{width: "3rem"}}
                            />
                            Tage vor Blisterbeginn ({verblisterungWochentag})

                            {!medikationsplan?.maschinenverblisterung && <div>
                                Voreinstellung für BlisterTyp:<br />
                                <BlisterTypeSelect
                                    onChange={val => setBlisterType(val)}
                                    defaultValue={medikationsplan?.patient.blisterType_id || 0}
                                />
                            </div>}
                        </div>}>
                            <Button>Einstellungen zu Verblisterung</Button>
                        </Popover>

                    </Col>

                </Row>
            </TabPane>

            <TabPane tab="Verblisterung" key="verblisterung"  style={{paddingTop: 40}}>
                <BlisterJobExport
                    patientId={medikationsplan?.patient.id}
                    medikationsplan={medikationsplan}
                    blisterjobs={blisterjobs}
                    triggerReload={handleLoad}
                    updated={changed}
                />

                <BlisterJobListe
                    patientId={medikationsplan?.patient.id}
                    medikationsplan={medikationsplan}
                    // onImportJob={() => window.location.reload()}
                    onImportJob={planzeilen => {
                        handleLoad();

                        // prüfe auf mehrere unterschiedliche Verordner
                        let verordner = null
                        for (let planzeile of planzeilen) {
                            if (planzeile.verordnerFreitext) {
                                if (!verordner) {
                                    verordner = planzeile.verordnerFreitext;
                                } else if (verordner !== planzeile.verordnerFreitext) {
                                    notification.warn({message: "Der Medikationsplan enthält Einträge von unterschiedlichen Verordnern!"})
                                }
                            }
                        }

                        const url = history.location.pathname;
                        const query = queryString.parse(history.location.search);
                        delete query.tab;
                        history.push(queryString.stringifyUrl({url, query}));
                    }}
                    onJobReload={setBlisterjobs}
                />
            </TabPane>

            <TabPane tab="Zeiträume" key="zeitraeume" style={{paddingTop: 40}}>
                <MedikationsplanZeitraeume />
            </TabPane>

            <TabPane tab="Anspruch &amp; Bestand" key="anspruch" style={{paddingTop: 40}}>
                <MedikationsplanBuendelTable />
            </TabPane>

            <TabPane tab="Ausgelieferte Packungen" key="ausgeliefert" style={{paddingTop: 40}}>
                <MedikationsplanExternalPackage />
            </TabPane>

            <TabPane tab="Anforderungen" key="anforderungen" style={{paddingTop: 40}}>
                <MedikationsplanAnforderungenTable />
            </TabPane>

            <TabPane tab="Blister-Abgaben" key="abgaben" style={{paddingTop: 40}}>
                <MedikationsplanKopfAbgaben />
            </TabPane>

            <TabPane tab="Historie" key="historie" style={{paddingTop: 40}}>
                <MedikationsplanKopfLogs />
            </TabPane>
        </Tabs>

        {checkAenderungsauftragAbDebounced && showAenderungsauftragModal &&
            <Modal
                title={"Eine Freigabe wurde erteilt"}
                okText={"JA, auf Änderungen prüfen"}
                cancelText={"NEIN, nicht prüfen"}
                onOk={() => {
                    setShowAenderungsauftragModal(false);
                    medikationsplanContext.setReload(true);
                    medikationsplanContext.setExportStart(checkAenderungsauftragAbDebounced);
                    setTab("verblisterung");

                    window.scrollTo({top: 0, behavior: 'smooth'});
                }}
                onCancel={() => {
                    setShowAenderungsauftragModal(false);
                }}
                visible={true}
            >
                Eine Freigabe wurde erteilt. Soll Für den Zeitraum ab {checkAenderungsauftragAbDebounced.format("DD.MM.YYYY")} auf Änderungsaufträge geprüft werden?
            </Modal>
        }
    </StatusDisplay >
}