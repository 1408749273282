import React, {useEffect, useState} from 'react'

import moment from 'moment';
import {EINNAHMEZEITEN_FUENFFACH} from '../config/misc';
import {callApiAsync} from "./apiUtil";
import {planzeilenApi} from "../config/apiConfig";


// aktueller tag, 00:00:00.000 Uhr als unix timecode (millisec)
export const heute = moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).valueOf();




/*********************************************
    Sorter
*********************************************/

// sortiert einfach nach abgabetag aufsteigend
export const abgabemusterSort = (e1, e2) => (e1.tag - e2.tag)


export const dosierschemaSortByStart = (e1, e2) => {
    return e2.start - e1.start
}



/*
    gruppiert und sortiert dosierabschnitte
    - zuerst aktuelle (sortiert nach ende aufsteigend)
    - dann kommende (sortiert nach start aufsteigend)
    - dann vergangene (sortiert nach ende aufsteigend)
*/
export const dosierschemaSort = (e1, e2, referenzDatum) => {
    if (!referenzDatum) referenzDatum = heute;

    // e1 past / e2 future (and vice versa)
    if (
        (e1.ende !== null && e1.ende < referenzDatum)
        &&
        (e2.start !== null && e2.start > referenzDatum)
    ) {
        return 1;
    }


    if ((e1.start < referenzDatum) && (e2.ende > referenzDatum)) {
        return -1;
    }


    // e1 current / e2 isnt (and vice versa)
    if (
        !(e1.ende < referenzDatum || e1.start > referenzDatum)
        &&
        (e2.ende < referenzDatum || e2.start > referenzDatum)
    ) {
        return -1;
    }

    if (
        !(e2.ende < referenzDatum || e2.start > referenzDatum)
        &&
        (e1.ende < referenzDatum || e1.start > referenzDatum)
    ) {
        return 1;
    }

    //both in future, sort by start
    if (e1.start > referenzDatum && e2.start > referenzDatum) {
        return e1.start - e2.start;
    }



    //in all other cases sort by end descending
    return e1.ende - e2.ende;
}

export const getCurrentDosierabschnitt = (dosierschemaList, referenzDatum) => {
    if (!dosierschemaList?.length) return null;
    if (!referenzDatum) referenzDatum = heute;
    else referenzDatum = moment(referenzDatum).startOf('day');

    const sorted = dosierschemaList.sort((a,b) => dosierschemaSort(a, b, referenzDatum));
    const dosierabschnitt = sorted[0];

    if (
        (!dosierabschnitt.start || moment(dosierabschnitt.start).startOf('day').isSameOrBefore(referenzDatum)) &&
        (!dosierabschnitt.ende || moment(dosierabschnitt.ende).endOf('day').isAfter(referenzDatum))
    ) {
        return dosierabschnitt;
    }

    return null;
}




/*********************************************
    Formater
*********************************************/

/*
    formatiert stringdarstellung start und ende datum (quelle hier timestamp) und gibt zurück ob aktuelles datum im bereich ist
*/
export const daterangeToString = (start = null, ende = null) => {

    let istNichtZukunft = (start === null || moment(start).diff() <= 0)
    let istNichtVergangen = (ende === null || moment(ende).diff() >= 0)
    let istAktuell = istNichtZukunft && istNichtVergangen

    const startString = start ? moment(start).format("dd DD.MM.YYYY") : null
    const endeString = ende ? moment(ende).format("dd DD.MM.YYYY") : null

    let dateString = "unbekanntes Datum"
    if (startString && endeString)
        dateString = (istAktuell ? "seit " : "von ") + startString + " bis " + endeString

    else if (startString)
        dateString = "ab " + startString

    else if (endeString)
        dateString = "bis " + endeString

    if (!startString && !endeString)
        dateString = null;

    return [dateString, istNichtVergangen]

}

export const getDosierabschnittDateRangeString = (abschnitt, letzterAbschnitt=false) => {
    let [dateString] = daterangeToString(abschnitt.start, abschnitt.ende);

    if (!dateString) {
        switch (abschnitt.startTyp) {
            case 1:
                dateString = "ab Bestandseingang";
                break;
            case 2:
                dateString = "ab möglicher Verblisterung";
        }
    }

    switch (abschnitt.endeTyp) {
        case 2:
            dateString += " bis Anspruchsende";
    }

    return dateString + (abschnitt.ende && letzterAbschnitt ? " [danach abgesetzt]" : "");
}




/*
    formatiert stringdarstellung der menge (quelle hier float)
*/
export const mengeToString = (menge, opts) => {
    let faktor = opts?.faktor ?? 1.0,
        printZero = opts?.printZero ?? false,
        putSpace = opts?.putSpace ?? true;

    if (!menge && !printZero) return null;
    if (isNaN(menge)) return menge;
    if (isNaN(faktor)) return null;

    const mengeFloat = faktor * menge;
    let ganzzahl = Math.trunc(mengeFloat);
    let rest = mengeFloat - ganzzahl
    rest = Math.trunc(rest * 1000) / 1000;
    let restString = ""

    let skipZero = false;
    switch (Math.abs(rest)) {
        case 0:
            restString = ""
            break;

        case 0.25:
            skipZero = true;
            restString = "¼"
            break;

        case 0.5:
            skipZero = true;
            restString = "½"
            break;

        case 0.75:
            skipZero = true;
            restString = "¾"
            break;

        case 0.333:
            skipZero = true;
            restString = "⅓"
            break;

        case 0.666:
            skipZero = true;
            restString = "⅔"
            break;

        default:
            const roundNeu = Math.round(rest*100)/100;
            ganzzahl += Math.trunc(roundNeu);

            const slice = (""+roundNeu).slice(2);
            restString = slice ? "," + slice : "";
            putSpace = false;
    }

    if (!ganzzahl && !skipZero) ganzzahl = "0";
    return `${mengeFloat < 0 ? "-" : ""}${ganzzahl ? ganzzahl : ""}${ganzzahl && putSpace ? " " : ""}${restString}`;
}





export const parseWiederholungsTag = (wdhTag, abgabemuster) => {

    let tag, woche, monat = false
    if (wdhTag === 1 && abgabemuster.length < 2)
        tag = true
    else if (wdhTag === 7 && abgabemuster.length < 8)
        woche = true
    else if (wdhTag === 30 && abgabemuster.length < 31)
        monat = true

    return { tag, woche, monat }

}


/*
    ermittelt Abgabeschema (täglich, wochenplan, etc..) 
    sowie Einnahmeformat (minutengenau oder nach blisterfächern) und fügt passende fachnummer hinzu

    result beispiel:
    let faecherDarstellung = [
        {
            tag: 1,
            id: 617,
            isFaecher: false,
            zeiten: [
                {
                    fachNummer: 0,
                    menge: "0",
                    zeit: "08:00:00"
                },
                {
                    fachNummer: 1,
                    menge: "4",
                    zeit: "10:00:00"
                },
            ]
        }
    ]

*/
export const parseAbgabemuster = (abgabemuster = [], wdhTag = 1, musterTyp) => {

    let monat = false, tag = false, woche = false, faecher = false;
    let maxTag = 0;

    if (wdhTag === 1 && abgabemuster.length < 2)
        tag = true;
    else if (wdhTag === 7 && abgabemuster.length < 8)
        woche = true;
    else if (wdhTag === 99999)
        monat = true;


    // console.log("abgabemuster", abgabemuster)
    let faecherDarstellung = abgabemuster
        .sort(abgabemusterSort)
        .reduce((result, abgabeTag) => {

            let [istFaecher, zeitenMitFach, benutzteFaecher] = abgabeTag.zeiten ? abgabeTag.zeiten.reduce(

                ([istBisherFaecher, bisherigeZeiten, bisherGenutzteFaecher], einnahmeZeit) => {

                    if (!istBisherFaecher) {
                        return [false, [...bisherigeZeiten, einnahmeZeit], []]
                    }

                    let istImmernochFaecher = istBisherFaecher
                    let genutzteFaecher = bisherGenutzteFaecher

                    let fachNummer = einnahmeZeit.fachNummer;

                    if (!(fachNummer <= 0)) {
                        for (let FACH of EINNAHMEZEITEN_FUENFFACH)
                            if (FACH.zeitfenster.von <= einnahmeZeit.zeit
                                &&
                                FACH.zeitfenster.bis >= einnahmeZeit.zeit) {

                                fachNummer = FACH._id

                                // Fach ist "noch frei"
                                if (bisherGenutzteFaecher.indexOf(fachNummer) === -1) {
                                    genutzteFaecher.push(fachNummer)
                                }

                                // Fach ist "besetzt"
                                else {
                                    istImmernochFaecher = false
                                }

                                break
                            }


                        einnahmeZeit = {...einnahmeZeit, fachNummer};
                    }

                    return [istImmernochFaecher, [...bisherigeZeiten, einnahmeZeit], genutzteFaecher]
                }, [true, [], []]) : [true, [], []];

            let parsedAbgabeTag = {
                sollZeitgenau: abgabeTag.sollZeitgenau,
                zeiten: zeitenMitFach.sort(
                    (a, b) => b.zeit < a.zeit
                ),
                id: abgabeTag.id,
                tag: ['woche', 'monat'].includes(musterTyp) || result.length ? abgabeTag.tag : 1,
            }

            // validiere neuen Tag im Vergleich zu bereits eingetragenen Tagen
            if (result.length) {
                // prüfe, ob .tag <= wiederholungsTag
                if (wdhTag && abgabeTag.tag > wdhTag) return result;

                const popEmpty = musterTyp === "frei" ? 2 : 1;
                if (popEmpty && !abgabeTag.zeiten.length && result.length > popEmpty) {
                    let popThis = true;
                    for (let i=1; i<=popEmpty; i++) {
                        const entry = result[result.length - i];
                        if (entry.zeiten.length) {
                            popThis = false;
                            break;
                        }
                    }

                    if (popThis) return result;
                }

                else if (result[result.length - 1].tag !== parsedAbgabeTag.tag - 1) {
                    if (musterTyp !== "monat") {
                        result[result.length - 1].tagBis = parsedAbgabeTag.tag - 1;
                    }
                }
            }

            if (maxTag < abgabeTag.tag)
                maxTag = abgabeTag.tag

            return [...result, parsedAbgabeTag]
        }, []);

    // prüfe, ob eine Lücke zum Wiederholungstag exisitiert
    if (musterTyp === "frei" && wdhTag && faecherDarstellung[faecherDarstellung.length - 1] && wdhTag !== faecherDarstellung[faecherDarstellung.length - 1].tag) {
        const abgabeTag = faecherDarstellung[faecherDarstellung.length - 1];
        // es gibt Einnahmemengen => leere Zeitspanne nach diesem Tag bis Wiederholungstag erstellen
        if (abgabeTag.zeiten && abgabeTag.zeiten.length) {
            const neuerTag = {tag: abgabeTag.tag + 1, zeiten: [], sollZeitgenau: false, dummy: true};
            if (neuerTag.tag < wdhTag) neuerTag.tagBis = wdhTag;

            faecherDarstellung.push(neuerTag);
        }

        // es gibt keine Einnahmemengen => Zeitspanne bis Wiederholungstag ausweiten
        else {
            abgabeTag.tagBis = wdhTag;
        }
    }

    return { tag, woche, monat, maxTag, faecherDarstellung };
}

export const getMusterTyp = (dosierabschnitt) => {
    if (dosierabschnitt.freiesMuster) return "frei";
    else switch (dosierabschnitt.wiederholungsTag) {
        case 1:
            return "tag";
        case 7:
            return "woche";
        case 99999:
            return "monat";
        default:
            return "unbekannt";
    }
}

export const berechneBedarfProIntervall = (abgabemuster= [], wdhTag = 1, intervall = "Woche") => {
    const {tag, woche, monat, maxTag, faecherDarstellung} = parseAbgabemuster(abgabemuster, wdhTag);
    let mengeBedarf = {};

    switch (intervall) {
        case "Woche":
            if (!tag && !woche) return null;

            if (woche && abgabemuster) {
                for (let abgabe of abgabemuster) {
                    if (abgabe.zeiten) {
                        for (let i=0; i < abgabe.zeiten.length; i++) {
                            const zeit = abgabe.zeiten[i];
                            const m2String = zeit.arzneimittel ? zeit.arzneimittel.m2 : "000000000000";
                            const mengeZeit = zeit.faktor ? zeit.menge * zeit.faktor : zeit.menge;
                            const mengeCurrent = mengeBedarf[m2String] ? mengeBedarf[m2String] : 0;

                            mengeBedarf[m2String] = mengeCurrent + mengeZeit;
                        }
                    }
                }
            }

            else if (tag && abgabemuster[0] && abgabemuster[0].zeiten) {
                const length = abgabemuster[0].zeiten.length;
                if (length) for (let i=0; i < length; i++) {
                    const zeit = abgabemuster[0].zeiten[i];
                    const m2String = zeit.arzneimittel ? zeit.arzneimittel.m2 : "000000000000";
                    const mengeZeit = zeit.faktor ? parseFloat(zeit.menge) * zeit.faktor : parseFloat(zeit.menge);
                    const mengeCurrent = mengeBedarf[m2String] ? mengeBedarf[m2String] : 0;

                    mengeBedarf[m2String] = mengeCurrent + (mengeZeit * 7);
                }

                else mengeBedarf = {default: 0}
            }

            else return null;
            break;
    }

    return mengeBedarf;
}

export const getDosierungString = (abgabemuster= [], wdhTag = 1, intervall = "Woche") => {
    const {tag, woche, monat, maxTag, faecherDarstellung} = parseAbgabemuster(abgabemuster, wdhTag);
    const mengen = {};

    if (tag) for (let fachIndex=0; fachIndex<5; fachIndex++) if (abgabemuster[0] && abgabemuster[0].zeiten) for (let zeit of abgabemuster[0].zeiten) {
        if (zeit.fachNummer === fachIndex && zeit.menge && zeit.menge != 0) {
            const m2String = zeit.arzneimittel ? zeit.arzneimittel.m2 : "000000000000";
            if (!mengen[m2String]) mengen[m2String] = [0, 0, 0, 0, 0];

            let menge = mengen[m2String][fachIndex];
            menge += zeit.faktor ? parseFloat(zeit.menge) * zeit.faktor : parseFloat(zeit.menge);

            mengen[m2String][fachIndex] = menge;
        }
    }
    else return null;

    const result = {}
    for (let m2String of Object.keys(mengen)) result[m2String] = mengen[m2String].map(menge => mengeToString(menge, {faktor: 1, printZero: true, putSpace: false})).join(" ");
    return result;
}





export const useParseAbgabemuster = (abschnitt = null) => {

    const [tag, setTag] = useState(false)
    const [woche, setWoche] = useState(false)
    const [monat, setMonat] = useState(false)
    const [abgabemuster, setAbgabemuster] = useState([])


    useEffect(() => {
        const { tag: t, woche: w, monat: m, faecherDarstellung: a } = parseAbgabemuster(abschnitt.abgabemuster, abschnitt.wiederholungsTag)
        setTag(t)
        setWoche(w)
        setMonat(m)
        setAbgabemuster(a)
    }, [abschnitt])


    return { tag, woche, monat, abgabemuster }

}