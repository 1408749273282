export const getTeilbarkeitString = medicineM2 => {
    if (medicineM2) switch (medicineM2.divideable) {
        case "1":
            return "nicht teilbar";
        case "2":
            return "halbierbar";
        case "3":
            return "drittelbar";
        case "4":
            return "viertelbar";
        case "6":
            return "sechstelbar";
    }

    return "keine Daten zur Teilbarkeit";
}

export const checkTeilbarkeit = (medicineM2, faktor) => {
    if (!medicineM2 || !faktor) return false;

    const mod = medicineM2.divideable % Math.floor(faktor);
    return mod === 0;
}

export const checkTeilbarkeitAbgabezeit = ({ abgabeZeit, menge, planzeile, api }) => {
    if (!menge) menge = parseFloat(abgabeZeit.menge);
    if (abgabeZeit.faktor && !isNaN(abgabeZeit.faktor)) {
        menge = abgabeZeit.faktor * menge;
    }

    const teilmenge = menge - Math.floor(menge);
    if (teilmenge > 0) {
        const m2String = abgabeZeit.arzneimittel?.m2 || planzeile?.arzneimittel?.m2;
        const medicineM2 = m2String ? api.getMedicineM2Sync(m2String, true) : null;

        if (medicineM2 && !checkTeilbarkeit(medicineM2, 1 / teilmenge)) {
            return false;
        }
    }

    return true;
}

export const getMedicineM2Name = (medicineM2, printHersteller=true, printDrf=true) => {
    if (!medicineM2) return null;

    let resultString = medicineM2.name;

    if (printHersteller && medicineM2.supplierShort)
        resultString += ` (${medicineM2.supplierShort})`;

    if (printDrf && medicineM2.drfShort)
        resultString += ` ${medicineM2.drfShort}`;

    return resultString;
}