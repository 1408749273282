import React, {useEffect, useRef, useState} from "react";
import {useApi} from "../../utilities/useApi";
import {getUnitLangname} from "../../config/entities";

const UnitLangname = ({unit: unitProp, unitId}) => {
    const api = useApi();
    
    const [unit, setUnit] = useState(null);
    const unitPropRef = useRef(null);
    
    useEffect(() => {
        if (!unit) {
            if (unitProp) {
                setUnit(unitProp);
            } else if (unitId) {
                if (unitId in api.akteureIdToIndexMap) {
                    setUnit(api.akteure[api.akteureIdToIndexMap[unitId]]);
                } else {
                    api.loadAkteur(unitId).then(setUnit);
                }
            }
        } else if (unitProp) {
            const unitJsonString = JSON.stringify(unitProp);
            if (unitJsonString !== unitPropRef.current) {
                setUnit(unitProp);
            }
        }
        
        unitPropRef.current = JSON.stringify(unitProp);
    }, [unitProp])
    
    return <pre>{unit ? getUnitLangname(unit) : unitId ? `Akteur #${unitId}` : "K/A"}</pre>
}

export default UnitLangname;