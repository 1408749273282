import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, DatePicker, Input, notification, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {HotKeys} from "react-hotkeys";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {AkteurPicker} from "../atoms/AkteurPicker";
import {callApiAsync} from "../../utilities/apiUtil";
import {notizApi, planzeilenApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import NotizContext from "../../contexts/NotizContext";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import {Tooltip} from "../atoms/Tooltip";
import WindowContext from "../../contexts/WindowContext";
import * as moment from "moment";
import useNavigation from "../../utilities/useNavigation";
import Moveable from "react-moveable";
import locale_de from "antd/es/date-picker/locale/de_DE";
import Checkbox from "antd/es/checkbox/Checkbox";
import {useApi} from "../../utilities/useApi";
import {GlobalContext} from "../../config/globalContext";
import {PrinterOutlined} from "@ant-design/icons";
import {getUnitNameDateOfBirthConcat} from "../../config/entities";

const NotizModal = ({cardId}) => {
    const CARD_ID = cardId;

    const globalcontext = useContext(GlobalContext);
    const windowContext = useContext(WindowContext)
    const notizContext = useContext(NotizContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const auth = useAuth();
    const navigation = useNavigation();
    const api = useApi();

    const moveableRef = useRef();
    const parentRef = useRef();
    const isFocused = useRef(false);

    const [movableTarget, setMovableTarget] = useState(null);
    const [movableFrame, setMovableFrame] = useState({translate: [0, 0]});
    const [dragTarget, setDragTarget] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [skipSaveOnClose, setSkipSaveOnClose] = useState(false);

    const [inputVal, setInputVal] = useState({});

    const [unitSet, setUnitSet] = useState([]);
    const [interaktion, setInteraktion] = useState({});
    const [type, setType] = useState("UNIT");

    const [isPatientMap, setIsPatientMap] = useState({});

    useEffect(() => {
        isFocused.current = windowContext.activeId === CARD_ID;
    }, [windowContext.activeId])

    useEffect(() => {
        let notizInteraktion;

        const inputValNeu = {};
        if (notizContext.notiz?.text) inputValNeu.text = notizContext.notiz.text;
        if (notizContext.notiz?.titel) inputValNeu.titel = notizContext.notiz.titel;
        if (!notizContext.notiz.id && Object.keys(inputValNeu).length) inputValNeu.__changed__ = true;

        const notiz = notizContext.notiz;
        switch (notizContext.notiz?.type) {
            case "INTERAKTION":
                notizInteraktion = notiz.interaktionSet?.[0] || {};
                setInteraktion(notizInteraktion);

                if (notizInteraktion?.faelligkeit) inputValNeu.faelligkeit = moment(notizInteraktion?.faelligkeit);
                if (notizInteraktion?.gueltigVon) inputValNeu.gueltigVon = moment(notizInteraktion?.gueltigVon);
                if (notizInteraktion?.gueltigBis) inputValNeu.gueltigBis = moment(notizInteraktion?.gueltigBis);
                if (notizInteraktion?.bearbeitet) inputValNeu.bearbeitet = !!notizInteraktion?.bearbeitet;
                if (notizInteraktion?.anzeigenBeiAufnahme) inputValNeu.anzeigenBeiAufnahme = !!notizInteraktion?.anzeigenBeiAufnahme;
                if (notizInteraktion?.anzeigenBeiVerblisterung) inputValNeu.anzeigenBeiVerblisterung = !!notizInteraktion?.anzeigenBeiVerblisterung;
                if (notizInteraktion?.anzeigenBeiAnforderung) inputValNeu.anzeigenBeiAnforderung = !!notizInteraktion?.anzeigenBeiAnforderung;
                break;
            case "UNIT":
                let unitSetNeu = notiz.unitSet || [];
                if (!unitSetNeu.length) unitSetNeu.push({ id: { notizId: notiz.id, unitId: 0 } });
                else if (unitSetNeu.length > 1 && !!unitSetNeu.find(e => e.id.unitId === 0)) {
                    inputValNeu.__changed__ = true;
                    unitSetNeu = unitSetNeu.filter(e => e.id.unitId !== 0);
                }
                setUnitSet(unitSetNeu.sort((a, b) => a.id.unitId - b.id.unitId));
                break;
            default:
                setUnitSet([]);
                setInteraktion({});
        }

        setInputVal(inputValNeu);
        setType(notizContext.notiz?.type || "UNIT");
    }, [notizContext.notiz])

    useEffect(() => {
        switch (type) {
            case "UNIT":
                const unitId = medikationsplanContext.medikationsplan?.patient?.id || 0;
                if (!unitSet.length) {
                    setUnitSet([{ dummy: true, id: { unitId }, gueltigVon: globalcontext.heute?.valueOf(), planzeilen: medikationsplanContext.planzeileSelected ? [{id: {unitId, planzeileId: medikationsplanContext.planzeileSelected}}] : [] }]);
                } else if (unitSet.length === 1) {
                    if (unitId && unitSet[0].id.unitId === 0) {
                        setUnitSet((prev => [{...prev[0], id: {...prev[0].id, unitId}}]));
                    }
                }
                break;
        }
    }, [type, unitSet, medikationsplanContext.medikationsplan])

    useEffect(() => {
        if (medikationsplanContext.planzeileSelected && (unitSet[0]?.dummy)) {
            const unitId = medikationsplanContext.medikationsplan?.patient?.id || 0;
            setUnitSet(prev => {
                return [{...prev[0], planzeilen: [{id: {unitId, planzeileId: medikationsplanContext.planzeileSelected}}]}];
            });
        }
    }, [medikationsplanContext.planzeileSelected])

    useEffect(() => {
        if (notizContext.visible) {
            windowContext.setActiveId(CARD_ID);
            resetMoveable();
        } else {
            setSkipSaveOnClose(false);
            setMovableTarget(null);
            setDragTarget(null);

            if (windowContext.activeId === CARD_ID) {
                windowContext.setActiveId(null);
            }

            resetNotiz();
        }
    }, [notizContext.visible])

    useEffect(() => {
        if (movableTarget) {
            const rect = moveableRef.current.getRect();
            const win = window,
                doc = document,
                docElem = doc.documentElement,
                body = doc.getElementsByTagName('body')[0],
                viewPortWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                viewportHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

            const x = ((viewPortWidth - rect.width) / 2);
            const y = ((viewportHeight - rect.height) / 4) + window.scrollY;

            const targetHeight = 150;
            const deltaHeight = targetHeight - rect.height;

            moveableRef.current.request("draggable", {x, y}, true);
            moveableRef.current.request("resizable", {deltaHeight}, true);
        }

        checkMoveableHeight();
    }, [movableTarget])

    useEffect(() => {
        checkMoveableHeight();
    }, [collapsed])

    useEffect(() => {
        if (medikationsplanContext.medikationsplan) {
            notizContext.setAkteur(medikationsplanContext.medikationsplan.patient);
            notizContext.ref.settingMedikationsplan.current = true;

            setUnitSet([]);
        } else {
            notizContext.setAkteur(null);
        }
    }, [medikationsplanContext.medikationsplan])

    const checkMoveableHeight = () => {
        const rect = moveableRef.current.getRect();
        const targetHeight = rect.height;

        const cardBody = document.querySelector(`#${CARD_ID} .card-body`);
        const scrollHeight = cardBody.scrollHeight;
        if (scrollHeight > targetHeight) {
            moveableRef.current.request("resizable", {deltaHeight: scrollHeight - targetHeight}, true);
        }
    }

    const resetNotiz = () => {
        notizContext.setNotiz({});
    }

    const resetMoveable = () => {
        const cardSelector = document.querySelector(`#${CARD_ID}`);
        setMovableTarget(cardSelector);
        setDragTarget(cardSelector.querySelector(".draggable-target"));
    }

    const updateNotiz = (notiz) => {
        notizContext.setNotizSavedLatest(notiz);

        notizContext.setNotizMap(prev => {
            const neu = {...prev};

            for (let e of notiz?.unitSet || []) {
                neu[e.id.unitId] = {...(neu[e.id.unitId] || {}), [notiz.id]: notiz};
            }

            for (let e of notiz?.interaktionSet || []) {
                neu[e.id.unitId] = {...(neu[e.id.unitId] || {}), [notiz.id]: notiz};
            }

            return neu;
        });
    }

    const removeNotiz = (notiz) => {
        const unitIdSet = notiz.unitSet.map(u => u.id.unitId);
        notizContext.setNotizMap(prev => {
            for (let unitId of unitIdSet) {
                const neuUnit = {...prev[unitId]};
                delete neuUnit[notiz.id];
                return {...prev, [unitId]: neuUnit};
            }
        });
    }

    const onDelete = async (notiz) => {
        const response = await callApiAsync({auth, url: notizApi.delete(notiz.id), method: "delete"});
        removeNotiz(notiz);

        const notizNeu = {...notiz};
        delete notizNeu.id;
        for (let e of [...(notizNeu.unitSet || []), ...(notizNeu.interaktionSet || [])]) {
            delete e.id.notizId;
        }

        notizContext.setNotiz(notizNeu);
        setSkipSaveOnClose(true);

        notification.info({message: "Die Notiz wurde erfolgreich gelöscht"});
    }

    const performSave = async () => {
        if (inputVal.__changed__ && inputVal.titel) {
            let data;

            switch (type) {
                case "UNIT":
                    data = {
                        ...notizContext.notiz,
                        unitSet: unitSet,

                        titel: inputVal.titel,
                        text: inputVal.text,
                    };
                    break;
                case "INTERAKTION":
                    const notizInteraktion = {
                        id: interaktion.id,

                        faelligkeit: inputVal.faelligkeit ? inputVal.faelligkeit.valueOf() : null,
                        gueltigVon: inputVal.gueltigVon ? inputVal.gueltigVon.valueOf() : null,
                        gueltigBis: inputVal.gueltigBis ? inputVal.gueltigBis.valueOf() : null,
                        bearbeitet: !!inputVal.bearbeitet,
                        anzeigenBeiAufnahme: !!inputVal.anzeigenBeiAufnahme,
                        anzeigenBeiVerblisterung: !!inputVal.anzeigenBeiVerblisterung,
                        anzeigenBeiAnforderung: !!inputVal.anzeigenBeiAnforderung,
                    };

                    data = {
                        ...notizContext.notiz,
                        interaktionSet: [notizInteraktion],

                        titel: inputVal.titel,
                        text: inputVal.text,
                    };
                    break;
            }

            if (data) {
                const response = await callApiAsync({url: notizApi.post(), method: "post", data, auth});

                const dataNeu = {...data, ...response.data.OBJECT};
                notizContext.setNotiz(dataNeu);
                updateNotiz(dataNeu);

                notification.info({message: "Die Notiz wurde gespeichert"});
                return dataNeu;
            }
        }

        return notizContext.notiz;
    }

    const onSave = async () => {
        await performSave();
        notizContext.setVisible(false);
    }

    const onChange = (key, value) => {
        setInputVal(prev => ({...prev, __changed__: true, [key]: value}))
    }

    const onChangeNotizUnit = (val, type) => {
        let entry;
        switch (type) {
            case "unit":
                setInputVal(prev => ({...prev, __changed__: true}));

                if (!val?.neu) {
                    setUnitSet(prev => {
                        const neu = prev.filter(e => e.id.unitId !== val?.alt || 0);
                        if (!neu.length) neu.push({id: {notizId: notizContext.notiz.id, unitId: 0}, gueltigVon: globalcontext.heute?.valueOf()});

                        return neu;
                    });
                } else {
                    setUnitSet(prev => {
                        const neu = [...prev];
                        const entry = neu.find(e => e.id.unitId === (val?.alt || 0));
                        if (entry) {
                            entry.id.unitId = val.neu;
                            entry.planzeilen = [];
                        } else {
                            neu.push({
                                id: {
                                    notizId: notizContext.notiz.id,
                                    unitId: val.neu
                                },
                                planzeilen: [],
                            });
                        }

                        return neu.sort((a, b) => a.id.unitId - b.id.unitId);
                    });
                }
                break;
            case "planzeile":
                setInputVal(prev => ({...prev, __changed__: true}));

                entry = unitSet.find(e => e.id.unitId === val?.unitId);
                if (entry) {
                    let planzeilen;
                    if (val.neu) {
                        planzeilen = [...(entry.planzeilen || []).filter(e => e.id.planzeileId !== val.neu && e.id.planzeileId !== val?.alt), { id: { notizId: notizContext.notiz.id, unitId: val.unitId, planzeileId: val.neu } }];
                    } else {
                        planzeilen = (entry.planzeilen || []).filter(e => e.id.planzeileId !== val?.alt);
                    }

                    setUnitSet(prev => [...prev.filter(e => e.id.unitId !== val.unitId), {...entry, planzeilen}].sort((a, b) => a.id.unitId - b.id.unitId));
                }
                break;
            case "gueltigkeit":
                setInputVal(prev => ({...prev, __changed__: true}));
                setUnitSet(prev => {
                    let entry = unitSet.find(e => e.id.unitId === val?.unitId);
                    if (entry) {
                        const neu = [...prev.filter(e => e.id.unitId !== val.unitId), {...entry, gueltigVon: val.neu?.[0]?.startOf('day')?.valueOf() || null, gueltigBis: val.neu?.[1]?.startOf('day')?.valueOf() || null}];
                        return neu.sort((a, b) => a.id.unitId - b.id.unitId);
                    }

                    return prev;
                });
                break;
            case "bearbeitet":
            case "faelligkeit":
            case "anzeigenBeiAufnahme":
            case "anzeigenBeiVerblisterung":
            case "anzeigenBeiAnforderung":
                setInputVal(prev => ({...prev, __changed__: true}));
                setUnitSet(prev => {
                    let entry = unitSet.find(e => e.id.unitId === val?.unitId);
                    if (entry) {
                        const neu = [...prev.filter(e => e.id.unitId !== val.unitId), {...entry, [type]: val.neu}];
                        return neu.sort((a, b) => a.id.unitId - b.id.unitId);
                    }

                    return prev;
                });
                break;
        }
    }

    const isPatient = (unitId) => {
        if (unitId > 0) {
            if (isPatientMap[unitId]) {
                return isPatientMap[unitId];
            } else {
                api.loadAkteur(unitId).then(akteur => isPatientMap[unitId] = (akteur.roles || []).find(role => role.name === "Patient"));
            }
        }

        return false;
    }

    const onPrint = async () => {
        if (notizContext.notiz) {
            const notiz = await performSave();

            const getEntryStrings = (entry) => {
                const gueltigkeit = `${entry.gueltigVon ? 'ab ' + moment(entry.gueltigVon).format("DD.MM.YYYY") : ''}${entry.gueltigVon && entry.gueltigBis ? ' ' : ''}${entry.gueltigBis ? 'bis ' + moment(entry.gueltigBis).format("DD.MM.YYYY") : ''}`;
                const faelligkeit = entry.faelligkeit ? moment(entry.faelligkeit).format("DD.MM.YYYY") : '';
                const bearbeitet = entry.bearbeitet ? 'ja' : 'nein'
                const anzeigenBei = ['Aufnahme', 'Verblisterung', 'Anforderung'].filter(str => !!entry['anzeigenBei'+str]).join(', ');

                const stringList = []
                if (gueltigkeit) stringList.push('Gültigkeit: ' + gueltigkeit);
                if (faelligkeit) stringList.push('Fälligkeit: ' + faelligkeit);
                stringList.push('bearbeitet: ' + bearbeitet);
                if (anzeigenBei) stringList.push('anzeigen bei: ' + anzeigenBei);

                return stringList.join('; ')
            }

            const getUnits = async () => {
                const unitMap = new Map();
                const planzeileMap = new Map();

                if (unitSet.length) {
                    const promiseUnitList = []
                    const promisePlanzeileList = []

                    for (const entry of unitSet) {
                        if (entry.id.unitId === 0) continue;

                        const promiseUnit = api.loadAkteur(entry.id.unitId);

                        if (entry.planzeilen.length) {
                            for (const entryPz of entry.planzeilen) {
                                const url = planzeilenApi.getById(entryPz.id.planzeileId, true);
                                const promisePlanzeile = callApiAsync({url, auth});
                                promisePlanzeile.then(res => {
                                    const planzeile = res.data.OBJECT;
                                    planzeileMap.set(planzeile.id, planzeile)
                                });
                                promisePlanzeileList.push(promisePlanzeile);
                            }
                        }

                        promiseUnit.then(unit => unitMap.set(entry.id.unitId, unit));
                        promiseUnitList.push(promiseUnit);
                    }

                    await Promise.all(promiseUnitList);
                    await Promise.all(promisePlanzeileList);
                }

                return [unitMap, planzeileMap];
            }

            const [unitMap, planzeileMap] = await getUnits();
            const win = window.frames["printf"];
            win.document.write(`
                <body onload="window.print()">
                    <h3>${notiz.titel}</h3>
                    
                    ${!!notiz.dateErstellt ? `<span style='line-height: 0; color: darkgrey; font-size: small'>
                        ${!!notiz.id ? `#${notiz.id} - ` : ''}
                        Erstellt am ${moment(notiz.dateErstellt).format("DD.MM.YYYY")} von ${notiz.accountErstellt}
                        ${!!notiz.accountZuletztBearbeitet ? ` - zuletzt bearbeitet am ${moment(notiz.dateZuletztBearbeitet).format("DD.MM.YYYY")} von ${notiz.accountZuletztBearbeitet}` : ''}
                    </span>` : ''}
                    
                    <p>${type === "INTERAKTION" ? getEntryStrings(inputVal) : type === "UNIT" ?
                        unitSet.length ? `${unitSet.length ? `
                            <ul>${unitSet.map((entry) => {
                                let unitNameString = getUnitNameDateOfBirthConcat(unitMap.get(entry.id.unitId));
                                if (unitNameString) unitNameString += '; '
                            
                                return `
                                    <li>
                                        ${unitNameString}
                                        ${getEntryStrings(entry)}
                                        ${entry.planzeilen.length ? `
                                            <ul>${entry.planzeilen.map((entryPz) => {
                                                return `<li>${getArzneimittelLangname(planzeileMap.get(entryPz.id.planzeileId)?.arzneimittel, "Planzeile #" + entryPz.id.planzeileId)}</li>`
                                            })}</ul>
                                        ` : ''}
                                    </li>
                                `
                            })}</ul>` : '[keine Verknüpfungen eingetragen]'}`
                        : ''
                    : ''}</p>
                    
                    <p>${notiz.text ? notiz.text.split('\n').join('<br/>') : '[kein Text eingetragen]'}</p>
                </body>
            `)
            win.document.close();
        }
    }

    return <div className={"card-container"} style={{position: "absolute", top: 0, left: 0, width: "100%", height: 0, display: notizContext.visible ? "block" : "none", zIndex: windowContext.activeId === CARD_ID ? 1 : 0}}>
        <div
            ref={parentRef}
            id={CARD_ID}
            onClick={() => windowContext.setActiveId(CARD_ID)}
            style={{position: "absolute", display: "flex", flexDirection: "column", width: "50%", height: 355, backgroundColor: "#fff"}}
        >
            <div style={{display: "flex", flexDirection: "column", overflow: "hidden", flex: 1}}>
                <div className={"card-body"} style={{display: "flex", flexDirection: "column", flex: 1, padding: 10}}>
                    <Col className={"draggable-target"} style={{textAlign: "center", verticalAlign: "middle", cursor: "move"}}>
                        <span aria-label={"close"} style={{position: "absolute", top: 0, right: 0, lineHeight: 0, padding: 10, cursor: "pointer"}} onClick={e => { if (!skipSaveOnClose) onSave(); else notizContext.setVisible(false); }}><Tooltip title={"Notiz schließen"}><CloseOutlined /></Tooltip></span>
                        {/*<span aria-label={"collapse"} style={{position: "absolute", top: 10, left: 0, lineHeight: 0, padding: 10}} onClick={e => setCollapsed(!collapsed)}>*/}
                        {/*    {collapsed ? <CaretRightOutlined /> : <CaretDownOutlined />}*/}
                        {/*</span>*/}
                        <h3>Notiz {!!notizContext.notiz.id ? "bearbeiten" : "erstellen"}</h3>
                        {!!notizContext.notiz.dateErstellt && <span style={{lineHeight: 0, color: "darkgrey"}}>
                            {!!notizContext.notiz.id && `#${notizContext.notiz.id} - `}
                            Erstellt am {moment(notizContext.notiz.dateErstellt).format("DD.MM.YYYY")} von {notizContext.notiz.accountErstellt}

                            {!!notizContext.notiz.accountZuletztBearbeitet && ` - zuletzt bearbeitet am ${moment(notizContext.notiz.dateZuletztBearbeitet).format("DD.MM.YYYY")} von ${notizContext.notiz.accountZuletztBearbeitet}`}
                        </span>}
                    </Col>

                    <Row style={{flex: 0}}>
                        <Col span={24} style={{display: "flex"}}>
                            <Tooltip title={"Titel"}><Input disabled={type === "INTERAKTION"} placeholder={"Titel"} value={inputVal.titel} onChange={e => {
                                onChange("titel", e.target.value);
                            }} /></Tooltip>
                            {type === "INTERAKTION" && <Tooltip title={"Fälligkeit"}><DatePicker
                                placeholder={"Fälligkeit"}
                                value={inputVal.faelligkeit}
                                onChange={neu => onChange("faelligkeit", neu ? neu.startOf('day') : null)}
                                format={"DD.MM.YYYY"}
                            /></Tooltip>}
                        </Col>
                    </Row>
                    <Row style={{flex: 1}}>
                        <Col span={24}>
                            <HotKeys keyMap={{SAVE: "ctrl+enter", SELECT_ALL: "ctrl+a"}} handlers={{SAVE: e => onSave()}} style={{height: "100%"}}>
                                <TextArea placeholder={"Inhalt der Notiz"} onChange={e => onChange("text", e.target.value)} value={inputVal.text} style={{minHeight: 150, height: "100%", resize: "none"}} />
                            </HotKeys>
                        </Col>
                    </Row>
                    {type === "INTERAKTION" && <Row style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        <Tooltip title={"Gültigkeit"}>
                            <DatePicker.RangePicker
                                value={[inputVal.gueltigVon, inputVal.gueltigBis]}
                                onCalendarChange={range => {
                                    if (range) {
                                        onChange("gueltigVon", range[0]);
                                        onChange("gueltigBis", range[1]);
                                    } else {
                                        onChange("gueltigVon", null);
                                        onChange("gueltigBis", null);
                                    }
                                }}

                                format={"DD.MM.YYYY"}

                                locale={locale_de}
                                allowEmpty
                            />
                        </Tooltip>

                        <Tooltip title={"Bearbeitet"} style={{marginLeft: "auto"}}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.bearbeitet} onChange={e => {
                                onChange("bearbeitet", e.target.checked);
                            }} /> bearbeitet</span>
                        </Tooltip>

                        <span>
                            <span>Anzeigen bei: </span>
                            <Tooltip title={"Anzeigen bei Aufnahme"} style={{marginLeft: "auto"}}>
                                <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiAufnahme} onChange={e => {
                                    onChange("anzeigenBeiAufnahme", e.target.checked);
                                }} />Aufnahme</span>
                            </Tooltip>
                            <Tooltip title={"Anzeigen bei Verblisterung"} style={{marginLeft: "auto"}}>
                                <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiVerblisterung} onChange={e => {
                                    onChange("anzeigenBeiVerblisterung", e.target.checked);
                                }} />Verblisterung</span>
                            </Tooltip>
                            <Tooltip title={"Anzeigen bei Anforderung"} style={{marginLeft: "auto"}}>
                                <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiAnforderung} onChange={e => {
                                    onChange("anzeigenBeiAnforderung", e.target.checked);
                                }} />Anforderung</span>
                            </Tooltip>
                        </span>

                        {
                            medikationsplanContext.medikationsplan?.patient?.id !== interaktion.id.unitId &&
                            isPatient(interaktion.id.unitId) &&
                            <a style={{marginRight: 10}} onClick={() => navigation.gotoMedikationsplan({id: interaktion.id.unitId})}>Medikationsplan öffnen</a>
                        }
                    </Row>}
                    {type === "UNIT" && unitSet.map((e, i) => <PlanzeileUnit key={`${notizContext.notiz.id || 0}:${e.id.unitId}`} value={e} resetMoveable={checkMoveableHeight} onChange={onChangeNotizUnit} />)}
                    <Row style={{marginTop: 10}}>
                        <Col span={12}>
                            <Button type={"primary"} onClick={onSave} disabled={!inputVal["__changed__"]}>Notiz speichern</Button>
                            <Button onClick={onPrint} ><Tooltip title={'Notiz drucken'}><PrinterOutlined /></Tooltip></Button>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>
                            {!!notizContext.notiz.id && <Button type={"primary"} danger onClick={() => onDelete(notizContext.notiz)}>Notiz löschen</Button>}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

        <Moveable
            ref={moveableRef}
            className={"moveable-" + CARD_ID}
            target={movableTarget}
            origin={false}
            translateZ={windowContext.activeId === CARD_ID ? 1 : -1}

            draggable={true}
            dragTarget={dragTarget}
            throttleDrag={0}
            onDragStart={({ set }) => {
                set(movableFrame.translate);
            }}
            onDrag={({ target, beforeTranslate }) => {
                movableFrame.translate = beforeTranslate;
                target.style.transform
                    = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
            }}
            onDragEnd={({ target, isDrag, clientX, clientY }) => {

            }}

            resizable={true}
            throttleResize={0}
            keepRatio={false}
            onResizeStart={({ target, set, setOrigin, dragStart }) => {
                // Set origin if transform-orgin use %.
                setOrigin(["%", "%"]);

                // If cssSize and offsetSize are different, set cssSize. (no box-sizing)
                const style = window.getComputedStyle(target);
                const cssWidth = parseFloat(style.width);
                const cssHeight = parseFloat(style.height);
                set([cssWidth, cssHeight]);

                // If a drag event has already occurred, there is no dragStart.
                dragStart && dragStart.set(movableFrame.translate);
            }}
            onResize={({ target, width, height, drag }) => {
                target.style.width = `${width}px`;
                target.style.height = `${height}px`;

                // get drag event
                movableFrame.translate = drag.beforeTranslate;
                target.style.transform
                    = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;

                const offsetHeight = document.querySelector(`#${CARD_ID} .card-body`).offsetHeight;
                if (offsetHeight > height) {
                    target.style.height = `${offsetHeight}px`;
                }
            }}
            onResizeEnd={({ target, isDrag, clientX, clientY }) => {

            }}
        />
    </div>
}
export default NotizModal;

export const PlanzeileUnit = ({value, resetMoveable=()=>{}, onChange=(val)=>{}}) => {
    const auth = useAuth();
    const api = useApi();
    const navigation = useNavigation();

    const notizContext = useContext(NotizContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const globalContext = useContext(GlobalContext);

    const [inputVal, setInputVal] = useState({});
    const [unit, setUnit] = useState(null);
    const [planzeilenAusgewaehlt, setPlanzeilenAusgewaehlt] = useState([]);
    const [planzeilenAusgewaehltVerfuegbar, setPlanzeilenAusgewaehltVerfuegbar] = useState([]);
    const [planzeilenAusgewaehltNichtVerfuegbar, setPlanzeilenAusgewaehltNichtVerfuegbar] = useState([]);
    const [planzeilenVerfuegbar, setPlanzeilenVerfuegbar] = useState([]);
    const [planzeilenNichtVerfuegbar, setPlanzeilenNichtVerfuegbar] = useState([]);
    const [showNichtVerfuegbar, setShowNichtVerfuegbar] = useState(false);
    const [planzeilenNichtVerfuegbarIds, setPlanzeilenNichtVerfuegbarIds] = useState([]);
    const [planzeilenVerfuegbarIds, setPlanzeilenVerfuegbarIds] = useState([]);
    const [isPatient, setIsPatient] = useState(false);

    const [loadingFinished, setLoadingFinished] = useState(false);

    useEffect(() => {
        if (notizContext.visible && notizContext.notiz?.id === value.id?.notizId) {
            if (unit?.id !== value.id.unitId) {
                if (value.id.unitId > 0) {
                    const promiseList = [];
                    api.loadAkteur(value.id.unitId).then(setUnit);
                    promiseList.push(callApiAsync({auth, url: planzeilenApi.getByFilter({patientId: value.id.unitId})}).then(res => {
                        const heute = medikationsplanContext.medikationsplan?.patient?.id === value.id.unitId ? moment(medikationsplanContext.anzeigeDatum) : globalContext.heute;

                        const planzeilen = res.data.OBJECT;
                        const planzeilenAvailable = [];
                        const planzeilenAvailableIds = [];
                        const planzeilenUnavailable = [];
                        const planzeilenUnavailableIds = [];
                        for (let pz of planzeilen) {
                            if (medikationsplanContext.planzeileSelected === pz.id || !pz.datumAbsetzung || moment(pz.datumAbsetzung).startOf('day').isAfter(heute)) {
                                planzeilenAvailable.push(pz);
                                planzeilenAvailableIds.push(pz.id);
                            } else {
                                planzeilenUnavailable.push(pz);
                                planzeilenUnavailableIds.push(pz.id);
                            }
                        }

                        setPlanzeilenVerfuegbar(planzeilenAvailable);
                        setPlanzeilenVerfuegbarIds(planzeilenAvailableIds);

                        setPlanzeilenNichtVerfuegbar(planzeilenUnavailable);
                        setPlanzeilenNichtVerfuegbarIds(planzeilenUnavailableIds);
                    }));

                    Promise.all(promiseList).then(() => setLoadingFinished(true));
                }
            }

            setPlanzeilenAusgewaehlt((value.planzeilen || []).map(p => p.id.planzeileId));

            const inputValNeu = {};
            if (value?.faelligkeit) inputValNeu.faelligkeit = moment(value?.faelligkeit);
            if (value?.gueltigVon) inputValNeu.gueltigVon = moment(value?.gueltigVon);
            if (value?.gueltigBis) inputValNeu.gueltigBis = moment(value?.gueltigBis);
            if (value?.bearbeitet) inputValNeu.bearbeitet = !!value?.bearbeitet;
            if (value?.anzeigenBeiAufnahme) inputValNeu.anzeigenBeiAufnahme = !!value?.anzeigenBeiAufnahme;
            if (value?.anzeigenBeiVerblisterung) inputValNeu.anzeigenBeiVerblisterung = !!value?.anzeigenBeiVerblisterung;
            if (value?.anzeigenBeiAnforderung) inputValNeu.anzeigenBeiAnforderung = !!value?.anzeigenBeiAnforderung;
            setInputVal(inputValNeu);
        }
    }, [value, medikationsplanContext.anzeigeDatum, notizContext.visible])

    useEffect(() => {
        const pzVerfuegbar = [];
        const pzNichtVerfuegbar = [];
        for (let pzId of planzeilenAusgewaehlt) {
            if (planzeilenVerfuegbarIds.includes(pzId)) {
                pzVerfuegbar.push(pzId);
            } else if (planzeilenNichtVerfuegbarIds.includes(pzId)) {
                pzNichtVerfuegbar.push(pzId);
            }
        }

        setPlanzeilenAusgewaehltVerfuegbar(pzVerfuegbar);
        setPlanzeilenAusgewaehltNichtVerfuegbar(pzNichtVerfuegbar);
    }, [planzeilenAusgewaehlt, planzeilenVerfuegbarIds, planzeilenNichtVerfuegbarIds])

    useEffect(() => {
        if (loadingFinished) {
            setLoadingFinished(false);
            resetMoveable();
        }
    }, [loadingFinished])

    useEffect(() => {
        resetMoveable();
    }, [planzeilenAusgewaehlt])

    useEffect(() => {
        setIsPatient(!!unit && (unit.roles || []).find(role => role.name === "Patient"));
    }, [unit])

    if (!unit && value.id.unitId > 0) return <></>
    return <Row style={{flex: 0, verticalAlign: "top"}}>
        <Col span={12}>
            <b>Akteur:</b> {
            (unit && (!medikationsplanContext.medikationsplan || medikationsplanContext.medikationsplan.patient.id !== unit.id)) &&
            isPatient &&
            <a onClick={() => navigation.gotoMedikationsplan(unit)}>Medikationsplan öffnen</a>}

            <AkteurPicker value={unit} onChange={(val) => {
                if (val?.id !== unit?.id) {
                    setInputVal(prev => ({...prev, __changed__: true}));

                    setUnit(val);
                    setPlanzeilenAusgewaehlt([]);
                    onChange({neu: val?.id, alt: unit?.id}, "unit");
                }
            }} clearOnChange={true} />

            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <Tooltip title={"Gültigkeit"}>
                    <DatePicker.RangePicker
                        value={[inputVal.gueltigVon, inputVal.gueltigBis]}
                        onCalendarChange={range => {
                            onChange({neu: range, unitId: unit?.id || 0}, "gueltigkeit");
                        }}

                        format={"DD.MM.YYYY"}

                        locale={locale_de}
                        allowEmpty
                    />
                </Tooltip>

                <Tooltip title={"Fälligkeit"}><DatePicker
                    placeholder={"Fälligkeit"}
                    value={inputVal.faelligkeit}
                    onChange={neu => onChange({neu: neu?.startOf('day')?.valueOf() || null, unitId: unit?.id || 0}, "faelligkeit")}
                    format={"DD.MM.YYYY"}
                /></Tooltip>
            </div>

            <div>
                <Tooltip title={"Bearbeitet"}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.bearbeitet} onChange={e => {
                                onChange({neu: e.target.checked, unitId: unit?.id || 0}, "bearbeitet");
                            }} /> bearbeitet</span>
                </Tooltip>

                <span>Anzeigen bei: </span>
                <Tooltip title={"Anzeigen bei Aufnahme"}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiAufnahme} onChange={e => {
                                onChange({neu: e.target.checked, unitId: unit?.id || 0}, "anzeigenBeiAufnahme");
                            }} /> Aufnahme</span>
                </Tooltip>
                <Tooltip title={"Anzeigen bei Verblisterung"}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiVerblisterung} onChange={e => {
                                onChange({neu: e.target.checked, unitId: unit?.id || 0}, "anzeigenBeiVerblisterung");
                            }} /> Verblisterung</span>
                </Tooltip>
                <Tooltip title={"Anzeigen bei Anforderung"}>
                            <span style={{marginRight: 10}}><Checkbox checked={!!inputVal.anzeigenBeiAnforderung} onChange={e => {
                                onChange({neu: e.target.checked, unitId: unit?.id || 0}, "anzeigenBeiAnforderung");
                            }} /> Anforderung</span>
                </Tooltip>
            </div>
        </Col>
        <Col span={12}>
            <span style={{display: 'flex', gap: 5}}>
                <b>Planzeilen:</b>
                {!!planzeilenAusgewaehltNichtVerfuegbar.length && <>
                    <span>{planzeilenAusgewaehltNichtVerfuegbar.length} abgesetzte Planzeile{planzeilenAusgewaehltNichtVerfuegbar.length > 1 ? 'n sind' : ' ist'} verknüpft</span>
                    <a href="#" onClick={() => setShowNichtVerfuegbar(!showNichtVerfuegbar)}>[{showNichtVerfuegbar ? 'ausblenden' : 'anzeigen'}]</a>
                </>}
            </span>
            <div style={{display: "flex", flexDirection: "column"}}>
                {showNichtVerfuegbar && planzeilenAusgewaehltNichtVerfuegbar.map((planzeileId, i) => {
                    return <PlanzeileSelect key={planzeileId} disabledOptions={planzeilenNichtVerfuegbarIds} unitId={unit?.id} planzeilen={planzeilenNichtVerfuegbar} selected={planzeileId} onChange={(planzeileIdNeu) => {
                        setInputVal(prev => ({...prev, __changed__: true}));
                        setPlanzeilenAusgewaehlt(prev => {
                            const neu = prev.filter(id => id !== planzeileId);
                            if (planzeileIdNeu) neu.push(planzeileIdNeu);
                            return neu;
                        });

                        onChange({neu: planzeileIdNeu, alt: planzeileId, unitId: unit.id}, "planzeile");
                    }} />
                })}
                {planzeilenAusgewaehltVerfuegbar.map((planzeileId, i) => {
                    return <PlanzeileSelect key={planzeileId} unitId={unit?.id} planzeilen={planzeilenVerfuegbar} selected={planzeileId} onChange={(planzeileIdNeu) => {
                        setInputVal(prev => ({...prev, __changed__: true}));
                        setPlanzeilenAusgewaehlt(prev => {
                            const neu = prev.filter(id => id !== planzeileId);
                            if (planzeileIdNeu) neu.push(planzeileIdNeu);
                            return neu;
                        });

                        onChange({neu: planzeileIdNeu, alt: planzeileId, unitId: unit.id}, "planzeile");
                    }} />
                })}
                <PlanzeileSelect unitId={unit?.id} disabled={!unit} planzeilen={planzeilenVerfuegbar} onChange={planzeileIdNeu => {
                    if (!planzeilenAusgewaehlt.includes(planzeileIdNeu)) {
                        setInputVal(prev => ({...prev, __changed__: true}));
                        setPlanzeilenAusgewaehlt(prev => [...prev, planzeileIdNeu]);

                        onChange({neu: planzeileIdNeu, unitId: unit.id}, "planzeile");
                    }
                }} />
            </div>
        </Col>
    </Row>
}

export const PlanzeileSelect = ({unitId, selected=null, onChange=()=>{}, disabled=false, disabledOptions=[], planzeilen=[]}) => {
    return <Select
        disabled={disabled}
        value={selected}
        style={{width: "100%"}}
        allowClear={true}
        onChange={onChange}
    >
        {planzeilen.map((planzeile) => {
            return <Select.Option value={planzeile.id} key={planzeile.id} disabled={disabledOptions.includes(planzeile.id)}>{getArzneimittelLangname(planzeile.arzneimittel, planzeile.id)}</Select.Option>;
        })}
    </Select>
}