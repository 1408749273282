import React, {useState, useEffect, useRef, useContext} from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../utilities/useAuth'
import {Row, Col, Badge, Button} from 'antd';
import {useHistory} from "react-router-dom";
import {useApi} from "../utilities/useApi";
import NotizContext from "../contexts/NotizContext";
import {Tooltip} from "./atoms/Tooltip";
import {GlobalContext} from "../config/globalContext";



/**
  * Navigator Komponente (zB im Header)
  * @see {@link App }
  */
const Navigator = () => {
  const auth = useAuth();
  const api = useApi();
  const history = useHistory();

  const notizContext = useContext(NotizContext);
  const globalContext = useContext(GlobalContext)

  const [aktivesMenue, setAktivesMenue] = useState("");
  const [badgeMap, setBadgeMap] = useState({});

  const reloadInterval = useRef(null);

  const reload = async () => {
    if (auth.token) {
      api.loadDokumenteNeu(false);
      api.loadAnforderungenGesammelt(false);

      const highPriority = history.location.pathname.startsWith('/problemfaelle');
      api.loadProblemfaelle(false, highPriority).then(problemfaelle => setBadgeMap(prev => ({...prev, problemfaelle})));
    }
  }

  useEffect(() => {
    reload();
  }, [])

  useEffect(() => {
    reloadInterval.current = setInterval(reload, 30000);
    return () => clearInterval(reloadInterval.current);
  }, [auth.token])

  useEffect(() => {
    if (api.dokumenteNeu.length) {
      setBadgeMap(prev => ({...prev, dokumente: api.dokumenteNeu.length}));
    } else {
      setBadgeMap(prev => {
        const {dokumente, ...rest} = prev;
        return rest;
      })
    }
  }, [api.dokumenteNeu])

  useEffect(() => {if (api.anforderungenGesammelt.length) {
      setBadgeMap(prev => ({...prev, anforderungen: api.anforderungenGesammelt.length}));
    } else {
      setBadgeMap(prev => {
        const {anforderungen, ...rest} = prev;
        return rest;
      })
    }
  }, [api.anforderungenGesammelt])

  useEffect(() => {
    if (history.location) {
      for (let eintrag of navigation) {
        if (history.location.pathname.startsWith(eintrag.to)) {
          setAktivesMenue(eintrag.to);
          break;
        }
      }
    }
  }, [history.location])

  return (<Row>
    <Col span={16}>
      <Link
        to="/home"
        onClick={() => {
          setAktivesMenue("");
        }}
      ><img src={iconLogo} height="100" width="170" style={{ paddingRight: "70px" }} /></Link>

      {navigation.map((menuItem, index) => {
        return <MenuElement
          to={menuItem.to} label={menuItem.label} icon={menuItem.icon}
          aktivesMenue={aktivesMenue}
          badge={badgeMap[menuItem.badge]}

          key={index}
        />
      })}
    </Col>

    <Col
      span={8}
      style={{ textAlign: "right" }}
    >
      <Tooltip title={"neue Notiz erstellen"} placement={"bottom"}><img src={iconNotiz} height="32" onClick={() => notizContext.setVisible(true)} style={{cursor: "pointer"}} /></Tooltip>
      <Link to="/benutzer" ><img src={iconUser} height="32" /></Link>
      <Link to="/settings" ><img src={iconSettings} height="32" /></Link>
      <Link to="" onClick={() => auth.handleLogout()}><img src={iconLogout} height="32" /></Link>
    </Col>
  </Row>)

}



const MenuElement = ({ to, icon, label, aktivesMenue, badge }) => {
  const elLabel = <span style={{display: "inline-block", marginTop: 70}}>{label}</span>;

  return <Link
      style={{ fontWeight: "bolder" }}
      to={to}
  >
    <div className={`menu-item ${aktivesMenue === to && "aktiv"}`}>
      <div style={{height: 0, verticalAlign: "middle"}}><img src={icon} height={aktivesMenue === to ? "64" : "48"} alt={label} /></div>
      <Badge count={badge} offset={[-10, 15]}>{elLabel}</Badge>
    </div>
  </Link>
}





// todo in config auslagern
const iconLogo = require('../style/icons/logo.png');
const iconMplan = require('../style/icons/medikationsplan.png');
const iconNachrichten = require('../style/icons/dateiverwaltung.png');
const iconAufgaben = require('../style/icons/checklist.png');
const iconVerblisterung = require('../style/icons/arzneimittel.png');
const iconAdressbuch = require('../style/icons/adressbuch.png');
const iconDateiverwaltung = require('../style/icons/nachrichten.png');
const iconUser = require('../style/icons/user.png');
const iconSettings = require('../style/icons/settings.png');
const iconLogout = require('../style/icons/logout.png');
const iconNotiz = require('../style/icons/notiz.png');
const iconProblemfaelle = require('../style/icons/check-mark.png');

const navigation = [
  { to: "/medikationsplan", label: "Medikationspläne", icon: iconMplan },
  { to: "/verblisterung", label: "Verblisterung", icon: iconVerblisterung },
  { to: "/nachrichten", label: "Anforderungen", icon: iconNachrichten, badge: "anforderungen" },
  // { to: "/aufgaben", label: "Aufgaben", icon: iconAufgaben },
  { to: "/adressbuch", label: "Adressbuch", icon: iconAdressbuch },
  { to: "/dokumente", label: "Posteingang", icon: iconDateiverwaltung, badge: "dokumente" },
  { to: "/problemfaelle", label: "Problemfälle", icon: iconProblemfaelle, badge: "problemfaelle" },
]









export default Navigator