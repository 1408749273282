import React, {useEffect, useState} from 'react';
import moment from "moment";

export const GlobalContext = React.createContext({
    showContextMenu: false,
    setShowContextMenu: val => {},

    contextMenu: {},
    setContextMenu: val => {},

    heute: null,
    setHeute: val => {},
});

export const useInitialState = () => {
    const [contextMenu, setContextMenu] = useState({});
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [heute, setHeute] = useState(null);

    useEffect(() => {
        const bla = contextMenu;
    }, [contextMenu])

    return {
        showContextMenu,
        setShowContextMenu,

        contextMenu,
        setContextMenu,

        heute,
        setHeute,
    }
}
