import { format, isValid, addHours, addDays, subDays, addMonths, addYears } from 'date-fns'
import moment from 'moment';


/**
 * JSON String -> AntD DatePicker Format
 * @param {String} timestamp 
 * @returns {momentJSDate | null} timestamp als momentJS Datum
 */
export const timestampToDatepicker = timestamp => {
    let result = new Date(timestamp)
    return isValid(result) ? moment(result) : null
}

// AntD DatePicker Format -> JSON String
export const datepickerToTimestamp = date => {
    return moment(date).valueOf()
}


/**
  * @param {String} start start Datum als timestamp
 * @param {String} ende ende Datum als timestamp
 * @returns {Boolean} liegt das aktuelle Datum innnerhalb dieser Zeitspanne
 */
export const zeitspanneIstAktuell = (start, ende) => {
    return (start === null || moment(start).diff() <= 0)
        &&
        (ende === null || moment(ende).diff() >= 0)
}

export const startOfDay = (date) => {
    const neu = new Date(date);
    neu.setHours(0, 0, 0, 0);
    return neu;
}

export const endOfDay = (date) => {
    const neu = new Date(date);
    neu.setHours(23, 59, 59, 999);
    return neu;
}




export const naechsterWochentag = (wochentag, referenzDatum, addTage) => {
    const referenzMoment = (referenzDatum ? moment(referenzDatum) : moment()).startOf('day');
    const zielMoment = moment(referenzMoment).isoWeekday(wochentag);

    if (zielMoment.isBefore(referenzMoment)) {
        zielMoment.add(1, 'weeks');
    }

    if (addTage) {
        zielMoment.add(addTage, 'days');
    }

    return zielMoment.valueOf();
};









// JSON String / Long -> DatePicker Format (nur Zeit)
export const zeitStringToDate = zeitString => {
    let result = new Date("2001-01-01T" + zeitString)
    return isValid(result) ? result : null
}

// DatePicker Format -> JSON String / Long
export const dateToZeitString = date => {
    return isValid(date) ? format(new Date(date), 'HH:mm:ss') : ""
}


export const dateStringToTimestamp = dateString => {
    let value = new Date(dateString)
    return (dateString && isValid(value)) ? value.getTime() : null
}


// Für Ausgabe in GUI
export const dateToString = (date, dateFormat = 'dd.MM.yyyy') => {
    let value = new Date(date)
    return (date && isValid(value)) ? format(value, dateFormat) : ""
}



// ???
export const dataToForm = date => {
    var value = new Date(date)
    return date && isValid(value) ? value : ""
}

export const stringToDate = dateString => {
    let result = new Date(dateString)
    return isValid(result) ? result : null
}






export const getEnde = (start, dauer, ende) => {
    if (!dauer || dauer === "" || dauer === undefined) return ende
    var result = dauer.match(/(\d+) ?([wWtTmMjJ])/);
    if (result && result.length > 2) {
        var base = (result[2] === "w" || result[2] === "W") ? 7 : 1

        if ((result[2] === "m" || result[2] === "M")) {
            var x = addMonths(dataToForm(start), result[1])
            return subDays(x, 1)
        }
        else if ((result[2] === "j" || result[2] === "J")) {
            var y = addYears(dataToForm(start), result[1])
            return subDays(y, 1)
        }
        else
            return addDays(dataToForm(start), base * result[1] - 1)
    }
    return ende
}

// ToDo: nächste Stunde berechnen
export const getNextZeit = einnahmezeit => {
    const now = new Date("2001-01-01T" + einnahmezeit)
    return !isValid(now) ? null :
        format(addHours(now, 1), "HH:mm:ss")
}







// export const formToData = date => {
//     let text = new Date(date)
//     return isValid(text) ? format(text, 'yyyy-MM-dd') : ""
// }

