import React, {useState} from "react";

const usePagination = ({ pageSize:pageSizePref=10, position="bottom" }={}) => {
    const [pageSize, setPageSize] = useState(pageSizePref);

    return {
        pageSize,
        onShowSizeChange: (page, size) => setPageSize(size),
        hideOnSinglePage: pageSize === pageSizePref,
        position
    }
}

export default usePagination;