import React, {useRef, useEffect, useState} from "react";

/**
 * Hook für Alerts außerhalb von Referenzelement
 *
 * s. https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export const useOutsideAlerter = (ref, eventType="mousedown") => {
    const [firedEvent, setFiredEvent] = useState(null);

    useEffect(() => {
        const fireEvent = (event) => {
            if (ref.current?.contains && !ref.current.contains(event.target)) {
                setFiredEvent(event);
            }
        }

        // Bind the event listener
        document.addEventListener(eventType, fireEvent);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(eventType, fireEvent);
        };
    }, [ref]);

    return firedEvent
}