import moment from "moment";
import {BASE_URL} from "./apiConfig";

const postInfomarker = BASE_URL + "infomarker/"

const postSimuliereAbgabeDatum = (medikationsplan, start, ende, hardreset=false, erzwingeNeuberechnung=false, awaitFinish=false) => {
    if (!start  || !medikationsplan) return null;

    const startDatum = moment(start).format("YYYY-MM-DD");
    const endDatum = ende ? moment(ende).format("YYYY-MM-DD") : null;

    return BASE_URL + `medikationsplan/${medikationsplan.id}/abgabeplan/${startDatum}${endDatum ? "/"+endDatum : ""}?hardreset=${hardreset ? "true" : "false"}&erzwingeneuberechnung=${erzwingeNeuberechnung ? "true" : "false"}&awaitFinish=${awaitFinish ? "true" : "false"}`;
}


const postErstelleAnforderung = () => BASE_URL + "anforderung";


const postAnforderungAbbruch = () => BASE_URL + "anforderung/abbrechen";
const postAnforderungenVersenden = () => BASE_URL + "anforderung/versenden";
const postAnforderungenVersendeStornoMeldung = () => BASE_URL + "anforderung/stornomeldung";
const postAnforderungenAbbrechen = (erstelleStornoMeldung) => BASE_URL + `anforderung/abbrechen?stornoMeldung=${erstelleStornoMeldung ? "true" : "false"}`;
const postAnforderungenSchliessen = (zumTeil = false) => BASE_URL + `anforderung/schliessen${zumTeil ? "?zumTeil=true" : ""}`;
const postAnforderungenMahnen = () => BASE_URL + "anforderung/mahnen";
const getAnforderungVoransicht = (anforderungId) => BASE_URL + "anforderung/voransicht/"+anforderungId;

export {
    postInfomarker,
    postSimuliereAbgabeDatum,
    postErstelleAnforderung,
    postAnforderungAbbruch,
    postAnforderungenVersenden,
    postAnforderungenVersendeStornoMeldung,
    postAnforderungenAbbrechen,
    postAnforderungenSchliessen,
    postAnforderungenMahnen,
    getAnforderungVoransicht
}
