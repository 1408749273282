import React from "react";

const DisableOverlay = ({disable=true, text, children}) => {
    return disable ?
        <div className={"disableOverlay"} data-text={text}>
            {children}
        </div> :
        children;
}

export default DisableOverlay;