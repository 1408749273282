import React, {Fragment, useContext, useEffect, useState} from "react";
import {Popover} from "../atoms/Popover";
import {Button, Checkbox, Modal, notification} from "antd";
import {ArzneimittelPicker, getArzneimittelLangname} from "../atoms/Arzneimittel";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getUnitLangname} from "../../config/entities";
import {AkteurPicker} from "../atoms/AkteurPicker";
import {useApi} from "../../utilities/useApi";
import {useAuth} from "../../utilities/useAuth";
import {callApiAsync} from "../../utilities/apiUtil";
import {akteureApi, anforderungApi, buendelApi} from "../../config/apiConfig";
import moment from "moment";
import {usePost} from "../../utilities/usePost";
import {postAnforderungenSchliessen} from "../../config/postApiConfiguration";
import {erstelleAnforderung, getAnforderungDataAsync} from "../molecules/RezeptanforderungModal";
import {GlobalContext} from "../../config/globalContext";
import {getCurrentDosierabschnitt, heute} from "../../utilities/dosierschemaUtil";
import NotizContext from "../../contexts/NotizContext";

const FaxempfangEintragenModal = ({ visible=false, onVisibleChange=(visible, reason)=>{} }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const globalContext = useContext(GlobalContext);
    const notizContext = useContext(NotizContext);
    const api = useApi();
    const auth = useAuth();

    const [modalVisible, setModalVisible] = useState({});
    const [patient, setPatient] = useState(undefined);
    const [arzneimittel, setArzneimittel] = useState(undefined);
    const [verordner, setVerordner] = useState(undefined);
    const [planzeile, setPlanzeile] = useState(undefined);
    const [planzeilen, setPlanzeilen] = useState([]);
    const [anforderungen, setAnforderungen] = useState([]);
    const [anforderungenToChange, setAnforderungenToChange] = useState([]);
    const [disabledOkButton, setDisabledOkButton] = useState(true);
    const [anforderungenAlleMitFaxempfang, setAnforderungenAlleMitFaxempfang] = useState(false);

    const schliesseAnforderungTeil = usePost(postAnforderungenSchliessen(true));

    useEffect(() => {
        setModalVisible({ main: visible });
    }, [visible])

    useEffect(() => {
        setPatient(medikationsplanContext.medikationsplan?.patient);
    }, [medikationsplanContext.medikationsplan?.patient?.id])

    useEffect(() => {
        if (patient && arzneimittel) {
            const anforderungen = api.anforderungenAll[patient.id];
            if (anforderungen) handleAnforderungen(anforderungen);
            else api.loadAnforderungenAll(patient.id).then(handleAnforderungen);
        }
    }, [patient?.id, arzneimittel?.id])

    useEffect(() => {
        setDisabledOkButton(!anforderungenToChange.length || !!planzeile);
    }, [anforderungenToChange, planzeilen])

    const resetVars = () => {
        setModalVisible({});
        setPatient(undefined);
        setArzneimittel(undefined);
        setVerordner(undefined);
        setPlanzeile(undefined);
        setAnforderungen([]);
        setAnforderungenToChange([]);
        setPlanzeilen([]);
        setDisabledOkButton(true);
        setAnforderungenAlleMitFaxempfang(false);
    }

    const handleAnforderungen = anforderungen => {
        if (anforderungen?.length) {
            callApiAsync({auth, url: buendelApi.getByM2StringAndPatient(arzneimittel.m2, patient.id, true)}).then(async response => {
                const pairList = response.data.OBJECT;
                
                const buendelMap = {};
                for (let pair of pairList) {
                    if (pair.key.id) {
                        buendelMap[pair.key.id] = pair.key;
                    }
                }
                
                const buendelIdList = Object.keys(buendelMap);
                let anforderungenFiltered;
                let verordnerNeu;
                if (buendelIdList.length) {
                    anforderungenFiltered = anforderungen.filter(a => !a.datumErfuellt && !a.datumAbgebrochen && buendelIdList.includes(a.planzeile.buendelId +''));
                    verordnerNeu = buendelIdList.map(buendelId => buendelMap[buendelId].planzeilen.find(pz => !!pz.verordner))?.verordner;
                } else {
                    anforderungenFiltered = [];
                }

                if (!verordnerNeu) {
                    verordnerNeu = patient.hausarzt || (await callApiAsync({auth, url: akteureApi.getHausarztByPatient(patient.id)}))?.data?.OBJECT;
                }

                const planzeileList = [];
                for (let buendel of Object.values(buendelMap)) {
                    for (let planzeile of buendel.planzeilen) {
                        if (!!getCurrentDosierabschnitt(planzeile.dosierschema, globalContext.heute)) {
                            planzeileList.push(planzeile);
                        }
                    }
                }
                setPlanzeilen(planzeileList);
                if (planzeileList.length === 1) setPlanzeile(planzeileList[0]);

                setVerordner(verordnerNeu);
                setAnforderungen(anforderungenFiltered);

                if (anforderungenFiltered.length && anforderungenFiltered.every(a => a.datumFaxempfang)) {
                    setAnforderungenAlleMitFaxempfang(true);
                } else if (planzeileList.length === 1) {
                    setDisabledOkButton(false);
                }
            });
        }
    }

    const handleVisibleChange = (modalKey, reason) => {
        if (modalKey === 'main') {
            onVisibleChange(false, reason);
            resetVars();
        } else {
            setModalVisible(prev => ({...prev, [modalKey]: !modalVisible[modalKey]}));
        }
    }

    return <>
        <Modal
            visible={modalVisible.main}
            onCancel={() => handleVisibleChange('main', 'cancel')}
            onOk={async () => {
                if (!anforderungen.length || anforderungenAlleMitFaxempfang) {
                    const anforderungData = await getAnforderungDataAsync(
                        planzeilen[0],
                        auth
                    );

                    let anforderung = await erstelleAnforderung({anforderungData, api, auth, notizContext, verordner});
                    if (!anforderung) return;

                    const data = {
                        ...anforderung,
                        arzneimittel: {
                            id: arzneimittel.id
                        },
                        datumFaxempfang: globalContext.heute.valueOf()
                    }

                    anforderung = (await callApiAsync({auth, url: anforderungApi.post(), method: 'post', data}))?.data?.OBJECT;
                    await api.updateAnforderung(anforderung);
                    notification.info({message: `Die Anforderung wurde erfolgreich erstellt.`});
                } else {
                    const response = await schliesseAnforderungTeil.handleSend(anforderungenToChange.map(row => row.id));
                    for (let anforderung of response) {
                        await api.updateAnforderung(anforderung);
                    }

                    notification.info({message: `Die ${anforderungenToChange.length > 1 ? anforderungenToChange.length + ' Anforderungen wurden' : 'Anforderung wurde'} erfolgreich erstellt.`});
                }

                handleVisibleChange('main', 'ok');
            }}

            okButtonProps={{disabled: disabledOkButton}}
            destroyOnClose={true}
        >
            <h3>Faxempfang eintragen</h3>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'minmax(min-content, max-content) max-content',
                justifyItems: 'start',
                columnGap: '1rem',
                marginBottom: '2rem'
            }}>
                <span>Patient:</span>
                <span>{patient ? getUnitLangname(patient) : '-'}</span>

                <span>Arzneimittel:</span>
                <span>{arzneimittel ? getArzneimittelLangname(arzneimittel) : '-'}</span>

                <span>Verordner:</span>
                <span>{verordner ? getUnitLangname(verordner) : '-'}</span>

                {patient && arzneimittel && !planzeilen.length && <h5 style={{gridColumn: '1/-1', marginTop: '1rem'}}>Keine Planzeile für dieses Arzneimittel gefunden!</h5>}
                {planzeilen.length > 1 && <>
                    <h5 style={{gridColumn: '1/-1', marginTop: '1rem'}}>Planzeile für Anforderung</h5>
                    {planzeilen.map((pz) => <Fragment key={pz.id}>
                        <span style={{marginTop: '0.5rem'}}>Arzneimittel:</span>
                        <span style={{marginTop: '0.5rem'}}>{getArzneimittelLangname(pz.arzneimittel)}</span>

                        <span>Dosierung:</span>
                        <span>{getCurrentDosierabschnitt(pz.dosierschema)}</span>

                        <span style={{gridColumn: '1/-1'}}><Checkbox checked={planzeile?.id === pz.id} onChange={element => {
                            if (element.target.checked) {
                                setPlanzeile(pz);
                            } else {
                                setPlanzeile(undefined);
                            }
                        }} /> Planzeile für Anforderung nutzen</span>
                    </Fragment>)}
                </>}

                {!!anforderungen.length && <>
                    <h5 style={{gridColumn: '1/-1', marginTop: '1rem'}}>Anforderung gefunden</h5>
                    {anforderungen.map((anforderung) => <Fragment key={anforderung.id}>
                        <span style={{marginTop: '0.5rem'}}>Arzneimittel:</span>
                        <span style={{marginTop: '0.5rem'}}>{getArzneimittelLangname(anforderung.arzneimittel)}</span>

                        <span>Verordner:</span>
                        <span>{getUnitLangname(anforderung.verordner)}</span>

                        <span>Faxempfang:</span>
                        <span>{!!anforderung.datumFaxempfang ? moment(anforderung.datumFaxempfang).format('DD.MM.YYYY') : <><Checkbox onChange={element => {
                            if (element.target.checked) {
                                setAnforderungenToChange(prev => [...prev, anforderung]);
                            } else {
                                setAnforderungenToChange(prev => prev.filter(a => a.id !== anforderung.id));
                            }
                        }} /> Faxempfang eintragen</>}</span>
                    </Fragment>)}
                    {anforderungenAlleMitFaxempfang && <span style={{gridColumn: '1/-1', marginTop: '1rem'}}><Checkbox onChange={val => setDisabledOkButton(!val.target.checked)} /> Zusätzliche Anforderung erstellen</span>}
                </>}

                <div style={{gridColumn: '1/-1', marginTop: '1rem'}}>
                    <Popover
                        visible={!!modalVisible.patient}
                        destroyTooltipOnHide={true}
                        onVisibleChange={() => handleVisibleChange('patient')}
                        placement={'bottom'}
                        content={(
                            <div>
                                <Button onClick={() => handleVisibleChange('patient', 'cancel')}>Patient-Auswahl abbrechen</Button>
                                <AkteurPicker autofocus={true} value={patient} rolle={'patient'} onChange={val => {
                                    setPatient(val);
                                    handleVisibleChange('patient');
                                }}/>
                            </div>
                        )}>

                        <Button onClick={() => handleVisibleChange('patient')}>Patient ändern</Button>
                    </Popover>

                    <Popover
                        visible={!!modalVisible.arzneimittel}
                        destroyTooltipOnHide={true}
                        onVisibleChange={() => handleVisibleChange('arzneimittel')}
                        placement={'bottom'}
                        content={(
                            <div>
                                <Button onClick={() => handleVisibleChange('arzneimittel', 'cancel')}>Arzneimittel-Auswahl abbrechen</Button>
                                <ArzneimittelPicker value={arzneimittel} onChange={async val => {
                                    setArzneimittel(val);
                                    handleVisibleChange('arzneimittel');
                                }} />
                            </div>
                        )}>

                        <Button onClick={() => handleVisibleChange('arzneimittel')}>Arzneimittel ändern</Button>
                    </Popover>

                    <Popover
                        visible={!!modalVisible.verordner}
                        destroyTooltipOnHide={true}
                        onVisibleChange={() => handleVisibleChange('verordner')}
                        placement={'bottom'}
                        content={(
                            <div>
                                <Button onClick={() => handleVisibleChange('verordner', 'cancel')}>Verordner-Auswahl abbrechen</Button>
                                <AkteurPicker autofocus={true} rolle={'arztpraxis'} value={verordner} onChange={val => {
                                    setVerordner(val);
                                    handleVisibleChange('verordner');
                                }} />
                            </div>
                        )}>

                        <Button onClick={() => handleVisibleChange('verordner')}>Verordner ändern</Button>
                    </Popover>
                </div>
            </div>
        </Modal>
    </>
}

export default FaxempfangEintragenModal;