import React, {useContext, useEffect, useState} from 'react'

import { useFetch } from '../../utilities/useFetch';
import {Table, Divider, Tag, Button} from 'antd';
import {fetchAnforderungByNachrichtId, fetchNachrichtById} from '../../config/fetchApiConfiguration';
import { getUnitLangname } from '../../config/entities';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {getAnforderungVoransicht} from "../../config/postApiConfiguration";
import {nachrichtApi} from "../../config/apiConfig";
import {nl2br} from "../../utilities/formatUtil";
import NachrichtenContext from "../../contexts/NachrichtenContext";


export const NachrichtenDetailansicht = (props) => {
    const auth = useAuth();
    const nachrichtenContext = useContext(NachrichtenContext);
    const anforderungenFetch = useFetch(nachrichtenContext.gewaehlteNachricht ? fetchAnforderungByNachrichtId(nachrichtenContext.gewaehlteNachricht.id) : null);

    useEffect(() => {
        if (anforderungenFetch.data) nachrichtenContext.setGewaehlteAnforderungen(anforderungenFetch.data);
    }, [anforderungenFetch.data])

    return nachrichtenContext.gewaehlteNachricht ? <div>
        <div>
            <b>Empfänger:</b> {getUnitLangname(nachrichtenContext.gewaehlteNachricht.empfaengerListe[0], "arzt")}<br/>
            <b>Erstellt:</b> {moment(nachrichtenContext.gewaehlteNachricht.erstellungsDatum).format("DD.MM.YYYY HH:mm")}<br />
            {<Button onClick={() => { window.open(nachrichtApi.getDokumentById(nachrichtenContext.gewaehlteNachricht.anhangListe[0].id) + "?token=" + auth.token) }}>PDF anzeigen</Button>}
        </div>

        {!!nachrichtenContext.gewaehlteAnforderungen && Array.isArray(nachrichtenContext.gewaehlteAnforderungen) &&
            <div style={{marginTop: 20}}>
                {nachrichtenContext.gewaehlteAnforderungen.map(anforderung => {
                    let datumMahnung = anforderung.datumMahnung3 || anforderung.datumMahnung2 || anforderung.datumMahnung1;

                    return <div>
                        <hr/>
                        {
                            anforderung.datumFaxempfang ? <><b>Faxempfang:</b> {moment(anforderung.datumFaxempfang).format("DD.MM.YYYY")}<br/></> :
                            anforderung.datumErfuellt ? <><b>Erfüllt:</b> {moment(anforderung.datumErfuellt).format("DD.MM.YYYY")}<br/></> :
                            anforderung.datumAbgebrochen ? <><b>Storniert:</b> {moment(anforderung.datumAbgebrochen).format("DD.MM.YYYY")}<br/></> :
                            datumMahnung ? <><b>Zuletzt gemahnt:</b> {moment(datumMahnung).format("DD.MM.YYYY")}<br/></> : <></>
                        }
                        <b>Arzneimittel:</b> {getArzneimittelLangname(anforderung.arzneimittel)}<br/>
                        <b>Menge:</b> {anforderung.mengeBenoetigt > 0 ? anforderung.mengeBenoetigt : anforderung.anzahlArzneimittel !== 1 ? anforderung.anzahlArzneimittel+"x"+anforderung.arzneimittel?.menge || 0 : anforderung.arzneimittel?.menge || 0}<br/>
                        <b>Rezept benötigt bis:</b> {moment(anforderung.datumBenoetigt).format("DD.MM.YYYY")}<br/>
                        {!!anforderung.kommentar && <><b>Kommentar:</b><br/>{nl2br(anforderung.kommentar)}</>}
                    </div>
                })}
            </div>
        }
    </div> : <></>
}