import React, {useEffect, useRef, useState} from 'react'

import { useAuth } from '../../utilities/useAuth'
import {Button} from "antd";
import {callApiAsync} from "../../utilities/apiUtil";
import {BASE_URL, BASE_URL_NONMP, maintenanceApi, settingApi} from "../../config/apiConfig";
import {getUnitLangname, GRUPPEN, ROLLEN} from "../../config/entities";
import moment from "moment";
import {ModusRezeptanforderung, printAnforderungenList} from "../organisms/Rezeptanforderungen";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {useApi} from "../../utilities/useApi";
import {printAdressListe} from "../organisms/AdressbuchTable";
import {getSorter} from "../../utilities/sortUtil";
import {splitAtLength} from "../../utilities/formatUtil";

export const Settings = props => {
    const auth = useAuth();
    const api = useApi();

    const [planzeileResetting, setPlanzeileResetting] = useState(false);
    const [planzeileResettingStatus, setPlanzeileResettingStatus] = useState(null);
    const [planzeileResetLast, setPlanzeileResetLast] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const planzeileResetCheckInterval = useRef(null);

    useEffect(() => {
        checkPlanzeileResetting();
    }, [])

    const checkPlanzeileResetting = async () => {
        if (planzeileResetting) return;
        if (planzeileResetCheckInterval.current) clearTimeout(planzeileResetCheckInterval.current);

        const value = (await callApiAsync({auth, url: settingApi.get("MAINTENANCE_MEDIKATIONSPLAN_REICHWEITE_RESET_LAST"), onError: res => res.data.MESSAGE === "no setting found", successOnError: true})).data.OBJECT?.value;
        if (value) {
            setPlanzeileResetLast(moment(value));

            setPlanzeileResetting(false);
            setPlanzeileResettingStatus(null);
        } else {
            setPlanzeileResetLast(null);

            const resetStatus = (await callApiAsync({auth, url: maintenanceApi.getResetStatus()})).data.MESSAGE;
            setPlanzeileResettingStatus(resetStatus);
            planzeileResetCheckInterval.current = setTimeout(checkPlanzeileResetting, 1000);
        }
    }

    const resetPlanzeilen = () => {
        if (planzeileResetting) return;

        setPlanzeileResetting(true);
        setPlanzeileResetLast(null);

        callApiAsync({auth, url: maintenanceApi.resetPlanzeilen("ALL")})
            .then(response => {
                setPlanzeileResettingStatus(response.data.MESSAGE);
            });

        setTimeout(checkPlanzeileResetting, 3000);
    }

    const printListAnspruchUngleichBestand = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + `maintenance/list/anspruchungleichbestand`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => {
            const patientStringList = splitAtLength(entry.patient, ' ', 35);
            const stationStringList = splitAtLength(entry.station, ' ', 35);
            const arzneimittelStringList = splitAtLength(entry.arzneimittel, ' ', 40);

            return `<tr>
                        <td><pre>${patientStringList.join('\n')}</pre></td>
                        <td><pre>${stationStringList.join('\n')}</pre></td>
                        <td><pre>${arzneimittelStringList.join('\n')}</pre></td>
                        <td><pre>${entry.anspruch}</pre></td>
                        <td><pre>${entry.vorab} (${entry.vorabAnforderung})</pre></td>
                        <td><pre>${entry.bestand}</pre></td>
                        <td><pre>${entry.bestellung}</pre></td>
                        <td><pre>${entry.anspruch + entry.vorab}</pre></td>
                        <td><pre>${entry.bestand + entry.bestellung}</pre></td>
                    </tr>`;
        });

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Anspruch + Vorab != Bestand + Bestellung</h3><table><tr>
            <td>Patient</td>
            <td>Station</td>
            <td>Arzneimittel</td>
            <td>Anspruch</td>
            <td>Vorab</td>
            <td>Bestand</td>
            <td>Bestellung</td>
            <td>Summe A+V</td>
            <td>Summe B+B</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListAnspruchUngleichMedikationsplanAnspruch = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + `maintenance/list/anspruchungleichmedikationsplan`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${entry.arzneimittel}</pre></td>
                        <td><pre>${entry.menge}</pre></td>
                        <td><pre>${entry.mengeVerblisterung}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>tatsächlicher Anspruch != Anspruch-Referenz</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Anspruch</td>
            <td>Anspruch-Referenz</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListVorabPackungen = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + `maintenance/list/voraboffen`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${entry.arzneimittel}</pre></td>
                        <td><pre>${entry.packung}</pre></td>
                        <td><pre>${entry.vorab}</pre></td>
                        <td><pre>${moment(entry.datumAufnahme).format("DD.MM.YYYY")}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Offene Vorabpackungen</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Packung</td>
            <td>Vorab</td>
            <td>Aufnahme</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printVorabanforderungen = async (api) => {
        setButtonsDisabled(true);
        let anforderungen;
        if (!api.anforderungenOffenLoaded) {
            anforderungen = await api.loadAnforderungenOffen();
        } else {
            anforderungen = api.anforderungenOffen;
        }

        await printAnforderungenList(
            anforderungen
                .filter(anforderung => anforderung.vorabgabe)
                .sort(getSorter("anforderung", "patient"))
            , ModusRezeptanforderung.offen, "Offene Vorabanforderungen", api);

        setButtonsDisabled(false);
    }

    const printVorabpackungenOhneVorabanforderung = async () => {
        setButtonsDisabled(true);
        let anforderungen;
        if (!api.anforderungenOffenLoaded) {
            anforderungen = await api.loadAnforderungenOffen();
        } else {
            anforderungen = api.anforderungenOffen;
        }

        const anforderungenMap = {};
        for (let anforderung of anforderungen) {
            if (anforderung.planzeile?.medikationsplan?.patient?.id && anforderung.arzneimittel) {
                const key1 = `${anforderung.planzeile.medikationsplan.patient.id}_${anforderung.planzeile.buendelId}`;
                const key2 = anforderung.arzneimittel.m2;

                if (!anforderungenMap[key1]) anforderungenMap[key1] = {};
                anforderungenMap[key1][key2] = anforderung;
            }
        }

        const url = BASE_URL_NONMP + `maintenance/list/voraboffen`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT
            .filter(p => {
                const key1 = `${p.patientId}_${p.buendel}`;
                return !anforderungenMap[key1];
            })
            .sort((a,b) => a.datumAufnahme - b.datumAufnahme);
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${entry.arzneimittel}</pre></td>
                        <td><pre>${entry.packung}</pre></td>
                        <td><pre>${entry.vorab}</pre></td>
                        <td><pre>${moment(entry.datumAufnahme).format("DD.MM.YYYY")}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Vorabpackungen ohne Vorabanforderung</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Packung</td>
            <td>Vorab</td>
            <td>Aufnahme</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListBestellung = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + `maintenance/list/bestellung`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT.sort((a,b) => a.datumErstellung - b.datumErstellung);
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${entry.arzneimittel}</pre></td>
                        <td><pre>${moment(entry.datumErstellung).format("DD.MM.YYYY")}</pre></td>
                        <td><pre>${entry.fax || entry.fax ? "Fax" : "Rezept"}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Offene Bestellungen</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Bearbeitet</td>
            <td>Status</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListAnforderungenUnnoetig = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + `anforderung/unnoetig`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT.sort((a,b) => a.datumErstellt - b.datumErstellt);
        const rowList = data.map(entry => `<tr>
                        <td><pre>${getUnitLangname(entry.planzeile.medikationsplan.patient)}</pre></td>
                        <td><pre>${getArzneimittelLangname(entry.arzneimittel)}</pre></td>
                        <td><pre>${moment(entry.datumErstellt).format("DD.MM.YYYY")}</pre></td>
                        <td><pre>${moment(entry.planzeile.reichweiteAnspruch).format("DD.MM.YYYY")}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Unnötige Anforderungen</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Erstellt</td>
            <td>Aktuelle Reichweite</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListPlanzeilenWarnungVertriebsstatus = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + `planzeile?warnungVertriebsstatus=true`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data
            .sort((a,b) => getUnitLangname(a.medikationsplan.patient).localeCompare(getUnitLangname(b.medikationsplan.patient)))
            .map(entry => `<tr>
                <td><pre>${getUnitLangname(entry.medikationsplan.patient)}</pre></td>
                <td><pre>${getArzneimittelLangname(entry.arzneimittel)}</pre></td>
                <td><pre>${entry.arzneimittel.pzn.padStart(8, "0")}</pre></td>
                <td><pre>${entry.arzneimittel.vertriebsstatus}</pre></td>
            </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Planzeilen: Warnung Vertriebsstatus</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>PZN</td>
            <td>Vertriebsstatus</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printListPlanzeilenArzneimittelNichtInBestand = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + `planzeile?arzneimittelnichtinbestand=true`
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${entry.arzneimittel} ${entry.dosierung || ''}</pre></td>
                    </tr>${entry.bestand.map(b => `<tr>
                        <td></td>
                        <td><pre>${b.arzneimittel}</pre></td>
                        <td><pre>${b.packungsgroesse}</pre></td>
                        <td><pre>${b.pzn}</pre></td>
                        <td><pre>${b.restbestand}</pre></td>
                    </tr>`).join("")}`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Planzeilen: Arzneimittel nicht in Bestand</h3><table><tr>
            <td>Patient</td>
            <td colspan="4">Planzeile-Arzneimittel</td>
        </tr><tr>
            <td></td>
            <td>Bestand-Arzneimittel</td>
            <td>Packungsgröße</td>
            <td>PZN</td>
            <td>Restbestand</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printAerzte = async () => {
        setButtonsDisabled(true);
        let akteure;
        if (!api.akteureLoaded) {
            const [sorted] = await api.loadAkteure();
            akteure = sorted;
        } else {
            akteure = api.akteure;
        }

        const idRollePraxis = ROLLEN.find(rolle => rolle.name === "Arztpraxis")?.id;
        const idGruppeArzt = GRUPPEN.find(gruppe => gruppe.name === "Arzt")?.id;
        printAdressListe(akteure.filter(akteur => !!akteur.roles.find(r => r.id === idRollePraxis) || !!akteur.groups.find(g => g.id === idGruppeArzt)), "Adressbuch: Ärzte + Arzpraxen");
        setButtonsDisabled(false);
    }

    const printPlanzeileMitNichtOriginalenM2 = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + `planzeile?m2NichtOriginal=true`;
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${getUnitLangname(entry.medikationsplan.patient)}</pre></td>
                        <td><pre>${getArzneimittelLangname(entry.arzneimittel)}</pre></td>
                        <td><pre>${entry.arzneimittel.pzn.padStart(8, "0")}</pre></td>
                        <td><pre>${entry.arzneimittel.vertriebsstatus}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Planzeilen: nicht-originale M2-Nummer</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>PZN</td>
            <td>Vertriebsstatus</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printAkteurDatenUnvollstaendig = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + "unit/all?dataComplete=false";
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${getUnitLangname(entry)}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Akteure: Daten unvollständig</h3><table><tr>
            <td>Patient</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printPlanzeileDosierungUnpassend = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + "maintenance/list/dosierungunpassend";
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = data.map(entry => `<tr>
                        <td><pre>${entry["patient"]}</pre></td>
                        <td><pre>${entry["arzneimittelName"]}</pre></td>
                    </tr>`);

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Planzeile: Bestand muss geteilt werden</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printBestandUngenutzt = async (amountWochen) => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + "maintenance/list/packageunblistered/"+(amountWochen || 5);
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = [];
        for (let entry of (data.patientList || [])) {
            for (let packageId of entry.internalPackageList) {
                rowList.push(`<tr>
                        <td><pre>${entry.patient}</pre></td>
                        <td><pre>${data.internalPackageMap?.[packageId]?.batch?.id?.medicinePZN?.medicineM2?.name}</pre></td>
                        <td><pre>${packageId}</pre></td>
                    </tr>`)
            }
        }

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Bestand: Arzneimittel ungenutzt seit ${amountWochen} Blisterperioden</h3><table><tr>
            <td>Patient</td>
            <td>Arzneimittel</td>
            <td>Packung</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printBestandOhneBild = async (amountWochen) => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + "maintenance/list/bestandohnebild";
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = [];
        for (let row of (data || [])) {
            rowList.push(`<tr>
                        <td><pre>${row[0]}</pre></td>
                        <td><pre>${row[1]}</pre></td>
                        <td><pre>${row[2]}</pre></td>
                        <td><pre>${row[3]}</pre></td>
                        <td><pre>${row[4]}</pre></td>
                    </tr>`)
        }

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Bestand: Bild nicht eingestellt</h3><table><tr>
            <td>M2</td>
            <td>Arzneimittel</td>
            <td>Hersteller</td>
            <td>Patient</td>
            <td>Station</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printInaktiveVersorger = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL + "akteure/versorger/inaktiv";
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const rowList = [];
        for (let row of (data || [])) {
            rowList.push(`<tr>
                        <td><pre>${row[0]}</pre></td>
                        <td><pre>${row[1]}</pre></td>
                    </tr>`)
        }

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><h3>Gelöschte Versorger, die noch verlinkt sind</h3><table><tr>
            <td>Versorger</td>
            <td>Verlinkung</td>
        </tr>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const printUmsatzauswertung = async () => {
        setButtonsDisabled(true);
        const url = BASE_URL_NONMP + "maintenance/revenueStatistics";
        const response = await callApiAsync({url, auth});

        const data = response.data.OBJECT;
        const keysMonth = Object.keys(data.total).sort();
        const rowList = [];

        rowList.push('<tr><th>Monat</th><th>Einkauf</th><th>Verkauf</th><th>Gewinn</th><th>#aufgenommen</th><th>#ausgeliefert</th></tr>');
        rowList.push('<tr><th colspan="4">Gesamt</th></tr>');
        for (const key of keysMonth) {
            const entry = data.total[key];
            const ausgaben = (entry ? entry[0]/100 : 0).toFixed(2);
            const einnahmen = (entry ? entry[1]/100 : 0).toFixed(2);
            const gewinn = (entry ? (entry[1] - entry[0])/100 : 0).toFixed(2);
            rowList.push(`<tr><th>${key}</th><td>${ausgaben} €</td><td>${einnahmen} €</td><td>${gewinn} €</td><td>${entry?.[2] || 0}</td><td>${entry?.[3] || 0}</td></tr>`);
        }

        const keysCareFacility = Object.keys(data.careFacility).sort();
        for (const heim of keysCareFacility) {
            const heimEntry = data.careFacility[heim];
            rowList.push(`<tr><th colspan="4">${heim}</th></tr>`);
            for (const key of keysMonth) {
                const entry = heimEntry[key];
                const ausgaben = (entry ? entry[0]/100 : 0).toFixed(2);
                const einnahmen = (entry ? entry[1]/100 : 0).toFixed(2);
                const gewinn = (entry ? (entry[1] - entry[0])/100 : 0).toFixed(2);
                rowList.push(`<tr><th>${key}</th><td>${ausgaben} €</td><td>${einnahmen} €</td><td>${gewinn} €</td><td>${entry?.[2] || 0}</td><td>${entry?.[3] || 0}</td></tr>`);
            }
        }

        const win = window.frames["printf"];
        win.document.write(`<body onload="window.print()"><style>td{text-align: right; padding-left: 20px}</style><h3>Umsatzauswertung</h3><table>${rowList.join("")}</table></body>`);
        win.document.close();
        setButtonsDisabled(false);
    }

    const getVerfallendePackungenAlsCSV = () => {
        const url = BASE_URL_NONMP + `maintenance/expiringPackages/csv?token=${auth.token}`;
        window.open(url, '_blank');
    }

    const getAuswertungArzneimittelAlsCSV = (target) => {
        const url = BASE_URL_NONMP + `maintenance/drugCount/csv?target=${target}&token=${auth.token}`;
        window.open(url, '_blank');
    }

    const getVersorgendeAerzteAlsCSV = () => {
        const url = BASE_URL + `akteure/versorger/csv?token=${auth.token}`;
        window.open(url, '_blank');
    }

    const getAktivePatientenAlsCSV = () => {
        const url = BASE_URL + `akteure/patienten/csv?token=${auth.token}`;
        window.open(url, '_blank');
    }

    const getProblemkommunikationAlsCSV = () => {
        const url = BASE_URL_NONMP + `maintenance/problemkommunikation?csv=true&token=${auth.token}`;
        window.open(url, '_blank');
    }

    return <div>
        <h1>Einstellungen</h1>

        <h2>Programmwartung</h2>
        <p>
            <Button disabled={buttonsDisabled} onClick={() => printListAnspruchUngleichBestand()}>Anspruch+Vorab != Bestand+Bestellung</Button>
            {/*<Button disabled={buttonsDisabled} onClick={() => printListAnspruchUngleichMedikationsplanAnspruch()}>Liste tatsächlicher Anspruch != Anspruch-Referenz</Button>*/}
            <Button disabled={buttonsDisabled} onClick={() => printListBestellung()}>Offene Bestellungen</Button>
            <Button disabled={buttonsDisabled} onClick={() => printListVorabPackungen()}>Offene Vorabpackungen</Button>
            <Button disabled={buttonsDisabled} onClick={() => printVorabanforderungen(api)}>Offene Vorabanforderungen</Button>
            <Button disabled={buttonsDisabled} onClick={() => printVorabpackungenOhneVorabanforderung()}>Vorabpackungen ohne Vorabanforderung</Button>
            {/*<Button disabled={buttonsDisabled} onClick={() => printListAnforderungenUnnoetig()}>Unnötige Anforderungen</Button>*/}
            <Button disabled={buttonsDisabled} onClick={() => printListPlanzeilenWarnungVertriebsstatus()}>Planzeilen: Warnung Vertriebsstatus</Button>
            <Button disabled={buttonsDisabled} onClick={() => printListPlanzeilenArzneimittelNichtInBestand()}>Planzeilen: Arzneimittel nicht in Bestand</Button>
            <Button disabled={buttonsDisabled} onClick={() => printAerzte()}>Adressbuch: Ärzte + Arzpraxen</Button>
            <Button disabled={buttonsDisabled} onClick={() => printPlanzeileMitNichtOriginalenM2()}>Planzeilen: nicht-originale M2-Nummer</Button>
            <Button disabled={buttonsDisabled} onClick={() => printAkteurDatenUnvollstaendig()}>Akteure: Daten unvollständig</Button>
            <Button disabled={buttonsDisabled} onClick={() => printPlanzeileDosierungUnpassend()}>Planzeile: Bestand muss geteilt werden</Button>
            <Button disabled={buttonsDisabled} onClick={() => printBestandUngenutzt(5)}>Bestand: Arzneimittel ungenutzt seit 5 Blisterperioden</Button>
            <Button disabled={buttonsDisabled} onClick={() => printBestandOhneBild()}>Bestand: Bild nicht eingestellt</Button>

            <Button disabled={buttonsDisabled} onClick={() => printInaktiveVersorger()}>Gelöschte Versorger, die noch verlinkt sind</Button>
            <Button disabled={buttonsDisabled} onClick={() => printUmsatzauswertung()}>Umsatzauswertung</Button>
        </p>
        
        <h2>Auswertungen als CSV-Datei</h2>
        <p>
            <Button disabled={buttonsDisabled} onClick={() => getVerfallendePackungenAlsCSV()}>Verfallende Packungen</Button>
            <Button disabled={buttonsDisabled} onClick={() => getVersorgendeAerzteAlsCSV()}>Versorgende Ärzte</Button>
            <Button disabled={buttonsDisabled} onClick={() => getAktivePatientenAlsCSV()}>Aktive Patienten</Button>
            <Button disabled={buttonsDisabled} onClick={() => getAuswertungArzneimittelAlsCSV('stock')}>Arzneimittel in Bestand</Button>
            <Button disabled={buttonsDisabled} onClick={() => getAuswertungArzneimittelAlsCSV('entitlement')}>Arzneimittel in Anspruch</Button>
            <Button disabled={buttonsDisabled} onClick={() => getAuswertungArzneimittelAlsCSV('medicationEntry')}>Arzneimittel in Planzeile</Button>
            <Button disabled={buttonsDisabled} onClick={() => getProblemkommunikationAlsCSV()}>Problemkommunikation</Button>
        </p>

        {!!auth.administrator && <p>
            <Button disabled={buttonsDisabled} onClick={resetPlanzeilen} loading={planzeileResetting} style={{marginRight: 10}}>Reichweite für alle Planzeilen neu berechnen</Button>
            {!!planzeileResetLast && <span>Die letzte Berechnung wurde am {planzeileResetLast.format("DD.MM.YYYY HH:mm")} Uhr abgeschlossen</span>}
            {!!planzeileResettingStatus && <span>Status: {planzeileResettingStatus}</span>}
        </p>}

    </div>

}