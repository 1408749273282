import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment';

import { useFetch } from '../../utilities/useFetch';
import { fetchBlisterJobDetails } from '../../config/fetchApiConfiguration';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {mengeToString} from "../../utilities/dosierschemaUtil";
import {Button, Col, Collapse, Row} from "antd";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {callApiAsync} from "../../utilities/apiUtil";
import {getMedicineM2Name} from "../../utilities/medicineM2Util";
import ImportOutlined from "@ant-design/icons/lib/icons/ImportOutlined";
import {planzeilenApi, verblisterungApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import {useApi} from "../../utilities/useApi";
import {subAmountStringToDouble} from "../../utilities/blisterJobUtil";

const { Panel } = Collapse;

export const BlisterJobDetails = ({ job, patientId, collapsible=false, onClickImportButton=null, ...props }) => {

    const auth = useAuth();
    const api = useApi();
    const jobData = useFetch(fetchBlisterJobDetails(job.id, patientId), undefined, {onError: err => {
        if (err.data.EXCEPTION_CLASS === 'de.kushiworks.java.verblisterung.ejb.db.util.CustomExceptions$NoResultException') {
            setReturnNull(true);
            return true;
        }
    }})
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [internalPackageMap, setInternalPackageMap] = useState({});
    const [entryMap, setEntryMap] = useState({});
    const [start, setStart] = useState(null);
    const [ende, setEnde] = useState(null);
    const [jobIgnoredForAmendment, setJobIgnoredForAmendment] = useState(false);
    const [typeString, setTypeString] = useState(null);
    const [returnNull, setReturnNull] = useState(false);

    useEffect(() => {
        if (job.status.startsWith("SHORTAGE_")) {
            setTypeString('Fehlmenge');
        } else if (job.status.startsWith("AMENDMENT_")) {
            setTypeString('Einzelabgabe');
        }
    }, [job])

    useEffect(() => {
        if (jobData.data) {
            const entryMapNeu = {};
            let days, dateMin, dateMax, startNeu, endeNeu, ignoreForAmendmentCounter = 0;
            for (let entry of jobData.data) {
                if (entry.ignoreForAmendment) ignoreForAmendmentCounter++;

                days = entry.days.sort((a, b) => {
                    return a.id.date - b.id.date;
                });

                dateMin = days[0].id.date;
                dateMax = days[days.length - 1].id.date;

                if (!startNeu || startNeu > dateMin) startNeu = dateMin;
                if (!endeNeu || endeNeu < dateMax) endeNeu = dateMax;

                entryMapNeu[entry.id] = {...entry, days, dateMin, dateMax};
            }

            setEntryMap(entryMapNeu);
            setStart(moment(startNeu));
            setEnde(moment(endeNeu));

            setJobIgnoredForAmendment(ignoreForAmendmentCounter === Object.keys(entryMapNeu).length);
        }
    }, [jobData.data])

    if (returnNull) return null;

    const setIgnoreForAmendment = async (value, entryIdList) => {
        const data = {value, entryIdList};
        const response = await callApiAsync({auth, url: verblisterungApi.setIgnoreForAmentment(), data, method: "post"});

        const entryMapNeu = {...entryMap};

        let days, dateMin, dateMax, startNeu, endeNeu, ignoreForAmendmentCounter = 0;
        for (let entry of response.data.OBJECT) {
            days = entry.days.sort((a, b) => {
                return a.id.date - b.id.date;
            });

            dateMin = days[0].id.date;
            dateMax = days[days.length - 1].id.date;

            if (!startNeu || startNeu > dateMin) startNeu = dateMin;
            if (!endeNeu || endeNeu < dateMax) endeNeu = dateMax;

            entryMapNeu[entry.id] = {...entry, days, dateMin, dateMax};
        }

        if (!value) for (let entry in entryMapNeu) {
            if (entry.ignoreForAmendment) ignoreForAmendmentCounter++;
        }

        setEntryMap(entryMapNeu);
        setStart(moment(startNeu));
        setEnde(moment(endeNeu));

        setJobIgnoredForAmendment(ignoreForAmendmentCounter === Object.keys(entryMapNeu).length);
    }

    const heute = moment().startOf('day');
    const responseDiv = <div>
        {jobData.loading && <LoadingOutlined />}
        {!jobData.loading && Object.values(entryMap).map((e, i) => <div key={e.id}>
                <div style={{display: "flex"}}>
                    <h6>{e.medicineM2.name} (M2#: {e.medicineM2.m2}){!e.ignoreForAmendment || jobIgnoredForAmendment ? "" : " [nicht genutzt für Änderungsaufträge]"}{!auth.developer ? "" : ` [ID: ${e.id}]`}</h6>
                    {!jobIgnoredForAmendment && e.ignoreForAmendment && <Button style={{marginLeft: 10}} onClick={() => setIgnoreForAmendment(false, [e.id])}>Für Änderungsaufträge nutzen</Button>}
                </div>

                {e.days?.map((day, index) => {
                        const packageBlistered = {};
                        let amountBlistered = 0;
                        let substituteBlistered = false;
                        const substitutePackageList = [];
                        day.blisteredPackages.forEach(blisteredPackage => {
                            if (blisteredPackage.substituteBlistered) {
                                substituteBlistered = true;

                                const packageId = blisteredPackage.id.internalPackage.id;
                                substitutePackageList.push(packageId);
                                if (!medikationsplanContext.internalPackageMap[packageId]) api.loadInternalPackage(packageId);
                            }

                            amountBlistered += blisteredPackage.amountBlistered;
                            packageBlistered[blisteredPackage.id.internalPackage.id] ?
                                packageBlistered[blisteredPackage.id.internalPackage.id] += blisteredPackage.amountBlistered :
                                packageBlistered[blisteredPackage.id.internalPackage.id] = blisteredPackage.amountBlistered;
                        });

                        const amountDay = day.amount + subAmountStringToDouble(day.subAmount);
                        const substituteString = substituteBlistered ? ", substituiert mit " + substitutePackageList.map(packageId => getMedicineM2Name(medikationsplanContext.internalPackageMap[packageId]?.batch?.id?.medicinePZN?.medicineM2) || "[wird geladen]").join(", ") : "";
                        return <div key={day.id.date}>
                            <Row>
                                <Col span={3}>{moment(day.id.date).format('ddd DD.MM.YYYY HH:mm')}</Col>
                                <Col span={1}>{mengeToString(amountDay)}</Col>
                                <Col>{amountDay === amountBlistered ? `(verblistert${substituteString})` : amountBlistered > 0 ? `(teilverblistert: ${amountBlistered}${substituteString})` : "(unverblistert)"}</Col>
                            </Row>
                            {day.intakeNote && <Row>
                                <Col span={3}>Einnahmehinweis:</Col>
                                <Col>{day.intakeNote}</Col>
                            </Row>}
                        </div>
                    }
                )}

                <Row>
                    <Col><hr /></Col>
                </Row>
            </div>
        )}
    </div >

    if (!collapsible) return responseDiv;
    else return <Collapse>
        <Panel key={job.id} header={<div style={{display: "inline-flex"}}>
            <span>Details für Job #{job.id}{job.name ? ` (${job.name})` : typeString ? ` (${typeString})` : ''} anzeigen ({Object.keys(entryMap).length} Einträge insgesamt | Zeitraum: {start?.format('ddd DD.MM.YYYY')} - {ende?.format('ddd DD.MM.YYYY')}){jobIgnoredForAmendment && " [nicht genutzt für Änderungsaufträge]"}</span>
        </div>} extra={<>
            {!medikationsplanContext.dosierabschnittSpanneCurrent && !medikationsplanContext.dosierabschnittSpannePrev && !medikationsplanContext.dosierabschnittSpanneNext && moment(job.start).isBefore(heute) &&
            <Button onClick={onClickImportButton} ><ImportOutlined /> Job importieren</Button>}

            {jobIgnoredForAmendment && <Button onClick={() => setIgnoreForAmendment(false, Object.values(entryMap).map(e => e.id))}>Für Änderungsaufträge nutzen</Button>}
        </>}>
            {responseDiv}
        </Panel>
    </Collapse>
}

