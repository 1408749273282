import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { useDataAccess } from '../../utilities/useDataAccess'
import { Fallback } from '../../config/fallbackPages'
import {Button, Input, notification, Select, Switch} from 'antd';
import {
    DEFAULT_ADRESS_TYPE,
    defaultAdresse,
    defaultKontakt,
    KONTAKT_TYPES,
    KONTAKT_TYPES_INSTITUTION
} from '../../config/entities';
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";


export const AdressbuchAdressen = ({ ...props }) => {

    const akteur = useDataAccess({
        ...props,
    });

    if (!akteur.isReady)
        return Fallback(akteur);

    return <div>

        <Adressen
            akteur={akteur.current}
            value={akteur.current.addresses}
            onChange={e => akteur.handleChange('addresses', e)}
        />
        <hr />

        <Kontakte
            akteur={akteur.current}
            value={akteur.current.contacts}
            onChange={e => akteur.handleChange('contacts', e)}
            onChangeDefaultContact={(contact, type) => {
                return akteur.handleChangeBatch([
                    {name: 'defaultContact', payload: contact?.id.name || null},
                    {name: 'defaultContactType', payload: type || null}
                ]);
            }}
        />


    </div >
};

const Adressen = ({ akteur, ...props }) => {
    const reihenfolge = useRef(new Map());
    const handleSorting = (a, b) => {
        if (!reihenfolge.current.has(a.id.type)) reihenfolge.current.set(a.id.type, reihenfolge.current.size);
        if (!reihenfolge.current.has(b.id.type)) reihenfolge.current.set(b.id.type, reihenfolge.current.size);

        return reihenfolge.current.get(a.id.type) - reihenfolge.current.get(b.id.type);
    }

    const adressen = useDataAccess({ handleSorting, ...props });
    const [anschriftTyp, setAnschriftTyp] = useState("");

    if (adressen.current && !adressen.current.length) adressen.current.push({id: {type: DEFAULT_ADRESS_TYPE, unitID: akteur.id}});

    const checkAnschriftTyp = () => {
        return DEFAULT_ADRESS_TYPE.toLowerCase() === anschriftTyp.toLowerCase();
    };

    return <div className={"adressbuch-adressen"}>
        <h4>Adressen</h4>
        {adressen.current && adressen.current.map((adresse, index) => {
            // Todo use memo, starts infinite render loop atm
            // if (adresse.id.type === DEFAULT_ADRESS_TYPE)
            //     setAnschriftTyp("sonstige");
            return <Adresse
                disabled={!akteur.active}
                className={index > 0 ? "bar" : ""}
                fullName={akteur.type === "Person"}
                onDelete={(e) => adressen.handleChange(index, {}, "remove")}
                key={adresse.id.type}
                value={adresse}
                onChange={e => adressen.handleChange(index, e)}
            />
        }
        )}
        <hr />
        <Input placeholder="Bezeichnung" value={anschriftTyp} onChange={(e) => setAnschriftTyp(e.target.value)} style={{width: "50%"}} disabled={!akteur.active} />
        <Button disabled={!akteur.active || checkAnschriftTyp()} onClick={() => {
            if (anschriftTyp) {
                adressen.handleChange("-", defaultAdresse({akteur, type: anschriftTyp}), "add");
            } else {
                notification.error({message: "Für diesen Vorgang muss eine Bezeichnung eingegeben werden!"});
            }
        }}><PlusCircleOutlined /></Button>
    </div>
};


const Adresse = ({ fullName=false, className="", disabled, ...props }) => {
    const adresse = useDataAccess({ ...props, });
    const [inputVal, setInputVal] = useState({});

    const timeoutRef = useRef(null);
    useEffect(() => {
        if (!Object.keys(inputVal).length) return;

        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            const neu = {...adresse.current, ...inputVal};
            adresse.handleChange(null, neu);
        }, 100);
    }, [inputVal])

    const changeInput = (key, value) => {
        setInputVal(prev => ({...prev, [key]: value}))
    }

    if (!adresse.isReady)
        return Fallback(adresse);

    return <div className={"adresse " + className}>
        <h5>
            <Button type="danger" onClick={props.onDelete} disabled={disabled} ><DeleteOutlined /></Button> {adresse.current.id.type}</h5>

        {fullName ? <p>
            <Input
                disabled={disabled}
                className={"titel"}
                placeholder="Titel"
                value={inputVal.title || adresse.current.title}
                onChange={e => changeInput('title', e.target.value)}
            />
            <Input
                disabled={disabled}
                placeholder="Vorname"
                value={inputVal.firstName || adresse.current.firstName}
                onChange={e => changeInput('firstName', e.target.value)}
            />
            <Input
                disabled={disabled}
                placeholder="Nachname"
                value={inputVal.name || adresse.current.name}
                onChange={e => changeInput('name', e.target.value)}
            />
        </p> : <p>
            <Input
                disabled={disabled}
                placeholder="Name"
                value={inputVal.name || adresse.current.name}
                onChange={e => changeInput('name', e.target.value)}
            />
        </p>}

        <p>
            <Input
                disabled={disabled}
                placeholder="Adresse"
                value={inputVal.street || adresse.current.street}
                onChange={e => changeInput('street', e.target.value)}
            />
            <Input
                disabled={disabled}
                placeholder="Adresszusatz"
                value={inputVal.streetAddition || adresse.current.streetAddition}
                onChange={e => changeInput('streetAddition', e.target.value)}
            />
        </p>

        <p>
            <Input
                disabled={disabled}
                placeholder="PLZ"
                value={inputVal.postalCode || adresse.current.postalCode}
                onChange={e => changeInput('postalCode', e.target.value)}
            />
            <Input
                disabled={disabled}
                placeholder="Stadt"
                value={inputVal.town || adresse.current.town}
                onChange={e => changeInput('town', e.target.value)}
            />
        </p>
    </div>
};



const Kontakte = ({ akteur, onChangeDefaultContact=()=>{}, ...props }) => {
    const reihenfolge = useRef(new Map());

    const contacts = useDataAccess({ ...props});
    const [kontaktTyp, setKontaktTyp] = useState("");
    const contactsSorted = useMemo(() => {
        if (!contacts.current) return null;

        const result = [...KONTAKT_TYPES, ...(akteur.type === 'Institution' ? KONTAKT_TYPES_INSTITUTION : [])].reduce(
            (arr, name) => {
                if (arr && !arr.find(contact => contact.id.name === name)) {
                    arr.push({id: {name, unitID: akteur.id}});
                }

                return arr;
            }, [...contacts.current]
        );

        if (reihenfolge.current.size < result.length) {
            if (!reihenfolge.current.size) result.sort((a, b) => a.id.name.localeCompare(b.id.name));

            result.forEach(c => {
                if (!reihenfolge.current.has(c.id.name)) reihenfolge.current.set(c.id.name, reihenfolge.current.size);
            });
        }

        result.sort((a, b) => reihenfolge.current.get(a.id.name) - reihenfolge.current.get(b.id.name));
        return result;
    }, [contacts.current]);

    const checkKontaktTyp = () => {
        return KONTAKT_TYPES.find(name => name.toLowerCase() === kontaktTyp.toLowerCase());
    };

    return <div className={"adressbuch-kontakte"}>
        <h4>Kontakte</h4>
        {contactsSorted?.map((contact, index) => {
            return <Kontakt
                disabled={!akteur.active}
                className={index > 0 ? "bar" : ""}
                onDelete={(e) => contacts.handleChange(contacts.current.indexOf(contact), {}, "remove")}
                key={contact.id.name}
                value={contact}
                onChange={e => contacts.handleChange(contacts.current.indexOf(contact), e)}
                isDefault={akteur.defaultContact === contact.id.name && akteur.defaultContactType}
                setIsDefault={onChangeDefaultContact}
            />
        })}

        <hr />
        <input placeholder="Bezeichnung" value={kontaktTyp} onChange={(e) => setKontaktTyp(e.target.value)} style={{width: "50%"}} disabled={!akteur.active} />
        <Button disabled={!akteur.active || checkKontaktTyp()} onClick={() => {
            if (kontaktTyp) {
                contacts.handleChange("-", defaultKontakt({akteur, name: kontaktTyp}), "add");
            } else {
                notification.error({message: "Für diesen Vorgang muss eine Bezeichnung eingegeben werden!"});
            }
        }}><PlusCircleOutlined /></Button>
    </div>
};


const kontaktTypen = ["TEL", "FAX", "EMAIL", "KIM"];
const Kontakt = ({ className="", disabled, isDefault, setIsDefault=()=>{}, ...props }) => {
    const contact = useDataAccess({ ...props, });
    const [inputVal, setInputVal] = useState({});

    const [showDefaultDropdown, setShowDefaultDropdown] = useState(!!isDefault);
    const [defaultContactType, setDefaultContactType] = useState(isDefault);
    useEffect(() => {
        if (!isDefault && showDefaultDropdown) {
            setShowDefaultDropdown(false);
            setDefaultContactType(null);
        }
    }, [isDefault]);

    const timeoutRef = useRef(null);
    useEffect(() => {
        if (!Object.keys(inputVal).length) return;

        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            const neu = {...contact.current, ...inputVal};
            contact.handleChange(null, neu);
        }, 100);
    }, [inputVal])

    const changeInput = (key, value) => {
        setInputVal(prev => ({...prev, [key]: value}))
    }

    if (!contact.isReady)
        return Fallback(contact);

    return <div className={"kontakt " + className} key={contact?.current?.name}>
        <h5><Button type="danger" onClick={props.onDelete} disabled={disabled} ><DeleteOutlined /></Button> {contact.current.id.name}</h5>
        <div>
            <span style={{marginRight: 10}}>Als Standard für Kommunikation festlegen:</span>
            <Switch checked={showDefaultDropdown} onChange={value => {
                setShowDefaultDropdown(value);
                if (isDefault && !value) setIsDefault();
            }} />
            <span style={{marginRight: 10}} />
            {showDefaultDropdown && <Select value={defaultContactType} onChange={value => {
                setDefaultContactType(value);
                setIsDefault(contact.current, value);
            }} dropdownMatchSelectWidth={false} defaultOpen={!isDefault}>
                {kontaktTypen.map(name => <Select.Option key={name} value={name} />)}
            </Select>}
        </div>
        <p>
            <Input
                disabled={disabled}
                style={{width: "100%"}}
                value={inputVal.value || contact.current.value}
                onChange={e => changeInput('value', e.target.value)}
            />
        </p>
    </div>
};
