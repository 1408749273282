import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Input, Row, Select, Table, notification} from "antd";
import TextArea from "antd/es/input/TextArea";
import {HotKeys} from "react-hotkeys";
import {useAuth} from "../../utilities/useAuth";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import {Tooltip} from "../atoms/Tooltip";
import WindowContext from "../../contexts/WindowContext";
import * as moment from "moment";
import useNavigation from "../../utilities/useNavigation";
import {useApi} from "../../utilities/useApi";
import MitteilungContext from "../../contexts/MitteilungContext";
import {AkteurPicker} from "../atoms/AkteurPicker";
import {callApiAsync} from "../../utilities/apiUtil";
import {BASE_URL} from "../../config/apiConfig";
import {getUnitLangname} from "../../config/entities";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import Moveable from "react-moveable";
import {Link} from "react-router-dom";
import {encodeBase64} from "../../utilities/encodingUtil";

const inputValDefault = {empfaenger: [{unit: null, address: null}], empfaengerUnit: {}};

const MitteilungModal = ({cardId}) => {
    const CARD_ID = cardId;

    const auth = useAuth();
    const navigation = useNavigation();
    const api = useApi();
    const windowContext = useContext(WindowContext);
    const mitteilungContext = useContext(MitteilungContext);

    const moveableRef = useRef();
    const parentRef = useRef();
    const isFocused = useRef(false);
    const anschtiftenMap = useRef({});

    const [movableTarget, setMovableTarget] = useState(null);
    const [movableFrame, setMovableFrame] = useState({translate: [0, 0]});
    const [dragTarget, setDragTarget] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [skipSaveOnClose, setSkipSaveOnClose] = useState(false);

    const [inputVal, setInputVal] = useState(inputValDefault);

    const notify =

    useEffect(() => {
        isFocused.current = windowContext.activeId === CARD_ID;
    }, [windowContext.activeId])

    useEffect(() => {
        if (movableTarget) {
            const rect = moveableRef.current.getRect();
            const win = window,
                doc = document,
                docElem = doc.documentElement,
                body = doc.getElementsByTagName('body')[0],
                viewPortWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                viewportHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

            const x = ((viewPortWidth - rect.width) / 2);
            const y = ((viewportHeight - rect.height) / 4) + window.scrollY;

            const targetHeight = 150;
            const deltaHeight = targetHeight - rect.height;

            moveableRef.current.request("draggable", {x, y}, true);
            moveableRef.current.request("resizable", {deltaHeight}, true);
        }

        checkMoveableHeight();
    }, [movableTarget])

    useEffect(() => {
        checkMoveableHeight();
    }, [collapsed])

    const checkMoveableHeight = () => {
        const rect = moveableRef.current.getRect();
        const targetHeight = rect.height;

        const cardBody = document.querySelector(`#${CARD_ID} .card-body`);
        const scrollHeight = cardBody.scrollHeight;
        if (scrollHeight > targetHeight) {
            moveableRef.current.request("resizable", {deltaHeight: scrollHeight - targetHeight}, true);
        }
    }

    useEffect(() => {
        if (mitteilungContext.visible) {
            windowContext.setActiveId(CARD_ID);
            resetMoveable();
        } else {
            setSkipSaveOnClose(false);
            setMovableTarget(null);
            setDragTarget(null);

            if (windowContext.activeId === CARD_ID) {
                windowContext.setActiveId(null);
            }

            resetInputs();
        }
    }, [mitteilungContext.visible])

    useEffect(() => {
        checkMoveableHeight();
    }, [inputVal])

    const resetInputs = () => {
        setInputVal(inputValDefault);
    }

    const resetMoveable = () => {
        const cardSelector = document.querySelector(`#${CARD_ID}`);
        setMovableTarget(cardSelector);
        setDragTarget(cardSelector.querySelector(".draggable-target"));
    }

    const onChange = (key, value) => {
        setInputVal(prev => ({...prev, __changed__: true, [key]: value}))
    }

    const onSave = async () => {
        if (Object.keys(inputVal).length) {
            const data = {
                ...mitteilungContext.mitteilung,

                titel: inputVal.titel,
                text: inputVal.text,
                empfaenger: inputVal.empfaenger,
                anforderungen: mitteilungContext.anforderungen.map(a => a.id),
            };

            mitteilungContext.onSave(data);

            //window.open(BASE_URL +`anforderung/mitteilung-voransicht?token=${auth.token}&body=${encodeBase64(JSON.stringify(data))}`);
            notification.info({message: <div>
                    Die Mitteilung wurde erfolgreich erstellt. <Button onClick={() => window.open(BASE_URL +`anforderung/mitteilung-voransicht?token=${auth.token}&body=${encodeBase64(JSON.stringify(data))}`)}>anzeigen</Button>
                </div>})
            const response = await callApiAsync({url: BASE_URL + `anforderung/mitteilung`, auth, method: 'post', data});
            const nachrichten = response.data.OBJECT;
        } else {
            mitteilungContext.resetModal();
        }
    }

    const changeEmpfaenger = (change, idx) => {
        idx = idx ?? inputVal.empfaenger.length-1;

        const arrNeu = [...inputVal.empfaenger];
        const neu = {...arrNeu[idx], ...change};
        arrNeu.splice(idx, 1, neu);

        onChange('empfaenger', arrNeu);
    }

    const changeEmpfaengerUnit = (unit, idx) => {
        idx = idx ?? inputVal.empfaenger.length-1;
        onChange('empfaengerUnit', {...inputVal.empfaengerUnit, [idx]: unit});
        changeEmpfaenger({unit: unit.id}, idx);
    }

    const onSaveDisabled = !inputVal["__changed__"] || !inputVal['titel'] || !inputVal['empfaenger']?.[0]?.unit;

    return <div className={"card-container"} style={{position: "absolute", top: 0, left: 0, width: "100%", height: 0, display: mitteilungContext.visible ? "block" : "none", zIndex: windowContext.activeId === CARD_ID ? 1 : 0}}>
        <div
            ref={parentRef}
            id={CARD_ID}
            onClick={() => windowContext.setActiveId(CARD_ID)}
            style={{position: "absolute", display: "flex", flexDirection: "column", width: "50%", height: 355, backgroundColor: "#fff"}}
        >
            <div style={{display: "flex", flexDirection: "column", overflow: "hidden", flex: 1}}>
                <div className={"card-body"} style={{display: "flex", flexDirection: "column", flex: 1, padding: 10}}>
                    <Col className={"draggable-target"} style={{textAlign: "center", verticalAlign: "middle", cursor: "move"}}>
                        <span aria-label={"close"} style={{position: "absolute", top: 0, right: 0, lineHeight: 0, padding: 10, cursor: "pointer"}} onClick={e => { if (!skipSaveOnClose && !onSaveDisabled) onSave(); else mitteilungContext.resetModal(); }}><Tooltip title={"Fenster schließen"}><CloseOutlined /></Tooltip></span>
                        {/*<span aria-label={"collapse"} style={{position: "absolute", top: 10, left: 0, lineHeight: 0, padding: 10}} onClick={e => setCollapsed(!collapsed)}>*/}
                        {/*    {collapsed ? <CaretRightOutlined /> : <CaretDownOutlined />}*/}
                        {/*</span>*/}
                        <h3>Mitteilung {!!mitteilungContext.mitteilung?.id ? "bearbeiten" : "erstellen"}</h3>
                        {!!mitteilungContext.mitteilung?.dateErstellt && <span style={{lineHeight: 0, color: "darkgrey"}}>
                            {!!mitteilungContext.mitteilung?.id && `#${mitteilungContext.mitteilung?.id} - `}
                            Erstellt am {moment(mitteilungContext.mitteilung?.dateErstellt).format("DD.MM.YYYY")} von {mitteilungContext.mitteilung?.accountErstellt}

                            {!!mitteilungContext.mitteilung?.accountZuletztBearbeitet && ` - zuletzt bearbeitet am ${moment(mitteilungContext.mitteilung?.dateZuletztBearbeitet).format("DD.MM.YYYY")} von ${mitteilungContext.mitteilung?.accountZuletztBearbeitet}`}
                        </span>}
                    </Col>

                    <Row style={{flex: 0}}>
                        <Col span={24} style={{display: "flex"}}>
                            <Tooltip title={"Dieser Text wird in jeder Anforderung als Kommentar angefügt."}><Input placeholder={"Titel"} value={inputVal.titel} onChange={e => {
                                onChange("titel", e.target.value);
                            }} /></Tooltip>
                        </Col>
                    </Row>
                    <Row style={{flex: 1}}>
                        <Col span={24}>
                            <HotKeys keyMap={{SAVE: "ctrl+enter", SELECT_ALL: "ctrl+a"}} handlers={{SAVE: e => onSave()}} style={{height: "100%"}}>
                                <Tooltip title={"Dieser Text wird einmalig in der Mitteilung als Textblock ausgegeben."}><TextArea placeholder={"Inhalt der Mitteilung"} onChange={e => onChange("text", e.target.value)} value={inputVal.text} style={{minHeight: 150, height: "100%", resize: "none"}} /></Tooltip>
                            </HotKeys>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 20}}><h5>Mitteilung für Anforderungen:</h5></Row>
                    <Table dataSource={mitteilungContext.anforderungen} rowKey={'id'} columns={[
                        {
                            title: 'Patient',
                            key: 'patient',
                            render: (a) => <Tooltip title={'Patient als Akteur eintragen'}><a onClick={() => changeEmpfaengerUnit({...a.planzeile.medikationsplan.patient})}>{getUnitLangname(a.planzeile.medikationsplan.patient)}</a></Tooltip>,
                        },
                        {
                            title: 'Arzneimittel',
                            key: 'arzneimittel',
                            render: (a) => getArzneimittelLangname(a.arzneimittel)
                        },
                        {
                            title: 'Verordner',
                            key: 'verordner',
                            render: (a) => <Tooltip title={'Verordner als Akteur eintragen'}><a onClick={() => changeEmpfaengerUnit({...a.verordner})}>{getUnitLangname(a.verordner, 'arzt')}</a></Tooltip>
                        },
                        {
                            title: 'Angefordert',
                            key: 'angefordert',
                            render: (a) => a.datumAngefordert ? moment(a.datumAngefordert).format("DD.MM.YYYY") : ""
                        },
                        {
                            title: 'Gemahnt',
                            key: 'gemahnt',
                            render: (a) => {
                                let gemahntStringArray = [];
                                if (a.datumMahnung1) gemahntStringArray.push((a.datumMahnung2 ? "1: ": '')+moment(a.datumMahnung1).format("DD.MM.YYYY"));
                                if (a.datumMahnung2) gemahntStringArray.push("2: "+moment(a.datumMahnung2).format("DD.MM.YYYY"));
                                if (a.datumMahnung3) gemahntStringArray.push("3: "+moment(a.datumMahnung3).format("DD.MM.YYYY"));

                                return gemahntStringArray.map(s => <div>{s}</div>);
                            }
                        },
                    ]} />

                    <Row style={{marginTop: 20}}><h5>Empfänger: <Tooltip title={'Empfänger hinzufügen'}><Button onClick={() => onChange('empfaenger', [...inputVal.empfaenger, {unit: null, address: null}])}>+</Button></Tooltip></h5></Row>
                    {inputVal.empfaenger.map((empfaenger, idx) => {
                        return <Empfaenger key={idx} empfaenger={empfaenger} unit={inputVal.empfaengerUnit[idx]} onChange={neu => changeEmpfaenger(neu, idx)} />
                    })}

                    <Row style={{marginTop: 20}}>
                        <Col span={12}>
                            <Button type={"primary"} onClick={onSave} disabled={onSaveDisabled}>Mitteilung erstellen</Button>
                        </Col>
                        <Col span={12} style={{textAlign: "right"}}>

                        </Col>
                    </Row>
                </div>
            </div>
        </div>

        <Moveable
            ref={moveableRef}
            className={"moveable-" + CARD_ID}
            target={movableTarget}
            origin={false}
            translateZ={windowContext.activeId === CARD_ID ? 1 : -1}

            draggable={true}
            dragTarget={dragTarget}
            throttleDrag={0}
            onDragStart={({ set }) => {
                set(movableFrame.translate);
            }}
            onDrag={({ target, beforeTranslate }) => {
                movableFrame.translate = beforeTranslate;
                target.style.transform
                    = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
            }}
            onDragEnd={({ target, isDrag, clientX, clientY }) => {

            }}

            resizable={true}
            throttleResize={0}
            keepRatio={false}
            onResizeStart={({ target, set, setOrigin, dragStart }) => {
                // Set origin if transform-orgin use %.
                setOrigin(["%", "%"]);

                // If cssSize and offsetSize are different, set cssSize. (no box-sizing)
                const style = window.getComputedStyle(target);
                const cssWidth = parseFloat(style.width);
                const cssHeight = parseFloat(style.height);
                set([cssWidth, cssHeight]);

                // If a drag event has already occurred, there is no dragStart.
                dragStart && dragStart.set(movableFrame.translate);
            }}
            onResize={({ target, width, height, drag }) => {
                target.style.width = `${width}px`;
                target.style.height = `${height}px`;

                // get drag event
                movableFrame.translate = drag.beforeTranslate;
                target.style.transform
                    = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;

                const offsetHeight = document.querySelector(`#${CARD_ID} .card-body`).offsetHeight;
                if (offsetHeight > height) {
                    target.style.height = `${offsetHeight}px`;
                }
            }}
            onResizeEnd={({ target, isDrag, clientX, clientY }) => {

            }}
        />
    </div>
}
export default MitteilungModal;

const Empfaenger = ({empfaenger, unit=undefined, onChange:onChangeProp=(neu)=>{}}) => {
    const anschtiftenMap = useRef({});
    const [inputVal, setInputVal] = useState({empfaenger: [{unit: null, address: null}], empfaengerUnit: {}});

    const setSchnellsucheRef = useRef();

    useEffect(() => {
        if (inputVal['unit']) {
            onChangeProp({unit: inputVal['unit'], address: inputVal['address']});
        }
    }, [inputVal])

    useEffect(() => {
        setSchnellsucheRef.current(unit ? getUnitLangname(unit) : null);
        onChangeAkteur(unit);
    }, [unit])

    useEffect(() => {
        if (!empfaenger?.unit) {
            setSchnellsucheRef.current(null);
        }
    }, [empfaenger])

    const onChange = (key, value) => {
        setInputVal(prev => ({...prev, __changed__: true, [key]: value}));
    }

    const onChangeAkteur = neu => {
        let anschriftenNeu = null;
        let anschriftNeu = null;
        const adressen = neu?.addresses;
        if (adressen?.length) {
            anschriftenNeu = [];
            anschtiftenMap.current = adressen.reduce((map, a) => {
                if (a.id.type === 'Hauptanschrift')
                    anschriftNeu = a;

                const key = `${a.id.unitID}_${a.id.type}`;
                map[key] = a;

                const name = a.name || neu.surname || neu.name;
                const firstName = a.firstName || (neu.surname ? neu.name : null);
                let details;
                if (a.street && a.postalCode && a.town) {
                    details = `${a.street}, ${a.streetAddition ? a.streetAddition +', ' : ''}${a.postalCode} ${a.town}`;
                } else {
                    details = '';
                }

                anschriftenNeu.push({label: `${a.id.type}: ${name}${firstName ? ', '+firstName : ''}${details ? ': '+details : ''}`, value: key});

                return map;
            }, {});

            anschriftNeu = anschriftNeu || adressen[0];
        }

        onChange('anschriften', anschriftenNeu || [{label: 'K/A', value: '_'}]);
        onChange('anschrift', anschriftNeu ? `${anschriftNeu.id.unitID}_${anschriftNeu.id.type}` : '_');

        onChange('unit', neu?.id);
        onChange('address', anschriftNeu?.id?.type);
    }

    return <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
        <span>Akteur:</span>
        <AkteurPicker value={empfaenger.unit} setSchnellsucheRef={setSchnellsucheRef} onChange={onChangeAkteur} />

        {!!inputVal.unit && <>
            <span>Anschrift:</span>
            <Select options={inputVal.anschriften} value={inputVal.anschrift} onChange={neu => {
                onChange('anschrift', neu);
                onChange('address', anschtiftenMap.current[neu]?.id?.type);
            }} />
        </>}
    </div>
}