import React, {useState} from 'react'

import {DatePicker} from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';
import {ConditionalPopover} from "./ConditionalPopover";


export const InputDatum = ({
    value,
    label,
    onChange,

    allowClear = true,
    format = "DD.MM.YYYY",

    selectsEnd = false,
    startDate = null,

    markDay = null,
    markWeekDay = null,

    minDate = null,
    maxDate = null,
    placeholder = "Datum auswählen",
    suffixIcon = null,
    popover = null,
}) => {

    const [isOpen, setIsOpen] = useState(false);

    const dateRender = (datum) => {
        const style = {};

        if (markWeekDay && datum.weekday()+1 === markWeekDay) {
            style.border = '2px solid #51B588';
            style.borderRadius = '50%';
        }

        if (markDay && datum.isSame(markDay)) {
            style.backgroundColor = '#5089B1';
            style.borderRadius = '50%';
        }

        if (selectsEnd && startDate) {
            if (!datum.isAfter(value) && datum.isAfter(startDate)) {
                style.backgroundColor = '#eee';
            }
        }

        if (value && datum.isSame(value)) {
            if (selectsEnd) {
                style.borderLeft = '0px';
            }

            else {
                style.backgroundColor = '#ccc';
                style.borderRadius = '0%';
                style.border = '1px solid black';
            }
        }


        return (
            <div className="ant-picker-cell-inner" style={style}>
                {datum.date()}
            </div>
        );
    }

    let dynamicProps = {};
    if (suffixIcon || suffixIcon === false) dynamicProps.suffixIcon = suffixIcon;

    return <ConditionalPopover content={popover}>
        <DatePicker
            dateRender={dateRender}
            value={!isOpen && value ? moment(value) : null}
            onOpenChange={setIsOpen}
            onChange={e => onChange(!e ? null : e.valueOf(), label)}
            locale={locale}
            format={format}
            allowClear={allowClear}
            placeholder={placeholder}
            disabledDate={current => {
                if (!current) return true;

                const today = current.startOf('day');
                return minDate && today.isBefore(moment(minDate).startOf('day')) || maxDate && today.isAfter(moment(maxDate).startOf('day'));
            }}

            {...dynamicProps}
        />
    </ConditionalPopover>
}