import React, {useContext, useEffect, useState} from 'react'

import moment from 'moment';
import {REICHWEITE_MINIMAL, REICHWEITE_PRUEFUNG} from "../../config/misc";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";


export const ReichweiteBar = ({value, wochen, style={}}) => {

    const medikationsplanContext = useContext(MedikationsplanContext);

    const [backgroundColor, setBackgroundColor] = useState("none");
    const [tminus, setTminus] = useState(-1);
    const [width, setWidth] = useState("0");

    useEffect(() => {
        let tminusNeu;

        if (!wochen && !value) {
            tminusNeu = -1;
        } else if (wochen && !value) {
            tminusNeu = wochen;
        } else {
            // Für wieviele Wochen genügt der Anspruch noch
            tminusNeu = moment(value).diff(moment(), "weeks")
            if (tminusNeu < 0) tminusNeu = 0;
        }


        // Keine formatierungsunterschiede ab Obergrenze
        if (tminusNeu > REICHWEITE_MINIMAL) tminusNeu = REICHWEITE_MINIMAL;
        let backgroundColorNeu;

        if (tminusNeu < 0 || moment(medikationsplanContext.anzeigeDatum).startOf('day').valueOf() !== moment().startOf('day').valueOf()) backgroundColorNeu = "lightgrey";
        else switch (tminusNeu) {

            case REICHWEITE_MINIMAL:
                backgroundColorNeu = "aquamarine"
                break;

            case 5:
                backgroundColorNeu = "green"
                break;

            case 4:
                backgroundColorNeu = "yellow"
                break;

            case 3:
                backgroundColorNeu = "orange"
                break;

            case 2:
                backgroundColorNeu = "red"
                break;

            default:
                backgroundColorNeu = "darkred"
                break;
        }

        setTminus(tminusNeu);
        setBackgroundColor(backgroundColorNeu);
        setWidth(90 / REICHWEITE_MINIMAL * Math.abs(tminusNeu < 0 ? REICHWEITE_MINIMAL : tminusNeu) + "%");
    }, [value, wochen, medikationsplanContext.anzeigeDatum])

    if (tminus !== 0)
        return <div style={{marginBottom: 10, backgroundColor, width, height: 5, ...style}}>&nbsp;</div>
    else
        return <span style={{
            marginBottom: 10,
            fontWeight: "bolder",
            color: "white",
            textAlign: "center",
            backgroundColor: "red",
            padding: "0px 20px",
            ...style
        }}>Unzureichend für nächste Blisterperiode!</span>
}

