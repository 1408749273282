import React, {useEffect, useState} from "react";
import {useAuth} from "../../utilities/useAuth";
import {useDataAccess} from "../../utilities/useDataAccess";
import {AdressbuchSonstigesArzt} from "./AdressbuchSonstigesArzt";
import {AdressbuchSonstigesPatient} from "./AdressbuchSonstigesPatient";
import DokumentListeTabelle from "./DokumentListeTabelle";
import {useFetch} from "../../utilities/useFetch";
import {dokumenteApi} from "../../config/apiConfig";
import useDateiVorschau from "../../utilities/useDateiVorschau";
import {callApiAsync} from "../../utilities/apiUtil";
import {Loading} from "../organisms/StatusDisplay";

export const AdressbuchSonstiges = (props) => {
    const akteur = useDataAccess({
        ...props,
    });

    const [type, setType] = useState(null);

    useEffect(() => {
        if (akteur.current) {
            // ermittle den Typ des Akteurs
            if (akteur.current.roles && akteur.current.roles.length) {
                const role = akteur.current.roles.find(role => ["Patient", "Arztpraxis"].includes(role.name));
                if (role) {
                    setType(role.name);
                }
            }
        }
    }, [akteur.current])

    return <div>
        <h4>Sonstiges</h4>

        {type === "Patient" && <div>
            <AdressbuchSonstigesPatient
                value={akteur.current}
                onChange={e => akteur.handleChange(null, e)}
            />
        </div>}

        {type === "Arztpraxis" && <div>
            <AdressbuchSonstigesArzt
                value={akteur.current}
                onChange={e => akteur.handleChange(null, e)}
            />
        </div>}
    </div >
}