import moment from "moment";
import {naechsterWochentag} from "./myDates";

export const getExportStart = (medikationsplan, lastExportEnde, naechsterWochentagReferentDatum) => {
    if (lastExportEnde == null) return null;

    let exportStart = null;

    // Exportiere ab dem ersten Tag nach dem letzten Blisterjob
    if (lastExportEnde > 0) {
        exportStart = moment(lastExportEnde).add(1, "d").startOf('day').valueOf();
    }

    // Exportiere ab dem nächsten Wochentag gemäß medikationsplan.blisterStartTag
    if (lastExportEnde === 0 || exportStart < moment().startOf("day").valueOf()) {
        let startDatum = naechsterWochentag(medikationsplan.blisterStartTag, naechsterWochentagReferentDatum);
        exportStart = moment(startDatum).startOf('day').valueOf();
    }

    return exportStart;
}

export const getExportEnde = (exportStart, medikationsplan, lastExportEnde) => {
    if (!exportStart) return;

    // Berechne die Lücke zum letzten Blister
    const startLuecke = getStartLuecke(exportStart, lastExportEnde);

    // berechne das vorgeschlagene Ende
    const nw = naechsterWochentag(medikationsplan.blisterStartTag, exportStart);
    let endeMoment = moment(nw);
    if (nw === exportStart) {
        endeMoment.add(6, 'd');
    } else {
        endeMoment.subtract(1, 'd');
    }
    const tageAusgleich = moment(endeMoment).diff(exportStart, 'd');

    return {exportEnde: endeMoment.endOf('day').valueOf(), startLuecke, tageAusgleich};
}

export const getStartLuecke = (exportStart, lastExportEnde) => {
    if (lastExportEnde === 0) {
        return 0;
    }

    const startMoment = moment(exportStart).startOf("day");
    const lastExportEndeMoment = moment(lastExportEnde).startOf("day");
    const diffTage = startMoment.diff(lastExportEndeMoment, 'days');

    return diffTage - 1;
}

export const subAmountStringToDouble = (subAmountString) => {
    let faktor = 1.0;
    if (subAmountString.startsWith("-")) {
        faktor = -1.0;
        subAmountString = subAmountString.substring(1);
    }

    switch (subAmountString) {
        case "2":
            return faktor * 0.25;
        case "3":
            return faktor / 3.0;
        case "5":
            return faktor * 0.50;
        case "6":
            return faktor * 2.0 / 3.0;
        case "7":
            return faktor * 0.75;
    }

    return 0.0;
}