import React from "react";
import {ConditionalPopover} from "./ConditionalPopover";

export const Popover = ({children, pointerEvents="auto", onVisibleChange, visible, content, placement, trigger="click", ...props}) => {
    const attr = {...props, content, placement, trigger};
    if (pointerEvents) attr.pointerEvents = pointerEvents;
    if (onVisibleChange) attr.onVisibleChange = onVisibleChange;
    if (visible === true || visible === false) attr.visible = visible;

    return <ConditionalPopover {...attr}>{children}</ConditionalPopover>
}