import React, {useContext, useEffect} from 'react'
import {Route, Redirect, Switch, useHistory} from 'react-router-dom';


// ~~~~~~~~~~~~~~~~~~~~~~~~~ Pages ~~~~~~~~~~~~~~~~~~~~~~~ //

import { Medikationsplan } from './pages/Medikationsplan'

import { Default } from './templates/defaultPages'
import { Adressbuch } from './pages/Adressbuch'
import { Nachrichten } from './pages/Nachrichten'
import { Aufgaben } from './pages/Aufgaben'
import { Dokumente } from './pages/Dokumente'
import { PopupDokumenteAnsicht } from './pages/PopupDokumenteAnsicht'
import { Benutzer } from './pages/Benutzer'
import { Settings } from './pages/Settings'
import { Hilfe } from './pages/Hilfe'

import {VerblisterungDashboard} from "./pages/VerblisterungDashboard";
import Home from "./pages/Home";
import queryString from "query-string";
import moment from "moment";
import {GlobalContext} from "../config/globalContext";
import Problemfaelle from "./pages/Problemfaelle";


/**
 * übernimmt das routing zu pages-Komponenten
 * @see {@link App }
 */
const Vista = () => {
  const history = useHistory();

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    if (history.location) {
      const query = queryString.parse(history.location.search);
      if (query.heute) {
        const heute = moment(query.heute).startOf('day');
        globalContext.setHeute(heute);
      } else {
        globalContext.setHeute(moment().startOf('day'));
      }
    }
  }, [history.location])

  return !globalContext.heute ? <></> : <>
    <Switch>
      {/* Default Startseite */}
      <Redirect exact from="/" to="/home" />
      <Route exact path="/home" component={Home} />

      {/* Popups ohne Navbar */}
      <Route exact path="/vorschau/dokument/:dokumentId" component={PopupDokumenteAnsicht} />
      <Route exact path="/vorschau/planzeile/:planzeileId" component={PopupDokumenteAnsicht} />
      <Route exact path="/vorschau/patient/:patientId" component={PopupDokumenteAnsicht} />


      {/* Sonstiges Routing */}
      <Route exact path="/verblisterung" component={VerblisterungDashboard} />
      <Route exact path="/nachrichten" component={Nachrichten} />
      <Route exact path="/aufgaben" component={Aufgaben} />

      <Route exact path="/adressbuch" component={Adressbuch} />
      <Route exact path="/adressbuch/:akteurId" component={Adressbuch} />
      <Route exact path="/adressbuch/:akteurId/:tab" component={Adressbuch} />
      <Route exact path="/adressbuch/:akteurId/:tab/:contentParam1" component={Adressbuch} />
      <Route exact path="/adressbuch/:akteurId/:tab/:contentParam1/:contentParam2" component={Adressbuch} />

      <Route exact path="/medikationsplan" component={Medikationsplan} />
      <Route exact path="/medikationsplan/:planId" component={Medikationsplan} />
      <Route exact path="/medikationsplan/:planId/:ansicht" component={Medikationsplan} />
      <Route exact path="/medikationsplan/:planId/:ansicht/:planzeileId" component={Medikationsplan} />

      <Route exact path="/problemfaelle" component={Problemfaelle} />

      <Route exact path="/hilfe" component={Hilfe} />
      <Route exact path="/hilfe/:_filter" component={Hilfe} />

      <Route exact path="/dokumente" component={Dokumente} />
      <Route exact path="/dokumente/:_id" component={Dokumente} />

      <Route exact path="/benutzer" component={Benutzer} />
      <Route exact path="/settings" component={Settings} />

      {/* 404 */}
      <Route render={(props) => <Default {...props} pagename="404 - Seite nicht gefunden" />} />
    </Switch>
  </>;
}


export default Vista