import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../utilities/useAuth";
import {useApi} from "../../utilities/useApi";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {fetchExternalPackageListByPatient} from "../../config/fetchApiConfiguration";
import {callApiAsync} from "../../utilities/apiUtil";
import {Table} from "antd";
import {getMedicineM2Name} from "../../utilities/medicineM2Util";
import moment from "moment";
import {getSorter} from "../../utilities/sortUtil";
import {getUnitLangname} from "../../config/entities";
import {getColumnSearchProps} from "../../utilities/useTableSearchFilter";

const MedikationsplanExternalPackage = ({}) => {
    const auth = useAuth();
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [externalPackageList, setExternalPackageList] = useState();

    useEffect(() => {
        if (medikationsplanContext.medikationsplan?.patient) {
            callApiAsync({url: fetchExternalPackageListByPatient(medikationsplanContext.medikationsplan.patient.id, true), auth})
                .then(response => setExternalPackageList(response.data.OBJECT));
        }
    }, [medikationsplanContext.medikationsplan])

    const columns = [
        {
            key: 'id',
            title: 'ID',
            render: (externalPackage) => externalPackage.id,
        },
        {
            key: 'datum',
            title: 'Datum',
            render: (externalPackage) => moment(externalPackage.registrationDate).format('DD.MM.YYYY'),

            sorter: (a, b) => getSorter('date')(a.registrationDate, b.registrationDate),
            defaultSortOrder: 'descend'
        },
        {
            key: 'pzn',
            title: 'PZN',
            render: (externalPackage) => {
                return externalPackage.batch.id.medicinePZN.pzn
            },
        },
        {
            key: 'arzneimittel',
            title: 'Arzneimittel',
            render: (externalPackage) => getMedicineM2Name(externalPackage.batch.id.medicinePZN.medicineM2),

            ...getColumnSearchProps({dataIndex: "patient", onFilter: (value, externalPackage) => getMedicineM2Name(externalPackage.batch.id.medicinePZN.medicineM2)?.toLowerCase().includes(value?.toLowerCase())}),
        },
    ];

    return <>
        <Table dataSource={externalPackageList} columns={columns} />
    </>
}

export default MedikationsplanExternalPackage;