import React from "react";
import {Switch} from "antd";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";

export const LabeledSwitch = ({checkedChildren=<CheckOutlined/>, uncheckedChildren=<CloseOutlined/>, checked=false, onChange=()=>{}, label=null, labelPosition="right", disabled=false}) => {
    return <span className="switch-labeled">
        {!!label && labelPosition === "left" && <span className="switch-labeled-label left" style={{marginRight: 5}}>{label}</span>}
        <Switch
            className="switch-labeled-switch"

            checkedChildren={checkedChildren}
            unCheckedChildren={uncheckedChildren}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
        {!!label && labelPosition === "right" && <span className="switch-labeled-label right" style={{marginLeft: 5}}>{label}</span>}
    </span>
}