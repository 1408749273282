import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import { Document, Page, pdfjs } from "react-pdf";

import { dokumenteApi } from '../../config/apiConfig'
import DokumenteContext from "../../contexts/DokumenteContext";
import PopupDokumenteContext from "../../contexts/PopupDokumenteContext";

import throttle from 'lodash.throttle';
import {berechneKoordinate, berechneKoordinateReverse} from "../../utilities/infoMarkerUtil";
import {ANSICHT_STANDARD} from "./MedikationsplanZeilen";
import {Tooltip} from "../atoms/Tooltip";
import {encodeBase64FromByteArray} from "../../utilities/encodingUtil";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const iconSize = 20;
const iconChecked = require('../../style/icons/question.png');

export const pdfGroesseAngleichenDefault = "bodyHeight";

export const DokumentAnsicht = ({ auth, anzeigeDokument, infomarker = [], updateInfomarker = () => {}, finishedLoading, setFinishedLoading=()=>{}, closeSider=()=>{}, pdfRotate, pdfGroesseAngleichen, redrawDokument, setRedrawDokument, pageNumber = 1 }) => {
    const [highlightInfomarker, setHighlightInfomarker] = useState(null);

    const [numPages, setNumPages] = useState(null);
    const [pageNumberState, setPageNumberState] = useState(pageNumber);
    const [pdfFileObject, setPdfFileObject] = useState(null);
    const [imgFileObject, setImgFileObject] = useState(null);
    const [imContextMenu, setImContextMenu] = useState(null);

    const [pdfPageProps, setPdfPageProps] = useState({});
    const [pdfParentHeight, setPdfParentHeightState] = useState("initial");
    const [pdfParentWidth, setPdfParentWidthState] = useState("initial");
    const [documentSizeRef, setDocumentSizeRef] = useState(null);
    const [pdfFileLoaded, setPdfFileLoaded] = useState(false);

    const dokumenteContext = useContext(DokumenteContext);
    const popupDokumenteContext = useContext(PopupDokumenteContext);

    const documentRef = useRef();
    const pdfPagePropsLatest = useRef({});
    const resizeEventListener = useRef(()=>{});

    const prepareDokument = useCallback(() => {
        if (documentSizeRef && pdfGroesseAngleichen) {
            switch (pdfGroesseAngleichen) {
                case "bodyWidth":
                    if (documentSizeRef.pdfGroesseAngleichen !== pdfGroesseAngleichen) {
                        setDocumentSizeRef({...documentSizeRef, width: document.body.offsetWidth, pdfGroesseAngleichen, pdfRotate});
                        return
                    }
                    break;
                case "bodyHeight":
                    if (documentSizeRef.pdfGroesseAngleichen !== pdfGroesseAngleichen || documentSizeRef.pdfRotate !== pdfRotate) {
                        let {seitenverhaeltnis, width, height} = getSeitenverhaeltnis();
                        if ([90, 270].includes(pdfRotate)) seitenverhaeltnis = 1 / seitenverhaeltnis;

                        setDocumentSizeRef({...documentSizeRef, width: document.body.offsetHeight * seitenverhaeltnis, pdfGroesseAngleichen, pdfRotate});
                        return
                    }
                    break;
            }
        }

        showPdfDocument();
    }, [documentSizeRef, pdfGroesseAngleichen, pdfRotate])

    useEffect(() => {
        resizeEventListener.current = window.addEventListener('resize', throttle(() => setRedrawDokument(true), 300));

        return () => {
            window.removeEventListener('resize', resizeEventListener.current);
        }
    }, []);

    useEffect(() => {
        if (finishedLoading) {
            prepareDokument();
        }
    }, [pdfRotate, documentSizeRef, numPages, pdfGroesseAngleichen, finishedLoading])

    useEffect(() => {
        const width = documentSizeRef?.width || document.body.offsetWidth;

        if (finishedLoading && redrawDokument) {
            setDocumentSizeRef({width});
            setRedrawDokument(false);
        }
    }, [redrawDokument])

    // Prüfe, ob Scrollbars angezeigt werden
    useEffect(() => {
        if (documentSizeRef && documentSizeRef.width > document.body.offsetWidth) {
            setDocumentSizeRef({...documentSizeRef, width: document.body.offsetWidth});
        }
    }, [pdfParentWidth])

    useEffect(() => {
        setFinishedLoading(false);

        if (anzeigeDokument) {
            dokumenteContext.setIsPDF(anzeigeDokument.dokument.contentType === "application/pdf");
        }

        if (anzeigeDokument && auth) {
            if (anzeigeDokument.dokument.contentType === "application/pdf") {
                setPdfFileObject({...pdfFileObject, url: dokumenteApi.download(anzeigeDokument.id), httpHeaders: {Authorization: 'Bearer ' + auth.token} });
                setImgFileObject(null)
            } else {
                fetch(dokumenteApi.downloadAddToken(anzeigeDokument.id, auth))
                    .then(res => res.ok && res.arrayBuffer())
                    .then(buffer => {
                        const encoded = encodeBase64FromByteArray(new Uint8Array(buffer));
                        setImgFileObject(encoded);
                    })

                setPdfFileObject(null);
            }
        }
    }, [anzeigeDokument]);

    useEffect(() => {
        popupDokumenteContext.setSelectedInfoMarker(dokumenteContext.highlightInfoMarker)
    }, [dokumenteContext.highlightInfoMarker])

    useEffect(() => {
        if (finishedLoading) {
            if (popupDokumenteContext.selectedInfoMarker) {
                const marker = popupDokumenteContext.selectedInfoMarker;
                if (marker?.dokument?.id === anzeigeDokument.id && marker?.marker?.[0]?.seite === pageNumberState) {
                    setHighlightInfomarker(popupDokumenteContext.selectedInfoMarker);
                }
            } else {
                setHighlightInfomarker(null);
            }
        }
    }, [popupDokumenteContext.selectedInfoMarker, anzeigeDokument, finishedLoading]);

    useEffect(() => {
        if (highlightInfomarker) {
            if (highlightInfomarker.id) {
                highlightInfomarkerByElementId(`img-info-marker-${highlightInfomarker.id}`);
            } else if (highlightInfomarker.type) {
                highlightInfomarkerByElementId(`img-info-marker-${highlightInfomarker.type}-index-${highlightInfomarker.index}`);
            }
        }
    }, [highlightInfomarker]);

    useEffect(() => {
        if (pageNumberState && pageNumberState === popupDokumenteContext.selectedInfoMarker?.marker?.[0]?.seite) {
            setHighlightInfomarker(popupDokumenteContext.selectedInfoMarker);
        }
    }, [pageNumberState]);

    const showPdfDocument = () => {
        if (!numPages) return;

        setPDFWidth();

        if (documentSizeRef && pdfPagePropsLatest.current[pageNumberState]) {
            const {seitenverhaeltnis} = getSeitenverhaeltnis();
            switch (pdfRotate) {
                case 90:
                case 270:
                    calculateTranslate(documentSizeRef.width, documentSizeRef.width * seitenverhaeltnis);
                    break;
                default:
                    calculateTranslate(documentSizeRef.width / seitenverhaeltnis, documentSizeRef.width);
            }
        }
    }

    const highlightInfomarkerByElementId = elementId => {
        localStorage.removeItem("highlightInfoMarker");
        const elements = document.getElementsByClassName("highlight");
        if (elements.length) for (let i=0; i<elements.length; i++) elements.item(i).classList.remove('highlight');

        const elementById = document.getElementById(elementId);
        if (elementById) {
            elementById.classList.add('highlight', 'highlight-animate');

            const window = document.defaultView || document.parentWindow;
            window.focus();
            elementById.scrollIntoView({block: "center", inline: "center"});

            console.log(`readyState: ${document.readyState}, scrollIntoView: `, elementById);

            setTimeout(() => {
                elementById.classList.remove('highlight-animate');
                popupDokumenteContext.setSelectedInfoMarker(null);
            }, 3000);
        }
    };

    const onLoadSuccess = (props) => {
        //todo check res.fingerprint while uploading to prevent duplicates? downside: access restrictions. upside: access restrictions :D
        setNumPages(props.numPages);

        if (popupDokumenteContext.pageOrder.length !== props.numPages) {
            const pageOrderNew = [];
            for (let i=1; i<=props.numPages; i++) {
                pageOrderNew.push(i);
            }

            popupDokumenteContext.setPageOrder(prev => JSON.stringify(prev) === JSON.stringify(pageOrderNew) ? prev : pageOrderNew);
        }

        if (pageNumberState > props.numPages) {
            setPageNumberState(0);
        }
    };

    const onPageLoadSuccess = (props) => {
        pdfPagePropsLatest.current[pageNumberState] = {width: props._pageInfo.view[2], height: props._pageInfo.view[3], rotate: props._pageInfo.rotate};
        setFinishedLoading(true);

        showPdfDocument();
    }

    const handleOnDoubleClick = (event) => {
        // Die Koordinaten werden auf einen Bereich von 1000x1000px normalisiert
        // const [posX, posY] = translate(event.pageX, event.pageY);

        const boundingClientRect = document.getElementById("divParent_page"+pageNumber).getBoundingClientRect();
        const pageX = event.pageX ? event.pageX - boundingClientRect.left - window.scrollX : 0;
        const pageY = event.pageY ? event.pageY - boundingClientRect.top - window.scrollY : 0;

        const {zoomLevel, width, height} = getSeitenverhaeltnis();
        const {posX, posY} = berechneKoordinateReverse(
            pageX,
            pageY,
            iconSize,
            0,
            pdfRotate,
            zoomLevel,
            width,
            height
        );

        let newInfomarkerUpdated = {
            dokument: anzeigeDokument,
            kommentar: "",
            marker: [
            {
                "seite": pageNumberState,
                "posX1": posX,
                "posY1": posY,
            }]
        };

        dokumenteContext.addInfoMarker(newInfomarkerUpdated);
    };

    const getSeitenverhaeltnis = () => {
        let pdfWidth, pdfHeight, documentSizeRefWidth;

        if (pdfPagePropsLatest.current[pageNumberState]){
            pdfWidth = pdfPagePropsLatest.current[pageNumberState].width;
            pdfHeight = pdfPagePropsLatest.current[pageNumberState].height;

            documentSizeRefWidth = documentSizeRef.width;
        } else {
            const imageElement = document.getElementById('img-document');
            if (!imageElement) return {};

            pdfWidth = imageElement.naturalWidth || imageElement.width;
            pdfHeight = imageElement.naturalHeight || imageElement.height;

            documentSizeRefWidth = imageElement.width;
        }

        let zoomLevel, zoomedWidth, zoomedHeight;
        switch (pdfRotate) {
            case 90:
            case 270:
                zoomedHeight = documentSizeRefWidth
                zoomLevel = zoomedHeight / pdfHeight;
                zoomedWidth = zoomLevel * pdfWidth;
                break;
            default:
                zoomedWidth = documentSizeRefWidth;
                zoomLevel = zoomedWidth / pdfWidth;
                zoomedHeight = zoomLevel * pdfHeight;
        }

        console.log(`zoomLevel ${zoomLevel}`)
        return {width: pdfWidth, height: pdfHeight, seitenverhaeltnis: pdfWidth / pdfHeight, zoomLevel, zoomedWidth, zoomedHeight};
    }

    const getInfoMarkerStyle = (x, y) => {
        const {zoomLevel, width, height} = getSeitenverhaeltnis();
        if (!zoomLevel || !width || !height) return;

        const {posX: left, posY: top} = berechneKoordinate(
            x,
            y,
            iconSize,
            0,
            pdfRotate,
            zoomLevel,
            width,
            height
        );

        return {
            position: "absolute",
            top: top + "px",
            left: left + "px",
            zIndex: "100",
            lineHeight: 0,

            backgroundColor: "rgba(255, 255, 255, 0.6)"
        }
    };

    const handleContextMenu = (event, infoMarker) => {
        const target = event.target;
        const classNames = target.className.split(" ");
        if (classNames.includes('img-info-marker')) {
            event.preventDefault();

            // Context-Menü anzeigen
            popupDokumenteContext.setImContextMenu({event: {...event}, infoMarker});
        }
    };

    const handleDragStart = ({event, type, index}) => {
        event.dataTransfer.setData("infoMarkerType", type);
        event.dataTransfer.setData("infoMarkerIndex", index);
        event.dataTransfer.setData("infoMarkerId", event.target.id);

        event.target.classList.add("dragging");
    };

    const handleDrop = event => {
        event.preventDefault();

        const infoMarkerId = event.dataTransfer.getData("infoMarkerId");
        const infoMarkerIndex = event.dataTransfer.getData("infoMarkerIndex");
        const infoMarkerType = event.dataTransfer.getData("infoMarkerType");

        const boundingClientRect = document.getElementById("divParent_page"+pageNumber).getBoundingClientRect();
        const pageX = event.pageX ? event.pageX - boundingClientRect.left - window.scrollX : 0;
        const pageY = event.pageY ? event.pageY - boundingClientRect.top - window.scrollY : 0;

        // const [posX, posY] = translate(event.pageX, event.pageY);

        const {zoomLevel, width, height} = getSeitenverhaeltnis();
        const {posX, posY} = berechneKoordinateReverse(
            pageX,
            pageY,
            iconSize,
            0,
            pdfRotate,
            zoomLevel,
            width,
            height
        );

        if (infoMarkerType === 'neu') {
            const infoMarkerNeu = [...dokumenteContext.infoMarkerNeu];
            infoMarkerNeu[infoMarkerIndex].marker[0].posX1 = posX;
            infoMarkerNeu[infoMarkerIndex].marker[0].posY1 = posY;

            dokumenteContext.setInfoMarkerNeu(infoMarkerNeu);
        } else if (infoMarkerType === 'alt') {
            const infoMarkerNeu = infomarker[infoMarkerIndex];
            infoMarkerNeu.marker[0].posX1 = posX;
            infoMarkerNeu.marker[0].posY1 = posY;

            updateInfomarker(infoMarkerNeu);
        }

        document.getElementById(infoMarkerId).classList.remove("dragging");
    };

    const setPDFWidth = () => {
        if (!documentSizeRef) {
            setDocumentSizeRef({width: document.body.offsetWidth});
            return;
        }

        setPdfPageProps({...pdfPageProps, width: documentSizeRef.width});
    };

    const calculateTranslate = (pageWidth, pageHeight) => {
        let width = pageWidth;
        let height = pageHeight;

        // Prüfe, ob die gewünschte Höhe/Breite (je nach Drehung) der von react-pdf übergebenen PDF-Höhe entspricht
        // react-pdf hat intern scheinbar eine Rotations-Angabe und kann eine PDF intern hochkant verarbeiten, aber längs ausgeben
        // dadurch entspricht hier z.B. pageHeight nicht immer der ausgegebenen Seitenhöhe
        // if (Math.abs(documentSizeRef.width - pageHeight) < Math.abs(documentSizeRef.width - pageWidth)) {
            // Die gewünschte Höhe (wenn hochkant) / Breite (wenn längs) entspricht der PDF-Höhe => drehe das Seitenverhältnis
            // width = pageHeight;
            // height = pageWidth;
        // }

        setPdfParentWidthState(width);
        setPdfParentHeightState(height);
    }

    return !pageNumberState ? <></> : <div onClick={closeSider} style={{display: "relative"}}>
        {/*{anzeigeDokument && <div><div id={`divParent_page${pageNumber}`} className={`divParent page${pageNumber}`} onDoubleClick={handleOnDoubleClick} onDragOver={e => e.preventDefault()}  onClick={e => popupDokumenteContext.setImContextMenu(null)} style={{overflow: "hidden", position: "relative", width: pdfParentWidth}}>*/}
        {anzeigeDokument && <div><div id={`divParent_page${pageNumber}`} className={`divParent page${pageNumber}`} onDoubleClick={handleOnDoubleClick} onDragOver={e => e.preventDefault()}  onClick={e => popupDokumenteContext.setImContextMenu(null)} style={{overflow: "hidden", position: "relative"}}>
            {/* <div onDrop={handleDrop} className={pdfRotate > 0 ? `rotate-align-${pdfRotate}` : ""} style={pdfRotate === 90 || pdfRotate === 270 ? {width: pdfParentHeight, height: pdfParentWidth} : {width: pdfParentWidth, height: pdfParentHeight}}> */}
            <div onDrop={handleDrop}>
                {/* Das Dokument soll als PDF dargestellt werden */}
                {pdfFileObject &&
                    <Document
                        file={pdfFileObject}
                        onLoadError={(loadError) => console.log(loadError)}
                        onLoadSuccess={onLoadSuccess}

                        ref={documentRef}
                    >
                        <Page
                            loading={<p>Bitte warten!</p>}
                            pageNumber={pageNumberState}
                            onLoadError={(loadError) => console.log(loadError)}
                            onLoadSuccess={onPageLoadSuccess}

                            rotate={pdfRotate}

                            {...pdfPageProps}
                        />
                    </Document>
                }

                {/* Das Dokument soll als Bild dargestellt werden */}
                {imgFileObject &&
                    <img id={'img-document'} src={`data:${anzeigeDokument.dokument.contentType};base64,${imgFileObject}`} width="100%" onDragOver={e => e.preventDefault()} onDrop={handleDrop} onLoad={() => setFinishedLoading(pageNumberState)} />
                }
            </div>

            {/* temporäre InfoMarker, die noch keiner Planzeile zugeordnet wurden */}
            {dokumenteContext.infoMarkerNeu && dokumenteContext.infoMarkerNeu.map((im, i) => im.marker.map((q) => {
                if (q && im.dokument.id === anzeigeDokument.id && q.seite === pageNumberState)
                    return <a onContextMenu={e => handleContextMenu(e, im)} key={i} style={getInfoMarkerStyle(q.posX1, q.posY1)}>
                        <img key={i} id={`img-info-marker-neu-index-${i}`} draggable="true" onDragStart={e => {handleDragStart({event:e, type:'neu', index:i})}} className="img-info-marker neu" src={iconChecked} width={iconSize} height={iconSize} />
                    </a>
            }))}

            {/* InfoMarker aus der Datenbank */}
            {infomarker && infomarker.map((im, i) => im.marker.map((q) => {
                if (q && im.dokument.id === anzeigeDokument.id && q.seite === pageNumberState) {
                    if (!im.planzeile.medikationsplan) return '';

                    let dosierabschnittId = 0;
                    if (im.dosierabschnittSet) for (let d of im.dosierabschnittSet) {
                        if (dosierabschnittId < d.id) {
                            dosierabschnittId = d.id;
                        }
                    };

                    console.log('redraw infoMarkers')
                    return <a onContextMenu={e => handleContextMenu(e, im)} key={im.id}
                              style={getInfoMarkerStyle(q.posX1, q.posY1)}
                              href={`/medikationsplan/${im.planzeile.medikationsplan.id}/${encodeURIComponent(im.ansicht ? im.ansicht : ANSICHT_STANDARD)}/${im.planzeile.id}${dosierabschnittId ? "?dosierabschnitt=" + dosierabschnittId : ""}`}
                              target={"parent-default"}
                              onClick={e => popupDokumenteContext.setSelectedInfoMarker(im)}
                    >
                        <Tooltip title={im.dosierabschnittSet?.length > 1 ? "Verknüpfung mit mehreren Dosierabschnitten" : null}><img key={im.id} id={`img-info-marker-${im.id}`} draggable="true" onDragStart={e => {
                            handleDragStart({event: e, type: 'alt', index: i})
                        }} className="img-info-marker" src={iconChecked} width={iconSize} height={iconSize}/></Tooltip>
                    </a>
                }
            }))}
        </div>

        {/*<p style={{position: "absolute", top: 0, left: 0, zIndex: 200, backgroundColor: "#ffffff66", padding: 5}}>*/}
        {/*    Seite: {new Array(numPages).fill().map((_, i) => {*/}
        {/*        const seite = i+1;*/}
        {/*        const style = {marginLeft: 10};*/}
        {/*        if (seite === pageNumberState) style.fontWeight = 'bold';*/}

        {/*        return <a key={"a-seite-"+i} style={style} onClick={() => setPageNumberState(seite)}>{seite}</a>*/}
        {/*    })}*/}
        {/*</p>*/}

        </div>}
    </div>
};