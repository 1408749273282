import React, {useState} from 'react'
import {DateiListePopoverTable} from "../molecules/DateiListePopover";
import {Button} from "antd";
import {EditFilled, SaveFilled} from "@ant-design/icons";
import {Tooltip} from "../atoms/Tooltip";

export const DateiInfo = ({ anzeigeDokument, setFilter, filter, onEdit, setEdit }) => {

    const [mouseEntered, setMouseEntered] = useState(false);
    const [edit, setEditBool] = useState(false);
    const [editState, setEditState] = useState({});

    const onMouseEnter = e => {
        setMouseEntered(true);
    }

    const onMouseLeave = e => {
        if (!edit) setMouseEntered(false)
    }

    const onClick = () => {
        const editStateNeu = {};
        if (edit) {
            onEdit(editState)
        }

        if (setEdit) setEdit(!edit);
        setEditBool(!edit);
        setEditState(editStateNeu);
    }

    const handleChange = (key, value) => {
        setEditState(alt => {
            const neu = {...alt};
            neu[key] = value;

            return neu;
        });
    }

    return <div>
        <h4>Informationen zu diesem Dokument</h4>
        {anzeigeDokument && <DateiListePopoverTable datei={anzeigeDokument} />}

    </div>
}
