import React, {useContext, useRef} from "react";
import {useAuth} from "./useAuth";
import ApiContext from "../contexts/ApiContext";
import {callApiAsync} from "./apiUtil";
import {buendelApi} from "../config/apiConfig";
import moment from "moment";

const useApiBuendel = () => {
    const auth = useAuth();
    const apiContext = useContext(ApiContext);

    const forceBuendelReload = useRef(new Set());
    const forceMedikationsplanReload = useRef(new Set());

    const loadBuendelListByMedikationsplan = async medikationsplanId => {
        let buendelList;

        if (!forceMedikationsplanReload.current.has(medikationsplanId) && apiContext.buendelData.buendelListLoaded?.[medikationsplanId] && moment(apiContext.buendelData.buendelListLoaded[medikationsplanId]).add(1, 'minutes').isAfter(moment())) {
            buendelList = apiContext.buendelData.buendelListMap[medikationsplanId];
        } else {
            forceMedikationsplanReload.current.delete(medikationsplanId);

            const response = await callApiAsync({auth, url: buendelApi.getByMedikationsplan(medikationsplanId)});
            buendelList = response.data.OBJECT;
            const now = Date.now();

            const buendelMap = {};
            const buendelLoaded = {};
            const planzeileMap = {};
            buendelList.forEach(b => {
                buendelMap[b.id] = b;
                buendelLoaded[b.id] = now;

                b.planzeilen.forEach((p) => {
                    planzeileMap[p.id] = b.id;
                }, {});
            });

            apiContext.setBuendelData(prev => ({
                ...prev,

                planzeileMap: {...prev.planzeileMap, ...planzeileMap},

                buendelMap: {...prev.buendelMap, ...buendelMap},
                buendelLoaded: {...prev.buendelLoaded, ...buendelLoaded},

                buendelListMap: {...prev.buendelListMap, [medikationsplanId]: buendelList},
                buendelListLoaded: {...prev.buendelListLoaded, [medikationsplanId]: now}
            }));
        }

        return buendelList;
    }

    const reloadBuendelListByMedikationsplan = async medikationsplanId => {
        forceMedikationsplanReload.current.add(medikationsplanId);
        return loadBuendelListByMedikationsplan(medikationsplanId);
    }

    const loadBuendel = async buendelId => {
        let buendel;

        if (!forceBuendelReload.current.has(buendelId) && apiContext.buendelData.buendelLoaded?.[buendelId] && moment(apiContext.buendelData.buendelLoaded[buendelId]).add(1, 'minutes').isAfter(moment())) {
            buendel = apiContext.buendelData.buendelMap[buendelId];
        } else {
            forceBuendelReload.current.delete(buendelId);

            const response = await callApiAsync({auth, url: buendelApi.getById(buendelId, true)});
            buendel = response.data.OBJECT;
            const planzeileMap = buendel.planzeilen.reduce((obj, p) => {
                obj[p.id] = buendel.id;
                return obj;
            }, {});

            apiContext.setBuendelData(prev => ({
                ...prev,

                planzeileMap: {...prev.planzeileMap, ...planzeileMap},
                buendelMap: {...prev.buendelMap, [buendelId]: buendel},
                buendelLoaded: {...prev.buendelLoaded, [buendelId]: Date.now()}
            }));
        }

        return buendel;
    }

    const reloadBuendel = async buendelId => {
        forceBuendelReload.current.add(buendelId);
        return loadBuendel(buendelId);
    }

    const resetPlanzeile = async (id) => {
        const response = await callApiAsync({auth, url: buendelApi.resetPlanzeile(id)});
        return response.data.OBJECT;
    }

    const resetAnspruch = async (entitlement) => {
        const data = {
            type: "RESET_BUNDLE",
            ownerID: entitlement.id.person.id,
            m2String: entitlement.id.medicineM2.m2
        };

        const response = await callApiAsync({auth, url: buendelApi.resetAnspruchPOST(), method: 'post', data});
        return response.data.OBJECT;
    }

    const resetBestand = async (internalPackage) => {
        const data = {
            type: "RESET_BUNDLE",
            internalPackageID: internalPackage.id
        };

        const response = await callApiAsync({auth, url: buendelApi.resetBestandPOST(), method: 'post', data});
        return response.data.OBJECT;
    }

    return {
        loadBuendelListByMedikationsplan,
        reloadBuendelListByMedikationsplan,
        buendelListLoaded: medikationsplanId => apiContext.buendelData.buendelListLoaded?.[medikationsplanId],

        loadBuendel,
        reloadBuendel,
        buendelLoaded: buendelId => apiContext.buendelData.buendelLoaded?.[buendelId],

        getBuendelByPlanzeile: planzeileId => apiContext.buendelData.planzeileMap?.[planzeileId],

        resetPlanzeile,
        resetAnspruch,
        resetBestand
    }
}

export default useApiBuendel;