import React, {useEffect, useRef, useState} from 'react'

import { useDataAccess } from '../../utilities/useDataAccess'
import { Fallback } from '../../config/fallbackPages'
import TextArea from "antd/es/input/TextArea";
import {useAuth} from "../../utilities/useAuth";
import {Checkbox, Input, Select, Switch} from "antd";
import * as PropTypes from "prop-types";
import {DatePicker} from "antd/es";
import moment from "moment";
import {signal, useSignal} from "@preact/signals-react"
import {callApiAsync} from "../../utilities/apiUtil";
import {BASE_URL} from "../../config/apiConfig";
import SearchInputDropdown from "../atoms/SearchInputDropdown";
import useDebounce from "../../utilities/useDebounce";

const ikList = signal();

function filterIkList(ik, name) {
    if (!ik && !name || !ikList) return [];

    return ikList.value.filter(e => {
        return ik ? e[0].includes(ik) : e[1].toLowerCase().includes(name.toLowerCase())
    });
}

function Datum(props) {
    return null;
}

Datum.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any
};
export const AdressbuchPerson = ({ ...props }) => {
    const auth = useAuth();

    const akteur = useDataAccess({
        ...props,
    });


    const [gesetzlichVersichert, setGesetzlichVersichert] = useState(1);
    const [ik, setIk] = useState();
    const [ikLoading, setIkLoading] = useState(false);
    const [ikOverlayItems, setIkOverlayItems] = useState();
    const [ikName, setIkName] = useState();
    const [ikNameLoading, setIkNameLoading] = useState(false);
    const [ikNameOverlayItems, setIkNameOverlayItems] = useState();

    const ikNameRef = useSignal();

    const ikDebounced = useDebounce(ik, 100);
    const ikNameDebounced = useDebounce(ikName, 100);

    useEffect(() => {
        if (!ikList.value) {
            callApiAsync({url: BASE_URL + 'akteure/iklist', auth}).then(res => {
                ikList.value = res.data.OBJECT;
                ikNameRef.value = filterIkList(akteur.current?.ikNumber, null)[0]?.[1];
            });
        } else {
            ikNameRef.value = filterIkList(akteur.current?.ikNumber, null)[0]?.[1];
        }

        setGesetzlichVersichert(akteur.current?.ikNumber === 0 ? 0 : 1);
    }, [akteur.current]);

    useEffect(() => {
        if (!gesetzlichVersichert) {
            akteur.handleChangeBatch([
                {name: 'ikNumber', payload: 0},
                {name: 'dateZuzahlungsbefreitBis', payload: null}
            ]);
        }
    }, [gesetzlichVersichert]);

    useEffect(() => {
        setIkLoading(true);
        const ikList = filterIkList(ikDebounced?.length > 3 ? ikDebounced : null, null);
        setIkOverlayItems(ikList.map(a => ({key: a[0], item: `${a[0]} - ${a[1]}`, entry: a})));
        setIkLoading(false);
    }, [ikDebounced]);

    useEffect(() => {
        setIkNameLoading(true);
        const ikList = filterIkList(null, ikNameDebounced?.length > 2 ? ikNameDebounced : null);
        setIkNameOverlayItems(ikList.map(a => ({key: a[0], item: `${a[0]} - ${a[1]}`, entry: a})));
        setIkNameLoading(false);
    }, [ikNameDebounced]);

    if (!akteur.isReady)
        return Fallback(akteur);

    return <div className="adressbuch-stammdaten">
        <div className="grid">
            {auth.developer && <div>UnitId:</div>}
            {auth.developer && <div>{akteur.current.id}</div>}

            <div>Titel:</div>
            <div><Input
                disabled={!akteur.current.active}
                value={akteur.current.title}
                onChange={e => akteur.handleChange('title', e.target.value)}
            /></div>

            <div>Vorname:</div>
            <div><Input
                disabled={!akteur.current.active}
                value={akteur.current.name}
                onChange={e => {
                    akteur.handleChange('name', e.target.value);
                }}
            /></div>

            <div>Nachname:</div>
            <div><Input
                disabled={!akteur.current.active}
                value={akteur.current.surname}
                onChange={e => {
                    akteur.handleChange('surname', e.target.value);
                }}
            /></div>

            <div>Geburtstag:</div>
            <DatePicker
                disabled={!akteur.current.active}
                defaultValue={akteur.current.dateOfBirth ? moment(akteur.current.dateOfBirth) : null}
                onChange={val => {
                    akteur.handleChange('dateOfBirth', val.valueOf());
                }}

                format={"DD.MM.YYYY"}
            />

            <div>Krankenversicherung</div>
            <Select value={gesetzlichVersichert} dropdownMatchSelectWidth={false} onChange={val => {
                setGesetzlichVersichert(val);
                if (val) setIk("");
            }}>
                <Select.Option value={1}>gesetzlich versichert</Select.Option>
                <Select.Option value={0}>privat versichert</Select.Option>
            </Select>

            {!!gesetzlichVersichert && <>
                <div>IK-Nummer</div>
                <SearchInputDropdown
                    loading={ikLoading}
                    value={ik ?? akteur.current.ikNumber}
                    onChange={setIk}
                    overlayItems={ikOverlayItems}
                    handleChange={val => {
                        setIk(null);
                        setIkName(null);
                        ikNameRef.value = val.entry[1];
                        akteur.handleChange('ikNumber', val.entry[0]);
                    }}
                />

                <div>Versicherung</div>
                <SearchInputDropdown
                    loading={ikNameLoading}
                    value={ikName || ikNameRef.value}
                    onChange={setIkName}
                    overlayItems={ikNameOverlayItems}
                    handleChange={val => {
                        setIk(null);
                        setIkName(null);
                        ikNameRef.value = val.entry[1];
                        akteur.handleChange('ikNumber', val.entry[0]);
                    }}
                    onFocusChange={val => setIkName(null)}
                />

                <div style={{whiteSpace: "nowrap"}}>Zuzahlungsbefreiung bis</div>
                <DatePicker.MonthPicker
                    format={"MM/YYYY"}
                    defaultValue={akteur.current.dateZuzahlungsbefreitBis ? moment(akteur.current.dateZuzahlungsbefreitBis) : null}
                    onChange={val => {
                        console.log(val);
                        akteur.handleChange('dateZuzahlungsbefreitBis', val.endOf('month').valueOf());
                    }}
                />
            </>}

            <div>Daten vollständig:</div>
            <div><Checkbox
                disabled={!akteur.current.active}
                checked={akteur.current.dataComplete}
                onChange={e => akteur.handleChange('dataComplete', e.target.checked)}
            /></div>

            <div className="span-2">Allgmeine Notiz:</div>
            <div className="span-2"><TextArea
                disabled={!akteur.current.active}
                value={akteur.current.generalNote}
                onChange={e => akteur.handleChange('generalNote', e.target.value)}
            /></div>

            <div className="span-2">Allgmeiner Einnahmehinweis:</div>
            <div className="span-2"><TextArea
                disabled={!akteur.current.active}
                value={akteur.current.dosageNote}
                onChange={e => akteur.handleChange('dosageNote', e.target.value)}
            /></div>
        </div>
    </div>
}
