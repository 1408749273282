import React, {useContext, useEffect, useRef, useState} from 'react'
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {StatusDisplay, useDataNode} from "../../utilities/useDataNode";
import {bestandNode} from "../../config/dataNodeConfiguration";
import {getUnitLangname} from "../../config/entities";
import {Button, List, Table} from "antd";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {MedikationsplanBestand} from "../molecules/MedikationsplanBestand";
import {callApiAsync} from "../../utilities/apiUtil";
import {lagerApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import usePagination from "../../utilities/usePagination";
import {getColumnSearchProps, useTableSearchFilter} from "../../utilities/useTableSearchFilter";

export const MedikationsplanBestandTable = ({triggerSave, setUpdated}) => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [tableData, setTableData] = useState([]);
    const [bestandDragging, setBestandDragging] = useState(null);
    const [elementDragging, setElementDragging] = useState(null);
    const [syncing, setSyncing] = useState(false);
    const [syncWasCalled, setSyncWasCalled] = useState(false);

    const [bestandList, setBestandList] = useState(null);
    const updateLast = useRef(null);

    const {getColumnSearchProps} = useTableSearchFilter()

    const {
        current: bestandListFetch,
        status,
        setError,

        handleChange,
        handleSave,
    } = useDataNode({
        dataNode: bestandNode,
        isCollection: true,
        key: medikationsplanContext.medikationsplan ? medikationsplanContext.medikationsplan.patient.id : null,
        idAttribute: "packungId",
        value: medikationsplanContext.bestandList
    })

    useEffect(() => {
        if (bestandListFetch) {
            medikationsplanContext.setBestandList(bestandListFetch);
        }
    }, [bestandListFetch])

    useEffect(() => {
        if (medikationsplanContext.bestandList) {
            const planzeileMap = {};

            if (!medikationsplanContext.bestandList.length && !syncWasCalled) {
                syncBestand();
                return;
            }

            medikationsplanContext.bestandList.forEach(bestand => {
                let planzeilen;
                if (bestand.planzeile) {
                    planzeilen = {
                        planzeileId: bestand.planzeile.id,
                        planzeile: bestand.planzeile,
                        bestandList: [], ...planzeileMap[bestand.planzeile.id]
                    };
                } else {
                    planzeilen = {planzeileId: -1, bestandList: [], ...planzeileMap[-1]};
                }

                planzeilen.bestandList.push({...bestand});
                if (bestand.planzeile) planzeilen.planzeile = bestand.planzeile;

                planzeileMap[planzeilen.planzeileId] = planzeilen;

                if (updateLast.current?.packungId === bestand.packungId && updateLast.current?.planzeile?.id === bestand.planzeile?.id) {
                    updateLast.current = null;
                    handleSave(bestand.packungId);
                }
            });

            const tableDataNew = [];
            Object.keys(planzeileMap).forEach(planzeileId => {
                tableDataNew.push({
                    key: planzeileId,
                    planzeile: planzeileMap[planzeileId].planzeile,
                    bestandList: planzeileMap[planzeileId].bestandList
                });
            });

            setTableData(tableDataNew);
        }
    }, [medikationsplanContext.bestandList])

    useEffect(() => {
        if (medikationsplanContext.bestaendeLoaded && syncing) {
            setSyncing(false);
        }
    }, [medikationsplanContext.bestaendeLoaded])

    useEffect(() => {
        if (triggerSave) {
            (async () => {
                await handleSave();
                setUpdated(false);
            })();
        }
    }, [triggerSave])

    useEffect(() => {
        if (syncing && !medikationsplanContext.reichweiteWirdBerechnet.length) {
            syncBestand();
        }
    }, [medikationsplanContext.reichweiteWirdBerechnet])




    const handleDrop = (tableDataElement) => {
        if (bestandDragging) {
            confirmDrop(bestandDragging, tableDataElement.planzeile);
        }


        if (elementDragging) {

        }

        setBestandDragging(null);
        setElementDragging(null);
    }

    const handleDragStart = (bestand, element) => {
        setElementDragging(element);
        setBestandDragging(bestand);
    }

    const confirmDrop = (bestandDragging, planzeile) => {
        if ((!bestandDragging.planzeile || bestandDragging.planzeile.id !== planzeile.id) && window.confirm(planzeile ? `Sind Sie sicher, dass der Bestand in die Planzeile "${getArzneimittelLangname(planzeile.arzneimittel)}" verschoben werden soll?` : "Sind Sie sicher, dass die Zuordnung entfernt werden soll?")) {
        // if ((!bestandDragging.planzeile || bestandDragging.planzeile.id !== planzeile.id)) {
            const bestand = medikationsplanContext.bestandList.filter(bestand => bestand.packungId === bestandDragging.packungId)[0];
            const bestandNeu = {...bestand, planzeile};

            handleChange(bestandNeu, bestandNeu.packungId);
            updateLast.current = bestandNeu;
            setUpdated(true);
        }
    }

    const syncBestand = async () => {
        setSyncing(true);
        if (medikationsplanContext.reichweiteWirdBerechnet.length) return;

        try {
            await callApiAsync({
                auth,
                url: lagerApi.postSyncBestand(),
                method: 'POST',
                data: {patientId: medikationsplanContext.medikationsplan.patient.id}
            });

            medikationsplanContext.setReloadBestaende(true);
            setSyncWasCalled(true);
        } catch (e) {
            setError(e);
        }
    }

    const statusNeu = {...status, loading: syncing || status.loading};
    return (
        <StatusDisplay status={statusNeu}>
            <h6>Bestand für {medikationsplanContext.medikationsplan && getUnitLangname(medikationsplanContext.medikationsplan.patient)}</h6>
            <Table
                pagination={usePagination()}

                dataSource={tableData}
                onRow={(bestand) => {
                    return {
                        onDragOver: (e) => {
                            e.preventDefault();
                            e.target.closest(".ant-table-row").classList.add("bestand-row-drag-over");
                        },
                        onDragLeave: (e) => {
                            e.target.closest(".ant-table-row").classList.remove("bestand-row-drag-over");
                        },
                        onDrop: (e) => {
                            e.preventDefault();
                            e.target.closest(".ant-table-row").classList.remove("bestand-row-drag-over");
                            handleDrop(bestand);
                        },
                    }
                }}

                columns={[
                    {
                        title: "Planzeile",
                        dataIndex: "planzeile",
                        render: (planzeile) => planzeile ? `${getArzneimittelLangname(planzeile.arzneimittel)}${planzeile.arzneimittel?.normgroesse ? " "+planzeile.arzneimittel.normgroesse : ""}${planzeile.bedarfsmedikation ? " (Bedarfsmedikation)" : ""}` : "Zuordnung fehlt",
                        sorter: (a, b) => {
                            const aText = a.planzeile ? `${getArzneimittelLangname(a.planzeile.arzneimittel)}${a.planzeile.arzneimittel?.normgroesse ? " "+a.planzeile.arzneimittel.normgroesse : ""}${a.planzeile.bedarfsmedikation ? " (Bedarfsmedikation)" : ""}` : "Zuordnung fehlt";
                            const bText = b.planzeile ? `${getArzneimittelLangname(b.planzeile.arzneimittel)}${b.planzeile.arzneimittel?.normgroesse ? " "+b.planzeile.arzneimittel.normgroesse : ""}${b.planzeile.bedarfsmedikation ? " (Bedarfsmedikation)" : ""}` : "Zuordnung fehlt";

                            return aText.localeCompare(bText);
                        },
                        defaultSortOrder: "ascend",

                        ...getColumnSearchProps({onFilter: (value,  record) => {
                                const text = getArzneimittelLangname(record.planzeile.arzneimittel).toLowerCase();
                                const terms = value.toLowerCase().split(" ");
                                return terms.every(term => {
                                    const isIncluded = text.includes(term);
                                    return isIncluded;
                                });
                            }})
                    },
                    {
                        title: "Bestand",
                        dataIndex: "bestandList",
                        render: (text, row) => {
                            return <List
                                dataSource={row.bestandList}
                                renderItem={ (bestand) => {
                                    return <MedikationsplanBestand value={bestand} onDragStart={e => handleDragStart(bestand, e.target)} onUpdate={bestandNeu => confirmDrop(bestandNeu, bestandNeu.planzeile)} />
                                }}
                            />
                        },
                        sorter: (a, b) => a.bestandList[0].packungId.localeCompare(b.bestandList[0].packungId)
                    }
                ]}
            />
            <Button onClick={syncBestand}>Bestand synchronisieren</Button>
        </StatusDisplay>
    )
}

export const loadBestandList = async (auth, medikationsplanContext) => {
    if (!medikationsplanContext?.bestandList) {
        const response = await callApiAsync({auth, url: bestandNode.api.getByParent(medikationsplanContext.medikationsplan.patient.id)});
        const bestandList = response.data.OBJECT;

        medikationsplanContext.setBestandList(bestandList);
        medikationsplanContext.setBestaendeLoaded(true);
        return bestandList;
    }

    return medikationsplanContext.bestandList;
}