import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Dropdown, Input, Modal, notification} from "antd";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import plugins from "suneditor/src/plugins";
import suneditor from "suneditor";
import 'suneditor/dist/css/suneditor.min.css';
import {callApiAsync} from "../../utilities/apiUtil";
import {BASE_URL_NONMP} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import uuid from "uuid/v4";

const from = 'verblisterung@sankt-barbara-apo.de'
const inputValuesDefault = {from};
const emailRegex = /^[A-Z0-9._%+-]+@([A-Z0-9.-]+\.[A-Z]{2,})$/gi;
export const MessageModal = forwardRef(({ akteur }, ref) => {
    const auth = useAuth();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contactDropdownIsOpen, setContactDropdownIsOpen] = useState(false);
    const [contactsSorted, setContactsSorted] = useState([]);
    const [inputValues, setInputValues] = useState(inputValuesDefault);

    /** @type {MutableRefObject<SunEditorCore>} */
    const editor = useRef(null);

    useImperativeHandle(ref, () => ({
        createMessage() {
            setModalIsOpen(true);
        },
        editor
    }));

    useEffect(() => {
        if (akteur.contacts) {
            let defaultValue = null;
            setContactsSorted(akteur.contacts.filter(c => {
                if (c.value && [...c.value.trim().matchAll(emailRegex)].length) {
                    if (akteur.defaultContact === c.id.name) defaultValue = c.value;
                    return true;
                }
                return false;
            }).toSorted((a,b) => a.id.name.localeCompare(b.id.name)));

            if (defaultValue) setInputValues({...inputValues, to: defaultValue});
        }
    }, [akteur.contacts])

    const onOk = async () => {
        if (!inputValues.to || !inputValues.from || !inputValues.subject) {
            notification.error({message: 'Es müssen ein Empfänger, ein Absender und ein Betreff eingetragen sein!'});
            return;
        }

        setModalIsOpen(false);

        // extract img data
        const div = document.createElement('div');
        div.innerHTML = editor.current.getContents(true);
        const imgData = {};
        for (const img of Array.from(div.getElementsByTagName('img'))) {
            const key = uuid();
            const value = img.src;
            img.src = key;
            imgData[key] = value;
        }
        const html = div.innerHTML;

        const data = {
            host: "10.37.234.11",
            port: 25,
            ssl: false,

            to: inputValues.to.split(/,\w*/),
            from: {
                email: inputValues.from,
                name: 'Sankt Barbara Apotheke'
            },
            subject: inputValues.subject,
            html,
            imgData
        }

        const isKim = inputValues.from.includes('.kim.telematik');
        try {
            await callApiAsync({url: BASE_URL_NONMP + 'communication/send/email', method: 'post', data, auth});
            notification.info({message: `Die ${isKim ? 'KIM-Nachricht' : 'eMail'} wurde erfolgreich verschickt.`});

            setInputValues(inputValuesDefault);
            editor.current.setContents('');
        } catch (e) {
            notification.error(`Beim Versand der ${isKim ? 'KIM-Nachricht' : 'eMail'} ist ein Fehler aufgetreten.`);
            console.error('Nachricht-Versand', e);
        }
    }

    const onCancel = () => {
        setModalIsOpen(false);
    }

    const setTo = (string) => {
        if (string) {
            let isEmail = false, isKim = false;
            const invalidAddresses = [];
            string.split(/,\s*/).forEach(to => {
                const match = [...to.matchAll(emailRegex)];
                if (match.length) {
                    const domain = match[0][1];
                    if (domain.includes('.kim.telematik')) {
                        isKim = true;
                    } else {
                        isEmail = true;
                    }
                } else {
                    invalidAddresses.push(to);
                }
            });

            if (isKim && isEmail) {
                notification.warn({message: 'Es wurde sowohl eine eMail- als auch eine KIM-Adresse als Empfänger eingetragen. ' +
                        'Eine Nachricht kann jedoch nicht in einem Vorgang als eMail und KIM-Nachricht verschickt werden. Die Nachricht müsste dafür in zwei Nachrichten aufgeteilt werden.'})
            }

            if (invalidAddresses.length) {
                notification.warn({message: (invalidAddresses.length > 1 ? 'Folgende Adressen sind keine gültigen Empfänger: ' : 'Folgende Adresse ist kein gültiger Empfänger: ') + invalidAddresses.join(', ')});
            }

            if (isEmail) {
                setInputValues({...inputValues, to: string, from});
            } else if (isKim) {
                setInputValues({...inputValues, to: string, from: 'sankt-barbara-apo-pflege@apo.kim.telematik'});
            }
        } else {
            setInputValues({...inputValues, to: undefined})
        }
    }

    return <Modal visible={modalIsOpen} onCancel={onCancel} onOk={onOk} width={'90%'} okText={'Nachricht absenden'}>
        <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr'}}>
            <span style={{marginRight: 10}}>Betreff:</span>
            <Input value={inputValues.subject}
                   onChange={(e => setInputValues({...inputValues, subject: e.target.value}))}/>

            <span style={{marginRight: 10}}>Empfänger:</span>
            <Dropdown onVisibleChange={setContactDropdownIsOpen} visible={contactDropdownIsOpen} overlay={<div style={{backgroundColor: 'white', padding: 10, display: !!contactsSorted.length ? 'bock' : 'none'}}>{contactsSorted.map(c => <span
                key={c.id.name} style={{padding: 5, cursor: 'pointer'}}
                onMouseOver={(e) => e.target.style.backgroundColor = 'lightgrey'}
                onMouseOut={(e) => e.target.style.backgroundColor = 'white'} onClick={() => {
                    setContactDropdownIsOpen(false);
                    setTo(c.value);
                }}>{`${c.id.name} (${c.value})`}</span>)}</div>} trigger={'click'}>
                <Input value={inputValues.to} onChange={(e) => setInputValues({...inputValues, to: e.target.value})} onBlur={(e) => setTo(e.target.value)}/>
            </Dropdown>

            <span style={{marginRight: 10}}>Absender:</span>
            <Input value={inputValues.from} onChange={(e => setInputValues({...inputValues, from: e.target.value}))}/>
        </div>

        <SunEditor getSunEditorInstance={(e) => editor.current = e} lang={"de"} height={400} setOptions={{
            buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['paragraphStyle', 'blockquote'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image', 'video'], // , 'math' You must add the 'katex' library at options to use the 'math' plugin.
                // ['imageGallery'], // You must add the "imageGalleryUrl".
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview', 'print'],
                // ['save', 'template'],
                ['dir', 'dir_ltr', 'dir_rtl'],
                // '/', Line break
            ]
        }} />
    </Modal>
})