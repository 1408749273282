import React, {useContext, useEffect, useRef, useState} from "react";
import {
    fetchAbgabeplanBetween, fetchArzneimittelGleicheM2ById
} from "../../config/fetchApiConfiguration";
import {usePost} from "../../utilities/usePost";
import {postErstelleAnforderung} from "../../config/postApiConfiguration";
import {Button, Divider, Modal, notification, Radio, Input} from "antd";
import {Popover} from "../atoms/Popover";
import {getUnitLangname} from "../../config/entities";
import {AkteurPicker} from "../atoms/AkteurPicker";
import WarningOutlined from "@ant-design/icons/lib/icons/WarningOutlined";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import moment from "moment";
import {ArzneimittelPopoverData, getArzneimittelLangname} from "../atoms/Arzneimittel";
import {getQuartalsende} from "../../utilities/dateUtil";
import {berechneBedarfProIntervall, dosierschemaSort} from "../../utilities/dosierschemaUtil";
import {buendelApi, planzeilenApi} from "../../config/apiConfig";
import { Link } from 'react-router-dom';
import {useApi} from "../../utilities/useApi";
import {Abschnitt} from "./Dosierschema";
import {Loading} from "../templates/defaultPages";
import NotizContext from "../../contexts/NotizContext";
import {getCurrentZeitraum, getCurrentZeitraumTyp} from "../../utilities/useApiZeitraum";

const { TextArea } = Input;

const RezeptanforderungModal = ({
                                    visible = false,
                                    onOk = (anforderung)=>{},
                                    onCancel = ()=>{},
                                    hausarzt = null,
                                    verordner = null,
                                    arzneimittel = null,
                                    bedarf = 0,
                                    planzeile = {},
                                    kommentar:kommentarProp="",
                                    anforderungData = [{}]
                                }) => {

    const auth = useAuth();
    const api = useApi();
    const anforderungDataRef = useRef();

    const notizContext = useContext(NotizContext);

    const [gewaehlterArzt, setGewaehlterArzt] = React.useState(null);
    const [andererArzt, setAndererArzt] = React.useState(null);

    const [gewaehltesArzneimittel, setGewaehltesArzneimittel] = React.useState({});
    const [gewaehltesArzneimittelMenge, setGewaehltesArzneimittelMenge] = React.useState(1);

    const [arzneimittelEmpfehlungen, setArzneimittelEmpfehlungen] = useState([]);

    const [anforderung, setAnforderung] = React.useState(null);

    const [kommentar, setKommentar] = useState(kommentarProp);

    const [dosierabschnittAktuell, setDosierabschnittAktuell] = useState(null);
    const [dosierabschnittNext, setDosierabschnittNext] = useState(null);

    const [notizen, setNotizen] = useState(null);
    const [patient, setPatient] = useState(null);

    useEffect(() => {
        if (anforderungData && JSON.stringify(anforderungData) !== JSON.stringify(anforderungDataRef.current)) {
            anforderungDataRef.current = anforderungData;

            const ersteAnforderung = anforderungData[0] ? {...anforderungData[0]} : {
                mengeQuartal: 0,
                mengeBisQuartalsende: 0
            }

            getKommentar({ersteAnforderung, kommentar: kommentarProp, api, auth, verordnerId: (verordner || hausarzt)?.id, planzeile})
                .then(setKommentar);

            const anforderungNeu = {
                ...ersteAnforderung,

                "mengeBenoetigt": anforderungData.mengeBenoetigt,
                "mengeBenoetigtMin": anforderungData.mengeBenoetigtMin,

                "arzneimittel": ersteAnforderung.arzneimittel,
                "planzeile": {
                    "id": planzeile.id
                },
                "anzahlArzneimittel": 1,
                "mahnstufe": 0
            };

            setAnforderung(prev => ({
                ...prev,
                ...anforderungNeu
            }));

            setGewaehltesArzneimittel(arzneimittel);
            setGewaehltesArzneimittelMenge(1);
            loadArzneimittelEmpfehlungen(anforderungNeu);

            if (api.akteure[planzeile.medikationsplan.patient.id]) {
                setPatient(api.akteure[planzeile.medikationsplan.patient.id]);
            } else {
                api.loadAkteur(planzeile.medikationsplan.patient.id).then(setPatient);
            }
        }

        if (anforderungData && (verordner || hausarzt)) {
            setGewaehlterArzt(verordner ? verordner : hausarzt);
        }
    }, [anforderungData, verordner, hausarzt])

    useEffect(() => {
        if (!visible) {
            setNotizen(null);
        }
    }, [visible])

    useEffect(() => {
        if (gewaehlterArzt) {
            setAnforderung(prev => ({
                ...prev,
                "verordner": {
                    "type": "Person", "id": gewaehlterArzt ? gewaehlterArzt.id : null
                }
            }))
        }
    }, [gewaehlterArzt])

    useEffect(() => {
        if (planzeile) (async () => {
            // Dosierabschnitt
            const anzeigeDatum = moment().startOf('day');
            const sortiert = [...(planzeile.dosierschema) || []].sort((a, b) => dosierschemaSort(a, b, anzeigeDatum.valueOf()));
            const dosierabschnitt = sortiert.find(abschnitt => {
                const start = abschnitt.start ? moment(abschnitt.start) : null;
                const ende = abschnitt.ende ? moment(abschnitt.ende) : null;

                return !(ende?.isBefore(anzeigeDatum)) && !(start && anzeigeDatum.isBefore(start));
            });
            setDosierabschnittAktuell(dosierabschnitt);

            if (!!dosierabschnitt) {
                setDosierabschnittNext(null);
            } else {
                const dosierabschnittNext = sortiert.find(abschnitt => {
                    const start = abschnitt.start ? moment(abschnitt.start) : null;
                    return start && start.isAfter(anzeigeDatum);
                });
                setDosierabschnittNext(dosierabschnittNext);
            }

            // Notizen
            const patientId = planzeile?.medikationsplan?.patient?.id;
            if (patientId) {
                setNotizen(await getNotizen({api, notizContext, planzeile}));
            }
        })();
    }, [planzeile])

    useEffect(() => {
        if (gewaehltesArzneimittel && gewaehltesArzneimittelMenge) {
            let anzahlArzneimittel = parseInt(gewaehltesArzneimittelMenge);
            if (!anzahlArzneimittel) {
                return;
            }

            setAnforderung(prev => ({
                ...prev,
                arzneimittel: {
                    id: gewaehltesArzneimittel.id
                },
                anzahlArzneimittel
            }));
        }
    }, [gewaehltesArzneimittel, gewaehltesArzneimittelMenge])


    useEffect(() => {
        setAnforderung(prev => ({
            ...prev,
            kommentar
        }))
    }, [kommentar])

    useEffect(() => {
        if (anforderung) {
            if (arzneimittel && !anforderung.mengeBenoetigt) {
                if (!arzneimittelEmpfehlungen.length) {
                    loadArzneimittelEmpfehlungen();
                }
            }
        }
    }, [anforderung])

    const reset = () => {
        setGewaehlterArzt(null);
        setGewaehltesArzneimittel({});
        setGewaehltesArzneimittelMenge(1);
        setAnforderung(null);

        setAndererArzt(null);
        setArzneimittelEmpfehlungen([]);
        setDosierabschnittAktuell(null);
        setDosierabschnittNext(null);

        setNotizen(null);
        setPatient(null);
    }



    const erstelleAnforderung = usePost(postErstelleAnforderung(), anforderung)

    const handleSend = async () => {
        if (!anforderung.verordner || !anforderung.verordner.id) {
            notification.error({message: "Bitte einen Arzt auswählen."});
            return;
        }

        const anforderungNeu = await erstelleAnforderung.handleSend();
        api.addAnforderungGesammelt(anforderungNeu);

        onOk(anforderungNeu);
    }

    const loadArzneimittelEmpfehlungen = async (anforderungProp) => {
        if (!arzneimittel) return null;

        const result = await callApiAsync({auth, url: fetchArzneimittelGleicheM2ById(arzneimittel.id)}).catch(err => {
            console.error(`FEHLER in loadArzneimittelEmpfehlung() (${arzneimittel.id}):`, err);
        });

        const empfehlungen = result.data.OBJECT.sort((a, b) => (a.menge - b.menge));
        const a = anforderungProp || anforderung;
        const [gewaehltesArzneimittelNeu, gewaehltesArzneimittelMengeNeu] = getArzneimittelEmpfehlung(empfehlungen, a.mengeBenoetigt, a.mengeBenoetigtMin);

        setArzneimittelEmpfehlungen(empfehlungen);
        setGewaehltesArzneimittel(gewaehltesArzneimittelNeu);
        setGewaehltesArzneimittelMenge(gewaehltesArzneimittelMengeNeu);

        return empfehlungen;
    }

    return <Modal
        title="Rezeptanforderung"
        visible={visible}
        onCancel={() => {
            reset();
            if (onCancel) onCancel();
        }}
        footer={null}
    >

        <div style={{display: "flex", flexDirection: "column"}}>
            {!planzeile.freigabe && <h3 className={'warning'}>Planzeile ist nicht freigegeben!</h3>}

            {!!patient && <>
                <h4>{getUnitLangname(patient)}</h4>
                <p>
                    {getUnitLangname(patient.careFacility)} - {getUnitLangname(patient.station)}
                </p>
            </>}

            <h4>Arzt wählen</h4>
            {gewaehlterArzt && (!gewaehlterArzt.addresses || !gewaehlterArzt.addresses.length) ? <Link to={`/adressbuch/${gewaehlterArzt.id}/adressen`}><span className={"warning warning-arzt-ohne-anschrift"}><WarningOutlined /> Es ist keine Anschrift hinterlegt.</span><br/></Link> : <></>}
            <Radio.Group
                onChange={(e) => setGewaehlterArzt(e.target.value)}
                value={gewaehlterArzt}
            >

                {hausarzt &&
                <div>
                    <Radio.Button
                        className={"radio-rezept-anfordern-modal" + ((hausarzt == gewaehlterArzt) ? " checked" : "")}
                        value={hausarzt}>
                        <b>{getUnitLangname(hausarzt, "arzt")}</b> (Hausarzt)
                    </Radio.Button>
                </div>
                }

                {verordner &&
                <div>
                    <Radio.Button
                        className={"radio-rezept-anfordern-modal" + ((verordner == gewaehlterArzt) ? " checked" : "")}
                        value={verordner}>
                        <b>{getUnitLangname(verordner, "arzt")}</b> (Verordner)
                    </Radio.Button>
                </div>
                }
            </Radio.Group>

            <div>
                <AkteurPicker
                    value={andererArzt}
                    onChange={(e) => {
                        setAndererArzt(e);
                        setGewaehlterArzt(e);
                    }}
                />
            </div>

            <Divider/>
            <h5>{arzneimittel?.langname}</h5>
            <div>
                {anforderung && <>
                    <b>Benötigte Menge: {planzeile?.bedarf} {arzneimittel?.einheit} {planzeile?.datumBedarf ? `(bis ${moment(planzeile?.datumBedarf).format('DD.MM.YYYY')})` : ''}{planzeile?.bedarfMin && planzeile.bedarfMin !== planzeile?.bedarf ? ` (min. ${planzeile.bedarfMin} ${arzneimittel?.einheit})` : ''}</b><br/>
                    {anforderung?.datumBenoetigt && <b>Rezept benötigt bis {moment(anforderung?.datumBenoetigt).format("DD.MM.YYYY")}</b>}
                </>}
            </div>
            <br/>

            <h6>Aktuelle Dosierung:</h6>
            {!!dosierabschnittAktuell ? <Abschnitt abschnitt={dosierabschnittAktuell} planzeile={planzeile} /> : "[Kein aktueller Dosierabschnitt vorhanden]"}
            <p />

            {!dosierabschnittAktuell && <>
                <h6>Kommende Dosierung:</h6>
                {!!dosierabschnittNext ? <Abschnitt abschnitt={dosierabschnittNext} planzeile={planzeile} /> : "[Kein zukünftiger Dosierabschnitt vorhanden]"}
                <p />
            </>}

            {notizen ? (notizen.length ? <div style={{backgroundColor: "#ffbb4c", padding: 5}}>
                <h6>Notizen:</h6>
                {notizen.map(notiz => <p key={notiz.id}>
                    {notiz.titel && <>{notiz.titel}:<br/></>}
                    {notiz.text}
                </p>)}
            </div> : <></>) : <Loading showText text={"Notizen werden geladen"} size={"sm"} />}
            <p />

            {!bedarf ? <></> : <h6>Eine bestimmte Packung anfordern:</h6>}
            <Radio.Group
                onChange={(e) => {
                    setGewaehltesArzneimittel(e.target.value);
                }}
                value={gewaehltesArzneimittel}
            >

                {arzneimittelEmpfehlungen && Array.isArray(arzneimittelEmpfehlungen) && arzneimittelEmpfehlungen.map(
                    (am, index) => {
                        return <Popover key={index} content={<ArzneimittelPopoverData arzneimittel={am} />} placement={"right"} trigger={"hover"}><div>
                            <Radio.Button
                                className={"radio-rezept-anfordern-modal" + ((gewaehltesArzneimittel && am.id === gewaehltesArzneimittel.id) ? " checked" : "")}
                                value={am}>
                                <b>PZN: {("00000000"+am.pzn).slice(-8)} - {am.menge} {am.einheit} </b> ({am.zusammenstellung}) {am.normgroesse}
                            </Radio.Button>
                        </div></Popover>
                    }
                )}
            </Radio.Group>

            <Input addonBefore={"Menge"} addonAfter={"Pkg"} value={gewaehltesArzneimittelMenge} onChange={e => setGewaehltesArzneimittelMenge(e.target.value)} />

            <Divider/>
            <h5>Kommentar:</h5>
            <TextArea rows={2} value={kommentar} onChange={e => setKommentar(e.target.value)} />

            <Divider/>
            <Button onClick={handleSend} type={"primary"}>Rezeptanforderung erstellen</Button>

            {/*<Collapse>*/}
            {/*    <Panel header="JSON">*/}
            {/*        <pre>{JSON.stringify(anforderung, null, 2)}</pre>*/}
            {/*    </Panel>*/}
            {/*</Collapse>*/}
        </div>


    </Modal>

}

export const getAnforderungDataAsync = async (planzeile, auth, ansprueche, bedarfAnforderungList, isVorab=false) => {
    const endeQuartal = getQuartalsende().add(1, "day");
    let ende = moment(planzeile.datumRezeptBenoetigtBis);

    if (!ansprueche) ansprueche = (await callApiAsync({auth, url: buendelApi.getEntitlementList(planzeile.buendelId)})).data.OBJECT;
    if (!bedarfAnforderungList) bedarfAnforderungList = (await callApiAsync({auth, url: planzeilenApi.getBedarfAnforderungen(planzeile.id)})).data.OBJECT;

    let bedarfAnforderung = null;
    if (isVorab) {
        const filter = bedarfAnforderungList.filter(ba => ba.id.typ === "VORAB");
        bedarfAnforderung = filter[0];
        if (!bedarfAnforderung) {
            return {};
        }
    } else {
        const configJsonString = planzeile.verordner ? planzeile.verordner.configJsonString : null;
        if (configJsonString) {
            const configJson = JSON.parse(configJsonString);
            const filter = bedarfAnforderungList.filter(ba => ba.id.typ === configJson.MEDIKATIONSPLAN_ANFORDERUNG_BEDARF_TYP);
            bedarfAnforderung = filter[0];
        } else if (bedarfAnforderungList.length) {
            bedarfAnforderung = bedarfAnforderungList.filter(e => e.id.typ === "WOCHEN_12")[0];
        } else if (planzeile.bedarf) {
            bedarfAnforderung = {id: {planzeileId: planzeile.id, typ: "WOCHEN_12"}, datumBedarf: planzeile.datumBedarf, mengeBedarf: planzeile.bedarf}
        }
    }

    const abgabePlan = (await callApiAsync({auth, url: fetchAbgabeplanBetween(planzeile.id, moment.now(), ende)})).data.OBJECT;
    const ersteAbgabeZeit = !!abgabePlan.length ? moment(abgabePlan[0].abgabeZeit) : null;

    // ermittle Dosierabschnitt für erste Abgabe
    let dosierabschnitt, startDosierabschnitt, endeDosierabschnitt, indexDosierabschnitt;
    for (let i = 0; i < planzeile.dosierschema.length; i++) {
        dosierabschnitt = planzeile.dosierschema[i];

        if (moment(dosierabschnitt.start).isSameOrBefore(ersteAbgabeZeit)) {
            if (!dosierabschnitt.ende || moment(dosierabschnitt.ende).isSameOrAfter(ersteAbgabeZeit)) {
                startDosierabschnitt = moment(dosierabschnitt.start);
                endeDosierabschnitt = dosierabschnitt.ende ? moment(dosierabschnitt.ende).add(1, "day") : null;
                indexDosierabschnitt = i;

                break;
            }
        }
    }
    if (!dosierabschnitt && !isVorab) {
        notification.error({message: "Bei der Berechnung der Daten für die Anforderung ist ein Fehler aufgetreten: Kein Dosierabschnitt vorhanden für erste Abgabe."});
        return {};
    }

    const bedarfType = "Woche";

    // forEach Abgaben => Berechne Anforderung-Daten
    const menge = isVorab ? {default: bedarfAnforderung?.mengeBedarf || 0} : (berechneBedarfProIntervall(dosierabschnitt.abgabemuster, dosierabschnitt.wiederholungsTag, bedarfType) || {default: bedarfAnforderung?.mengeBedarf || 0});

    const response = await callApiAsync({auth, url: planzeilenApi.getDosierabschnittString(planzeile.id, true)});
    const responseMap = response.data.OBJECT;

    const {planzeileIdToDosierabschnittIdMap, dosierabschnittStringMap} = responseMap;
    const dosierungString = dosierabschnittStringMap[planzeileIdToDosierabschnittIdMap[planzeile.id]];
    
    const dosierungStringMap = {};
    if (Object.keys(planzeileIdToDosierabschnittIdMap).length > 1) {
        for (let planzeileIdString in planzeileIdToDosierabschnittIdMap) {
            dosierungStringMap[planzeileIdString] = dosierabschnittStringMap[planzeileIdToDosierabschnittIdMap[planzeileIdString]];
        }
    }

    const bedarfMengeObj = [{menge, dosierung: {"000000000000": dosierungString}}];
    for (let abgabe of abgabePlan) {
        if (abgabe.packungId) continue;

        const abgabeZeit = moment(abgabe.abgabeZeit);

        // prüfe Dosierabschnitt und aktualisiere diesen gegebenenfalls
        if (endeDosierabschnitt && endeDosierabschnitt.isBefore(abgabeZeit)) {
            indexDosierabschnitt++;
            const dosierabschnitt = planzeile.dosierschema[indexDosierabschnitt];
            if (!dosierabschnitt) {
                notification.error({message: "Bei der Berechnung der Daten für die Anforderung ist ein Fehler aufgetreten: Nicht alle Abgaben sind durch Dosierabschnitte abgedeckt."});
                return;
            }

            startDosierabschnitt = moment(dosierabschnitt.start);
            endeDosierabschnitt = dosierabschnitt.ende ? moment(dosierabschnitt.ende) : null;
            bedarfMengeObj.push({
                menge: berechneBedarfProIntervall(dosierabschnitt.abgabemuster, dosierabschnitt.wiederholungsTag, bedarfType),
                dosierung: {"000000000000": dosierungString}
            });
        }
    }

    const bedarfMenge = {};
    const dosierung = {};
    bedarfMengeObj.forEach(bedarfObj => {
        if (bedarfObj.menge) for (let m2String of Object.keys(bedarfObj.menge)) {
            if (!bedarfMenge[m2String]) bedarfMenge[m2String] = bedarfObj.menge[m2String];
            else if (bedarfMenge[m2String] !== -1 && bedarfMenge[m2String] !== bedarfMenge[m2String]) bedarfMenge[m2String] = -1;
        }


        if (bedarfObj.dosierung) for (let m2String of Object.keys(bedarfObj.dosierung)) {
            if (!dosierung[m2String]) dosierung[m2String] = bedarfObj.dosierung[m2String];
            else if (dosierung[m2String] !== "" && dosierung[m2String] !== dosierung[m2String]) dosierung[m2String] = "";
        }
    });

    const anforderungDataNew = [];
    Object.keys(bedarfMenge).forEach(m2String => {
        anforderungDataNew.push({
            planzeile,
            arzneimittel: m2String === "default" ? planzeile.arzneimittel : m2String,
            dosierungString: dosierung?.[m2String] || "-",
            bestand: (ansprueche || []).reduce((mengeVerblisterung, anspruch) => mengeVerblisterung + anspruch.amount, 0),
            datumBenoetigt: ende.valueOf(),
            bedarfAnforderung,
            vorabgabe: bedarfAnforderung && bedarfAnforderung.id.typ === "VORAB",
            mengeBenoetigt: bedarfAnforderung ? bedarfAnforderung.mengeBedarf : planzeile.bedarf,
            mengeBenoetigtMin: planzeile.bedarfMin,
            mengeBisQuartalsende: planzeile.bedarfBisQuartalsende,
            datumQuartal: endeQuartal.subtract(1, "day"),
            mengeQuartal: planzeile.bedarf,

            bedarfAnforderungList,
            dosierungStringMap
        });
    });

    return anforderungDataNew;
}

export const getBedarfAnforderungTypString = (bedarfAnforderungTyp) => {
    switch (bedarfAnforderungTyp) {
        case "WOCHEN_1":
            return "1 Woche";
        case "WOCHEN_2":
            return "2 Wochen";
        case "WOCHEN_3":
            return "3 Wochen";
        case "WOCHEN_4":
            return "4 Wochen";
        case "WOCHEN_12":
            return "12 Wochen";
        case "QUARTAL":
            return "Bis Quartalsende";
        case "QUARTAL_PLUS":
            return "Bis Quartalsende+";
        case "VORAB":
            return "Vorab";
    }
}

export const erstelleAnforderung = async ({anforderungData, api, auth, notizContext, verordner}) => {
    if (anforderungData && auth) {
        const ersteAnforderung = anforderungData[0] ? {...anforderungData[0]} : {
            mengeQuartal: 0,
            mengeBisQuartalsende: 0
        }

        const planzeile = ersteAnforderung?.planzeile;
        const arzneimittel = ersteAnforderung?.arzneimittel === "000000000000" ? planzeile?.arzneimittel : ersteAnforderung.arzneimittel;
        if (!verordner) verordner = verordner = planzeile?.verordner || planzeile?.medikationsplan?.patient?.hausarzt;

        if (
            !planzeile ||
            !planzeile.freigabe ||
            !verordner ||
            !arzneimittel
        ) {
            return null;
        }

        // check Notizen
        const notizen = await getNotizen({api, notizContext, planzeile});
        if (notizen.length) {
            return null;
        }

        // check Arzneimittel (benötigt N-Größe)
        let mengeBenoetigt = anforderungData.mengeBenoetigt;
        let gewaehltesArzneimittel = arzneimittel;
        let gewaehltesArzneimittelMenge = 1;
        const result = await callApiAsync({auth, url: fetchArzneimittelGleicheM2ById(arzneimittel.id)}).catch(err => {
            console.error(`FEHLER in erstelleAnforderung() (${arzneimittel.id}):`, err);
        });
        const arzneimittelEmpfehlungen = result.data.OBJECT.sort((a, b) => a.menge - b.menge);
        [gewaehltesArzneimittel, gewaehltesArzneimittelMenge] = getArzneimittelEmpfehlung(arzneimittelEmpfehlungen, ersteAnforderung.mengeBenoetigt, ersteAnforderung.mengeBenoetigtMin);
        if (!gewaehltesArzneimittel) {
            return null;
        }

        // Check Verordner-Zeitraum
        let zeitraumMap = api.unitIdToZeitraumMapMap[verordner.id];
        if (!zeitraumMap) zeitraumMap = await api.getZeitraumMapByUnit(verordner.id);
        const currentZeitraumTyp = getCurrentZeitraumTyp(zeitraumMap, null, 14);
        if (currentZeitraumTyp === 'U' || currentZeitraumTyp === 'U_B') {
            return null;
        }

        const kommentar = await getKommentar({ersteAnforderung, api, auth, verordnerId: verordner.id, planzeile})

        const anforderung = {
            ...ersteAnforderung,
            mengeBenoetigt,
            kommentar,

            "arzneimittel": {
                id: gewaehltesArzneimittel?.id
            },
            "planzeile": {
                "id": planzeile.id
            },
            "verordner": {
                "type": verordner.type, "id": verordner.id
            },
            "anzahlArzneimittel": gewaehltesArzneimittelMenge,
            "mahnstufe": 0
        };

        return anforderung;
    }
}

const getNotizen = async ({api, notizContext, planzeile}) => {
    if (!api || !notizContext || !planzeile) return [];

    const patientId = planzeile?.medikationsplan?.patient?.id;

    let notizenMap = notizContext.notizMap[patientId];
    if (!notizenMap) {
        notizenMap = await api.loadNotizenForUnit(patientId);
    }

    const heute = moment().startOf('day');
    const notizenList = [];
    for (let notizId in notizenMap) {
        const notiz = notizenMap[notizId];

        if (
            notiz.unitSet.find(e => e.anzeigenBeiAnforderung && (!e.planzeilen.length || e.planzeilen.find(p => p.id.planzeileId === planzeile.id)) && (e.gueltigVon && !heute.isBefore(e.gueltigVon)) && (!e.gueltigBis || !heute.isAfter(e.gueltigBis))) ||
            notiz.interaktionSet.find(e => e.anzeigenBeiAnforderung && e.id.unitId === patientId && (e.gueltigVon && !heute.isBefore(e.gueltigVon)) && (!e.gueltigBis || !heute.isAfter(e.gueltigBis)))
        ) {
            notizenList.push(notiz);
        }
    }

    return notizenList;
}

const getKommentar = async ({ersteAnforderung, kommentar, api, auth, verordnerId, planzeile}) => {
    kommentar = kommentar || '';

    if (!ersteAnforderung || !api || !auth || !verordnerId || !planzeile)
        return kommentar;

    // Praxisurlaub
    if (planzeile.datumAnforderungVorverlegt) {
        const kommentarAdd = await getPraxisurlaubKommentar(api, verordnerId);
        if (kommentarAdd) kommentar += kommentar?.length ? `\n${kommentarAdd}` : kommentarAdd;
    }

    // Mehrere aktive Planzeilen im Bündel
    const dosierungStringMap = ersteAnforderung.dosierungStringMap;
    if (dosierungStringMap && Object.keys(dosierungStringMap).length) {
        const kommentarAdd = await getPlanzeilenKombinationKommentar(auth, dosierungStringMap, planzeile);
        if (kommentarAdd) kommentar += kommentar?.length ? `\n${kommentarAdd}` : kommentarAdd;
    }

    // Kommentar 'genau diese Wirkstärke', wenn Planzeilenwirkstärke nicht in Bestand
    const kommentarAdd = await getPlanzeileWirkstaerkeKommentar(api, planzeile.buendelId);
    if (kommentarAdd) kommentar += kommentar?.length ? `\n${kommentarAdd}` : kommentarAdd;

    return kommentar;
}

const getPraxisurlaubKommentar = async (api, verordnerId) => {
    if (!verordnerId) return;

    const zeitraumMap = await api.getZeitraumMapByUnit(verordnerId);
    const zeitraum = getCurrentZeitraum(zeitraumMap, null, 14);
    if (zeitraum) {
        return `Anforderungsdatum wg. Praxisurlaubs (${moment(zeitraum.id.start).format('DD.MM.YYYY')} - ${moment(zeitraum.ende).format('DD.MM.YYYY')}) vorverlegt`;
    }
}

const getPlanzeilenKombinationKommentar = async (auth, dosierungStringMap, planzeile) => {
    const stringList = [];
    let planzeileObj;
    for (let planzeileIdString in dosierungStringMap) {
        if (planzeileIdString === ""+planzeile.id) {
            planzeileObj = planzeile;
        } else {
            const response = await callApiAsync({auth, url: planzeilenApi.getById(planzeileIdString, true)});
            planzeileObj = response.data.OBJECT;
        }

        stringList.push(`${getArzneimittelLangname(planzeileObj.arzneimittel)}: ${dosierungStringMap[planzeileIdString]}`);
    }

    stringList.sort();
    return `Das Medikament ist Teil der Kombination:\n- ${stringList.join('\n- ')}`;
}

const getPlanzeileWirkstaerkeKommentar = async (api, buendelId) => {
    const buendel = await api.loadBuendel(buendelId);
    const passendeDosierungVorhanden = buendel.internalPackageSet?.some(p => p.active && p.factorBundle === 1);
    if (!passendeDosierungVorhanden) {
        return "Bitte genau diese Wirkstärke verordnen.";
    }
}

const getArzneimittelEmpfehlung = (arzneimittelEmpfehlungen, mengeBenoetigt, mengeBenoetigtMin) => {
    let gewaehltesArzneimittel, gewaehltesArzneimittelMenge = 1;

    for (const arzneimittel of arzneimittelEmpfehlungen) {
        if (!arzneimittel.normgroesse) continue;

        gewaehltesArzneimittel = arzneimittel;

        if (arzneimittel.menge >= mengeBenoetigt) {
            break;
        }
    }

    while (gewaehltesArzneimittel && gewaehltesArzneimittel.menge * gewaehltesArzneimittelMenge < mengeBenoetigtMin) {
        gewaehltesArzneimittelMenge++;
    }

    return [gewaehltesArzneimittel, gewaehltesArzneimittelMenge];
}


export {RezeptanforderungModal}