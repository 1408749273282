import React, {createContext, useEffect, useState} from "react";

const initVal = {
    lastHotkey: null,
    setLastHotkey: hotkey=>{},
}

const GlobalHotkeyContext = createContext(initVal)
export default GlobalHotkeyContext;

export const useInitialState = () => {
    const [lastHotkey, setLastHotkey] = useState(null);

    useEffect(() => {
        if (lastHotkey) {
            setLastHotkey(null);
        }
    }, [lastHotkey])

    return {
        lastHotkey,
        setLastHotkey
    }
}