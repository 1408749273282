import {getUnitLangname} from "../config/entities";
import moment from "moment";
import {getArzneimittelLangname} from "../components/atoms/Arzneimittel";
import {ZeitraumTyp} from "../components/organisms/MedikationsplanZeitraeume";
import {getMedicineM2Name} from "./medicineM2Util";

const createSorter = (entityType, sortMethod="default") => {
    switch (entityType) {
        case "reverse":
            return (a, b) => {
                if (!a && !b) return 0;
                if (!a) return -1;
                if (!b) return 1;
                return b - a;
            }
        case "string":
            switch (sortMethod) {
                case 'default':
                    return (a, b) => (a || "").localeCompare(b || "");
                default:
                    return (a, b) => (a[sortMethod] || "").localeCompare(b[sortMethod] || "");
            }
        case "boolean":
            return (a, b) => (a === b) ? 0 : a ? -1 : 1;
        case "id":
            const idAttr = sortMethod === "default" ? 'id' : sortMethod;
            return (a, b) => a[idAttr] - b[idAttr];
        case "moment":
            switch (sortMethod) {
                case "nullFirst":
                    return (a, b) => {
                        if (!a && !b) return 0;
                        if (!a) return -1;
                        if (!b) return 1;
                        const number = moment(a).diff(moment(b));
                        return number;
                    }
                case "nullLast":
                default:
                    return (a, b) => {
                        if (!a && !b) return 0;
                        if (!a) return 1;
                        if (!b) return -1;
                        return moment(a).diff(moment(b));
                    }
            }
        case "date":
            switch (sortMethod) {
                case "nullFirst":
                    return (a, b) => {
                        if (!a && !b) return 0;
                        if (!a) return -1;
                        if (!b) return 1;
                        return a - b;
                    }
                case "nullLast":
                default:
                    return (a, b) => {
                        if (!a && !b) return 0;
                        if (!a) return 1;
                        if (!b) return -1;
                        return a - b;
                    }
            }
        case "unit":
            switch (sortMethod) {
                case "heim":
                    return (a, b) => getSorter("unit", "name")(a?.careFacility, b?.careFacility);
                    break;
                case "station":
                    return (a, b) => getSorter("unit", "name")(a?.station, b?.station);
                    break;
                case "name":
                default:
                    return (a, b) => {
                        if (a && b) {
                            return getUnitLangname(a).localeCompare(getUnitLangname(b));
                        } else if (!a && !b) {
                            return 0;
                        } else if (!b) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
            }
        case "dokument":
            switch (sortMethod) {
                case "pflegeheim":
                    return (a, b) => getSorter("unit")(a?.pflegeheim, b?.pflegeheim);
                case "verordner":
                    return (a, b) => getSorter("unit")(a?.verordner, b?.verordner);
                case "quelle":
                    return (a, b) => getSorter("unit")(a?.quelle, b?.quelle);
                case "patientName":
                    return (a, b) => getSorter("unit")(a?.patient, b?.patient);
                case "datumErstellung":
                    return (a, b) => getSorter("date")(a?.datumErstellung, b?.datumErstellung);
                case "datumEingang":
                    return (a, b) => getSorter("date")(a?.datumEingang, b?.datumEingang);
                case "uploadTimestamp":
                    return (a, b) => getSorter("date")(a?.dokument?.timeUploaded, b?.dokument?.timeUploaded);
                case "zuletztBearbeitet":
                    return (a, b) => {
                        const bearbeitungA = a?.bearbeitungen?.length ? a?.bearbeitungen.sort((a, b) => {
                            if (a?.datumBearbeitungAbgeschlossen && !b?.datumBearbeitungAbgeschlossen) return 1;
                            if (!a?.datumBearbeitungAbgeschlossen && b?.datumBearbeitungAbgeschlossen) return -1;
                            if (!a?.datumBearbeitungAbgeschlossen && !b?.datumBearbeitungAbgeschlossen) return 0;
                            return b?.datumBearbeitungAbgeschlossen - a?.datumBearbeitungAbgeschlossen;
                        })[0] : null;

                        const bearbeitungB = b?.bearbeitungen?.length ? b?.bearbeitungen?.sort((a, b) => {
                            if (a?.datumBearbeitungAbgeschlossen && !b?.datumBearbeitungAbgeschlossen) return 1;
                            if (!a?.datumBearbeitungAbgeschlossen && b?.datumBearbeitungAbgeschlossen) return -1;
                            if (!a?.datumBearbeitungAbgeschlossen && !b?.datumBearbeitungAbgeschlossen) return 0;
                            return b?.datumBearbeitungAbgeschlossen - a?.datumBearbeitungAbgeschlossen;
                        })[0] : null;

                        return getSorter("date")(bearbeitungA?.datumBearbeitungAbgeschlossen, bearbeitungB?.datumBearbeitungAbgeschlossen);
                    }
                case "typ":
                    return (a, b) => {
                        if (a?.typ && b?.typ) {
                            return a?.typ.localeCompare(b?.typ);
                        } else if (!a?.typ && !b?.typ) {
                            return 0;
                        } else if (!b?.typ) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                case "titel":
                default:
                    return (a, b) => {
                        if (a?.dokument?.titel && b?.dokument?.titel) {
                            return (''+a?.dokument?.titel).localeCompare(b?.dokument?.titel);
                        } else if (!a?.dokument?.titel && !b?.dokument?.titel) {
                            return (''+a?.dokument?.dateiname).localeCompare(b?.dokument?.dateiname);
                        } else if (a?.dokument?.titel) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
            }
        case "dokumentVerknuepfung":
            switch (sortMethod) {
                case "typ":
                    return (a, b) => {
                        const val = DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[a?.typ || "__NONE__"]].index - DokumentVerknuepfungTypen[DokumentVerknuepfungTypenMap[b?.typ || "__NONE__"]].index;
                        if (val === 0) {
                            return getSorter("unit", "name")(a?.unit, b?.unit);
                        } else {
                            return val;
                        }
                    }
                case "gueltigVon":
                default:
                    return (a, b) => {
                        const val = getSorter("date")(a?.gueltigVon, b?.gueltigVon);
                        if (val === 0) {
                            // return a.dokument.id - b.dokument.id;
                            return 0;
                        } else {
                            return val;
                        }
                    }
            }
        case "medikationsplan":
            switch (sortMethod) {
                case "pflegeheim":
                    return (a, b) => getSorter("unit")(a?.patient?.careFacility, b?.patient?.careFacility);
                case "station":
                    return (a, b) => getSorter("unit")(a?.patient?.station, b?.patient?.station);
                case "patient":
                default:
                    return (a, b) => getSorter("unit")(a?.patient, b?.patient);
            }
        case "anspruch":
            switch (sortMethod) {
                case "arzneimittel":
                default:
                    return (a, b) => {
                        const arzneimittelLangnameA = getArzneimittelLangname(a?.arzneimittel);
                        const arzneimittelLangnameB = getArzneimittelLangname(b?.arzneimittel);
                        return arzneimittelLangnameA.localeCompare(arzneimittelLangnameB);
                    }
            }
        case "dosierabschnitt":
            switch (sortMethod) {
                case "start":
                default:
                    return (a, b) => getSorter("date")(a?.start, b?.start);
            }
        case "notiz":
            switch (sortMethod) {
                case "faelligkeit":
                    return (a, b) => getSorter("date", "nullFirst")(
                        [...(a.unitSet || []).map(e => e.faelligkeit), ...(a.interaktionSet || []).map(e => e.faelligkeit)].sort(getSorter("date", "nullFirst"))[0],
                        [...(b.unitSet || []).map(e => e.faelligkeit), ...(b.interaktionSet || []).map(e => e.faelligkeit)].sort(getSorter("date", "nullFirst"))[0]
                    );
                case "dateErstellt":
                    return (a, b) => getSorter("date", "nullFirst")(a.dateErstellt, b.dateErstellt);
                case "gueltigVon":
                    return (a, b) => getSorter("date", "nullFirst")(
                        [...(a.unitSet || []).map(e => e.gueltigVon), ...(a.interaktionSet || []).map(e => e.gueltigVon)].sort(getSorter("date", "nullFirst"))[0],
                        [...(b.unitSet || []).map(e => e.gueltigVon), ...(b.interaktionSet || []).map(e => e.gueltigVon)].sort(getSorter("date", "nullFirst"))[0]
                    );
                case "gueltigBis":
                    return (a, b) => getSorter("date", "nullFirst")(
                        [...(a.unitSet || []).map(e => e.gueltigBis), ...(a.interaktionSet || []).map(e => e.gueltigBis)].sort(getSorter("date", "nullFirst"))[0],
                        [...(b.unitSet || []).map(e => e.gueltigBis), ...(b.interaktionSet || []).map(e => e.gueltigBis)].sort(getSorter("date", "nullFirst"))[0]
                    );
                case "bearbeitet":
                    return (a, b) => getSorter("boolean")(
                        [...(a.unitSet || []).map(e => e.bearbeitet), ...(a.interaktionSet || []).map(e => e.bearbeitet)].sort(getSorter("boolean"))[0],
                        [...(b.unitSet || []).map(e => e.bearbeitet), ...(b.interaktionSet || []).map(e => e.bearbeitet)].sort(getSorter("boolean"))[0]
                    );
                case "titel":
                default:
                    return (a, b) => (a?.titel || "").localeCompare(b?.titel || "")
            }
        case "nachricht":
            switch (sortMethod) {
                case "versender":
                    return (a, b) => getSorter("unit")(a?.versender, b?.versender);
                case "senderUID":
                    return (a, b) => a?.senderUID.localeCompare(b?.senderUID);
                case "konto":
                    return (a, b) => (a?.konto ? a.konto.bezeichner : "").localeCompare(b?.konto ? b.konto.bezeichner : "");
                case "datum":
                default:
                    return (a, b) => getSorter("date")(a?.versendeDatum || a?.erstellungsDatum, b?.versendeDatum || b?.erstellungsDatum);
            }
        case "anforderung":
            switch (sortMethod) {
                case "arzneimittel":
                    return (a, b) => getArzneimittelLangname(a?.arzneimittel).localeCompare(getArzneimittelLangname(b?.arzneimittel));
                case "verordner":
                    return (a, b) => getSorter("unit")(a?.verordner, b?.verordner);
                case "datumAngefordert":
                    return (a, b) => getSorter("date")(a?.datumAngefordert, b?.datumAngefordert);
                case "datumFaxempfang":
                    return (a, b) => getSorter("date")(a?.datumFaxempfang, b?.datumFaxempfang);
                case "datumBenoetigt":
                    return (a, b) => getSorter("date")(a?.datumBenoetigt, b?.datumBenoetigt);
                case "erledigt":
                    return (a, b) => getSorter("date")(a?.datumErfuellt || a?.datumAbgebrochen, b?.datumErfuellt || b?.datumAbgebrochen);
                case "datumErfuellt":
                    return (a, b) => getSorter("date")(a?.datumErfuellt, b?.datumErfuellt);
                case "datumAbgebrochen":
                    return (a, b) => getSorter("date")(a?.datumAbgebrochen, b?.datumAbgebrochen);
                case "datumRezeptBenoetigtBis":
                    return (a, b) => getSorter("date")(a?.planzeile?.datumRezeptBenoetigtBis, b?.planzeile?.datumRezeptBenoetigtBis);
                case "reichweiteAnspruch":
                    return (a, b) => getSorter("date")(a?.planzeile?.reichweiteAnspruch, b?.planzeile?.reichweiteAnspruch);
                case "gemahnt":
                    return (a, b) => getSorter("date")(a?.datumMahnung3 || a?.datumMahnung2 || a?.datumMahnung1, b?.datumMahnung3 || b?.datumMahnung2 || b?.datumMahnung1);
                case "patient":
                default:
                    return (a, b) => getSorter("unit")(a?.planzeile?.medikationsplan?.patient, b?.planzeile?.medikationsplan?.patient);
            }
        case "planzeile":
            switch (sortMethod) {
                case "arzneimittel":
                    return (a, b) => getArzneimittelLangname(a?.arzneimittel).localeCompare(getArzneimittelLangname(b?.arzneimittel));
                case "arzt":
                    return (a, b) => getSorter("unit")(a?.verordner || a?.medikationsplan?.patient?.hausarzt, b?.verordner || b?.medikationsplan?.patient?.hausarzt);
                case "reichweite":
                    return (a, b) => getSorter("date")(a?.reichweiteAnspruch, b?.reichweiteAnspruch);
                case "datumRezeptBenoetigtBis":
                    return (a, b) => getSorter("date")(a?.datumRezeptBenoetigtBis, b?.datumRezeptBenoetigtBis);
                case "datumAnforderung":
                    return (a, b) => getSorter("date")(a?.datumAnforderung, b?.datumAnforderung);
                case "patient":
                default:
                    return (a, b) => getSorter("unit")(a?.medikationsplan?.patient, b?.medikationsplan?.patient);
            }
        case "logEntry":
            return (a, b) => getSorter("moment")({...a?.id?.creationDate?.date, ...a?.id?.creationDate?.time}, {...b?.id?.creationDate?.date, ...b?.id?.creationDate?.time})
        case "zeitraum":
            return (a, b) => {
                const sortStart = getSorter("date")(a?.id?.start, b?.id?.start);
                if (sortStart === 0) {
                    const sortEnde = getSorter("date")(a?.ende, b?.ende);
                    if (sortEnde === 0) {
                        return ZeitraumTyp[a.id.typ].sortOrder - ZeitraumTyp[b.id.typ].sortOrder;
                    }

                    return sortEnde;
                }

                return sortStart;
            }
        case "medicineM2":
            return (a, b) => getSorter("string")(getMedicineM2Name(a), getMedicineM2Name(b))
        case "medicineEntitlement":
            return (a, b) => getSorter("medicineM2")(a?.id?.medicineM2, b?.id?.medicineM2)
        case "internalPackage":
            return (a, b) => getSorter("medicineM2")(a?.batch?.id?.medicinePZN?.medicineM2, b?.batch?.id?.medicinePZN?.medicineM2)
    }
}

export const getSorter = (entityType, sortMethod="default", reverse=false) => {
    const sorter = createSorter(entityType, sortMethod);

    if (reverse) {
        return (a, b) => sorter(b, a);
    } else {
        return sorter;
    }
}

export const DokumentVerknuepfungTypen = [
    {
        key: "SUB",
        label: "Betrifft",
        index: 0,
        bearbeitetDefault: false,
    },{
        key: "VON",
        label: "Absender",
        index: 1,
        bearbeitetDefault: true,
    },{
        key: "AN",
        label: "Empfänger",
        index: 2,
        bearbeitetDefault: true,
    },{
        key: "PATIENT",
        label: "Patient",
        index: 3,
        disabled: true,
        bearbeitetDefault: false,
    },{
        key: "PFLEGEHEIM",
        label: "Pflegeheim",
        index: 4,
        disabled: true,
        bearbeitetDefault: true,
    },{
        key: "VERORDNER",
        label: "Verordner",
        index: 5,
        disabled: true,
        bearbeitetDefault: true,
    },{
        key: "BETREUER",
        label: "Betreuer",
        index: 6,
        disabled: true,
        bearbeitetDefault: true,
    },{
        key: "ANGEHOERIGER",
        label: "Angehöriger",
        index: 7,
        disabled: true,
        bearbeitetDefault: true,
    },{
        key: "SONSTIGE",
        label: "Sonstiger Bezug",
        index: 8,
        disabled: true,
        bearbeitetDefault: false,
    },{
        key: "QUELLE",
        label: "Quelle",
        index: 9,
        disabled: true,
        bearbeitetDefault: true,
    },{
        key: "__NONE__",
        label: "[K/A]",
        index: 10,
        disabled: true,
        bearbeitetDefault: true,
    }
];

export const DokumentVerknuepfungTypenMap = {
    SUB: 0,
    VON: 1,
    AN: 2,

    PATIENT: 3,
    PFLEGEHEIM: 4,
    VERORDNER: 5,
    BETREUER: 6,
    ANGEHOERIGER: 7,
    SONSTIGE: 8,
    QUELLE: 9,
    __NONE__: 10,
};