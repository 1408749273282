import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'

import moment from 'moment';
import 'moment/locale/de'

import {Button, Col, Collapse, Input, InputNumber, Menu, notification, Row, Select, Switch, Table, Tabs} from 'antd';
import {Popover} from "../atoms/Popover";

import {useDataNode} from './../../utilities/useDataNode';
import {
    getDosierabschnittDateRangeString,
    getMusterTyp,
    mengeToString,
    parseAbgabemuster
} from '../../utilities/dosierschemaUtil'

import {EINNAHMEZEITEN_FUENFFACH} from './../../config/misc';

import {InputWiederholungsTag} from './../atoms/InputWiederholungsTag';
import {InputDatum} from '../atoms/InputDatum';
import {InputBoolean} from './../atoms/InputBoolean';
import {InputUhrzeit} from './../atoms/InputUhrzeit';
import {InputMenge} from './../atoms/InputMenge';
import {InfomarkerButton} from './../atoms/InfomarkerButton';
import {Abgabemuster} from './Abgabemuster';
import {InputTextfeld} from '../atoms/InputTextfeld';
import DokumenteContext from "../../contexts/DokumenteContext";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import PlayCircleOutlined from "@ant-design/icons/lib/icons/PlayCircleOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import PauseOutlined from "@ant-design/icons/lib/icons/PauseOutlined";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import {arzneimittelApi, infoMarkerApi} from "../../config/apiConfig";
import {ArzneimittelAlternativeIcon} from "./ArzneimittelAlternative";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {getSorter} from "../../utilities/sortUtil";
import {loadBestandList} from "../organisms/MedikationsplanBestandTable";
import {ANSICHT_STANDARD} from "../organisms/MedikationsplanZeilen";
import {useOnClickOutside} from "../../utilities/useOnClickOutside";
import {WEEKDAYS_ALL} from "../../utilities/dateUtil";
import usePagination from "../../utilities/usePagination";
import {checkTeilbarkeitAbgabezeit} from "../../utilities/medicineM2Util";
import {useApi} from "../../utilities/useApi";
import EinnahmezeitTeilungExplizitErlaubtIcon from "./EinnahmezeitTeilungExplizitErlaubtIcon";
import ButtonSwitch from "../atoms/ButtonSwitch";
import medikationsplanContext from "../../contexts/MedikationsplanContext";
import {InfoCircleOutlined, InfoOutlined} from "@ant-design/icons";


const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

export const EditDosierschema = ({ value, onChange, planzeile, arzneimittel, patientId, onClose, blisterTag, triggerSave, setUpdated, faktorMenge, setSaved=()=>{}, handleSmartEdit=()=>{} }) => {

    const {
        current: dosierschema,
        status,
        handleChange,
        handleChangeMultiple,
        updated
    } = useDataNode({
        value,
        sorter: getSorter("dosierabschnitt", "start", true),
        // filter: (e) => (e.ende === null || moment(e.ende).diff() >= 0)
    });

    const medikationsplanContext = useContext(MedikationsplanContext);

    useEffect(() => {
        if (triggerSave) {
            handleSave();
        }
    }, [triggerSave])

    // ersten Dosierabschnitt ausklappen, wenn neu geladen
    useEffect(() => {
        let abschnittAusgeklappt = false;

        if (medikationsplanContext.dosierabschnittGeoeffnet) {
            const abschnitt = dosierschema.find(d => d.id === medikationsplanContext.dosierabschnittGeoeffnet.id);
            if (abschnitt) {
                abschnittAusgeklappt = true;
                setAusgeklappt(abschnitt.id);
            }
        }

        if (!abschnittAusgeklappt && dosierschema && dosierschema.length > 0 && !medikationsplanContext.oeffneDosierabschnitt) {
            const heute = moment().startOf('day');
            for (let abschnitt of dosierschema) {
                if (moment(abschnitt.start).startOf('day').isSameOrBefore(heute) && (!abschnitt.ende || moment(abschnitt.ende).startOf('day').isSameOrAfter(heute))) {
                    abschnittAusgeklappt = true;
                    setAusgeklappt(abschnitt.id);
                    break;
                }
            }
        }

        if (!abschnittAusgeklappt && dosierschema.length) {
            setAusgeklappt(dosierschema[0].id);
        }
    }, [dosierschema])

    // klappt einen Dosierabschnitt aus und einen anderen wieder ein, wenn dieser gerade ausgeklappt ist
    const setAusgeklappt = (dosierabschnittId) => {
        dosierabschnittId = parseInt(dosierabschnittId);
        if (medikationsplanContext.dosierabschnittGeoeffnet?.id === dosierabschnittId) return;

        medikationsplanContext.setOeffneDosierabschnitt(prev => {
            return null;
        });
        medikationsplanContext.setOeffneDosierabschnittPlanzeileId(prev => {
            return null;
        });
        medikationsplanContext.setDosierabschnittGeoeffnet(prev => {
            return {id: dosierabschnittId, planzeile};
        });
    }

    const handleSave = () => {
        if (updated) {
            onChange(dosierschema);
        }

        setSaved(true);
    };

    const handleNew = (blisterStartTag) => {
        const abschnittLast = {...dosierschema[0]};
        let abschnittLastEnde = abschnittLast.ende ? moment(abschnittLast.ende).startOf('day') : null;
        if (!abschnittLast.id) {
            abschnittLastEnde = moment().startOf('day').subtract(1, 'day');
        } else if (!abschnittLastEnde) {
            const abschnittLastStart = moment(abschnittLast.start).startOf('day');
            abschnittLastEnde = moment().startOf('day').add(1, 'week').isoWeekday(blisterStartTag).subtract(1, 'day');
            if (abschnittLastEnde.isBefore(abschnittLastStart)) abschnittLastEnde = abschnittLastStart;
            abschnittLast.ende = abschnittLastEnde.valueOf();
        }

        const momentNeu = abschnittLastEnde.add(1, 'day');

        const abschnittNeu = {
            id: medikationsplanContext.dosierabschnittNeuId.current--,
            start: momentNeu.valueOf(),
            wiederholungsTag: 1,
            freigabe: false,
            abgabemuster: []
        };

        handleChangeMultiple(!!abschnittLast.id ? [[abschnittLast], [abschnittNeu]] : [[abschnittNeu]]);
        setAusgeklappt(abschnittNeu.id);
    };

    const handleDelete = index => {
        const abschnitt = dosierschema[index];

        // setze Ende des Vorherigen Abschnitts auf das Ende des zu löschenden
        const handleChangeMultipleList = [[null, abschnitt.id]];
        if (index < dosierschema.length-1) handleChangeMultipleList.push([{...dosierschema[index + 1], ende: abschnitt.ende}]);

        handleChangeMultiple(handleChangeMultipleList);
    }

    if (!status.ready) return <></>;

    return <div style={{
        borderStyle: "solid",
        borderWidth: "3px",
        borderRadius: "10px",
        borderColor: "#97D3B8",
        padding: "5px"
    }}>
        <div style={{textAlign: "right", padding: 5}}>
            {/*<Button onClick={handleSave} disabled={!updated}><SaveOutlined /></Button>*/}
            {/*<Button onClick={onClose}><CloseOutlined /> schließen</Button>*/}
            <Button onClick={() => handleNew(blisterTag)}><PlusCircleOutlined /> Neuen Dosierabschnitt hinzufügen</Button>
        </div>

        {dosierschema && dosierschema
            .map((abschnitt, dosierschema_index) => {
                return <DosierAbschnittEdit
                    key={abschnitt.id}
                    value={abschnitt}
                    onChange={e => {
                        handleChange(e);
                    }}
                    onDelete={() => handleDelete(dosierschema_index)}

                    ausklappen={abschnitt.id === medikationsplanContext.oeffneDosierabschnitt || medikationsplanContext.dosierabschnittGeoeffnet?.id === abschnitt.id}
                    setAusgeklappt={setAusgeklappt}

                    blisterTag={blisterTag}
                    handleSmartEdit={handleSmartEdit}

                    infomarker={medikationsplanContext.infoMarkerMap[planzeile.id]}
                    planzeile={planzeile}
                    patientId={patientId}
                    start={abschnitt.start}
                    handeSave={handleSave}

                    faktorMenge={faktorMenge}
                    arzneimittel={arzneimittel}

                    changeStart={async val => {
                        if (dosierschema_index < dosierschema.length - 1) {
                            const dosierabschnittDanach = {...dosierschema[dosierschema_index + 1]};
                            dosierabschnittDanach.ende = moment(val).subtract(1, 'day').valueOf();
                            handleChange(dosierabschnittDanach);
                        }
                    }}

                    changeEnde={async val => {
                        if (dosierschema_index > 0) {
                            const dosierabschnittDanach = {...dosierschema[dosierschema_index - 1]};

                            if (!val) {
                                notification.error({message: "Es existiert mindestens ein Dosierabschnitt, der später beginnt. Für diesen Vorgang müssen zuvor alle späteren Dosierabschnitte entfernt werden."});
                                return false;
                            } else {
                                dosierabschnittDanach.start = moment(val).add(1, 'day').valueOf();
                                handleChange(dosierabschnittDanach);
                            }
                        }

                        return true;
                    }}

                    setUpdated={setUpdated}

                    datumMin={dosierschema_index < dosierschema.length-1 ? moment(dosierschema[dosierschema_index + 1].start).add(1, 'day') : null}
                    datumMax={dosierschema_index > 0 ? dosierschema[dosierschema_index - 1].ende ? moment(dosierschema[dosierschema_index - 1].ende).subtract(1, 'day') : null : null}
                />
            })}
    </div >
};





const DosierAbschnittEdit = ({ value, onChange, start, infomarker, planzeile, arzneimittel, patientId, handeSave, onDelete, faktorMenge, ausklappen = false, setAusgeklappt, blisterTag = null, handleSmartEdit = null, changeStart = null, changeEnde = null, setUpdated, datumMin, datumMax }) => {
    const auth = useAuth();

    const dokumenteContext = useContext(DokumenteContext);
    const medikationsplanContext = useContext(MedikationsplanContext);

    const pagination = usePagination({position: "none"});

    const {
        current: abschnitt,
        status,
        handleChange,
    } = useDataNode({
        value,
        onChange,
    });


    const [maxTag, setMaxTag] = useState(0);
    const [sichtbareInfoMarker, setSichtbareInfoMarker] = useState([]);
    const [abgabemuster, setAbgabemuster] = useState([]);
    const [genutzteTage, setGenutzteTage] = useState([]);
    const [musterTyp, setMusterTyp] = useState(null);

    const [imContextMenu, setImContextMenu] = useState(null);
    const imContextMenuRef = useRef();

    const dateString = getDosierabschnittDateRangeString(abschnitt);

    useEffect(() => () => medikationsplanContext.setDosierabschnittGeoeffnet(null), [])

    useEffect(() => {
        if (infomarker) {
            const sichtbareInfoMarkerNeu = infomarker.filter(im => !!im.dosierabschnittSet?.find(d => d.id === abschnitt.id));

            setSichtbareInfoMarker(sichtbareInfoMarkerNeu);
        }
    }, [infomarker])

    useOnClickOutside(() => {
        setImContextMenu(null);
    }, [imContextMenuRef])

    useEffect(() => {
        if (abschnitt && abschnitt.abgabemuster) {
            const musterTypNeu = getMusterTyp(abschnitt);
            const abgabemusterNeu = abschnitt.abgabemuster.sort((a, b) => a.tag-b.tag);

            // prüfe ersten Tag und korrigiere .tag bei Bedarf auf 1, falls keine Wochenansicht
            if (!['woche', 'monat'].includes(musterTypNeu)) {
                const ersterTag = abgabemusterNeu[0];
                if (ersterTag && ersterTag.tag > 1) {
                    ersterTag.tag = 1;
                }

                // ermittle maxTag
                const maxTagNeu = abgabemusterNeu.reduce((maxTag, abgabeTag) => Math.max(maxTag, abgabeTag.tag), 0);
                setMaxTag(maxTagNeu);
            }

            const genutzteTageNeu = abgabemusterNeu.map(schema => schema.tag);

            setMusterTyp(musterTypNeu);
            setAbgabemuster(abgabemusterNeu);
            setGenutzteTage(genutzteTageNeu);
        }
    }, [abschnitt])

    const handleChangeAbgabemuster = (abgabemuster) => {
        const { maxTag, faecherDarstellung: abgabemusterNeu } = parseAbgabemuster(abgabemuster, 0, musterTyp);

        setMaxTag(maxTag);
        handleChange(
            abgabemusterNeu,
            "abgabemuster"
        )
    };

    const handleDatumVon = async (val, label) => {
        if (changeStart) changeStart(val);
        handleChange(val, label);

        if (!val) {
            handleDatumBis(null, "ende");
        } else if (abschnitt.endeTyp === 1 && abschnitt.laengeTage) {
            handleDatumBis(moment(val).add(abschnitt.laengeTage, 'days').valueOf(), "ende");
        }
    }

    const handleDatumBis = async (val, label) => {
        if (!changeEnde || await changeEnde(val)) {
            handleChange(val, label);
        }
    }

    const handleChangeStartTyp = async val => {
        handleChange(val, 'startTyp');

        if (val === 0) {
            handleDatumVon(abschnitt.start, "start");
        } else {
            handleDatumVon(null, "start");

            const bestandList = await loadBestandList(auth, medikationsplanContext);
            if (bestandList) {
                const filtered = bestandList.filter(b => b.planzeile && b.planzeile.id === planzeile.id);
                if (filtered.length) {
                    notification.warn({message: "Zu dieser Planzeile gibt es bereits Bestände. Diese Funktion wird ab der nächsten Bestandsaufnahme aktiviert."});
                }
            }
        }
    }

    const removeInfomarker = async im => {
        const response = await callApiAsync({auth, url: infoMarkerApi.delete(im.id), method: 'DELETE'});

        medikationsplanContext.setInfoMarkerMap(prev => ({...prev, [planzeile.id]: prev[planzeile.id].filter(v => v.id !== im.id)}));
        notification.info({message: "Die Verknüpfung wurde erfolgreich gelöscht"});
    }

    const handleContextMenu = (event, infoMarker) => {
        const target = event.target;
        const classNames = target.className.split(" ");
        if (classNames.includes('infomarker-verknuepfung-dosierabschnitt')) {
            event.preventDefault();

            // Context-Menü anzeigen
            setImContextMenu({event: {...event}, infoMarker});
        }
    };


    if (!status.ready) return <></>;

    return (
        <>
            {/* Kontextmenü für InfoMarker */}
            {imContextMenu &&
            <div style={{zIndex: 200, position: "fixed", top: imContextMenu.event.pageY, left: imContextMenu.event.pageX, border: "1px solid black"}} ref={imContextMenuRef}>
                <Menu mode="inline">
                    <Menu.Item key="1" onClick={e => removeInfomarker(imContextMenu.infoMarker)}>Verknüpfung löschen</Menu.Item>

                    {
                        imContextMenu.infoMarker.dosierabschnittSet?.length > 1 &&
                        imContextMenu.infoMarker.dosierabschnittSet.map(d => <Menu.Item key={"d" + d.id} onClick={e => {
                            window.open(
                                `/medikationsplan/${imContextMenu.infoMarker.planzeile.medikationsplan.id}/${encodeURIComponent(imContextMenu.infoMarker.ansicht ? imContextMenu.infoMarker.ansicht : ANSICHT_STANDARD)}/${imContextMenu.infoMarker.planzeile.id}?dosierabschnitt=${d.id}`,
                                "parent-default")
                        }}>Abschnitt {getDosierabschnittDateRangeString(d)}</Menu.Item>)
                    }
                </Menu>
            </div>
            }

            <Collapse
                activeKey={ausklappen ? value.id || "0" : null}
                onChange={activeKeyList => {
                    if (setAusgeklappt) {
                        setAusgeklappt(activeKeyList.length ? activeKeyList[0] : null);
                    }
                }}
            >

                {/* <p style={{ fontSize: "8pt" }}>abschnitt# {abschnitt.id}</p> */}


                <Panel
                    style={{ backgroundColor: "#DCF0E7" }}
                    header={<Row>
                        <Col>
                            <h6>
                                {dateString}
                                {abschnitt.bemerkung && <span style={{ fontWeight: "normal" }}> ({abschnitt.bemerkung})</span>}
                            </h6>
                        </Col>
                        <Col style={{ textAlign: "right" }} span={12}>

                            {abschnitt.freigabe === false &&
                                <>
                                    {auth.developer && <span style={{marginRight: 10}}>[{abschnitt.id}]</span>}
                                    <Button onClick={onDelete} type="danger">Dosierabschnitt löschen</Button>
                                    <Button onClick={e => handleChange(true, "freigabe")}>Dosierabschnitt freigeben</Button>
                                </>
                            }

                        </Col>
                    </Row>}
                    key={value.id || "0"}
                >


                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <h5>Dokumente für diesen Dosierabschnitt:</h5>
                            <Table
                                rowKey={"id"}
                                columns={getInfomarkerColumns(medikationsplanContext.dokumenteGueltig)}
                                dataSource={sichtbareInfoMarker}
                                onRow={row => ({
                                    style: {cursor: "pointer"},

                                    onClick: () => vorschau(row.dokument, row),
                                    onContextMenu: (e) => handleContextMenu(e, row),
                                })}
                                pagination={pagination} />

                            {dokumenteContext.infoMarkerNeu && dokumenteContext.infoMarkerNeu.map((im, index) => {
                                return <InfomarkerButton
                                    name={`Marker #${index+1}`}
                                    index={index}

                                    infoMarker={im}
                                    planzeileId={planzeile.id}
                                    dosierabschnittId={abschnitt.id}
                                />
                            })}
                        </Col>
                        <Col span={16}>

                            {/*Wenn die Planzeile bereits freigegeben ist*/}
                            {abschnitt.freigabe && <div>
                                <h6>Arzneimittel: {getArzneimittelLangname(arzneimittel || planzeile.arzneimittel)}</h6>
                                <div style={{display: "flex", gap: 15}}>
                                    <div><Switch disabled={true} checked={abschnitt.imBlister} /> verblistert</div>
                                    <div><Switch disabled={true} checked={abschnitt.blisterPause} /> pausiert</div>
                                </div>
                                <Abgabemuster
                                    abgabemuster={abgabemuster}
                                    start={start}
                                    wiederholungsTag={abschnitt.wiederholungsTag}
                                    planzeile={planzeile}
                                    arzneimittel={arzneimittel}
                                    faktorMenge={faktorMenge}
                                />

                                {abschnitt.blisterPause && <h4>Blister-Pause</h4>}
                            </div>}


                            {/*Wenn die Planzeile noch nicht freigegeben ist*/}
                            {!abschnitt.freigabe &&
                                <div>
                                    <h6>Arzneimittel: {getArzneimittelLangname(arzneimittel || planzeile.arzneimittel)}</h6>
                                    <Row>

                                        <Col span={12}>
                                            <Row>
                                                <Col span={3}>
                                                    Beginn:
                                                </Col>
                                                <Col span={21}>
                                                    <span style={{marginRight: 10}}>
                                                        <Select
                                                            defaultValue={0}
                                                            value={abschnitt.startTyp || 0}
                                                            dropdownMatchSelectWidth={false}

                                                            onChange={handleChangeStartTyp}
                                                        >
                                                            <Select.Option value={0}>Datum</Select.Option>
                                                            <Select.Option value={1}>Ab Bestandseingang</Select.Option>
                                                            <Select.Option value={2}>Ab möglicher Verblisterung</Select.Option>
                                                        </Select>
                                                        {!abschnitt.startTyp && <InputDatum
                                                            label="start"
                                                            markWeekDay={moment(blisterTag).day()}
                                                            value={abschnitt.start}
                                                            onChange={handleDatumVon}
                                                            allowClear={false}
                                                            minDate={datumMin}
                                                            maxDate={abschnitt?.ende ? abschnitt.ende + 1 : null}
                                                        />}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={3}>
                                                    Ende:
                                                </Col>
                                                <Col span={21}>
                                                    <span>
                                                        <Select
                                                            defaultValue={0}
                                                            value={abschnitt.endeTyp || 0}
                                                            dropdownMatchSelectWidth={false}

                                                            onChange={val => {
                                                                handleChange(val, 'endeTyp');
                                                            }}
                                                        >
                                                            <Select.Option value={0}>Datum</Select.Option>
                                                            <Select.Option value={1}>Für x Tage</Select.Option>
                                                            <Select.Option value={2}>Bis Anspruchsende</Select.Option>
                                                        </Select>
                                                        {!abschnitt.endeTyp && <InputDatum
                                                            label="ende"
                                                            value={abschnitt.ende}
                                                            onChange={handleDatumBis}

                                                            minDate={abschnitt.start}
                                                            maxDate={datumMax}
                                                            selectsEnd={true}
                                                            startDate={moment(abschnitt.start)}
                                                            markWeekDay={moment(abschnitt.start).day()}
                                                        />}
                                                        {abschnitt.endeTyp === 1 && <Input
                                                            type={"number"}
                                                            placeholder={"#Tage"}
                                                            style={{width: 100}}
                                                            allowClear={true}

                                                            min={1}
                                                            value={abschnitt.laengeTage}
                                                            onChange={e => {
                                                                const laengeTage = e.target.value ? parseInt(e.target.value) : null;
                                                                handleChange(laengeTage, "laengeTage");

                                                                if (!laengeTage) {
                                                                    handleDatumBis(null, "ende");
                                                                } else if (!abschnitt.startTyp && abschnitt.start) {
                                                                    handleDatumBis(moment(abschnitt.start).add(laengeTage, 'days').valueOf(), "ende");
                                                                }
                                                            }}
                                                        />}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={2} />
                                        <Col span={10} style={{ textAlign: "right" }}>
                                            <InputWiederholungsTag
                                                label="wiederholungsTag"
                                                value={abschnitt.wiederholungsTag}
                                                onChange={(val, label) => {
                                                    handleChange(false, "freiesMuster");
                                                    handleChange(val, label);
                                                }}
                                                maxTag={maxTag}
                                                freiesMuster={abschnitt.freiesMuster}
                                            />
                                        </Col>
                                    </Row>
                                    <div style={{display: "flex", gap: 15}}>
                                        <div><Switch onChange={val => handleChange(val, "imBlister")} checked={abschnitt.imBlister} /> verblistert</div>
                                        <div><Switch onChange={val => handleChange(val, "blisterPause")} checked={abschnitt.blisterPause} /> pausiert</div>
                                    </div>

                                    <AbgabemusterEdit
                                        value={abgabemuster}
                                        onChange={handleChangeAbgabemuster}
                                        maxTag={maxTag}
                                        setMaxTag={setMaxTag}
                                        start={start}
                                        wiederholungsTag={abschnitt.wiederholungsTag}

                                        faktorMenge={faktorMenge}
                                        arzneimittel={arzneimittel}
                                        planzeile={planzeile}
                                        musterTyp={musterTyp}

                                        genutzteTage={genutzteTage}
                                        setWiederholungstag={wiederholungstag => handleChange(wiederholungstag, "wiederholungsTag")}

                                        startTag={abschnitt.startTag}
                                        setStartTag={startTag => handleChange(startTag, "startTag")}
                                    />
                                </div>
                            }

                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <h5>Kommentar zu diesem Dosierabschnitt:</h5>
                            <InputTextfeld
                                width={"100%"}
                                value={abschnitt.kommentar}
                                label="kommentar"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col span={8}>
                            <h5>Einnahmehinweis:</h5>
                            <InputTextfeld
                                width={"100%"}
                                value={abschnitt.einnahmeFreitext}
                                label="einnahmeFreitext"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col span={8}>
                            {abschnitt.freigabe && <div>

                                {abschnitt.blisterPause &&
                                <SmartEditButton
                                    modus='PAUSE_BEENDEN'
                                    label="Pause beenden"
                                    icon={<PlayCircleOutlined />}
                                    abschnitt={abschnitt}
                                    handleSmartEdit={handleSmartEdit}
                                    handleCurrentChange={handleChange}
                                    blisterTag={blisterTag}
                                    showLabelOnButton
                                    datumMax={datumMax}
                                />
                                }

                                {!abschnitt.blisterPause &&
                                <>

                                    <SmartEditButton
                                        modus='DOSIERUNG_AENDERN'
                                        label="Dosierung ändern"
                                        icon={<EditOutlined />}
                                        abschnitt={abschnitt}
                                        handleSmartEdit={handleSmartEdit}
                                        handleCurrentChange={handleChange}
                                        blisterTag={blisterTag}
                                        showLabelOnButton
                                        datumMax={datumMax}
                                    />

                                    <SmartEditButton
                                        modus='PLAN_PAUSIEREN'
                                        label="Plan pausieren"
                                        icon={<PauseOutlined />}
                                        abschnitt={abschnitt}
                                        handleSmartEdit={handleSmartEdit}
                                        handleCurrentChange={handleChange}
                                        blisterTag={blisterTag}
                                        showLabelOnButton
                                        datumMax={datumMax}
                                    />
                                </>
                                }

                                <SmartEditButton
                                    modus='ABSETZEN'
                                    label="Absetzen"
                                    icon={<StopOutlined />}
                                    abschnitt={abschnitt}
                                    handleCurrentChange={handleChange}
                                    handleSmartEdit={handleSmartEdit}
                                    blisterTag={blisterTag}
                                    showLabelOnButton
                                    datumMax={datumMax}
                                />


                                <Button
                                    onClick={() => handleChange(false, "freigabe")}
                                ><LockOutlined /> Freigabe aufheben</Button>





                            </div>}
                        </Col>
                    </Row>

                </Panel>
            </Collapse >
        </>)

};

const SmartEditButton = ({ label = "", showLabelOnButton = false, icon = <>Button</>, abschnitt = null, modus = null, handleSmartEdit = null, handleCurrentChange = null, blisterTag = null, resetFreigabeOnChange=false, datumMax }) => {

    const [datum, setDatum] = useState(new Date());
    if (!abschnitt || !modus || !handleSmartEdit)
        return <></>;

    const handleSubmit = () => {
        handleSmartEdit(modus, abschnitt, moment(datum).startOf('day').valueOf())
    };

    return (<Popover
        pointerEvents={"initial"}
        content={<div>
            <b>Änderungsdatum:</b>
            <br />
            <InputDatum
                label="start"
                value={datum}
                onChange={(e, l) => setDatum(e)}
                markDay={moment(abschnitt.start)}
                markWeekDay={moment(blisterTag).day()}
                minDate={moment(abschnitt.start).add(1, 'day')}
                maxDate={datumMax}
            />
            <br />

            <Button
                onClick={handleSubmit}
                style={{ backgroundColor: "#97D3B8", width: "100%" }}
            >{label}</Button>
        </div>}
        title={label}
        trigger="click"
    >
        <Button>{icon}{showLabelOnButton && label}</Button>
    </Popover>)
};



export const AbgabemusterEdit = ({ value, onChange, maxTag, setMaxTag, wiederholungsTag, start, faktorMenge, arzneimittel, planzeile, musterTyp, genutzteTage, setWiederholungstag, startTag, setStartTag }) => {

    const [weekdays, setWeekdays] = useState([]);

    const {
        current: abgabemuster,
        status,
        handleChange,
        handleNew,
    } = useDataNode({
        value,
        onChange,
    });


    useEffect(() => {
        setWeekdays(WEEKDAYS_ALL)
    }, []);

    useEffect(() => {
        let wd = WEEKDAYS_ALL;
        let maxTagNeu = 0;

        for (let a of abgabemuster) {
            wd = wd.filter(e => e.tag !== a.tag);
            if (a.tag && a.tag > maxTagNeu) {
                maxTagNeu = a.tag;
            }
        }

        setWeekdays(wd)
        if (maxTagNeu !== maxTag) setMaxTag(maxTagNeu);
    }, [abgabemuster]);

    const handleNeuerTag = (tag) => {
        const newElement = { zeitgenau: false, sollZeitgenau: false, zeiten: [], tag: tag };
        if (musterTyp === "monat") newElement.tagBis = tag;
        handleNew({newElement, changeNewId: true});
    };




    if (!status.ready) return <></>;

    return (<div>

        {(musterTyp === "tag" && maxTag === 0) &&
            <Button onClick={() => handleNeuerTag(1)} >leeren Abgabetag erstellen</Button>
        }

        {musterTyp === "frei" && <span style={{marginRight: 10}}>
            Wiederholungstag: <Input style={{width: 50, backgroundColor: wiederholungsTag < maxTag ? "#ffaaaa" : "initial"}} value={wiederholungsTag+1} onChange={e => setWiederholungstag(e.target.value-1)} />
            Starttag: <Input style={{width: 50, backgroundColor: startTag < maxTag ? "#ffaaaa" : "initial"}} value={startTag ? startTag : 1} onChange={e => setStartTag(e.target.value-1)} />
        </span>}

        {(musterTyp === "frei" || (maxTag < wiederholungsTag && wiederholungsTag > 7)) &&
            <Button onClick={() => handleNeuerTag(maxTag + 1)} >neuer Tag #{maxTag + 1}</Button>
        }

        {
            musterTyp === "woche" &&
            <Select
                style={{ width: 200 }}
                value={"Wochentag hinzufügen"}
                onChange={(e) => {
                    if (e > -1) {
                        handleNeuerTag(e);
                    }
                }}
            >
                <Option key={-1} disabled={true}>Wochentag hinzufügen</Option>
                {weekdays.map(e => <Option key={e.tag} value={e.tag}>{e.bezeichnung}</Option>)}
            </Select>
        }


        {
            abgabemuster.map((tag, i) => {
                return <AbgabetagEdit
                    key={tag.id}
                    value={tag}
                    start={start}
                    onDelete={() => {
                        handleChange(null, tag.id);
                    }}
                    onChange={e => {
                        handleChange(e);
                    }}
                    wiederholungsTag={wiederholungsTag}

                    faktorMenge={faktorMenge}
                    arzneimittel={arzneimittel}
                    planzeile={planzeile}
                    musterTyp={musterTyp}
                    genutzteTage={genutzteTage}
                />

            })
        }

    </div >)


};



const AbgabetagEdit = ({ value, onChange, onDelete, wiederholungsTag, start, faktorMenge, arzneimittel, planzeile, musterTyp, genutzteTage }) => {

    const {
        current: abgabetag,
        status,
        handleChange,
        handleChangeMultiple,
    } = useDataNode({
        value,
        onChange,
    });

    const [tag, setTag] = useState(null);
    const [istTeilbar, setIstTeilbar] = useState(true);
    const [teilungExplizitErlaubt, setTeilungExplizitErlaubt] = useState(false);

    useEffect(() => {
        if (abgabetag) {

        }
    }, [abgabetag])

    if (!status.ready) return <></>;

    return (<div>
        <hr />
        <Row>
            <Col span={4}>
                {(musterTyp === "frei" || (wiederholungsTag !== 1 && wiederholungsTag !== 7)) &&
                    <div>
                        Tag <InputNumber value={tag || abgabetag.tag} onChange={tagNeu => {
                            const tagAlt = tag || abgabetag.tag;
                            const istKleiner = tagAlt > tagNeu;
                            while (genutzteTage.includes(tagNeu)) {
                                if (istKleiner) tagNeu--;
                                else tagNeu++;
                            }
                            setTag(tagNeu);
                        }} onPressEnter={e => e.target.blur()} onBlur={() => {
                            if (tag) {
                                handleChange(tag, "tag");
                            }
                            setTag(null);
                        }} style={{width: 50}} />
                    </div>
                }
                {musterTyp === "woche" &&
                    <div>
                        {WEEKDAYS_ALL[abgabetag.tag - 1].bezeichnung}
                    </div>
                }

                <div>
                    <InputBoolean
                        label="sollZeitgenau"

                        wahr="Zeitgenau"
                        falsch="nach Fächern"

                        value={abgabetag.sollZeitgenau}
                        onChange={(val, label) => {
                            handleChange(val, label);
                        }}
                    />
                </div>

                {/* <p style={{ fontSize: "8pt" }}>Abgabetag# {abgabetag.id}</p> */}

                {/* <pre>{JSON.stringify(abgabetag, null, 2)}</pre> */}

            </Col>

            <Col span={18}>
                {abgabetag.sollZeitgenau &&
                    <EinnahmeZeitenEdit
                        value={abgabetag.zeiten}
                        onChange={e => {
                            handleChange(e, "zeiten");
                        }}

                        faktorMenge={faktorMenge}
                        arzneimittel={arzneimittel}
                        planzeile={planzeile}
                    />
                }

                {!abgabetag.sollZeitgenau &&
                    <EinnahmeZeitenFaecherEdit
                        value={abgabetag.zeiten}
                        onChange={e => {
                            handleChange(e, 'zeiten');
                        }}

                        faktorMenge={faktorMenge}
                        arzneimittel={arzneimittel}
                        planzeile={planzeile}
                    />
                }

            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
                <Button onClick={onDelete} ><DeleteOutlined /></Button>
                {/* {(wiederholungsTag !== 1) &&
                    <Button onClick={() => handleChange(null)} ><DangerOutlined /></Button>
                } */}
            </Col>

        </Row>

    </div >)

};





const EinnahmeZeitenFaecherEdit = ({ value, onChange, faktorMenge, planzeile, arzneimittel }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [loading, setLoading] = useState(true);

    const {
        current: zeiten,
        status,
        handleChangeMultiple
    } = useDataNode({
        isCollection: true,
        value,
        onChange,
    });

    const [zeitenSortiert, setZeitenSortiert] = useState({});
    const neueZeitId = useRef(-1);

    useEffect(() => {
        const patient = medikationsplanContext.medikationsplan.patient;
        const blisterTypes = medikationsplanContext.blisterTypes;

        if (zeiten && patient && blisterTypes) {
            const blisterType = blisterTypes.find(b => b.id === patient.blisterType_id || 1);
            const groupTypes = blisterType.blisterGroupPattern?.type === 'BY_TIME' ? blisterType.blisterGroupPattern.groupTypes.sort(getSorter('id', 'start')) : [];
            zeiten.sort(getSorter('string', 'zeit'));

            const zeitenSortiertNeu = zeiten.reduce((result, zeit, index) => {
                    if (!zeit) return result;

                    const momentZeit = moment(`1970-01-01 ${zeit.zeit}`);
                    let index_groupType = -1;
                    for (let i=0; i<groupTypes.length; i++) {
                        const g = groupTypes[i];
                        const momentStart = moment(g.start);
                        const momentEnde = moment(g.end);

                        if (momentEnde.format('HH:mm:ss') === '00:00:00') momentEnde.add(1, 'day');
                        if (momentStart.isSameOrBefore(momentZeit) && momentEnde.isAfter(momentZeit)) {
                            index_groupType = i;
                            break;
                        }
                    }

                    const entry = result[index_groupType] || { zeiten: [] };
                    entry.zeiten.push(zeit);

                    result[index_groupType] = entry;
                    return result;
                },
                EINNAHMEZEITEN_FUENFFACH.map((e, index) => {
                    return {
                        zeiten: [],
                        index: e._id
                    }
                })
            );

            console.log("setZeitenSortiert: ", zeitenSortiertNeu, zeiten);
            setZeitenSortiert(zeitenSortiertNeu);
            setLoading(false);
        }
    }, [zeiten, medikationsplanContext.blisterTypes, medikationsplanContext.medikationsplan])

    const handleChangeZeiten = (zeiten, index) => {
        const changeMultiple = zeiten.filter(z => z.menge > 0 || z.id).map(z => {
            if (z.id === neueZeitId.current) neueZeitId.current--;
            return [z.menge > 0 ? z : null, z.id];
        });

        if (changeMultiple.length) {
            handleChangeMultiple(changeMultiple);
        }
    }


    if (!status.ready || !zeitenSortiert) return <></>;

    return (<Row>
        <EinnahmeZeitenFachEdit span={4} loading={loading} value={zeitenSortiert[0]?.zeiten || []} index={0} onChange={zeiten => handleChangeZeiten(zeiten, 0)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} neueZeitId={neueZeitId.current} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} loading={loading} value={zeitenSortiert[1]?.zeiten || []} index={1} onChange={zeiten => handleChangeZeiten(zeiten, 1)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} neueZeitId={neueZeitId.current} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} loading={loading} value={zeitenSortiert[2]?.zeiten || []} index={2} onChange={zeiten => handleChangeZeiten(zeiten, 2)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} neueZeitId={neueZeitId.current} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} loading={loading} value={zeitenSortiert[3]?.zeiten || []} index={3} onChange={zeiten => handleChangeZeiten(zeiten, 3)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} neueZeitId={neueZeitId.current} />
        <Col span={1}/>
        <EinnahmeZeitenFachEdit span={4} loading={loading} value={zeitenSortiert[4]?.zeiten || []} index={4} onChange={zeiten => handleChangeZeiten(zeiten, 4)} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} neueZeitId={neueZeitId.current} />

    </Row>)

};


const EinnahmeZeitenFachEdit = ({ value, loading=false, index, onChange, faktorMenge, planzeile, arzneimittel, neueZeitId }) => {

    const auth = useAuth();
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const DEFAULT_ZEIT = useMemo(() => ({
        id: neueZeitId,
        menge: "0",
        zeit: EINNAHMEZEITEN_FUENFFACH[index].zeitfenster.default,
        fachNummer: index
    }), [neueZeitId]);

    const [istTeilbar, setIstTeilbar] = useState(true);
    const [defaultZeit, setDefaultZeit] = useState(DEFAULT_ZEIT);
    const [mengeSumme, setMengeSumme] = useState(0);
    const [blisterTypeGroup, setBlisterTypeGroup] = useState(null);
    const [teilungExplizitErlaubt, setTeilungExplizitErlaubt] = useState(true);
    const [zeitenInfoStrings, setZeitenInfoStrings] = useState([]);

    const {
        current: zeiten,
        status,
        handleChange,
        handleChangeMultiple,
    } = useDataNode({
        isCollection: true,
        value,
        onChange,
    });

    useEffect(() => {
        let teilungExpliyitErlaubtNeu = true;
        const patient = medikationsplanContext.medikationsplan.patient
        const blisterType = medikationsplanContext.blisterTypes?.find(b => b.id === patient.blisterType_id || 1);
        if (patient && blisterType) {
            setBlisterTypeGroup(blisterType.blisterGroupPattern?.groupTypes?.[index]);

            if (zeiten?.length) {
                const zeitenInfoStringsNeu = [];
                const momentZeitDefault = moment(EINNAHMEZEITEN_FUENFFACH[index].zeitfenster.default, 'HH:mm:ss');

                let zeitDefault;
                let summe = 0;
                for (let zeit of zeiten) {
                    const mengeZeit = parseFloat(zeit.menge) * (zeit.faktor && !isNaN(zeit.faktor) ? zeit.faktor : 1);
                    const momentZeit = moment(zeit.zeit, 'HH:mm:ss');

                    if (momentZeit.isSame(momentZeitDefault)) {
                        zeitDefault = zeit;
                    }

                    zeitenInfoStringsNeu.push(`${momentZeit.format('HH:mm')} Uhr: ${mengeToString(mengeZeit)}`);
                    if (!zeit.teilungExplizitErlaubt && teilungExpliyitErlaubtNeu) teilungExpliyitErlaubtNeu = false;

                    summe += mengeZeit;
                }

                if (!zeitDefault) {
                    zeitDefault = zeiten[0];
                }

                setMengeSumme(summe);
                setDefaultZeit(zeitDefault);
                setIstTeilbar(checkTeilbarkeitAbgabezeit({abgabeZeit: zeitDefault, menge: summe, planzeile, api}));
                setZeitenInfoStrings(zeitenInfoStringsNeu);
            } else {
                setDefaultZeit(DEFAULT_ZEIT);
            }
        }

        setTeilungExplizitErlaubt(teilungExpliyitErlaubtNeu);
    }, [zeiten, medikationsplanContext.blisterTypes, medikationsplanContext.medikationsplan.patient])

    const handleChangeDefault = (menge, label) => {
        if (menge > 0) {
            let delta = menge - mengeSumme;
            const defaultMenge = parseFloat(defaultZeit.menge);

            const changeMultiple = [];
            if (defaultMenge + delta > 0) {
                changeMultiple.push({...defaultZeit, menge: (defaultMenge + delta)+''});
            } else {
                changeMultiple.push({...defaultZeit, menge: '0'});
                const idSeen = [defaultZeit.id];

                delta = defaultMenge + delta;
                while (delta < 0) {
                    const zeit = zeiten.find(z => !idSeen.includes(z.id));
                    idSeen.push(zeit.id);

                    const mengeZeit = parseFloat(zeit.menge);
                    changeMultiple.push({...zeit, menge: Math.max(0, mengeZeit + delta) +''});
                    delta = Math.min(0, mengeZeit + delta);
                }
            }
            handleChangeMultiple(changeMultiple);
        } else {
            handleChangeMultiple(zeiten.map(z => ({...z, menge: '0'})));
        }
    }

    if (!status.ready) return <></>;

    const faktor = defaultZeit.faktor ? 1/defaultZeit.faktor : 1/faktorMenge;
    return <Col span={3} style={{ textAlign: "center" }}>
        <InputMenge
            loading={loading}
            label="menge"
            value={mengeSumme}
            onChange={handleChangeDefault}

            faktorMenge={faktor}
            faktorLabel={!!faktor && (() => getArzneimittelLangname(planzeile.arzneimittel))}
            styleClass={[...(defaultZeit.faktor ? ["input-menge-alternative"] : []), ...(!istTeilbar ? ["input-menge-nicht-teilbar"] : [])]}

            einheit={(planzeile?.arzneimittel)?.einheit}
            suffix={!istTeilbar && teilungExplizitErlaubt ? "!" : null}
        />
        <div>
            {zeitenInfoStrings.length > 1 && <Popover placement={'top'} trigger={'hover'} pointerEvents={'none'} content={<div>
                <h6>Einnahmezeiten werden zusammengefasst</h6>
                {zeitenInfoStrings.map((z, i) => <div key={i}>{z}</div>)}
            </div>}>
                <InfoCircleOutlined className={'icon'} style={{marginRight: 5}} />
            </Popover>}

            {!istTeilbar ? <EinnahmezeitTeilungExplizitErlaubtIcon
                value={teilungExplizitErlaubt}
                onChange={istTeilungExplizitErlaubt => {
                    const changeMultiple = zeiten.map(z => ({...z, teilungExplizitErlaubt: istTeilungExplizitErlaubt}));
                    handleChangeMultiple(changeMultiple);
                }}
                text={(blisterTypeGroup?.label || defaultZeit.zeit.substring(0, 5)) + "!"}
            /> : (blisterTypeGroup?.label || defaultZeit.zeit.substring(0, 5))}

            <ArzneimittelAlternativeIcon
                einnahmezeit={defaultZeit}
                planzeile={planzeile}
                showRemove={true}

                pickArzneimittel={async arzneimittel => {
                    if (planzeile && planzeile.arzneimittel && arzneimittel) {
                        const response = await callApiAsync({auth, url: arzneimittelApi.getSubstitutFaktor(planzeile.arzneimittel.m2, arzneimittel.m2), onError: [{message: "medicine item is no substitute.", callback: () => {
                            notification.error({message: "Das ausgewählte Arzneimittel ist kein gültiges Substitut."});
                        }}]});
                        const faktor = 1 / response.data.OBJECT[Object.keys(response.data.OBJECT)[0]];
                        handleChangeMultiple([[arzneimittel, "arzneimittel"], [faktor, "faktor"]]);
                    } else {
                        handleChange(arzneimittel, "arzneimittel");
                        handleChange(null, "faktor");
                    }
                }}
            />
        </div>
    </Col>

};



const EinnahmeZeitenEdit = ({ value, onChange, faktorMenge, arzneimittel, planzeile }) => {

    const {
        current: zeiten,
        status,
        handleChange,
        handleNew,
    } = useDataNode({
        value,
        onChange,

        idAttribute: "fachNummer",
        initialNewId: value.reduce((minId, zeit) => Math.min(minId, zeit.fachNummer-1), -1)
    });

    const [zeitenSorted, setZeitenSorted] = useState([]);

    useEffect(() => {
        if (zeitenSorted) {

        }
    }, [zeitenSorted])

    useEffect(() => {
        setZeitenSorted(prev => {
            if (zeiten) {
                if (zeiten.length > 1) {
                    return zeiten.sort((a, b) => a.zeit.localeCompare(b.zeit));
                } else {
                    return zeiten;
                }
            }

            return [];
        });
    }, [zeiten])

    const neueZeit = () => {
        const newElem = handleNew({newElement: { zeit: "00:00", menge: 0 }, changeNewId: true});
    };

    if (!status.ready) return <></>;

    return (<div>
        {zeitenSorted.map((e, i) => <EinnahmeZeitEdit
            key={e.fachNummer}
            value={e}
            onChange={e => {
                handleChange(e);
            }}
            onDelete={() => handleChange(null, e.fachNummer)}

            faktorMenge={faktorMenge}
            arzneimittel={arzneimittel}
            planzeile={planzeile}
        />
        )}

        <Button onClick={neueZeit} >neue Zeit</Button>

    </div>);

};





const EinnahmeZeitEdit = ({ value, onChange, onDelete, faktorMenge, arzneimittel, planzeile }) => {

    const auth = useAuth();
    const api = useApi();

    const [istTeilbar, setIstTeilbar] = useState(true);

    const {
        current: zeit,
        status,
        handleChange,
    } = useDataNode({
        value,
        onChange
    });

    useEffect(() => {
        setIstTeilbar(checkTeilbarkeitAbgabezeit({abgabeZeit: zeit, planzeile, api}));
    }, [zeit])

    if (!status.ready) return <></>;

    const faktor = zeit.faktor ? 1/zeit.faktor : 1/faktorMenge;
    return (<Row>

        <Col span={8}>
            <InputUhrzeit
                label="zeit"
                value={zeit.zeit}
                onChange={(val, label) => {
                    handleChange(val, label);
                }}
            />
        </Col>

        <Col span={8}>
            <InputMenge
                label="menge"
                value={zeit.menge}
                onChange={(val, label) => {
                    handleChange(val, label);
                }}

                faktorMenge={faktor}
                faktorLabel={!!faktor && (() => getArzneimittelLangname(planzeile.arzneimittel))}
                styleClass={[...(zeit.faktor ? ["input-menge-alternative"] : []), ...(!istTeilbar ? ["input-menge-nicht-teilbar"] : [])]}

                einheit={(arzneimittel || planzeile?.arzneimittel)?.einheit}
                suffix={!istTeilbar && zeit.teilungExplizitErlaubt ? "!" : null}
            />
            <div>
                {!istTeilbar && <EinnahmezeitTeilungExplizitErlaubtIcon
                    value={zeit.teilungExplizitErlaubt}
                    onChange={val => handleChange(val, "teilungExplizitErlaubt")}
                    text={"Teilung!"}
                />}
                <ArzneimittelAlternativeIcon
                    einnahmezeit={zeit}
                    planzeile={planzeile}
                    showRemove={true}

                    pickArzneimittel={async arzneimittel => {
                        if (planzeile && planzeile.arzneimittel && arzneimittel) {
                            const response = await callApiAsync({auth, url: arzneimittelApi.getSubstitutFaktor(planzeile.arzneimittel.m2, arzneimittel.m2), onError: [{message: "medicine item is no substitute.", callback: () => {
                                        notification.error({message: "Das ausgewählte Arzneimittel ist kein gültiges Substitut."});
                                    }}]});

                            handleChange(arzneimittel, "arzneimittel");
                            handleChange(1 / response.data.OBJECT[Object.keys(response.data.OBJECT)[0]], "faktor");
                        } else {
                            handleChange(arzneimittel, "arzneimittel");
                            handleChange(null, "faktor");
                        }
                    }}
                />
            </div>

        </Col>

        <Col span={8}>
            <Button onClick={onDelete} >Zeit löschen</Button>

        </Col>


        {/* <pre>{JSON.stringify(zeit, null, 2)}</pre> */}




    </Row>)

};



const vorschau = (dokument, infoMarker) => {
    window.open(`/vorschau/dokument/${dokument.id}${infoMarker.id ? `?im=${infoMarker.id}` : ''}`, `dokumenteViewer-dokument`, 'location=0,menubar=0');
};


export const getInfomarkerColumns = (dokumenteGueltig) => [
    {
        title: 'Dokument',
        key: 'dokument',
        className: "infomarker-verknuepfung-dosierabschnitt",
        render: row => {
            let str = row.dokument.typ ? `${row.dokument.typ}${row.dokument.dokument.titel ? ` [${row.dokument.dokument.titel}]` : ""}` : (row.dokument.dokument.titel || row.dokument.dokument.dateiname);

            if (dokumenteGueltig) {
                if (dokumenteGueltig.aktuell?.includes(row.dokument.id)) {
                    str = str + " [aktuell]";
                } else if (dokumenteGueltig.vergangen?.includes(row.dokument.id)) {
                    str = str + " [vergangen]";
                } else if (dokumenteGueltig.kommend?.includes(row.dokument.id)) {
                    str = str + " [kommend]";
                }
            }

            return str;
        },
    }
];
export const infomarkerColumns = getInfomarkerColumns();

export const handleSmartEditGlobal = (modus = null, abschnitt = null, datum = null, dosierschema, neuerAbschnittId, datum2) => {
    if (!dosierschema) return [];
    dosierschema = dosierschema.sort(getSorter("dosierabschnitt", "start", true));

    const datumMoment = moment(datum).startOf('day');
    datum = datumMoment.valueOf();
    if (datum2) datum2 = moment(datum2).startOf('day').valueOf();

    let start = datum;
    let ende = undefined;

    let setzeEnde = true;
    let erstelleNeuenAbschnitt = true;
    let abschnittLast = null;
    let abschnittDanach = null;

    // Der neue Abschnitt startet nach dem letzten
    const abschnittLastStart = dosierschema[0] ? moment(dosierschema[0].start).startOf('day') : null;
    if (abschnittLastStart && datumMoment.isAfter(abschnittLastStart)) {
        // Setze Ende des letzten Abschnitts auf datum - 1Tag
        abschnittLast = {...dosierschema[0], freigabe: false, ende: datumMoment.subtract(1, 'day').endOf('day').valueOf()};
    }

    // Der neue Abschnitt liegt zwischen zwei Abschnitten
    else {
        // ermittle Index, auf dem der Abschnitt eingefügt werden wird
        let index;
        for (let i = 0; i < dosierschema.length; i++) {
            const startAbschnitt = dosierschema[i].start;
            if (startAbschnitt) {
                let startMoment = moment(startAbschnitt).startOf('day');

                // Der neue Abschnitt startet am selben Tag wie ein bereits bestehender => Fehler
                if (startMoment.isSame(datumMoment)) {
                    abschnittDanach = dosierschema[i+1] || null;

                    if ((i === 0 && dosierschema[i].blisterPause && modus === "PAUSE_BEENDEN")) {
                        modus = "PAUSE_LOESCHEN";
                        break;
                    }
                    if (modus === "PLAN_PAUSIEREN") {
                        abschnitt = {...dosierschema[i]};
                        abschnitt.freigabe = false;

                        if (datum2) {
                            // Es soll ein Ende für die Pause eingetragen werden
                            if (!abschnitt.ende) {
                                // Der Dosierabschnitt hat kein Ende => Dosierabschnitt durch Pause ersetzen
                                abschnitt.ende = datum2;
                                erstelleNeuenAbschnitt = false;
                            } else {
                                const endeMoment = moment(abschnitt.ende);
                                ende = datum2;

                                if (endeMoment.isAfter(datum2)) {
                                    // Das Ende des Dosierabschnitts liegt nach dem Ende der Pause => Pause als neuer Abschnitt, bestehender Abschnitt startet nach Pause
                                    abschnittLast = {...dosierschema[i]};
                                    abschnittLast.start = moment(datum2).add(1, 'day').valueOf();
                                } else if (endeMoment.isBefore(datum2)) {
                                    // Das Ende des Dosierabschnitts liegt vor dem Ende der Pause => bestehender Abschnitt wird Pause, neuer Abschnitt für Rest der Pause
                                    abschnittLast = {...dosierschema[i]};
                                    abschnittLast.blisterPause = true;

                                    start = endeMoment.add(1, 'day').valueOf();
                                } else {
                                    // Das Ende des Dosierabschnitts entspricht genau dem der Pause => Dosierabschnitt durch Pause ersetzen
                                    erstelleNeuenAbschnitt = false;
                                }
                            }
                        }

                        setzeEnde = false;
                        datum2 = null;
                        break;
                    } else if (modus === "ABSETZEN") {
                        // Das Änderungsdatum entspricht genau dem Start des Dosierabschnitts => Dosierabschnitt löschen
                        return [{deleteId: dosierschema[i].id},];
                    } else {
                        // Das Änderungsdatum entspricht genau dem Start des Dosierabschnitts => Dosierabschnitt bearbeiten, statt neuen zu erstellen
                        return [{...dosierschema[i], freigabe: false}];
                    }
                } else if (startMoment.isBefore(datumMoment)) {
                    abschnittDanach = dosierschema[i+1] || null;
                    index = i;
                    break;
                }
            }
        }

        if (setzeEnde && modus !== "PAUSE_LOESCHEN") {
            // Setze Ende des vorherigen Abschnitts auf datum - 1Tag
            const abschnitVorher = {...dosierschema[index]};
            if (abschnitVorher.ende && moment(abschnitVorher.start).isSame(abschnitVorher.ende)) {
                notification.error({
                    duration: 7,
                    message: "Der Dosierabschnitt kann nicht erstellt werden.",
                    description: "Das Eingegebene Datum kann nicht genutzt werden. Der Dosierabschnitt muss mindestens einen Tag betragen und die Abschnitte, zwischen denen der neue eingefügt werden soll, können dafür nicht abgeändert werden."
                });
                return [];
            } else {
                abschnittLast = {
                    ...abschnitVorher,
                    freigabe: false,
                    ende: datumMoment.subtract(1, 'day').endOf('day').valueOf()
                };
            }

            // setze Ende des neuen Abschnitts auf das Ende des vorherigen Abschnitts
            ende = abschnitVorher.ende;
        }
    }

    const neuerAbschnitt = !erstelleNeuenAbschnitt ? abschnitt : abschnitt ? JSON.parse(JSON.stringify(abschnitt)) : null;
    if (erstelleNeuenAbschnitt && neuerAbschnitt) {
        neuerAbschnitt.id = neuerAbschnittId;
        neuerAbschnitt.start = start;
        neuerAbschnitt.ende = ende;
        neuerAbschnitt.freigabe = false;

        for (let abgabetag of neuerAbschnitt.abgabemuster) {
            delete abgabetag.id;
            for (let einnahmezeit of abgabetag.zeiten) {
                delete einnahmezeit.id;
            }
        }
    }

    if (neuerAbschnitt) switch (modus) {
        case 'DOSIERUNG_AENDERN':
            break;

        case 'PLAN_PAUSIEREN':
            neuerAbschnitt["blisterPause"] = true;
            if (datum2 && !neuerAbschnitt["ende"]) neuerAbschnitt["ende"] = datum2;
            break;

        case 'PAUSE_BEENDEN':
            neuerAbschnitt["blisterPause"] = false;
            break;

        case 'PAUSE_LOESCHEN':
            return [
                {deleteId: dosierschema[0].id},
                {...dosierschema[1], freigabe: false, blisterPause: false, ende: undefined}
            ];

        case 'ABSETZEN':
            return [abschnittLast];

        default:
            console.error("unbekannter SmartEdit Modus: " + modus);
            return [];
    }

    return [abschnittLast, neuerAbschnitt, abschnittDanach];
};