import React from 'react'

export const Aufgaben = (props) => {

    return <div>
        <h1>Aufgaben-Modul</h1>

        <h3>Erstellung und Nachverfolgung</h3>
        <ul>
            <li>Vorgänge können bestimmte Aufgaben erzeugen</li>
            <li>Die Zuweisung kann aufgrund von Rollen (z.B. Apotheker, PTA, Administrator) erfolgen, Personen bezogen sein oder auch allgmein</li>
            <li>Aufgaben können auch manuell erzstellt werden</li>
            <li>Aufgaben haben Fälligkeitsdatum</li>
            <li>Aufgaben können sich auf andere Aufgaben, Medikationspläne, Patienten, Ärzte, Pflegeheime, etc. beziehen</li>
        </ul>

        <h3>Beispiele</h3>
        <ul>
            <li>Rezeptanforderung nötig</li>
            <li>Eine Medikationsplan-Änderung muss freigegeben werden</li>
            <li>Neue Dokumente zu einem Patienten sind eingegangen und </li>
        </ul>

    </div>
}
