import React, {useContext, useEffect, useMemo, useState} from 'react'
import moment from 'moment';
import 'moment/locale/de'

import {Row, Col, Divider} from 'antd';
import {Popover} from "../atoms/Popover";

import {
    dosierschemaSort,
    mengeToString,
    parseAbgabemuster,
    getMusterTyp, getDosierabschnittDateRangeString
} from '../../utilities/dosierschemaUtil'
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {ArzneimittelAlternativePopover} from "./ArzneimittelAlternative";
import {getUnitLangname} from "../../config/entities";
import {WEEKDAYS_ALL} from "../../utilities/dateUtil";
import {useApi} from "../../utilities/useApi";
import {checkTeilbarkeit, checkTeilbarkeitAbgabezeit} from "../../utilities/medicineM2Util";
import {EINNAHMEZEITEN_FUENFFACH} from "../../config/misc";
import {getSorter} from "../../utilities/sortUtil";
import {Loading} from "../templates/defaultPages";
import {InfoCircleOutlined} from "@ant-design/icons";

moment.locale('de');


export const Dosierschema = ({ value: dosierschema, abschnittFilter = null, faktorMenge, planzeile, arzneimittel=null, planzeileAlt }) => {

    const [dosierschemaSortiert, setDosierschemaSortiert] = useState([]);
    const [dosierschemaVergangen, setDosierschemaVergangen] = useState([]);
    const [dosierschemaKommend, setDosierschemaKommend] = useState([]);

    const [showKommend, setShowKommend] = useState(false);

    const [dosierabschnittAktuellAlt, setDosierabschnittAktuellAlt] = useState(null);

    const medikationsplanContext = useContext(MedikationsplanContext);

    useEffect(() => {
        if (dosierschema) {
            const anzeigeDatum = moment(medikationsplanContext.anzeigeDatum);
            const anzeigeDatumTimestamp = anzeigeDatum.valueOf();

            const sortiert = [...dosierschema].sort((a, b) => dosierschemaSort(a, b, anzeigeDatumTimestamp));
            const dosierschemaSortiertNeu = [];
            const dosierschemaVergangenNeu = [];
            const dosierschemaKommendNeu = [];

            let wirdVerblistert = false;
            let start, ende;
            const dosierabschnittMapNeu = {};
            sortiert.forEach(abschnitt => {
                dosierabschnittMapNeu[abschnitt.id] = planzeile.id;
                if (abschnitt.imBlister && !abschnitt.blisterPause && abschnitt.abgabemuster.some(a => (a.zeiten || []).some(z => !!z.menge))) {
                    wirdVerblistert = true;
                }

                start = abschnitt.start ? moment(abschnitt.start) : null;
                ende = abschnitt.ende ? moment(abschnitt.ende) : null;

                if (ende?.isBefore(anzeigeDatum)) {
                    dosierschemaVergangenNeu.push(abschnitt);
                } else if (start && anzeigeDatum.isBefore(start)) {
                    dosierschemaKommendNeu.push(abschnitt);
                } else {
                    dosierschemaSortiertNeu.push(abschnitt);
                }
            });

            if (!dosierschemaSortiertNeu.length) {
                // aktuell gibt es keine Daten

                const abschnittDummy = {dummy: true, bemerkung: "Dummy-Abschnitt, weil keine Daten vorhanden sind"};
                if (dosierschemaVergangenNeu.length) {
                    // es gibt vergangene Abschnitte
                    abschnittDummy.start = moment(dosierschemaVergangenNeu[dosierschemaVergangenNeu.length-1].ende).add(1, 'day').valueOf();

                    if (dosierschemaKommendNeu.length) {
                        // es gibt vergangene und zukünftige Abschnitte => Lücke!!
                        abschnittDummy.ende = moment(dosierschemaKommendNeu[0].start).subtract(1, 'day').valueOf();
                        abschnittDummy.text = "Lücke in Dosierung!";
                    } else {
                        // es gibt nur vergangene Abschnitte => Planzeile wurde abgesetzt
                        abschnittDummy.text = "abgesetzt";
                    }
                } else if (dosierschemaKommendNeu.length) {
                    // es gibt nur kommende Abschnitte => Planzeile hat noch nicht begonnen
                    abschnittDummy.ende = moment(dosierschemaKommendNeu[0].start).subtract(1, 'day').valueOf();
                    abschnittDummy.text = "Keine Daten";
                }

                dosierschemaSortiertNeu.push(abschnittDummy);
                setShowKommend(!!dosierschemaKommendNeu.length);
                medikationsplanContext.setDosierabschnittAktuell(planzeile.id, null);
                medikationsplanContext.setDosierabschnittPrev(planzeile.id, null);
                medikationsplanContext.setDosierabschnittNext(planzeile.id, dosierschemaKommendNeu[0]);
            } else {
                setShowKommend(false);
                medikationsplanContext.setDosierabschnittAktuell(planzeile.id, dosierschemaSortiertNeu[0]);
                medikationsplanContext.setDosierabschnittPrev(planzeile.id, dosierschemaVergangenNeu[dosierschemaVergangenNeu.length - 1]);
                medikationsplanContext.setDosierabschnittNext(planzeile.id, dosierschemaSortiertNeu[1] ? dosierschemaSortiertNeu[1] : dosierschemaKommendNeu[0]);
            }

            medikationsplanContext.setPlanzeilenVerblistert(prev => ({...prev, [planzeile.id]: wirdVerblistert}));
            medikationsplanContext.setDosierabschnittMap(prev => ({...prev, ...dosierabschnittMapNeu}));
            setDosierschemaSortiert(dosierschemaSortiertNeu);
            setDosierschemaVergangen(dosierschemaVergangenNeu);
            setDosierschemaKommend(dosierschemaKommendNeu);

            medikationsplanContext.postDosierabschnitte(sortiert);
        }
    }, [dosierschema, medikationsplanContext.anzeigeDatum])

    useEffect(() => {
        let dosierabschnittAktuellAltNeu = null;

        if (planzeileAlt) {
            if (!planzeile.freigabe && planzeile.json) {
                if (planzeileAlt.dosierschema) {
                    const anzeigeDatum = moment(medikationsplanContext.anzeigeDatum);
                    const dosierschemaAlt = [...planzeileAlt.dosierschema].sort((a, b) => dosierschemaSort(a, b, anzeigeDatum.valueOf()));

                    for (let abschnitt of dosierschemaAlt) {
                        const start = moment(abschnitt.start);
                        const ende = abschnitt.ende ? moment(abschnitt.ende) : null;

                        if (!(ende && ende.isBefore(anzeigeDatum)) && !(anzeigeDatum.isBefore(start))) {
                            dosierabschnittAktuellAltNeu = abschnitt;
                            break;
                        }
                    }
                }
            }
        }

        setDosierabschnittAktuellAlt(dosierabschnittAktuellAltNeu);
    }, [planzeileAlt, dosierschemaSortiert])



    if (!dosierschema) {
        return <h6>Kein Dosierschema</h6>
    }

    return (<div>
        {dosierschemaSortiert
            .map(
                (abschnitt, index) => {
                    if (medikationsplanContext.planzeileSelected !== planzeile.id && index > 0) {
                        return <></>;
                    }

                    return <div key={index}>
                        {
                            !!dosierabschnittAktuellAlt &&
                            medikationsplanContext.planzeileSelected === planzeile.id &&
                            <div style={{color: "grey"}}>
                                <b>zuletzt freigegebene Dosierung (freigegeben von: {getUnitLangname(planzeileAlt?.freigeber)}):</b><br />
                                <Abschnitt key={index + "-alt"} abschnitt={dosierabschnittAktuellAlt} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} />
                                <br />
                            </div>
                        }

                        <Abschnitt abschnitt={abschnitt} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} />
                    </div>
                }
            )}

        {medikationsplanContext.planzeileSelected === planzeile.id && (!abschnittFilter || abschnittFilter==="alle" || showKommend) && !!dosierschemaKommend.length && dosierschemaKommend[0].start &&
            <div>
                <Divider orientation="left">Kommende Dosierung</Divider>
                <Abschnitt abschnitt={dosierschemaKommend[0]} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} />
            </div>
        }

        {medikationsplanContext.planzeileSelected === planzeile.id && (!abschnittFilter || abschnittFilter==="alle") && !!dosierschemaVergangen.length &&
            <div>
                <Divider orientation="left">Vergangene Dosierungen</Divider>
                {dosierschemaVergangen.map(
                    (abschnitt, index) => <Abschnitt key={index} abschnitt={abschnitt} faktorMenge={faktorMenge} planzeile={planzeile} arzneimittel={arzneimittel} />
                    )
                }
            </div>
        }
    </div>)

}




export const Abschnitt = ({ abschnitt = {}, faktorMenge, planzeile, arzneimittel=null, dateString }) => {
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const [musterTyp, setMusterTyp] = useState("unbekannt");
    const [abgabemuster, setAbgabemuster] = useState([]);

    useEffect(() => {
        const musterTypNeu = getMusterTyp(abschnitt);
        const { faecherDarstellung } = parseAbgabemuster(abschnitt.abgabemuster, abschnitt.wiederholungsTag, musterTypNeu);

        setMusterTyp(musterTypNeu);
        setAbgabemuster(faecherDarstellung);
    }, [abschnitt])

    return (<div>
        {undefined === dateString ? getDosierabschnittDateRangeString(abschnitt, !medikationsplanContext.dosierabschnittNext[planzeile.id]) : dateString}
        {musterTyp === "frei" && <i style={{marginLeft: "1em"}}>[Staffel mit {abschnitt.wiederholungsTag} Tagen]</i>}

        {
            abschnitt.blisterPause && <Row><Col span={24}><b>Pause</b></Col></Row>
        }

        {abschnitt.dummy || !abgabemuster.length ? (
            <Row key={0}><Col span={24}><b>{abschnitt.text || "Keine Daten"}</b></Col></Row>
        ) : (
            abgabemuster.map((abgabetag, i) => {
                return <Row key={i} style={{color: abschnitt.blisterPause ? "grey" : "inherit"}}>
                    <Col span={8}>
                        <Row>
                            <Col span={16} style={{fontWeight: !abschnitt.blisterPause ? "bold" : "inherit"}}>
                                {musterTyp === "tag" && "täglich"}
                                {musterTyp === "woche" && WEEKDAYS_ALL[abgabetag.tag - 1].bezeichnung}
                                {musterTyp === "monat" && `${abgabetag.tag}.${abgabetag.tagBis ? ` bis ${abgabetag.tagBis === 99999 ? "Ende" : abgabetag.tagBis + "."}` : ""} des Monats`}
                                {musterTyp === "frei" && `Tag ${abgabetag.tag}${abgabetag.tagBis ? ` bis Tag ${abgabetag.tagBis}` : ""}`}
                            </Col>
                            <Col span={8}><i>{arzneimittel?.einheit || planzeile?.arzneimittel?.einheit}</i></Col>
                        </Row>
                    </Col>

                    <Col span={16}>
                        {abgabetag.sollZeitgenau && abgabetag.zeiten.sort((a,b) => a.zeit.localeCompare(b.zeit)).map(abgabeZeit => {
                            const isTeilbar = checkTeilbarkeitAbgabezeit({abgabeZeit, planzeile, api});
                            const style = isTeilbar ? {} : {backgroundColor: "#ffadd2"};

                            return <Row key={abgabeZeit.id}>
                                <Col span={5} offset={2}>
                                    {abgabeZeit.zeit.substring(0, 5)}
                                </Col>
                                {abgabeZeit.arzneimittel && <Popover content={<ArzneimittelAlternativePopover
                                    arzneimittelAlternative={abgabeZeit.arzneimittel}
                                    faktor={abgabeZeit.faktor ? abgabeZeit.faktor : 1}
                                    ansichtFaktor={faktorMenge}/>}
                                >
                                    <Col span={3} style={{textAlign: "center", ...style}} className="input-menge-alternative">
                                        {mengeToString(abgabeZeit.menge, abgabeZeit.faktor ? {faktor: abgabeZeit.faktor} : {faktor: faktorMenge})}{!isTeilbar && abgabeZeit.teilungExplizitErlaubt && "!"}
                                    </Col>
                                </Popover>}
                                {!abgabeZeit.arzneimittel && <Col span={3} style={{textAlign: "center", ...style}}>
                                    {mengeToString(abgabeZeit.menge, {faktor: faktorMenge})}{!isTeilbar && abgabeZeit.teilungExplizitErlaubt && "!"}
                                </Col>}
                            </Row>
                        })}

                        {!abgabetag.sollZeitgenau &&
                        <AbgabeFaecher zeiten={abgabetag.zeiten} faktorMenge={faktorMenge} planzeile={planzeile}
                                       arzneimittel={arzneimittel}/>
                        }

                    </Col>
                </Row>
            })
        )}


    </div >)


}



// todo  AbgabeFaecher -> moleculde / Fach -> atom

const AbgabeFaecher = ({ zeiten, faktorMenge, planzeile, arzneimittel=null }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const api = useApi();

    const [zeitenSortiert, setZeitenSortiert] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const patient = planzeile.medikationsplan?.patient;
        const blisterTypes = medikationsplanContext.blisterTypes;

        if (!blisterTypes) {
            api.loadBlisterTypes();
        } else if (zeiten && patient) {
            const blisterType = blisterTypes.find(b => b.id === patient.blisterType_id || 1);
            const groupTypes = blisterType.blisterGroupPattern?.type === 'BY_TIME' ? blisterType.blisterGroupPattern.groupTypes.sort(getSorter('id', 'start')) : [];
            zeiten.sort(getSorter('string', 'zeit'));

            const zeitenSortiertNeu = zeiten.reduce((result, zeit, index) => {
                    if (!zeit) return result;

                    const momentZeit = moment(`1970-01-01 ${zeit.zeit}`);
                    let index_groupType = -1;
                    for (let i=0; i<groupTypes.length; i++) {
                        const g = groupTypes[i];
                        const momentStart = moment(g.start);
                        const momentEnde = moment(g.end);

                        if (momentEnde.format('HH:mm:ss') === '00:00:00') momentEnde.add(1, 'day');
                        if (momentStart.isSameOrBefore(momentZeit) && momentEnde.isAfter(momentZeit)) {
                            index_groupType = i;
                            break;
                        }
                    }

                    const entry = result[index_groupType] || { zeiten: [] };
                    entry.zeiten.push(zeit);

                    result[index_groupType] = entry;
                    return result;
                },
                EINNAHMEZEITEN_FUENFFACH.map((e, index) => {
                    return {
                        zeiten: [],
                        index: e._id
                    }
                })
            );

            setZeitenSortiert(zeitenSortiertNeu);
            setLoading(false);
        }
    }, [zeiten, medikationsplanContext.blisterTypes, medikationsplanContext.medikationsplan])

    if (loading) return <Loading size={'sm'} showText={true} />

    return zeiten && zeiten.length ? (<Row style={{textAlign: "center"}}>
        <Fach zeiten={zeitenSortiert[0]?.zeiten} faktorMenge={faktorMenge} fachIndex={0} planzeile={planzeile} arzneimittel={arzneimittel} />
        <Fach zeiten={zeitenSortiert[1]?.zeiten} faktorMenge={faktorMenge} fachIndex={1} planzeile={planzeile} arzneimittel={arzneimittel} />
        <Fach zeiten={zeitenSortiert[2]?.zeiten} faktorMenge={faktorMenge} fachIndex={2} planzeile={planzeile} arzneimittel={arzneimittel} />
        <Fach zeiten={zeitenSortiert[3]?.zeiten} faktorMenge={faktorMenge} fachIndex={3} planzeile={planzeile} arzneimittel={arzneimittel} />
        <Fach zeiten={zeitenSortiert[4]?.zeiten} faktorMenge={faktorMenge} fachIndex={4} planzeile={planzeile} arzneimittel={arzneimittel} />
    </Row>) : (<Row style={{textAlign: "center"}}>
        <Fach />
        <Fach />
        <Fach />
        <Fach />
        <Fach />
    </Row>)
}

const Fach = ({ zeiten = [], fachIndex = 0, faktorMenge=1, planzeile, arzneimittel:anzeigeArzneimittel=null }) => {
    const api = useApi();
    const medikationsplanContext = useContext(MedikationsplanContext);

    const DEFAULT_ZEIT = useMemo(() => ({
        id: 0,
        menge: "0",
        zeit: EINNAHMEZEITEN_FUENFFACH[fachIndex].zeitfenster.default,
        fachNummer: fachIndex
    }), [fachIndex]);

    const [mengeSumme, setMengeSumme] = useState(0);
    const [istTeilbar, setIstTeilbar] = useState(true);
    const [defaultZeit, setDefaultZeit] = useState(DEFAULT_ZEIT);
    const [teilungExplizitErlaubt, setTeilungExplizitErlaubt] = useState(true);
    const [zeitenInfoStrings, setZeitenInfoStrings] = useState([]);

    useEffect(() => {
        let teilungExplizitErlaubtNeu = true;
        let summe = 0;

        if (zeiten.length) {
            const zeitenInfoStringsNeu = [];
            const momentZeitDefault = moment(EINNAHMEZEITEN_FUENFFACH[fachIndex].zeitfenster.default, 'HH:mm:ss');

            let zeitDefault;
            for (let zeit of zeiten) {
                const mengeZeit = parseFloat(zeit.menge) * (zeit.faktor && !isNaN(zeit.faktor) ? zeit.faktor : 1);
                const momentZeit = moment(zeit.zeit, 'HH:mm:ss');

                if (momentZeit.isSame(momentZeitDefault)) {
                    zeitDefault = zeit;
                }

                zeitenInfoStringsNeu.push(`${momentZeit.format('HH:mm')} Uhr: ${mengeToString(mengeZeit)}`);
                if (!zeit.teilungExplizitErlaubt && teilungExplizitErlaubtNeu) teilungExplizitErlaubtNeu = false;

                summe += mengeZeit;
            }

            if (!zeitDefault) {
                zeitDefault = zeiten[0];
            }

            setDefaultZeit(zeitDefault);
            const teilbarkeitAbgabezeit = checkTeilbarkeitAbgabezeit({abgabeZeit: zeitDefault, menge: summe, planzeile, api});
            setIstTeilbar(teilbarkeitAbgabezeit);
            setZeitenInfoStrings(zeitenInfoStringsNeu);
        }

        setMengeSumme(summe);
        setTeilungExplizitErlaubt(teilungExplizitErlaubtNeu);
    }, [zeiten])

    const zeitenInfoStringsPopover = zeitenInfoStrings.length > 1 ? <Popover placement={'top'} trigger={'hover'} pointerEvents={'none'} content={<div>
        <h6>Einnahmezeiten werden zusammengefasst</h6>
        {zeitenInfoStrings.map((z, i) => <div key={i}>{z}</div>)}
    </div>}>
        <InfoCircleOutlined style={{marginRight: 5}} />
    </Popover> : <></>

    if (mengeSumme > 0 && (!anzeigeArzneimittel || defaultZeit.arzneimittel && defaultZeit.arzneimittel.id === anzeigeArzneimittel.id || !defaultZeit.arzneimittel && planzeile.arzneimittel && planzeile.arzneimittel.id === anzeigeArzneimittel.id)) {
        if (medikationsplanContext.isStandardAnsicht && defaultZeit.arzneimittel) {
            return <>
                <Popover content={<ArzneimittelAlternativePopover
                    arzneimittelAlternative={defaultZeit.arzneimittel}
                    faktor={defaultZeit.faktor ? defaultZeit.faktor : 1}
                    ansichtFaktor={faktorMenge}/>}
                >
                    <Col span={4} className="input-menge-alternative" style={istTeilbar ? {} : {backgroundColor: "#ffadd2"}}>{zeitenInfoStringsPopover}<b>{mengeToString(mengeSumme, defaultZeit.faktor ? {faktor: defaultZeit.faktor} : {faktor: faktorMenge})}{!istTeilbar && teilungExplizitErlaubt && "!"}</b></Col>
                </Popover>
            </>
        } else {
            return <>
                <FachAusgabe style={istTeilbar ? {} : {backgroundColor: "#ffadd2"}}>{zeitenInfoStringsPopover}<b>{mengeToString(mengeSumme, {faktor: faktorMenge})}{!istTeilbar && teilungExplizitErlaubt && "!"}</b></FachAusgabe>
            </>
        }
    }

    return <>
        <FachAusgabe>{zeitenInfoStringsPopover}0</FachAusgabe>
    </>
}

const FachAusgabe = ({ children, style={} }) => {
    return <Col span={4} style={{ fontSize: "8pt", ...style}}>{children}</Col>;
}