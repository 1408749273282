import React, {useEffect} from 'react'

import {ModusRezeptanforderung, Rezeptanforderungen} from './Rezeptanforderungen';
import {useApi} from "../../utilities/useApi";

export const RezeptanforderungenOffen = ({ gewaehlteNachricht, setGewaehlteNachricht, ...props }) => {
    const api = useApi();

    useEffect(() => {
        if (!api.anforderungenOffenLoaded) {
            api.loadAnforderungenOffen();
        }
    }, [api.anforderungenOffenLoaded])

    return <>
        <h6>Offene Rezeptanforderungen:</h6>
        <Rezeptanforderungen
            title={"Anforderungen offen"}
            anforderungen={api.anforderungenOffen}
            loading={!api.anforderungenOffenLoaded}
            modus={ModusRezeptanforderung.offen}
        />
    </>

}
