import React, { useEffect } from 'react'

import { Radio } from 'antd';


export const InputWiederholungsTag = ({ value, label, onChange, maxTag, freiesMuster }) => {

    const TAGE = [
        {
            tag: 1,
            bezeichnung: "täglich"
        },
        {
            tag: 7,
            bezeichnung: "Woche"
        },
        {
            tag: 99999,
            bezeichnung: "Monat"
        },
        {
            tag: 0,
            bezeichnung: "frei"
        },
    ]

    const handleChange = (e) => {
        if (e.target.value > 0) {
            onChange(e.target.value, label);
            onChange(false, "freiesMuster");
        }
        else {
            onChange(1, label);
            onChange(true, "freiesMuster");
        }
    }

    useEffect(() => {
        if (!freiesMuster && maxTag > value) {
            if (maxTag > 99999)
                onChange(null, label)

            else if (maxTag > 7)
                onChange(99999, label)

            else if (maxTag > 1)
                onChange(7, label)

        }
    }, [maxTag])

    return <Radio.Group
        value={freiesMuster ? 0 : value}
        onChange={handleChange}
    >
        {TAGE.map((schema, index) => <Radio.Button
            key={index}
            style={{ width: "100px", textAlign: "center" }}
            disabled={schema.tag > 0 && maxTag > schema.tag}
            value={schema.tag}
        >{schema.bezeichnung}</Radio.Button>
        )}

    </Radio.Group>

}


