import React from "react";
import {Popover} from "antd";

export const ConditionalPopover = ({pointerEvents="auto", onVisibleChange, visible, ...props}) => {
    const popoverProps = {...props};
    delete popoverProps.children;
    popoverProps.overlayStyle = {pointerEvents, ...popoverProps.overlayStyle}

    const attr = {...popoverProps};
    if (onVisibleChange) attr.onVisibleChange = onVisibleChange;
    if (visible === true || visible === false) attr.visible = visible;

    return <Popover {...attr} overlayClassName={!!props.content ? "popover-visible" : "popover-invisible"}>{props.children}</Popover>;
}