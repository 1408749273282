import React, {useContext, useEffect, useRef, useState} from "react";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import moment from "moment";
import {callApiAsync} from "../../utilities/apiUtil";
import {arzneimittelApi, medikationsplanApi} from "../../config/apiConfig";
import {useAuth} from "../../utilities/useAuth";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {Spin, Tree} from "antd";
import {useApi} from "../../utilities/useApi";
import {mengeToString} from "../../utilities/dosierschemaUtil";

export const MedikationsplanKopfAbgaben = ({}) => {
    const auth = useAuth();
    const medikationsplanContext = useContext(MedikationsplanContext);
    const api = useApi();

    const [abgaben, setAbgaben] = useState({});
    const [treeDataBestand, setTreeDataBestand] = useState([]);
    const treeDataWirdBerechnet = useRef(false);

    const [loading, setLoading] = useState(true);

    const processAbgaben = async (abgaben) => {
        if (!treeDataWirdBerechnet.current) {
            treeDataWirdBerechnet.current = true;

            const internalPackageMap = {};
            const treeDataArrayBestand = [];
            if (abgaben) {
                const treeDataMapBestand = {};
                const m2Map = {};
                const keyToM2StringMap = {};
                const m2StringToBuendelID = {};

                let buendelId = null;
                let m2String = null;
                let arzneimittel = null;
                let arzneimittelBuendel;
                let internalPackage;
                for (let planzeileId in abgaben) {
                    const abgabezeitenMap = abgaben[planzeileId];

                    for (let abgabezeit in abgabezeitenMap) {
                        const abgabenMap = abgabezeitenMap[abgabezeit];

                        for (let key in abgabenMap) {
                            const pair = abgabenMap[key];
                            const abgabemenge = pair.key;
                            const abgabefaktor = pair.value;

                            internalPackage = null;
                            m2String = null;
                            arzneimittel = null;
                            buendelId = null;
                            arzneimittelBuendel = null;

                            if (key.startsWith("M2_")) {
                                m2String = keyToM2StringMap[key];
                                if (!m2String) {
                                    const match = key.match(/M2_(.+)_B_(.+)/);
                                    m2String = match[1];
                                    m2StringToBuendelID[m2String] = match[2];
                                    keyToM2StringMap[key] = m2String;
                                }

                                buendelId = m2StringToBuendelID[m2String];
                            } else {
                                internalPackage = internalPackageMap[key] || medikationsplanContext.internalPackageMap[key];
                                if (!internalPackage) {
                                    internalPackage = await api.loadInternalPackage(key);
                                    internalPackageMap[key] = internalPackage;
                                }

                                m2String = internalPackage.batch.id.medicinePZN.medicineM2.m2;
                                buendelId = m2StringToBuendelID[m2String];
                                if (!buendelId) {
                                    buendelId = internalPackage.idBundle;
                                    m2StringToBuendelID[m2String] = buendelId;
                                }
                            }

                            arzneimittel = m2Map[m2String];
                            if (!arzneimittel) {
                                const response = await callApiAsync({auth, url: arzneimittelApi.getByM2(m2String)});
                                arzneimittel = response.data.OBJECT;
                                m2Map[m2String] = arzneimittel;
                            }

                            const entry = {
                                title: `${moment(parseInt(abgabezeit)).format("dd DD.MM.YYYY HH:mm")}: ${mengeToString(abgabemenge)} (${internalPackage ? `Packung #${internalPackage.id}` : "Planzeilenarzneimittel"}: ${getArzneimittelLangname(arzneimittel)})`,
                                key: abgabezeit + m2String + abgabemenge + abgabefaktor,
                                abgabezeit,
                            }

                            let entryBuendel = treeDataMapBestand[buendelId];
                            if (!entryBuendel) {
                                const buendel = medikationsplanContext.buendelMap[buendelId];
                                if (!buendel) return false;

                                arzneimittelBuendel = m2Map[buendel.m2Ref];
                                if (!arzneimittelBuendel) {
                                    const response = await callApiAsync({auth, url: arzneimittelApi.getByM2(buendel.m2Ref)});
                                    arzneimittelBuendel = response.data.OBJECT;
                                    m2Map[buendel.m2Ref] = arzneimittelBuendel;
                                }

                                entryBuendel = {
                                    title: buendel ? `Bündel: ${getArzneimittelLangname(arzneimittelBuendel)}` : `Kein Bündel gefunden (ID ${buendelId})`,
                                    key: buendelId,
                                    children: []
                                }
                                treeDataMapBestand[buendelId] = entryBuendel;
                                treeDataArrayBestand.push(entryBuendel);
                            }
                            entryBuendel.children.push(entry);
                        }
                    }
                }
            }

            // Sortiere nach Arzneimittel, Abgabezeit
            treeDataArrayBestand.sort((a,b) => a.title.localeCompare(b.title));
            treeDataArrayBestand.forEach(entry => entry.children.sort((a,b) => a.abgabezeit - b.abgabezeit));

            setTreeDataBestand(treeDataArrayBestand);
            treeDataWirdBerechnet.current = false;
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (medikationsplanContext.medikationsplan?.id) {
            setLoading(true);
            callApiAsync({auth, url: medikationsplanApi.getAbgaben(medikationsplanContext.medikationsplan.id), method: "post", data: []}).then(response => {
                const abgabenNeu = response.data.OBJECT;
                if (Object.keys(abgabenNeu).length) setAbgaben(abgabenNeu);
                else setLoading(false);
            });
        }
    }, [medikationsplanContext.medikationsplan?.id])

    useEffect(() => {
        if (Object.keys(abgaben).length && Object.keys(medikationsplanContext.buendelMap).length) {
            processAbgaben(abgaben).then(res => {
                if (res) {
                    setLoading(false);
                }
            });
        }
    }, [abgaben, medikationsplanContext.buendelMap])

    if (loading) {
        return <div style={{textAlign: "center"}}>
            <Spin tip={"Abgaben werden geladen"} />
        </div>
    }

    return <>
        <h3>Abgaben</h3>
        {treeDataBestand.length ? <Tree treeData={treeDataBestand} /> : "[keine Abgaben]"}
    </>
}