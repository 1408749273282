import React, {useContext, useEffect, useState} from 'react'

import { useDataNode } from '../../utilities/useDataNode';
import { useFetch } from '../../utilities/useFetch';
import { fetchDokumente } from '../../config/fetchApiConfiguration';


import {medikationsplanNode} from '../../config/dataNodeConfiguration';

import { MedikationsplanKopf } from './MedikationsplanKopf';
import { MedikationsplanZeilen } from './MedikationsplanZeilen';

import { MediplanCacheNavigator } from '../molecules/MediplanCacheNavigator';

import {StatusDisplay} from "../../utilities/useDataNode";
import {notification} from "antd";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {useApi} from "../../utilities/useApi";
import {akteureApi, arzneimittelApi, buendelApi, planzeilenApi, verblisterungApi} from "../../config/apiConfig";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import ArzneimittelContext from "../../contexts/ArzneimittelContext";
import AdressbuchContext from "../../contexts/AdressbuchContext";
import {Fallback} from "../../config/fallbackPages";


export const MedikationsplanDetail = ({ ansicht=null, selected, medikationsplanId }) => {
    const medikationsplanContext = useContext(MedikationsplanContext);
    const adressbuchContext = useContext(AdressbuchContext);
    const api = useApi();

    const medikationsplan = medikationsplanContext.medikationsplan;

    const {
        current: medikationsplanFetched,
        status,
        handleChange,
        handleChangeNoTriggerUpdated,
        handleSave,
        handleLoad,
        updated,

        chachedIdList

    } = useDataNode({
        key: medikationsplanId,
        dataNode: medikationsplanNode,
        cacheSlice: "medikationsplan"
    })


    // Dokumente
    const dokumente = useFetch(
        medikationsplan && medikationsplan.patient ? fetchDokumente(medikationsplan.patient.id) : null
    )


    // triggerSave === true löst speicherung der planzeilen in Kind-Element aus
    const [triggerSave, setTriggerSave] = useState(false)
    const [childrenUpdated, setChildrenUpdated] = useState([]);
    const [childrenSaved, setChildrenSaved] = useState([]);
    const [triggerNew, setTriggerNew] = useState(false)

    const speichereMediplan = () => {
        setTriggerSave(true)
    }

    const [disableNeueZeile, setDisableNeueZeile] = useState(false);
    const [tabChanged, setTabChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    const [finishLoadingAnspreuche, setFinishLoadingAnsprueche] = useState(false);

    useEffect(() => {
        if (medikationsplanFetched) {
            (async () => {
                if (updated) {
                    await handleSave();
                }

                medikationsplanFetched.patient = adressbuchContext.akteur;
                medikationsplanContext.setMedikationsplan(medikationsplanFetched);
                medikationsplanContext.setPlanzeileSelected(selected);

                const buendelList = await api.loadBuendelListByMedikationsplan(medikationsplanFetched.id);
                medikationsplanContext.setBuendelList(buendelList);

                api.loadNotizenForUnit(adressbuchContext.akteur.id);

                if (!medikationsplanContext.blisterTypes) {
                    api.loadBlisterTypes();
                }
            })()
        }
    }, [medikationsplanFetched])

    useEffect(() => {
        if (medikationsplanContext.updatePatient) {
            handleChangeNoTriggerUpdated(medikationsplanContext.updatePatient, "patient");
            medikationsplanContext.setUpdatePatient(null);
        }
    }, [medikationsplanContext.updatePatient])

    useEffect(() => {
        if (triggerSave && !childrenUpdated.length) {
            setTriggerSave(false);
            if (updated) handleSave();
        }
    }, [triggerSave, childrenUpdated])

    useEffect(() => {
        if (childrenSaved.length) {
            const childrenUpdatedNew = childrenUpdated.filter(id => !childrenSaved.includes(id));
            setChildrenUpdated(childrenUpdatedNew);
            setChildrenSaved([]);
        }
    }, [childrenSaved])

    useEffect(() => {
        if (saving && !(childrenUpdated.length > 0 || updated) && !tabChanged) {
            notification.info({message: "Der Medikationsplan wurde erfolgreich gespeichert."})
            setSaving(false);
        }

        medikationsplanContext.setSaveButtonDisabled(!tabChanged && !(childrenUpdated.length > 0 || updated));
    }, [updated, childrenUpdated, tabChanged])

    useEffect(() => {
        if (finishLoadingAnspreuche) {
            api.checkAnspruecheSubstitute().then(() => {
                medikationsplanContext.setAnspruecheLoaded(true);
            });
        }
    }, [finishLoadingAnspreuche])

    useEffect(() => {
        if (medikationsplanContext.reloadAnsprueche) {
            reloadAnsprueche();
        }
    }, [medikationsplanContext.reloadAnsprueche])

    useEffect(() => {
        if (medikationsplanContext.bestandList?.length > 0) {
            const keyList = Object.keys(medikationsplanContext.internalPackageMap);
            if (medikationsplanContext.bestandList.length === keyList.length) {
                for (let bestand of medikationsplanContext.bestandList) {
                    if (bestand.planzeile) {
                        const internalPackage = medikationsplanContext.internalPackageMap[bestand.packungId];
                        let mengeVorab = 0;
                        if (internalPackage?.upfrontSet?.length) {
                            for (let upfront of internalPackage.upfrontSet) {
                                mengeVorab += upfront.amountRemaining;
                            }
                        }

                        if (mengeVorab > 0) {
                            medikationsplanContext.setVorabMap(prev => ({...prev, [bestand.planzeile.id]: mengeVorab}));
                        }
                    }
                }
            }
        }
    }, [medikationsplanContext.bestandList, medikationsplanContext.internalPackageMap])



    const reloadAnsprueche = async () => {
        await api.loadAnsprueche(medikationsplan?.patient?.id);
        await api.checkAnspruecheSubstitute();

        medikationsplanContext.setReloadAnsprueche(false);
    }

    // triggerNew === true löst speicherung der planzeilen in Kind-Element aus
    const neuePlanzeile = () => {
        setTriggerNew(true);
    }

    const setChildUpdated = planzeileID => {
        setChildrenUpdated(prev => {
            const childrenNew = [...prev.filter(id => id != planzeileID), planzeileID];
            return childrenNew;
        });
    }

    const setChildSaved = planzeileID => {
        setChildrenUpdated(prev => {
            const childrenNew = [...prev.filter(id => id != planzeileID)];
            return childrenNew;
        });
    }

    return <StatusDisplay status={status}>
        {!!medikationsplan && <>

            <MediplanCacheNavigator
                chachedIdList={chachedIdList}
            />

            <MedikationsplanKopf
                value={medikationsplan}
                onChange={handleChange}
                handleSave={speichereMediplan}
                handleNew={neuePlanzeile}
                handleLoad={() => {
                    handleLoad();
                    setChildrenUpdated([]);
                }}
                dokumente={dokumente}

                changed={childrenUpdated.length > 0 || updated}
                disableNeueZeile={disableNeueZeile}

                tabChanged={tabChanged}
                setTabChanged={setTabChanged}

                saving={saving}
                setSaving={setSaving}
            />
            <hr/>

            <div style={{display: medikationsplanContext.medikationsplanVisible ? "initial" : "none"}}>
                <MedikationsplanZeilen
                    medikationsplan={medikationsplan}
                    blisterStartTag={medikationsplan.blisterStartTag}

                    triggerSave={triggerSave}
                    setTriggerSave={setTriggerSave}

                    triggerNew={triggerNew}
                    setTriggerNew={setTriggerNew}

                    hausarzt={medikationsplan.patient?.hausarzt}

                    childrenUpdated={childrenUpdated}
                    setChildUpdated={setChildUpdated}
                    setChildSaved={setChildSaved}
                    setDisableNeueZeile={setDisableNeueZeile}
                    ansicht={ansicht}
                    updated={childrenUpdated.length > 0 || updated}
                />
            </div>
        </>}
    </StatusDisplay>
}