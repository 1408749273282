import React from 'react'
import Icon from '@ant-design/icons'
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";

// Lädt andere JSX Komponenten, falls Fehler aufgetreten, Datensatz noch geladen wird oder keine Daten geladen wurden
export const Fallback = (data, jsxComponent = null) => {
    if (!data) return <></>
    if (data.error) return Errorpage(data.error)
    if (data.isLoading) return IsLoading()
    if (data.isSaving) return IsSaving()
    return jsxComponent ? jsxComponent : <></>
}


const Errorpage = (props) => {
    return <div>
        <h3>Es ist ein Fehler aufgetreten:</h3>
        <pre>{JSON.stringify(props, null, 2)}</pre>
    </div>
}

const IsLoading = (props) => {
    return <div><LoadingOutlined style={{ fontSize: '64px', color: '#08c' }} /></div >
}

const IsSaving = (props) => {
    return <div><SyncOutlined spin style={{ fontSize: '64px', color: '#08c' }} /></div >
}
