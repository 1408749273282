
import React, {useRef} from 'react'
import { useState, useEffect } from 'react';
import { Progress, notification, Collapse, Button } from 'antd';
import {LoadingOutlined, WarningOutlined} from "@ant-design/icons";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";


/********************************************************************************************************************************/

// Status Display Komponenten
// ToDo in andere Datei auslagern (config / util ?)

const { Panel } = Collapse;


/*********************************************
    Status Display : Error
*********************************************/
export const Error = ({ msg }) => {

    useEffect(() => {

        if (msg.lösung)
            notification.open({
                duration: 20,
                message: "Lösungsvorschlag:",
                description: msg.lösung
            });


        if (msg.data && msg.data.EXCEPTION) {
            notification.open({
                duration: 20,
                message: "Keine Panik! ;)",
                description: "Solche unverständlichen Meldungen sollten hier gar nicht auftauchen, sorry... Um den Fehler zu melden, bitte den roten Knopf drücken. Danke!"
            });
        }

    }, [msg])


    return <div style={{ marginTop: "50px" }}>
        <h1><WarningOutlined /> Fehler aufgetreten</h1>

        <h4>{msg.problem && <div>{msg.problem}</div>}</h4>

        <h4>{msg.data && msg.data.EXCEPTION && msg.data.EXCEPTION.detailMessage}</h4>

        {msg.config && <pre>{JSON.stringify(msg.config, null, 2)}</pre>}


        <div>
            <Button
                onClick={() => window.location.reload()}
                type="primary">Neuladen</Button>
        </div>

        <div>
            <Button
                onClick={() =>
                    notification.open({
                        message: "ToDo: ",
                        description: "Automatisches Fehlermeldesystem bauen..."
                    })
                }
                type="danger">Fehler an Entwicklerteam melden</Button>
        </div>


        <Collapse defaultActiveKey={null}>
            <Panel header="Fehlermeldung im Detail anzeigen" key="1">
                <pre>{JSON.stringify(msg, null, 2)}</pre>
            </Panel>
        </Collapse>,

    </div >

}



/*********************************************
    Status Display : Loading
*********************************************/
export const Loading = () => {
    const [wartezeit, setWartezeit] = useState(0)
    const intervalRef = useRef(null);

    const count = (startCounter = 0) => {
        if (intervalRef.current) clearInterval(intervalRef.current);

        setWartezeit(startCounter);
        intervalRef.current = setInterval(() => {
            setWartezeit(prev => prev + 1);
        }, 1000);
    }

    useEffect(() => {
        if (wartezeit === 7)
            notification.open({
                message: "Da stimmt was nicht :(",
                description: "Solange sollte das nicht dauern. Besteht das Problem weiter, wenn Sie die Seite neuladen (F5) ?",
            });
    }, [wartezeit])

    useEffect(() => {
        count(0)
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [])

    return <div style={{ marginTop: "50px" }}>
        <h1><LoadingOutlined /> wird geladen</h1>
        <h4>{(wartezeit > 2) && <i>Ohje... Sie warten jetzt schon {JSON.stringify(wartezeit)} Sekunden :(</i>}</h4>
    </div>

}



/*********************************************
    Status Display : Saving
*********************************************/
export const Saving = ({ status }) => {

    return <div style={{ marginTop: "50px" }}>
        {JSON.stringify(status)}
        {status.progress !== null && <Progress type="circle" percent={status.progress} />}

        {status.saving && <h1><SyncOutlined spin /> speichere</h1>}
    </div>

}