import React, {useCallback, useContext, useEffect, useRef} from "react";
import {GlobalHotKeys} from "react-hotkeys";
import WindowContext from "../../contexts/WindowContext";
import NotizContext from "../../contexts/NotizContext";
import InteraktionenContext from "../../contexts/InteraktionenContext";
import GlobalHotkeyContext from "../../contexts/GlobalHotkeyContext";

const keyMap = {
    OPEN_MODAL_NOTIZ: "n",
    ESC: "esc",
    UP: "ArrowUp",
    DOWN: "ArrowDown",
    LEFT: "ArrowLeft",
    RIGHT: "ArrowRight",
}

const CustomGlobalHotKeys = (props) => {

    const windowContext = useContext(WindowContext);
    const notizContext = useContext(NotizContext);
    const interaktionenContext = useContext(InteraktionenContext);
    const globalHotkeyContext = useContext(GlobalHotkeyContext);

    const activeWindowRef = useRef(null);

    useEffect(() => {
        activeWindowRef.current = windowContext.activeId;
    }, [windowContext.activeId])

    const ctrlKeyDown = useRef(false);
    const shiftKeyDown = useRef(false);

    const openModalNotiz = useCallback(() => {
        notizContext.setVisible(true);
    }, []);

    const onEscGlobal = useCallback(e => {
        if (activeWindowRef.current) {
            switch (activeWindowRef.current) {
                case "card-notiz":
                    e.preventDefault();
                    notizContext.setVisible(false);
                    break;
                case "card-interaktionen":
                    e.preventDefault();
                    interaktionenContext.setShowModal(false);
                    break;
            }
        }
    }, [windowContext.activeId]);

    const handlers = {
        OPEN_MODAL_NOTIZ: (e) => {
            globalHotkeyContext.setLastHotkey("OPEN_MODAL_NOTIZ");
            const isEditable = e.target instanceof HTMLInputElement && e.target.type === 'text';
            if (!isEditable) {
                openModalNotiz();
            }
        },
        ESC: e => {
            globalHotkeyContext.setLastHotkey("ESC");
            onEscGlobal(e);
        },
        UP: () => globalHotkeyContext.setLastHotkey("UP"),
        DOWN: () => globalHotkeyContext.setLastHotkey("DOWN"),
        LEFT: () => globalHotkeyContext.setLastHotkey("LEFT"),
        RIGHT: () => globalHotkeyContext.setLastHotkey("RIGHT"),
    }

    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
            {props.children}
        </GlobalHotKeys>
    )
}

export default CustomGlobalHotKeys;