import React, {useEffect, useState} from 'react'
import {Button, Row, Col, Tabs, Modal} from 'antd';

// import { MedikationsplanSidebarSuche } from '../organisms/MedikationsplanSidebarSuche';
import { NachrichtenArchiv } from '../organisms/NachrichtenArchiv';
import { NachrichtenDetailansicht } from '../organisms/NachrichtenDetailansicht';
import { RezeptanforderungenGesammelt } from '../organisms/RezeptanforderungenGesammelt';
import { RezeptanforderungenOffen } from '../organisms/RezeptanforderungenOffen';
import { Bestellungen } from '../organisms/Bestellungen';
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import {EditOutlined, InboxOutlined, WarningOutlined} from "@ant-design/icons";
import NachrichtenContext, {useInitialState} from "../../contexts/NachrichtenContext";
import {useHistory} from "react-router-dom";
import queryString from "query-string";

const { TabPane } = Tabs;


export const Nachrichten = ({ ...props }) => {
    const nachrichtenContextState = useInitialState();
    const history = useHistory();

    const [tab, setTab] = useState("sammlung");

    useEffect(() => {
        const query = queryString.parse(history.location.search);
        if (query.tab) {
            setTab(query.tab);
        } else {
            setTab("sammlung");
        }
    }, [history.location])

    return <NachrichtenContext.Provider value={nachrichtenContextState}>
        <Modal
            visible={!!nachrichtenContextState.gewaehlteNachricht}
            footer={null}
            onCancel={() => nachrichtenContextState.setGewaehlteNachricht(null)}
        >
            <NachrichtenDetailansicht />
        </Modal>

        <Row>
            <Col span={24}>
                <Tabs
                    tabBarExtraContent={
                        <div>
                            <Button><SyncOutlined /></Button>
                            {/* <Button onClick={handleSave}><SaveOutlined /></Button> */}
                            <Button><PlusCircleOutlined /></Button>
                        </div>
                    }

                    onChange={key => {
                        const url = history.location.pathname;
                        const query = queryString.parse(history.location.search);

                        switch (key) {
                          case "sammlung":
                              delete query.tab;
                              break;
                          default:
                              query.tab = key;
                        }

                        history.push(queryString.stringifyUrl({url, query}));
                    }}
                    activeKey={tab}
                >

                    {/* gesammelte Rezeptanforderungen */}
                    <TabPane tab={<span><EditOutlined /> anzufordern / zu mahnen</span>} key="sammlung">
                        <RezeptanforderungenGesammelt />
                    </TabPane>


                    {/* unbeantwortete Rezeptanforderungen */}
                    <TabPane tab={<span><WarningOutlined /> offene Anforderungen</span>} key="offen">
                        <RezeptanforderungenOffen />
                    </TabPane>


                    {/* bestellte Packungen */}
                    {/*<TabPane tab={<span><WarningOutlined /> bestellte Packungen</span>} key="offen">*/}
                    {/*    <Bestellungen />*/}
                    {/*</TabPane>*/}


                    {/* NachrichtenArchiv */}
                    <TabPane tab={<b><InboxOutlined /> Nachrichten</b>} key="nachrichten">
                        <NachrichtenArchiv />
                    </TabPane>

                </Tabs>

            </Col>
        </Row>
    </NachrichtenContext.Provider >

}

