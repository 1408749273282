import React from "react";
import {Popover} from "../atoms/Popover";
import {Checkbox, Slider, Switch} from "antd";

const EinnahmezeitTeilungExplizitErlaubtIcon = ({ onChange, value, text })  => {

    return <Popover trigger={"hover"} placement={"bottom"} content={<div>
        <h6>Teilung nicht möglich</h6>
        <p>
            Laut Bewertung ist dieses Arzneimittel nicht teilbar wie angegeben.<br/>
            Dadurch wird das Arzneimittel für eine Reichweitenberechnung nicht berücksichtigt.<br/>
            Die Teilung kann dennoch explizit erlaubt werden, wodurch ALLE eigentlich nicht teilbaren Arzneimittel in dem Planzeilenbündel für eine Reichweitenberechnung berücksichtigt werden.
        </p>
        <p>
            <Switch onChange={onChange} checked={value} /> Teilung explizit erlauben
        </p>
    </div>}>
        <span>{text || "!"}</span>
    </Popover>
}

export default EinnahmezeitTeilungExplizitErlaubtIcon;