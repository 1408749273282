import React, {useState, useEffect, useRef} from 'react';

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export default function useDebouncedClickCounter(delay, maxCount= 0, firstClickInstant=false) {
    const [debouncedClickCounter, setDebouncedClickCounter] = useState(0);
    const [clickedCounter, setClickedCounter] = useState(0);
    const clickedCounterReference = useRef();

    useEffect(() => {
        if (clickedCounter > 0) {
            if (clickedCounter === maxCount) {
                setDebouncedClickCounter(maxCount);
                setClickedCounter(0);
            } else {
                if (firstClickInstant && clickedCounter === 1) {
                    setDebouncedClickCounter(clickedCounter);
                }

                const handler = setTimeout(() => {
                    setDebouncedClickCounter(clickedCounter);
                    setClickedCounter(0);
                }, delay);

                return () => {
                    clearTimeout(handler);
                }
            }
        } else {
            setDebouncedClickCounter(0);
        }
    },[clickedCounter, clickedCounterReference.current]);

    const onClick = (ref) => {
        if (clickedCounterReference.current === ref) {
            setClickedCounter(clickedCounter + 1);
        } else {
            setClickedCounter(1);
            clickedCounterReference.current = ref;
        }
    }

    return [debouncedClickCounter, onClick];
}