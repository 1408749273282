import {createContext, useEffect, useReducer, useRef, useState} from "react";
import moment from "moment";

import {heute} from '../utilities/dosierschemaUtil'
import {ANSICHT_STANDARD} from "../components/organisms/MedikationsplanZeilen";
import {getAnforderungDataAsync} from "../components/molecules/RezeptanforderungModal";

const initVal = {
    reset: ()=>{},
    
    medikationsplan: null,
    setMedikationsplan: medikationsplan => {},

    planzeilen: null,
    planzeilenMap: {},
    setPlanzeilen: (planzeilen) => {},

    buendelList: null,
    buendelMap: {},
    setBuendelList: (buendelList) => {},

    ansicht: "",
    setAnsicht: val=>{},

    ansichten: "",
    setAnsichten: val=>{},

    dosierabschnittMap: {},
    setDosierabschnittMap: val=>{},

    dosierabschnittSpanneNext: null,
    dosierabschnittSpannePrev: null,
    dosierabschnittSpanneCurrent: null,

    anzeigeDatumPrev: null,
    setAnzeigeDatumPrev: datum => {},
    anzeigeDatumNext: null,
    setAnzeigeDatumNext: datum => {},

    postDosierabschnitte: dosierabschnitte => {},

    anzeigeDatum: null,
    setAnzeigeDatum: timestamp => {},

    dosierabschnittAktuell: {},
    setDosierabschnittAktuell: (planzeileId, dosierabschnitt) => {},

    dosierabschnittPrev: {},
    setDosierabschnittPrev:val=>{},

    dosierabschnittNext: {},
    setDosierabschnittNext:val=>{},

    dosierabschnittGeoeffnet: null,
    setDosierabschnittGeoeffnet: ({id, planzeile}) => {},

    isStandardAnsicht: true,
    setIsStandardAnsicht: (isStandardansicht) => {},

    neuePlanzeile: null,
    setNeuePlanzeile: (neuePlanzeile)=>{},

    berechneReichweiteAb: null,
    setBerechneReichweiteAb: val => {},

    berechneReichweiteBis: null,
    setBerechneReichweiteBis: val => {},

    berechneReichweiteAwaitFinish: false,
    setBerechneReichweiteAwaitFinish: val => {},

    berechneReichweitenachExport: false,
    setBerechneReichweiteNachExport: val => {},

    berechneReichweiteAllePlanzeilen: false,
    setBerechneReichweiteAllePlanzeilen: val => {},

    reichweiteWirdBerechnet: [],
    setReichweiteWirdBerechnet: ([])=>{},

    fehlerBeiReichweitenberechnungAb: null,
    setFehlerBeiReichweitenberechnungAb: val => {},

    reloadBestaende: false,
    setReloadBestaende: ()=>{},

    bestaendeLoaded: false,
    setBestaendeLoaded: val => {},

    bestandList: null,
    setBestandList: val => {},

    zeitraeumeLoaded: false,
    setZeitraeumeLoaded: val => {},

    zeitraumList: null,
    zeitraumMap: null,
    setZeitraumMap: (val) => {},

    internalPackageMap: {},
    setInternalPackageMap: val => {},

    vorabMap: {},
    setVorabMap: val => {},

    reloadAnsprueche: false,
    setReloadAnsprueche: ()=>{},

    reloadVerknuepfungen: false,
    setReloadVerknuepfungen: ()=>{},

    anspruecheLoaded: false,
    setAnspruecheLoaded: val => {},

    anspruchList: [],
    setAnspruchList: val => {},
    anspruchSubstituteChecked: false,
    anspruchMap: {},

    infoMarkerMap: {},
    setInfoMarkerMap: () => {},

    planzeileSelected: null,
    setPlanzeileSelected: ()=>{},

    anforderungen: {},
    setAnforderungen: (neu) => {},

    saveButtonDisabled: false,
    setSaveButtonDisabled: val => {},

    checkAenderungsauftragAb: null,
    setCheckAenderungsauftragAb: val => {},

    blisterjobs: [],
    setBlisterjobs: val => {},

    exportStart: null,
    setExportStart: val => {},

    exportEnde: null,
    setExportEnde: val => {},

    exportFinished: false,
    setExportFinished: val => {},

    exportPending: false,
    setExportPending: val => {},

    exportingAmmendment: false,
    setExportingAmmendment: () => {},

    displayExportResult: false,
    setDisplayExportResult: () => {},

    dokumenteGueltig: {},

    verknuepfungen: {},
    setVerknuepfungen: ()=>{},

    verknuepfungChanged: null,
    setVerknuepfungChanged: val => {},

    vorgangAktiv: false,
    setVorgangAktiv: val => {},

    reload: false,
    setReload: val => {},

    planzeilenChange: [],
    setPlanzeilenChange: val => {},

    planzeilenChangeAfterLoad: [],
    setPlanzeilenChangeAfterLoad: val => {},

    medikationsplanVisible: true,
    setMedikationsplanVisible: val => {},

    updatePatient: {},
    setUpdatePatient: val => {},

    changePatient: null,
    setChangePatient: val=>{},

    patientStammdatenLoaded: false,
    setPatientStammdatenLoaded: val=>{},

    oeffneDosierabschnitt: null,
    setOeffneDosierabschnitt: dosierabschnittId=>{},

    oeffneDosierabschnittPlanzeileId: null,
    setOeffneDosierabschnittPlanzeileId: planzeileId=>{},

    pauseGlobal: {},
    setPauseGlobal: val=>{},

    resetGlobal: [],
    setResetGlobal: val=>{},

    planzeilenChecked: [],
    setPlanzeilenChecked: val=>{},

    planzeilenSichtbar: {},
    setPlanzeilenSichtbar: val=>{},

    absetzenGlobal: null,
    setAbsetzenGlobal: val=>{},

    planzeilenVerblistert: {},
    setPlanzeilenVerblistert: val=>{},

    searchList: {},
    setSearchList: val=>{},

    dosierabschnittNeuId: -1,

    disableCheckAenderungModal: false,
    setDisableCheckAenderungModal: val=>{},

    isPlanzeileSichtbar: planzeileId => false,

    handleErstelleAnforderung: (planzeile, auth, ansprueche, isVorab=false) => {},
    setAnforderungNeu: (prev) => {},
    anforderungData: null,
    setAnforderungData: (prev) => {},

    showPlanzeileInvisible: false,
    setShowPlanzeileInvisible: val => {},

    blisterTypes: [],
    setBlisterTypes: val=>{},

    reihenfolgeMap: {},
    setReihenfolgeMap: val => {},
};

const MedikationsplanContext = createContext(initVal);
export default MedikationsplanContext

export const useInitialState = () => {
    const [dosierabschnittSpanne, dispatchDosierabschnitte] = useReducer(dosierabschnittReducer, {anzeigeDatum: heute});

    const [medikationsplan, setMedikationsplan] = useState(null);
    const [planzeilen, setPlanzeilen] = useState(null);
    const [planzeilenMap, setPlanzeilenMap] = useState({});
    const [buendelList, setBuendelList] = useState(null);
    const [buendelMap, setBuendelMap] = useState({});
    const [ansicht, setAnsicht] = useState(ANSICHT_STANDARD);
    const [ansichten, setAnsichten] = useState([ANSICHT_STANDARD]);

    const [dosierabschnittMap, setDosierabschnittMap] = useState({});
    const [dosierabschnittAktuell, setDosierabschnittAktuellState] = useState({});
    const [dosierabschnittPrev, setDosierabschnittPrevState] = useState({});
    const [dosierabschnittNext, setDosierabschnittNextState] = useState({});
    const [dosierabschnittGeoeffnet, setDosierabschnittGeoeffnet] = useState(null);
    const [isStandardAnsicht, setIsStandardAnsicht] = useState(false);
    const [neuePlanzeile, setNeuePlanzeile] = useState(null);
    const [bestandList, setBestandList] = useState([]);
    const [internalPackageMap, setInternalPackageMap] = useState({});
    const [vorabMap, setVorabMap] = useState({});
    const [anspruchList, setAnspruchList] = useState([]);
    const [anspruchMap, setAnspruchMap] = useState({});
    const [anspruchSubstituteChecked, setAnspruchSubstituteChecked] = useState(false);
    const [reloadBestaende, setReloadBestaende] = useState(false);
    const [reloadAnsprueche, setReloadAnsprueche] = useState(false);
    const [berechneReichweiteAb, setBerechneReichweiteAb] = useState(null);
    const [berechneReichweiteBis, setBerechneReichweiteBis] = useState(null);
    const [berechneReichweiteAwaitFinish, setBerechneReichweiteAwaitFinish] = useState(false);
    const [berechneReichweiteAllePlanzeilen, setBerechneReichweiteAllePlanzeilen] = useState(false);
    const [berechneReichweitenachExport, setBerechneReichweiteNachExport] = useState(false);
    const [reichweiteWirdBerechnet, setReichweiteWirdBerechnet] = useState([]);
    const [fehlerBeiReichweitenberechnungAb, setFehlerBeiReichweitenberechnungAb] = useState(null);
    const [infoMarkerMap, setInfoMarkerMap] = useState({});
    const [planzeileSelected, setPlanzeileSelected] = useState(null);
    const [anforderungen, setAnforderungen] = useState({});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [checkAenderungsauftragAb, setCheckAenderungsauftragAb] = useState(null);
    const [blisterjobs, setBlisterjobs] = useState([]);
    const [exportStart, setExportStart] = useState(null);
    const [exportEnde, setExportEnde] = useState(null);
    const [exportFinished, setExportFinished] = useState(false);
    const [exportPending, setExportPending] = useState(false);
    const [exportingAmmendment, setExportingAmmendment] = useState(false);
    const [displayExportResult, setDisplayExportResult] = useState(false);
    const [dokumenteGueltig, setDokumenteGueltig] = useState({});
    const [verknuepfungen, setVerknuepfungen] = useState({});
    const [verknuepfungChanged, setVerknuepfungChanged] = useState(null);
    const [vorgangAktiv, setVorgangAktiv] = useState(false);
    const [reload, setReload] = useState(false);
    const [reloadVerknuepfungen, setReloadVerknuepfungen] = useState(false);

    const [zeitraumList, setZeitraumList] = useState([]);
    const [zeitraumMap, setZeitraumMapProp] = useState({});

    const [planzeilenChange, setPlanzeilenChange] = useState([]);
    const [planzeilenChangeAfterLoad, setPlanzeilenChangeAfterLoad] = useState([]);

    const [anspruecheLoaded, setAnspruecheLoaded] = useState(false);
    const [bestaendeLoaded, setBestaendeLoaded] = useState(false);
    const [zeitraeumeLoaded, setZeitraeumeLoaded] = useState(false);
    const [medikationsplanVisible, setMedikationsplanVisible] = useState(true);

    const [updatePatient, setUpdatePatientState] = useState(null);
    const [changePatient, setChangePatient] = useState([]);
    const [patientStammdatenLoaded, setPatientStammdatenLoaded] = useState(false);

    const [anzeigeDatumPrev, setAnzeigeDatumPrev] = useState(null);
    const [anzeigeDatumNext, setAnzeigeDatumNext] = useState(null);
    const [oeffneDosierabschnitt, setOeffneDosierabschnitt] = useState(null);
    const [oeffneDosierabschnittPlanzeileId, setOeffneDosierabschnittPlanzeileId] = useState(null);
    const [pauseGlobal, setPauseGlobal] = useState(null);
    const [resetGlobal, setResetGlobal] = useState(false);
    const [absetzenGlobal, setAbsetzenGlobal] = useState(false);
    const [planzeilenChecked, setPlanzeilenChecked] = useState([]);
    const [planzeilenSichtbar, setPlanzeilenSichtbar] = useState({});
    const [planzeilenVerblistert, setPlanzeilenVerblistert] = useState({});

    const [searchList, setSearchList] = useState({});

    const dosierabschnittNeuId = useRef(-1);

    const [disableCheckAenderungModal, setDisableCheckAenderungModal] = useState(false);

    const [anforderungData, setAnforderungData] = useState(null);
    const [anforderungNeu, setAnforderungNeu] = useState(null);

    const [showPlanzeileInvisible, setShowPlanzeileInvisible] = useState(false);

    const [blisterTypes, setBlisterTypes] = useState(null);
    const [reihenfolgeMap, setReihenfolgeMap] = useState({});

    useEffect(() => {
        if (planzeilen) {
            const planzeilenMapNeu = {};
            planzeilen.forEach(planzeile => planzeilenMapNeu[planzeile.id] = planzeile);
            setPlanzeilenMap(planzeilenMapNeu);
        }
    }, [planzeilen])

    useEffect(() => {
        if (buendelList) {
            const buendelMapNeu = {};
            buendelList.forEach(b => buendelMapNeu[b.id] = b);
            setBuendelMap(buendelMapNeu);
        }
    }, [buendelList])

    useEffect(() => {
        let datumPrevNeu;
        if (dosierabschnittSpanne.prev) {
            if (dosierabschnittSpanne.anzeigeDatum > dosierabschnittSpanne.prev.ende) datumPrevNeu = dosierabschnittSpanne.prev.ende;
            else datumPrevNeu = dosierabschnittSpanne.prev.start;
        } else {
            datumPrevNeu = null;
        }
        if (heute < dosierabschnittSpanne.anzeigeDatum) {
            if (!datumPrevNeu || datumPrevNeu < heute) datumPrevNeu = heute;
        }
        setAnzeigeDatumPrev(datumPrevNeu);

        let datumNextNeu;
        if (dosierabschnittSpanne.next) {
            if (dosierabschnittSpanne.anzeigeDatum < dosierabschnittSpanne.next.start) datumNextNeu = dosierabschnittSpanne.next.start;
            else datumNextNeu = dosierabschnittSpanne.next.ende;
        } else {
            datumNextNeu = null;
        }
        if (heute > dosierabschnittSpanne.anzeigeDatum) {
            if (!datumNextNeu || datumNextNeu > heute) datumNextNeu = heute;
        }
        setAnzeigeDatumNext(datumNextNeu);
    }, [dosierabschnittSpanne])

    useEffect(() => {
        if (resetGlobal) {
            setResetGlobal(false);
        }
    }, [resetGlobal])

    useEffect(() => {
        if (pauseGlobal) {
            setPauseGlobal(null);
        }
    }, [pauseGlobal])

    useEffect(() => {
        if (absetzenGlobal) {
            setAbsetzenGlobal(null);
        }
    }, [absetzenGlobal])

    useEffect(() => {
        if (anspruchList.length) {
            const anspruchMapNeu = {};
            for (let anspruch of anspruchList) {
                if (anspruch.planzeileId) {
                    if (!anspruchMapNeu[anspruch.planzeileId]) anspruchMapNeu[anspruch.planzeileId] = [];
                    anspruchMapNeu[anspruch.planzeileId].push(anspruch);
                }
            }

            setAnspruchMap(anspruchMapNeu);
        } else if (!anspruchMap || Object.keys(anspruchMap).length) {
            setAnspruchMap({});
        }
    }, [anspruchList])



    useEffect(() => {
        if (verknuepfungen && Object.keys(verknuepfungen).length) {
            const {aktuell, kommend, vergangen} = verknuepfungen;

            const dokumenteGueltigNeu = {};
            dokumenteGueltigNeu.aktuell = aktuell.map(v => v.dokument.id);
            dokumenteGueltigNeu.kommend = kommend.map(v => v.dokument.id);
            dokumenteGueltigNeu.vergangen = vergangen.map(v => v.dokument.id);
            setDokumenteGueltig(dokumenteGueltigNeu);
        } else {
            setDokumenteGueltig({});
        }
    }, [verknuepfungen])
    
    const reset = () => {
        console.log("reset medikatiosnplanContext");

        setAnzeigeDatum(moment().startOf('day'));
        setMedikationsplan(null);
        setPlanzeilen(null);
        setPlanzeilenMap({});
        setAnsicht(ANSICHT_STANDARD);
        setAnsichten([ANSICHT_STANDARD]);

        setDosierabschnittMap({});
        setDosierabschnittAktuellState({});
        setDosierabschnittPrevState({});
        setDosierabschnittNextState({});
        setDosierabschnittGeoeffnet(null);
        setIsStandardAnsicht(false);
        setNeuePlanzeile(null);
        setBestandList([]);
        setInternalPackageMap({});
        setVorabMap({});
        setAnspruchList([]);
        setAnspruchMap({});
        setAnspruchSubstituteChecked(false);
        setReloadBestaende(false);
        setReloadAnsprueche(false);
        setBerechneReichweiteAb(null);
        setBerechneReichweiteBis(null);
        setBerechneReichweiteAwaitFinish(false);
        setBerechneReichweiteAllePlanzeilen(false);
        setBerechneReichweiteNachExport(false);
        setReichweiteWirdBerechnet([]);
        setFehlerBeiReichweitenberechnungAb(null);
        setInfoMarkerMap({});
        setPlanzeileSelected(null);
        setAnforderungen({});
        setSaveButtonDisabled(true);
        setCheckAenderungsauftragAb(null);
        setBlisterjobs([]);
        setExportStart(null);
        setExportEnde(null);
        setExportFinished(false);
        setExportPending(false);
        setDokumenteGueltig({});
        setVerknuepfungen({});
        setVerknuepfungChanged(null);
        setVorgangAktiv(false);
        setReload(false);
        setReloadVerknuepfungen(false);

        setZeitraumList([]);
        setZeitraumMapProp({});

        setPlanzeilenChange([]);
        setPlanzeilenChangeAfterLoad([]);

        setAnspruecheLoaded(false);
        setBestaendeLoaded(false);
        setZeitraeumeLoaded(false);
        setMedikationsplanVisible(true);

        setUpdatePatientState(null);
        setChangePatient([]);
        setPatientStammdatenLoaded(false);

        setAnzeigeDatumPrev(null);
        setAnzeigeDatumNext(null);
        setOeffneDosierabschnitt(null);
        setOeffneDosierabschnittPlanzeileId(null);
        setPauseGlobal(null);
        setResetGlobal(false);
        setAbsetzenGlobal(false);
        setPlanzeilenChecked([]);
        setPlanzeilenSichtbar({});
        setPlanzeilenVerblistert({});

        setDisableCheckAenderungModal(false);

        setAnforderungData(null);
        setAnforderungNeu(null);

        setShowPlanzeileInvisible(false);

        setReihenfolgeMap({});

        dosierabschnittNeuId.current = -1;
    }
    
    const postDosierabschnitte = dosierabschnitte => {
        dispatchDosierabschnitte({type: "berechneSpanne", dosierabschnitte});
    }

    const setAnzeigeDatum = timestamp => {
        dispatchDosierabschnitte({type: "setAnzeigeDatum", timestamp})
    }

    const setDosierabschnittAktuell = (planzeileId, dosierabschnitt) => setDosierabschnittAktuellState(alt => {
        const neu = {...alt};
        neu[planzeileId] = dosierabschnitt;

        return neu;
    });

    const setDosierabschnittPrev = (planzeileId, dosierabschnitt) => setDosierabschnittPrevState(alt => {
        const neu = {...alt};
        neu[planzeileId] = dosierabschnitt;

        return neu;
    });

    const setDosierabschnittNext = (planzeileId, dosierabschnitt) => setDosierabschnittNextState(alt => {
        const neu = {...alt};
        neu[planzeileId] = dosierabschnitt;

        return neu;
    });

    const isPlanzeileSichtbar = (planzeileId, heuteReferenz) => {
        if (showPlanzeileInvisible) return true;

        const keinDosierschema = !planzeilenMap[planzeileId]?.dosierschema?.length;
        const aktuellerAbschnitt = dosierabschnittAktuell?.[planzeileId];
        const zukuenftigerAbschnitt = dosierabschnittNext?.[planzeileId] && (dosierabschnittNext[planzeileId].start <= dosierabschnittSpanne.anzeigeDatum || heuteReferenz.isSame(dosierabschnittSpanne.anzeigeDatum));
        const istAusgewaehlt = planzeileSelected === planzeileId;
        const keineFreigabe = !planzeilenMap[planzeileId]?.freigabe;

        return keinDosierschema || aktuellerAbschnitt || zukuenftigerAbschnitt || istAusgewaehlt || keineFreigabe;
    }

    const handleErstelleAnforderung = async (planzeile, auth, ansprueche, isVorab=false) => {
        const anforderungDataNew = await getAnforderungDataAsync(planzeile, auth, ansprueche, isVorab);
        setAnforderungData(anforderungDataNew);
    }

    const setUpdatePatient = val => {
        setUpdatePatientState(val);
        setChangePatient([]);
    }

    const setZeitraumMap = zeitraumMap => {
        setZeitraumMapProp(zeitraumMap);
        setZeitraumList([...(zeitraumMap.B || []), ...(zeitraumMap.K || []), ...(zeitraumMap.P || [])]);
        setZeitraeumeLoaded(true);
    }

    return {
        reset,
        
        medikationsplan,
        setMedikationsplan,

        planzeilen,
        planzeilenMap,
        setPlanzeilen,

        buendelList,
        buendelMap,
        setBuendelList,

        ansicht,
        setAnsicht,

        ansichten,
        setAnsichten,

        dosierabschnittSpanneNext: dosierabschnittSpanne.next,
        dosierabschnittSpannePrev: dosierabschnittSpanne.prev,
        dosierabschnittSpanneCurrent: dosierabschnittSpanne.current,
        postDosierabschnitte,

        anzeigeDatum: dosierabschnittSpanne.anzeigeDatum,
        setAnzeigeDatum,

        anzeigeDatumPrev,
        anzeigeDatumNext,

        dosierabschnittMap,
        setDosierabschnittMap,

        dosierabschnittAktuell,
        setDosierabschnittAktuell,

        dosierabschnittPrev,
        setDosierabschnittPrev,

        dosierabschnittNext,
        setDosierabschnittNext,

        dosierabschnittGeoeffnet,
        setDosierabschnittGeoeffnet,

        isStandardAnsicht,
        setIsStandardAnsicht,

        neuePlanzeile,
        setNeuePlanzeile,

        berechneReichweiteAb,
        setBerechneReichweiteAb,

        berechneReichweiteBis,
        setBerechneReichweiteBis,

        berechneReichweiteAwaitFinish,
        setBerechneReichweiteAwaitFinish,

        berechneReichweiteAllePlanzeilen,
        setBerechneReichweiteAllePlanzeilen,

        berechneReichweitenachExport,
        setBerechneReichweiteNachExport,

        fehlerBeiReichweitenberechnungAb,
        setFehlerBeiReichweitenberechnungAb,

        reichweiteWirdBerechnet,
        setReichweiteWirdBerechnet,

        bestandList,
        setBestandList,

        zeitraumList,
        zeitraumMap,
        setZeitraumMap,

        internalPackageMap,
        setInternalPackageMap,

        vorabMap,
        setVorabMap,

        anspruchList,
        setAnspruchList,
        anspruchSubstituteChecked,
        setAnspruchSubstituteChecked,
        anspruchMap,

        reloadBestaende,
        setReloadBestaende,

        reloadAnsprueche,
        setReloadAnsprueche,

        reloadVerknuepfungen,
        setReloadVerknuepfungen,

        infoMarkerMap,
        setInfoMarkerMap,

        planzeileSelected,
        setPlanzeileSelected,

        anforderungen,
        setAnforderungen,

        saveButtonDisabled,
        setSaveButtonDisabled,

        checkAenderungsauftragAb,
        setCheckAenderungsauftragAb,

        blisterjobs,
        setBlisterjobs,

        exportStart,
        setExportStart,

        exportEnde,
        setExportEnde,

        exportFinished,
        setExportFinished,

        exportPending,
        setExportPending,

        exportingAmmendment,
        setExportingAmmendment,

        displayExportResult,
        setDisplayExportResult,

        dokumenteGueltig,

        verknuepfungen,
        setVerknuepfungen,

        verknuepfungChanged,
        setVerknuepfungChanged,

        vorgangAktiv,
        setVorgangAktiv,

        reload,
        setReload,

        planzeilenChange,
        setPlanzeilenChange,

        planzeilenChangeAfterLoad,
        setPlanzeilenChangeAfterLoad,

        anspruecheLoaded,
        setAnspruecheLoaded,

        bestaendeLoaded,
        setBestaendeLoaded,

        zeitraeumeLoaded,
        setZeitraeumeLoaded,

        medikationsplanVisible,
        setMedikationsplanVisible,

        updatePatient,
        setUpdatePatient,

        changePatient,
        setChangePatient,

        patientStammdatenLoaded,
        setPatientStammdatenLoaded,

        oeffneDosierabschnitt,
        setOeffneDosierabschnitt,

        oeffneDosierabschnittPlanzeileId,
        setOeffneDosierabschnittPlanzeileId,

        pauseGlobal,
        setPauseGlobal,

        resetGlobal,
        setResetGlobal,

        absetzenGlobal,
        setAbsetzenGlobal,

        planzeilenChecked,
        setPlanzeilenChecked,

        planzeilenSichtbar,
        setPlanzeilenSichtbar,

        planzeilenVerblistert,
        setPlanzeilenVerblistert,

        searchList,
        setSearchList,

        dosierabschnittNeuId,

        disableCheckAenderungModal,
        setDisableCheckAenderungModal,

        isPlanzeileSichtbar,

        handleErstelleAnforderung,
        setAnforderungNeu,
        anforderungData,
        setAnforderungData,

        showPlanzeileInvisible,
        setShowPlanzeileInvisible,

        blisterTypes,
        setBlisterTypes,

        reihenfolgeMap,
        setReihenfolgeMap,
    }
};

const dosierabschnittReducer = (prev, action) => {
    let state = {...prev};

    switch (action.type) {
        case "setAnzeigeDatum":
            if (state.anzeigeDatum && state.anzeigeDatum === action.timestamp) break;

            delete state.prev;
            delete state.current;
            delete state.next;

            state.anzeigeDatum = action.timestamp;

            break;
        case "berechneSpanne":
            const dosierabschnitte = action.dosierabschnitte;
            const anzeigeDatum = moment(action.anzeigeDatum ? action.anzeigeDatum : state.anzeigeDatum).startOf('day');

            let startCurrent = state.current ? moment(state.current.start) : null;
            let endeCurrent = state.current && state.current.ende ? moment(state.current.ende) : null;

            let startPrev = state.prev ? moment(state.prev.start) : null;
            let endeNext = state.next && state.next.ende ? moment(state.next.ende) : null;


            let start, ende;
            for (let abschnitt of dosierabschnitte) {
                start = moment(abschnitt.start).startOf('day');
                ende = abschnitt.ende ? moment(abschnitt.ende) : null;

                // Verarbeite Abschnitt-Start
                if (anzeigeDatum.isBefore(start)) {
                    if (!endeCurrent || start.isBefore(endeCurrent)) {
                        endeNext = endeCurrent;
                        endeCurrent = start.clone().subtract(1, 'day');
                    } else if (endeCurrent.isBefore(start)) {
                        if (!endeNext || start.isBefore(endeNext)) endeNext = start;
                    }
                } else {
                    if (!startCurrent) {
                        startCurrent = start;
                    }

                    if (start.isBefore(startCurrent)) {
                        if (!startPrev || startPrev.isBefore(start)) startPrev = start;
                    } else if (startCurrent.isBefore(start)) {
                        if (!endeCurrent || (start.isBefore(endeCurrent) && !start.isAfter(anzeigeDatum))) {
                            startPrev = startCurrent;
                            startCurrent = start;
                        } else if (endeCurrent.isBefore(start)) {
                            if (!endeNext || start.isBefore(endeNext)) endeNext = start;
                        }
                    }
                }

                // Verarbeite Abschnitt-Ende
                if (startCurrent && ende) {
                    if (ende.isBefore(anzeigeDatum)) {
                        if (startCurrent.isBefore(ende)) {
                            startPrev = startCurrent;
                            startCurrent = ende.clone().add(1, 'day');
                        } else if (ende.isBefore(startCurrent)) {
                            if (!startPrev || startPrev.isBefore(ende)) startPrev = ende;
                        }
                    } else {
                        if (ende.isBefore(startCurrent)) {
                            if (!startPrev || startPrev.isBefore(ende)) startPrev = ende;
                        } else if (startCurrent.isBefore(ende)) {
                            if (!endeCurrent) {
                                endeCurrent = ende;
                            } else if (ende.isBefore(endeCurrent)) {
                                endeNext = endeCurrent;
                                endeCurrent = ende;
                            } else if (endeCurrent.isBefore(ende)) {
                                if (!endeNext || ende.isBefore(endeNext)) endeNext = ende;
                            }
                        }
                    }
                }
            }

            // Schreibe Dosierabschnitt-Spannen in Context
            if (!startCurrent) startCurrent = moment(0);
            if (!state.current || state.current.start !== startCurrent?.valueOf() || state.current.ende !== endeCurrent?.valueOf()) {
                state.current = {start: startCurrent.valueOf(), ende: endeCurrent ? endeCurrent.valueOf() : null};

                // Setze Prev und Next, weil Current verändert
                if (startPrev) {
                    state.prev = {
                        start: startPrev.valueOf(),
                        ende: startCurrent.clone().subtract(1, 'day').valueOf()
                    };
                } else {
                    state.prev = null;
                }
                if (endeNext && endeCurrent && endeNext.isAfter(endeCurrent)) {
                    state.next = {start: endeCurrent.clone().add(1, 'day').valueOf(), ende: endeNext.valueOf()};
                } else if (endeCurrent) {
                    state.next = {start: endeCurrent.clone().add(1, 'day').valueOf()};
                } else {
                    state.next = null;
                }
            } else {

                // Prüfe Prev auf Veränderungen
                if (!startPrev) state.prev = null;
                else if (!state.prev || state.prev.start !== startPrev.valueOf()) {
                    state.prev = {
                        start: startPrev.valueOf(),
                        ende: startCurrent.clone().subtract(1, 'day').valueOf()
                    };
                }

                // Prüfe Next auf Veränderungen
                if (!endeCurrent) state.next = null;
                else {
                    const startNextValue = endeCurrent.clone().add(1, 'day').valueOf();

                    if (!state.next || state.next.start !== startNextValue) {
                        state.next = {start: startNextValue, ende: endeNext?.valueOf() || null};
                    }
                }
            }
    }

    return state;
}