import React from 'react'

import {Button, Radio} from 'antd';
import {SwapOutlined} from '@ant-design/icons';
import RadioGroup from "antd/es/radio/group";


export const InputBoolean = ({ value, label = "", onChange = (e, l) => { }, wahr, falsch, disabled = false }) => {

    return (
        <div>
            <RadioGroup defaultValue={value} onChange={(e) => {
                onChange(e.target.value, label);
            }}>
                <Radio.Button value={true}>{wahr}</Radio.Button>
                <Radio.Button value={false}>{falsch}</Radio.Button>
            </RadioGroup>
        </div>
    )

}
