import React from 'react'

import { getUnitLangname } from './../../config/entities';
import { Link } from 'react-router-dom';


export const Verordner = ({ value, freitext, hausarzt = null }) => {

    if (!value && hausarzt)
        return (<div>
            <p><Link to={'/adressbuch/' + hausarzt.id}>{getUnitLangname(hausarzt, "arzt")}</Link> <i>(Hausarzt)</i></p>
        </div>)


    if (value && hausarzt && hausarzt.id == value.id)
        return (<div>
            <p><Link to={'/adressbuch/' + value.id}>{getUnitLangname(hausarzt, "arzt")}</Link> <i>(Verordner & Hausarzt)</i></p>
        </div>)

    if (value)
        return (<div>
            <p><Link to={'/adressbuch/' + value.id}>{getUnitLangname(value, "arzt")}</Link> <i>(Verordner)</i></p>
        </div>)

    if (freitext)
        return (<div>
            <p>{JSON.stringify(freitext)}</p>
        </div>)

    return <div>Kein Verordner angegeben</div>
}