import React, {useEffect, useRef, useState} from 'react'
import { useSchnellsuche } from '../../utilities/useSchnellsuche'
import {Table, Row, Col, Button, Select, Input} from 'antd';
import { arzneimittelApi } from '../../config/apiConfig'
import usePagination from "../../utilities/usePagination";
import {getMedicineM2Name} from "../../utilities/medicineM2Util";
import {useApi} from "../../utilities/useApi";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";

const { Option } = Select;


export const getArzneimittelLangname = (arzneimittel = null, freitext = "") => {
    if (!arzneimittel) return freitext;
    let langname = arzneimittel.langname;
    return langname !== "" ? langname : freitext;
}


export const ArzneimittelPicker = ({ value=null, onChange=async ()=>{}, freitext=null, handleFreitext=null, entitlementset=null, syncValue=false, schnellauswahl=false, setSchnellsucheRef=null, autofocus=true, resetTrigger=[false, () => {}], resetTriggerAfterChange=false }) => {
    const auth = useAuth();

    const [freitextProp, setFreitext] = useState(freitext)
    const [arzneimittel, setArzneimittel] = useState(value)
    const [entitlementSorted, setEntitlementSorted] = useState(null);

    const changeTriggered = useRef(false);

    const schnellsuche = useSchnellsuche({
        placeholder: getArzneimittelLangname(value, freitextProp),
        searchAfterChar: 3,
        searchAfterDelay: 500,
        getUrlBySearchText: arzneimittelApi.find,
        defaultResultByValue: getArzneimittelLangname(value, freitextProp),
        filterSearchText: searchText => {
            const searchTextFiltered = searchText.replace(/^[\s0]*(.*)$/, "$1");
            return searchTextFiltered;
        },
    })

    if (setSchnellsucheRef) setSchnellsucheRef.current = schnellsuche.setValue;

    useEffect(() => {
        if (syncValue) {
            setArzneimittel(value);
            schnellsuche.setValue(value ? getArzneimittelLangname(value) : "");

            if (!value) schnellsuche.reset();
            else if (value !== arzneimittel) schnellsuche.triggersearch();
        }
    }, [value])

    useEffect(() => {
        if (entitlementset) setEntitlementSorted(entitlementset.filter(e => e.amount !== 0).sort((a, b) => getMedicineM2Name(a.id.medicineM2).localeCompare(getMedicineM2Name(b.id.medicineM2))));
        else setEntitlementSorted(null);
    }, [entitlementset])

    useEffect(() => {
        if (schnellsuche.value) setFreitext(schnellsuche.value);
    }, [schnellsuche.value])

    useEffect(() => {
        if (resetTrigger[0]) {
            changeTriggered.current = false;
            if (resetTrigger[1]) resetTrigger[1]();
        }
    }, [resetTrigger[0]])

    const handleChange = async arzneimittel => {
        if (changeTriggered.current) return;
        changeTriggered.current = true;

        setArzneimittel(arzneimittel)
        await onChange(arzneimittel);

        if (resetTriggerAfterChange) {
            changeTriggered.current = false;
        }
    }


    return <div>

        <Row>
            <Col span={14}>
                {arzneimittel ? <div>
                    {/*<button onClick={() => onChange(arzneimittel)}>Arzneimittel übernehmen:</button>*/}
                    <h4>{arzneimittel.name} {arzneimittel.drf}</h4>
                    <h6>{arzneimittel.hersteller}</h6>
                    <h6>PZN: {("00000000"+arzneimittel.pzn).slice(-8)}</h6>
                    <h6>M2: {arzneimittel.m2}</h6>
                </div> : <div>
                    <h4>Kein Arzneimittel ausgewählt</h4>
                </div>}

                {entitlementSorted && <div style={{marginTop: 20, marginBottom: 10}}>
                    <h6>Ansprüche des Patienten</h6>
                    <Select style={{width: 300}} onChange={index => {
                        schnellsuche.handleselect(entitlementSorted[index]?.id?.medicineM2?.m2, []);
                        schnellsuche.triggersearch();
                    }}>
                        {entitlementSorted.map((entitlement, i) => <Option key={i} value={i}>{getMedicineM2Name(entitlement.id.medicineM2)}</Option>)}
                    </Select>
                </div>}
            </Col>
            {handleFreitext && <Col span={8}>
                <h6>Freitext</h6>
                <Input value={freitextProp} onChange={e => setFreitext(e.target.value)} />
                <Button onClick={() => handleFreitext(freitextProp)}>Nur den Freitext verwenden</Button>
            </Col>}
        </Row>

        <h6>Suche:</h6>
        <Input {...schnellsuche.inputProps} loading={schnellsuche.loading.toString()} handleselect="" autoFocus={autofocus} />
        {/*<Button onClick={() => schnellsuche.triggersearch()} >Suche starten</Button>*/}

        <ArzneimittelPickerResult {...schnellsuche} {...schnellsuche.inputProps} onChange={handleChange} />


    </div>

}


const ArzneimittelPickerResult = ({ result, loading, error, onChange }) => {
    const [drfList, setDrfList] = useState([]);
    const [stueckzahlList, setStueckzahlList] = useState([]);
    const [herstellerList, setHerstellerList] = useState([]);
    const [normgroesseList, setNormgroesseList] = useState([]);
    const [vertriebsstatusList, setVertriebsstatusList] = useState([]);

    const pagination = usePagination({pageSize: 20});

    useEffect(() => {
        if (result) {
            const drfSet = new Set();
            const stueckzahlSet = new Set();
            const herstellerSet = new Set();
            const normgroesseSet = new Set();
            const vertriebsstatusSet = new Set();

            result.forEach(arzneimittel => {
                drfSet.add(arzneimittel.drf);
                stueckzahlSet.add(`${arzneimittel.menge} ${arzneimittel.einheit}`);
                herstellerSet.add(arzneimittel.hersteller);
                normgroesseSet.add(arzneimittel.normgroesse);
                vertriebsstatusSet.add(arzneimittel.vertriebsstatus);
            })

            const sorter = (a, b) => a.localeCompare(b);

            setDrfList(Array.from(drfSet).sort(sorter));
            setStueckzahlList(Array.from(stueckzahlSet).sort(sorter));
            setHerstellerList(Array.from(herstellerSet).sort(sorter));
            setNormgroesseList(Array.from(normgroesseSet).sort(sorter));
            setVertriebsstatusList(Array.from(vertriebsstatusSet).sort(sorter));
        } else {
            setDrfList([]);
        }
    }, [result])

    if (error)
        return <div>Fehler: {JSON.stringify(error, null, 2)}</div>

    if (!result)
        return <div />

    const columns = [
        {
            title: 'Name',
            dataIndex: 'langname',
            render: text => <i onClick={() => { }}>{text}</i>,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'DRF',
            dataIndex: 'drf',
            sorter: (a, b) => a.drf.localeCompare(b.drf),
            filters: drfList.map(drfString => {
                return {
                    text: drfString,
                    value: drfString,
                }
            }),
            onFilter: (drfFilter, arzneimittel) => {
                return arzneimittel.drf === drfFilter;
            },
        },
        {
            title: "Stückzahl",
            key: "menge",
            render: arzneimittel => `${arzneimittel.menge} ${arzneimittel.einheit}`,
            sorter: (a, b) => {
                return `${a.menge} ${a.einheit}`.localeCompare(`${b.menge} ${b.einheit}`);
            },
            filters: stueckzahlList.map(val => {
                return {
                    text: val,
                    value: val,
                }
            }),
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.menge === filterVal;
            },
        },
        {
            title: 'Hersteller',
            dataIndex: 'hersteller',
            sorter: (a, b) => a.hersteller.localeCompare(b.hersteller),
            filters: herstellerList.map(val => {
                return {
                    text: val,
                    value: val,
                }
            }),
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.hersteller === filterVal;
            },
        },
        {
            title: 'PZN',
            dataIndex: 'pzn',
            sorter: (a, b) => a.pzn - b.pzn,
            render: (text, record) => ("00000000"+text).slice(-8)
        },
        {
            title: 'M2',
            dataIndex: 'm2',
            sorter: (a, b) => a.m2 - b.m2
        },
        {
            title: 'NG',
            dataIndex: 'normgroesse',
            sorter: (a, b) => a.normgroesse.localeCompare(b.normgroesse),
            sortDirections: ['descend', 'ascend'],
            filters: normgroesseList.map(val => {
                return {
                    text: val,
                    value: val,
                }
            }),
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.normgroesse === filterVal;
            },
        },
        {
            title: 'Vertriebsstatus',
            dataIndex: 'vertriebsstatus',
            sorter: (a, b) => a.vertriebsstatus.localeCompare(b.vertriebsstatus),
            sortDirections: ['ascend', 'descend'],
            filters: vertriebsstatusList.map(val => {
                return {
                    text: val,
                    value: val,
                }
            }),
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.vertriebsstatus === filterVal;
            },
        },
        {
            title: 'Klinikpackung',
            dataIndex: 'klinikpackung',
            sorter: (a, b) => {
                return a.klinikpackung - b.klinikpackung;
            },
            sortDirections: ['ascend', 'descend'],
            filters: [{text: "Ja", value: true}, {text: "Nein", value: false}],
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.klinikpackung === filterVal;
            },
            render: (val, record) => {
                return val ? "Ja" : "Nein";
            },
        },
        {
            title: 'Import',
            dataIndex: 'importArzneimittel',
            sorter: (a, b) => {
                return a.importArzneimittel - b.importArzneimittel;
            },
            sortDirections: ['ascend', 'descend'],
            filters: [{text: "importiert", value: true}, {text: "nicht importiert", value: false}],
            onFilter: (filterVal, arzneimittel) => {
                return arzneimittel.importArzneimittel === filterVal;
            },
            render: (val, record) => {
                return val ? "Ja" : "Nein";
            },
        }
    ];

    return <Table
        onRow={(record, rowIndex) => {
            const style = {};

            if (record.vertriebsstatus === 'KEINE_ANGABE') {
                style.fontStyle = 'italic';
            } else if (record.vertriebsstatus !== 'IM_VERTRIEB') {
                style.color = 'orange';
            }

            // ist Klinikpackung
            if (record.klinikpackung) {
                style.color = 'red';
            }

            return {
                onClick: e => {
                    onChange(record);
                },
                style
            };
        }}
        pagination={pagination}

        loading={loading}
        columns={columns}
        dataSource={result}
        style={{cursor: "pointer"}}
        showSorterTooltip={false}
        rowKey={"id"}
    />
}

export const ArzneimittelPopoverData = ({arzneimittel}) => {
    return (
        <table>
            <tbody>
                <tr>
                    <th colSpan="2">{getArzneimittelLangname(arzneimittel)}</th>
                </tr>
                <tr>
                    <td>PZN:</td>
                    <td>{("00000000"+arzneimittel.pzn).slice(-8)}</td>
                </tr>
                <tr>
                    <td>M2:</td>
                    <td>{arzneimittel.m2}</td>
                </tr>
                <tr>
                    <td>Hersteller:</td>
                    <td>{arzneimittel.hersteller}</td>
                </tr>
                <tr>
                    <td>DRF:</td>
                    <td>{arzneimittel.drf}</td>
                </tr>
                <tr>
                    <td>Inhalt:</td>
                    <td>{arzneimittel.menge} {arzneimittel.einheit}</td>
                </tr>
                {arzneimittel.zusammenstellung != arzneimittel.menge &&<tr>
                    <td>Zusammenstellung:</td>
                    <td>{arzneimittel.zusammenstellung} {arzneimittel.einheit}</td>
                </tr>}
                {arzneimittel.normgroesse && <tr>
                    <td>Normgröße:</td>
                    <td>{arzneimittel.normgroesse}</td>
                </tr>}
                <tr>
                    <td>Vertriebsstatus:</td>
                    <td>{arzneimittel.vertriebsstatus}</td>
                </tr>
            </tbody>
        </table>
    )
}