import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {StatusDisplay, useDataNode} from '../../utilities/useDataNode';

import {planzeilenNode} from '../../config/dataNodeConfiguration';

import locale from 'antd/es/date-picker/locale/de_DE';
import {Button, Checkbox, Col, DatePicker, Modal, notification, Row, Select} from 'antd';

import {MedikationsplanZeile} from '../molecules/MedikationsplanZeile';
import {postInfomarker, postSimuliereAbgabeDatum} from '../../config/postApiConfiguration';
import {InputDatum} from "../atoms/InputDatum";
import moment from "moment";
import {useFetch} from "../../utilities/useFetch";
import {fetchSimulationsBeginn} from "../../config/fetchApiConfiguration";
import CalculatorOutlined from "@ant-design/icons/lib/icons/CalculatorOutlined";
import {callApiAsync} from "../../utilities/apiUtil";
import {useAuth} from "../../utilities/useAuth";
import {interaktionApi, medikationsplanApi, planzeilenApi} from "../../config/apiConfig";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import MedikationsplanContext from "../../contexts/MedikationsplanContext";
import {ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import {heute} from "../../utilities/dosierschemaUtil";
import DokumenteContext from "../../contexts/DokumenteContext";
import {getPostBody} from "../atoms/InfomarkerButton";
import {getArzneimittelLangname} from "../atoms/Arzneimittel";
import {boundsType, getViewportBoundsType, scrollElementIntoViewport} from "../../utilities/viewportUtil";
import {LabeledSwitch} from "../atoms/LabeledSwitch";
import {Tooltip} from "../atoms/Tooltip";
import {getSorter} from "../../utilities/sortUtil";
import InteraktionenContext from "../../contexts/InteraktionenContext";
import {Popover} from "../atoms/Popover";
import {useOnClickOutside} from "../../utilities/useOnClickOutside";
import GlobalHotkeyContext from "../../contexts/GlobalHotkeyContext";
import queryString from "query-string";
import {useApi} from "../../utilities/useApi";
import {RezeptanforderungModal} from "../molecules/RezeptanforderungModal";
import {GlobalContext} from "../../config/globalContext";

const {Option} = Select;


// todo gehört in ../styles/
const stylePlanzeileHeader = {color: "#006B3A", backgroundColor: "#86ADC8", paddingTop: 5, paddingLeft: 5}

export const ANSICHT_STANDARD = "Standard";
export const ANSICHT_FAVORITEN = "Alphabetisch";
export const ANSICHT_KONTROLLE = "Blisterkontrolle";

export const MedikationsplanZeilen = ({medikationsplan, blisterStartTag, onChange, triggerSave, setTriggerSave, triggerNew, setTriggerNew, hausarzt = null, childrenUpdated=[], setChildUpdated, setChildSaved, setDisableNeueZeile, ansicht: ansichtParam=null, updated}) => {

    const auth = useAuth();
    const api = useApi();

    const history = useHistory();
    const locationLastStringified = useRef(null);

    const globalContext = useContext(GlobalContext);
    const medikationsplanContext = useContext(MedikationsplanContext);
    const dokumenteContext = useContext(DokumenteContext);
    const interaktionenContext = useContext(InteraktionenContext);
    const globalHotkeyContext = useContext(GlobalHotkeyContext);

    const planzeilenLast = useRef([]);
    const onSaveResolve = useRef({});

    const [abschnittFilter, setAbschnittFilter] = React.useState("aktuell")
    const [reportIndex, setReportIndex] = React.useState(0)

    const [ansichten, setAnsichten] = React.useState([ANSICHT_STANDARD]);
    const [ansicht, setAnsicht] = React.useState(ansichtParam || ANSICHT_STANDARD);

    const [ansichtNeu, setAnsichtNeu] = React.useState(null);
    const [showInputAnsichtNeu, setShowInputAnsichtNeu] = React.useState(false);
    const [inputAnsichtNeuValue, setInputAnsichtNeuValue] = React.useState("");

    const [ansichtPlanzeilen, setAnsichtPlanzeilen] = React.useState(null);
    const [anzeigePlanzeilen, setAnzeigePlanzeilen] = React.useState(null);
    const [reihenfolge, setReihenfolge] = React.useState(null);
    const [reihenfolgeIdx, setReihenfolgeIdx] = React.useState(null);
    const [reihenfolgeChanged, setReihenfolgeChanged] = React.useState({});
    const [reihenfolgeDB, setReihenfolgeDB] = React.useState({});

    const [dragPlanzeileId, setDragPlanzeileId] = React.useState(-2);
    const [dropPlanzeileId, setDropPlanzeileId] = React.useState(-2);

    const [scrollToReihenfolgeIndex, setScrollToReihenfolgeIndex] = React.useState(null);

    const [openEditor, setOpenEditor] = useState({});

    const [simulationStart, setSimulationStart] = useState(null)

    const [showAnzeigeDatumPicker, setShowAnzeigeDatumPicker] = useState(false);

    const [reloadPlanzeilen, setReloadPlanzeilen] = useState(false);

    const [kontrollModus, setKontrollModus] = useState(false);

    const [showReichweiteModal, setShowReichweiteModal] = useState(false);
    const [planzeileIdListZurNeuberechnung, setPlanzeileIdListZurNeuberechnung] = useState([]);
    const [skipPlanzeileIdListZurNeuberechnung, setSkipPlanzeileIdListZurNeuberechnung] = useState([]);

    const planzeileIdListZurNeuberechnungRef = useRef([]);

    const [scrollToPlanzeileTrigger, setScrollToPlanzeileTrigger] = useState(null);
    const [triggerScroll, setTriggerScroll] = useState(false);
    const scrollToElement = useRef(null);
    const scrollDirection = useRef(0);
    const reportIndexLast = useRef(null);
    const reihenfolgeLast = useRef(null);

    const handleSaveTimeout = useRef(null);

    const anzeigeDatumRef = useRef();

    const reihenfolgeChangedLastJsonString = useRef(null);
    const reihenfolgeChangeBeingSaved = useRef(new Set());
    const reihenfolgeChangeToBeSavedAgain = useRef({});

    const [triggerAnspruchReloadAfterSave, setTriggerAnspruchReloadAfterSave] = useState(false);
    const [triggerBestandReloadAfterSave, setTriggerBestandReloadAfterSave] = useState(false);

    const [disablePlanzeileFreigebenButton, setDisablePlanzeileFreigebenButton] = useState(true);
    const [triggerPlanzeilenFreigeben, setTriggerPlanzeilenFreigeben] = useState(false);

    const planzeilenChanging = useRef([]);
    const [checkSaveAfterChange, setCheckSaveAfterChange] = useState(false);
    const [planzeilenToSaveAfterChange, setPlanzeilenToSaveAfterChange] = useState([]);

    const [interaktionenLoading, setInteraktionenLoading] = useState(false);

    const [freigabeMitDosierabschnitten, setFreigabeMitDosierabschnitten] = useState(true);
    const [freigabePopoverVisible, setFreigabePopoverVisible] = useState(false);

    const [medikationsplanPause, setMedikationsplanPause] = useState({start: moment().startOf('day').valueOf()});
    const [datumAbsetzung, setDatumAbsetzung] = useState(moment().endOf('day'));

    const reihenfolgeNeuIndexRef = useRef(null);
    const [movePlanzeileAfterSave, setMovePlanzeileAfterSave] = useState(null);

    const skipVerarbeitePlanzeilenRef = useRef(false);

    const reihenfolgeIndexMap = useRef({}); // Daten aus Ansicht
    const reihenfolgePlanzeileIdMap = useRef({}); // Daten aus Ansicht
    const reihenfolgeGesaeubert = useRef(null);

    const reportOptions = [
        {
            name: "manuell",
            handsortiert: true,
            sort: (e1, e2) => {
                const reihenfolgeE1 = "reihenfolge" in (reihenfolge?.[e1.id] || {}) ? reihenfolge[e1.id].reihenfolge : 99999;
                const reihenfolgeE2 = "reihenfolge" in (reihenfolge?.[e2.id] || {}) ? reihenfolge[e2.id].reihenfolge : 99999;

               return reihenfolgeE1 - reihenfolgeE2;
            }
        },
        {
            name: "alphabetisch",
            sort: (e1, e2) => {
                if (!e1.arzneimittel || !e1.arzneimittel.langname) return -1
                if (!e2.arzneimittel || !e2.arzneimittel.langname) return 1
                //console.log("comparing ", e1.arzneimittel.langname, e2.arzneimittel.langname)
                return (e1.arzneimittel.langname < e2.arzneimittel.langname) ? -1 : 1;
            }
        },
        {
            name: "none",
            sort: () => 0
        }
    ]

    const {
        current: planzeilen,
        status,
        handleChange,
        handleChangeMultiple,
        handleLoad,
        handleSave,
        handleNew,
        updated: updatedPlanzeilen,
        setTriggerSave: setTriggerSaveDataNode,
        savedAfterTrigger,
        deletedResponseMap,
    } = useDataNode({
        dataNode: planzeilenNode,
        // value: medikationsplanContext.planzeilen,
        isCollection: true,
        key: medikationsplan.id,
        // cacheSlice: "planzeilen",
        onChange,
    });

    // Abgabe simulieren und Reichweite berechnen
    const simulationsBeginn = useFetch(fetchSimulationsBeginn(medikationsplan.id))

    useEffect(() => {
        let locationStringified = history.location.pathname + history.location.search;
        if (!locationLastStringified.current || locationLastStringified.current !== locationStringified) {
            const query = queryString.parse(history.location.search);
            let replaceHistory = false;

            if (query.datum) {
                const momentDatum = moment(query.datum);
                medikationsplanContext.setAnzeigeDatum(momentDatum.valueOf());
            }

            if (query.ansicht) {
                changeAnsicht(query.ansicht);
            }

            if (query.planzeile) {
                const planzeileId = parseInt(query.planzeile);
                delete query.planzeile;
                replaceHistory = true;

                if (!isNaN(planzeileId)) {
                    medikationsplanContext.setPlanzeileSelected(planzeileId)
                }
            }

            if (replaceHistory) {
                locationStringified = queryString.stringifyUrl({url: history.location.pathname, query});
                locationLastStringified.current = locationStringified;
                history.replace(locationStringified);
            } else {
                locationLastStringified.current = locationStringified;
            }
        }
    }, [history.location])

    useEffect(() => {
        if (medikationsplanContext.planzeileSelected) {
            const query = queryString.parse(history.location.search);

            if (medikationsplanContext.planzeilenMap && query.dosierabschnitt) {
                const dosierabschnitt = parseInt(query.dosierabschnitt);
                const abschnitt = medikationsplanContext.planzeilenMap[medikationsplanContext.planzeileSelected]?.dosierschema.find(a => dosierabschnitt == a.id);
                const momentAnzeigeDatum = moment(medikationsplanContext.anzeigeDatum);

                if (abschnitt && momentAnzeigeDatum) {
                    if (abschnitt.start && momentAnzeigeDatum.isBefore(abschnitt.start)) {
                        medikationsplanContext.setAnzeigeDatum(abschnitt.start);
                    } else if (abschnitt.ende && momentAnzeigeDatum.isAfter(abschnitt.ende)) {
                        medikationsplanContext.setAnzeigeDatum(abschnitt.ende);
                    }
                }

                delete query.dosierabschnitt;
                history.replace(queryString.stringifyUrl({url: history.location.pathname, query}));
            }
        }
    }, [history.location, medikationsplanContext.planzeileSelected, medikationsplanContext.planzeilenMap])

    useEffect(() => {
        if (planzeilen) {
            console.log("setze Planzeilen:", planzeilen);
            medikationsplanContext.setPlanzeilen(planzeilen);
        }
    }, [planzeilen])

    useEffect(() => {
        if (skipPlanzeileIdListZurNeuberechnung.length) {
            verarbeitePlanzeilen(medikationsplanContext.planzeilen);
        }
    }, [skipPlanzeileIdListZurNeuberechnung])

    useEffect(() => {
        if (Object.keys(deletedResponseMap).length) {
            let anzahlAnforderungen = 0;
            for (let response of Object.values(deletedResponseMap)) {
                anzahlAnforderungen += response.data.OBJECT?.length;
            }

            if (anzahlAnforderungen > 0) notification.info({message: anzahlAnforderungen === 1 ? 'Es existiert eine Anforderung, die den gelöschten Planzeilen zugeordnet ist.' : `Es existieren ${anzahlAnforderungen} Anforderungen, die den gelöschten Planzeilen zugeordnet sind.`});
        }
    }, [deletedResponseMap])

    useEffect(() => {
        if (checkSaveAfterChange) for (let i=0; i<planzeilenToSaveAfterChange.length; i++) {
            const planzeileId = planzeilenToSaveAfterChange[i];
            if (!planzeilenChanging.current.includes(planzeileId)) {
                setPlanzeilenToSaveAfterChange(prev => [...prev.filter(id => id !== planzeileId)]);
                (async () => {
                    const result = await onSave(planzeileId);

                    if (onSaveResolve.current[planzeileId]) {
                        onSaveResolve.current[planzeileId](result);
                        delete onSaveResolve.current[planzeileId];
                    }
                })();
            }
        }

        setCheckSaveAfterChange(false);
    }, [planzeilenToSaveAfterChange, checkSaveAfterChange])

    useEffect(() => {
        if (triggerPlanzeilenFreigeben) {
            medikationsplanContext.setDisableCheckAenderungModal(true);
            setDisablePlanzeileFreigebenButton(true);
            if (!childrenUpdated.length) {
                (async () => {
                    await handleBerechneReichweite(medikationsplanContext.planzeilenChecked, null, null, true);
                    setTriggerPlanzeilenFreigeben(false);
                    medikationsplanContext.setDisableCheckAenderungModal(false);
                })();
            }
        }
    }, [triggerPlanzeilenFreigeben, childrenUpdated])

    useEffect(() => {
        const scrollHandler = (e) => {
            if (scrollToElement.current && scrollDirection.current !== 0) {
                const currentBoundsType = getViewportBoundsType(scrollToElement.current.element);
                if ([boundsType.full, boundsType.top].includes(currentBoundsType)) {
                    scrollToElement.current = null;
                    scrollDirection.current = 0;
                }
            }
        };

        window.addEventListener("scroll", scrollHandler);
        window.addEventListener("wheel", scrollHandler);
        window.addEventListener("touchmove", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("wheel", scrollHandler);
            window.removeEventListener("touchmove", scrollHandler);
        }
    }, [])

    useEffect(() => {
        if (triggerScroll && scrollToElement.current) {
            setTriggerScroll(false);
            scrollElementIntoViewport(scrollToElement.current.element, scrollToElement.current.boundsType || boundsType.top);
        }
    }, [triggerScroll, scrollToElement.current])

    useEffect(() => {
        const reihenfolgeChangedJsonString = JSON.stringify(reihenfolgeChanged);
        if (reihenfolgeChangedLastJsonString.current === reihenfolgeChangedJsonString) {
            return;
        }

        reihenfolgeChangedLastJsonString.current = reihenfolgeChangedJsonString;
        const keys = Object.keys(reihenfolgeChanged);

        // Eine neue Ansicht wurde angelegt
        if (!keys.length && ansichtNeu) {
            setAnsichtNeu(null);
            setAnsichten([...ansichten, ansichtNeu]);
            changeAnsicht(ansichtNeu);
        }

        // Eine bestehende Ansicht wurde bearbeitet
        else {
            const reihenfolgeChangedNeu = {...reihenfolgeChanged};
            let reihenfolgeChangedUpdated = false;
            const promises = [];
            for (let planzeileId of keys) {
                planzeileId = parseInt(planzeileId);
                if (reihenfolgeChangedNeu[planzeileId].triggerSave) {
                    delete reihenfolgeChangedNeu[planzeileId].triggerSave;
                    reihenfolgeChangedUpdated = true;

                    if (!reihenfolgeChangeBeingSaved.current.has(planzeileId)) {
                        reihenfolgeChangeBeingSaved.current.add(planzeileId);
                        promises.push(new Promise(async resolve => {
                            resolve([planzeileId, await onSaveReihenfolgeChangedTriggered(planzeileId, true)])
                        }));
                    } else {
                        reihenfolgeChangeToBeSavedAgain.current[planzeileId] = reihenfolgeChangedNeu[planzeileId];
                    }
                }
            }

            if (promises.length) {
                // setReihenfolgeChanged(reihenfolgeChangedNeu);

                Promise.all(promises).then(results => {
                    setReihenfolgeDB(prev => {
                        const neu = {...prev};

                        for (let result of results) {
                            neu[result[0]] = result[1];
                        }

                        return neu;
                    });

                    setReihenfolgeChanged(prev => {
                        const neu = {...prev};
                        for (let result of results) {
                            if (reihenfolgeChangeToBeSavedAgain.current[result[0]]) {
                                neu[result[0]] = {...reihenfolgeChangeToBeSavedAgain.current[result[0]], triggerSave: true};
                                delete reihenfolgeChangeToBeSavedAgain.current[result[0]];
                            } else {
                                delete neu[result[0]];
                            }

                            reihenfolgeChangeBeingSaved.current.delete(result[0]);
                        }

                        return neu;
                    });

                    fetchAnsicht();
                });
            }
        }
    }, [reihenfolgeChanged])

    useEffect(() => {
        const reihenfolgeNeu = {
            ...reihenfolgeDB,
            ...reihenfolgeChanged
        };

        const length = Object.keys(reihenfolgeNeu).length;
        if (!reihenfolgeNeu[-1]) reihenfolgeNeu[-1] = {reihenfolge: length};

        const idxArr = Object.keys(reihenfolgeNeu)
            .sort((a, b) =>
                (reihenfolgeNeu[a] && "reihenfolge" in reihenfolgeNeu[a] ? reihenfolgeNeu[a].reihenfolge : 99999) -
                (reihenfolgeNeu[b] && "reihenfolge" in reihenfolgeNeu[b] ? reihenfolgeNeu[b].reihenfolge : 99999)
            );

        medikationsplanContext.setReihenfolgeMap(reihenfolgeNeu);
        setReihenfolge(reihenfolgeNeu);
        setReihenfolgeIdx(idxArr);
    }, [reihenfolgeChanged, reihenfolgeDB])

    useEffect(() => {
        const jsonReihenfolge = JSON.stringify(reihenfolge);
        if (anzeigePlanzeilen && (reportIndexLast.current !== reportIndex || reihenfolgeLast.current !== jsonReihenfolge)) {
            reportIndexLast.current = reportIndex;
            reihenfolgeLast.current = jsonReihenfolge;

            setAnzeigePlanzeilen(prev => {
                const neu = [...prev].sort(reportOptions[reportIndex].sort);
                return neu;
            });
        }
    }, [reportIndex, anzeigePlanzeilen, reihenfolge])

    useEffect(() => {
        if (medikationsplan) (async () => {
            const response = await callApiAsync({auth, url: medikationsplanApi.getAnsichtenByPlanId(medikationsplan.id)});
            setAnsichten([ANSICHT_STANDARD, ANSICHT_FAVORITEN, ...response.data.OBJECT.filter(a => ![ANSICHT_STANDARD, ANSICHT_FAVORITEN].includes(a)).sort()]);
        })();

        return () => {
            clearHandleSaveTimeout();
        }
    }, [medikationsplan])

    useEffect(() => {
        medikationsplanContext.setAnsichten(ansichten);
    }, [ansichten])

    useEffect(() => {
        if (ansichtNeu) {
            const reihenfolgeChangedNeu = {...reihenfolge, ...reihenfolgeChanged};
            for (let key in reihenfolgeChangedNeu) {
                reihenfolgeChangedNeu[key].reihenfolgeDB = reihenfolgeDB[key]?.reihenfolge;
                reihenfolgeChangedNeu[key].triggerSave = true
            }

            if (medikationsplanContext.planzeilen.length !== reihenfolgeChangedNeu.length) delete reihenfolgeChangedNeu[-1];

            setReihenfolgeChanged(reihenfolgeChangedNeu);
            return;
        }

        if (ansicht) {
            switch (ansicht) {
                case ANSICHT_STANDARD:
                    setAnsichtPlanzeilen(null);
                    setReportIndex(0);
                    break;
                case ANSICHT_FAVORITEN:
                    setAnsichtPlanzeilen(null);
                    setReportIndex(1);
                    break;
                case ANSICHT_KONTROLLE:
                    setAnsichtPlanzeilen(null);
                    setReportIndex(2);
                    break;
                default:
                    setReportIndex(0);
                    fetchAnsicht();
            }
        }

        medikationsplanContext.setIsStandardAnsicht(ansicht === ANSICHT_STANDARD || ansicht === ANSICHT_FAVORITEN);
    }, [ansicht])

    useEffect(() => {
        if (ansichtNeu) {
            setShowInputAnsichtNeu(false);
            if ("" !== inputAnsichtNeuValue) {
                setInputAnsichtNeuValue("");
                changeAnsicht(ansichtNeu);
            }
        }
    }, [ansichtNeu])

    useEffect(() => {
        if (!medikationsplanContext.vorgangAktiv && medikationsplanContext.reload) {
            medikationsplanContext.setReload(false);

            (async () => {
                const ret = await handleLoad();

                if (medikationsplanContext.planzeilenChangeAfterLoad) {
                    for (let p of medikationsplanContext.planzeilenChangeAfterLoad) {
                        handleChange(p, p.id);
                        setChildUpdated(p.id);
                    }
                }

                medikationsplanContext.setPlanzeilenChangeAfterLoad([]);
            })();
        }
    }, [medikationsplanContext.vorgangAktiv, medikationsplanContext.reload])

    useEffect(() => {
        if (medikationsplanContext.planzeilen && planzeilen && planzeilen.length !== medikationsplanContext.planzeilen.length) {
            const planzeilenIds = planzeilen.map(p => p.id);
            const planzeilenToAdd = [];
            for (let planzeile of medikationsplanContext.planzeilen) {
                if (!planzeilenIds.includes(planzeile.id)) {
                    planzeilenToAdd.push(planzeile);
                }
            }

            if (planzeilenToAdd.length) {
                handleChangeMultiple(planzeilenToAdd);
                for (let planzeile of planzeilenToAdd) {
                    setChildUpdated(planzeile.id);
                }
            }
        }

        const planzeilenChangingNeu = [];
        for (let i=0; i<planzeilenChanging.length; i++) {
            const planzeileId = planzeilenChanging[i];
            const planzeileLast = planzeilenLast.current.find(zeile => zeile.id === planzeileId);
            const planzeile = medikationsplanContext.planzeilen.find(zeile => zeile.id === planzeileId);

            if (JSON.stringify(planzeile) === JSON.stringify(planzeileLast)) planzeilenChangingNeu.push(planzeileId);
        }
        planzeilenChanging.current = planzeilenChangingNeu;
        setCheckSaveAfterChange(true);

        planzeilenLast.current = medikationsplanContext.planzeilen;
    }, [medikationsplanContext.planzeilen])

    useEffect(() => {
        if (childrenUpdated.length) {
            setHandleSaveTimeout();
        } else {
            clearHandleSaveTimeout();
        }
    }, [childrenUpdated])

    useEffect(() => {
        const a = medikationsplanContext.planzeilen;
        const b = ansichtPlanzeilen;
        const c = medikationsplanContext.anspruchList;
        const d = medikationsplanContext.bestandList;

        verarbeitePlanzeilen(medikationsplanContext.planzeilen);
    }, [medikationsplanContext.planzeilen, ansichtPlanzeilen, medikationsplanContext.anspruchList, medikationsplanContext.bestandList])

    useEffect(() => {
        if (anzeigePlanzeilen) {
            for (let planzeile of anzeigePlanzeilen) {
                if (planzeile.id > 0) {
                    if (!planzeile.freigabe) setDisablePlanzeileFreigebenButton(false);
                }
            }
        }
    }, [anzeigePlanzeilen])

    useEffect(() => {
        if (triggerNew === true) {
            setTriggerNew(false);

            handleNeuePlanzeile();
        }
    }, [triggerNew])

    useEffect(() => {
        if (scrollToPlanzeileTrigger) {
            if (scrollToPlanzeile(scrollToPlanzeileTrigger, boundsType.bottom)) {
                setScrollToPlanzeileTrigger(null);
            }
        }
    }, [scrollToPlanzeileTrigger, anzeigePlanzeilen])

    useEffect(() => {
        if (medikationsplanContext.planzeileSelected) {
            setScrollToPlanzeileTrigger(medikationsplanContext.planzeileSelected);
        }
    }, [medikationsplanContext.planzeileSelected])

    useEffect(() => {
        if (savedAfterTrigger && savedAfterTrigger[0]) {
            const planzeileNeu = savedAfterTrigger[0];
            setMovePlanzeileAfterSave({planzeile: planzeileNeu, index: reihenfolgeNeuIndexRef.current});

            // alte temporäre Planzeile löschen
            handleChange(null, -1);
        }
    }, [savedAfterTrigger])

    useEffect(() => {
        if (movePlanzeileAfterSave && reihenfolge[movePlanzeileAfterSave.planzeile.id]) {
            (async () => {
                setMovePlanzeileAfterSave(null);

                // neue Planzeile auswählen
                medikationsplanContext.setPlanzeileSelected(movePlanzeileAfterSave.planzeile.id);
                medikationsplanContext.setNeuePlanzeile(movePlanzeileAfterSave.planzeile);
                addOpenEditor(movePlanzeileAfterSave.planzeile, "Arzneimittel");

                if (movePlanzeileAfterSave.index !== null && movePlanzeileAfterSave.index !== reihenfolge[movePlanzeileAfterSave.planzeile.id].reihenfolge) {
                    let dropId;
                    for (let planzeileId in reihenfolge) if (reihenfolge[planzeileId]?.reihenfolge > movePlanzeileAfterSave.index && (!dropId || reihenfolge[planzeileId]?.reihenfolge < reihenfolge[dropId]?.reihenfolge)) dropId = planzeileId;
                    await setReihenfolgeIndex(movePlanzeileAfterSave.planzeile.id, dropId, true);
                }
            })();
        }
    }, [reihenfolge, movePlanzeileAfterSave])

    useEffect(() => {
        if (scrollToReihenfolgeIndex) {
            const el = document.getElementById(`planzeile_${scrollToReihenfolgeIndex}_ende`);
            if (el) el.scrollIntoView();
            setScrollToReihenfolgeIndex(null);
        }
    }, [scrollToReihenfolgeIndex])

    useEffect(() => {
        if (simulationsBeginn.data) {
            const dateMoment = moment(simulationsBeginn.data);
            setSimulationStart(simulationsBeginn.data);
        }
    }, [simulationsBeginn.data]);

    useEffect(() => {
        if (dragPlanzeileId > -2) {

        }
    }, [dragPlanzeileId])

    useEffect(() => {
        if (dropPlanzeileId > -2) {
            let planzeileDrag = medikationsplanContext.planzeilenMap[dragPlanzeileId];
            let planzeileDrop = medikationsplanContext.planzeilenMap[dropPlanzeileId];

            if (planzeileDrag && planzeileDrop) setReihenfolgeIndex(planzeileDrag.id, planzeileDrop.id);

            setDragPlanzeileId(-2);
            setDropPlanzeileId(-2);
        }
    }, [dropPlanzeileId]);

    useEffect(() => {
        // prüfe, ob alle aktuell bearbeiteten Planzeilen gelöscht wurden (wurden geändert, werden aber nicht mehr angezeigt)
        if (anzeigePlanzeilen) {
            const filtered = childrenUpdated.filter(planzeileId => !anzeigePlanzeilen.filter(planzeile => planzeileId === planzeile.id).length);
            if (filtered.length && triggerSave && childrenUpdated.length === filtered.length) {
                // speichere aktuellen Cache
                if (medikationsplan.active) (async () => {
                    clearHandleSaveTimeout();

                    const response = await handleSave();
                    filtered.forEach(setChildSaved);

                    medikationsplanContext.setReloadBestaende(true);
                    medikationsplanContext.setReloadAnsprueche(true);
                })();
            }
        }

        setTriggerSave(false);
    }, [childrenUpdated, triggerSave])

    // Verknüpft automatisch einen InfoMarker mit einer Planzeile, wenn eine Planzeile ausgewählt ist
    useEffect(() => {
        const planzeileIdsMitOffenemEditor = Object.keys(openEditor);
        if (
            // Wenn ein Dosierabschnitt geöffnet ist, wird der neue InfoMarker im Dosierabschnitt verarbeitet, nicht hier
            !medikationsplanContext.dosierabschnittGeoeffnet &&

            // es darf nur einen neuen InfoMarker geben
            dokumenteContext.infoMarkerNeu &&
            dokumenteContext.infoMarkerNeu.length === 1 &&

            (
                // Ist eine Planzeile ausgewählt ?
                medikationsplanContext.planzeileSelected ||

                // (oder) Ist ein Editor einer Planzeile offen?
                planzeileIdsMitOffenemEditor.length === 1
            )
        ) {
            const planzeileId = medikationsplanContext.planzeileSelected || planzeileIdsMitOffenemEditor[0];
            const dosierabschnitt = medikationsplanContext.dosierabschnittAktuell[planzeileId] || medikationsplanContext.dosierabschnittNext[planzeileId];

            (async () => {
                const data = getPostBody(dokumenteContext.infoMarkerNeu[0], planzeileId, dosierabschnitt?.id, medikationsplanContext.isStandardAnsicht ? null : medikationsplanContext.ansicht);
                const response = await callApiAsync({auth, url: postInfomarker, method: "post", data});

                dokumenteContext.clearInfoMarker();
                dokumenteContext.setZuletztGespeicherterInfoMarker(response.data.OBJECT);

                notification.info({message: `Für die Planzeile ${getArzneimittelLangname(data.planzeile.arzneimittel)} wurde ein Infomarker eingetragen.`});
            })();
        }
    }, [dokumenteContext.infoMarkerNeu])

    // berechne Reichweiten
    useEffect(() => {
        if (planzeileIdListZurNeuberechnung?.length) {
            (async () => {
                if (!await handleBerechneReichweite(planzeileIdListZurNeuberechnung)) {
                    setSkipPlanzeileIdListZurNeuberechnung(planzeileIdListZurNeuberechnung);
                }
            })();
        }
    }, [planzeileIdListZurNeuberechnung, simulationStart, medikationsplanContext.planzeilenVerblistert])

    useEffect(() => {
        if (!updated) {
            clearHandleSaveTimeout();
        }
    }, [updated])

    useEffect(() => {
        if (medikationsplanContext.berechneReichweiteAb) {
            medikationsplanContext.setFehlerBeiReichweitenberechnungAb(null);

            (async () => {
                const ret = await handleBerechneReichweite(medikationsplanContext.planzeilen.map(planzeile => planzeile.id), medikationsplanContext.berechneReichweiteAb, medikationsplanContext.berechneReichweiteBis, false, false, medikationsplanContext.berechneReichweiteAwaitFinish);
                medikationsplanContext.setBerechneReichweiteAb(null);
                medikationsplanContext.setBerechneReichweiteBis(null);
                medikationsplanContext.setBerechneReichweiteAwaitFinish(false);
            })()
        }
    }, [medikationsplanContext.berechneReichweiteAb])

    useEffect(() => {
        if (simulationStart && medikationsplanContext.exportFinished) {
            if (medikationsplanContext.berechneReichweitenachExport) {
                if (medikationsplanContext.planzeilen) {
                    medikationsplanContext.setExportFinished(false);
                    medikationsplanContext.setBerechneReichweiteNachExport(false);

                    handleBerechneReichweite(medikationsplanContext.planzeilen.map(planzeile => planzeile.id), null, null, true);
                }
            } else {
                medikationsplanContext.setExportFinished(false);
            }
        }
    }, [medikationsplanContext.exportFinished, medikationsplanContext.berechneReichweitenachExport, medikationsplanContext.planzeilen, simulationStart])

    useOnClickOutside(() => {
        setShowAnzeigeDatumPicker(false);
    }, [anzeigeDatumRef])

    useEffect(() => {
        if (globalHotkeyContext.lastHotkey) {
            switch (globalHotkeyContext.lastHotkey) {
                case "UP":
                    onArrowUp();
                    break;
                case "DOWN":
                    onArrowDown();
                    break;
                case "LEFT":
                    onArrowLeft();
                    break;
                case "RIGHT":
                    onArrowRight();
                    break;
            }
        }
    }, [globalHotkeyContext.lastHotkey])

    useEffect(() => {
        medikationsplanContext.setAnsicht(ansicht);
    }, [ansicht])




    const verarbeitePlanzeilen = async (planzeilen) => {
        if (!planzeilen || skipVerarbeitePlanzeilenRef.current) return;

        // Wenn es nicht gleich viele Ansicht-Einträge wie Planzeilen gibt
        const ansichtPlanzeileMap = {};
        if (ansichtPlanzeilen && ansichtPlanzeilen.length < planzeilen.length) {
            const ansichtPlanzeilenNeu = [...ansichtPlanzeilen];

            // erstelle eine neue Ansicht für jede Planzeile ohne Ansicht
            const zusaetzlicheAnsichtEintraege = [];
            for (let planzeile of planzeilen) {
                if (planzeile.id > 0) {
                    const ansichtPlanzeile = ansichtPlanzeilenNeu.find(pz => pz.id.planzeile.id === planzeile.id);
                    if (!ansichtPlanzeile) {
                        const reihenfolge = reihenfolgeDB[planzeile.id] ? reihenfolgeDB[planzeile.id].reihenfolge : ansichtPlanzeilenNeu.length;

                        const data = {
                            id: {
                                planzeile: planzeile.arzneimittel ? planzeile : {
                                    ...planzeile,
                                    arzneimittel: reihenfolgeChanged[planzeile.id] ? reihenfolgeChanged[planzeile.id].arzneimittel : null
                                },
                                bezeichnung: ansicht
                            },
                            reihenfolge
                        };
                        const ret = await persistChangeAnsicht(planzeile.id, data, true);
                        if (ret && ret.length) zusaetzlicheAnsichtEintraege.push(...ret);
                    }
                }
            }

            fetchAnsicht(zusaetzlicheAnsichtEintraege);
        } else {
            if (checkReihenfolgeChangedEquals()) {
                onSaveReihenfolgeChanged();
                return
            }

            const anzeigePlanzeilenNeu = [...planzeilen];
            reihenfolgeIndexMap.current = {};
            reihenfolgePlanzeileIdMap.current = {};
            const reihenfolgeNeu = {};
            const planzeilenMap = {};

            // überschreibe die Reihenfolge der Planzeilen, wenn eine Ansicht angezeigt wird
            const persistAnsichtPlanzeileIdList = [];
            let reihenfolgeDB;
            if (ansichtPlanzeilen) {
                const ansichtSeen = [];
                let reihenfolgeDBMax = 0;
                for (let ansichtPlanzeile of ansichtPlanzeilen) {
                    ansichtSeen.push(ansichtPlanzeile.id.planzeile.id);
                    ansichtPlanzeileMap[ansichtPlanzeile.id.planzeile.id] = ansichtPlanzeile;

                    // Wenn keine Reihenfolge für diese Ansicht in der DB festgelegt ist
                    if (!ansichtPlanzeile.reihenfolge) {
                        ansichtPlanzeile.reihenfolge = Number.MAX_VALUE;
                        persistAnsichtPlanzeileIdList.push(ansichtPlanzeile.id.planzeile.id);
                    }

                    reihenfolgeDB = ansichtPlanzeile.reihenfolge;
                    if (reihenfolgeDBMax < reihenfolgeDB) reihenfolgeDBMax = reihenfolgeDB;
                    const reihenfolgeNeuEntry = {reihenfolgeDB};

                    reihenfolgeNeu[ansichtPlanzeile.id.planzeile.id] = reihenfolgeNeuEntry;
                    if (ansichtPlanzeile.arzneimittel) {
                        reihenfolgeNeuEntry.arzneimittel = ansichtPlanzeile.arzneimittel;
                        reihenfolgeNeuEntry.faktorArzneimittel = ansichtPlanzeile.faktorArzneimittel || 1;
                    }

                    reihenfolgeIndexMap.current[reihenfolgeDB] = reihenfolgeIndexMap.current[reihenfolgeDB] ? [...reihenfolgeIndexMap.current[reihenfolgeDB], ansichtPlanzeile.id.planzeile.id] : [ansichtPlanzeile.id.planzeile.id];
                    reihenfolgePlanzeileIdMap.current[ansichtPlanzeile.id.planzeile.id] = reihenfolgeDB;
                }

                let showLog = false;
                reihenfolgeDBMax++;
                for (let planzeile of anzeigePlanzeilenNeu) {
                    planzeilenMap[planzeile.id] = planzeile;

                    // wenn noch keine Ansicht für diese Planzeile existiert
                    if (!ansichtSeen.includes(planzeile.id)) {
                        reihenfolgeDB = reihenfolgeDBMax;
                        reihenfolgeNeu[planzeile.id] = {reihenfolgeDB};

                        reihenfolgeIndexMap.current[reihenfolgeDB] = reihenfolgeIndexMap.current[reihenfolgeDB] ? [...reihenfolgeIndexMap.current[reihenfolgeDB], planzeile.id] : [planzeile.id];
                        reihenfolgePlanzeileIdMap.current[planzeile.id] = reihenfolgeDB;
                        if (planzeile.id >= 0) {
                            showLog = true;
                        }
                    }
                }

                if (showLog) {
                    console.log(`Ansicht ${ansicht} hat nicht die gleiche Anzahl an Planzeilen`, ansichtPlanzeilen, planzeilen);
                }
            } else {
                for (let zeile of anzeigePlanzeilenNeu) {
                    reihenfolgeDB = zeile.reihenfolge;
                    planzeilenMap[zeile.id] = zeile;
                    reihenfolgeNeu[zeile.id] = {reihenfolgeDB};

                    reihenfolgeIndexMap.current[reihenfolgeDB] = reihenfolgeIndexMap.current[reihenfolgeDB] ? [...reihenfolgeIndexMap.current[reihenfolgeDB], zeile.id] : [zeile.id];
                    reihenfolgePlanzeileIdMap.current[zeile.id] = reihenfolgeDB;
                }
            }

            // ermittle eine flat map der Planzeilen IDs
            const planzeilenIDs = [];
            for (let index in reihenfolgeIndexMap.current) {
                for (let planzeileId of reihenfolgeIndexMap.current[index]) {
                    planzeilenIDs.push(planzeileId);
                }
            }

            // sortiere Planzeilen und stelle sicher, dass zusammenhängende Planzeilen untereinander sind
            // const planzeileToVerknuepftMap = {};
            // const planzeileIDsSortiert = [];
            // const planzeileIDsFirstSortiert = [];
            // let planzeileNext, planzeileLast;
            // for (let planzeileId of planzeilenIDs) {
            //     if (!planzeileIDsSortiert.includes(planzeileId) && !!planzeilenMap[planzeileId]) {
            //         const planzeile = planzeilenMap[planzeileId];
            //         const json = planzeile.json ? JSON.parse(planzeile.json) : {};
            //         if (json.PLANZEILE_PREVIOUS && planzeilenIDs.includes(json.PLANZEILE_PREVIOUS)) {
            //             continue;
            //         } else if (json.PLANZEILE_NEXT) {
            //             planzeileIDsSortiert.push(planzeileId);
            //             const idSet = [planzeileId];
            //             planzeileNext = planzeilenMap[json.PLANZEILE_NEXT];
            //             while (planzeileNext) {
            //                 planzeileToVerknuepftMap[planzeileNext.id] = {first: planzeileId, all: idSet};
            //                 planzeileIDsSortiert.push(planzeileNext.id);
            //                 idSet.push(planzeileNext.id);
            //                 planzeileLast = planzeileNext;
            //
            //                 const jsonNext = planzeileNext.json ? JSON.parse(planzeileNext.json) : {};
            //                 planzeileNext = jsonNext.PLANZEILE_NEXT ? planzeilenMap[jsonNext.PLANZEILE_NEXT] : null;
            //             }
            //
            //             if (planzeileLast) {
            //                 planzeileToVerknuepftMap[planzeileId] = {all: idSet};
            //
            //                 for (let id of idSet) {
            //                     if (id === planzeileLast?.id) continue;
            //                     planzeileToVerknuepftMap[id].last = planzeileLast?.id;
            //                 }
            //             }
            //         } else if (!json.DOSISGENAU_ANFORDERN) {
            //             planzeileIDsSortiert.push(planzeileId);
            //         } else if (!json.DOSISGENAU_ANFORDERN[1]) {
            //             planzeileIDsSortiert.push(planzeileId);
            //
            //             const faktorList = Object.keys(json.DOSISGENAU_ANFORDERN).sort();
            //             for (let faktor of faktorList) {
            //                 const planzeileIdVerknuepft = json.DOSISGENAU_ANFORDERN[faktor];
            //                 planzeileIDsSortiert.push(planzeileIdVerknuepft);
            //             }
            //         }
            //
            //         // Planzeile ist die erste einer Verknüpfung
            //         planzeileIDsFirstSortiert.push(planzeileId);
            //     }
            // }

            // sicherstellen, dass jede Planzeile einen einzigartigen Reihenfolge-Index erhält und dass es keine Lücken gibt
            for (let i=0; i<planzeilenIDs.length; i++) {
                const planzeileId = planzeilenIDs[i];

                // setze gültigen Index
                if (!!reihenfolgeNeu[planzeileId]) {
                    reihenfolgeNeu[planzeileId].reihenfolge = i;
                } else {
                    reihenfolgeNeu[planzeileId] = {reihenfolge: i};
                }

                // Gibt es Verknüpfungen?
                // if (planzeileId in planzeileToVerknuepftMap) reihenfolgeNeu[planzeileId].verknuepft = planzeileToVerknuepftMap[planzeileId];
            }

            // sicherstellen, dass die aktuell zugewiesene Reihenfolge auch in der DB gespeichert ist
            let indexDB = 1;
            const reihenfolgeChangedNeu = {...reihenfolgeChanged};
            // ist Ansicht
            if (ansichtPlanzeilen?.length && reihenfolgeGesaeubert.current !== ansicht) {
                reihenfolgeGesaeubert.current = ansicht;

                for (let planzeileId of planzeilenIDs) {
                    // erste Planzeile in Verknüpfung
                    // reihenfolgeNeu[planzeileId].reihenfolgeDB = indexDB;
                    if (!ansichtPlanzeileMap[planzeileId] || indexDB !== ansichtPlanzeileMap[planzeileId].reihenfolge) {
                        reihenfolgeChangedNeu[planzeileId] = {
                            ...reihenfolgeNeu[planzeileId],
                            ...reihenfolgeChangedNeu[planzeileId],
                            reihenfolgeDB: indexDB,
                            triggerSave: true
                        };
                    }

                    // alle anderen verknüpften Planzeilen
                    // for (let planzeileIdVerknuepft of reihenfolgeNeu[planzeileId].verknuepft?.all || []) {
                    //     // reihenfolgeNeu[planzeileIdVerknuepft].reihenfolgeDB = indexDB;
                    //     if (!ansichtPlanzeileMap[planzeileIdVerknuepft] || indexDB !== ansichtPlanzeileMap[planzeileIdVerknuepft].reihenfolge) {
                    //         reihenfolgeChangedNeu[planzeileIdVerknuepft] = {
                    //             ...reihenfolgeNeu[planzeileIdVerknuepft],
                    //             ...reihenfolgeChangedNeu[planzeileIdVerknuepft],
                    //             reihenfolgeDB: indexDB,
                    //             triggerSave: true
                    //         };
                    //     }
                    // }

                    indexDB++;
                }
            }

            // ist Keine Ansicht
            else if (ansicht === ANSICHT_STANDARD && reihenfolgeGesaeubert.current !== ANSICHT_STANDARD) {
                reihenfolgeGesaeubert.current = ANSICHT_STANDARD;

                for (let planzeileId of planzeilenIDs) {
                    // erste Planzeile in Verknüpfung
                    // reihenfolgeNeu[planzeileId].reihenfolgeDB = indexDB;
                    if (indexDB !== planzeilenMap[planzeileId].reihenfolge) {
                        reihenfolgeChangedNeu[planzeileId] = {
                            ...reihenfolgeChangedNeu[planzeileId],
                            reihenfolgeDB: indexDB,
                            triggerSave: true
                        };
                    }

                    // alle anderen verknüpften Planzeilen
                    // for (let planzeileIdVerknuepft of reihenfolgeNeu[planzeileId].verknuepft?.all || []) {
                    //     // reihenfolgeNeu[planzeileIdVerknuepft].reihenfolgeDB = indexDB;
                    //     if (indexDB !== planzeilenMap[planzeileIdVerknuepft].reihenfolge) {
                    //         reihenfolgeChangedNeu[planzeileIdVerknuepft] = {
                    //             ...reihenfolgeChangedNeu[planzeileIdVerknuepft],
                    //             reihenfolgeDB: indexDB,
                    //             triggerSave: true
                    //         };
                    //     }
                    // }

                    indexDB++;
                }
            }

            // Verarbeite neue Planzeilen
            if (anzeigePlanzeilenNeu) {
                let ansichtPlanzeilenNeu = ansichtPlanzeilen ? [...ansichtPlanzeilen] : [];
                let disableNeueZeile = false;

                const planzeileIdsToRemove = [];
                let index;
                for (let zeile of anzeigePlanzeilenNeu

                    // ermittle alle Planzeilen, deren reihenfolge undefined ist => das ist eine neue Planzeile
                    .filter((zeile, i) => {
                        if (zeile.id < 0) disableNeueZeile = true;
                        if (typeof zeile.reihenfolge === 'undefined') {
                            index = i;
                            return true;
                        }

                        return false;
                    }))

                // setze Standard-Daten für neu erstellte Zeile und öffne Arzneimittel-Picker
                {
                    // if (reihenfolgeNeuIndexRef.current !== null) zeile.reihenfolge = reihenfolgeNeuIndexRef.current;
                    if (reihenfolge && typeof reihenfolge[zeile.id] !== 'undefined') zeile.reihenfolge = reihenfolge[zeile.id].reihenfolge;
                    zeile.medikationsplan = medikationsplan;

                    // speichere die Planzeile
                    handleChange({...zeile}, zeile.id);
                    setTriggerSaveDataNode([zeile.id]);

                    // entferne die ungespeicherte Planzeile aus der Anzeige
                    anzeigePlanzeilenNeu.splice(index, 1);
                    planzeileIdsToRemove.push(zeile.id);
                }

                // deaktiviere Button für neue Planzeile, wenn eine neu erstellte Planzeile gefunden wurde
                setDisableNeueZeile(disableNeueZeile);

                if (planzeileIdsToRemove.length) {
                    // aktualisiere Ansicht
                    ansichtPlanzeilenNeu = ansichtPlanzeilenNeu.filter(val => !planzeileIdsToRemove.includes(val.id.planzeile.id));
                    setAnsichtPlanzeilen(ansichtPlanzeilenNeu.length ? ansichtPlanzeilenNeu : null);

                    // entferne neu erstellte Planzeile aus MedikationsplanContext
                    medikationsplanContext.setPlanzeilen(prev => {
                        const neu = prev.filter(p => !planzeileIdsToRemove.includes(p.id));
                        return neu;
                    });
                }
            }

            anzeigePlanzeilenNeu.sort(reportOptions[reportIndex].sort);
            setAnzeigePlanzeilen(anzeigePlanzeilenNeu);
            setReihenfolgeDB(reihenfolgeNeu);
            setReihenfolgeChanged(reihenfolgeChangedNeu);
        }
    }

    const fetchAnsicht = async (zusaetzlicheAnsichtEintraege=[]) => {
        if (ansicht === ANSICHT_FAVORITEN || ansicht === ANSICHT_STANDARD) return;

        const response = await callApiAsync({auth, url: medikationsplanApi.getAnsichtenPlanzeilenByPlanIdAndAnsichtBezeichnung(medikationsplan.id, ansicht)});
        const ansichtPlanzeilenNeu = response.data.OBJECT;

        if (ansichtPlanzeilenNeu.length) setAnsichtPlanzeilen([...ansichtPlanzeilenNeu, ...zusaetzlicheAnsichtEintraege]);
        else changeAnsicht(ANSICHT_STANDARD);
    }

    const setHandleSaveTimeout = () => {
        if (handleSaveTimeout.current) clearHandleSaveTimeout();

        handleSaveTimeout.current = setTimeout(async () => {
            for (let planzeileId of childrenUpdated) {
                onSave(planzeileId);
            }
        }, 1000);
    }

    const clearHandleSaveTimeout = () => {
        if (handleSaveTimeout.current) {
            clearTimeout(handleSaveTimeout.current);
            handleSaveTimeout.current = null;
        }
    }

    const setReihenfolgeIndex = async (dragId, dropId, ignoreNew=false) => {
        if (dragId < 1 || dropId < 1) return;

        if (reihenfolge) {
            const planzeileIdsVerknuepftDrag = reihenfolge[dragId].verknuepft?.all || [dragId];

            // Abbrechen, wenn nur verknüpfte Planzeilen involviert sind
            if (planzeileIdsVerknuepftDrag.includes(dropId)) {
                return;
            }

            const reihenfolgeIndexDrag = reihenfolgePlanzeileIdMap.current[dragId];
            const reihenfolgeIndexDrop = reihenfolgePlanzeileIdMap.current[dropId];
            const runterGeschoben = reihenfolge[dropId].reihenfolge > reihenfolge[dragId].reihenfolge;
            const reihenfolgeChangedNeu = {...reihenfolgeChanged};

            // gezogene Planzeile (+Verknüpfungen) aus der Reihenfolge-Map entfernen
            const reihenfolgeIndexMapNeu = JSON.parse(JSON.stringify(reihenfolgeIndexMap.current));
            reihenfolgeIndexMapNeu[reihenfolgeIndexDrag] = [...reihenfolgeIndexMapNeu[reihenfolgeIndexDrag].filter(id => !planzeileIdsVerknuepftDrag.includes(id))];

            let zielIndex, zielIndexDB;

            // gibt es schon Planzeilen mit dem Ziel-Index?
            if (reihenfolgeIndexMapNeu[reihenfolgeIndexDrop]?.length) {
                // Index (einschließlich), ab dem bis zur nächsten Lücke der Wert um eins erhöht werden soll
                let erhoeheAbIndex = -1;

                /*
                    Die Panzeile wurde nach unten gezogen
                    => Die Planzeile soll UNTER der zuletzt verknüpften Planzeile derer, auf die gezogen wurde, landen
                */
                if (runterGeschoben) {
                    zielIndexDB = reihenfolgeIndexDrop;
                    zielIndex = reihenfolge[reihenfolge[dropId].verknuepft?.last || dropId].reihenfolge;

                    if (reihenfolgeIndexMapNeu[reihenfolgeIndexDrag].length) {
                        // Das alte Mapping ist nicht leer => Alle Indizes ab dem Ziel-Index um eins erhöhen, Panzeile in Ziel-Index +1 einfügen
                        erhoeheAbIndex = reihenfolgeIndexDrop +1;

                        zielIndexDB++;
                        zielIndex++;
                    } else {
                        // Das alte Mapping ist leer => Alle Indizes von Quell-Index +1 bis Ziel-Index um eins reduzieren, Planzeile in Ziel-Index einfügen
                        for (let i = reihenfolgeIndexDrag+1; i<=reihenfolgeIndexDrop; i++) {
                            for (let pId of reihenfolgeIndexMapNeu[i] || []) {
                                if (reihenfolgeChangedNeu[pId]) {
                                    reihenfolgeChangedNeu[pId].reihenfolgeDB = reihenfolgeChangedNeu[pId].reihenfolgeDB - 1;
                                } else {
                                    reihenfolgeChangedNeu[pId] = {...reihenfolge[pId], reihenfolge: reihenfolge[pId].reihenfolge -1, reihenfolgeDB: reihenfolgePlanzeileIdMap.current[pId] - 1};
                                }

                                reihenfolgeChangedNeu[pId].triggerSave = true;
                            }
                        }
                    }
                }

                /*
                    Die Panzeile wurde nach oben gezogen
                    => Die Planzeile soll ÜBER der ersten, verknüpften Planzeile derer, auf die gezogen wurde, landen
                */
                else {
                    // Alle Indizes ab Ziel-Index um eins erhöhen, Planzeile in Ziel-Index einfügen
                    erhoeheAbIndex = reihenfolgeIndexDrop;

                    zielIndexDB = reihenfolgeIndexDrop;
                    zielIndex = reihenfolge[reihenfolge[dropId].verknuepft?.first || dropId].reihenfolge;
                }

                // Indizes wie angegeben erhöhen (zu reduzierende Indizes wurden bereits verarbeitet)
                if (erhoeheAbIndex >= 0) {
                    // ermittle ersten leeren index
                    let max = erhoeheAbIndex;
                    while (reihenfolgeIndexMapNeu[max]?.length) {
                        max++;
                    }

                    // erhöhe index von jeder Planzeile auf dem Weg um 1
                    for (let i = max - 1; i >= erhoeheAbIndex; i--) {
                        for (let pId of reihenfolgeIndexMapNeu[i] || []) {
                            if (reihenfolgeChangedNeu[pId]) {
                                reihenfolgeChangedNeu[pId].reihenfolgeDB = reihenfolgeChangedNeu[pId].reihenfolgeDB + 1;
                            } else {
                                reihenfolgeChangedNeu[pId] = {...reihenfolge[pId], reihenfolge: reihenfolge[pId].reihenfolge + 1, reihenfolgeDB: reihenfolgePlanzeileIdMap.current[pId] + 1};
                            }

                            reihenfolgeChangedNeu[pId].triggerSave = true;
                        }
                    }
                }
            }

            for (let pId of planzeileIdsVerknuepftDrag) {
                if (reihenfolgeChangedNeu[pId]) {
                    reihenfolgeChangedNeu[pId].reihenfolge = zielIndex;
                } else {
                    reihenfolgeChangedNeu[pId] = {...reihenfolge[pId], reihenfolge: zielIndex, reihenfolgeDB: zielIndexDB};
                }

                reihenfolgeChangedNeu[pId].triggerSave = true;
            }

            setReihenfolgeChanged(reihenfolgeChangedNeu);
        }
    }

    const setReihenfolgeArzneimittel = (planzeileId, arzneimittelNeu=null, faktorArzneimittel=null) => {
        if (!planzeileId) return;
        const reihenfolgeChangedEntry = {...reihenfolge[planzeileId], triggerSave: true};

        // Prüfe, ob das Arzneimittel ein anderes ist als das in der Planzeile
        const planzeile = {...medikationsplanContext.planzeilen.find(pz => pz.id === planzeileId)};
        const arzneimittelIstAnders = planzeile.arzneimittel && arzneimittelNeu?.id !== planzeile.arzneimittel.id && arzneimittelNeu?.id !== reihenfolgeChangedEntry.arzneimittel?.id;

        if (arzneimittelIstAnders) {
            reihenfolgeChangedEntry.arzneimittel = arzneimittelNeu;
            reihenfolgeChangedEntry.faktorArzneimittel = faktorArzneimittel;
        } else {
            delete reihenfolgeChangedEntry.arzneimittel;
            delete reihenfolgeChangedEntry.faktorArzneimittel;
        }

        setReihenfolgeChanged(prev => ({...prev, [planzeileId]: reihenfolgeChangedEntry}));
    }

    const changeAnsicht = ansicht => {
        if (!ansicht) ansicht = ANSICHT_STANDARD;

        const query = queryString.parse(history.location.search);
        if (ANSICHT_STANDARD === ansicht) {
            delete query.ansicht;
        } else {
            query.ansicht = ansicht;
        }

        history.push(queryString.stringifyUrl({url: history.location.pathname, query}));
        setAnsicht(prev => ansicht !== prev ? ansicht : prev);
    }

    const checkReihenfolgeChangedEquals = () => {
        if (Object.keys(reihenfolgeChanged).length) {
            const isStandardAnsicht = ansicht === ANSICHT_STANDARD;
            const zeilen = isStandardAnsicht ? medikationsplanContext.planzeilen : ansichtPlanzeilen;

            for (let pz of zeilen || []) {
                const planzeileId = isStandardAnsicht ? pz.id : pz.id.planzeile.id;
                const change = reihenfolgeChanged[planzeileId];
                if (change && !change.triggerSave) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    const addOpenEditor = (planzeile, type) => {
        const openEditorNew = {};
        if (ANSICHT_FAVORITEN !== ansicht) {
            openEditorNew[planzeile.id] = type;
        }
        setOpenEditor(openEditorNew);

        if (type) {
            setScrollToPlanzeileTrigger(planzeile.id);
        } else {
            if (scrollToPlanzeileTrigger === planzeile.id) {
                setScrollToPlanzeileTrigger(null);
            }

            medikationsplanContext.setDosierabschnittGeoeffnet(null);
        }
    }

    const removeFromReichweiteWirdBerechnet = (planzeileIdList) => {
        medikationsplanContext.setReichweiteWirdBerechnet(prev => {
            const neu = prev.filter(alt => !planzeileIdList.includes(alt));
            if (!neu.length) setShowReichweiteModal(false);
            return neu;
        });
    }

    const onSuccessBerechneReichweite = async (planzeileIdList) => {
        removeFromReichweiteWirdBerechnet(planzeileIdList);

        if (!planzeileIdList.length) {
            medikationsplanContext.setReload(true);
        } else {
            const planzeilenToChange = [];
            for (let planzeileId of planzeileIdList) {
                const response = await callApiAsync({auth, url: planzeilenApi.getById(planzeileId)});
                planzeilenToChange.push(response.data.OBJECT);
            }

            handleChangeMultiple(planzeilenToChange);
        }

        medikationsplanContext.setReloadAnsprueche(true);
        medikationsplanContext.setReloadBestaende(true);
    };

    const handleBerechneReichweite = async (planzeileIdList, customStart, customEnde, erzwingeNeuberechnung, hardReset=false, awaitFinish=false) => {
        if (!simulationStart) {
            return false;
        }

        if (!planzeileIdList.length) {
            return false;
        }

        if (!medikationsplan.patient.active) {
            return false;
        }

        let dosierabschnittNichtAusreichend = null;
        setPlanzeileIdListZurNeuberechnung([]);
        const customStartMoment = customStart ? moment(customStart).startOf('day') : null;
        const planzeilenMap = medikationsplanContext.planzeilenMap;

        if (dosierabschnittNichtAusreichend) {
            medikationsplanContext.setFehlerBeiReichweitenberechnungAb(dosierabschnittNichtAusreichend);
            notification.error({message: `Mindestens ein Dosierabschnitt ist nicht ausreichend für die Berechnung des Abgabeplans ab dem ${customStartMoment.format('DD.MM.YYYY')}.`})
            medikationsplanContext.setExportPending(false);
            return false;
        }

        const data = [];
        if (!medikationsplanContext.berechneReichweiteAllePlanzeilen) {
            if (planzeileIdList.length === planzeilen.length) for (let id of planzeileIdList) {
                if (!medikationsplanContext.reichweiteWirdBerechnet.includes(id)) {
                    if (planzeilenMap[id] && (erzwingeNeuberechnung || !planzeilenMap[id].reichweiteAnspruch)) {
                        data.push(id);
                    }
                }
            }

            if (!data.length) {
                return false;
            }
        }

        medikationsplanContext.setReichweiteWirdBerechnet(prev => [...prev.filter(alt => !planzeileIdList.includes(alt)), ...planzeileIdList]);
        setShowReichweiteModal(true);

        const url = postSimuliereAbgabeDatum(medikationsplan, customStart ? customStart : simulationStart, customEnde ? customEnde : null, hardReset, erzwingeNeuberechnung, awaitFinish);
        console.log("post berechne reichweite: " + url, data);
        const response = await callApiAsync({auth, url, method: 'POST', data, onError: res => {
            removeFromReichweiteWirdBerechnet(planzeileIdList);
        }});
        onSuccessBerechneReichweite(planzeileIdList);
        return true;
    }

    const onSaveReihenfolgeChanged = () => {
        if (medikationsplan.active) (async () => {
            let neueZeileGefunden = false;
            Object.keys(reihenfolgeChanged).forEach(async planzeileId => {
                planzeileId = parseInt(planzeileId)
                setChildSaved(planzeileId);
                if (planzeileId === -1) neueZeileGefunden = true;
            });
            setReihenfolgeChanged({});

            clearHandleSaveTimeout();

            const newElements = await handleSave();
            if (neueZeileGefunden && ansicht !== ANSICHT_STANDARD) {
                const planzeilenDesc = newElements.sort(pz => pz.id).reverse();
                const planzeile = planzeilenDesc[0];

                if (planzeile?.id > 0) {
                    const data = {
                        id: {
                            planzeile,
                            bezeichnung: ansicht
                        },
                        reihenfolge: planzeile.reihenfolge
                    };

                    const ret = await persistChangeAnsicht(planzeile.id, data);
                }
            }
        })();
    }

    const persistChangePlanzeile = planzeileId => {
        const change = reihenfolgeChanged[planzeileId];
        const planzeileNeu = {...medikationsplanContext.planzeilen.filter(pz => planzeileId === pz.id)[0]};

        let wasChanged = false;
        if (change) {
            if ("reihenfolgeDB" in change && planzeileNeu.reihenfolge !== change.reihenfolgeDB) {
                planzeileNeu.reihenfolge = change.reihenfolgeDB;
                wasChanged = true;
            }

            if (change.arzneimittel && change.arzneimittel.id !== planzeileNeu.arzneimittel?.id) {
                planzeileNeu.arzneimittel = change.arzneimittel;
                wasChanged = true;
            }
        }

        if (wasChanged) {
            handleChange(planzeileNeu, planzeileId);
            setChildUpdated(planzeileId);
        }
    }

    const persistChangeAnsicht = async (planzeileId, dataParam, skipStateChange) => {
        let data;
        let planzeile;

        if (dataParam) {
            data = dataParam;
            planzeile = dataParam.id.planzeile;
        } else {
            const change = reihenfolgeChanged[planzeileId];
            planzeile = medikationsplanContext.planzeilenMap[planzeileId];
            if (!planzeile) return [];

            data = {
                id: {
                    planzeile: {id: planzeile.id},
                    bezeichnung: ansicht
                },
                reihenfolge: reihenfolgeDB[planzeileId].reihenfolgeDB
            };

            let wasChanged = false;
            if (change) {
                // wurde die Reihenfolge geändert?
                if ("reihenfolgeDB" in change && (ansichtNeu === ansicht || change.reihenfolgeDB !== reihenfolgeDB[planzeileId].reihenfolgeDB)) {
                    data.reihenfolge = change.reihenfolgeDB;
                    wasChanged = true;
                }

                // wurde das Arzneimittel geändert?
                if (change.arzneimittel?.id !== reihenfolgeDB[planzeileId].arzneimittel?.id) {
                    wasChanged = true;

                    // unterscheidet sich das Arzneimittel von dem der Planzeile?
                    if (change.arzneimittel && change.arzneimittel.id !== planzeile.arzneimittel?.id) {
                        data.arzneimittel = change.arzneimittel;
                        data.faktorArzneimittel = change.faktorArzneimittel;
                    }
                }

                if (!wasChanged) {
                    return [{...data, reihenfolge: change.reihenfolge, arzneimittel: change.arzneimittel, faktorArzneimittel: change.faktorArzneimittel, reihenfolgeDB: change.reihenfolgeDB}];
                }
            }
        }

        // ist die Planzeile neu?
        if (planzeileId < 0) {
            if (!skipStateChange) fetchAnsicht(([data]));
            return [data];
        } else {
            // prüfe, ob eine Planzeile bekannt ist
            let response;
            if (!!planzeile) {
                // Planzeile ist bekannt => normal speichern
                response = await callApiAsync({
                    auth,
                    url: planzeilenApi.putOrDeleteAnsicht(planzeileId, ansicht),
                    method: 'PUT',
                    data
                });
            } else {
                // Planzeile ist nicht bekannt => Ansicht löschen
                console.warn(`lösche Ansicht für #${planzeileId}: `, ansicht, data);
                response = await callApiAsync({
                    auth,
                    url: planzeilenApi.putOrDeleteAnsicht(planzeileId, ansicht),
                    method: 'DELETE'
                });
            }

            if (!skipStateChange) fetchAnsicht();
        }

        return [];
    }

    const onSaveReihenfolgeChangedTriggered = async (planzeileId, skipStateChange) => {
        if (ansicht === ANSICHT_STANDARD || planzeileId === -1) {
            persistChangePlanzeile(planzeileId);
        } else if (planzeileId > 0) {
            const ret = await persistChangeAnsicht(planzeileId, null, true);
            if (ret) {
                const reihenfolgeNeu = reihenfolgeChanged[planzeileId];
                delete reihenfolgeNeu.triggerSave;

                if (!skipStateChange) setReihenfolgeDB(prev => {
                    const neu = {...prev};
                    neu[planzeileId] = reihenfolgeNeu;
                    return neu;
                });

                return reihenfolgeNeu;
            }
        }
    }

   const onSave = planzeileId => {
        return new Promise(async resolve => {
            let result = null;
            if (!medikationsplan.active) return null;

            // wird die Planzeile gerade aktualisiert?
            if (planzeilenChanging.current.includes(planzeileId)) {
                setPlanzeilenToSaveAfterChange(prev => [...prev, planzeileId]);

                console.log(`Planzeile #${planzeileId} wird gerade aktualisiert`);
                onSaveResolve.current[planzeileId] = resolve;
            } else {
                // wurde die Planzeile selbst geändert?
                if (updatedPlanzeilen && updatedPlanzeilen.includes(planzeileId)) {
                    console.log(`Planzeile #${planzeileId} wurde geändert`);

                    clearHandleSaveTimeout();
                    setChildSaved(planzeileId);
                    result = await handleSave(planzeileId);
                }

                // oder wurde Reihenfolge der Planzeile geändert?
                else if (reihenfolgeChanged && reihenfolgeChanged[planzeileId]) {
                    console.log(`Reihenfolge von Planzeile #${planzeileId} wurde geändert`);

                    if (!reihenfolgeChangeBeingSaved.current.has(planzeileId)) {
                        setReihenfolgeChanged(prev => ({...prev, [planzeileId]: {...prev[planzeileId], triggerSave: true}}));
                    }
                }

                if (onSaveResolve.current[planzeileId]) {
                    onSaveResolve.current[planzeileId](result);
                    delete onSaveResolve.current[planzeileId];
                }

                if (planzeilenToSaveAfterChange[planzeileId]) {
                    setPlanzeilenToSaveAfterChange(prev => [...prev.filter(id => id !== planzeileId)]);
                }

                return result;
            }
        });
    }

    const handleNeuePlanzeile = () => {
        if (medikationsplanContext.planzeileSelected) {
            reihenfolgeNeuIndexRef.current = reihenfolge[medikationsplanContext.planzeileSelected].reihenfolge;
        }

        const heute = moment().startOf('day');
        let startDosierabschnitt = moment(anzeigePlanzeilen?.[0]?.dosierschema[0]?.start || Date.now()).startOf('day');
        startDosierabschnitt = (anzeigePlanzeilen || [])?.reduce(
            (start, planzeile) => {
                return (planzeile?.dosierschema?.length === 1 && heute.diff(planzeile.datumErstellung, 'days') === 0 && planzeile.dosierschema[0].start && start?.isSame(planzeile.dosierschema[0].start) && start) || null
            }, startDosierabschnitt
        ) || heute;

        const planzeileNeu = handleNew({addAtEnd: true, performOnThis: th => th.dosierschema[0].start = startDosierabschnitt.valueOf()});
        const ansichten = ansichtPlanzeilen ? [...ansichtPlanzeilen] : [];
        if (ansicht !== ANSICHT_STANDARD) {
            setAnsichtPlanzeilen([...ansichten, { id: { planzeile: planzeileNeu, ansicht: ansicht }, reihenfolge: ansichtPlanzeilen?.length || 0 }]);
        }
    }

    const handleDeleteAnsicht = async () => {
        if (!ansicht) return;

        const response = await callApiAsync({
            auth,
            url: medikationsplanApi.getAnsichtenPlanzeilenByPlanIdAndAnsichtBezeichnung(medikationsplan.id, ansicht),
            method: "DELETE"
        });

        changeAnsicht(null);
        setAnsichten([...ansichten.filter(e => e !== ansicht)]);
    }

    const scrollToPlanzeile = (planzeileId, bounds=boundsType.top) => {
        if (!scrollToElement.current && scrollDirection.current === 0) {
            const performScroll = (element) => {
                const elementBoundsType = getViewportBoundsType(element);
                if (
                    (bounds === boundsType.top && [boundsType.full, boundsType.bottom].includes(elementBoundsType)) ||
                    (bounds === boundsType.bottom && [boundsType.full, boundsType.top].includes(elementBoundsType))
                ) {
                    const rect = element.getBoundingClientRect();
                    scrollToElement.current = {element, boundsType: bounds};
                    scrollDirection.current = rect.top - 100;
                    setTriggerScroll(true);
                }

                return true;
            };

            const element = document.getElementById(`planzeile-${planzeileId}`);
            if (element) {
                performScroll(element);
            } else {
                setTimeout(() => {
                    const element = document.getElementById(`planzeile-${planzeileId}`);
                    if (element) {
                        performScroll(element);
                    }
                }, 100);
            }
        }

        return false;
    }


    const oeffneDruckansicht = () => {
        const params = {
            ansicht: ansicht === ANSICHT_STANDARD || ansicht === ANSICHT_FAVORITEN ? undefined : ansicht,
            datum: moment(medikationsplanContext.anzeigeDatum).format('YYYYMMDD'),
            token: auth.token
        }
        window.open(medikationsplanApi.getBundesMedikationsplanPDF(medikationsplan.id, params));
    }

    const showInteraktionen = async (m2List) => {
        setInteraktionenLoading(true);

        if (typeof m2List === 'undefined') {
            m2List = medikationsplanContext.planzeilen.reduce((m2List, planzeile) => {
                if (planzeile.arzneimittel && medikationsplanContext.planzeilenSichtbar[planzeile.id]) {
                    return [...m2List, planzeile.arzneimittel.m2];
                } else {
                    return m2List;
                }
            }, []);
        }

        const url = interaktionApi.getInteraktionenByM2List(m2List);
        const response = await callApiAsync({auth, url});

        if (!interaktionenContext.ueberschriftenInt) {
            interaktionenContext.setUeberschriftenInt((await callApiAsync({auth, url: interaktionApi.getUeberschriften(1311)})).data.OBJECT);
        }

        if (!interaktionenContext.ueberschriftenItx) {
            interaktionenContext.setUeberschriftenItx((await callApiAsync({auth, url: interaktionApi.getUeberschriften(1312)})).data.OBJECT);
        }

        const responsePair = response.data.OBJECT;
        interaktionenContext.setInteraktionen(responsePair.key);
        interaktionenContext.setInteraktionenTextMap(responsePair.value);
        interaktionenContext.setShowModal(true);
        setInteraktionenLoading(false)
    }

    const handleAbgabeplanNeuBerechnen = async () => {
        // Abgabeplan komplett zurücksetzen
        console.log("abgabeplan zurücksetzen: " + medikationsplan.id);
        // const response = await callApiAsync({auth, url: medikationsplanApi.resetAbgabeplan(), method: "post", data: {medikationsplan: medikationsplan.id}});

        // Abgabeplan für alle Planzeilen neu berechnen
        handleBerechneReichweite(medikationsplanContext.planzeilen.map(planzeile => planzeile.id), null, null, true);
    }

    const pauseEintragen = (zeitspanne) => {
        medikationsplanContext.setPauseGlobal({modus: "PLAN_PAUSIEREN", datum: zeitspanne.start || moment().valueOf(), datum2: zeitspanne.ende});
    }

    const pauseAufheben = (zeitspanne) => {
        medikationsplanContext.setPauseGlobal({modus: "PAUSE_BEENDEN", datum: moment(zeitspanne.ende).add(1, 'day').valueOf() || moment().valueOf()});
    }

    const pauseLoeschen = () => {
        let start = null;
        let ende = null;
        for (let planzeileId of medikationsplanContext.planzeilenChecked) {
            const planzeile = medikationsplanContext.planzeilenMap[planzeileId];
            const abschnitt = planzeile.dosierschema.sort(getSorter("dosierabschnitt", "start", true))[0];
            if (!abschnitt || !abschnitt.blisterPause) continue;

            if (!start) {
                start = abschnitt.start;
                ende = abschnitt.ende;
            } else if (start !== abschnitt.start || ende !== abschnitt.ende) {
                notification.error({message: "Es gibt unterschiedliche Pausenlängen unter den ausgewählten Planzeilen. Der Vorgang wird abgebrochen."});
                return;
            }
        }

        if (!!start) {
            medikationsplanContext.setPauseGlobal({modus: "PAUSE_BEENDEN", datum: start});
        }
    }

    const onArrowDown = () => {
        const planzeileIdsSorted = Object.keys(reihenfolge).sort((a, b) => reihenfolge[a].reihenfolge - reihenfolge[b].reihenfolge);
        if (!planzeileIdsSorted.length) return;

        let index = 0;
        if (medikationsplanContext.planzeileSelected) {
            index = reihenfolge[medikationsplanContext.planzeileSelected].reihenfolge + 1;
        }

        while (index < planzeileIdsSorted.length-1 && (parseInt(planzeileIdsSorted[index]) < 0 || !medikationsplanContext.isPlanzeileSichtbar(planzeileIdsSorted[index], globalContext.heute))) {
            index++;
        }

        if (planzeileIdsSorted[index]) {
            medikationsplanContext.setPlanzeileSelected(parseInt(planzeileIdsSorted[index]));
        } else {
            medikationsplanContext.setPlanzeileSelected(null);
        }
    }

    const onArrowUp = () => {
        const planzeileIdsSorted = Object.keys(reihenfolge).sort((a, b) => reihenfolge[a].reihenfolge - reihenfolge[b].reihenfolge);
        if (!planzeileIdsSorted.length) return;

        let index = planzeileIdsSorted.length - 1;
        if (medikationsplanContext.planzeileSelected) {
            index = reihenfolge[medikationsplanContext.planzeileSelected].reihenfolge - 1;
        }

        while (index >= 0 && (parseInt(planzeileIdsSorted[index]) < 0 || !medikationsplanContext.isPlanzeileSichtbar(planzeileIdsSorted[index], globalContext.heute))) {
            index--;
        }

        if (index >= 0) {
            medikationsplanContext.setPlanzeileSelected(parseInt(planzeileIdsSorted[index]));
        } else {
            medikationsplanContext.setPlanzeileSelected(null);
        }
    }

    const onArrowLeft = () => {
        if (medikationsplanContext.anzeigeDatumPrev) {
            setAnzeigeDatum(medikationsplanContext.anzeigeDatumPrev);
        }
    }

    const onArrowRight = () => {
        if (medikationsplanContext.anzeigeDatumNext) {
            setAnzeigeDatum(medikationsplanContext.anzeigeDatumNext);
        }
    }

    const setAnzeigeDatum = datumNeu => {
        const query = queryString.parse(history.location.search);
        query.datum = moment(datumNeu).format("YYYY-MM-DD");
        const url = queryString.stringifyUrl({url: history.location.pathname, query});

        history.push(url);
    }

    const auswahlAbsetzen = (momentDatumAbsetzung) => {
        medikationsplanContext.setAbsetzenGlobal(momentDatumAbsetzung);
    }

    return <StatusDisplay status={status} showSaving={false}>
        <div onClick={e => {
            e.stopPropagation();
        }}>
            <RezeptanforderungModal
                planzeile={medikationsplanContext.anforderungData?.[0]?.planzeile}
                visible={!!medikationsplanContext.anforderungData}
                onCancel={() => {
                    medikationsplanContext.setAnforderungData(null)
                }}
                onOk={(anforderung) => {
                    medikationsplanContext.setAnforderungNeu(anforderung);
                    medikationsplanContext.setAnforderungData(null);
                }}
                hausarzt={medikationsplan?.patient?.hausarzt}
                verordner={medikationsplanContext.anforderungData?.[0]?.planzeile?.verordner}
                arzneimittel={medikationsplanContext.anforderungData?.[0]?.planzeile?.arzneimittel}
                bedarf={medikationsplanContext.anforderungData?.[0]?.planzeile?.bedarf}
                anforderungData={medikationsplanContext.anforderungData}
            />
        </div>

        <Modal
            visible={showReichweiteModal}
            footer=""
        >
            <h3>Bitte warten Sie einen Augenblick</h3>
            <p>Die Abgabe des Medikationsplans wird simuliert, um die Reichweite zu berechnen. Dies kann einige Zeit dauern!</p>
        </Modal>

        <Row type="flex" gutter={[16, 16]} align={"middle"} style={{
            backgroundColor: "#86ADC8",
            borderStyle: "solid",
            borderWidth: "3px",
            borderColor: "#86ADC8",
            borderRadius: "10px",
            alignItems: "start"
        }}>
            <Col span={5} style={stylePlanzeileHeader} offset={1}>
                <Col span={24}>
                    <span>
                        <h6 style={{display: "inline-block", verticalAlign: "middle"}}>
                            <span style={{marginRight: 10}}>
                                {showInputAnsichtNeu && <Tooltip title={"neue Ansicht speichern"}><SaveOutlined onClick={() => setAnsichtNeu(inputAnsichtNeuValue)} style={{marginRight: 5, verticalAlign: 0}} /></Tooltip>}
                                {!showInputAnsichtNeu && <Tooltip title={"neue Ansicht erstellen"}><PlusCircleOutlined onClick={() => setShowInputAnsichtNeu(true)} style={{marginRight: 5, verticalAlign: 0}} /></Tooltip>}

                                {showInputAnsichtNeu && <Tooltip title={"abbrechen"}><CloseCircleOutlined onClick={() => setShowInputAnsichtNeu(false)} style={{verticalAlign: 0}} /></Tooltip>}
                                {!showInputAnsichtNeu && <Tooltip title={"Ansicht löschen"}><MinusCircleOutlined onClick={handleDeleteAnsicht} style={{verticalAlign: 0}} /></Tooltip>}
                            </span>

                            Ansicht:
                        </h6>
                    </span>
                </Col>

                <Col span={24}>
                    {showInputAnsichtNeu && <input
                        placeholder={"Ansicht-Bezeichnung"}
                        value={inputAnsichtNeuValue}
                        onChange={e => setInputAnsichtNeuValue(e.target.value)}
                        onKeyDown={e => {
                            switch (e.key) {
                                case "Enter":
                                    setAnsichtNeu(inputAnsichtNeuValue);
                                    break;
                                case "Escape":
                                    setShowInputAnsichtNeu(false);
                            }
                        }} autoFocus={true}
                    />}

                    {!showInputAnsichtNeu && <Select
                        onChange={changeAnsicht}
                        defaultValue={() => {
                            return ansichtParam ? ansichten.find(key => key === ansichtParam) || ANSICHT_STANDARD : ANSICHT_STANDARD;
                        }}
                        value={ansicht}
                        style={{width: 150}}
                    >
                        {ansichten.map((bezeichnung, i) =>
                            <Option key={i} value={bezeichnung}>{bezeichnung}</Option>
                        )}
                    </Select>}
                </Col>
            </Col>

            <Col span={18} style={stylePlanzeileHeader}>
                <Col span={24}><h6>Aktionen</h6></Col>

                <Col span={24}>
                    <Popover
                        content={<div>
                            <h6>Sollen wirklich alle ausgewählten Planzeilen freigegeben weden?</h6>
                            <LabeledSwitch label={"Alle Dosierabschnitte freigeben"} checked={freigabeMitDosierabschnitten} onChange={setFreigabeMitDosierabschnitten} />

                            <Row style={{marginTop: 20}}>
                                <Col span={12}>
                                    <Button size={"small"} onClick={e => {
                                        medikationsplanContext.setResetGlobal(true);
                                        setDisablePlanzeileFreigebenButton(true);
                                        setFreigabePopoverVisible(false);
                                    }}>NEIN, alle Änderungen zurücksetzen</Button>
                                    <Button size={"small"} onClick={e => setFreigabePopoverVisible(false)}>NEIN, abbrechen</Button>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>
                                    <Button
                                        size={"small"}
                                        type={"primary"}
                                        style={{right: 0}}
                                        onClick={e => {
                                            setTriggerPlanzeilenFreigeben(true);
                                            setFreigabePopoverVisible(false);
                                        }}
                                    >JA, alle ausgewählten Planzeilen freigeben</Button>
                                </Col>
                            </Row>
                        </div>}

                        trigger={"click"}
                        visible={freigabePopoverVisible}
                        onVisibleChange={setFreigabePopoverVisible}
                    >
                        <Button disabled={disablePlanzeileFreigebenButton || !medikationsplanContext.planzeilenChecked.length} onClick={e => setFreigabePopoverVisible(true)}>Planzeilen freigeben</Button>
                    </Popover>

                    <Button onClick={() => showInteraktionen()} loading={interaktionenLoading || interaktionenContext.hashesLoading}>Interaktionen anzeigen</Button>
                    <Button onClick={() => oeffneDruckansicht(medikationsplanContext.anzeigeDatum)}>drucken</Button>

                    {/**
                     *** Pause
                     **/}
                    <Popover trigger="click" content={<div>
                        <h5>Zeitraum der Pause:</h5>
                        Start: <DatePicker
                        defaultValue={moment(medikationsplanPause.start)}
                        format={"DD.MM.YYYY"}
                        disabledDate={time => medikationsplanPause.ende && time.isAfter(medikationsplanPause.ende)}

                        onChange={date => {
                            setMedikationsplanPause(prev => ({...prev, start: !!date ? date.valueOf() : null}));
                        }}
                    /><br/>
                        Ende: <DatePicker
                        defaultValue={medikationsplanPause.ende ? moment(medikationsplanPause.ende) : null}
                        format={"DD.MM.YYYY"}
                        disabledDate={time => medikationsplanPause.start && time.isBefore(medikationsplanPause.start)}

                        onChange={date => {
                            setMedikationsplanPause(prev => ({...prev, ende: !!date ? date.valueOf() : null}));
                        }}
                    /><p>
                        <Button onClick={() => pauseEintragen(medikationsplanPause)}>Pause für ausgewählte, unpausierte Planzeilen eintragen ({medikationsplanPause.start ? moment(medikationsplanPause.start).format("DD.MM.YYYY") : "??"}{medikationsplanPause.ende ? " - " + moment(medikationsplanPause.ende).format("DD.MM.YYYY") : ""})</Button><br/>
                        <Button onClick={() => pauseAufheben(medikationsplanPause)} disabled={!medikationsplanPause.ende}>Pause für ausgewählte, pausierte Planzeilen aufheben{!!medikationsplanPause.ende && ` (${moment(medikationsplanPause.ende).startOf('day').format("DD.MM.YYYY")})`}</Button><br/>
                        <Button onClick={() => pauseLoeschen()}>Pause für ausgewählte, pausierte Planzeilen löschen</Button>
                    </p>
                    </div>}><Button disabled={!medikationsplanContext.planzeilenChecked.length}>Pause eintragen/aufheben</Button></Popover>

                    {/**
                     *** Absetzen
                     **/}
                    <Popover trigger="click" content={<div>
                        <h5>Planzeile absetzen:</h5>
                        Ende: <DatePicker
                        defaultValue={datumAbsetzung}
                        format={"DD.MM.YYYY"}

                        onChange={date => {
                            setDatumAbsetzung(date.endOf('day'));
                        }}
                    /><p>
                        <Button onClick={() => auswahlAbsetzen(datumAbsetzung)}>ausgewählte Planzeilen absetzen{datumAbsetzung && ` (${datumAbsetzung.format("DD.MM.YYYY")})`}</Button><br/>
                    </p>
                    </div>}><Button disabled={!medikationsplanContext.planzeilenChecked.length}>Planzeilen absetzen</Button></Popover>
                </Col>

                {<Col span={24}>
                    <InputDatum
                        markWeekDay={moment(blisterStartTag).day()}
                        value={simulationStart}
                        onChange={setSimulationStart}
                        allowClear={true}
                        placeholder="(optional)"
                    />
                    {medikationsplanContext.saveButtonDisabled ?
                        <Button onClick={handleAbgabeplanNeuBerechnen}><CalculatorOutlined/>Reichweiten neu berechnen ab Datum</Button> :
                        <Tooltip title={"Bitte zuerst speichern"}><Button disabled><CalculatorOutlined/>Abgabeplan neu berechnen</Button></Tooltip>
                    }
                </Col>}
            </Col>

            {/* Zweite Zeile */}
            <Col span={6} style={{paddingLeft: 15}}>
                {medikationsplanContext.anzeigeDatumPrev && medikationsplanContext.anzeigeDatumPrev < medikationsplanContext.anzeigeDatum && <Button onClick={e => setAnzeigeDatum(medikationsplanContext.anzeigeDatumPrev)}><ArrowLeftOutlined />{medikationsplanContext.anzeigeDatumPrev !== heute ? `Voriger Abschnitt: ${moment(medikationsplanContext.anzeigeDatumPrev).format("DD.MM.YYYY")}` : "Heute"}</Button>}
            </Col>

            <Col span={12} style={{textAlign: "center"}} ref={anzeigeDatumRef}>
                <span style={{position: "relative"}}>
                    <Tooltip title={"Datum ändern"}>
                        <h4 style={{cursor: "pointer", display: "inline-block"}} onClick={e => {
                            setShowAnzeigeDatumPicker(!showAnzeigeDatumPicker);
                        }}>{moment(medikationsplanContext.anzeigeDatum).format("DD.MM.YYYY")}</h4>
                    </Tooltip>

                    <DatePicker open={showAnzeigeDatumPicker} onChange={datum => {
                        setShowAnzeigeDatumPicker(false);

                        const anzeigeDatumNeu = datum.startOf('day').valueOf();
                        if (anzeigeDatumNeu !== medikationsplanContext.anzeigeDatum) {
                            setAnzeigeDatum(anzeigeDatumNeu);
                            // medikationsplanContext.setAnzeigeDatum(anzeigeDatumNeu);
                        }
                    }} locale={locale} style={{position: "absolute", zIndex: "-999", left: 0}} getPopupContainer={triggerNode => {
                        console.log("datepicker: ", triggerNode);
                        return triggerNode.parentNode;
                    }} />
                </span>
            </Col>

            <Col span={6} style={{textAlign: "right", paddingRight: 15}}>
                {medikationsplanContext.anzeigeDatumNext && medikationsplanContext.anzeigeDatumNext > medikationsplanContext.anzeigeDatum && <Button onClick={e => setAnzeigeDatum(medikationsplanContext.anzeigeDatumNext)}>{medikationsplanContext.anzeigeDatumNext !== heute ? `Nächster Abschnitt: ${moment(medikationsplanContext.anzeigeDatumNext).format("DD.MM.YYYY")}` : "Heute"}<ArrowRightOutlined /></Button>}
            </Col>
        </Row>

        <Row>
            <Col span={1}><Checkbox
                style={{float: "right"}}
                checked={anzeigePlanzeilen && medikationsplanContext.planzeilenChecked.length && medikationsplanContext.planzeilenChecked.length === anzeigePlanzeilen.length}
                indeterminate={anzeigePlanzeilen && medikationsplanContext.planzeilenChecked.length && anzeigePlanzeilen.length !== medikationsplanContext.planzeilenChecked.length}

                onChange={e => {
                    if (e.target.checked) {
                        medikationsplanContext.setPlanzeilenChecked(anzeigePlanzeilen.map(p => p.id));
                    } else {
                        medikationsplanContext.setPlanzeilenChecked([]);
                    }
                }}
            /></Col>
        </Row>


        {/* Planzeilen sortieren, filtern und ausgeben */}
        {anzeigePlanzeilen && reihenfolge && anzeigePlanzeilen
            .map((zeile, index) => {
                    //  { console.log("MedikationsplanZeile", zeile.id) }

                    return (<MedikationsplanZeile
                        style={{borderBottomStyle: "solid", borderColor: "#51B588", borderWidth: 1}}

                        key={zeile.id}
                        handleSave={async () => {
                            const result = await onSave(zeile.id);
                            return result;
                        }}
                        triggerSave={triggerSave && !reihenfolge[zeile.id].triggerSave}

                        value={zeile}
                        onChange={e => {
                            planzeilenChanging.current = [...planzeilenChanging.current.filter(id => id !== zeile.id), zeile.id];
                            handleChange(e, zeile.id);
                            setChildUpdated(zeile.id);
                        }}
                        onDelete={(planzeileId) => {
                            planzeilenChanging.current = [...planzeilenChanging.current.filter(id => id !== planzeileId), planzeileId];
                            handleChange(null, planzeileId);
                            setChildUpdated(planzeileId);
                            setAnsichtPlanzeilen(prev => prev ? prev.filter(pa => pa.id.planzeile.id !== planzeileId) : prev);
                        }}

                        updated={childrenUpdated.includes(zeile.id) || (updatedPlanzeilen && updatedPlanzeilen.find(pz => pz.id === zeile.id) !== undefined)}
                        setUpdated={() => setChildUpdated(zeile.id)}
                        setSaved={() => setChildSaved(zeile.id)}

                        isStandardAnsicht={ansicht === ANSICHT_STANDARD}
                        reihenfolge={reihenfolge}
                        setReihenfolgeIndex={setReihenfolgeIndex}
                        setReihenfolgeArzneimittel={setReihenfolgeArzneimittel}

                        abschnittFilter={abschnittFilter}
                        report={reportOptions[reportIndex]}

                        setDragPlanzeileId={setDragPlanzeileId}
                        setDropPlanzeileId={setDropPlanzeileId}

                        hausarzt={hausarzt}

                        openEditor={openEditor[zeile.id]}
                        setOpenEditor={type => addOpenEditor(zeile, type)}

                        scrollTo={scrollToPlanzeile}
                        kontrollModus={kontrollModus}
                        isFavoritenAnsicht={ANSICHT_FAVORITEN === ansicht}

                        setTriggerAnspruchReloadAfterSave={setTriggerAnspruchReloadAfterSave}
                        setTriggerBestandReloadAfterSave={setTriggerBestandReloadAfterSave}

                        triggerFreigabe={triggerPlanzeilenFreigeben && medikationsplanContext.planzeilenChecked.includes(zeile.id)}
                        addPlanzeile={handleChange}

                        abgabeplanNeuBerechnen={async () => {
                            await handleBerechneReichweite([zeile.id], null, null, true);
                        }}
                    />)
                }
            )}
    </StatusDisplay>
}
