/* 
    workloadReducer 
    wird mittels useReducer() für den state in useDataAccess() verwendet
*/

export const SET_LOADING = 'SET_LOADING'
export const SET_SAVING = 'SET_SAVING'
export const SET_ERROR = 'SET_ERROR'
export const SET_DATA = 'SET_DATA'
export const UPDATE_CURRENT = 'UPDATE_CURRENT'
export const LOAD_FROM_CACHE = 'LOAD_FROM_CACHE'

export const RESET_DATA = 'RESET_DATA'
export const SET_CURRENT = 'SET_CURRENT'
export const SORT_DATA = 'SORT_DATA'
export const SET_FROM_VALUE = 'SET_FROM_VALUE'

export const UPDATE_ARRAY_ELEMENT = 'UPDATE_ARRAY_ELEMENT'
export const REMOVE_ARRAY_ELEMENT = 'REMOVE_ARRAY_ELEMENT'

export const workloadReducer = (state, action) => {
    switch (action.type) {

        case SET_LOADING:
            return { ...state, isLoading: action.payload }

        case SET_SAVING:
            return { ...state, isSaving: action.payload }

        case SET_ERROR:
            return { ...state, error: action.payload }

        case SET_DATA:
            let newCurrent = JSON.parse(JSON.stringify(action.payload))
            if (action.handleSorting)
                newCurrent = action.payload.sort(action.handleSorting);

            return {
                ...state,
                current: newCurrent,
                last: JSON.parse(JSON.stringify(newCurrent)),
                isLoading: false,
                isSaving: false,
                updated: false
            }

        case RESET_DATA:
            return { ...state, current: state.last }

        case LOAD_FROM_CACHE:
            let newCurrentFromCache = JSON.parse(JSON.stringify(action.payload))
            return newCurrentFromCache

        case UPDATE_CURRENT:
            let newCurrentFromUpdate = action.payload
            if (action.handleSorting)
                newCurrentFromUpdate = action.payload.sort(action.handleSorting);
            return {
                ...state,
                current: newCurrentFromUpdate,
                updated: true
            }

        case REMOVE_ARRAY_ELEMENT:
            let currentAfterDelete = state.current.filter(e => e.id !== action.payload)
            return {
                ...state,
                current: JSON.parse(JSON.stringify(currentAfterDelete)),
                last: JSON.parse(JSON.stringify(currentAfterDelete)),
            };


        case UPDATE_ARRAY_ELEMENT:
            if (action.index === null) return state
            let currentAfterUpdate = [...state.current]
            currentAfterUpdate[action.index] = action.payload
            return {
                ...state,
                current: JSON.parse(JSON.stringify(currentAfterUpdate)),
                last: JSON.parse(JSON.stringify(currentAfterUpdate)),
                updated: false,
                isSaving: false
            }


        default:
            console.log('unbekannter Reducer', state, action)
            return state;
    }
}



/* 
    cacheReducer 
    werden mittels useContext() für den cache / globalContext in useDataAccess() verwendet
*/

export const UPDATE_CACHE = 'UPDATE_CACHE'
export const RESET_CACHE = 'RESET_CACHE'
export const SET_SUCHTEXT = 'SET_SUCHTEXT'
export const SET_RESULT = 'SET_RESULT'
export const SET_RESULTFILTERED = 'SET_RESULTFILTERED'
export const SET_SEARCHTERM = 'SET_SEARCHTERM'


export const RESET_SUCHE = 'RESET_SUCHE'

export const workloadCacheReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_CACHE:
            if (!action.cacheId) return state

            let newState = state ? JSON.parse(JSON.stringify(state)) : {}
            newState[action.cacheId] = JSON.parse(JSON.stringify(action.payload))
            newState["CACHE_HISTORY"] = action.cacheId

            //newState.history = Array.isArray(newState.history) ? [...action.cacheId] : [action.cacheId]
            return newState

        case RESET_CACHE:
            return null

        default:
            console.log('undefined action type', action, state)
            return state;
    }
}


export const sucheCacheReducer = (state, action) => {
    switch (action.type) {
        case SET_SUCHTEXT:
            return { ...state, suchtext: action.payload };
        case SET_RESULT:
            return { ...state, result: action.payload };
        case SET_RESULTFILTERED:
            return { ...state, resultFiltered: action.payload };
        case SET_SEARCHTERM:
            return { ...state, searchTerm: action.payload };
        case RESET_SUCHE:
            return { result: [], suchtext: "" }
        default:
            console.log('undefined action type', state, action)
            return state;
    }
}
