import {createContext, useState} from "react";

const initVal = {
    gewaehlteNachricht: null,
    setGewaehlteNachricht: (nachricht) => {},

    gewaehlteAnforderungen: [],
    setGewaehlteAnforderungen: (anforderungen) => {},
}

const NachrichtenContext = createContext(initVal)
export default NachrichtenContext;

export const useInitialState = () => {
    const [gewaehlteNachricht, setGewaehlteNachricht] = useState(null);
    const [gewaehlteAnforderungen, setGewaehlteAnforderungen] = useState(null);

    return {
        gewaehlteNachricht,
        setGewaehlteNachricht,

        gewaehlteAnforderungen,
        setGewaehlteAnforderungen,
    }
}